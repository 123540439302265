import {
  ClubPublicSettingsCustomerSpaceDisplaySessionConfig,
  DiveSessionTimeDayPeriod,
} from '@mabadive/app-common-model';
import {
  clubDiveSessionThemeBuilder,
  dateService,
} from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { useClubResumePublic } from 'src/business/_core/store';
import { ClubDiveSessionHeaderDiveSite } from './ClubDiveSessionHeaderDiveSite';

export const ClubDiveSessionHeaderTimeAndDiveSite = function ({
  diveSiteId,
  sessionIndex,
  time,
  isMultiSession,
  config,
  className,
}: {
  diveSiteId: string;
  sessionIndex: number;
  time: Date;
  isMultiSession: boolean;
  config: ClubPublicSettingsCustomerSpaceDisplaySessionConfig;
  className?: string;
}) {
  const clubResume = useClubResumePublic();

  const timeDayPeriod: DiveSessionTimeDayPeriod = useMemo(
    () => clubDiveSessionThemeBuilder.buildTimeDayPeriod(time),
    [time],
  );

  return (
    <div
      className={`max-w-full flex gap-1 font-bold 
          bg-day-period-${timeDayPeriod}-light text-gray-600 
          ${className}`}
    >
      {config?.showTime && (
        <div
          className={`bg-day-period-${timeDayPeriod}-dark text-white py-0.5 px-1`}
        >
          {dateService.formatUTC(time, 'HH:mm')}
        </div>
      )}

      {config?.showDiveSite && (
        <ClubDiveSessionHeaderDiveSite
          className=" py-0.5"
          diveSiteId={diveSiteId}
          sessionIndex={sessionIndex}
          time={time}
          isMultiSession={isMultiSession}
        />
      )}
    </div>
  );
};
