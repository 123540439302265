import React from 'react';
import {
  AppButtonFreeColor,
  AppIconsAction,
  AppLoadableContentContainer,
  AppLoader,
  AppPageContainer,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import { useRedirect } from '../../_core/data/hooks';
import { diveCenterBookingUrlBuilder } from '../BO-booking/diveCenterBookingUrlBuilder.service';
import { DiveCenterBrandCard } from '../components';
import { CustomerProductsPage_FillContactPanel } from './CustomerProductsPage_FillContactPanel';
import {
  DiveCenterBookingContactPageLocalState,
  useDiveCenterBookingContactPageLocalState,
} from './useDiveCenterBookingContactPageLocalState.hook';

export const DiveCenterBookingContactPage = ({
  navigationContext,
}: {
  navigationContext: {
    clubPublicReference: string;
  };
}) => {
  const clubPublicReference = navigationContext.clubPublicReference;
  const redirectTo = useRedirect();

  const localState: DiveCenterBookingContactPageLocalState =
    useDiveCenterBookingContactPageLocalState({
      clubPublicReference,
    });
  const { state, data, actions } = localState;

  const clubSettings = data.clubSettings;
  const clubPublicSettings = data.clubPublicSettings;
  const diveCenter = data.diveCenter;

  const appUiTheme = clubPublicSettings?.appUiTheme;
  const enableCatalog =
    clubPublicSettings?.customerSpace?.onlineBooking?.enableCatalog;

  return state.loadableContent.contentState !== 'full' ? (
    <AppLoader type="loading" />
  ) : (
    <AppPageContainer
      className="bg-gray-50 overflow-y-auto"
      {...state.loadableContent}
    >
      <AppPageContentContainer
        paddingBottom={false}
        className="bg-gray-50 app-p-content"
      >
        <AppLoadableContentContainer {...state.loadableContent}>
          <div className="h-full flex flex-col justify-between gap-4 md:gap-8">
            {
              <>
                <DiveCenterBrandCard
                  className="app-card app-p-content hidden sm:block"
                  clubPublicData={clubSettings?.publicData}
                  clubPublicSettings={clubPublicSettings}
                  diveCenterPublicData={diveCenter?.publicData}
                  clubPublicReference={clubPublicReference}
                  showHeader={true}
                  showContactLinks={true}
                />
                <DiveCenterBrandCard
                  className="app-card app-p-content sm:hidden"
                  clubPublicData={clubSettings?.publicData}
                  clubPublicSettings={clubPublicSettings}
                  diveCenterPublicData={diveCenter?.publicData}
                  clubPublicReference={clubPublicReference}
                  showHeader={true}
                  showContactLinks={false} // sur mobile, on masque les numéros, ils sont visibles en bas de page
                  showLegalInformation={false} // sur mobile, on masque les infos légales, ils sont visibles en bas de page
                />
              </>
            }
            <div className="w-full max-w-6xl mx-auto">
              <CustomerProductsPage_FillContactPanel localState={localState} />
            </div>
            <div className="flex-grow"></div>
            {enableCatalog && (
              <AppButtonFreeColor
                className="text-sm whitespace-nowrap"
                icon={AppIconsAction.back}
                onClick={(e) => {
                  e.stopPropagation();
                  redirectTo(
                    diveCenterBookingUrlBuilder.buildDiveCenterShoppingCartUrl({
                      clubPublicReference,
                    }),
                  );
                }}
                {...appUiTheme.buttonPrimary}
              >
                RETOUR AU PANIER
              </AppButtonFreeColor>
            )}
          </div>
        </AppLoadableContentContainer>
      </AppPageContentContainer>
    </AppPageContainer>
  );
};
