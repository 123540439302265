import {
  DayPlanning,
  WeekDate,
  WeekPlanning,
} from '@mabadive/app-common-model';
import { commonDiveSessionBuilder } from '../../../business/session/commonDiveSessionBuilder.service';
import { dateService, uuidGenerator } from '../../../data';

export const weekPlanningBuilder = {
  buildWeekPlanning,
  buildDayReferences,
};

function buildDayReferences(weekPlanning: WeekPlanning) {
  return weekPlanning.daysPlanning.map((dayPlanning) => {
    const date = dayPlanning.weekDate.date;
    const dayReference = commonDiveSessionBuilder.buildDayReference(date);
    return dayReference;
  }, [] as string[]);
}

function buildWeekPlanning({ focusDate }: { focusDate: Date }): WeekPlanning {
  if (!focusDate) {
    return undefined;
  }
  const weekDates: WeekDate[] = dateService.getWeekDates(focusDate);

  const { daysPlanning } = weekDates.reduce(
    (acc, weekDate) => {
      const isToday = dateService.isTodayUTC(weekDate.date);
      const dayPlanning: DayPlanning = {
        _id: uuidGenerator.random(),
        index: weekDate.isoWeekDay,
        weekDate: weekDate,
        isToday: isToday,
        isPast: !isToday && dateService.isPastUTC(weekDate.date),
      };

      acc.daysPlanning.push(dayPlanning);

      return acc;
    },
    {
      daysPlanning: [],
    } as {
      daysPlanning: DayPlanning[];
    },
  );

  const weekPlanning: WeekPlanning = {
    daysPlanning,
    firstDate: daysPlanning[0].weekDate.date,
    lastDate: daysPlanning[daysPlanning.length - 1].weekDate.date,
  };
  return weekPlanning;
}
