import { Booking } from '@mabadive/app-common-model';
import { DiverBookingGraphqlResult } from '../../../../../../../../_core/data/store-repository/diver-booking/model';

export const bookingResumeToBookingConvertor = {
  convert,
};

function convert(
  bookingResume: DiverBookingGraphqlResult,
  {
    clubReference,
  }: {
    clubReference: string;
  },
): Booking {
  const {
    diveCenter,
    bookingContact,
    bookingProducts,
    bookingMembers,
    bookingSessions,
    clubResume,
    bookingCustomerUpdates,
    inquiryResponses,
    bookingPayments,

    ...bookingProps
  } = bookingResume;
  const booking: Booking = {
    ...bookingProps,
    clubReference,
  };

  return booking;
}
