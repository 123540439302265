"use strict";
exports.__esModule = true;
exports.ONLINE_BOOKING_STATES = void 0;
exports.ONLINE_BOOKING_STATES = [
    'draft',
    'pending-club',
    'pending-customer',
    'confirmed',
    'cancelled',
];
