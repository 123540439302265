/* eslint-disable @typescript-eslint/no-unused-vars */
import { Listbox, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React, { CSSProperties, Fragment, useMemo } from 'react';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { AppSingleSelect2HeadlessUIOption } from './AppSingleSelect2HeadlessUIOption';
import { appSingleSelectOptionColorsPaleteBuilder } from './appSingleSelectOptionColorsPaleteBuilder.service';

export type AppSingleSelect2HeadlessUIPropsColor =
  | 'gray'
  | 'green'
  | 'blue'
  | 'fuchsia'
  | 'theme';

export type AppSingleSelect2HeadlessUIPropsPaletGlobal = {
  focusRing?: string;
  focusBorder?: string;
};
export type AppSingleSelect2HeadlessUIPropsPaletOption = {
  option: {
    icon?: string;
    label?: string;
    selectedCheckIcon?: string;
  };
  selected: {
    icon?: string;
    label?: string;
  };
};

export type AppSingleSelect2HeadlessUIProps<
  T,
  L extends string | React.ReactNode = string,
> = {
  options: ValueLabel<T, L>[];
  optionsStyle?: CSSProperties;
  theme: 'tailwind' | 'material-ui';
  value?: T;
  color?: AppSingleSelect2HeadlessUIPropsColor;
  onChange?: (value: T) => void;
  required?: boolean;
  disableClearButton?: boolean;
  showIcons?: boolean;
  disabled?: boolean;
  className?: string;
};

export function AppSingleSelect2HeadlessUI<
  T,
  L extends string | React.ReactNode = string,
>({
  options,
  optionsStyle,
  theme,
  value,
  color = 'gray',
  onChange,
  required,
  disableClearButton,
  showIcons = true,
  className = '',
  disabled,
}: AppSingleSelect2HeadlessUIProps<T, L>) {
  const selectedOption = useMemo(() => {
    return getSelectedOption({
      options,
      value,
    });
  }, [options, value]);

  const SelectedOptionIcon = selectedOption?.icon;

  function clear() {
    onChange(null);
  }

  const globalColors: AppSingleSelect2HeadlessUIPropsPaletGlobal =
    useMemo(() => {
      return buildColorsPaletGlobal<T, L>({
        color,
      });
    }, [color]);

  const selectedColors = useMemo(() => {
    return appSingleSelectOptionColorsPaleteBuilder.buildColorsPaletOption({
      color,
      option: selectedOption,
    })?.selected;
  }, [color, selectedOption]);

  return (
    <div className={clsx('', className)}>
      <Listbox
        disabled={disabled}
        value={value}
        onChange={(item) => {
          onChange((item as any).value);
        }}
      >
        {({ open }) => (
          <>
            {/* <Listbox.Label className="block text-sm font-medium text-gray-700">
            Assigned toy
          </Listbox.Label> */}
            <div
              className={`${
                theme === 'tailwind' ? '' : 'mt-1'
              } bg-white w-full relative`}
            >
              <Listbox.Button
                className={clsx(
                  ' w-full flex bg-white gap-2 cursor-pointer relative ',
                  'text-left sm:text-sm focus:outline-none',
                  theme === 'tailwind' &&
                    clsx(
                      'pl-1 sm:pl-2 pr-8 sm:pr-9 py-2 border rounded-md focus:ring-1  shadow-sm',
                      disableClearButton ? 'pl-1 sm:pl-2' : 'pl-8',
                      required && !value
                        ? 'border-status-error'
                        : 'border-gray-300',
                    ),
                  theme === 'material-ui' &&
                    clsx(
                      disableClearButton ? 'pl-2' : 'pl-6',
                      '-pt-2 pr-3 pb-2 border-b border-gray-400 focus:border-b-2 focus:border-app-primary',
                    ),
                  globalColors.focusBorder,
                  globalColors.focusRing,
                )}
              >
                {!disableClearButton && (
                  <div
                    className={clsx(
                      value ? 'block' : 'hidden',
                      theme === 'material-ui' &&
                        'absolute -top-0.5 -left-1 pr-2',
                      theme === 'tailwind' && 'absolute top-0.5 left-0.5 pr-2',
                    )}
                  >
                    <AppHeroIcons.actionCancel2
                      className={'h-6 w-6 text-gray-300 hover:text-gray-600'}
                      onClick={(e) => {
                        clear();
                        // e.preventDefault();
                        e.stopPropagation();
                      }}
                    />
                  </div>
                )}
                {showIcons && SelectedOptionIcon && (
                  <SelectedOptionIcon
                    className={clsx('h-4 w-4', selectedColors?.icon)}
                  />
                )}
                <span
                  className={clsx(
                    'w-full block truncate min-h-[1rem]',
                    theme === 'material-ui'
                      ? 'text-sm uppercase'
                      : 'text-xs uppercase font-bold',
                    selectedColors?.label,
                  )}
                >
                  {selectedOption?.label ?? ''}
                </span>
                <span
                  className={`absolute inset-y-0 ${
                    theme === 'material-ui' ? '-right-2' : 'right-0'
                  } flex items-center pointer-events-none`}
                >
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open && !disabled}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  style={optionsStyle}
                  className="absolute z-20 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                >
                  {options.map((option, i) => (
                    <AppSingleSelect2HeadlessUIOption
                      key={`${i}`}
                      option={option}
                      showIcons={showIcons}
                      color={color === 'theme' ? 'gray' : color}
                    />
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
}

function buildColorsPaletGlobal<
  T,
  L extends string | React.ReactNode = string,
>({
  color,
}: {
  color: AppSingleSelect2HeadlessUIPropsColor;
}): AppSingleSelect2HeadlessUIPropsPaletGlobal {
  let palet: AppSingleSelect2HeadlessUIPropsPaletGlobal = {};

  // couleur de focus
  if (color === 'blue') {
    palet = {
      focusRing: 'focus:ring-blue-500',
      focusBorder: 'focus:border-blue-500',
    };
  } else if (color === 'green') {
    palet = {
      focusRing: 'focus:ring-green-500',
      focusBorder: 'focus:border-green-500',
    };
  } else if (color === 'fuchsia') {
    palet = {
      focusRing: 'focus:ring-fuchsia-500',
      focusBorder: 'focus:border-fuchsia-500',
    };
  } else if (color === 'theme') {
    palet = {
      focusRing: 'focus:ring-gray-500',
      focusBorder: 'focus:border-gray-500',
    };
  }

  return palet;
}

function getSelectedOption<T, L extends string | React.ReactNode = string>({
  options,
  value,
}: {
  options: ValueLabel<T, L>[];
  value: T;
}): ValueLabel<T, L> {
  if (options && value !== null && value !== undefined) {
    const selectedOption = options.find((o) => o.value === value);
    if (selectedOption) {
      return selectedOption;
    }
  }
  return null;
}
