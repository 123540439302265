import {
  LoadableAttributeStore,
  loadableAttributeStoreFactory,
  SimpleStore,
} from 'src/lib/reactive/store';
import { libLogger } from '../libLogger.service';

const logger = libLogger.child({
  module: 'graphql',
  filename: 'graphqlClientStoreProvider.service',
});

export type GraphqlClientStore = {
  authenticationToken: LoadableAttributeStore<string>;
  baseHttpUrl: LoadableAttributeStore<string>;
  baseWsUrl: LoadableAttributeStore<string>;
};

export const graphqlClientStoreProvider = {
  get,
  init,
};

let store: GraphqlClientStore;

function get(): GraphqlClientStore {
  if (!store) {
    throw new Error('Call init method first to initialize GraphqlClientStore.');
  }
  return store;
}

function init(baseStore: SimpleStore<any>): GraphqlClientStore {
  logger.info('[graphql][graphqlClientStoreProvider.init]');

  store = {
    authenticationToken: loadableAttributeStoreFactory.create<string>(
      baseStore,
      ' graphql_authenticationToken',
    ),
    baseHttpUrl: loadableAttributeStoreFactory.create<string>(
      baseStore,
      ' graphql_baseHttpUrl',
    ),
    baseWsUrl: loadableAttributeStoreFactory.create<string>(
      baseStore,
      ' graphql_baseWsUrl',
    ),
  };
  return get();
}
