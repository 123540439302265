"use strict";
exports.__esModule = true;
exports.DEFAULT_EQUIPMENT_SETTINGS_TANK = void 0;
var DEFAULT_SIZES = [6, 10, 12, 13.5, 14, 15, 20];
exports.DEFAULT_EQUIPMENT_SETTINGS_TANK = {
    type: 'tank',
    enabled: true,
    enableStaffEquipment: true,
    club: {
        enabled: false,
        models: DEFAULT_SIZES.map(function (capacity) {
            var ref = "".concat(capacity, "L");
            var model = {
                ref: ref,
                tank: {
                    capacity: capacity
                }
            };
            return model;
        }),
        visibleOnSession: true
    },
    self: {
        enabled: false,
        models: [],
        visibleOnSession: true
    }
};
