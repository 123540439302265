import { ClubDiveSite } from '@mabadive/app-common-model';
import { SortableAttribute } from '../../data';
import { dataSorter } from '../../data/sorter/dataSorter.service';
import { ClubDiveSiteSortByAttribute } from './ClubDiveSiteSortByAttribute.type';

export const clubDiveSitesSorter = {
  sortDiveSitesBy,
};

function sortDiveSitesBy<
  T extends Pick<ClubDiveSite, '_id' | 'ref' | 'name' | 'emojiId' | 'enabled'>,
>(
  diveSites: T[],
  {
    attributeName,
    asc,
  }: {
    attributeName: ClubDiveSiteSortByAttribute;
    asc: boolean;
  },
): T[] {
  const type = isFullText() ? 'full-text' : 'default';

  return dataSorter.sortMultiple(diveSites, {
    getSortAttributes: (x: T) => {
      const attributes: SortableAttribute[] = [];
      const value = getAttribute(x, { attributeName });
      if (value && attributeName === 'ref') {
        let valueAsInteger: number | string = parseInt(
          value as unknown as string,
          10,
        );
        if (isNaN(valueAsInteger)) {
          valueAsInteger = value;
        }
        attributes.push({
          value: valueAsInteger,
          type,
        });
      }
      attributes.push({
        value: value,
        type,
      });
      return attributes.concat([
        {
          value: x.name,
          type: 'full-text',
        },
        {
          value: x.ref,
          type: 'full-text',
        },
      ]);
    },
    asc,
  });

  function isFullText() {
    const textAttributes: ClubDiveSiteSortByAttribute[] = ['name', 'ref'];
    return textAttributes.includes(attributeName);
  }

  function getAttribute(
    x: Partial<T>,
    { attributeName }: { attributeName: ClubDiveSiteSortByAttribute },
  ) {
    return (x as any)[attributeName] as any;
  }
}
