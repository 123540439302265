import { ApolloQueryResult } from 'apollo-client';
import {
  Observable as ApolloLinkObservable,
  DocumentNode,
  FetchResult,
} from 'apollo-link';
import { OutgoingHttpHeaders } from 'http';
import { Observable, from, of, throwError } from 'rxjs';
import { catchError, retry, switchMap } from 'rxjs/operators';
import { libLogger } from '../../libLogger.service';
import { graphqlClientProvider } from '../graphqlClientProvider.service';
import { GraphqlClientQueryType } from './GraphqlClientQueryType.type';

export const rxApolloClient = {
  query,
  subscribe,
  fetch,
};

function fetch<T>(
  queryString: DocumentNode,
  {
    name = '',
    type,
    headers,
  }: {
    name: string;
    type: GraphqlClientQueryType;
    headers: OutgoingHttpHeaders;
  },
): Observable<T> {
  if (type === 'query') {
    return query<T>(queryString, { headers, name });
  } else {
    return subscribe<T>(queryString, { name });
  }
}

function query<T>(
  queryString: DocumentNode,
  { headers, name }: { headers: OutgoingHttpHeaders; name: string },
): Observable<T> {
  libLogger.info(
    `[graphql][rxApolloClient.query] RUN query "${name}"`,
    queryString,
  );

  return graphqlClientProvider.get().pipe(
    switchMap((client) => {
      const graphqlQueryResultPromise: Promise<ApolloQueryResult<T>> =
        client.query<T>({
          query: queryString,
          context: {
            headers,
          },
          fetchPolicy: 'no-cache',
        });
      return from(graphqlQueryResultPromise);
    }),
    switchMap((result) => {
      // libLogger.warn('[graphql][rxApolloClient.query] query results', result);
      if (result.errors && result.errors.length) {
        libLogger.error(
          `[graphql][rxApolloClient.query] "${name}" error fetching data`,
          result,
        );
        return throwError('Error fetching data').pipe();
      }
      return of(result.data);
    }),
    catchError((err) => {
      libLogger.warn(
        `[graphql][rxApolloClient.query] "${name}" error running query`,
        queryString,
        err,
      );
      return throwError(err);
    }),
  );
}

function subscribe<T>(
  queryString: DocumentNode,
  {
    name,
  }: {
    name: string;
  },
): Observable<T> {
  libLogger.info(
    `[graphql][rxApolloClient.subscribe] RUN subscription "${name}"`,
    queryString,
  );
  return graphqlClientProvider.get().pipe(
    switchMap((client) => {
      const apolloLinkObservable$: ApolloLinkObservable<FetchResult<T>> =
        client.subscribe<T>({
          query: queryString,
        });

      return new Observable<FetchResult<T>>((observer) => {
        const sub = apolloLinkObservable$.subscribe(observer);

        return () => {
          libLogger.warn(
            `[graphql][rxApolloClient.subscribe] UNSUBSCRIBE "${name}" 1`,
            sub,
          );
          if (!sub.closed) {
            libLogger.warn(
              `[graphql][rxApolloClient.subscribe] UNSUBSCRIBE "${name}" 2`,
            );
            sub.unsubscribe();
          }
        };
      });
    }),
    switchMap((result: FetchResult<T>) => {
      libLogger.info(
        `[graphql][rxApolloClient.subscribe] subscription "${name}" result:`,
        result,
      );
      return of(result.data);
    }),
    catchError((err) => {
      console.warn(
        `[graphql][rxApolloClient.subscribe]  error fetching data "${name}"`,
        err,
      );
      if (err.errors?.[0]?.message) {
        // eslint-disable-next-line no-console
        console.error('[ERROR] graphql error', err.errors?.[0]?.message);
      }
      libLogger.error(
        `[graphql][rxApolloClient.subscribe]  error fetching data "${name}"`,
        err,
      );
      if (err.message && err.message.indexOf('JWTExpired')) {
      }
      return throwError(err);
    }),
    retry(3),
  );
}
