import {
  AppInquiry,
  BookingMemberConfigInquiry,
  ClubDiver,
  DiveMode,
} from '../../../../app-common-model/src';
import { dateService } from '../../data';

export const appInquiryFilter = {
  findActiveInquiries,
};

function findActiveInquiries<
  T extends Pick<AppInquiry, '_id' | 'name' | 'settings'>,
>(
  allInquiries: T[],
  {
    diver,
    bookingMemberConfigInquiry,
    nextDiveModes,
  }: // previousDiveModes,
  {
    diver: Pick<ClubDiver, 'birthdate' | 'age'>;
    bookingMemberConfigInquiry: BookingMemberConfigInquiry;
    nextDiveModes: DiveMode[];
    // previousDiveModes: DiveMode[];
  },
): T[] {
  return (allInquiries ?? []).filter((inquiry) => {
    const settings = inquiry.settings;
    if (!settings?.enabled) {
      return false; // désactivé globalement
    }
    const bmInquiryConfig = bookingMemberConfigInquiry?.inquiries?.find(
      ({ appInquiryId, enabled }) => appInquiryId === inquiry._id,
    );
    if (bmInquiryConfig) {
      return bmInquiryConfig?.enabled; // la configuration manuelle est prioritaire, si définie
    }

    // on teste la configuration automatique
    const aa = settings.autoActivation;
    if (!aa?.enabled) {
      return false;
    }
    if (
      aa?.futurDiveModesEnabled &&
      aa?.diveModes?.length > 0 &&
      !haveIntersection(nextDiveModes, aa?.diveModes)
    ) {
      // diveMode non compatible
      // if (
      //   !aa?.diverWithoutAnyDiveEnabled ||
      //   nextDiveModes.length > 0
      //   // ||
      //   // previousDiveModes.length > 0
      // ) {
      // diverWithoutAnyDiveEnabled non activé ou au moins 1 plongée
      console.log('xxx filter out');
      return false;
      // }
    }

    if (aa?.age?.min > 0 || aa?.age?.max > 0) {
      // check future participant age
      const refDate = new Date();

      const age =
        diver && diver.birthdate
          ? dateService.getAge(diver.birthdate, refDate)
          : diver.age;

      if (!age) {
        return false; // age inconnu
      }
      if (aa.age.min > 0 && age < aa.age.min) {
        return false; // trop jeune
      }
      if (aa.age.max > 0 && age > aa.age.max) {
        return false; // trop vieux
      }
    }
    return true; // tous les critères sont OK: activation automatique
  });
}
function haveIntersection(arr1: string[], arr2: string[]): boolean {
  const set1 = new Set(arr1);
  for (const item of arr2) {
    if (set1.has(item)) {
      return true;
    }
  }
  return false;
}
