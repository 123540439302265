"use strict";
exports.__esModule = true;
exports.SESSION_TIDE_TAG = void 0;
exports.SESSION_TIDE_TAG = {
    reference: 'tide',
    avatar: {
        label: 'Ma',
        color: '#fff',
        background: '#1f358c'
    },
    content: {
        label: 'Marée',
        color: '#fff',
        background: '#1f358c'
    }
};
