import {
  ClubDiver,
  ClubParticipant,
  DiveSession,
} from '@mabadive/app-common-model';
import { dateService, nameFormatter } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';

export function ParticipantDiverNameLabel({
  diver,
  participant,
  displayAgeIfMinor,
  isCancelledLineThrough,
  diveSession,
  onClick,
  className,
}: {
  diver?: Pick<ClubDiver, 'firstName' | 'lastName' | 'fakeName' | 'birthdate'>;
  participant: Pick<
    ClubParticipant,
    'bookingState' | 'diveSessionGroupDiveGuide'
  >;
  diveSession: Pick<DiveSession, 'status' | 'time'>;
  displayAgeIfMinor?: boolean;
  isCancelledLineThrough?: boolean;
  onClick?: () => void;
  className?: string;
}) {
  const diveSessionStatus = useMemo(
    () =>
      participant && participant.bookingState.value === 'cancelled'
        ? 'cancelled'
        : diveSession.status,
    [diveSession.status, participant],
  );

  const age = useMemo(
    () =>
      diver && displayAgeIfMinor && diver.birthdate
        ? dateService.getAge(diver.birthdate, diveSession.time)
        : undefined,
    [displayAgeIfMinor, diveSession.time, diver],
  );

  const { firstName, lastName } = useMemo(
    () => nameFormatter.formatFullNameChunks(diver),
    [diver],
  );

  return (
    <div className={`${className ?? ''}`} onClick={onClick}>
      {diver && (firstName?.length > 0 || lastName?.length > 0) ? (
        <>
          <div
            className={`flex whitespace-nowrap flex flex-wrap items-center gap-1
            ${
              isCancelledLineThrough && diveSessionStatus === 'cancelled'
                ? 'text-gray-400 line-through'
                : ''
            }`}
          >
            {lastName?.length > 0 ? (
              <span className={'truncate font-bold'}>{lastName}</span>
            ) : null}
            <span className={'truncate flex-shrink'}>{firstName}</span>
            {displayAgeIfMinor && age > 0 && age < 18 && (
              <span className="text-xs italic">{`(${age} ans)`}</span>
            )}
            {participant.diveSessionGroupDiveGuide && (
              <span className="text-xs font-bold text-red-400">{'(GP)'}</span>
            )}
          </div>
        </>
      ) : (
        <span className="diver-name hidden">x</span>
      )}
    </div>
  );
}
