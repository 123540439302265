"use strict";
exports.__esModule = true;
exports.DIVE_SESSION_THEMES = void 0;
exports.DIVE_SESSION_THEMES = [
    'night',
    'whale',
    'turtle',
    'shark',
    'dolphin',
    'biology',
    'ccr',
    'photo',
    'tek',
    'afterwork',
    'private', // Sortie privée
];
