import { useMemo } from 'react';

export type AppDeviceOS = 'windows-phone' | 'android' | 'ios' | 'web';

export function useAppDevice(): {
  isMobile: boolean;
  deviceOS: AppDeviceOS;
  userAgent: string;
} {
  return useMemo(() => {
    const userAgent = getUserAgent();
    if (userAgent) {
      const deviceOS: AppDeviceOS = getMobileOperatingSystem({ userAgent });
      const isMobile = getIsMobile({ userAgent });
      return {
        isMobile,
        deviceOS,
        userAgent,
      };
    } else {
      return {
        isMobile: false,
        deviceOS: 'web' as AppDeviceOS,
        userAgent,
      };
    }
  }, []);
}

function getUserAgent(): string {
  const userAgent =
    navigator.userAgent || navigator.vendor || (window as any).opera;
  return userAgent;
}
function getIsMobile({ userAgent }: { userAgent: string }): boolean {
  return !!userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
}

function getMobileOperatingSystem({
  userAgent,
}: {
  userAgent: string;
}): AppDeviceOS {
  // https://stackoverflow.com/questions/21741841/detecting-ios-android-operating-system

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'windows-phone';
  }

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any)?.MSStream) {
    return 'ios';
  }

  return 'web';
}
