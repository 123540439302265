"use strict";
exports.__esModule = true;
exports.SCUBA_DIVING_INSTRUCTOR_LEVELS = void 0;
exports.SCUBA_DIVING_INSTRUCTOR_LEVELS = [
    'GP',
    'E1',
    'E2',
    'E3',
    'E4',
    'E5',
];
