import {
  AppEntityUpdateDescriptor,
  AppLogger,
} from '@mabadive/app-common-model';
import { jsonPatcherSmart } from '../json';
export const changeDescriptorAggregator = {
  aggregateMany,
};

function aggregateMany<T, K extends keyof T>(
  updateDescriptors: AppEntityUpdateDescriptor<T>[] = [],
  {
    pk,
    initials = [],
    ignoreErrors = false,
    appLogger,
    logPrefix,
  }: {
    pk: K;
    initials?: T[];
    ignoreErrors?: boolean;
    appLogger?: AppLogger;
    logPrefix?: string;
  },
): T[] {
  let allItems: T[] = initials ? [...initials] : [];

  if (!updateDescriptors) {
    return allItems;
  }

  updateDescriptors.forEach((updateDescriptor) => {
    if (updateDescriptor.original) {
      const item = updateDescriptor.original;
      // append original added item
      if (!allItems.some((i) => i[pk] === item[pk])) {
        // si il y a des "initials", ça peut faire des doublons sinon
        allItems.push(item);
      }
    }

    if (updateDescriptor.created) {
      const item = updateDescriptor.created;
      if (!allItems.some((i) => i[pk] === item[pk])) {
        // append created item only if not already in state (NOTE: sinon on avait parfois un bug d'affichage lors de l'enregistrement d'une résa avec des nouvelles sessions)
        allItems.push(item);
      }
    }

    if (updateDescriptor.updated) {
      const patch = updateDescriptor.updated;
      allItems = allItems.map((item) => {
        if (item[pk] === (patch.pk as any)) {
          item = jsonPatcherSmart.applySmartPatchOperations(item, {
            patchOperations: patch.patchOperations,
            logPrefix,
            ignoreErrors,
            appLogger,
          }).output;
        }
        return item;
      });
    }
  });

  const deletedIds = updateDescriptors
    .filter((x) => x.deleted)
    .map((x) => x.pk);

  if (deletedIds.length) {
    const filtered = allItems.filter(
      (x) => !deletedIds.includes(x[pk] as unknown as string),
    );
    return filtered;
  }

  return allItems;
}
