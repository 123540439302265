import { ColoredTag } from '@mabadive/app-common-model';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { ColoredTagAvatarCardSize, ColoredTagCard } from 'src/business/_core/modules/layout';
import { useCacheableClasses } from 'src/business/_core/modules/root/theme';

export const ColoredTagCardBar = ({
  tags,
  avatarSize,
  avatarOnly,
  className,
}: {
  tags: ColoredTag[];
  avatarSize?: ColoredTagAvatarCardSize;
  avatarOnly?: boolean;
  className?: string;
}) => {


  const classes = useCacheableClasses({
    cacheKey: 'ColoredTagCardBar',
    buildStyles,
  });

  return tags.length ?
    <div key="tags" className={`${className ? className : ''} ${classes.root}`}>
      {tags.map((tag, i) => (
        <ColoredTagCard key={`${i}`} className="tag-bar-item" tag={tag} avatarSize={avatarSize} avatarOnly={avatarOnly} />
      ))}
    </div>
    : null;
}


function buildStyles(theme: Theme) {
  return makeStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: -theme.spacing(0.5), 
      [theme.breakpoints.only('xs')]: {
        marginTop: 0,
      },
      '& .tag-bar-item': {
        marginTop: theme.spacing(0.5),
        '&:not(:last-child)': {
          marginRight: theme.spacing(0.5),
        },
      },
    },
  });
}