"use strict";
exports.__esModule = true;
exports.APP_ENV_IDS = void 0;
exports.APP_ENV_IDS = [
    'dev',
    'dev-ip',
    'alpha',
    'next',
    'prod',
    'localtest',
    'demo468',
    'demo976',
    'secours',
];
