import React, { FC } from 'react';

export const AppFormAutoSubmit: FC<{
  onSubmit?: () => void;
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
}> = ({ children, onSubmit, className }) => {
  return (
    <form
      className={className}
      noValidate
      onSubmit={(event) => {
        event.preventDefault();
        if (onSubmit) {
          onSubmit();
        }
        return false;
      }}
    >
      {children}
      <input type="submit" hidden></input>
    </form>
  );
};
