import { APP_COLORS } from '@mabadive/app-common-model';
import { Box, Button, Paper, Theme, Typography } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { AppIconsMaterial } from '../../../layout';
import { useGlobalClasses } from '../../theme';

/**
 * @deprecated use AppLoader
 */
export const LoadingProgressBar: FC<{
  hasError?: boolean;
  children?: React.ReactNode;
}> = ({ hasError, children }) => {
  const classes = useStyles({});

  const [completed, setCompleted] = React.useState(10);

  React.useEffect(() => {
    let timer: NodeJS.Timeout;

    function cleanup(timer: NodeJS.Timeout) {
      // appLogger.debug('[LoadingProgressBar] cleanup', timer)
      if (timer) {
        clearInterval(timer);
        timer = undefined;
      }
    }

    function progress() {
      setCompleted((oldCompleted) => {
        if (oldCompleted === 100) {
          return 10;
        }
        const remaining = 100 - oldCompleted;
        const diff = (Math.random() * 10 * remaining) / 100;
        const newCompleted = Math.min(oldCompleted + diff, 100);
        // appLogger.debug('[LoadingProgressBar] newCompleted:', newCompleted)
        return newCompleted;
      });
    }

    if (!hasError) {
      cleanup(timer);
      timer = setInterval(progress, 100);
      return () => {
        // appLogger.debug('[LoadingProgressBar] clean (hasError)', timer)
        return cleanup(timer);
      };
    }
    return () => {
      // appLogger.debug('[LoadingProgressBar] clean', timer)
      return cleanup(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError]);

  const globalClasses = useGlobalClasses();

  return completed ? null : (
    <Paper className={classes.root}>
      {hasError ? (
        <Typography variant="h6" className={classes.errorMessage}>
          Erreur innatendue. Veuillez vérifier votre connexion Internet et
          ré-essayer. Si cela persiste, merci de nous contacter.
        </Typography>
      ) : (
        <Typography variant="h6" className={classes.message}>
          {children ? children : 'Chargement en cours...'}
        </Typography>
      )}
      <Box mt={2}>
        <LinearProgress variant="determinate" value={completed} />
        <LinearProgress color="secondary" variant="determinate" value={100} />
      </Box>
      {hasError ? (
        <Box mt={6}>
          <Box
            mt={4}
            flexGrow={1}
            display="flex"
            flexDirection="row"
            justifyContent="space-around"
          >
            <Button
              startIcon={<AppIconsMaterial.refresh />}
              variant="outlined"
              tabIndex={500}
              className={globalClasses.buttonRemove}
              onClick={() => refreshPage()}
            >
              Rafraîchir la page
            </Button>
          </Box>
        </Box>
      ) : null}
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: '10%',
    marginLeft: '10%',
    marginRight: '10%',
    padding: '10% 5%',
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
  },
  message: {
    marginBottom: '30px',
  },
  errorMessage: {
    color: APP_COLORS.red,
  },
}));

function refreshPage() {
  window.location.reload();
}
