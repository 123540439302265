"use strict";
exports.__esModule = true;
exports.CLUB_DIVE_SITE_TYPES = void 0;
exports.CLUB_DIVE_SITE_TYPES = [
    'sea',
    'ocean',
    'lagoon',
    'lake',
    'pond',
    'basin',
    'bay',
    'river',
    'pass',
    'cave',
    'quarry',
    'pool',
    'pit', // fosse
];
