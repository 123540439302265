/* eslint-disable @typescript-eslint/no-unused-vars */
import { DiverBookingUpdatePayload } from '@mabadive/app-common-model';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { diverBookingUpdatorClient } from 'src/business/_core/data/app-operation';
import {
  DiveCenterBookingPageAggregatedBookingResume,
  DiveCenterBookingPageUpdateState,
} from '../DiveCenterBookingPage/model';
import { DiveCenterBookingMemberEditFormModel } from './panels/BookingCheckoutPanel2EditMembers/model/DiveCenterBookingMemberEditFormModel.type';
import { bookingCheckoutFormUpdateBuilder } from './services';

export type DiveCenterBookingCheckoutSaveDraftChanges = ReturnType<
  typeof useDiveCenterBookingCheckoutSaveDraftChanges
>;

export function useDiveCenterBookingCheckoutSaveDraftChanges({
  updateState: initialUpdateState,
  setUpdateState,
  aggregatedBookingResume,
  initialFormValue,
}: {
  updateState: DiveCenterBookingPageUpdateState;
  setUpdateState: Dispatch<SetStateAction<DiveCenterBookingPageUpdateState>>;
  aggregatedBookingResume: DiveCenterBookingPageAggregatedBookingResume;
  initialFormValue: DiveCenterBookingMemberEditFormModel;
}) {
  const saveDraftChanges = useCallback(
    async ({
      formValue,
      isDraft,
    }: {
      formValue: DiveCenterBookingMemberEditFormModel;
      isDraft: boolean;
    }) => {
      const updateState = bookingCheckoutFormUpdateBuilder.buildChanges({
        formValue,
        aggregatedBookingResume,
        updateState: initialUpdateState,
        initialFormValue,
      });

      const payload: DiverBookingUpdatePayload = {
        updateState,
        isDraft,
      };
      setUpdateState(updateState);
      await diverBookingUpdatorClient.update(payload);
    },
    [
      aggregatedBookingResume,
      initialFormValue,
      initialUpdateState,
      setUpdateState,
    ],
  );

  return saveDraftChanges;
}
