"use strict";
exports.__esModule = true;
exports.SCUBA_DIVING_INSTRUCTOR_DEGREE_TEMPLATES = void 0;
exports.SCUBA_DIVING_INSTRUCTOR_DEGREE_TEMPLATES = [
    {
        level: 'GP',
        name: 'N4'
    },
    {
        level: 'GP',
        name: 'N5'
    },
    {
        level: 'E1',
        name: 'INITIATEUR'
    },
    {
        level: 'E2',
        name: 'INITIATEUR'
    },
    {
        level: 'E2',
        name: 'BPJEPS'
    },
    {
        level: 'E2',
        name: 'BPP GAPPN'
    },
    {
        level: 'E3',
        name: 'MF1'
    },
    {
        level: 'E3',
        name: 'MF1A'
    },
    {
        level: 'E3',
        name: 'BEES1'
    },
    {
        level: 'E3',
        name: 'DEJEPS'
    },
    {
        level: 'E4',
        name: 'MF2'
    },
    {
        level: 'E4',
        name: 'MF2A'
    },
    {
        level: 'E4',
        name: 'BEES2'
    },
    {
        level: 'E4',
        name: 'DEJEPS'
    },
    {
        level: 'E4',
        name: 'DESJEPS'
    },
    {
        level: 'E1',
        name: 'INSTRUCTEUR'
    },
    {
        level: 'E2',
        name: 'INSTRUCTEUR'
    },
    {
        level: 'E3',
        name: 'INSTRUCTEUR'
    },
    {
        level: 'E4',
        name: 'INSTRUCTEUR'
    },
    {
        level: 'E1',
        name: 'SSI'
    },
    {
        level: 'E2',
        name: 'SSI'
    },
    {
        level: 'E3',
        name: 'SSI'
    },
    {
        level: 'E4',
        name: 'SSI'
    },
    {
        level: 'E1',
        name: 'PADI'
    },
    {
        level: 'E2',
        name: 'PADI'
    },
    {
        level: 'E3',
        name: 'PADI'
    },
    {
        level: 'E4',
        name: 'PADI'
    },
    {
        level: 'E1',
        name: 'TDI-SDI'
    },
    {
        level: 'E2',
        name: 'TDI-SDI'
    },
    {
        level: 'E3',
        name: 'TDI-SDI'
    },
    {
        level: 'E4',
        name: 'TDI-SDI'
    },
    {
        level: 'E1',
        name: 'FFESSM'
    },
    {
        level: 'E2',
        name: 'FFESSM'
    },
    {
        level: 'E3',
        name: 'FFESSM'
    },
    {
        level: 'E4',
        name: 'FFESSM'
    },
    {
        level: 'E1',
        name: 'CMAS'
    },
    {
        level: 'E2',
        name: 'CMAS'
    },
    {
        level: 'E3',
        name: 'CMAS'
    },
    {
        level: 'E4',
        name: 'CMAS'
    },
    {
        level: 'E1',
        name: 'ANMP'
    },
    {
        level: 'E2',
        name: 'ANMP'
    },
    {
        level: 'E3',
        name: 'ANMP'
    },
    {
        level: 'E4',
        name: 'ANMP'
    },
];
