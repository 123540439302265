"use strict";
exports.__esModule = true;
exports.TRAINING_TYPES = void 0;
exports.TRAINING_TYPES = [
    'main-certification',
    'gaz-certification',
    'security-certification',
    'equipment-certification',
    'child',
];
