import {
  CLUB_STAFF_MEMBER_DAILY_AVAILABILITY_PERIODS_AVAILABLE,
  ClubStaffMember,
  ClubStaffMemberRole,
  DiveCenterDailyConfig,
  DiveCenterDailyConfigStaffMember,
  StaffMemberDailyAvailabilityResume,
} from '@mabadive/app-common-model';
import { staffMemberDefaultAvailabilityFinder } from './staffMemberDefaultAvailabilityFinder.service';

export const staffMemberAvailabilityFinder = {
  getDailyAvailabilityResume,
};

function getDailyAvailabilityResume({
  date = new Date(),
  staffMember,
  dailyConfig,
}: {
  date: Date;
  staffMember: Pick<ClubStaffMember, '_id' | 'profile' | 'availabilities'>;
  dailyConfig: DiveCenterDailyConfig;
}): StaffMemberDailyAvailabilityResume {
  const availableRoles: ClubStaffMemberRole[] =
    staffMember.profile?.roles ?? [];

  // recherche de la config par défaut
  const defaultAvailability: StaffMemberDailyAvailabilityResume =
    staffMemberDefaultAvailabilityFinder.getDefaultDailyAvailability(
      staffMember,
      {
        date,
      },
    );

  // recherche d'une daily config
  const staffMemberDailyConfig: DiveCenterDailyConfigStaffMember =
    getStaffMemberDailyConfig({
      dailyConfig,
      staffMemberId: staffMember._id,
    });
  if (staffMemberDailyConfig) {
    const isAvailableToday =
      CLUB_STAFF_MEMBER_DAILY_AVAILABILITY_PERIODS_AVAILABLE.includes(
        staffMemberDailyConfig.availabilityPeriod,
      );
    const result: StaffMemberDailyAvailabilityResume = {
      isAvailableOnPeriod:
        (isAvailableToday || defaultAvailability?.isAvailableOnPeriod) ?? false,
      isAvailableToday,
      comment: staffMemberDailyConfig.comment,
      availableRoles, // pour le moment, les roles sont globaux, pas de surcharge par période par défaut ni par jour
      jobType: defaultAvailability?.jobType,
      defaultWorkingDays: defaultAvailability?.defaultWorkingDays,
      availabilityPeriod: staffMemberDailyConfig.availabilityPeriod,
    };
    return result;
  }

  return defaultAvailability;
}
function getStaffMemberDailyConfig({
  staffMemberId,
  dailyConfig,
}: {
  staffMemberId: string;
  dailyConfig: DiveCenterDailyConfig;
}): DiveCenterDailyConfigStaffMember {
  const staffMemberDailyConfig: DiveCenterDailyConfigStaffMember =
    dailyConfig?.staffMembers.find((x) => x.staffMemberId === staffMemberId);

  return staffMemberDailyConfig;
}

// function getSessionRoles({
//   session1,
//   session2,
// }: {
//   session1: DiveSessionStaffMemberTableModelStaffMemberSession;
//   session2: DiveSessionStaffMemberTableModelStaffMemberSession;
// }): ClubStaffMemberRole[] {
//   const roles: ClubStaffMemberRole[] = [];
//   if (session1?.isSurfaceSecurity || session2?.isSurfaceSecurity) {
//     roles.push('surface-security');
//   }
//   if (session1?.isInstructor || session2?.isInstructor) {
//     roles.push('scuba-diving-instructor');
//   }
//   if (session1?.isDivingDirector || session2?.isDivingDirector) {
//     roles.push('diving-director');
//   }
//   return roles;
// }

// function isSessionInUse({
//   session1,
//   session2,
// }: {
//   session1: DiveSessionStaffMemberTableModelStaffMemberSession;
//   session2: DiveSessionStaffMemberTableModelStaffMemberSession;
// }) {
//   return getSessionRoles({ session1, session2 }).length > 0;
// }

// function getOnActivePeriod(
//   staffMember: Pick<ClubStaffMember, 'availabilities'>,
//   {
//     date = new Date(),
//   }: {
//     date?: Date;
//   } = {
//     date: new Date(),
//   },
// ): ClubStaffMemberAvailability {
//   return staffMember.availabilities.find(
//     (x) =>
//       dateService.isBefore(x.beginDate, date) &&
//       (!x.endDate || dateService.isBefore(date, x.endDate)),
//   );
// }

// function getOnActiveToday(
//   staffMember: Pick<ClubStaffMember, 'availabilities'>,
//   {
//     date = new Date(),
//   }: {
//     date?: Date;
//   } = {
//     date: new Date(),
//   },
// ): ClubStaffMemberAvailability {
//   const todayIso = dateService.getUTCWeekDayIso(date);
//   const availability = getDefaultAvailabilityToday(staffMember, { date });
//   if (availability.defaultWorkingIsoDays.includes(todayIso)) {
//     return availability;
//   }
// }

// function getDefaultAvailabilityToday(
//   staffMember: Pick<ClubStaffMember, 'availabilities'>,
//   {
//     date = new Date(),
//   }: {
//     date?: Date;
//   } = {
//     date: new Date(),
//   },
// ): ClubStaffMemberAvailability {
//   // TODO: quand il y aura plusieurs périodes, il faudra améliorer ce service pour ne prendre que la plus proche (comme poru le p)
//   return staffMember.availabilities.find(
//     (x) =>
//       dateService.isBefore(x.beginDate, date) &&
//       (!x.endDate || dateService.isBefore(date, x.endDate)),
//   );
// }
