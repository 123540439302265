import React from 'react';
import {
  AppBreadcrumbBar,
  AppButton,
  AppIconsAction,
  AppMessageLight,
  AppPageContainerWithFixedBars,
} from 'src/business/_core/modules/layout';
import { useAppSecurityUser } from 'src/business/auth/services';
import { CUS_BookingMemberFull } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/models';
import { DiveCenterBookingMembersList } from '../../../DiveCenterBookingPage/tabs/DiveCenterBookingPageTab2BookingParticipants/DiveCenterBookingMembersList';
import { BookingResumeMembersTableOnClickMemberAction } from '../../../DiveCenterBookingPage/tabs/DiveCenterBookingPageTab2BookingParticipants/DiveCenterBookingMembersList/DiveCenterBookingMembersListItem';
import { DiveCenterBookingCheckoutPageLocalState } from '../../useDiveCenterBookingCheckoutPageLocalState.hook';

export const BookingCheckoutEditMemberPreviewPanel = ({
  localState,
}: {
  localState: DiveCenterBookingCheckoutPageLocalState;
}) => {
  const securityUser = useAppSecurityUser();

  const {
    loadableContent,
    aggregatedBookingResume,
    clubSettings,
    setViewState,
    breadcrumbItems,
    actions,
  } = localState;
  const { diveCenter, links, checkoutState } = aggregatedBookingResume;

  const diveCenterPublicData = diveCenter?.publicData;
  const clubPublicData = clubSettings?.publicData;
  const clubPublicSettings = clubSettings?.publicSettings;

  return (
    <AppPageContainerWithFixedBars
      contentState={loadableContent.contentState}
      lastActionStatus={loadableContent.lastActionStatus}
      marginBottom={true}
      paddingBottom={true}
      contentClassName="bg-gray-50 app-p-content"
      headerBar={() => (
        <>
          <AppBreadcrumbBar
            className="my-4"
            color={'#333'}
            items={breadcrumbItems}
          />
          {localState.az?.view?.otherParticipantsDetails && (
            <h3 className="text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
              Liste des participants
            </h3>
          )}
        </>
      )}
      footerBar={() => (
        <div className="mt-8 mb-2 mx-4 flex flex-col-reverse items-stretch md:flex-row gap-4 md:gap-8 justify-start md:justify-center md:items-start">
          {checkoutState.customerUpdateState.enableUpdate && (
            <AppButton
              type="button"
              className="text-sm md:text-base font-bold uppercase"
              size="normal"
              fullWidth={false}
              icon={AppIconsAction.confirm}
              // iconPosition="right"
              color="gray-outline-light"
              onClick={() => {
                setViewState({
                  mode: 'checkout-edit-members',
                  participantsIndex: 0,
                });
              }}
            >
              Modifier
            </AppButton>
          )}
          <AppButton
            type="button"
            className="text-sm md:text-base font-bold uppercase"
            size="normal"
            icon={AppIconsAction.confirm}
            // iconPosition="right"
            color="primary-bg"
            onClick={() => {
              setViewState({
                mode: 'checkout-edit-member-submit',
                participantsIndex: 0,
              });
            }}
          >
            Confirmer
          </AppButton>
        </div>
      )}
    >
      <div className="app-card app-p-content grid gap-4">
        <DiveCenterBookingMembersList
          enableCustomerUpdate={true}
          aggregatedBookingResume={aggregatedBookingResume}
          onClick={(
            bookingMembersFull: CUS_BookingMemberFull,
            options: {
              action: BookingResumeMembersTableOnClickMemberAction;
              participantsIndex: number;
            },
          ) => {
            actions.editParticipantByIndex(options.participantsIndex);
          }}
        />
        {checkoutState.customerUpdateState.status === 'draft' ? (
          <AppMessageLight type="warn" className="my-2">
            <p className="font-bold text-status-warn">
              Vous n'avez pas confirmé vos modifications!
            </p>
            <p>
              Veuillez cliquer sur le bouton "Valider mes modifications"
              ci-dessous.
            </p>
          </AppMessageLight>
        ) : checkoutState.customerUpdateState.status === 'ko' ? (
          <AppMessageLight type="danger" className="my-2">
            <p className="font-bold text-status-error">
              Vous n'avez effectué aucune modification!
            </p>
            <p>
              Veuillez cliquer sur le bouton "Modifier la fiche des
              participants" ci-dessous.
            </p>
          </AppMessageLight>
        ) : null}
      </div>
    </AppPageContainerWithFixedBars>
  );
};
