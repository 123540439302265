import { Observable, of } from 'rxjs';
import { catchError, first, map, startWith } from 'rxjs/operators';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { LoadableContentPartial } from '../loadable';
import { AppLoaderType } from './AppLoaderType.type';

export const appLoader = { load };

function load<T>(
  obs$: Observable<T>,
  { type, defaultValue, isSubscription }: { type: AppLoaderType; defaultValue?: T; isSubscription?: boolean },
): Observable<LoadableContentPartial<T>> {
  if (!isSubscription) {
    // il faut impérativement qu'il retourne seulement 1 résultat, sinon il ne sera pas chainable (appLoaderChain attendra qu'il termine)
    obs$ = obs$.pipe(first());
  }
  return obs$.pipe(
    map((content) => {
      const lastActionStatus = type === 'full' ? 'success' : 'in-progress';
      if (content) {
        const contentState = type === 'full' ? 'full' : 'partial';
        const res: LoadableContentPartial<T> = {
          content,
          contentState,
          lastActionStatus,
        };
        return res;
      } else {
        return {
          content: defaultValue,
          contentState: 'none',
          lastActionStatus,
        } as LoadableContentPartial<T>;
      }
    }),
    catchError((err) => {
      appLogger.warn('[appLoader] error loading data', err);
      return of({
        content: defaultValue,
        contentState: 'none',
        lastActionStatus: 'error',
      } as LoadableContentPartial<T>);
    }),
    startWith({
      content: defaultValue,
      contentState: 'none',
      lastActionStatus: 'in-progress',
    } as LoadableContentPartial<T>),
  );
}
