"use strict";
exports.__esModule = true;
exports.BOOKING_GROUP_TYPES = void 0;
exports.BOOKING_GROUP_TYPES = [
    'individual',
    'club',
    'association',
    'collectivity',
    'company',
];
