import { dateService } from '@mabadive/app-common-services';
import { NEVER, Observable, of } from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  retryWhen,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  AppAuth,
  AppSecurityUser,
  authenticationClient,
  AuthenticationClientError,
  authenticationStore,
} from 'src/business/auth/services';
import { rxjsUtilWeb } from 'src/lib/reactive';
import { appLogger } from '../logger/appLogger.service';
import { refreshTokenTimeChecker } from './refreshTokenTimeChecker.service';

export const refreshTokenManager = {
  manageRefreshToken,
};

function manageRefreshToken() {
  appLogger.debug('[refreshTokenManager.manageRefreshToken]');

  return authenticationStore.auth.get().pipe(
    switchMap((auth: AppAuth) => {
      if (auth && auth.user) {
        appLogger.info(
          `[refreshTokenManager.manageRefreshToken] auth user: (${formatSecurityUserForLogs(
            auth.user,
          )})`,
        );
        return refreshUserTokenPeriodic(auth);
      } else {
        appLogger.warn(
          '[refreshTokenManager.manageRefreshToken] user not authenticated: stop refresh',
        );
        return NEVER;
      }
    }),
    catchError((err) => {
      appLogger.error(
        '[refreshTokenManager.manageRefreshToken] token refresh fatal error',
        err,
      );
      logout();
      return NEVER;
    }),
  );
}

function refreshUserTokenPeriodic(auth: AppAuth): Observable<AppSecurityUser> {
  return of(auth.user).pipe(
    distinctUntilChanged(
      (x, y) => x.tokenInfo?.expirationDate === y.tokenInfo?.expirationDate,
    ),
    delay(30000), // on attend 30s après le chargement de l'appli, sinon ça rentre en conflit avec le logout, et le token est forcé
    tap((user) =>
      appLogger.debug(
        `[refreshTokenManager.manageRefreshToken] triggerNextRefresh (${formatSecurityUserForLogs(
          user,
        )})`,
      ),
    ),
    switchMap((user) =>
      triggerNextRefresh({ user }).pipe(
        retryWhen((err$: Observable<AuthenticationClientError>) =>
          manageRefreshRetryOnError(err$),
        ),
      ),
    ),
  );
}

function manageRefreshRetryOnError(
  err$: Observable<AuthenticationClientError>,
): Observable<any> {
  return err$.pipe(
    switchMap((err: AuthenticationClientError) => {
      if (err && err.status === 401) {
        appLogger.warn(
          '[refreshTokenManager.manageRefreshToken] security error: logout',
        );
        logout();
        return NEVER;
      }
      appLogger.warn('[refreshTokenManager.manageRefreshToken] err', err);
      return of(true).pipe(delay(4000));
    }),
  );
}

function triggerNextRefresh({ user }: { user: AppSecurityUser }) {
  const { isValid, delayBeforeRefreshMs } =
    refreshTokenTimeChecker.checkTokenValidityDelay({
      expirationDate: user.tokenInfo?.expirationDate,
      issueDate: user.tokenInfo?.issueDate,
      minRemainingTimeMs: 1000,
    });

  if (isValid) {
    appLogger.debug(
      `[refreshTokenManager.triggerNextRefresh] delayBeforeRefreshMs: ${delayBeforeRefreshMs}`,
    );

    const triggerDelay = delayBeforeRefreshMs > 1000 ? delayBeforeRefreshMs : 0;

    // NOTE: si le délai est trop long, la fonction est buggée: https://github.com/ReactiveX/rxjs/issues/3015#issuecomment-340654132=
    const triggerOnRefreshTime$ = rxjsUtilWeb.timerLongInteger(triggerDelay);

    return triggerOnRefreshTime$.pipe(
      tap(() =>
        appLogger.warn(
          `[refreshTokenManager.triggerNextRefresh] refresh token now (${formatSecurityUserForLogs(
            user,
          )})`,
        ),
      ),
      switchMap(() => authenticationClient.refreshToken()),
    );
  } else {
    appLogger.warn(
      '[refreshTokenManager.triggerNextRefresh] token expired: stop to refresh',
    );
    logout();
    return NEVER;
  }
}

function logout() {
  authenticationStore.logoutRequired.set(true);
}

function formatDateForLogs(date: Date) {
  return dateService.formatLocal(date, 'HH:mm:ss');
}

function formatSecurityUserForLogs(user: AppSecurityUser) {
  return `issueDate=${formatDateForLogs(
    user.tokenInfo?.issueDate,
  )}, expirationDate=${formatDateForLogs(
    user.tokenInfo?.expirationDate,
  )}, now=${formatDateForLogs(new Date())}, userId=${user.userId}`;
}
