/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppDocUploadContext,
  AppLanguageCode,
  Booking,
  Customer_ClubSettingsPublicCustomer,
} from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useAutoFocus } from 'src/business/_core/modules/layout';
import { useAppSecurityUser } from 'src/business/auth/services';
import {
  AppInputImageRHF,
  AppInputRHF,
  AppSingleAutocomplete2RHF,
} from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';
import { useLanguagesOptions } from 'src/pages';
import { AppInputDatePickerMaterialRHF } from 'src/stories/components/04-form';
import { DiveCenterBookingMemberEditFormModel } from '../model';
import { DiveCenterBookingMemberEditFormAddress } from './DiveCenterBookingMemberEditFormAddress';

export const DiveCenterBookingMemberEditFormContact = ({
  form,
  booking,
  participantsIndex,
  clubSettings,
  onLanguageCodeUpdated,
  className = '',
}: {
  form: UseFormReturn<DiveCenterBookingMemberEditFormModel>;
  booking: Booking;
  participantsIndex: number;
  clubSettings: Customer_ClubSettingsPublicCustomer;
  onLanguageCodeUpdated: (
    languageCode: AppLanguageCode,
    {
      participantIndex,
    }: {
      participantIndex: number;
    },
  ) => void;
  className?: string;
}) => {
  const securityUser = useAppSecurityUser();

  const { control, setValue } = form;

  const baseFormName = `participants.${participantsIndex}` as const;

  const [birthdate, diverId, bookingMemberId] = useWatch({
    control,
    name: [
      `${baseFormName}.diver.birthdate`,
      `${baseFormName}.diver._id`,
      `${baseFormName}.bookingMember._id`,
    ],
  });

  const autoFocus = useAutoFocus();

  const pendingList = booking.bookingCustomerConfig?.pendingList;
  const displayFields =
    clubSettings?.publicSettings?.customerSpace?.displayFields;

  const languagesOptions = useLanguagesOptions({
    scope: 'all',
  });
  const profilePhotoUploadContext = useMemo(() => {
    const context: AppDocUploadContext = {
      clubDiverId: diverId,
      bookingId: booking?._id,
      bookingMemberId: bookingMemberId,
      appBookletPageId: undefined,
      fileType: 'image',
      scope: 'diver',
      isPublic: false,
      docRef: 'personal-photo',
      status: 'submitted',
    };
    return context;
  }, [booking?._id, bookingMemberId, diverId]);

  return (
    <div className={className}>
      {pendingList?.diversContactInfo && (
        <div
          className={
            'w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4 gap-4'
          }
        >
          <AppFormControlRHF
            className={'md:col-span-2 w-full'}
            label="Prénom"
            control={control}
            required={
              displayFields?.diversContactInfo?.firstName?.edit &&
              displayFields?.diversContactInfo?.firstName?.required
            }
            name={`${baseFormName}.diver.firstName`}
            renderComponent={(props) =>
              displayFields?.diversContactInfo?.firstName?.edit ? (
                <AppInputRHF
                  {...props}
                  fullWidth
                  type="text"
                  autoFocus={autoFocus}
                />
              ) : (
                <div className="text-gray-600 font-medium">{props.value}</div>
              )
            }
          />
          <div className="md:col-span-2 w-full flex flex-col xs:flex-row gap-4">
            <AppFormControlRHF
              className={'md:col-span-2 w-full'}
              label="Nom"
              required={
                displayFields?.diversContactInfo?.lastName?.edit &&
                displayFields?.diversContactInfo?.lastName?.required
              }
              control={control}
              name={`${baseFormName}.diver.lastName`}
              renderComponent={(props) =>
                displayFields?.diversContactInfo?.lastName?.edit ? (
                  <AppInputRHF
                    {...props}
                    fullWidth
                    type="text"
                    autoFocus={autoFocus}
                  />
                ) : (
                  <div className="text-gray-600 font-medium">{props.value}</div>
                )
              }
            />

            {displayFields?.diversContactInfo?.profilePhoto?.edit && (
              <AppInputImageRHF
                required={
                  displayFields?.diversContactInfo?.profilePhoto?.required
                }
                previewImageClassName="h-[30px]"
                label="Photo"
                height={400}
                width={400}
                control={control}
                setValue={setValue}
                appDocUploadContext={profilePhotoUploadContext}
                name={`${baseFormName}.diver.details.profilePhoto`}
              />
            )}
          </div>
          {displayFields?.diversContactInfo?.phoneNumber?.edit && (
            <AppFormControlRHF
              className={'md:col-span-2 w-full flex gap-4'}
              label="Téléphone portable"
              control={control}
              required={displayFields?.diversContactInfo?.phoneNumber?.required}
              name={`${baseFormName}.diver.phoneNumber`}
              renderComponent={(props) => (
                <AppInputRHF {...props} fullWidth type="text" />
              )}
            />
          )}
          {displayFields?.diversContactInfo?.emailAddress?.edit && (
            <AppFormControlRHF
              className={'md:col-span-2 w-full'}
              label="Adresse e-mail"
              control={control}
              required={
                displayFields?.diversContactInfo?.emailAddress?.required
              }
              validation={{
                email: true,
              }}
              name={`${baseFormName}.diver.emailAddress`}
              renderComponent={(props) => (
                <AppInputRHF {...props} fullWidth type="text" />
              )}
            />
          )}
          {displayFields?.diversContactInfo?.birthdate?.edit && (
            <AppFormControlRHF
              className="md:col-span-2 w-full"
              label="Date de naissance"
              control={control}
              required={displayFields?.diversContactInfo?.birthdate?.required}
              name={`${baseFormName}.diver.birthdate`}
              renderComponent={(props) => (
                <AppInputDatePickerMaterialRHF
                  initialView={birthdate ? 'weeks' : 'years'}
                  maxYear={new Date().getFullYear()}
                  {...props}
                  className="w-full"
                />
              )}
            />
          )}
          {displayFields?.diversContactInfo?.profilePhoto?.edit && (
            <AppFormControlRHF
              className={'md:col-span-2 w-full'}
              label="Langue"
              control={control}
              required={
                displayFields?.diversContactInfo?.profilePhoto?.required
              }
              name={`${baseFormName}.diver.languageCode`}
              renderComponent={(props) => (
                <AppSingleAutocomplete2RHF
                  {...props}
                  options={languagesOptions}
                  fullWidth
                  onChange={(languageCode) => {
                    if (languageCode) {
                      onLanguageCodeUpdated(languageCode, {
                        participantIndex: participantsIndex,
                      });
                    }
                  }}
                />
              )}
            />
          )}
        </div>
      )}
      {pendingList?.diversContactInfo &&
        displayFields?.diversContactInfo?.emergencyContact?.edit && (
          <div className={'mt-2 w-full max-w-[97vw] flex flex-col'}>
            <AppFormControlRHF
              className={'md:col-span-4 w-full'}
              label="Personne à contacter en cas d'urgence (Nom + tél)"
              control={control}
              required={
                displayFields?.diversContactInfo?.profilePhoto?.required
              }
              name={`${baseFormName}.diver.emergencyContact`}
              renderComponent={(props) => (
                <AppInputRHF
                  {...props}
                  fullWidth
                  type="text"
                  multiline
                  rowsMax={15}
                  rows={2}
                />
              )}
            />
          </div>
        )}
      {pendingList?.diversPostalAddressInfo && (
        <DiveCenterBookingMemberEditFormAddress
          key={participantsIndex}
          className="mt-2"
          form={form}
          participantsIndex={participantsIndex}
          clubSettings={clubSettings}
        />
      )}
    </div>
  );
};
