import { Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { AppAuth, authenticationStore } from 'src/business/auth/services';
import { diveCenterBookingUrlBuilder } from 'src/business/dive-center/BO-booking/diveCenterBookingUrlBuilder.service';
import { appLogger } from '../../logger';

export const appRouteBuilder = {
  getContactRoute,
  getDefaultRoute,
  getDefaultRouteFromAuth,
  getResetPasswordQueryUrl,
  getLoginUrl,
  getSigningUrl,
};

function getContactRoute() {
  return '/club/contact';
}
function getResetPasswordQueryUrl() {
  return '/auth/reset/password/query';
}
function getLoginUrl() {
  return '/';
}
function getSigningUrl() {
  return '/';
}

function getDefaultRouteFromAuth({
  appAuth,
}: {
  appAuth: AppAuth;
}): Observable<string> {
  if (appAuth && appAuth.user) {
    if (appAuth.user.roles.includes('diver-booking')) {
      const url = diveCenterBookingUrlBuilder.buildBookingHomeUrl({
        bookingId: appAuth.user.diver.bookingId,
        clubPublicReference: appAuth.user.diver.clubPublicReference,
      });
      return of(url);
    }
    // if (appAuth.user.roles.includes('account-reset-password')) {
    //   return of('/account/choose-password');
    // }
  }
  appLogger.info('[appRouteBuilder] getDefaultRouteFromAuth:', appAuth);
  return of('/');
}

function getDefaultRoute(): Observable<string> {
  return authenticationStore.auth.get().pipe(
    first(),
    switchMap((appAuth) => getDefaultRouteFromAuth({ appAuth })),
  );
}
