/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubProductPackageOfferView,
  PurchaseResumeOffer,
} from '@mabadive/app-common-model';
import { dataSorter } from '../../../data';
import { clubProductPackageMetaReader } from './clubProductPackageMetaReader.service';
import { clubProductPackageOfferMatcherBestCommon } from './clubProductPackageOfferMatcherBestCommon.service';
import { DiverPurchaseOtherEditorDialogMatchingOfferCriteria } from './model';

export const clubProductPackageOfferMatcherBestOther = {
  findBestMatchingOfferOther,
};

function findBestMatchingOfferOther({
  criteria,
  productPackageOffers,
}: {
  criteria?: DiverPurchaseOtherEditorDialogMatchingOfferCriteria;
  productPackageOffers: ClubProductPackageOfferView[];
}): PurchaseResumeOffer {
  if (!criteria) {
    return undefined;
  }

  const matchingOffers: ClubProductPackageOfferView[] = excludeNonBestOffers({
    productPackageOffers,
    criteria,
  });

  // sort by total count
  const sortedMatchingOffers = dataSorter.sortMultiple(matchingOffers, {
    getSortAttributes: (o) => {
      const diveAttributes = o.productPackage.diveAttributes;
      const productAttributes = o.productPackage.productAttributes;
      const salesCriteria = o.productPackage.salesCriteria;
      const meta = clubProductPackageMetaReader.readMeta(o.productPackage);
      const totalCount = meta.productDefaultCreditsCount ?? 0;
      return [
        {
          value: totalCount,
        },
        {
          value:
            o.productPackage?.diveAttributes?.firstDiveTrainingReference ===
            criteria?.firstDiveTrainingReference,
          asc: false, // // en priorité les baptêmes qui ont le bon type
        },
        {
          value: productAttributes?.minAge > 0 ? 0 : 1, // on met en priorité les offres qui ont un filtre d'âge (elles ont été filtrées avant si elles ne matchen pas)
        },
        {
          value: productAttributes?.maxAge > 0 ? 0 : 1, // on met en priorité les offres qui ont un filtre d'âge (elles ont été filtrées avant si elles ne matchen pas)
        },
        {
          value: o.price,
          asc: false, // par défaut, on facture la prestation la plus chère
        },
        {
          value: o.reference,
          type: 'full-text',
        },
        {
          value: o._id,
          type: 'full-text',
        },
      ];
    },
    asc: true,
  });

  let bestMatchingOffer: ClubProductPackageOfferView;

  if (!bestMatchingOffer && criteria.diveSessionTheme) {
    // on recherche la première offre qui correspond au theme
    bestMatchingOffer =
      clubProductPackageOfferMatcherBestCommon.getFirstMatchingCountOffer({
        divesCount: criteria.divesNumber,
        offers: sortedMatchingOffers.filter(
          (x) =>
            x.productPackage?.salesCriteria?.diveSessionTheme ===
            criteria.diveSessionTheme,
        ),
      });
  }

  if (!bestMatchingOffer) {
    // sinon on prend la première qui correspond (= la plus chère)
    bestMatchingOffer =
      clubProductPackageOfferMatcherBestCommon.getFirstMatchingCountOffer({
        divesCount: criteria.divesNumber,
        offers: sortedMatchingOffers,
      });
  }

  if (!bestMatchingOffer && matchingOffers.length) {
    bestMatchingOffer = matchingOffers[matchingOffers.length - 1]; // use last
  }
  return bestMatchingOffer;
}

function excludeNonBestOffers({
  productPackageOffers,
  criteria,
}: {
  productPackageOffers: ClubProductPackageOfferView[];
  criteria: DiverPurchaseOtherEditorDialogMatchingOfferCriteria;
}): ClubProductPackageOfferView[] {
  return productPackageOffers.filter((o) => {
    const meta = clubProductPackageMetaReader.readMeta(o.productPackage);
    const diveAttributes = o.productPackage.diveAttributes;
    const productAttributes = o.productPackage.productAttributes;
    const salesCriteria = o.productPackage.salesCriteria;

    if (salesCriteria?.dontSelectByDefault) {
      return false;
    }

    if (!meta.isOther) {
      return false;
    }

    if (salesCriteria?.diveSessionTheme && !criteria.diveSessionTheme) {
      return false; // les presta de thème sont ignorées si le plongeur n'y est pas explicitement associé
    }

    if (
      productAttributes?.minAge > 0 &&
      criteria.age &&
      criteria.age < productAttributes?.minAge
    ) {
      // âge mini : si âge du participant inconnu, on ignore le critère
      return false;
    }
    if (
      productAttributes?.maxAge > 0 &&
      ((criteria.age && criteria.age > productAttributes?.maxAge) ||
        (!criteria.age && productAttributes?.maxAge >= 18))
    ) {
      // âge mini : si âge du participant inconnu,
      // on ignore le critère seulement si maxAge >=18 (les offres enfants sont exclues)
      return false;
    }

    return true;
  });
}
