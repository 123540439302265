import {
  AppPaymentMethod,
  APP_PAYMENT_METHODS_ALL,
  DashboardPaymentByMethod,
} from '@mabadive/app-common-model';

export const paymentMethodFilter = {
  filterPaymentMethods,
  filterPaymentsByMethod,
};

function filterPaymentMethods({
  paymentsByMethod,
  paymentMethods,
}: {
  paymentsByMethod: DashboardPaymentByMethod[];
  paymentMethods: AppPaymentMethod[];
}): AppPaymentMethod[] {
  return APP_PAYMENT_METHODS_ALL.filter((method) => {
    if (paymentMethods.includes(method)) {
      return true;
    }
    const x = paymentsByMethod.find((z) => z.method === method);
    if (x) {
      const isNotNull = x.amountPending + x.amountValidated > 0;
      if (isNotNull) {
        return true;
      }
    }
    return false;
  });
}

function filterPaymentsByMethod({
  paymentsByMethod,
  paymentMethods,
}: {
  paymentsByMethod: DashboardPaymentByMethod[];
  paymentMethods: AppPaymentMethod[];
}): DashboardPaymentByMethod[] {
  return (paymentsByMethod ?? []).filter((x) => {
    const isNotNull = x.amountPending + x.amountValidated > 0;
    if (isNotNull) {
      return true;
    }
    return paymentMethods.includes(x.method as AppPaymentMethod);
  });
}
