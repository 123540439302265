import {
  AppUserTrackingSession,
  ClubBaseDataLoadingParams,
  ClubBaseDataLoadingState,
  ClubBoat,
} from '@mabadive/app-common-model';
import {
  loadableAttributeCollectionStoreFactory,
  loadableAttributeStoreFactory,
} from 'src/lib/reactive/store';

import { getBaseStore } from './baseStore';

export const clubStore = {
  trackingSession: loadableAttributeStoreFactory.create<AppUserTrackingSession>(
    getBaseStore(),
    'trackingSession',
  ),
  // club: loadableAttributeStoreFactory.create<Club>(getBaseStore(), 'clu_club'), // FIXME to migrate to clubLocalStore
  /** @deprecated use clubResume (from currentClubResumeStore.getSnapshot()) */
  clubBoats: loadableAttributeCollectionStoreFactory.create<ClubBoat>(
    getBaseStore(),
    'clu_clubBoats',
  ),
  // clubSettings: loadableAttributeStoreFactory.create<ClubSettings>(
  //   getBaseStore(),
  //   'clu_clubServicesTypes',
  // ),
  // divers: loadableAttributeCollectionStoreFactory.create<ClubDiver>(
  //   getBaseStore(),
  //   'clu_divers',
  // ),
  // diveSites: loadableAttributeCollectionStoreFactory.create<ClubDiveSite>(
  //   getBaseStore(),
  //   'clu_diveSites',
  // ),
  // diveSitesGroups:
  //   loadableAttributeCollectionStoreFactory.create<ClubDiveSiteGroup>(
  //     getBaseStore(),
  //     'clu_diveSitesGroups',
  //   ),
  initialDataLoaded: loadableAttributeStoreFactory.create<boolean>(
    getBaseStore(),
    'clu_initialDataLoaded',
  ),
  loadingParams:
    loadableAttributeStoreFactory.create<ClubBaseDataLoadingParams>(
      getBaseStore(),
      'clu_loadingParams',
    ),
  loadingState: loadableAttributeStoreFactory.create<ClubBaseDataLoadingState>(
    getBaseStore(),
    'clu_loadingState',
  ),
};
