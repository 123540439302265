"use strict";
// https://en.wikipedia.org/wiki/IETF_language_tag
exports.__esModule = true;
exports.APP_LANGUAGES_FLAGS = void 0;
// Mapping some language codes to country flag emojis
exports.APP_LANGUAGES_FLAGS = {
    af: '🇿🇦',
    ak: '🇬🇭',
    sq: '🇦🇱',
    am: '🇪🇹',
    ar: '🇸🇦',
    hy: '🇦🇲',
    as: '🇮🇳',
    ay: '🇧🇴',
    az: '🇦🇿',
    bm: '🇲🇱',
    eu: '🇪🇸',
    be: '🇧🇾',
    bn: '🇧🇩',
    bho: '🇮🇳',
    bs: '🇧🇦',
    bg: '🇧🇬',
    ca: '🇪🇸',
    ceb: '🇵🇭',
    ny: '🇲🇼',
    zh: '🇨🇳',
    'zh-TW': '🇹🇼',
    co: '🇫🇷',
    hr: '🇭🇷',
    cs: '🇨🇿',
    da: '🇩🇰',
    dv: '🇲🇻',
    doi: '🇮🇳',
    nl: '🇳🇱',
    en: '🇬🇧',
    eo: '🏳',
    et: '🇪🇪',
    ee: '🇬🇭',
    tl: '🇵🇭',
    fi: '🇫🇮',
    fr: '🇫🇷',
    fy: '🇳🇱',
    gl: '🇪🇸',
    ka: '🇬🇪',
    de: '🇩🇪',
    el: '🇬🇷',
    gu: '🇮🇳',
    ht: '🇭🇹',
    ha: '🇳🇬',
    he: '🇮🇱',
    hi: '🇮🇳',
    hu: '🇭🇺',
    is: '🇮🇸',
    ig: '🇳🇬',
    id: '🇮🇩',
    ga: '🇮🇪',
    it: '🇮🇹',
    ja: '🇯🇵',
    jv: '🇮🇩',
    kn: '🇮🇳',
    kk: '🇰🇿',
    km: '🇰🇭',
    ko: '🇰🇷',
    ku: '🇹🇷',
    ky: '🇰🇬',
    lo: '🇱🇦',
    la: '🏳',
    lv: '🇱🇻',
    lt: '🇱🇹',
    lb: '🇱🇺',
    mk: '🇲🇰',
    mg: '🇲🇬',
    ms: '🇲🇾',
    ml: '🇮🇳',
    mt: '🇲🇹',
    mi: '🇳🇿',
    mr: '🇮🇳',
    mn: '🇲🇳',
    ne: '🇳🇵',
    no: '🇳🇴',
    or: '🇮🇳',
    ps: '🇦🇫',
    fa: '🇮🇷',
    pl: '🇵🇱',
    pt: '🇵🇹',
    pa: '🇮🇳',
    qu: '🇵🇪',
    ro: '🇷🇴',
    ru: '🇷🇺',
    sm: '🇼🇸',
    gd: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
    sr: '🇷🇸',
    st: '🇱🇸',
    sn: '🇿🇼',
    sd: '🇵🇰',
    si: '🇱🇰',
    sk: '🇸🇰',
    sl: '🇸🇮',
    so: '🇸🇴',
    es: '🇪🇸',
    su: '🇮🇩',
    sw: '🇹🇿',
    sv: '🇸🇪',
    ta: '🇮🇳',
    te: '🇮🇳',
    th: '🇹🇭',
    tr: '🇹🇷',
    uk: '🇺🇦',
    ur: '🇵🇰',
    uz: '🇺🇿',
    vi: '🇻🇳',
    cy: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
    xh: '🇿🇦',
    yi: '🇮🇱',
    yo: '🇳🇬',
    zu: '🇿🇦',
    'zh-CN': '🇨🇳'
};
