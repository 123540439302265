import { StackLogLevel } from './stack-logger-level.type';

// Hack: 2 interfaces identiques
// - StackLogLogger présent dans @mabadive/stack-log-core
// - AppLogger présent dans @mabadive/app-common-model
// Et aussi: StackLogLevel = AppLoggerLevel
export abstract class StackLogLogger {
  debug(message: string, ...params: any[]) {
    this.log('debug', message, params);
  }
  info(message: string, ...params: any[]) {
    this.log('info', message, params);
  }
  warn(message: string, ...params: any[]) {
    this.log('warn', message, params);
  }
  error(message: string, ...params: any[]) {
    this.log('error', message, params);
  }

  abstract log(level: StackLogLevel, message: string, params: any[]): void;
}
