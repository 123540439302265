"use strict";
exports.__esModule = true;
exports.APP_DOC_UPLOAD_FILE_TYPE_EXTENSIONS_MAP = void 0;
exports.APP_DOC_UPLOAD_FILE_TYPE_EXTENSIONS_MAP = {
    text: ['.txt'],
    richText: ['.pdf', '.doc', '.docx', '.odt'],
    spreadSheet: ['.xls', '.xlsx', '.ods'],
    presentation: ['.ppt', '.pptx', '.odp'],
    image: ['.jpeg', '.jpg', '.png', '.gif', '.webp'],
    archive: ['.zip', '.rar', '.7z', '.gz', '.bz2', '.xz', '.tar', '.tgz']
};
