import {
  AppInquirySettings,
  ClubCustomerConsentType,
  ClubCustomerConsent_Content,
  Customer_ClubSettingsPublicCustomer,
  buildDefaultRGPDAgreementMessage,
} from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { CONSENT_VALID_INFO_CONTENT } from '../BO-booking/DiveCenterBookingInquiryResponsePage/DiveCenterBookingInquiryResponsePage_ConfirmFormPanel';

export type ClubCustomerConsent_Config = {
  type: ClubCustomerConsentType;
  enabled: boolean;
  agreementId: string;
  content: ClubCustomerConsent_Content;
};

export function useCustomerConsentConfig({
  type,
  clubSettings,
  inquirySettings,
}: {
  type: Extract<ClubCustomerConsentType, 'valid-info' | 'gdpr'>;
  clubSettings: Customer_ClubSettingsPublicCustomer;
  inquirySettings?: AppInquirySettings;
}): ClubCustomerConsent_Config {
  const contact = clubSettings?.publicData?.contact;
  const brand = clubSettings?.publicData?.brand;
  const brandFullName = useMemo(
    () => `${brand?.name}, ${brand?.locationResume}`,
    [brand?.locationResume, brand?.name],
  );

  return useMemo(() => {
    if (type === 'gdpr') {
      const globalGdprSettings =
        clubSettings?.publicSettings?.customerSpace?.consents?.gdpr;

      const gdprEnabled = inquirySettings
        ? inquirySettings?.consents?.gdpr?.enabled
        : globalGdprSettings?.enabled;

      if (gdprEnabled) {
        const gdprConsentMessageDescription =
          globalGdprSettings?.customize &&
          globalGdprSettings?.consentAgreement?.message?.trim()?.length > 0
            ? globalGdprSettings?.consentAgreement?.message
            : buildDefaultRGPDAgreementMessage({
                brandFullName,
                contactEmailAddress: contact?.emailAddress,
              });

        const content: ClubCustomerConsent_Content = {
          title: 'Traitement des données personnelles',
          description: gdprConsentMessageDescription,
          question:
            'Je reconnais avoir pris connaissance des informations et les accepter.',
        };
        const gDPRConsentConfig: ClubCustomerConsent_Config = {
          type,
          enabled: true,
          content,
          agreementId: globalGdprSettings?.consentAgreement?._id,
        };
        return gDPRConsentConfig;
      }
      const gDPRConsentConfig: ClubCustomerConsent_Config = {
        type,
        enabled: false,
        content: undefined,
        agreementId: undefined,
      };
      return gDPRConsentConfig;
    } else if (type === 'valid-info') {
      const content: ClubCustomerConsent_Content = {
        ...CONSENT_VALID_INFO_CONTENT,
      };
      const gDPRConsentConfig: ClubCustomerConsent_Config = {
        type,
        enabled: true, // toujours activé
        content,
        agreementId: undefined,
      };
      return gDPRConsentConfig;
    }
  }, [
    brandFullName,
    clubSettings?.publicSettings?.customerSpace?.consents?.gdpr,
    contact?.emailAddress,
    inquirySettings,
    type,
  ]);
}
