"use strict";
exports.__esModule = true;
exports.CLUB_OFFER_STATES = void 0;
exports.CLUB_OFFER_STATES = [
    'draft',
    'trial',
    'active',
    'archived',
    'demo',
];
