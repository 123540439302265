/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  dateService,
  effectiveDateBoundsBuilder,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useRedirect } from 'src/business/_core/data/hooks';
import { OnlineBookingProductConfig } from '../../../../../../../../../mabadive-libs/app-common-libs/app-common-model/src/entities/company/settings/club-settings/general/OnlineBookingProductConfig.type';
import { AppHackableCalendar } from '../../../../../../stories/components';
import { CustomerProductsPage_BookProductPanelLocalState } from './useCustomerProductsPage_BookProductPanelLocalState.hook';

export const CustomerProductsPage_BookProductPanel_ChooseSingleDate = ({
  localState,
  className,
}: {
  localState: CustomerProductsPage_BookProductPanelLocalState;
  className?: string;
}) => {
  const redirectTo = useRedirect();

  const { state, data, actions } = localState;
  const control = state.form.control;

  const { viewState, clubReference, diveCenterId } = state;
  const productConfig: OnlineBookingProductConfig = state.productConfig;

  const [activityDate] = useWatch({
    control,
    name: ['article.activityDate'],
  });

  const { effectiveFirstDate, effectiveLastDate } =
    effectiveDateBoundsBuilder.getBoundsFromDateConstraints(
      productConfig?.preBook?.prompBeginDateConstraints,
      {
        firstDate: new Date(), // aujourd'hui
        lastDate: dateService.add(new Date(), 2, 'year'), // date maxi en dur: dans 2 ans
      },
    );
  const [viewDate, setViewDate] = useState<Date>(
    activityDate ?? effectiveFirstDate,
  );

  const [selectedDate, setSelectedDate] = useState<Date>(activityDate);

  return (
    <div
      className={clsx(
        'flex flex-col md:grid md:grid-cols-2 items-stretch gap-4 md:gap-16',
        className,
      )}
    >
      <div className={clsx('flex flex-col gap-4')}>
        <h2 className="uppercase font-medium text-gray-800">
          Demande de réservation
        </h2>
        {/* // TODO commentaire personnalisable côté pro */}
        <div className="text-gray-600 leading-5 grid gap-2">
          <p>
            <b>Choisissez la date</b> à laquelle vous souhaiteriez réaliser
            cette prestation.
          </p>
          <p>
            Vous pourrez donner des précisions sur vos disponibilités dans le
            commentaire en fin de réservation.
          </p>
          <p>
            <b>Nous vous contacterons</b> pour vous confirmer la disponibilité
            ou vous proposer une autre date si besoin.
          </p>
        </div>
      </div>
      <div className={clsx('flex flex-col gap-4')}>
        <h2 className="uppercase font-medium text-gray-800">
          Choisissez une date
        </h2>
        <AppHackableCalendar
          className="mx-auto w-full md:w-[24rem] max-w-full"
          minDate={effectiveFirstDate}
          maxDate={effectiveLastDate}
          initialViewDate={viewDate}
          selectedDate={undefined}
          onViewDateChange={(viewDate) => {
            setSelectedDate(undefined);
            setViewDate(viewDate);
          }}
          onSelectDate={(activityDate) => {
            setSelectedDate(activityDate);
            setViewDate(activityDate);
            state.form.setValue('article.activityDate', activityDate);
            actions.goToNextStep();
            // TODO, à voir si on fait confirmer la date ici, ou si c'est bon avec le step suivant?
          }}
        />
      </div>
      {/* Si on a fait back, et que la date est renseignée, on peut ajouter ce bouton : */}
      {/* <div
        className={clsx(
          'my-6 text-center',
          state.shoppingCart.totalQuantity > 0 ? '' : 'invisible',
        )}
      >
        <AppButton
          className="uppercase"
          size="large"
          icon={AppHeroIcons.actionConfirm}
          color="primary-bg"
          onClick={() => actions.confirmQuantity()}
        >
          Confirmer
        </AppButton>
      </div> */}
    </div>
  );
};
