import { ClubDiver } from '@mabadive/app-common-model';
import { dataSorter, SortableAttribute } from '../../../data';

export const bookingMemberFullSorter = {
  sort,
  getSortAttributes,
};

function sort<
  T extends {
    diver: ClubDiver;
  },
>(
  members: T[],
  {
    bookingContactDiverId,
    bookingMembersIdsInitialOrder,
  }: {
    bookingContactDiverId: string;
    bookingMembersIdsInitialOrder?: string[];
  },
): T[] {
  return dataSorter.sortMultiple(members, {
    getSortAttributes: (item, index) => {
      const previousIndex = (bookingMembersIdsInitialOrder ?? []).indexOf(
        item.diver._id,
      );
      return [
        {
          value: previousIndex === -1 ? 10000 : previousIndex,
          asc: true,
        },
        ...getSortAttributes({ item, index, bookingContactDiverId }),
      ];
    },
    asc: true,
  });
}

function getSortAttributes<
  T extends {
    diver: ClubDiver;
  },
>({
  item,
  index,
  bookingContactDiverId,
}: {
  item: T;
  index: number;
  bookingContactDiverId: string;
}): SortableAttribute[] {
  return [
    {
      value: item.diver._id === bookingContactDiverId ? 1 : 2,
      type: 'default',
    },
    {
      value: `${item.diver.lastName} ${item.diver.firstName}`.includes('?')
        ? 2
        : 1,
      type: 'default',
    },
    {
      value: item.diver.lastName,
      type: 'full-text',
    },
    {
      value: item.diver.firstName,
      type: 'full-text',
    },
    {
      value: item.diver.fakeName?.memberIndex,
    },
    {
      value: item.diver._id,
      type: 'default',
    },
  ];
}
