function isObject(item: any): boolean {
  const type = typeof item;
  // eslint-disable-next-line no-mixed-operators
  return type === 'function' || type === 'object' && !!item;
}

function clone<T>(item: T): T {
  if (!item) {
    // empty
    return item;
  }
  if (Array.isArray(item)) {
    // clone array
    return (item as any).concat([]);
  }

  if (isObject(item)) {
    // clone object
    return {
      ...item,
    };
  }

  // return immutable primitive
  return item;
}

export const cloner = {
  clone,
};
