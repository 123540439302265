import {
  ClubDiver,
  ClubParticipant,
  ClubStaffMember,
  DiveSession,
  DiveSessionGroup,
  DiveSessionGroupSession1,
  DiveSessionGroupSession2,
  SessionDiveGroupSupervision,
} from '@mabadive/app-common-model';

export const diveSessionDiveSupervisionBuilder = {
  buildDiveSupervision,
  buildDivingInstructors,
};

function buildDiveSupervision({
  sessionDiveNumber,
  diveSession,
  group,
  divingInstructorsStaffs,
  participantsExts,
}: {
  sessionDiveNumber: number;
  diveSession: Pick<DiveSession, 'diveTourSession2'>;
  group: Pick<
    DiveSessionGroup,
    'diveTourGroupSession1' | 'diveTourGroupSession2'
  >;
  divingInstructorsStaffs: Pick<
    ClubStaffMember,
    '_id' | 'scubaDivingInstructor' | 'profile' | 'equipment'
  >[];
  participantsExts: {
    diver: Pick<ClubDiver, 'firstName' | 'lastName' | 'divingCertification1'>;
    participant: Pick<
      ClubParticipant,
      '_id' | 'certificationReference' | 'diveMode' | 'autoSupervisedDetails'
    >;
    // bookingSessionParticipant?: DiveSessionResumeFullBookingSessionParticipant; // attention: sur l'ancienne fiche de sécu, ce n'est pas renseigné
  }[];
}): SessionDiveGroupSupervision {
  if (!group) {
    return {};
  }
  if (
    (sessionDiveNumber === 1 ||
      diveSession.diveTourSession2?.sameStaffSession1) &&
    (group?.diveTourGroupSession1?.instructor?.staffId ||
      group?.diveTourGroupSession1?.diveGuide?.participantId ||
      group?.diveTourGroupSession1?.isAutonomous)
  ) {
    const instructor = divingInstructorsStaffs.find(
      (s) => s._id === group?.diveTourGroupSession1?.instructor?.staffId,
    );
    if (instructor) {
      const aptitude =
        group?.diveTourGroupSession1?.instructor?.degree?.level ??
        instructor.scubaDivingInstructor?.degree?.level;

      const level = group?.diveTourGroupSession1?.instructor?.degree?.name;

      return { instructor, level, aptitude };
      //
    } else if (group?.diveTourGroupSession1?.diveGuide?.participantId) {
      const diveGuide = participantsExts.find(
        (x) =>
          x.participant._id ===
          group?.diveTourGroupSession1?.diveGuide?.participantId,
      );
      const level = buildDiveGuideLevel(group.diveTourGroupSession1, diveGuide);

      return { diveGuide, level };
    } else if (group?.diveTourGroupSession1?.isAutonomous) {
      return { isAutonomous: true };
    }
  } else if (
    sessionDiveNumber === 2 &&
    !diveSession.diveTourSession2?.sameStaffSession1 &&
    (group?.diveTourGroupSession2?.instructor?.staffId ||
      group?.diveTourGroupSession2?.diveGuide?.participantId ||
      group?.diveTourGroupSession2?.isAutonomous)
  ) {
    const instructor = divingInstructorsStaffs.find(
      (s) => s._id === group?.diveTourGroupSession2?.instructor?.staffId,
    );
    if (instructor) {
      const aptitude =
        group?.diveTourGroupSession2?.instructor?.degree?.level ??
        instructor.scubaDivingInstructor?.degree?.level ??
        '';
      const level = group?.diveTourGroupSession2?.instructor?.degree?.name;

      return { instructor, level, aptitude };
    } else if (group?.diveTourGroupSession2?.diveGuide?.participantId) {
      const diveGuide = participantsExts.find(
        (x) =>
          x.participant._id ===
          group?.diveTourGroupSession2?.diveGuide?.participantId,
      );
      if (diveGuide) {
        const level = buildDiveGuideLevel(
          group.diveTourGroupSession2,
          diveGuide,
        );

        return { diveGuide, level };
      }
    } else if (group?.diveTourGroupSession2?.isAutonomous) {
      return { isAutonomous: true };
    }
  }
  return {};
}
function buildDiveGuideLevel(
  diveTourGroupSession: DiveSessionGroupSession1 | DiveSessionGroupSession2,
  diveGuide: {
    diver: Pick<ClubDiver, 'firstName' | 'lastName' | 'divingCertification1'>;
    participant: Pick<
      ClubParticipant,
      '_id' | 'certificationReference' | 'diveMode' | 'autoSupervisedDetails'
    >;
  },
) {
  let level = diveTourGroupSession?.diveGuide?.mainCertificationReference;

  if (!level) {
    if (diveGuide?.participant) {
      // si pas défini dans la palanquée: on prend le niveau du participant
      const p = diveGuide?.participant;
      level =
        p?.diveMode === 'autoSupervised'
          ? p?.autoSupervisedDetails.certificationReference
          : p?.certificationReference;
    }
    if (!level) {
      level =
        diveGuide?.diver?.divingCertification1?.mainCertificationReference;
    }
  }
  return level;
}

function buildDivingInstructors({
  sessionDiveNumber,
  diveSession,
  group,
  divingInstructorsStaffs,
}: {
  sessionDiveNumber: number;
  diveSession: Pick<DiveSession, 'diveTourSession2'>;
  group: Pick<
    DiveSessionGroup,
    'diveTourGroupSession1' | 'diveTourGroupSession2'
  >;
  divingInstructorsStaffs: Pick<
    ClubStaffMember,
    '_id' | 'scubaDivingInstructor' | 'profile' | 'equipment'
  >[];
}): SessionDiveGroupSupervision[] {
  if (!group) {
    return [];
  }
  const staffIds = (
    sessionDiveNumber === 2 && !diveSession.diveTourSession2?.sameStaffSession1
      ? group?.diveTourGroupSession2?.divingInstructors ?? []
      : group?.diveTourGroupSession1?.divingInstructors ?? []
  )?.map((x) => x.staffId);

  const divingInstructorsStaffMembers = divingInstructorsStaffs.filter((s) =>
    staffIds.includes(s._id),
  );
  return divingInstructorsStaffMembers.map((instructor) => {
    const aptitude =
      group?.diveTourGroupSession1?.instructor?.degree?.level ??
      instructor.scubaDivingInstructor?.degree?.level;

    const level = group?.diveTourGroupSession1?.instructor?.degree?.name;

    return { instructor, level, aptitude };
  });
}
