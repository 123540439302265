"use strict";
exports.__esModule = true;
exports.DIVE_CERTIFICATIONS = void 0;
var offer_1 = require("../../../offer");
var dive_organization_1 = require("../dive-organization");
var diveCertificationSortReferenceBuilder_service_1 = require("./diveCertificationSortReferenceBuilder.service");
var DIVE_CERTIFICATION_DEBUTANT_const_1 = require("./DIVE_CERTIFICATION_DEBUTANT.const");
var DIVE_CERTIFICATIONS_BASE = [
    DIVE_CERTIFICATION_DEBUTANT_const_1.DIVE_CERTIFICATION_DEBUTANT,
];
var index = DIVE_CERTIFICATIONS_BASE.length;
exports.DIVE_CERTIFICATIONS = dive_organization_1._DIVE_ORGANIZATIONS_DATA.reduce(function (acc, data) {
    return acc.concat(data.certifications.map(function (cert) {
        var organization = dive_organization_1.DIVE_ORGANIZATIONS.find(function (org) { return org.reference === data.orgGroupReference; });
        var organizationv2 = offer_1.DIVE_SERVICE_ORGANIZATIONS.find(function (org) { return org.orgGroupReferencev2 === data.orgGroupReferencev2; });
        var diveCertification = {
            sortReference: diveCertificationSortReferenceBuilder_service_1.diveCertificationSortReferenceBuilder.buildSortReference({
                org: organizationv2,
                cert: cert,
                suffixIndex: index++
            }),
            organization: organization,
            organizationv2: organizationv2,
            reference: cert.reference,
            label: cert.label,
            referenceDescription: cert.referenceDescription,
            supervisor: cert.supervisor,
            nitrox: cert.nitrox,
            defaultDiveMode: cert.defaultDiveMode,
            nextSuggestedTrainingReference: cert.nextSuggestedTrainingReference,
            supervised: cert.supervised,
            autonomous: cert.autonomous
        };
        return diveCertification;
    }));
}, DIVE_CERTIFICATIONS_BASE);
