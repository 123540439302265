import { BookingStatus } from '@mabadive/app-common-model';

export const bookingStatusFormatter = {
  formatStatus,
};

function formatStatus(value: BookingStatus): string {
  if (!value) {
    return '';
  }
  switch (value) {
    case 'draft':
      return 'Non confirmée';
    case 'pending':
      return 'En attente';
    case 'confirmed':
      return 'Confirmée';
    case 'cancelled':
      return 'Annulée';
  }
}
