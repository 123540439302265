import { ClubLegalInformation } from '@mabadive/app-common-model';
import { nameFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';

export function CustomerProductsPage_ClubLegalInformationCard({
  legalInformation,
  className,
}: {
  legalInformation: ClubLegalInformation;
  className?: string;
}) {
  return !legalInformation ? null : (
    <div className={clsx('text-gray-500 text-sm', className)}>
      <div className="grid gap-1">
        <h2 className="font-bold text-base text-gray-600">
          {legalInformation.isIndividualContractor ? (
            nameFormatter.formatFullName(
              legalInformation.individualContractorDetails,
            )
          ) : (
            <span className="uppercase">
              {legalInformation.companyDetails?.legalName}
            </span>
          )}
        </h2>
        <p>
          <span className="font-semibold">{legalInformation.legalForm}</span>
          {legalInformation.companyDetails?.shareCapitalAmount && (
            <span className="">
              {' '}
              au capital de{' '}
              {legalInformation.companyDetails?.shareCapitalAmount}
            </span>
          )}
        </p>
        {legalInformation.legalAddress && (
          <p className="text-xs">
            <span>{legalInformation.legalAddress.line1}</span>
            <span>
              {' '}
              {`${legalInformation.legalAddress.postalCode??''} ${legalInformation.legalAddress.city??''}, ${legalInformation.legalAddress.state??''}`}
            </span>
            <span> {legalInformation.legalAddress.country??''}</span>
          </p>
        )}
        <div className="text-xs">
          {legalInformation.companyRcsNumber && (
            <p>
              <span className="font-semibold">N° d'immatriculation RCS: </span>
              {legalInformation.companyRcsNumber}
            </p>
          )}
          {legalInformation.companyVatNumber && (
            <p>
              <span className="font-semibold">N° de TVA: </span>
              {legalInformation.companyVatNumber}
            </p>
          )}
          {legalInformation.companySiretNumber && (
            <p>
              <span className="font-semibold">N° de SIRET: </span>
              {legalInformation.companySiretNumber}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
