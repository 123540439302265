"use strict";
exports.__esModule = true;
exports.DEFAULT_EQUIPMENT_SETTINGS_MASK = void 0;
exports.DEFAULT_EQUIPMENT_SETTINGS_MASK = {
    type: 'mask',
    enabled: false,
    club: {
        enabled: false,
        models: []
    },
    self: {
        enabled: false,
        models: []
    }
};
