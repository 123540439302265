import {
  ClubParticipant,
  ClubResumeStaffMember,
  ClubSettings,
  DiveCenterDailyConfig,
  DiveSession,
  DiveSessionGroupDiveMode,
  DiveSessionResumeGroup,
  DiveSessionStaffConfigMember,
  DiveSessionStaffMemberTableModel,
  DiveSessionStaffMemberTableModelStaffMember,
  DiveSessionStaffMemberTableModelStaffMemberMeta,
  StaffMemberDailyAvailabilityResume,
  StaffMemberSessionAssigmentResume,
} from '@mabadive/app-common-model';
import { commonDiveSessionBuilder } from '../../business';
import { dataSorter } from '../../data';
import { StaffMemberAvailabilityFilter } from '../../pro-web/staffMembers';
import { staffMemberAvailabilityFinder } from '../../pro-web/staffMembers/staffMemberAvailabilityFinder.service';
import { staffMemberSessionAssignmentBuilder } from '../../pro-web/staffMembers/staffMemberSessionAssignmentBuilder.service';

export const diveSessionStaffMembersTableModelBuilder = {
  buildTableModel,
  buildStaffMembersFull,
  filterStaffMembersFull,
};

function buildTableModel({
  groups,
  isMultiSessionConfigForStaff,
  clubParticipants,
  staffMembersFull,
}: {
  groups: DiveSessionResumeGroup[];
  isMultiSessionConfigForStaff: boolean;
  clubParticipants: Pick<ClubParticipant, 'diveSessionGroupId'>[];
  staffMembersFull?: DiveSessionStaffMemberTableModelStaffMember[];
}): DiveSessionStaffMemberTableModel {
  const participantsWithoutGroupsCount = clubParticipants.filter(
    (x) => !x.diveSessionGroupId,
  ).length;
  const groupsWithoutMonitors = groups
    .filter(
      (x) =>
        !x.diveTourGroupSession1?.instructor?.staffId ||
        (isMultiSessionConfigForStaff &&
          !x.diveTourGroupSession2?.instructor?.staffId),
    )
    .map((g) => ({
      _id: g._id,
      diveMode: g.diveMode,
      participantsCount: clubParticipants.filter(
        (p) => p.diveSessionGroupId === g._id,
      ).length,
    }))
    .reduce(
      (acc, g) => {
        if (g.diveMode === 'autonomous') {
          acc.autonomous.push(g);
        } else {
          acc.notAutonomous.push(g);
        }
        return acc;
      },
      {
        autonomous: [] as {
          _id: string;
          diveMode: DiveSessionGroupDiveMode;
          participantsCount: number;
        }[],
        notAutonomous: [] as {
          _id: string;
          diveMode: DiveSessionGroupDiveMode;
          participantsCount: number;
        }[],
      },
    );

  const rowsCount =
    groupsWithoutMonitors.notAutonomous.length +
    groupsWithoutMonitors.autonomous.length +
    staffMembersFull.length;

  const model: DiveSessionStaffMemberTableModel = {
    rowsCount,
    groupsWithoutMonitors,
    staffMembersFull,
    participantsWithoutGroupsCount,
  };
  return model;
}

function buildStaffMembersFull({
  dailyConfigs,
  groups,
  staffFirstNameBefore,
  staffMembers,
  clubParticipants,
  diveSession,
  filterMode,
  clubSettings,
}: {
  dailyConfigs: DiveCenterDailyConfig[];
  groups: DiveSessionResumeGroup[];
  staffFirstNameBefore: boolean;
  staffMembers: ClubResumeStaffMember[];
  clubParticipants: Pick<ClubParticipant, 'diveSessionGroupId'>[];
  diveSession: Pick<
    DiveSession,
    | 'staffConfig'
    | 'diveTourSession1'
    | 'diveTourSession2'
    | 'time'
    | 'reference'
  >;
  filterMode: StaffMemberAvailabilityFilter;
  clubSettings: Pick<ClubSettings, 'ui'>;
}): DiveSessionStaffMemberTableModelStaffMember[] {
  const date = diveSession.time;

  const staffMembersConfig: DiveSessionStaffConfigMember[] =
    diveSession.staffConfig?.staffMembers;

  const staffMembersFull: DiveSessionStaffMemberTableModelStaffMember[] =
    staffMembers.map((staffMember) => {
      // const session1 = buildMemberSessionModel({
      //   staffMember,
      //   groups,
      //   sessionNumber: 1,
      //   clubParticipants,
      //   diveSession,
      // });
      // const session2 = buildMemberSessionModel({
      //   staffMember,
      //   groups,
      //   sessionNumber: 2,
      //   clubParticipants,
      //   diveSession,
      // });

      // const isSessionInUse = staffMemberAvailabilityFinder.isSessionInUse({
      //   session1,
      //   session2,
      // });
      // const staffMemberConfig = (staffMembersConfig ?? []).find(
      //   (x) => x.staffMemberId === staffMember._id,
      // );
      // const isSessionForceAssigned = staffMemberConfig?.assigned === true;
      // const isSessionForceUnassigned = staffMemberConfig?.assigned === false;
      // const isOnActivePeriod =
      //   isSessionForceAssigned ||
      //   !!staffMemberAvailabilityFinder.getOnActivePeriod(staffMember, {
      //     now,
      //   });
      // const isOnActiveToday =
      //   isSessionInUse ||
      //   isSessionForceAssigned ||
      //   (isOnActivePeriod &&
      //     !!staffMemberAvailabilityFinder.getOnActiveToday(staffMember, {
      //       now,
      //     }) &&
      //     !isSessionForceUnassigned);

      // const isRoleSurfaceSecurity =
      //   staffMember.profile.roles.includes('surface-security');
      // const isRoleDivingDirector =
      //   staffMember.profile.roles.includes('diving-director');
      // const isRoleScubaInstructor = staffMember.profile.roles.includes(
      //   'scuba-diving-instructor',
      // );
      // const isRoleFreeDivingInstructor = staffMember.profile.roles.includes(
      //   'free-diving-instructor',
      // );

      // const availableRoles: ClubStaffMemberRole[] = [
      //   ...(staffMember.profile.roles ?? []),
      // ];
      // const assignedRoles: ClubStaffMemberRole[] = [
      //   ...(staffMemberConfig?.assignedRoles ?? []),
      // ];
      // const sessionRoles = staffMemberAvailabilityFinder.getSessionRoles({
      //   session1,
      //   session2,
      // });

      // const activeRoles: ClubStaffMemberRole[] = arrayBuilder.filterDuplicated([
      //   ...assignedRoles,
      //   ...sessionRoles,
      // ]);

      // const metaInner: DiveSessionStaffMemberTableModelStaffMemberMetaInner = {
      //   isSessionInUse,
      //   isSessionForceAssigned,
      //   isOnActivePeriod,
      //   isOnActiveToday,
      //   isRoleSurfaceSecurity,
      //   isRoleDivingDirector,
      //   isRoleScubaInstructor,
      //   isRoleFreeDivingInstructor,
      //   assignedRoles,
      // };
      // const active = isSessionInUse || assignedRoles.length > 0;

      const dateReference = commonDiveSessionBuilder.buildDayReference(date);
      const dailyConfig: DiveCenterDailyConfig = dailyConfigs.find(
        (x) => x.dateReference === dateReference,
      );

      // const staffMemberDailyConfig: DiveCenterDailyConfigStaffMember =
      //   staffMemberAvailabilityFinder.getStaffMemberDailyConfig({
      //     dailyConfig,
      //     now,
      //     staffMemberId: staffMember._id,
      //   });
      // if (staffMemberDailyConfig) {
      //   //
      // }

      const dailyAvailabilityResume: StaffMemberDailyAvailabilityResume =
        staffMemberAvailabilityFinder.getDailyAvailabilityResume({
          dailyConfig,
          date,
          staffMember,
        });
      const sessionAssigmentResume: StaffMemberSessionAssigmentResume =
        staffMemberSessionAssignmentBuilder.buildSessionAssignment({
          clubParticipants,
          diveSession,
          groups,
          staffMember,
          staffMemberDailyAvailabilityResume: dailyAvailabilityResume,
          clubSettings,
        });

      const {
        session1,
        session2,
        activeRoles,
        availableRoles,
        isActiveOnSession,
        isAvailableOnSession,
      } = sessionAssigmentResume;

      const meta: DiveSessionStaffMemberTableModelStaffMemberMeta = {
        // _inner: metaInner,
        active: isActiveOnSession,
        available: isAvailableOnSession,
        // available:
        //   active ||
        //   isSessionForceAssigned ||
        //   (isOnActivePeriod &&
        //     !!staffMemberAvailabilityFinder.getOnActiveToday(staffMember, {
        //       now,
        //     }) &&
        //     !isSessionForceUnassigned),
        activeRoles,
        availableRoles,
      };

      const staffMemberFull: DiveSessionStaffMemberTableModelStaffMember = {
        staffMember,
        session1,
        session2,
        dailyConfig,
        dailyAvailabilityResume,
        sessionAssigmentResume,
        meta,
      };
      return staffMemberFull;
    });

  const filteredStaffMembers = filterStaffMembersFull({
    staffMembersFull,
    filterMode,
  });
  return dataSorter.sortMultiple(filteredStaffMembers, {
    getSortAttributes: (x) => [
      {
        value: x.sessionAssigmentResume.isActiveOnSession,
        type: 'default',
        asc: false,
      },
      {
        value: x.sessionAssigmentResume.isAvailableOnSession,
        type: 'default',
        asc: false,
      },
      {
        value: x.dailyAvailabilityResume.isAvailableToday,
        type: 'default',
        asc: false,
      },
      {
        value: x.dailyAvailabilityResume.isAvailableOnPeriod,
        type: 'default',
        asc: false,
      },
      {
        value: x.session1.isSurfaceSecurity,
        type: 'default',
        asc: false,
      },
      {
        value: x.session2?.isSurfaceSecurity,
        type: 'default',
        asc: false,
      },
      {
        value: x.meta.activeRoles.includes('surface-security'),
        type: 'default',
        asc: false,
      },
      {
        value: x.meta.activeRoles.includes('diving-director'),
        type: 'default',
        asc: false,
      },
      {
        value: x.meta.activeRoles.includes('scuba-diving-instructor'),
        type: 'default',
        asc: false,
      },
      {
        value: x.meta.activeRoles.includes('free-diving-instructor'),
        type: 'default',
        asc: false,
      },
      {
        value: groups.length,
        type: 'default',
        asc: false,
      },
      {
        value: x.meta.availableRoles.includes('surface-security'),
        type: 'default',
        asc: false,
      },
      {
        value: x.meta.availableRoles.includes('diving-director'),
        type: 'default',
        asc: false,
      },
      {
        value: x.meta.availableRoles.includes('scuba-diving-instructor'),
        type: 'default',
        asc: false,
      },
      {
        value: x.meta.availableRoles.includes('free-diving-instructor'),
        type: 'default',
        asc: false,
      },
      {
        value: staffFirstNameBefore
          ? x.staffMember.profile.firstName
          : x.staffMember.profile.lastName,
        asc: true,
        type: 'full-text',
      },
      {
        value: staffFirstNameBefore
          ? x.staffMember.profile.lastName
          : x.staffMember.profile.firstName,
        asc: true,
        type: 'full-text',
      },
      {
        value: x.staffMember._id,
        type: 'full-text',
      },
    ],
  });
}

function filterStaffMembersFull({
  staffMembersFull,
  filterMode,
}: {
  staffMembersFull: DiveSessionStaffMemberTableModelStaffMember[];
  filterMode: StaffMemberAvailabilityFilter;
}): DiveSessionStaffMemberTableModelStaffMember[] {
  return staffMembersFull.filter((x) => {
    switch (filterMode) {
      case 'session-available': {
        return x.sessionAssigmentResume.isAvailableOnSession;
      }
      case 'session-active': {
        return x.sessionAssigmentResume.isActiveOnSession;
      }
      case 'active-period': {
        return (
          x.sessionAssigmentResume.isActiveOnSession ||
          x.sessionAssigmentResume.isAvailableOnSession ||
          x.dailyAvailabilityResume.isAvailableOnPeriod
        );
      }
      case 'active-today': {
        return (
          x.sessionAssigmentResume.isActiveOnSession ||
          x.sessionAssigmentResume.isAvailableOnSession ||
          x.dailyAvailabilityResume.isAvailableToday
        );
      }
      case 'all':
      default:
        return true;
    }
  });
}
