import { ClubEventStatus } from '@mabadive/app-common-model';

export const clubEventStatusFormatter = {
  formatClubEventStatus,
};

function formatClubEventStatus(status: ClubEventStatus): string {
  switch (status) {
    case 'cancelled': {
      return 'Annulé';
    }
    case 'confirmed': {
      return 'Confirmé';
    }
    case 'pending': {
      return 'À confirmer';
    }
  }
  return '';
}
