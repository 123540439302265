import { ParticipantPaymentStatus } from '@mabadive/app-common-model';

export const participantPaymentStatusFormatter = {
  formatStatus,
};

function formatStatus(status: ParticipantPaymentStatus): string {
  switch (status) {
    case 'pending-diver':
      return 'En attente du plongeur';
    case 'pending-club':
      return 'En attente du club';
    case 'rejected':
      return 'Refusé';
    case 'accepted':
      return 'Payé';
  }
  // default
  return status;
}
