import { ClubProductPackageAttributeProductType } from '@mabadive/app-common-model';

export const productTypeFormatter = {
  format,
};

function format(productType: ClubProductPackageAttributeProductType): string {
  switch (productType) {
    case 'fee':
      return 'Prestation';
    case 'rental':
      return 'Location';
    case 'sale':
      return 'Vente';
    default:
      return productType;
  }
}
