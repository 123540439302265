import { APP_COLORS } from '@mabadive/app-common-model';
import { Theme } from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/styles/withStyles';
import { responsiveAttributesValues } from '../../../layout';
import { appStylesHelper } from './appStylesHelper.service';

export function buildGlobalFormCardStyles(
  theme: Theme,
): CreateCSSProperties<{}> {
  return {
    '&.MuiCard-root': {
      // border: '3px solid #eb4f26',
      // border: '3px solid #ff3b07',
      border: '2px dashed #ccc',
      // borderColor: theme.palette.primary.main,
      ...responsiveAttributesValues(theme, {
        padding: {
          values: [
            theme.spacing(0.2),
            theme.spacing(0.4),
            theme.spacing(0.6),
            theme.spacing(0.8),
            theme.spacing(1),
          ],
          suffix: 'px',
        },
      }),
      // boxShaddow: '0 0 0 5px rgba(0, 100, 255, 0.5)',
      '&.has-changes': {
        borderColor: APP_COLORS.update,
        // boxShaddow: '0 0 0 5px rgba(50, 100, 255, 0.5)',
      },
      overflow: 'visible !important', // to make auto-complete options visible
      '& .form-label, & label': {
        display: 'block',
        fontSize: theme.typography.body1.fontSize,
        // color: theme.palette.text.hint,
        color: theme.palette.text.secondary,
        margin: '5px 5px 0 0',
        '& .MuiTypography-overline': {
          fontSize: '0.9rem',
        },
      },
      '& .MuiCardHeader-root': {
        ...responsiveAttributesValues(theme, {
          padding: {
            values: [
              theme.spacing(0.2),
              theme.spacing(0.4),
              theme.spacing(0.6),
              theme.spacing(0.8),
              theme.spacing(1),
            ],
            suffix: 'px',
          },
        }),
        '& .MuiTypography-h5': {
          ...responsiveAttributesValues(theme, {
            fontSize: appStylesHelper.getFontSizeResponsive('lg'),
          }),
        },
      },
      '& .MuiCardContent-root': {
        ...responsiveAttributesValues(theme, {
          padding: {
            values: [
              theme.spacing(0.2),
              theme.spacing(0.4),
              theme.spacing(0.6),
              theme.spacing(0.8),
              theme.spacing(1),
            ],
            suffix: 'px',
          },
        }),
        '& .MuiInputBase-root': {
          ...responsiveAttributesValues(theme, {
            fontSize: appStylesHelper.getFontSizeResponsive('md'),
          }),
        },
      },
      '& .form-label': {
        transform: 'translate(-12.5%, 0px) scale(0.75)',
        '& .MuiFormLabel-asterisk': {
          color: APP_COLORS.red,
        },
        '&.Mui-error': {
          color: APP_COLORS.red,
        },
      },
      '& .form-input': {
        display: 'block',
        margin: '2px 5px 0 0',
      },
      '&.MuiPaper-root': {
        overflow: 'initial',
      },
    },
  };
}
