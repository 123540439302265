import {
  AccommodationPickup,
  AccommodationPickupParticipant,
  AccommodationPickupSession,
  BookingJourneyStep,
  CompanyAccommodation,
  DiveSessionResumeFull,
  DiveSessionResumeParticipant,
  SessionPickup,
} from '@mabadive/app-common-model';
import { dataSorter, dateService } from '../../data';

export const diveCenterSessionPickupsBuilder = { buildSessionPickups };

function buildSessionPickups({
  sessions,
  accommodations,
  diveCenterId,
}: {
  sessions: DiveSessionResumeFull[];
  accommodations: CompanyAccommodation[];
  diveCenterId: string;
}): SessionPickup[] {
  const x = (sessions ?? []).reduce((sessionsPickupResult, s) => {
    const sessionDate = dateService.getUTCDateSetTime(s.time);
    for (const p of s.participants) {
      const step = findSessionPickupStep({
        participant: p,
        sessionDate,
        diveCenterId,
      });

      if (step) {
        // on filtre seulement les étapes du centre de plongée de la session qui ont du pickup actif

        const sessionTime = s.time;
        const accommodationId = step.accommodationId;

        let sessionPickup: SessionPickup = sessionsPickupResult.find(
          (x) => x.sessionTime.getTime() === sessionTime.getTime(),
        );

        if (!sessionPickup) {
          sessionPickup = {
            sessionTime,
            sessionsIds: [],
            accommodationsPickups: [],
            accommodationPickupsTimes: {},
          };
          sessionsPickupResult.push(sessionPickup);
        }
        sessionPickup.sessionsIds.push(s._id);
        if (s.details?.accommodationPickupsTimes) {
          // merge times overrides (chaque session doit contenir la configuration, pour éviter les problèmes si on en supprime ou ajoute une)
          sessionPickup.accommodationPickupsTimes = {
            ...sessionPickup.accommodationPickupsTimes,
            ...s.details.accommodationPickupsTimes,
          };
        }

        let accommodationPickup: AccommodationPickup =
          sessionPickup.accommodationsPickups.find(
            (x) => x.accommodation._id === accommodationId,
          );
        if (!accommodationPickup) {
          const accommodation = accommodations.find(
            (x) => x._id === step.accommodationId,
          );
          if (!accommodation) {
            console.warn(
              `Accompodation ${step.accommodationId} not found for diver ${p.diver?.lastName} ${p.diver?.firstName} ${p.diver?._id}`,
            );
          }
          if (accommodation?.pickup?.enabled) {
            const pickupTime =
              sessionPickup.accommodationPickupsTimes[accommodationId] ??
              dateService.add(
                sessionTime,
                -accommodation.pickup?.offsetInMinutes ?? 0,
                'minute',
              );
            accommodationPickup = {
              pickupTime,
              accommodation,
              participants: [],
            };
            sessionPickup.accommodationsPickups.push(accommodationPickup);
          }
        }
        if (accommodationPickup) {
          const participant = accommodationPickup.participants.find(
            (x) => x.diver._id === p.diver._id,
          );

          if (!participant) {
            // let sessionPickup: SessionPickup = sessionsPickupResult.find(
            //   (x) => x.sessionTime.getTime() === sessionTime.getTime(),
            // );
            // on recherche un pickup précédent
            const previousPickupParticipant = findPreviousParticipant({
              sessionsPickupResult,
              p,
              maxDelayInMinutes: 3 * 60,
              session: s,
            });

            const session: AccommodationPickupSession = {
              reference: s.reference,
              time: s.time,
            };
            // le participant a déjà un pickup plus tôt dans la journée
            if (!previousPickupParticipant) {
              const newParticipant: AccommodationPickupParticipant = {
                diver: p.diver,
                dailySessions: [session],
              };
              accommodationPickup.participants.push(newParticipant);
            } else {
              previousPickupParticipant.dailySessions.push(session);
            }
          }
        }
      }
    }
    return sessionsPickupResult;
  }, [] as SessionPickup[]);

  const results = x
    .map((x) => ({
      ...x,
      accommodationsPickups: dataSorter.sortMultiple(
        x.accommodationsPickups.filter((x) => x.participants.length !== 0),
        {
          getSortAttributes: (x) => [
            {
              value: x.pickupTime,
            },
          ],
        },
      ),
    }))
    .filter((x) => x.accommodationsPickups.length !== 0);

  return dataSorter.sortMultiple(results, {
    getSortAttributes: (x) => [
      {
        value: x.sessionTime,
      },
    ],
  });
}

function findSessionPickupStep({
  participant,
  sessionDate,
  diveCenterId,
}: {
  participant: DiveSessionResumeParticipant;
  sessionDate: Date;
  diveCenterId: string;
}): BookingJourneyStep {
  const elligibleSteps = (participant.bookingJourney?.steps ?? []).filter(
    (s) =>
      s?.diveCenterId === diveCenterId &&
      s?.accommodationId &&
      s?.pickup?.active,
  );
  if (elligibleSteps.length > 0) {
    if (elligibleSteps.length === 1) {
      return elligibleSteps[0]; // une seule étape: on ne vérifie pas les dates
    }
    // on exclue celles dont les dates ne correspondent pas
    const validDatesStep = elligibleSteps.filter((x) => {
      if (
        x.arrival?.date &&
        dateService.isBefore(sessionDate, x.arrival?.date)
      ) {
        return false;
      }
      if (
        x.departure?.date &&
        dateService.isBefore(x.departure?.date, sessionDate)
      ) {
        return false;
      }
      return true;
    });

    if (elligibleSteps.length > 0) {
      if (validDatesStep.length === 1) {
        // une seule étape
        return validDatesStep[0];
      }
      // plusieurs, on filtre plus strict
      const validDatesStepStrict = validDatesStep.filter(
        (x) => !!x.arrival?.date && !!x.departure?.date,
      );
      if (validDatesStepStrict.length > 0) {
        return validDatesStepStrict[0]; // on retourne la première qui a des dates renseignées
      }
      // on retourne la première, faute de mieux
      return validDatesStep[0];
    }

    // on retourne la première, faute de mieux
    return elligibleSteps[0];
  }
}

function findPreviousParticipant({
  session,
  sessionsPickupResult,
  p,
  maxDelayInMinutes,
}: {
  session: DiveSessionResumeFull;
  sessionsPickupResult: SessionPickup[];
  p: DiveSessionResumeParticipant;
  maxDelayInMinutes: number;
}) {
  return sessionsPickupResult.reduce((acc1, sessionPickup) => {
    if (!acc1) {
      return sessionPickup.accommodationsPickups.reduce(
        (acc2, accommodationPickup) => {
          if (!acc2) {
            return accommodationPickup.participants.find((x) => {
              if (x.diver._id === p.diver._id) {
                const lastSession = x.dailySessions[x.dailySessions.length - 1];
                if (
                  session.time.getTime() - lastSession.time.getTime() <
                  maxDelayInMinutes * 60 * 1000
                ) {
                  return true; // plongées successive, on les regroupe dans le même pickup
                }
              }
              return false;
            });
          }
          return acc2;
        },
        acc1,
      );
    }
    return acc1;
  }, null as AccommodationPickupParticipant);
}
