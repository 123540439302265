import {
  Customer_ClubSettingsPublicCustomer,
  OnlineBookingOrderArticleFull,
} from '@mabadive/app-common-model';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAppCurrencyMain } from '../../../../../pages';
import { useAppRouter } from '../../../../_core/data/hooks/useAppRouter';
import { AppPriceLabel } from '../../../../club/modules/_common/ui';
import { useDiveCenterShoppingCart } from '../services';
import { useDiveCenterShoppingCartTotalDetails } from './useDiveCenterShoppingCartTotalDetails.hook';

const DiveCenterShoppingCart = ({
  clubPublicReference,
  orderArticlesFull,
  clubSettings,
  children,
}: {
  clubPublicReference: string;
  orderArticlesFull: OnlineBookingOrderArticleFull[];
  clubSettings: Customer_ClubSettingsPublicCustomer;
  children?: React.ReactNode | React.ReactNode[];
}) => {
  const { cart } = useDiveCenterShoppingCart();
  const history = useHistory();
  const clubPublicSettings = clubSettings?.publicSettings;
  const mainCurrency = useAppCurrencyMain({ clubPublicSettings });

  const { amountToPayNow, amountToPayLater } =
    useDiveCenterShoppingCartTotalDetails({ cart });

  const appRouter = useAppRouter();

  return (
    <section
      aria-labelledby="summary-heading"
      className="app-card app-px-content py-8"
    >
      <h2 id="summary-heading" className="text-lg font-medium text-gray-900">
        Récapitulatif de la commande
      </h2>

      <dl className="mt-6 space-y-4">
        {amountToPayNow > 0 && (
          <div className="flex items-center justify-between text-app-red">
            <dt className="text-base font-medium sm:text-lg uppercase">
              A payer maintenant
            </dt>
            <dd className="text-sm sm:text-base font-medium text-gray-900">
              <AppPriceLabel
                amount={amountToPayNow}
                mainCurrency={mainCurrency}
              />
            </dd>
          </div>
        )}
        {amountToPayLater > 0 && (
          <div className="flex items-center justify-between  text-app-primary">
            <dt className="text-base font-medium sm:text-lg uppercase">
              A payer{amountToPayNow > 0 && ' plus tard'}
            </dt>
            <dd className="text-sm sm:text-base font-medium text-gray-900">
              <AppPriceLabel
                amount={amountToPayLater}
                mainCurrency={mainCurrency}
              />
            </dd>
          </div>
        )}

        <div className="flex items-center justify-between border-t border-gray-200 pt-4">
          <dt className="text-base sm:text-lg font-medium text-gray-900 uppercase">
            Total commande
          </dt>
          <dd className="text-base font-medium text-gray-900">
            <AppPriceLabel
              amount={amountToPayLater + amountToPayNow}
              mainCurrency={mainCurrency}
            />
          </dd>
        </div>
      </dl>

      {children && children}
    </section>
  );
};

export default DiveCenterShoppingCart;
