/* eslint-disable jsx-a11y/alt-text */
import {
  ClubPublicSettings,
  CustomerEcommerceProductArticleFull,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { useAppCurrencyMain } from '../../../../../../pages';
import { AppPriceLabel } from '../../../../../club/modules/_common/ui';
import { EcommerceProductArticlePreviewCover_Image } from './EcommerceProductArticlePreviewCover_Image';
import { EcommerceProductArticlePreviewCover_SubTitle } from './EcommerceProductArticlePreviewCover_SubTitle';
import { EcommerceProductArticlePreviewCover_Title } from './EcommerceProductArticlePreviewCover_Title';
export const EcommerceProductArticlePreviewCover = ({
  productArticleFull,
  clubPublicSettings,
  showCoverImage,
  showTitle,
  showPrices,
  children,
  onClick,
  className,
}: {
  productArticleFull: CustomerEcommerceProductArticleFull;
  clubPublicSettings: ClubPublicSettings;
  showCoverImage: boolean;
  showTitle: boolean;
  showPrices: boolean;
  children?: React.ReactNode | React.ReactNode[];
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  className?: string;
}) => {
  const mainCurrency = useAppCurrencyMain({ clubPublicSettings });
  const pricing = productArticleFull?.article?.pricing;
  return productArticleFull ? (
    <div
      className={clsx(
        'flex flex-col overflow-y-auto',
        onClick && 'app-card-highlight cursor-pointer',
        className,
      )}
      onClick={(e) => onClick && onClick(e)}
    >
      {showCoverImage && (
        <EcommerceProductArticlePreviewCover_Image
          productArticleFull={productArticleFull}
        />
      )}
      <div className="h-full py-2 px-4 flex-grow flex flex-col gap-4 justify-between">
        <div>
          {showTitle && (
            <>
              <EcommerceProductArticlePreviewCover_Title
                productArticleFull={productArticleFull}
                clubPublicSettings={clubPublicSettings}
              />{' '}
              <EcommerceProductArticlePreviewCover_SubTitle
                productArticleFull={productArticleFull}
                clubPublicSettings={clubPublicSettings}
              />
            </>
          )}
          {showPrices && pricing?.prices?.length > 0 && (
            <table className="mt-4 w-full">
              <tbody>
                {pricing?.prices.map((x, i) => (
                  <tr key={i}>
                    {pricing?.prices.length > 1 && (
                      <th className="text-left font-medium text-gray-500 uppercase">
                        {x.label}
                      </th>
                    )}
                    <td className="text-right font-bold text-gray-800">
                      <AppPriceLabel
                        amount={x.unitPrice}
                        mainCurrency={mainCurrency}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        {children && children}
      </div>
    </div>
  ) : null;
};
