import { AuthUserRole } from '@mabadive/app-common-model';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { authenticationStore } from './authenticationStore.service';
import { AppSecurityUser } from './model';

export const authenticationChecker = {
  isAuthorized,
  hasRequiredRoles,
  hasRequiredRole,
  hasRequiredRolesOneOf,
};

function isAuthorized({
  requiredRoles,
}: {
  requiredRoles: AuthUserRole[];
}): Observable<boolean> {
  if (requiredRoles) {
    return authenticationStore.auth.get().pipe(
      map((auth) => {
        if (!hasRequiredRoles(auth.user, requiredRoles)) {
          return false;
        }
        return true;
      }),
    );
  }
  return of(true);
}

function hasRequiredRoles(
  appSecurityUser: Pick<AppSecurityUser, 'roles'>,
  requiredRoles: AuthUserRole[],
): boolean {
  return (
    !!appSecurityUser &&
    !!appSecurityUser.roles &&
    requiredRoles.find(
      (requiredRole) => !hasRequiredRole(appSecurityUser, requiredRole),
    ) === undefined
  );
}

function hasRequiredRolesOneOf(
  appSecurityUser: Pick<AppSecurityUser, 'roles'>,
  requiredRoles: AuthUserRole[],
): boolean {
  return (
    !!appSecurityUser &&
    !!appSecurityUser.roles &&
    requiredRoles.find((requiredRole) =>
      hasRequiredRole(appSecurityUser, requiredRole),
    ) !== undefined
  );
}

function hasRequiredRole(
  appSecurityUser: Pick<AppSecurityUser, 'roles'>,
  requiredRole: AuthUserRole,
): boolean {
  return (
    !!appSecurityUser &&
    !!appSecurityUser.roles &&
    appSecurityUser.roles.indexOf(requiredRole) !== -1
  );
}
