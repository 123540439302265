import {
  ClubDiver,
  ClubParticipantPurchasedPackageInfo,
  ClubPurchasePackagePaymentStatus,
  ClubSettingsUIPlanningPayment,
  ClubSettingsUIPlanningShowPassMode,
  ColoredTag,
} from '@mabadive/app-common-model';

export const participantTagPaymentBuilder = {
  buildTagPayment,
  buildTagBookingDeposit,
  buildTagParticipantPayment,
  buildTagParticipantPaymentForSecuritySheetExport,
};

export type ParticipantTagPaymentBuilderProps = {
  purchaseStatus: 'todo' | 'done';
  purchasePaymentStatus?: ClubPurchasePackagePaymentStatus;
  passStatus?: 'open' | 'full';
  avatarLabel?: string;
  isPast: boolean;
};
function buildTagParticipantPayment({
  diver,
  settingsUIPlanning,
  purchasePaymentStatus,
  purchasePaymentPending,
  activePurchasedPackage,
  isPast,
}: {
  diver?: Pick<ClubDiver, 'birthdate' | 'bookingDeposits'>;
  settingsUIPlanning: ClubSettingsUIPlanningPayment;
  purchasePaymentStatus: ClubPurchasePackagePaymentStatus;
  purchasePaymentPending: boolean;
  activePurchasedPackage: ClubParticipantPurchasedPackageInfo;
  isPast: boolean;
}): ColoredTag {
  purchasePaymentStatus = purchasePaymentPending
    ? 'todo' // force status if payment is pending
    : purchasePaymentStatus;
  const {
    showPaymentFlagNotPayed,
    showPaymentFlagNotPurchased,
    showPaymentFlagNotPurchasedPass,
    showPaymentFlagNotPurchasedPassMode,
    showPaymentFlagPayed,
  } = settingsUIPlanning;

  let avatarLabel: string;
  if (
    showPaymentFlagNotPurchasedPass &&
    activePurchasedPackage?.creditsTotalCount > 0 &&
    purchasePaymentStatus === undefined
  ) {
    if (showPaymentFlagNotPurchasedPassMode === 'remaining') {
      // 2 (plongées restantes sur le pass)
      avatarLabel = `# ${
        activePurchasedPackage?.creditsTotalCount -
        activePurchasedPackage?.consumedTotalCount
      }`;
    } else {
      // 8/10 (par défaut)
      avatarLabel = `# ${activePurchasedPackage?.consumedTotalCount}/${activePurchasedPackage?.creditsTotalCount}`;
    }
    if (
      activePurchasedPackage?.consumedTotalCount +
        activePurchasedPackage?.toImputeBeforeCount >=
      activePurchasedPackage?.creditsTotalCount
    ) {
      return buildTagPayment({
        purchaseStatus: 'todo',
        passStatus: 'full',
        avatarLabel,
        isPast,
      });
    } else {
      return buildTagPayment({
        purchaseStatus: 'todo',
        passStatus: 'open',
        avatarLabel,
        isPast,
      });
    }
  }

  if (!showPaymentFlagNotPurchased && purchasePaymentStatus === undefined) {
    return;
  }
  if (
    !showPaymentFlagNotPayed &&
    (purchasePaymentStatus === 'todo' || purchasePaymentStatus === 'partial')
  ) {
    return;
  }
  if (!showPaymentFlagPayed && purchasePaymentStatus === 'done') {
    return;
  }

  if (diver && (!purchasePaymentStatus || purchasePaymentStatus === 'todo')) {
    const tag = buildTagBookingDeposit({
      diver,
    });
    if (tag) {
      return tag;
    }
  }
  const tag = buildTagPayment({
    purchaseStatus: purchasePaymentStatus ? 'done' : 'todo',
    purchasePaymentStatus,
    avatarLabel,
    isPast,
  });

  return tag;
}

function buildTagParticipantPaymentForSecuritySheetExport({
  showPaymentFlagNotPurchasedPassMode,
  purchasePaymentStatus,
  purchasePaymentPending,
  activePurchasedPackage,
  isPast,
}: {
  showPaymentFlagNotPurchasedPassMode: ClubSettingsUIPlanningShowPassMode;
  purchasePaymentStatus: ClubPurchasePackagePaymentStatus;
  purchasePaymentPending: boolean;
  activePurchasedPackage: ClubParticipantPurchasedPackageInfo;
  isPast: boolean;
}): string {
  purchasePaymentStatus = purchasePaymentPending
    ? 'todo' // force status if payment is pending
    : purchasePaymentStatus;

  let label: string;
  if (
    activePurchasedPackage?.creditsTotalCount > 0 &&
    purchasePaymentStatus === undefined
  ) {
    if (showPaymentFlagNotPurchasedPassMode === 'remaining') {
      // 2 (plongées restantes sur le pass)
      label = `# ${
        activePurchasedPackage?.creditsTotalCount -
        activePurchasedPackage?.consumedTotalCount
      }`;
    } else {
      // 8/10 (par défaut)
      label = `# ${activePurchasedPackage?.consumedTotalCount}/${activePurchasedPackage?.creditsTotalCount}`;
    }
  } else if (purchasePaymentStatus === 'done') {
    label = 'ok';
  }

  return label;
}

function buildTagPayment({
  purchaseStatus,
  purchasePaymentStatus,
  passStatus,
  avatarLabel,
  isPast,
}: ParticipantTagPaymentBuilderProps): ColoredTag {
  const isPass = purchaseStatus === 'todo' && !!passStatus;

  const color =
    purchaseStatus === 'todo'
      ? passStatus === 'open'
        ? '#000080'
        : passStatus === 'full'
        ? isPast
          ? '#b52206'
          : '#aaa'
        : isPast
        ? '#b52206'
        : '#555'
      : purchasePaymentStatus === 'todo'
      ? '#e0831b'
      : purchasePaymentStatus === 'partial'
      ? '#e0831b'
      : purchasePaymentStatus === 'done'
      ? '#008000'
      : isPast
      ? '#b52206'
      : '#555';

  return {
    shape: 'square',
    reference: 'paiement',
    widthAuto: !!avatarLabel,
    avatar: {
      label: avatarLabel ?? (isPass ? '#' : '$'),
      color: '#fff',
      background: color,
    },
    content: {
      label: 'Paiement',
      color: '#fff',
      background: color,
    },
  };
}

function buildTagBookingDeposit({
  diver,
}: {
  diver?: Pick<ClubDiver, 'bookingDeposits'>;
}): ColoredTag {
  const isActive =
    diver?.bookingDeposits?.hasActive || diver?.bookingDeposits?.hasDraft;

  if (isActive) {
    const color = diver?.bookingDeposits?.hasActive ? '#008000' : '#e0831b';

    return {
      shape: 'square',
      reference: 'booking-deposit',
      widthAuto: false,
      avatar: {
        label: '*',
        color: '#fff',
        background: color,
      },
      content: {
        label: 'Acompte',
        color: '#fff',
        background: color,
      },
    };
  }
}
