"use strict";
exports.__esModule = true;
exports.EXPORT_DEF_COLORS = exports.EXPORT_DEF_COLORS_BLACK_WHITE = exports.EXPORT_DEF_COLORS_FULL = void 0;
exports.EXPORT_DEF_COLORS_FULL = {
    scheme: 'colored',
    dark: '#333333',
    gray50: '#f9fafb',
    gray100: '#f1f5f9',
    gray150: '#ebeef2',
    gray200: '#e5e7eb',
    gray250: '#dbdee3',
    gray300: '#d1d5db',
    gray400: '#9ca3af',
    gray500: '#6b7280',
    gray600: '#4b5563',
    gray700: '#374151',
    gray800: '#1f2937',
    gray900: '#111827',
    "default": '#333333',
    white: '#ffffff',
    important: '#B52206',
    info: '#1a7498',
    success: '#008000',
    warn: '#ec9a3c',
    danger: '#B52206'
};
exports.EXPORT_DEF_COLORS_BLACK_WHITE = {
    scheme: 'black-white',
    dark: '#333333',
    gray50: '#f9fafb',
    gray100: '#f1f5f9',
    gray150: '#ebeef2',
    gray200: '#e5e7eb',
    gray250: '#dbdee3',
    gray300: '#d1d5db',
    gray400: '#9ca3af',
    gray500: '#6b7280',
    gray600: '#4b5563',
    gray700: '#374151',
    gray800: '#1f2937',
    gray900: '#111827',
    "default": '#333333',
    white: '#ffffff',
    important: '#111111',
    info: '#555555',
    success: '#333',
    warn: '#222222',
    danger: '#111111'
};
exports.EXPORT_DEF_COLORS = {
    'black-white': exports.EXPORT_DEF_COLORS_BLACK_WHITE,
    colored: exports.EXPORT_DEF_COLORS_FULL
};
