import { DiveSession } from '@mabadive/app-common-model';

export type DiveSessionStringFormat = 'prefix-date-name' | 'name' | 'date-long' | 'date-time-short' | 'date-short' | 'date-long-time' | 'time';

export const diveSessionFormatter = {
  formatDiveSession,
};

const DATE_TIME_FORMAT_LONG = 'dddd DD MMMM YYYY (HH:mm)';
const DATE_TIME_FORMAT_SHORT = 'ddd DD/MM/YYYY (HH:mm)';
const DATE_FORMAT_LONG = 'dddd DD MMMM YYYY';
const DATE_FORMAT_SHORT = 'DD/MM/YYYY';
const TIME_FORMAT_SHORT = 'HH:mm';

function formatDiveSession(
  diveSession: Pick<DiveSession, 'name' | 'time'>,
  {
    format,
    formatUTC,
  }: {
    format: DiveSessionStringFormat;
    formatUTC: (date: Date, format: string) => string;
  },
): string {
  if (!diveSession) {
    return null;
  }
  if (format === 'name') {
    return getNameLabel(diveSession);
  } if (format === 'date-long-time') {
    return formatUTC(diveSession.time, DATE_TIME_FORMAT_LONG);
  } if (format === 'date-long') {
    return formatUTC(diveSession.time, DATE_FORMAT_LONG);
  } if (format === 'date-short') {
    return formatUTC(diveSession.time, DATE_FORMAT_SHORT);
  } if (format === 'time') {
    return formatUTC(diveSession.time, TIME_FORMAT_SHORT);
  } if (format === 'date-time-short') {
    return formatUTC(diveSession.time, DATE_TIME_FORMAT_SHORT);
  } if (format === 'prefix-date-name') {
    const dateLabel = formatUTC(diveSession.time, DATE_TIME_FORMAT_LONG);
    const sessionName = getNameLabel(diveSession, ' - ');
    return `Plongée du ${dateLabel} ${sessionName}`;
  }
}

function getNameLabel(diveSession: Pick<DiveSession, 'name'>, prefix = ''): string {
  return diveSession.name ? `${prefix}${diveSession.name}` : '';
}
