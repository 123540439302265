import {
  bookingJourneyStepTransformer,
  dateService,
  nameFormatter,
} from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { DiveCenterBookingPageAggregatedBookingResume } from '../../model';
import { PeriodDatesLabel } from './PeriodDatesLabel';

export function DiveCenterBookingPageTab1BookingResumeDates({
  aggregatedBookingResume,
}: {
  aggregatedBookingResume: DiveCenterBookingPageAggregatedBookingResume;
}) {
  const {
    participantsCount,
    isUniqueMember,
    booking,
    datesRange,
    clubResume,
    diveCenter,
    bookingMemberContactFull,
    totalDivesCount,
    totalSessionsCount,
  } = aggregatedBookingResume;

  const clubSettings = clubResume?.clubSettings;
  const diveCenterPublicData = diveCenter?.publicData;
  const clubPublicData = clubSettings?.publicData;
  const clubPublicSettings = clubSettings?.publicSettings;

  const hasBookingDates = !!datesRange.minDate;

  const journeyDates = useMemo(
    () => bookingJourneyStepTransformer.getJourneyDates(booking),
    [booking],
  );

  const hasJourneyDates = !!journeyDates?.departure || !!journeyDates?.arrival;

  return (
    <div>
      <span className="font-bold">
        {nameFormatter.formatFullName(bookingMemberContactFull.diver)}
      </span>
      {!isUniqueMember && (
        <span className="">
          {' - '}
          <span className="">{participantsCount}</span> participants
        </span>
      )}
      {hasJourneyDates ? (
        <>
          {' '}
          {' - séjour '}
          <PeriodDatesLabel
            beginDate={journeyDates.arrival}
            endDate={journeyDates.departure}
            dateClassName="font-bold"
          />
        </>
      ) : hasBookingDates ? (
        <>
          {totalSessionsCount > 1
            ? ` -  ${totalSessionsCount} sessions`
            : ' -  1 session'}
          {totalDivesCount > 1
            ? ` - ${totalDivesCount} plongées au total`
            : ' - plongée '}{' '}
          {datesRange.minDate.getTime() === datesRange.maxDate.getTime() ? (
            <span className="">
              le{' '}
              <b className="">
                {dateService.formatUTC(datesRange.minDate, 'DD/MM/YYYY')}
              </b>
            </span>
          ) : (
            <span>
              <span className="">
                du{' '}
                <b className="">
                  {dateService.formatUTC(datesRange.minDate, 'DD/MM/YYYY')}
                </b>{' '}
                au{' '}
                <b className="">
                  {dateService.formatUTC(datesRange.maxDate, 'DD/MM/YYYY')}
                </b>
              </span>
            </span>
          )}
        </>
      ) : null}
    </div>
  );
}
