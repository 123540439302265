import { DiveServiceOrganizationReference } from '@mabadive/app-common-model';
import { dataSorter } from '../../data/sorter/dataSorter.service';

export const diveServiceOrganizationReferenceSorter = {
  sortOrganizationReferences,
  buildOrgSortReference,
};

function sortOrganizationReferences(organizationReferences: DiveServiceOrganizationReference[], options?: {
  asc?: boolean,
}): DiveServiceOrganizationReference[] {
  if (options === undefined) {
    options = {};
  }
  if (options.asc === undefined) {
    options.asc = true;
  }

  return dataSorter.sortMultiple(organizationReferences, {
    getSortAttributes: (x: DiveServiceOrganizationReference) => [{
      value: buildOrgSortReference(x),
      type: 'default',
    }],
    asc: options.asc,
  });
}

function buildOrgSortReference(organizationReference: DiveServiceOrganizationReference) {
  switch (organizationReference) {
    case 'ffessm': return '1';
    case 'anmp': return '2';
    case 'fsgt': return '3';
    case 'ssi': return '4';
    case 'padi': return '5';
  }

  return '9';
}
