import {
  ClubProductPackageOffer_ServiceCategory,
  ClubProductPackageOfferView,
} from '@mabadive/app-common-model';

export const clubProductPackageOffer_ServiceCategoryOrganizer = {
  groupOffersByServiceCategory,
};
function groupOffersByServiceCategory(
  productPackageOffers: ClubProductPackageOfferView[],
) {
  const offersByContext: {
    [key in ClubProductPackageOffer_ServiceCategory]: ClubProductPackageOfferView[];
  } = {
    'first-dive': [],
    training: [],
    explo: [],
    others: [],
    extras: [],
    store: [],
    'free-dive': [],
  };

  (productPackageOffers ?? []).forEach((offer) => {
    const diveAttributes = offer.productPackage.diveAttributes;
    const diveMode = diveAttributes?.diveMode;
    const type = offer.productPackage.type;
    const offerContext: ClubProductPackageOffer_ServiceCategory =
      type === 'product'
        ? 'extras'
        : type === 'store'
        ? 'store'
        : diveMode === 'first-dive'
        ? 'first-dive'
        : diveMode === 'training'
        ? 'training'
        : diveMode === 'free-dive'
        ? 'free-dive'
        : diveMode === 'autonomous' ||
          diveMode === 'supervised' ||
          diveMode === 'instructor' ||
          diveMode === 'autoSupervised'
        ? 'explo'
        : 'others';

    offersByContext[offerContext].push(offer);
  });
  return offersByContext;
}
