"use strict";
exports.__esModule = true;
exports.PARTICIPANT_BIRTHDAY_TAG = void 0;
exports.PARTICIPANT_BIRTHDAY_TAG = {
    reference: 'anniversaire',
    avatar: {
        label: '@',
        color: '#fff',
        background: '#d12ddb',
        appIcon: 'birthday'
    },
    content: {
        label: 'Anniversaire',
        color: '#fff',
        background: '#ea5f5f'
    }
};
