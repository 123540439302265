import {
  DIVE_CERTIFICATIONS_REFERENCE_MAP,
  DIVE_TRAININGS,
  DIVE_TRAININGS_REFERENCE_MAP,
  DiveCertification,
  DiveSessionResumeParticipant,
  DiveTraining,
  FIRST_DIVES,
  FIRST_DIVES_REFERENCE_MAP,
  OrgDataFirstDiveExt,
} from '@mabadive/app-common-model';
import { dataSorter } from '../../data';
import { nameFormatter } from '../string';

export const diveSessionResumeParticipantSorter = {
  sortSessionParticipants,
};

function sortSessionParticipants(
  participants: DiveSessionResumeParticipant[],
  {
    asc,
  }: {
    asc?: boolean;
  },
): DiveSessionResumeParticipant[] {
  return dataSorter.sortMultiple(participants, {
    getSortAttributes: (x: DiveSessionResumeParticipant) => {
      const { firstName, lastName } = nameFormatter.formatFullNameChunks(
        x?.diver,
      );

      return [
        {
          value: getSortByDiveModeAttribute(x),
        },
        {
          value: getSortByAutoSupervisedDiveModeAttribute(x),
        },
        {
          value: x.diveSessionGroupDiveGuide ? 1 : 0,
        },
        {
          value: getSortByMaxDepth(x),
        },
        {
          value: lastName,
          type: 'full-text',
        },
        {
          value: firstName,
          type: 'full-text',
        },
      ];
    },
    asc,
  });

  function getSortByDiveModeAttribute(x: DiveSessionResumeParticipant): any {
    switch (x.diveMode) {
      case 'unknown':
        return 0;
      case 'observer':
        return 1;
      case 'snorkeling':
        return 2;
      case 'snorkelingSupervised':
        return 3;
      case 'watchingTour':
        return 4;
      case 'first-dive':
        return 5;
      case 'training':
        return 6;
      case 'supervised':
        return 7;
      case 'autonomous':
        return 8;
      case 'free-dive':
        return 9;
      case 'free-dive-auto':
        return 10;
      case 'free-dive-training':
        return 11;
      case 'theoretical-training':
        return 12;
      case 'instructor':
        return 13;
      case 'autoSupervised':
        return 14;
    }
  }
  function getSortByAutoSupervisedDiveModeAttribute(
    x: DiveSessionResumeParticipant,
  ): any {
    switch (x.autoSupervisedDetails?.diveMode) {
      case 'training':
        return 5;
      case 'supervised':
        return 6;
      case 'autonomous':
        return 7;
      case 'instructor':
        return 10;
    }
  }

  function getSortByMaxDepth(x: DiveSessionResumeParticipant): any {
    if (
      x.diveMode === 'training' ||
      (x.diveMode === 'autoSupervised' &&
        x.autoSupervisedDetails?.diveMode === 'training')
    ) {
      const trainingReference =
        x.diveMode === 'autoSupervised'
          ? x.autoSupervisedDetails?.trainingReference
          : x.trainingReference;

      if (!trainingReference) {
        return undefined;
      }

      const training: DiveTraining =
        DIVE_TRAININGS_REFERENCE_MAP[trainingReference];

      if (!training) {
        console.error('training not found:', trainingReference, DIVE_TRAININGS);
      }

      return training?.sortReference;
    }
    if (x.diveMode === 'first-dive') {
      if (!x.firstDiveReference) {
        return undefined;
      }

      const firstDive: OrgDataFirstDiveExt =
        FIRST_DIVES_REFERENCE_MAP[x.firstDiveReference];

      if (!firstDive) {
        console.error(
          'firstDive not found:',
          x.firstDiveReference,
          FIRST_DIVES,
        );
      }

      return firstDive?.sortReference;
    }
    const certificationReference =
      x.diveMode === 'autoSupervised'
        ? x.autoSupervisedDetails?.certificationReference
        : x.certificationReference;

    if (!certificationReference) {
      return undefined;
    }

    const certification: DiveCertification =
      DIVE_CERTIFICATIONS_REFERENCE_MAP[certificationReference];

    if (!certification) {
      console.error('certification not found:', certificationReference);
      return undefined;
    }

    return certification?.sortReference;
  }
}
