"use strict";
exports.__esModule = true;
exports.DIVE_MODE_GROUPABLE_LIST = exports.DIVE_MODE_WITH_CERTIFICATION = exports.DIVE_MODE_BEGINNER = void 0;
exports.DIVE_MODE_BEGINNER = [
    'first-dive',
    'snorkeling',
    'snorkelingSupervised',
    'watchingTour',
    'observer',
    // pour le moment, 'free-dive' est ignoré de DIVE_MODE_BEGINNER et DIVE_MODE_WITH_CERTIFICATION pour ne pas l'afficher sur l'interface client
];
exports.DIVE_MODE_WITH_CERTIFICATION = [
    'supervised',
    'autonomous',
    'instructor',
    // pour le moment, 'free-dive' est ignoré de DIVE_MODE_BEGINNER et DIVE_MODE_WITH_CERTIFICATION pour ne pas l'afficher sur l'interface client
];
// TODO: rendre ça configurable?
exports.DIVE_MODE_GROUPABLE_LIST = [
    'first-dive',
    'training',
    'supervised',
    'autonomous',
    'free-dive-auto',
    'free-dive-training',
];
