import { ClubDiver, SAFETY_CERTIFICATIONS } from '@mabadive/app-common-model';

export const safetyCertificationFormatter = {
  formatDiverSafetyCertifications,
};

function formatDiverSafetyCertifications(
  diver: Pick<ClubDiver, 'divingCertification1' | 'divingCertification2'>,
  {
    format,
  }: {
    format: 'label';
  },
): string {
  const safetyCertificationReferences: string[] = (
    diver.divingCertification1?.safetyCertificationReferences ?? []
  )
    .concat(diver.divingCertification2?.safetyCertificationReferences ?? [])
    .filter((x) => !!x);
  return SAFETY_CERTIFICATIONS.filter((c) =>
    safetyCertificationReferences.includes(c.reference),
  )
    .map((x) => {
      if (format === 'label') {
        return x.label;
      }
      return x.label; // TODO ajouter d'autres modes
    })
    .join(', ');
}
