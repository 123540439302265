/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppCurrency,
  CustomerEcommerceProductArticleFull,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { UseFormReturn, useFieldArray } from 'react-hook-form';
import { AppPriceLabel } from '../../../../../../club/modules/_common/ui';
import { CustomerProductsPage_BookProductPanelFormModel_Item } from '../_model';
import { CustomerProductsPage_BookProductPanelLocalState } from '../useCustomerProductsPage_BookProductPanelLocalState.hook';
import { CustomerProductsPage_BookProductPanel_FillParticipants_PriceParticipants_item } from './CustomerProductsPage_BookProductPanel_FillParticipants_PriceParticipants_item';
import { CustomerProductsPage_FormModel } from './model';

export const CustomerProductsPage_BookProductPanel_FillParticipants_PriceParticipants =
  ({
    localState,
    localForm,
    priceIndex,
    priceParticipant,
    productArticleFull,
    mainCurrency,
    className = '',
  }: {
    localState: CustomerProductsPage_BookProductPanelLocalState;
    priceIndex: number;
    priceParticipant: CustomerProductsPage_BookProductPanelFormModel_Item;
    productArticleFull: CustomerEcommerceProductArticleFull;
    mainCurrency: AppCurrency;
    localForm: UseFormReturn<CustomerProductsPage_FormModel, any, undefined>;
    className?: string;
  }) => {
    const { state } = localState;
    const { control } = state.form;

    const baseParticipantFormName = `items.${priceIndex}.participants` as const;
    const priceParticipantsFieldArray = useFieldArray({
      control: localForm.control,
      name: baseParticipantFormName,
    });

    return (
      <div className={clsx('grid gap-4', className)}>
        <div className="">
          <div className="w-full flex flex-row gap-4 justify-between items-start">
            <div className="uppercase font-bold text-gray-600">
              <div className="text-app-primary">
                {productArticleFull?.product?.name}
              </div>
              <div className="">{priceParticipant.price.label}</div>
            </div>
            <AppPriceLabel
              className="text-gray-500 font-bold"
              amount={priceParticipant.price.unitPrice}
              mainCurrency={mainCurrency}
            />
          </div>
          <div className="italic text-gray-500">
            {priceParticipant.price.comment}
          </div>
        </div>
        {priceParticipantsFieldArray.fields.map(
          (participant, participantIndex) => (
            <CustomerProductsPage_BookProductPanel_FillParticipants_PriceParticipants_item
              key={participantIndex}
              participant={participant}
              participantIndex={participantIndex}
              localForm={localForm}
              priceIndex={priceIndex}
            />
          ),
        )}
      </div>
    );
  };
