import { PurchasePaymentState } from '@mabadive/app-common-model';

export const purchasePaymentStateFormatter = {
  formatPaymentState,
};

function formatPaymentState(paymentState: PurchasePaymentState): string {
  switch (paymentState) {
    case 'pending':
      return 'En attente';
    case 'authorized':
      return 'Autorisé';
    case 'validated':
      return 'Confirmé';
  }
}
