import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppInputBooleanColor } from './AppInputBooleanColor.type';
import { AppInputBooleanType } from './AppInputBooleanType.type';

// TODO une fois complètement migré vers react-hook-form, migrer les composants de 'material-ui' vers '@headlessui/react' (voir https://tailwindui.com/components/application-ui/forms)
export function AppInputBoolean({
  clearable,
  name,
  description,
  className,
  labelColorClassName,
  labelFontClassName,
  type,
  value,
  onChange,
  color = 'blue',
  ...extraProps
}: {
  name?: string;
  description?: string;
  className?: string;
  labelColorClassName?: string;
  labelFontClassName?: string;
  clearable?: boolean;
} & {
  type?: AppInputBooleanType;
  value?: boolean | string;
  color?: AppInputBooleanColor;
  onChange?: (value: boolean) => void;
} & Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    'type' | 'value' | 'onChange' | 'color'
  >) {
  const isEmptyValue = useMemo(
    () => !value || ((value as string).trim && (value as string).trim() === ''),
    [value],
  );

  return (
    <div className={`inline-block ${className ?? ''}`}>
      <input
        {...extraProps}
        type={type}
        className={`
        ${color === 'red' ? 'focus:ring-red-400 text-red-400' : ''}
        ${isEmptyValue ? 'empty' : ''} ${
          extraProps.disabled ? 'border-gray-400 cursor-not-allowed' : ''
        }`}
        checked={value === true}
        onChange={() => {
          !!onChange && onChange(value !== true);
        }}
        // error={invalid}
      />{' '}
      {description && (
        <span
          className={clsx(
            'uppercase cursor-pointer',
            value && ' font-medium',
            labelFontClassName ?? 'text-xs',
            labelColorClassName ?? 'text-gray-500',
          )}
          onClick={() => {
            !!onChange && onChange(value !== true);
          }}
        >
          {description}
        </span>
      )}
    </div>
  );
}
