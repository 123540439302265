
import { Snackbar } from '@material-ui/core';
import React, { SyntheticEvent } from 'react';
import { useAppSnackbar } from './AppSnackbar.facade';
import { AppSnackbarContentWrapper } from './AppSnackbarContentWrapper';

export default function AppSnackbar() {

  const { loaded, snackbar } = useAppSnackbar();

  function handleClose(event?: SyntheticEvent, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    // setState({
    //   open: false
    // });
  }

  return (!loaded ? null :
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={snackbar.open}
      onClose={handleClose}
    >
      <AppSnackbarContentWrapper
        onClose={handleClose}
        variant={snackbar.type}
        message={snackbar.message}
      />
    </Snackbar>
  );
}