import {
  AppInquiryResponse_Status,
  CustomerInquiryWithResponse,
} from '@mabadive/app-common-model';
import { inquiryFormatter } from '@mabadive/app-common-services';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { AppIconsInquiry } from '../../../../../_core/modules/layout';
import { diveCenterBookingUrlBuilder } from '../../../diveCenterBookingUrlBuilder.service';

export function DiveCenterBookingPageTab5Inquiries_InquiryBadge({
  inquiryWithResponse,
  bookingId,
  bookingMemberId,
  diverId,
  clubPublicReference,
}: {
  inquiryWithResponse: CustomerInquiryWithResponse;
  bookingId: string;
  bookingMemberId: string;
  diverId: string;
  clubPublicReference: string;
  className?: string;
}) {
  const { inquiry, responses, lastResponse } = inquiryWithResponse;
  const type = inquiry.type;
  const Icon = AppIconsInquiry[inquiry.type];

  const inquiryTypeLabel = useMemo(
    () => inquiryFormatter.formatInquiryType(inquiry.type),
    [inquiry.type],
  );

  const status: AppInquiryResponse_Status = lastResponse?.status ?? 'draft';

  const inquiryResponseUrl = useMemo(() => {
    return diveCenterBookingUrlBuilder.buildBookingInquiryUrl({
      bookingId,
      clubPublicReference,
      bookingMemberId,
      diverId,
      inquiryResponseId: lastResponse?._id,
      inquiryId: inquiry._id,
    });
  }, [
    lastResponse?._id,
    bookingId,
    clubPublicReference,
    bookingMemberId,
    diverId,
    inquiry._id,
  ]);

  return (
    <Tippy delay={[200, 50]} placement="top" content={inquiryTypeLabel}>
      <Link
        to={inquiryResponseUrl}
        className={clsx(
          'place-self-center inline-flex px-1 py-1 items-center gap-1 text-base font-bold uppercase rounded-lg',
          'border',
          status === 'draft' || status === 'rejected'
            ? 'border-red-400 text-red-400'
            : status === 'submitted' || status === 'validated'
            ? type === 'medical-form'
              ? 'border-green-600 text-green-600'
              : type === 'parental-consent'
              ? 'border-blue-600 text-blue-600'
              : 'border-gray-600 text-gray-600'
            : 'border-gray-400 text-gray-400',
        )}
      >
        {Icon && <Icon className="h-5 w-5" />}{' '}
        {inquiry.name && <span>{inquiry.name}</span>}
      </Link>
    </Tippy>
  );
}
