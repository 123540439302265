import {
  CustomerEcommerceProductArticleFull,
  OnlineBookingOrderArticle,
  OnlineBookingOrderArticleFull,
} from '@mabadive/app-common-model';

export const onlineBookingOrderArticleFullBuilder = {
  buildArticles,
  buildArticle,
};

function buildArticles({
  orderArticles,
  productArticlesFull,
}: {
  orderArticles: OnlineBookingOrderArticle[];
  productArticlesFull: CustomerEcommerceProductArticleFull[];
}): OnlineBookingOrderArticleFull[] {
  return (orderArticles ?? [])
    .map((orderArticle) =>
      buildArticle({
        orderArticle,
        productArticlesFull,
      }),
    )
    .filter(Boolean);
}
function buildArticle({
  orderArticle,
  productArticlesFull,
}: {
  orderArticle: OnlineBookingOrderArticle;
  productArticlesFull: CustomerEcommerceProductArticleFull[];
}) {
  const productArticleFull = (productArticlesFull ?? []).find(
    (x) => orderArticle.articleId === x.article._id,
  );
  if (!productArticleFull) {
    return null;
  }
  const orderArticleFull: OnlineBookingOrderArticleFull = {
    ...orderArticle,
    productArticleFull,
  };
  return orderArticleFull;
}
