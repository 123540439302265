"use strict";
exports.__esModule = true;
exports.ORG_GROUP_FRENCH_DATA = void 0;
var ORG_GROUP_FRENCH_DATA_TRAININGS_const_1 = require("./ORG_GROUP_FRENCH_DATA_TRAININGS.const");
exports.ORG_GROUP_FRENCH_DATA = {
    orgGroupReference: 'cmas',
    orgGroupReferencev2: 'french',
    orgName: 'CMAS',
    firstDives: [
        {
            diveMode: 'first-dive',
            reference: 'BPT',
            label: "Bapt\u00EAme",
            maxDepth: 6,
            trainingDivesTotalCount: 1,
            compatibilityGroups: []
        },
        {
            diveMode: 'first-dive',
            reference: 'BPT++',
            label: "Bapt\u00EAme avanc\u00E9",
            maxDepth: 12,
            trainingDivesTotalCount: 1,
            compatibilityGroups: []
        },
        {
            diveMode: 'first-dive',
            reference: 'BPT-XL',
            label: "Bapt\u00EAme XL",
            maxDepth: 12,
            trainingDivesTotalCount: 1,
            compatibilityGroups: []
        },
        {
            diveMode: 'first-dive',
            reference: 'BPT-ENF',
            label: "Bapt\u00EAme Enfant",
            maxDepth: 12,
            trainingDivesTotalCount: 1,
            compatibilityGroups: []
        },
    ],
    certifications: [
        {
            reference: 'N1',
            label: 'Niveau 1',
            favourite: true,
            referenceDescription: 'PE20',
            supervised: {
                maxDepth: 20,
                defaultAptitude: 'PE20'
            },
            defaultDiveMode: 'supervised',
            nextSuggestedTrainingReference: 'FN2'
        },
        {
            reference: 'N2',
            label: 'Niveau 2',
            favourite: true,
            // referenceDescription: 'PA20 + PE40',
            supervised: {
                maxDepth: 40,
                defaultAptitude: 'PE40'
            },
            defaultDiveMode: 'autonomous',
            autonomous: {
                maxDepth: 20,
                defaultAptitude: 'PA20'
            },
            nextSuggestedTrainingReference: 'FN3'
        },
        {
            reference: 'N3',
            label: 'Niveau 3',
            favourite: true,
            // referenceDescription: 'PA20 + PE40',
            supervised: {
                maxDepth: 60,
                defaultAptitude: 'PE60'
            },
            autonomous: {
                maxDepth: 60,
                defaultAptitude: 'PA60'
            },
            defaultDiveMode: 'autonomous'
        },
        {
            reference: 'N4',
            label: 'Niveau 4',
            favourite: true,
            supervised: {
                maxDepth: 60,
                defaultAptitude: 'PE60'
            },
            autonomous: {
                maxDepth: 60,
                defaultAptitude: 'PA60'
            },
            supervisor: true,
            defaultDiveMode: 'autonomous'
        },
        {
            reference: 'N5',
            label: 'Niveau 5',
            favourite: true,
            supervised: {
                maxDepth: 60,
                defaultAptitude: 'PE60'
            },
            autonomous: {
                maxDepth: 60,
                defaultAptitude: 'PA60'
            },
            supervisor: true,
            defaultDiveMode: 'autonomous'
        },
        {
            reference: 'PE12',
            label: 'PE 12',
            supervised: {
                maxDepth: 12,
                defaultAptitude: 'PE12'
            },
            defaultDiveMode: 'supervised'
        },
        {
            reference: 'PA12',
            label: 'Autonome 12',
            supervised: {
                maxDepth: 12,
                defaultAptitude: 'PE12'
            },
            autonomous: {
                maxDepth: 12,
                defaultAptitude: 'PA12'
            },
            defaultDiveMode: 'supervised'
        },
        {
            reference: 'PA20',
            label: 'Autonome 20',
            supervised: {
                maxDepth: 20,
                defaultAptitude: 'PE20'
            },
            autonomous: {
                defaultAptitude: 'PA20',
                maxDepth: 20
            },
            defaultDiveMode: 'autonomous'
        },
        {
            reference: 'PE40',
            label: 'Encadré 40',
            supervised: {
                maxDepth: 40,
                defaultAptitude: 'PE40'
            },
            defaultDiveMode: 'supervised'
        },
        {
            reference: 'PE60',
            label: 'Encadré 60',
            supervised: {
                maxDepth: 60,
                defaultAptitude: 'PE60'
            },
            defaultDiveMode: 'supervised'
        },
        {
            reference: 'PA40',
            label: 'Autonome 40',
            autonomous: {
                maxDepth: 40,
                defaultAptitude: 'PA40'
            },
            defaultDiveMode: 'autonomous'
        },
        {
            reference: 'NXS',
            label: "Nitrox simple",
            nitrox: 'simple',
            defaultDiveMode: 'supervised'
        },
        {
            reference: 'NXC',
            label: "Nitrox confirm\u00E9",
            nitrox: 'advanced',
            defaultDiveMode: 'supervised'
        },
        {
            reference: 'NXT',
            label: "Trimix",
            nitrox: 'trimix',
            defaultDiveMode: 'supervised'
        },
        {
            reference: 'CCR',
            label: "Recycleur",
            nitrox: 'ccr',
            defaultDiveMode: 'autonomous'
        },
        {
            reference: 'JPB',
            label: "Jeune Plongeur de Bronze",
            supervised: {
                maxDepth: 6,
                defaultAptitude: 'PE12'
            },
            minAge: 8,
            maxAge: 14,
            defaultDiveMode: 'supervised'
        },
        {
            reference: 'JPA',
            label: "Jeune Plongeur d'Argent",
            supervised: {
                maxDepth: 6,
                defaultAptitude: 'PE12'
            },
            minAge: 10,
            maxAge: 14,
            defaultDiveMode: 'supervised'
        },
        {
            reference: 'JPO',
            label: "Jeune Plongeur d'Or",
            supervised: {
                maxDepth: 6,
                defaultAptitude: 'PE12'
            },
            minAge: 12,
            maxAge: 14,
            defaultDiveMode: 'supervised'
        },
        {
            reference: 'AQU',
            label: "Aquaticus",
            supervised: {
                maxDepth: 3,
                defaultAptitude: 'PE12'
            },
            minAge: 6,
            maxAge: 8,
            defaultDiveMode: 'supervised'
        },
        {
            reference: 'TET',
            label: "T\u00E9tardo",
            supervised: {
                maxDepth: 5,
                defaultAptitude: 'PE12'
            },
            minAge: 8,
            maxAge: 10,
            defaultDiveMode: 'supervised'
        },
        {
            reference: 'EXP',
            label: "Explorator",
            supervised: {
                maxDepth: 8,
                defaultAptitude: 'PE12'
            },
            minAge: 10,
            maxAge: 12,
            defaultDiveMode: 'supervised'
        },
        {
            reference: 'E1',
            label: "E1",
            defaultDiveMode: 'autonomous',
            supervisor: true,
            supervised: {
                maxDepth: 60,
                defaultAptitude: 'PE60'
            },
            autonomous: {
                maxDepth: 60,
                defaultAptitude: 'PA60'
            }
        },
        {
            reference: 'E2',
            label: "E2",
            defaultDiveMode: 'autonomous',
            supervisor: true,
            supervised: {
                maxDepth: 60,
                defaultAptitude: 'PE60'
            },
            autonomous: {
                maxDepth: 60,
                defaultAptitude: 'PA60'
            }
        },
        {
            reference: 'E3',
            label: "E3",
            defaultDiveMode: 'autonomous',
            supervisor: true,
            supervised: {
                maxDepth: 60,
                defaultAptitude: 'PE60'
            },
            autonomous: {
                maxDepth: 60,
                defaultAptitude: 'PA60'
            }
        },
        {
            reference: 'E4',
            label: "E4",
            defaultDiveMode: 'autonomous',
            supervisor: true,
            supervised: {
                maxDepth: 60,
                defaultAptitude: 'PE60'
            },
            autonomous: {
                maxDepth: 60,
                defaultAptitude: 'PA60'
            }
        },
        {
            reference: 'MF',
            label: "Moniteur F\u00E9d\u00E9ral",
            defaultDiveMode: 'autonomous',
            supervisor: true,
            supervised: {
                maxDepth: 60,
                defaultAptitude: 'PE60'
            },
            autonomous: {
                maxDepth: 60,
                defaultAptitude: 'PA60'
            }
        },
        {
            reference: 'SMF',
            label: "Stagiaire Moniteur F\u00E9d\u00E9ral",
            defaultDiveMode: 'autonomous',
            supervisor: true,
            supervised: {
                maxDepth: 60,
                defaultAptitude: 'PE60'
            },
            autonomous: {
                maxDepth: 60,
                defaultAptitude: 'PA60'
            }
        },
        {
            reference: 'BE',
            label: "Brevet d'\u00E9tat",
            defaultDiveMode: 'autonomous',
            supervisor: true,
            supervised: {
                maxDepth: 60,
                defaultAptitude: 'PE60'
            },
            autonomous: {
                maxDepth: 60,
                defaultAptitude: 'PA60'
            }
        },
        {
            reference: 'BP',
            label: "Brevet professionnel",
            defaultDiveMode: 'autonomous',
            supervisor: true,
            supervised: {
                maxDepth: 60,
                defaultAptitude: 'PE60'
            },
            autonomous: {
                maxDepth: 60,
                defaultAptitude: 'PA60'
            }
        },
        {
            reference: 'DE',
            label: "Dipl\u00F4me d'\u00E9tat",
            defaultDiveMode: 'autonomous',
            supervisor: true,
            supervised: {
                maxDepth: 60,
                defaultAptitude: 'PE60'
            },
            autonomous: {
                maxDepth: 60,
                defaultAptitude: 'PA60'
            }
        },
    ],
    specialities: [
        {
            reference: 'fr-nxs',
            specialityType: 'gaz',
            label: 'Nitrox simple',
            trainingType: 'gaz-certification',
            nitrox: 'simple'
        },
        {
            reference: 'fr-nxc',
            specialityType: 'gaz',
            label: 'Nitrox confirmé',
            trainingType: 'gaz-certification',
            nitrox: 'advanced'
        },
        {
            reference: 'fr-nxt',
            specialityType: 'gaz',
            label: 'Trimix',
            trainingType: 'gaz-certification',
            nitrox: 'trimix'
        },
        {
            reference: 'fr-dry-suit',
            specialityType: 'equipment',
            label: 'Étanche',
            trainingType: 'equipment-certification'
        },
        {
            reference: 'fr-rifa',
            specialityType: 'security',
            label: 'RIFA',
            trainingType: 'security-certification'
        },
    ],
    trainings: ORG_GROUP_FRENCH_DATA_TRAININGS_const_1.ORG_GROUP_FRENCH_DATA_TRAININGS
};
