import clsx from 'clsx';
import { FC, default as React } from 'react';
import { AppLoadableContentContainer } from '../AppLoadableContentContainer';
import { AppPageContainerProps } from './AppPageContainerProps.type';

export const AppPageContainer: FC<AppPageContainerProps> = ({
  children,
  retry,
  lastActionStatus = 'success',
  contentState = 'full',
  maxScreenClassName = 'max-w-screen-2xl',
  className,
}) => {
  return (
    <div
      id="app-scrollable-container"
      className={clsx(
        'w-full h-full flex flex-col',
        maxScreenClassName,
        className,
      )}
    >
      <AppLoadableContentContainer
        contentState={contentState}
        lastActionStatus={lastActionStatus}
        retry={retry}
      >
        {contentState !== 'none' && <>{children}</>}
      </AppLoadableContentContainer>
    </div>
  );
};
