import {
  BookingProductAttributesDive,
  EquipmentDescription,
} from '@mabadive/app-common-model';

export const equipmentDescriptionHelper = {
  isClubEquipment,
  isProductWithClubEquipment,
};

function isClubEquipment(desc1: EquipmentDescription): boolean {
  return (desc1 && !desc1.selfEquipped) ?? false;
}

function isProductWithClubEquipment(
  attributes: BookingProductAttributesDive,
): boolean {
  if (attributes.equipment?.provider === 'customer') {
    return false;
  }

  return true; // par défayt

  // pour mémoire, ce qu'on faisait avant le 09/09/2022 (à supprimer plus tard)
  // const jacket = equipmentDescriptionHelper.isClubEquipment(
  //   attributes?.equipment?.jacket,
  // );
  // const wetsuit = equipmentDescriptionHelper.isClubEquipment(
  //   attributes?.equipment?.wetsuit,
  // );

  // return jacket || wetsuit;
}
