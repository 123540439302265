/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Booking,
  Customer_ClubSettingsPublicCustomer,
  EQUIPMENT_TYPES,
  EQUIPMENT_TYPES_DEFAULT_CUSTOMER_SPACE_FULL_EQUIPMENT,
} from '@mabadive/app-common-model';
import { equipmentFormatter } from '@mabadive/app-common-services';
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useAppSecurityUser } from 'src/business/auth/services';
import {
  AppFormControlV2,
  AppInputBooleanSwitch,
  AppInputBooleanSwitchRHF,
  AppInputRHF,
} from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';
import { AppMessageSingleLine } from '../../../../../../_core/modules/layout';
import { AppIconsDiveEquipmentType } from '../../../../../../_core/modules/layout/icons/AppIconsDiveEquipment.const';
import { DiveCenterBookingMemberEditFormModel } from '../model';

export const DiveCenterBookingMemberEditFormEquipment = ({
  form,
  booking,
  participantsIndex,
  clubSettings,
  className = '',
}: {
  form: UseFormReturn<DiveCenterBookingMemberEditFormModel>;
  booking: Booking;
  participantsIndex: number;
  clubSettings: Customer_ClubSettingsPublicCustomer;
  className?: string;
}) => {
  const securityUser = useAppSecurityUser();

  const { control, setValue } = form;

  const baseFormName = `participants.${participantsIndex}` as const;

  const pendingList = booking.bookingCustomerConfig?.pendingList;
  const displayFields =
    clubSettings?.publicSettings?.customerSpace?.displayFields;

  const hasAnyBodyMeasurements =
    displayFields?.diversEquipmentInfo?.bodyMeasurements?.enabled &&
    (displayFields?.diversEquipmentInfo?.bodyMeasurements?.size?.edit ||
      displayFields?.diversEquipmentInfo?.bodyMeasurements?.weight?.edit ||
      displayFields?.diversEquipmentInfo?.bodyMeasurements?.shoeSize?.edit);

  const hasAnySelfEquipment =
    displayFields?.diversEquipmentInfo?.selfEquipment?.enabled &&
    EQUIPMENT_TYPES.find(
      (equipmentType) =>
        displayFields?.diversEquipmentInfo?.selfEquipment?.details?.[
          equipmentType
        ]?.edit,
    );

  const [equipmentProvider] = useWatch({
    control,
    name: [`${baseFormName}.diver.defaultDiveConfig.equipment.provider`],
  });

  return (
    <div className={className}>
      {pendingList?.diversEquipmentInfo && (
        <div className={'w-full flex flex-col gap-4'}>
          {hasAnySelfEquipment && (
            <div className="flex flex-col gap-2">
              <AppMessageSingleLine className="block">
                Si vous venez avec vos équipements, veuillez préciser lesquels.
              </AppMessageSingleLine>
              <div className="md:col-span-2 w-full flex flex-col xs:flex-row flex-wrap gap-4">
                {displayFields?.diversEquipmentInfo?.selfEquipment
                  ?.hasSelfEquipment?.edit && (
                  <AppFormControlV2
                    className={'md:col-span-2'}
                    label="Complètement équipé?"
                    required={false}
                    renderComponent={() => (
                      <AppInputBooleanSwitch
                        value={equipmentProvider === 'customer'}
                        onChange={(checked) => {
                          setValue(
                            `${baseFormName}.diver.defaultDiveConfig.equipment.provider`,
                            checked ? 'customer' : 'club',
                          );
                          EQUIPMENT_TYPES_DEFAULT_CUSTOMER_SPACE_FULL_EQUIPMENT.filter(
                            (equipmentType) =>
                              displayFields?.diversEquipmentInfo?.selfEquipment
                                ?.details?.[equipmentType]?.edit,
                          ).forEach((equipmentType) => {
                            setValue(
                              `${baseFormName}.diver.defaultDiveConfig.equipment.${equipmentType}.selfEquipped`,
                              checked,
                            );
                          });
                        }}
                      />
                    )}
                  />
                )}
                {EQUIPMENT_TYPES.filter(
                  (equipmentType) =>
                    displayFields?.diversEquipmentInfo?.selfEquipment
                      ?.details?.[equipmentType]?.edit,
                ).map((equipmentType) => (
                  <AppFormControlRHF
                    key={equipmentType}
                    className={'md:col-span-2'}
                    label={equipmentFormatter.formatEquipmentType(
                      equipmentType,
                    )}
                    control={control}
                    required={
                      displayFields?.diversEquipmentInfo?.bodyMeasurements
                        ?.weight?.required
                    }
                    name={`${baseFormName}.diver.defaultDiveConfig.equipment.${equipmentType}.selfEquipped`}
                    renderComponent={(props) => {
                      const Icon = AppIconsDiveEquipmentType[equipmentType];

                      return (
                        <AppInputBooleanSwitchRHF
                          {...props}
                          label={<Icon className="w-6 h-6" />}
                          onChange={(enabled) => {
                            if (
                              !enabled &&
                              EQUIPMENT_TYPES_DEFAULT_CUSTOMER_SPACE_FULL_EQUIPMENT.includes(
                                equipmentType,
                              )
                            ) {
                              setValue(
                                `${baseFormName}.diver.defaultDiveConfig.equipment.provider`,
                                'club',
                              );
                            }
                          }}
                        />
                      );
                    }}
                  />
                ))}
              </div>
            </div>
          )}
          {hasAnyBodyMeasurements && (
            <div className="flex flex-col gap-2">
              <AppMessageSingleLine className="block">
                Vos mensurations nous permettent de préparer vos équipements en
                choisissant la taille la mieux adaptée pour vous.
              </AppMessageSingleLine>
              <div className="md:col-span-2 w-full flex flex-col xs:flex-row gap-4">
                {displayFields?.diversEquipmentInfo?.bodyMeasurements?.size
                  ?.edit && (
                  <AppFormControlRHF
                    className={'md:col-span-2 w-full'}
                    label="Taille (cm)"
                    control={control}
                    required={
                      displayFields?.diversEquipmentInfo?.bodyMeasurements?.size
                        ?.required
                    }
                    name={`${baseFormName}.diver.size`}
                    renderComponent={(props) => (
                      <AppInputRHF {...props} fullWidth type="number" />
                    )}
                  />
                )}
                {displayFields?.diversEquipmentInfo?.bodyMeasurements?.weight
                  ?.edit && (
                  <AppFormControlRHF
                    className={'md:col-span-2 w-full'}
                    label="Poids (Kg)"
                    control={control}
                    required={
                      displayFields?.diversEquipmentInfo?.bodyMeasurements
                        ?.weight?.required
                    }
                    name={`${baseFormName}.diver.weight`}
                    renderComponent={(props) => (
                      <AppInputRHF {...props} fullWidth type="number" />
                    )}
                  />
                )}
                {displayFields?.diversEquipmentInfo?.bodyMeasurements?.shoeSize
                  ?.edit && (
                  <AppFormControlRHF
                    className={'md:col-span-2 w-full'}
                    label="Pointure"
                    control={control}
                    required={
                      displayFields?.diversEquipmentInfo?.bodyMeasurements
                        ?.shoeSize?.required
                    }
                    name={`${baseFormName}.diver.details.measurements.shoeSize`}
                    renderComponent={(props) => (
                      <AppInputRHF {...props} fullWidth type="number" />
                    )}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
