import { appWebConfig } from '../../../../../_core/modules/root/config';

export const imageUrlBuilder = {
  getImageFullUrl,
};

function getImageFullUrl({
  image,
  defaultUrl,
}: {
  image: string;
  defaultUrl?: string;
}) {
  if (!image) {
    return defaultUrl;
  }
  return appWebConfig.applications.cdnUrl + image;
}
