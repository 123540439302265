import {
  ClubStaffMember,
  ClubStaffMembersGroups,
} from '@mabadive/app-common-model';

export const clubStaffMemberGrouper = {
  groupStaffMembers,
};

function groupStaffMembers({
  staffMembers,
}: {
  staffMembers: ClubStaffMember[];
}): ClubStaffMembersGroups {
  const initialValue: ClubStaffMembersGroups = {
    all: staffMembers,
    divingDirectorsStaffs: [],
    divingInstructorsStaffs: [],
    surfaceSecurityStaffs: [],
  };

  return staffMembers.reduce((acc, s) => {
    if (s.profile.roles.includes('diving-director')) {
      acc.divingDirectorsStaffs.push(s);
    }
    if (
      s.profile.roles.includes('scuba-diving-instructor') ||
      s.profile.roles.includes('free-diving-instructor')
    ) {
      acc.divingInstructorsStaffs.push(s);
    }
    if (s.profile.roles.includes('surface-security')) {
      acc.surfaceSecurityStaffs.push(s);
    }
    return acc;
  }, initialValue);
}
