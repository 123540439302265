import { AppCompanyMessageLocalized } from '@mabadive/app-common-model';

export const smsLinkBuilder = {
  buildLinkSms,
  buildLinkTel,
  cleanPhoneNumbers,
  cleanPhoneNumber,
  buildMessageFromChunks,
};

function buildMessageFromChunks({
  title,
  body,
  signature,
}: Pick<AppCompanyMessageLocalized, 'title' | 'body' | 'signature'>) {
  const content = [title, body, signature]
    .map((x) => x?.trim())
    .filter((x) => x?.length > 0)
    .join(' - ');
  return content;
}

function cleanNumberString(s: string) {
  return s.replace(/\D/g, '');
}

function cleanPhoneNumber(phoneNumber: string) {
  if (phoneNumber) {
    phoneNumber = phoneNumber.trim();
    if (!phoneNumber.length) {
      return;
    }
    if (phoneNumber.charAt(0) === '+') {
      return '+' + cleanNumberString(phoneNumber.substring(1));
    }
    return cleanNumberString(phoneNumber);
  }
}
function cleanPhoneNumbers(phoneNumbers: string[]) {
  const numbers = (phoneNumbers ?? [])
    .filter((x) => !!x?.length)
    .map((x) => cleanPhoneNumber(x))
    .filter((x) => !!x);

  return [...new Set(numbers)];
}
function buildLinkSms({
  phoneNumbers,
  message,
  deviceOS,
}: {
  phoneNumbers: string[];
  message: string;
  deviceOS: 'ios' | any;
}) {
  if (phoneNumbers?.length) {
    const encodedMessage = encodeURIComponent(message);

    /* https://stackoverflow.com/a/58131833 */
    if (deviceOS === 'ios') {
      // NOTE 25/07/2022 : il semblerait que ça fonctionne sous firefox, mais pas safari (en tout cas sur ma tablette)
      return `sms://open?addresses=${phoneNumbers.join(
        ',',
      )};?&body=${encodedMessage}`;
    } else {
      return `sms://${phoneNumbers.join(',')};?&body=${encodedMessage}`;
    }
  }
}

function buildLinkTel({ phoneNumber }: { phoneNumber: string }) {
  if (phoneNumber?.length) {
    return `tel:${phoneNumber}`;
  }
}
