/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  DiveCenterShoppingCartContextProps,
  useDiveCenterShoppingCart,
} from '../../DiveCenterShoppingCartPage';
import { DiveCenterBookingContactPageLocalState } from '../useDiveCenterBookingContactPageLocalState.hook';
import { CustomerProductsPage_FillContactPanel_FormModel } from './model/CustomerProductsPage_FillContactPanel_FormModel';

export type CustomerProductsPage_FillContactPanelLocalState_CurrentStep =
  // | 'is-contact-participant'
  'choose-contact-participant' | 'fill-contact-form';

export function useCustomerProductsPage_FillContactPanelLocalState({
  parentState,
}: {
  parentState: DiveCenterBookingContactPageLocalState;
}) {
  const redirectTo = useRedirect();

  const { state, data, actions } = parentState;

  const shoppingCartContext: DiveCenterShoppingCartContextProps =
    useDiveCenterShoppingCart();

  const { cart } = shoppingCartContext;

  const hasAnyParticipants =
    cart?.bookingDetails?.bookingParticipants?.length > 0;

  const [currentStep, setCurrentStep] =
    useState<CustomerProductsPage_FillContactPanelLocalState_CurrentStep>(
      hasAnyParticipants ? 'choose-contact-participant' : 'fill-contact-form',
    );

  const defaultValues: CustomerProductsPage_FillContactPanel_FormModel = {
    bookingContact: {
      diverId: undefined,
      isParticipant: hasAnyParticipants,
    },
    bookingGroup: {
      type: 'individual',
    },
    bookingContactDiver: undefined,
    comment: undefined,
  };

  const form = useForm<CustomerProductsPage_FillContactPanel_FormModel>({
    defaultValues,
  });

  return {
    state: {
      ...parentState.state,
      form,
      currentStep,
      setCurrentStep,
      shoppingCartContext,
      cart: shoppingCartContext?.cart,
    },
    data: {
      ...parentState.data,
    },
    actions: {
      ...parentState.actions,
    },
  };
}

export type CustomerProductsPage_FillContactPanelLocalState = ReturnType<
  typeof useCustomerProductsPage_FillContactPanelLocalState
>;
