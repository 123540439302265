import { stackLogConfigurer } from '@mabadive/stack-log-core';
import { StackLogWebLogger } from './logger/stack-log-web-logger.service';
import { stackLogGlobalConfigHolder } from './models/stack-log-global-config-holder.service';
import { StackLogInnerConfig } from './models/stack-log-inner-config.model';
import { StackLogWebConfig } from './models/stack-log-web-config.model';
import { StackLogWebContext } from './models/stack-log-web-context.model';


export const StackLogWeb = {

  configure: (config: StackLogWebConfig): void => {

    const innerSeverityConfig = stackLogConfigurer.buildInnerSeverityConfig(config.global);

    const globalConfig: StackLogInnerConfig = {
      ...config,
      global: innerSeverityConfig
    };

    if (globalConfig.appenders) {
      globalConfig.appenders.forEach(app => app.activate(config));
    }
    stackLogGlobalConfigHolder.setGlobalConfig(globalConfig);
  },

  logger: (context: StackLogWebContext): StackLogWebLogger => {
    return new StackLogWebLogger(context);
  },
};

export const stackLogWeb = StackLogWeb;