"use strict";
exports.__esModule = true;
exports.AUTH_USER_DEFAULT_PROFILES = void 0;
var DEFAULT_PROFILE_ACCOUNTANT_const_1 = require("./DEFAULT_PROFILE_ACCOUNTANT.const");
var DEFAULT_PROFILE_BOOKING_PAYMENTS_const_1 = require("./DEFAULT_PROFILE_BOOKING_PAYMENTS.const");
var DEFAULT_PROFILE_COMPANY_ADMIN_const_1 = require("./DEFAULT_PROFILE_COMPANY_ADMIN.const");
var DEFAULT_PROFILE_DIVE_CENTER_MANAGER_const_1 = require("./DEFAULT_PROFILE_DIVE_CENTER_MANAGER.const");
var DEFAULT_PROFILE_VIEW_PLANNING_const_1 = require("./DEFAULT_PROFILE_VIEW_PLANNING.const");
exports.AUTH_USER_DEFAULT_PROFILES = [
    DEFAULT_PROFILE_VIEW_PLANNING_const_1.DEFAULT_PROFILE_VIEW_PLANNING,
    DEFAULT_PROFILE_BOOKING_PAYMENTS_const_1.DEFAULT_PROFILE_BOOKING,
    DEFAULT_PROFILE_DIVE_CENTER_MANAGER_const_1.DEFAULT_PROFILE_DIVE_CENTER_MANAGER,
    DEFAULT_PROFILE_COMPANY_ADMIN_const_1.DEFAULT_PROFILE_COMPANY_ADMIN,
    DEFAULT_PROFILE_ACCOUNTANT_const_1.DEFAULT_PROFILE_ACCOUNTANT,
];
