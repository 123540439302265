import {
  Customer_ClubSettingsPublicCustomer,
  OnlineBookingOrderArticleFull,
} from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';
import { useDiveCenterShoppingCart } from '../services';
import { DiveCenterShoppingCartOrderArticleItem } from './DiveCenterShoppingCartOrderArticleItem';

export const DiveCenterShoppingCartOrderArticle = ({
  orderArticleFull,
  clubSettings,
  className,
}: {
  orderArticleFull: OnlineBookingOrderArticleFull;
  clubSettings: Customer_ClubSettingsPublicCustomer;
  className?: string;
}) => {
  const { cart } = useDiveCenterShoppingCart();

  const appUiTheme = clubSettings?.publicSettings?.appUiTheme;
  const articleBookletPage =
    orderArticleFull?.productArticleFull?.articleBookletPage;
  const content = articleBookletPage?.mainContent?.content;

  const activityDate = orderArticleFull?.activityDate;
  const diveSessionDateTime = orderArticleFull?.diveSessionDateTime;
  return (
    <li className={clsx('flex py-4', className)}>
      <div className="w-full flex flex-1 flex-col">
        <div className="flex gap-4 justify-between">
          <div>
            <div
              className={clsx(
                'uppercase font-bold w-full text-left',
                'text-sm sm:text-base',
              )}
              style={{ color: appUiTheme.title1.textColor }}
            >
              {content?.title}
            </div>
            {content?.subTitle && (
              <div
                className={clsx(
                  'uppercase font-medium w-full text-left',
                  'text-xs sm:text-sm',
                )}
                style={{ color: appUiTheme.title2.textColor }}
              >
                {content?.subTitle}
              </div>
            )}
          </div>
          <div className="font-bold text-gray-800">
            <span className="uppercase">
              {dateService.formatUTC(activityDate, 'dddd DD/MM/YYYY')}
            </span>
            {diveSessionDateTime &&
              ` à ${dateService.formatUTC(diveSessionDateTime, 'HH:mm')}`}
          </div>
        </div>
        <div
          className={clsx(
            'mt-2 flex flex-col items-stretch divide-y divide-gray-200',
          )}
        >
          {orderArticleFull.items.map((orderArticleItem, i) => (
            <DiveCenterShoppingCartOrderArticleItem
              key={i}
              orderArticleFull={orderArticleFull}
              orderArticleItem={orderArticleItem}
              clubSettings={clubSettings}
            />
          ))}
        </div>
      </div>
    </li>
  );
};
