import { AppConfiguration } from '@mabadive/app-common-model';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { graphqlClient, MQueryDescription } from 'src/_common-browser';
import { appWebConfig } from '../../modules/root/config';
import { appLogger } from '../../modules/root/logger';

export type AppConfigurationVersion = Pick<AppConfiguration, 'appVersion'>;

export const appConfigurationGraphqlFetcher = {
  fetch,
};

function fetch(): Observable<AppConfiguration> {
  // group all graphql queries into one fetch

  const query: MQueryDescription<AppConfigurationVersion> = {
    queryName: 'app_configuration',
    returnType: 'first',
    returnAttributes: {
      appVersion: true,
    },
  } as MQueryDescription<AppConfigurationVersion>;

  return graphqlClient.query
    .runOne<AppConfigurationVersion>(query, {
      type: 'subscription',
    })
    .pipe(
      tap((appConfiguration) => {
        if (
          appWebConfig.debug.checkNewVersion &&
          appConfiguration.appVersion &&
          appWebConfig.appVersion &&
          appConfiguration.appVersion !== appWebConfig.appVersion
        ) {
          appLogger.error(
            `[appConfigurationGraphqlFetcher] appConfiguration changed ("${appWebConfig.appVersion}" => "${appConfiguration.appVersion}"): reload now`,
          );
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        } else {
          appLogger.info('[appConfigurationGraphqlFetcher] appConfiguration:', appConfiguration);
        }
      }),
    );
}
