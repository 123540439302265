import { DiveSession } from '@mabadive/app-common-model';
import { diveSessionStatusFormatter } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';

export const ClubDiveSessionHeaderStatus = function ({
  diveSession: session,
  className = '',
}: {
  diveSession: Pick<DiveSession, 'status' | 'details'>;
  className?: string;
}) {
  const sessionStatusLabel = useMemo(() => {
    if (session.status !== 'on' || session.details?.isFull) {
      return diveSessionStatusFormatter
        .formatStatus(session?.status, { isFull: session?.details?.isFull })
        ?.toUpperCase();
    }
  }, [session?.details?.isFull, session.status]);

  const sessionStatusLabelClass = useMemo(
    () =>
      session.status === 'on'
        ? session.details?.isFull
          ? 'font-bold text-status-error'
          : ''
        : session.status === 'off'
        ? 'font-bold bg-gray-400 text-white'
        : session.status === 'cancelled'
        ? 'font-bold bg-red-400 text-white'
        : '',
    [session.details?.isFull, session.status],
  );

  return !sessionStatusLabel ? null : (
    <div className={`px-1 text-center ${sessionStatusLabelClass} ${className}`}>
      {sessionStatusLabel}
    </div>
  );
};
