import { isIOS } from 'react-device-detect';
export const gisMapUrlBuilder = {
  getMapUrl,
};

function getMapUrl(direction: string) {
  const address = encodeURI(direction);
  if (isIOS) {
    return `https://maps.apple.com/?q=${address}`; // NOTE 04/03/2024: sur l'appli mobile, c'est http, une erreur ou c'est voulu? (pour le moment, on teste juste https ici)
  } else {
    return `https://maps.google.com/?q=${address}`;
  }
}
