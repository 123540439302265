import { OnlineBookingProductConfig_Date_Constraints } from '../../../../app-common-model/src/entities/company/settings/club-settings/general/OnlineBookingProductConfig.type';

export const effectiveDateBoundsBuilder = {
  getBoundsFromDateConstraints,
};

function getBoundsFromDateConstraints(
  dateConstraints: OnlineBookingProductConfig_Date_Constraints,
  {
    firstDate,
    lastDate,
  }: {
    firstDate: Date;
    lastDate: Date;
  },
): { effectiveFirstDate: Date; effectiveLastDate: Date } {
  const minDate: Date | null = dateConstraints?.minDate
    ? new Date(dateConstraints?.minDate)
    : null;
  const maxDate: Date | null = dateConstraints?.maxDate
    ? new Date(dateConstraints?.maxDate)
    : null;
  const minDaysBefore: number | null = dateConstraints?.minDaysBefore;
  const maxDaysBefore: number | null = dateConstraints?.maxDaysBefore;

  // Fusion des dates
  let effectiveFirstDate = minDate
    ? new Date(Math.max(minDate.getTime(), firstDate.getTime()))
    : firstDate;
  let effectiveLastDate = maxDate
    ? new Date(Math.min(maxDate.getTime(), lastDate.getTime()))
    : lastDate;

  // Ajout de minDaysBefore et maxDaysBefore dans les dates effectives
  if (minDaysBefore) {
    const minDaysBeforeDate = new Date(
      Date.now() + minDaysBefore * 24 * 60 * 60 * 1000,
    );
    effectiveFirstDate = new Date(
      Math.max(
        effectiveFirstDate.getTime(),
        minDaysBeforeDate.setUTCHours(0, 0, 0, 0),
      ),
    );
  }
  if (maxDaysBefore) {
    const maxDaysBeforeDate = new Date(
      Date.now() + maxDaysBefore * 24 * 60 * 60 * 1000,
    );
    effectiveLastDate = new Date(
      Math.min(
        effectiveLastDate.getTime(),
        maxDaysBeforeDate.setUTCHours(0, 0, 0, 0),
      ),
    );
  }
  return { effectiveFirstDate, effectiveLastDate };
}
