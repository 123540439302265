"use strict";
exports.__esModule = true;
exports.DIVE_CENTER_SETTINGS_EXPORTS_CASH_DEFAULT = void 0;
var configSheet1WidthCellsCount = 49;
// 49 = multiple de 7, car :
// - 7 colonnes sur le 1er tableau "payments-list" (2 double et 3 simple = 2*2 + 3 = 7)
// - 7 colonnes sur le 2ème tableau "payments-cash" (1 quadruple et 3 simple = 1*4 + 3 = 7)
var configSheet1 = {
    tab: {
        name: 'Caisse du jour',
        color: '#9f3909'
    },
    content: {
        title: 'Caisse du jour',
        items: [
            {
                id: 'payments-list',
                columns: [
                    {
                        id: 'fullName'
                    },
                    {
                        id: 'groupName'
                    },
                    {
                        id: 'paymentMethod'
                    },
                    {
                        id: 'paymentState'
                    },
                    {
                        id: 'amount'
                    },
                ]
            },
            {
                id: 'payments-cash',
                columns: [
                    // {
                    //   id: 'period',
                    // },
                    {
                        id: 'paymentMethod'
                    },
                    {
                        id: 'pending'
                    },
                    {
                        id: 'validated'
                    },
                    {
                        id: 'total'
                    },
                ]
            },
        ]
    },
    settings: {
        widthCellsCount: configSheet1WidthCellsCount,
        orientation: 'portrait'
    }
};
var settings = {
    colorsScheme: 'black-white',
    defaultTargetDurations: {}
};
var exportDailyConfig = {
    baseFileName: 'daily-export-cash',
    sheets: [configSheet1],
    settings: settings
};
exports.DIVE_CENTER_SETTINGS_EXPORTS_CASH_DEFAULT = {
    exportRef: 'daily-export-cash',
    label: 'Caisse du jour',
    description: 'Liste des paiements + caisse du jour sur le même onglet',
    exportConfig: exportDailyConfig,
    enabled: true,
    visibility: {
        dashboardDaily: true,
        planningDaily: false,
        planningSession: false
    }
};
