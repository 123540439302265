import { dateService, uuidGenerator } from '@mabadive/app-common-services';
import { bootstrapAuthentication } from 'src/business/auth/services';
import { uiStore } from 'src/business/_core/store';
import { appWebConfig } from '../config';
import { appLogger, appLoggerManager } from '../logger';
import { apiConfigurer } from './apiConfigurer.service';

const logger = appLogger.child({
  module: 'bootstrap',
  filename: 'appBootstrap.service',
});

export const appBootstrap = {
  init,
};

function init() {
  const appVersion = appWebConfig.appVersion;
  const appClientId = uuidGenerator.random();

  uiStore.app.set({
    version: appVersion,
    clientId: appClientId,
  });

  try {
    appLoggerManager.configure({
      appVersion,
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('[appBootstrap] Error initializing appLoggerManager', err);
    appLogger.error('[appBootstrap] Error initializing appLoggerManager', err);
  }

  appLogger.info('[appBootstrap] appClientId:', appClientId);

  try {
    dateService.init();
  } catch (err) {
    logger.error('[appBootstrap] Error initializing dateService', err);
  }

  try {
    apiConfigurer.configureApiAndGraphqlClients({
      appVersion,
      appClientId,
    });
  } catch (err) {
    logger.error(
      '[appBootstrap] Error initializing configureApiAndGraphqlClients',
      err,
    );
  }

  try {
    return bootstrapAuthentication();
  } catch (err) {
    logger.error('[appBootstrap] Error initializing authConfigurer', err);
  }
}
