import { Theme } from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/styles/withStyles';



export function buildGlobalInfoCardStyles(theme: Theme): CreateCSSProperties<{}> {
  return {
    '&.MuiCard-root': {
      // border: '3px solid #eb4f26',
      // backgroundColor: '#eeeeee',
      // width: '100%',
      border: '1px solid #ccc',
      boxShaddow: '0 0 0 5px rgba(100, 100, 100, 0.5)',
      padding: theme.spacing(1),
      '& .data-card-title': {
        paddingBottom: 0,
      },
      '&.MuiPaper-root': {
        overflow: 'initial',
      },
    },
  };
}