"use strict";
exports.__esModule = true;
exports.DIVE_SESSION_TYPES = void 0;
exports.DIVE_SESSION_TYPES = [
    'dive',
    'first-dive',
    'snorkeling',
    'snorkeling-tour',
    'watching-tour',
    'boat-tour',
    'theoretical-training',
    'dive-auto-supervised',
];
