import {
  ClubDiver,
  ClubParticipant,
  ClubPublicSettings,
  ClubSettingsUIDiveMode,
} from '@mabadive/app-common-model';
import { participantAptitudeBuilder } from '../dive';

export type DiveTypeStringFormat = 'short-ref';

export type DiveServiceFormatterParticipantProps = Partial<
  Pick<
    ClubParticipant,
    | 'trainingReference'
    | 'certificationReference'
    | 'certificationDeepDiver'
    | 'autoSupervisedDetails'
    | 'firstDiveReference'
    | 'diveMode'
    | 'aptitude'
  >
>;

export const diveSessionServiceFormatter = {
  formatSecuritySheetLevelFromParticipant,
  formatServiceFromParticipant,
  formatServiceExtraFromParticipant,
};

function formatSecuritySheetLevelFromParticipant(
  {
    diver,
    participant,
  }: {
    participant: DiveServiceFormatterParticipantProps;
    diver: Partial<Pick<ClubDiver, 'divingCertification1'>>;
  },
  {
    clubReference,
    clubPublicSettings,
    addSuffix = true,
  }: {
    clubReference: string;
    clubPublicSettings: ClubPublicSettings;
    addSuffix?: boolean;
  },
) {
  if (!participant) {
    return '';
  }

  const {
    trainingReference,
    diveMode,
    certificationReference,
    certificationDeepDiver,
  } = participant;

  if (diveMode === 'autoSupervised') {
    return formatServiceExtraFromParticipant(
      {
        diver,
        participant,
      },
      {
        clubReference,
        clubPublicSettings,
        addSuffix,
      },
    );
  }
  return formatServiceFromParticipant(
    {
      diver,
      participant,
    },
    {
      format: 'short-ref',
      clubReference,
      clubPublicSettings,
      addSuffix,
    },
  );
}
function formatServiceFromParticipant(
  {
    diver,
    participant,
  }: {
    participant: DiveServiceFormatterParticipantProps;
    diver: Partial<Pick<ClubDiver, 'divingCertification1'>>;
  },
  {
    format,
    clubReference,
    clubPublicSettings,
    addSuffix = true,
    diveModesConf,
  }: {
    format: DiveTypeStringFormat;
    clubReference: string;
    clubPublicSettings: ClubPublicSettings;
    addSuffix?: boolean;
    diveModesConf?: ClubSettingsUIDiveMode;
  },
) {
  if (!participant) {
    return '';
  }

  const {
    trainingReference,
    diveMode,
    certificationReference,
    certificationDeepDiver,
  } = participant;
  const diveModeConf = diveModesConf?.[diveMode];
  const ref = diveModeConf?.ref;

  switch (diveMode) {
    case 'observer': {
      return ref ?? 'ACC';
    }
    case 'snorkeling': {
      return ref ?? 'PMT';
    }
    case 'snorkelingSupervised': {
      return ref ?? 'RP';
    }
    case 'watchingTour': {
      return ref ?? 'EXC';
    }
    case 'free-dive': {
      return ref ?? 'APN';
    }
    case 'free-dive-auto': {
      return ref ?? 'APA';
    }
    case 'free-dive-training': {
      return ref ?? 'FAP';
    }
    case 'theoretical-training': {
      return ref ?? 'FT';
    }
    case 'unknown': {
      return ref ?? '???';
    }
    case 'instructor': {
      return ref ?? 'GP';
    }
    case 'first-dive': {
      return formatDiveModeFirstDive(
        {
          participant,
        },
        { clubPublicSettings, diveModesConf },
      );
    }
    case 'training': {
      return trainingReference ?? ref ?? 'FN';
    }
    case 'supervised':
    case 'autonomous': {
      return formatDiveModeExplo(
        {
          diver,
          participant,
          certificationReference,
          certificationDeepDiver,
        },
        {
          clubReference,
          addSuffix,
        },
      );
    }
    case 'autoSupervised': {
      return ref ?? 'AE';
    }
  }
}
function formatDiveModeFirstDive(
  {
    participant,
  }: {
    participant: DiveServiceFormatterParticipantProps;
  },
  {
    clubPublicSettings,
    diveModesConf,
  }: {
    clubPublicSettings: ClubPublicSettings;
    diveModesConf?: ClubSettingsUIDiveMode;
  },
) {
  if (!participant) {
    return '';
  }

  const { firstDiveReference } = participant;

  const diveModeConf = diveModesConf?.['first-dive'];
  const ref = firstDiveReference ?? diveModeConf?.ref;

  // PERSONNALISATION BPT
  if (clubPublicSettings.services?.firstDives?.customize) {
    const firstDive = clubPublicSettings.services?.firstDives?.customTypes.find(
      (x) => x.originalRef === ref,
    );
    return firstDive?.labelShort ?? ref;
  }
  return ref ?? 'BPT';
}
function formatDiveModeExplo(
  {
    diver,
    participant,
    certificationReference,
    certificationDeepDiver,
  }: {
    diver: Partial<Pick<ClubDiver, 'divingCertification1'>>;
    participant: DiveServiceFormatterParticipantProps;
    certificationReference: string;
    certificationDeepDiver: boolean;
  },
  {
    clubReference,
    addSuffix = true,
  }: {
    clubReference: string;
    addSuffix?: boolean;
  },
) {
  if (certificationReference) {
    const suffix = addSuffix && certificationDeepDiver ? '-DD' : '';
    return `${certificationReference}${suffix}`;
  }
  const aptitude = participantAptitudeBuilder.formatAptitude(
    {
      participant,
      diver,
    },
    {
      clubReference,
    },
  );
  return aptitude ?? '?';
}

function formatServiceExtraFromParticipant(
  {
    diver,
    participant,
  }: {
    participant: DiveServiceFormatterParticipantProps;
    diver: Partial<Pick<ClubDiver, 'divingCertification1'>>;
  },
  {
    clubReference,
    clubPublicSettings,
    addSuffix = true,
  }: {
    clubReference: string;
    clubPublicSettings: ClubPublicSettings;
    addSuffix?: boolean;
  },
) {
  // contrairement au label principal, on retourne "undefined" plutôt que "''"
  if (!participant) {
    return undefined;
  }

  const {
    trainingReference,
    diveMode,
    certificationReference,
    certificationDeepDiver,
  } = participant;

  switch (diveMode) {
    case 'autoSupervised': {
      const label = formatDiveModeExtraAutoSupervised(
        {
          diver,
          participant,
        },
        {
          clubReference,
          addSuffix,
        },
      );
      if (label?.length > 0) {
        return label;
      }
    }
  }
}
function formatDiveModeExtraAutoSupervised(
  {
    diver,
    participant,
  }: {
    participant: DiveServiceFormatterParticipantProps;
    diver: Partial<Pick<ClubDiver, 'divingCertification1'>>;
  },
  {
    clubReference,
    addSuffix = true,
  }: {
    clubReference: string;
    addSuffix?: boolean;
  },
) {
  if (!participant) {
    return undefined;
  }

  const { autoSupervisedDetails } = participant;
  if (autoSupervisedDetails?.diveMode) {
    switch (autoSupervisedDetails?.diveMode) {
      case 'training': {
        return autoSupervisedDetails?.trainingReference ?? 'FN';
      }
      case 'instructor': {
        return 'GP';
      }
      case 'supervised':
      case 'autonomous': {
        return formatDiveModeExplo(
          {
            diver,
            participant,
            certificationReference:
              autoSupervisedDetails?.certificationReference,
            certificationDeepDiver:
              autoSupervisedDetails?.certificationDeepDiver,
          },
          {
            clubReference,
            addSuffix,
          },
        );
      }
    }
  }
  return undefined;
}
