"use strict";
exports.__esModule = true;
exports.APP_DOC_REFS = void 0;
exports.APP_DOC_REFS = [
    'medical-certificate',
    'parental-consent',
    'medical-form',
    'personal-photo',
    'dive-certification',
    'insurance-proof',
    'other',
];
