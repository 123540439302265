import {
  AppAuthenticatedCustomerAuthorizations,
  BookingCustomerCheckoutState,
} from '@mabadive/app-common-model';
import React from 'react';
import { AppMessageLight } from 'src/business/_core/modules/layout';
import {
  useAppSecurityUser,
  useAppSecurityUserDiverAuthorizations,
} from 'src/business/auth/services';

export function DiveCenterBookingCheckoutStateInfo({
  checkoutState,
}: {
  checkoutState: BookingCustomerCheckoutState;
}) {
  const { customerUpdateState } = checkoutState;

  const securityUser = useAppSecurityUser();

  const az: AppAuthenticatedCustomerAuthorizations =
    useAppSecurityUserDiverAuthorizations();

  return customerUpdateState.enableUpdate ? (
    <div className="mt-2 grid gap-2 text-gray-600">
      <div className="uppercase font-bold text-base text-status-error">
        Informations manquantes
      </div>
      <p>
        Afin de permettre au centre de plongée de valider votre réservation,
        veuillez renseigner les informations indiquées ci-dessous.
      </p>
      {az?.actions?.editOtherParticipantsDetails ? (
        <ul className="mt-2 ml-3 list-disc">
          {customerUpdateState.pendingList?.diversContactInfo && (
            <li className="font-bold">
              Nom et coordonnées de tous les participants
            </li>
          )}
          {customerUpdateState.pendingList?.diversDivingInfo && (
            <li className="font-bold">
              Niveau et expérience de tous les plongeurs
            </li>
          )}
          {customerUpdateState.pendingList?.diversPostalAddressInfo && (
            <li className="font-bold">Adresse postale des participants</li>
          )}{' '}
          {customerUpdateState.pendingList?.diversExpectedDiveInfo && (
            <li className="font-bold">Prestation souhaitée</li>
          )}
        </ul>
      ) : (
        <ul className="mt-2 ml-3 list-disc">
          {customerUpdateState.pendingList?.diversContactInfo && (
            <li className="font-bold">Nom et coordonnées</li>
          )}
          {customerUpdateState.pendingList?.diversDivingInfo && (
            <li className="font-bold">Niveau et expérience</li>
          )}
          {customerUpdateState.pendingList?.diversPostalAddressInfo && (
            <li className="font-bold">Adresse postale</li>
          )}{' '}
          {customerUpdateState.pendingList?.diversExpectedDiveInfo && (
            <li className="font-bold">Prestation souhaitée</li>
          )}
        </ul>
      )}
      {customerUpdateState.status === 'draft' && (
        <AppMessageLight type="warn" className="my-2">
          <p className="font-bold text-status-warn">
            Vous n'avez pas confirmé vos modifications!
          </p>
          <p>
            Veuillez cliquer sur le bouton "Renseigner mes informations"
            ci-dessous.
          </p>
        </AppMessageLight>
      )}
    </div>
  ) : null;
}
