import {
  DiveSession,
  DiveSessionTimeDayPeriod,
} from '@mabadive/app-common-model';
import { dateService } from '../../data/date/date.service';

const DAY_PERIOD_COLORS = {
  morning: '#849b5a',
  afternoon: '#597f88',
  night: '#955',
};

export const clubDiveSessionThemeBuilder = {
  buildSessionAttributes,
  buildTimeDayPeriod,
  getDayPeriodColorsStyles,
};

function buildSessionAttributes({
  diveSession,
}: {
  diveSession: Pick<DiveSession, 'time'>;
}) {
  const highlight = dateService.isTodayUTC(diveSession.time);

  const timeDayPeriod: DiveSessionTimeDayPeriod = buildTimeDayPeriod(
    diveSession.time,
  );

  return {
    highlight,
    timeDayPeriod,
  };
}

function buildTimeDayPeriod(time: Date): DiveSessionTimeDayPeriod {
  if (time && !time?.getUTCHours) {
    time = new Date(time);
  }
  if (time?.getUTCHours) {
    return time.getUTCHours() < 12
      ? 'morning'
      : time.getUTCHours() < 17
      ? 'afternoon'
      : 'night';
  } else {
    console.error('Unexpected time object', time);
    // return 'morning';
    throw new Error('Unexpected time object');
  }
}

function getDayPeriodColorsStyles() {
  return {
    '&.day-period-morning': {
      color: DAY_PERIOD_COLORS.morning,
    },
    '&.day-period-afternoon': {
      color: DAY_PERIOD_COLORS.afternoon,
    },
    '&.day-period-night': {
      color: DAY_PERIOD_COLORS.night,
    },
  };
}
