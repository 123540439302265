"use strict";
exports.__esModule = true;
exports.TEMPLATE_EXPORT_SHEET_DEFAULT_ITEMS = void 0;
var TEMPLATE_EXPORT_SHEET_DIVES_type_1 = require("./TEMPLATE_EXPORT_SHEET_DIVES.type");
exports.TEMPLATE_EXPORT_SHEET_DEFAULT_ITEMS = [
    {
        value: 1,
        label: 'Pick-up',
        item: {
            id: 'pickup'
        }
    },
    {
        value: 2,
        label: 'Notes',
        item: {
            id: 'events'
        }
    },
    {
        value: 3,
        label: 'Sessions',
        item: TEMPLATE_EXPORT_SHEET_DIVES_type_1.TEMPLATE_EXPORT_SHEET_DIVES_ITEM_SESSIONS
    },
];
