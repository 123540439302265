"use strict";
exports.__esModule = true;
exports.ORG_GROUP_INT_DATA = void 0;
var ORG_GROUP_INT_DATA_TRAININGS_const_1 = require("./ORG_GROUP_INT_DATA_TRAININGS.const");
exports.ORG_GROUP_INT_DATA = {
    orgGroupReference: 'padi',
    orgGroupReferencev2: 'international',
    orgName: 'PADI',
    firstDives: [
        {
            diveMode: 'first-dive',
            reference: 'DIS',
            label: "Discover Scuba Diving",
            maxDepth: 6,
            trainingDivesTotalCount: 1,
            compatibilityGroups: []
        },
        {
            diveMode: 'first-dive',
            reference: 'TS',
            label: "Try Scuba",
            maxDepth: 6,
            trainingDivesTotalCount: 1,
            compatibilityGroups: []
        },
    ],
    certifications: [
        {
            reference: 'DSD',
            label: 'Discover Scuba Diving',
            supervised: {
                maxDepth: 12,
                defaultAptitude: 'PE12'
            },
            defaultDiveMode: 'supervised'
        },
        {
            reference: 'TRY',
            label: 'Try Scuba',
            supervised: {
                maxDepth: 12,
                defaultAptitude: 'PE12'
            },
            defaultDiveMode: 'supervised'
        },
        {
            reference: 'BSD',
            label: 'Basic Scuba Diver',
            supervised: {
                maxDepth: 12,
                defaultAptitude: 'PE12'
            },
            defaultDiveMode: 'supervised',
            nextSuggestedTrainingReference: 'FOW'
        },
        {
            reference: 'BD',
            label: 'Basic Diver',
            supervised: {
                maxDepth: 12,
                defaultAptitude: 'PE12'
            },
            defaultDiveMode: 'supervised',
            nextSuggestedTrainingReference: 'FOW'
        },
        {
            reference: 'SD',
            label: 'Scuba Diver',
            supervised: {
                // ATTENTION HACK, le Scuba Diver de chez NAUI correspond à un PADI Open Water, donc il peut plonger à 12m
                // => https://www.naui.org/certifications/entry-level/scuba-diver/
                // NOTE: hack ajouté dans le code (méthode "participantAptitudeBuilder.formatAptitude")
                maxDepth: 12,
                defaultAptitude: 'PE12'
            },
            defaultDiveMode: 'supervised',
            nextSuggestedTrainingReference: 'FOW'
        },
        {
            reference: 'OW',
            label: 'Open Water',
            supervised: {
                maxDepth: 18,
                defaultAptitude: 'PE20'
            },
            autonomous: {
                maxDepth: 18,
                defaultAptitude: 'PA20'
            },
            defaultDiveMode: 'supervised'
        },
        {
            reference: 'DG',
            label: 'Dive Guide',
            supervised: {
                maxDepth: 18,
                defaultAptitude: 'PE20'
            },
            autonomous: {
                maxDepth: 18,
                defaultAptitude: 'PA20'
            },
            defaultDiveMode: 'supervised'
        },
        {
            reference: 'RSC',
            label: 'Rescue',
            supervised: {
                maxDepth: 20,
                defaultAptitude: 'PE20'
            },
            autonomous: {
                maxDepth: 20,
                defaultAptitude: 'PA20'
            },
            defaultDiveMode: 'supervised'
        },
        {
            reference: 'ADV',
            label: 'Advanced Open Water',
            supervised: {
                maxDepth: 30,
                defaultAptitude: 'PE20'
            },
            autonomous: {
                maxDepth: 30,
                defaultAptitude: 'PA20'
            },
            defaultDiveMode: 'supervised'
        },
        {
            reference: 'AVT',
            label: 'Adventure Diver',
            supervised: {
                maxDepth: 30,
                defaultAptitude: 'PE20'
            },
            autonomous: {
                maxDepth: 30,
                defaultAptitude: 'PA20'
            },
            defaultDiveMode: 'supervised'
        },
        {
            reference: 'DM',
            label: 'Dive Master',
            supervised: {
                maxDepth: 30,
                defaultAptitude: 'PE20'
            },
            autonomous: {
                maxDepth: 30,
                defaultAptitude: 'PA20'
            },
            supervisor: true,
            defaultDiveMode: 'supervised'
        },
        {
            reference: 'AI',
            label: 'Assitant Instructeur',
            defaultDiveMode: 'autonomous',
            supervisor: true,
            supervised: {
                maxDepth: 60,
                defaultAptitude: 'PE60'
            },
            autonomous: {
                maxDepth: 60,
                defaultAptitude: 'PA60'
            }
        },
        {
            reference: 'IP',
            label: 'Instructeur',
            defaultDiveMode: 'autonomous',
            supervisor: true,
            supervised: {
                maxDepth: 60,
                defaultAptitude: 'PE60'
            },
            autonomous: {
                maxDepth: 60,
                defaultAptitude: 'PA60'
            }
        },
    ],
    // https://www.padi.com/courses?experience=continuing-education&sort=popularity
    specialities: [
        {
            reference: 'int-enriched-air',
            specialityType: 'gaz',
            label: 'Enriched Air (Nitrox) Diver',
            trainingType: 'gaz-certification',
            nitrox: 'simple'
        },
        {
            reference: 'int-deep',
            specialityType: 'deep',
            label: 'Deep Diver',
            trainingType: 'main-certification'
        },
    ],
    trainings: ORG_GROUP_INT_DATA_TRAININGS_const_1.ORG_GROUP_INT_DATA_TRAININGS
};
