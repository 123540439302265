import { LoadableContent } from './LoadableContent.type';
import { LoadableContentPartial } from './LoadableContentPartial.type';

export const loadableContentBuilder = {
  buildLoadableContentFromPartial,
  valueOrDefault,
};

function valueOrDefault<T>(
  value: T,
  {
    defaultValue,
  }: {
    defaultValue: T;
  },
): T {
  if (value !== null && value !== undefined) {
    return value;
  }
  return defaultValue;
}

function buildLoadableContentFromPartial<T>(
  loaded: LoadableContentPartial<T>,
  {
    defaultValue,
    startDate,
  }: {
    defaultValue: T;
    startDate: Date;
  },
) {
  const endDate = new Date();
  const durationInMs = startDate
    ? endDate.getTime() - startDate.getTime()
    : undefined;

  const loadableContent: LoadableContent<T> = {
    content: valueOrDefault(loaded.content, { defaultValue }),
    contentState: loaded.contentState ? loaded.contentState : 'full',
    lastActionStatus: loaded.lastActionStatus
      ? loaded.lastActionStatus
      : 'success',
    startDate,
    endDate,
    durationInMs,
  };
  return loadableContent;
}
