/* eslint-disable jsx-a11y/alt-text */
import { AppTranslationContentItemDoc } from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import { imageUrlBuilder } from './imageUrlBuilder.service';
export const AppTranslationContentImage = ({
  appDoc,
  showEmptyImage,
  className,
}: {
  appDoc: AppTranslationContentItemDoc;
  showEmptyImage?: boolean;
  className?: string;
}) => {
  const imageSrc = useMemo(() => {
    const imageSrc =
      appDoc?.publicUrl ??
      imageUrlBuilder.getImageFullUrl({
        image: undefined,
        defaultUrl: showEmptyImage ? '/assets/images/empty.png' : undefined,
      });
    return imageSrc;
  }, [appDoc, showEmptyImage]);

  return (
    imageSrc && (
      <div className={`w-full object-cover ${className}`}>
        {/* // TODO ajouter la taille de l'image ici! + aussi voir les formats plus adaptés, et les images responsive */}
        <img className="w-full object-cover" src={imageSrc} />
      </div>
    )
  );
};
