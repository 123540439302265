import {
  ClubStaffMember,
  DiveSession,
  DiveSessionGroup,
} from '@mabadive/app-common-model';

export const staffMemberSessionBuilder = {
  getStaffIdsInSession, 
};
 

function _addIfNotAlreadyInListAndNotFiltered(
  staffId: string,
  {
    filteredStaffIds,
    staffIds,
  }: {
    filteredStaffIds: string[];
    staffIds: string[];
  },
): void {
  if (
    staffId &&
    !staffIds.includes(staffId) &&
    filteredStaffIds.includes(staffId)
  ) {
    staffIds.push(staffId);
  }
}

function getStaffIdsInSession({
  diveSession,
  diveSessionGroups,
  filteredStaffIds,
}: {
  diveSession: Pick<DiveSession, 'diveTourSession1' | 'diveTourSession2'>;
  diveSessionGroups: Pick<
    DiveSessionGroup,
    'diveTourGroupSession1' | 'diveTourGroupSession2'
  >[];
  filteredStaffIds: string[];
}) {
  const staffIds: string[] = [];

  _addIfNotAlreadyInListAndNotFiltered(
    diveSession.diveTourSession1?.divingDirector?.staffId,
    { staffIds, filteredStaffIds },
  );
  if (!diveSession.diveTourSession2?.sameStaffSession1) {
    _addIfNotAlreadyInListAndNotFiltered(
      diveSession.diveTourSession2?.divingDirector?.staffId,
      { staffIds, filteredStaffIds },
    );
  }

  _addIfNotAlreadyInListAndNotFiltered(
    diveSession.diveTourSession1?.surfaceSecurityStaffId,
    { staffIds, filteredStaffIds },
  );

  if (!diveSession.diveTourSession2?.sameStaffSession1) {
    _addIfNotAlreadyInListAndNotFiltered(
      diveSession.diveTourSession2?.surfaceSecurityStaffId,
      { staffIds, filteredStaffIds },
    );
  }

  diveSessionGroups.forEach((group) => {
    _addIfNotAlreadyInListAndNotFiltered(
      group.diveTourGroupSession1?.instructor?.staffId,
      { staffIds, filteredStaffIds },
    );
    if (!diveSession.diveTourSession2?.sameStaffSession1) {
      _addIfNotAlreadyInListAndNotFiltered(
        group.diveTourGroupSession2?.instructor?.staffId,
        { staffIds, filteredStaffIds },
      );
    }
  });

  return staffIds;
}
