import { Observable } from 'rxjs';
import { appLoader, LoadableContentPartial } from '../../app-loading';
import { diverBookingGraphqlFetcher } from './diverBookingGraphqlFetcher.service';
import { DiverBookingCriteria, DiverBookingGraphqlResult } from './model';

export const diverBookingRepository = {
  findOne,
};

function findOne(
  criteria: DiverBookingCriteria,
  { isSubscription }: { isSubscription: boolean },
): Observable<LoadableContentPartial<DiverBookingGraphqlResult>> {
  const remoteLoader$ = appLoader.load(
    diverBookingGraphqlFetcher.findOne(criteria, {
      type: isSubscription ? 'subscription' : 'query',
    }),
    {
      type: 'full',
      isSubscription: isSubscription,
    },
  );

  return remoteLoader$;
}
