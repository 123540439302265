import { DiveSessionDefDayTime } from '@mabadive/app-common-model';
import { numberGenerator } from '../../data/number/numberGenerator.service';
import { DIVE_SESSION_DAY_REFERENCE_SEPARATOR } from './DIVE_SESSION_DAY_REFERENCE_SEPARATOR.const';
import { DIVE_SESSION_REFERENCE_WORDS_SEPARATOR } from './DIVE_SESSION_REFERENCE_WORDS_SEPARATOR.const';

export const commonDiveSessionBuilder = {
  buildDayReference,
  buildDayReferenceToReplaceOtherMethodInTheFuture,
  buildDiveSessionReference,
  buildSessionReferenceSuffix,
};

function buildSessionReferenceSuffix({
  dayTime,
}: {
  dayTime: DiveSessionDefDayTime;
}): string {
  const suffix = numberGenerator.fromRange({ min: 0, max: 10000 });
  const referenceSuffix: string = `${
    dayTime.hours * 1000000 + dayTime.minutes * 10000
  }s${suffix}`;

  return referenceSuffix;
}

function buildDayReference(time: Date) {
  // problème, les mois vont de 0 à 11, ce n'est pas intuitif: à remplacer par buildDayReferenceToReplaceOtherMethodInTheFuture ultérieurement
  const monthIndex = time.getUTCMonth();
  const dayIndex = time.getUTCDate();
  const yearIndex = time.getUTCFullYear();

  const monthString = `${monthIndex < 10 ? '0' : ''}${monthIndex}`;
  const dayString = `${dayIndex < 10 ? '0' : ''}${dayIndex}`;

  return `${yearIndex}${DIVE_SESSION_DAY_REFERENCE_SEPARATOR}${monthString}${DIVE_SESSION_DAY_REFERENCE_SEPARATOR}${dayString}`;
}

function buildDayReferenceToReplaceOtherMethodInTheFuture(time: Date) {
  const month = time.getUTCMonth() + 1; // month index is now 1-12
  const day = time.getUTCDate();
  const year = time.getUTCFullYear();

  const monthString = `${month < 10 ? '0' : ''}${month}`;
  const dayString = `${day < 10 ? '0' : ''}${day}`;

  return `${year}${DIVE_SESSION_DAY_REFERENCE_SEPARATOR}${monthString}${DIVE_SESSION_DAY_REFERENCE_SEPARATOR}${dayString}`;
}

function buildDiveSessionReference({
  clubReference,
  dayReference,
  suffix,
}: {
  clubReference: string;
  dayReference: string;
  suffix: string;
}) {
  if (!clubReference) {
    throw new Error('Invalid clubReference');
  }
  if (!dayReference) {
    throw new Error('Invalid dayReference');
  }
  if (suffix == null) {
    throw new Error('Invalid daySessionIndex');
  }

  return `${clubReference}${DIVE_SESSION_REFERENCE_WORDS_SEPARATOR}${dayReference}${DIVE_SESSION_REFERENCE_WORDS_SEPARATOR}${suffix}`;
}
