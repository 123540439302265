import clsx from 'clsx';
import React from 'react';

export const DiveCenterBackButton = ({
  onClick,
  className,
}: {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
}) => {
  return (
    <button
      className={clsx(
        'rounded rounded-tl-xl py-3 px-4 opacity-95 cursor-pointer',
        'text-sm uppercase font-bold',
        'bg-white text-gray-600 hover:text-gray-800 hover:bg-gray-50',
        className,
      )}
      onClick={onClick}
    >
      ← Retour
    </button>
  );
};
