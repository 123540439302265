import { BookingGroupType } from '@mabadive/app-common-model';
import { bookingGroupTypeFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';

export const BookingGroupTypeLabel = ({
  type,
  label,
  format,
  showIndividualWithoutLabel,
  preferLabelOnly,
  className,
}: {
  type: BookingGroupType;
  label?: string;
  format: 'short' | 'long';
  showIndividualWithoutLabel?: boolean;
  preferLabelOnly?: boolean;
  className?: string;
}) => {
  const hasLabel = useMemo(() => label?.trim()?.length > 0, [label]);

  const { displayType, displayLabel } = useMemo(() => {
    if (type === 'individual') {
      if (hasLabel) {
        return {
          displayType: false,
          displayLabel: true,
        };
      } else if (showIndividualWithoutLabel) {
        return {
          displayType: true,
          displayLabel: false,
        };
      } else {
        return {
          displayType: false,
          displayLabel: false,
        };
      }
    }
    const hasLabelAndPreferLabelOnly = preferLabelOnly && hasLabel;
    return {
      displayType: !hasLabelAndPreferLabelOnly,
      displayLabel: true,
    };
  }, [hasLabel, preferLabelOnly, showIndividualWithoutLabel, type]);

  const typeClassNames = useMemo(() => {
    switch (type) {
      case 'club':
        return 'bg-app-secondary text-white';
      case 'company':
        return 'bg-gray-600 text-white';
      case 'individual':
        return 'bg-app-primary text-white';
      case 'association':
        return 'bg-purple-600 text-white';
      case 'collectivity':
        return 'bg-pink-600 text-white';
      default:
        return 'bg-white border border-gray-600 text-gray-600';
    }
  }, [type]);

  return (displayType && type) || (displayLabel && hasLabel) ? (
    <div className={clsx('font-bold', typeClassNames, className)}>
      <div className="flex gap-1 items-center">
        {displayType && type && (
          <div className="">
            {bookingGroupTypeFormatter
              .format(type, {
                format,
              })
              .toUpperCase()}
          </div>
        )}
        <div className="max-w-[80px] truncate">{displayLabel && label}</div>
      </div>
    </div>
  ) : null;
};
