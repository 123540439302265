/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppDocUploadContext,
  Booking,
  Customer_ClubSettingsPublicCustomer,
  DIVE_CERTIFICATIONS,
  DiveCertificationReference,
  DiveMode,
} from '@mabadive/app-common-model';
import { diveModeFormatter } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useAppSecurityUser } from 'src/business/auth/services';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { DIVE_SERVICE_ORGANIZATIONS_OPTIONS } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/forms/BookingMemberDiverEditForm/DIVE_SERVICE_ORGANIZATIONS_OPTIONS.const';
import { NITROX_CERTIFICATION_OPTIONS } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/forms/BookingMemberDiverEditForm/NITROX_CERTIFICATION_OPTIONS.const';
import { mainCertificationOptionBuilder } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/forms/BookingMemberDiverEditForm/mainCertificationOptionBuilder.service';
import { YES_NO_OPTIONS } from 'src/business/dive-center/components';
import {
  AppInputBooleanRHF,
  AppInputRHF,
  AppToogleButtonGroupRHF,
} from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';
import { AppSingleAutocomplete2RHF } from 'src/lib/form/components/AppSingleAutocomplete';
import { AppSingleSelect2HeadlessUIRHF } from 'src/lib/form/components/AppSingleSelect/AppSingleSelect2HeadlessUIRHF';
import { AppInputDatePickerMaterialRHF } from 'src/stories/components/04-form';
import { AppInputFileRHF } from '../../../../../../../lib/form/components/AppInputFile';
import { DiveCenterBookingMemberEditFormModel } from '../model';

export const DiveCenterBookingMemberEditFormDiveService = ({
  form,
  booking,
  participantsIndex,
  clubSettings,
  className = '',
}: {
  form: UseFormReturn<DiveCenterBookingMemberEditFormModel>;
  booking: Booking;
  participantsIndex: number;
  clubSettings: Customer_ClubSettingsPublicCustomer;
  className?: string;
}) => {
  const securityUser = useAppSecurityUser();

  const { control, setValue } = form;

  const baseFormName = `participants.${participantsIndex}` as const;

  const [
    hasCertification,
    certificationReference,
    diverId,
    bookingMemberId,
    mainCertificationDoc,
    gazCertificationDoc,
    medicalCertificateDate,
    medicalCertificateDoc,
  ] = useWatch({
    control,
    name: [
      `${baseFormName}.bookingMember.details.hasCertification`,
      `${baseFormName}.diver.mainCertificationReference`,
      `${baseFormName}.diver._id`,
      `${baseFormName}.bookingMember._id`,
      `${baseFormName}.diver.divingCertification1.mainCertificationDoc`,
      `${baseFormName}.diver.divingCertification1.gazCertificationDoc`,
      `${baseFormName}.diver.medicalCertificateDate`,
      `${baseFormName}.bookingMember.details.medicalCertificate.doc`,
    ],
  });

  const mainCertification = useMemo(
    () =>
      DIVE_CERTIFICATIONS.find((x) => x.reference === certificationReference),
    [certificationReference],
  );
  const orgGroup = mainCertification?.organizationv2;
  const pendingList = booking.bookingCustomerConfig?.pendingList;
  const displayFields =
    clubSettings?.publicSettings?.customerSpace?.displayFields;

  const diveModesOptions: ValueLabel<DiveMode>[] = useMemo(
    () =>
      (
        displayFields?.diversExpectedDiveInfo
          ?.expectedDiveModesWithCertification ?? []
      )
        .filter((x) => !!x)
        .map((diveMode) => ({
          value: diveMode,
          label: diveModeFormatter.formatDiveMode(diveMode, {
            format: 'long-label',
          }),
        })),
    [displayFields?.diversExpectedDiveInfo?.expectedDiveModesWithCertification],
  );

  const diveModesOptionsBeginner: ValueLabel<DiveMode>[] = useMemo(
    () =>
      (displayFields?.diversExpectedDiveInfo?.expectedDiveModesBeginner ?? [])
        .filter((x) => !!x)
        .map((diveMode) => ({
          value: diveMode,
          label: diveModeFormatter.formatDiveMode(diveMode, {
            format: 'long-label',
          }),
        })),
    [displayFields?.diversExpectedDiveInfo?.expectedDiveModesBeginner],
  );

  const mainCertificationOptions = useMemo(
    () =>
      mainCertificationOptionBuilder.buildOptions({
        // orgGroupReferencev2: orgGroup,
      }),
    [],
  );

  const mainCertificationUploadContext = useMemo(() => {
    const context: AppDocUploadContext = {
      clubDiverId: diverId,
      bookingId: booking?._id,
      bookingMemberId: bookingMemberId,
      appBookletPageId: undefined,
      fileType: undefined, // dépend du fichier choisi
      scope: 'diver',
      isPublic: false,
      docRef: 'dive-certification',
      status: 'submitted',
      docDate: undefined,
    };
    return context;
  }, [booking?._id, bookingMemberId, diverId]);

  const gazCertificationUploadContext = useMemo(() => {
    const context: AppDocUploadContext = {
      clubDiverId: diverId,
      bookingId: booking?._id,
      bookingMemberId: bookingMemberId,
      appBookletPageId: undefined,
      fileType: undefined, // dépend du fichier choisi
      scope: 'diver',
      isPublic: false,
      docRef: 'dive-certification',
      status: 'submitted',
      docDate: undefined,
    };
    return context;
  }, [booking?._id, bookingMemberId, diverId]);

  const medicalCertificateUploadContext = useMemo(() => {
    const context: AppDocUploadContext = {
      clubDiverId: diverId,
      bookingId: booking?._id,
      bookingMemberId: bookingMemberId,
      appBookletPageId: undefined,
      fileType: undefined, // dépend du fichier choisi
      scope: 'diver',
      isPublic: false,
      docRef: 'medical-certificate',
      status: 'submitted',
      docDate: medicalCertificateDate,
    };
    return context;
  }, [booking?._id, bookingMemberId, diverId, medicalCertificateDate]);

  return (
    <div className={`w-full max-w-[97vw] flex flex-col gap-4 ${className}`}>
      {pendingList?.diversDivingInfo && (
        <>
          <div className={'w-full sm:max-w-[24rem]'}>
            {displayFields?.diversDivingInfo?.hasCertification?.edit && (
              <AppFormControlRHF
                className={'md:col-span-4 w-full'}
                label="Ce participant a-t-il un niveau de plongée"
                control={control}
                name={`${baseFormName}.bookingMember.details.hasCertification`}
                required={
                  displayFields?.diversDivingInfo?.hasCertification?.required
                }
                renderComponent={(props) => (
                  <AppToogleButtonGroupRHF
                    {...props}
                    color="boolean"
                    theme="strong"
                    items={YES_NO_OPTIONS}
                    // selected={selected}
                    // onChanges={onChange}
                  />
                )}
              />
            )}
          </div>
          {hasCertification && (
            <>
              <div
                className={
                  'w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4 gap-4'
                }
              >
                {displayFields?.diversDivingInfo?.mainCertificationReference
                  ?.edit && (
                  <AppFormControlRHF
                    className="md:col-span-2 w-full"
                    label={'Niveau / certification'}
                    control={control}
                    name={`${baseFormName}.diver.divingCertification1.mainCertificationReference`}
                    required={
                      displayFields?.diversDivingInfo
                        ?.mainCertificationReference?.required ||
                      !!mainCertificationDoc?._id
                    }
                    renderComponent={(props) => (
                      <AppSingleAutocomplete2RHF
                        optionClassName="text-xs sm:text-sm"
                        {...props}
                        options={mainCertificationOptions}
                        onChange={(value: DiveCertificationReference) => {
                          setValue(
                            `${baseFormName}.diver.mainCertificationReference`,
                            value,
                          );
                        }}
                      />
                    )}
                  />
                )}
                {displayFields?.diversDivingInfo?.mainCertificationDoc
                  ?.edit && (
                  <AppInputFileRHF
                    className="md:col-span-2 w-full"
                    label="Copie carte de niveau"
                    control={control}
                    setValue={setValue}
                    appDocUploadContext={mainCertificationUploadContext}
                    name={`${baseFormName}.diver.divingCertification1.mainCertificationDoc`}
                    required={
                      displayFields?.diversDivingInfo?.mainCertificationDoc
                        ?.required
                    }
                  />
                )}

                {orgGroup?.orgGroupReferencev2 === 'international' &&
                  displayFields?.diversDivingInfo?.deepDiver?.edit && (
                    <AppFormControlRHF
                      className="md:col-span-2 w-full"
                      label={'Deep Diver'}
                      control={control}
                      name={`${baseFormName}.diver.divingCertification1.specialties.deepDiver`}
                      required={
                        false // case à cocher, ne peux pas être obligatoire
                      }
                      renderComponent={(props) => (
                        <AppInputBooleanRHF
                          className="scale-125 block mx-auto mt-2"
                          {...props}
                          type="checkbox"
                        />
                      )}
                    />
                  )}
                {displayFields?.diversDivingInfo?.gazCertificationReference
                  ?.edit && (
                  <AppFormControlRHF
                    className="md:col-span-2 w-full"
                    label="Nitrox"
                    control={control}
                    name={`${baseFormName}.diver.divingCertification1.gazCertificationReference`}
                    required={
                      displayFields?.diversDivingInfo?.gazCertificationReference
                        ?.required || !!gazCertificationDoc?._id
                    }
                    renderComponent={(props) => (
                      <AppSingleSelect2HeadlessUIRHF
                        className={'w-full'}
                        theme="material-ui"
                        color="theme"
                        {...props}
                        options={NITROX_CERTIFICATION_OPTIONS}
                      />
                    )}
                  />
                )}
                {displayFields?.diversDivingInfo?.gazCertificationDoc?.edit && (
                  <AppInputFileRHF
                    className="md:col-span-2 w-full"
                    label="Copie carte nitrox"
                    control={control}
                    setValue={setValue}
                    appDocUploadContext={gazCertificationUploadContext}
                    name={`${baseFormName}.diver.divingCertification1.gazCertificationDoc`}
                    required={
                      displayFields?.diversDivingInfo?.gazCertificationDoc
                        ?.required
                    }
                  />
                )}
                {displayFields?.diversDivingInfo?.orgReference?.edit && (
                  <AppFormControlRHF
                    className="md:col-span-2 w-full"
                    label={'Organisme'}
                    control={control}
                    name={`${baseFormName}.diver.divingCertification1.orgReference`}
                    required={
                      displayFields?.diversDivingInfo?.orgReference?.required
                    }
                    renderComponent={(props) => (
                      <AppSingleSelect2HeadlessUIRHF
                        className={'w-full'}
                        theme="material-ui"
                        color="theme"
                        {...props}
                        options={DIVE_SERVICE_ORGANIZATIONS_OPTIONS}
                        onChange={(e) => {
                          // onChangeOrgReference(e)
                        }}
                      />
                    )}
                  />
                )}
              </div>
              <div
                className={
                  'w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4 gap-4'
                }
              >
                {displayFields?.diversDivingInfo?.lastDiveDate?.edit && (
                  <AppFormControlRHF
                    className="md:col-span-2 w-full"
                    label="Date dernière plongée"
                    control={control}
                    name={`${baseFormName}.bookingMember.details.lastDiveDate`}
                    required={
                      displayFields?.diversDivingInfo?.lastDiveDate?.required
                    }
                    renderComponent={(props) => (
                      <AppInputDatePickerMaterialRHF
                        className="w-full"
                        {...props}
                      />
                    )}
                  />
                )}
                {displayFields?.diversDivingInfo?.totalDivesCount?.edit && (
                  <AppFormControlRHF
                    className="md:col-span-2 w-full"
                    label="Nb de plongées"
                    control={control}
                    name={`${baseFormName}.bookingMember.details.totalDivesCount`}
                    required={
                      displayFields?.diversDivingInfo?.totalDivesCount?.required
                    }
                    renderComponent={(props) => (
                      <AppInputRHF
                        className="w-full"
                        type="number"
                        {...props}
                      />
                    )}
                  />
                )}
              </div>
              <div
                className={
                  'w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4 gap-4'
                }
              >
                {displayFields?.diversDivingInfo?.medicalCertificateDate
                  ?.edit &&
                  !medicalCertificateDoc?._id && (
                    <AppFormControlRHF
                      className="md:col-span-2 w-full"
                      label="Date certificat médical"
                      control={control}
                      name={`${baseFormName}.diver.medicalCertificateDate`}
                      required={
                        displayFields?.diversDivingInfo?.medicalCertificateDate
                          ?.required
                      }
                      renderComponent={(props) => (
                        <AppInputDatePickerMaterialRHF
                          className="w-full"
                          {...props}
                        />
                      )}
                    />
                  )}
                {displayFields?.diversDivingInfo?.medicalCertificateDoc
                  ?.edit && (
                  <AppInputFileRHF
                    className="md:col-span-2 w-full"
                    label="Copie certificat médical"
                    control={control}
                    setValue={setValue}
                    appDocUploadContext={medicalCertificateUploadContext}
                    name={`${baseFormName}.bookingMember.details.medicalCertificate.doc`}
                    required={
                      displayFields?.diversDivingInfo?.medicalCertificateDoc
                        ?.required
                    }
                  />
                )}
              </div>
              {pendingList?.diversExpectedDiveInfo &&
                diveModesOptions?.length > 0 && (
                  <AppFormControlRHF
                    className="md:col-span-2 w-full"
                    label="Prestation souhaitée"
                    control={control}
                    name={`${baseFormName}.bookingMember.details.expectedDive.diveMode`}
                    required={false}
                    renderComponent={(props) => (
                      <AppSingleAutocomplete2RHF
                        {...props}
                        options={diveModesOptions}
                        onChange={(diveMode: DiveMode) => {
                          // onDiveModeChange(diveMode);
                        }}
                      />
                    )}
                  />
                )}
            </>
          )}
        </>
      )}

      {pendingList?.diversExpectedDiveInfo &&
        hasCertification === false &&
        diveModesOptionsBeginner?.length > 0 && (
          <div className={'mt-2 w-full sm:max-w-[36rem]'}>
            <AppFormControlRHF
              label="Prestation souhaitée"
              control={control}
              name={`${baseFormName}.bookingMember.details.expectedDive.diveMode`}
              required={false}
              renderComponent={(props) => (
                <AppToogleButtonGroupRHF
                  {...props}
                  items={diveModesOptionsBeginner}
                  color="dive-mode"
                  theme="strong"
                  // onChange={(diveMode: DiveMode) => {
                  //   // onDiveModeChange(diveMode);
                  // }}
                />
              )}
            />
          </div>
        )}
      {displayFields?.diversExpectedDiveInfo?.comment?.edit && (
        <AppFormControlRHF
          className="w-full"
          label="Commentaire"
          control={control}
          name={`${baseFormName}.bookingMember.details.expectedDive.customerComment`}
          required={displayFields?.diversExpectedDiveInfo?.comment?.required}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth multiline rowsMax={15} rows={2} />
          )}
        />
      )}
    </div>
  );
};
