import { DiveModeGroup } from '@mabadive/app-common-model';

export const diveModeGroupFormatter = {
  formatDiveModeGroup,
};

export type DiveModeGroupStringFormat = 'short' | 'long';

function formatDiveModeGroup(
  diveModeGroup: DiveModeGroup,
  { format }: { format: DiveModeGroupStringFormat },
): string {
  if (format === 'long') {
    switch (diveModeGroup) {
      case 'dive-discover':
        return 'Plongée découverte';
      case 'dive-training':
        return 'Plongée de formation';
      case 'dive-explo':
        return `Plongée d'exploration`;
      case 'dive-autosupervised':
        return 'Plongée auto-encadrée';
      case 'snorkeling':
        return 'Randonnée palmée';
      case 'free-dive':
        return 'Apnée';
      case 'observer':
        return 'Accompagnateur';
      case 'watchingTour':
        return "Excursion d'observation";
      case 'store':
        return 'Boutique';
    }
  }
  if (format === 'short') {
    switch (diveModeGroup) {
      case 'dive-discover':
        return 'Découverte';
      case 'dive-training':
        return 'Formation';
      case 'dive-explo':
        return `Explo`;
      case 'dive-autosupervised':
        return 'Auto-encadré';
      case 'snorkeling':
        return 'Rando palmée';
      case 'free-dive':
        return 'Apnée';
      case 'observer':
        return 'Accompagnateur';
      case 'watchingTour':
        return 'Observation';
      case 'store':
        return 'Boutique';
    }
  }

  // default
  return diveModeGroup;
}
