import {
  APP_CURRENCIES,
  RegionalTaxCategory,
  RegionalTaxContext,
  RegionalTaxProductType,
  RegionalTaxResumeCategory,
} from '@mabadive/app-common-model';
import { numberFormatter } from '../../business/string';

export const taxesCalculator = {
  getTotalWithoutTaxes,
  getTaxTypeAmount,
  getTaxesAmounts,
};

function getTaxesAmounts({
  price,
  productType,
  taxContext,
}: {
  price: number;
  productType: RegionalTaxProductType;
  taxContext: RegionalTaxContext;
}): {
  taxesRoundingDiscount: number;
  totalWithTaxes: number;
  totalWithoutTaxes: number;
  vatTax: number;
  socialTax: number;
} {
  if (price === undefined || price === null || isNaN(price)) {
    return {
      taxesRoundingDiscount: undefined,
      totalWithTaxes: undefined,
      totalWithoutTaxes: undefined,
      vatTax: undefined,
      socialTax: undefined,
    };
  }
  let totalWithoutTaxes = taxesCalculator.getTotalWithoutTaxes({
    price: price,
    taxContext,
    productType,
  });
  let isOk = false;
  let taxesRoundingDiscount = 0;
  let vatTax = 0;
  let socialTax = 0;
  let totalWithTaxes = 0;
  for (let i = 0; i < 10 && !isOk; i++) {
    vatTax = taxesCalculator.getTaxTypeAmount({
      totalWithoutTaxes,
      taxContext,
      productType,
      category: 'vat',
    });
    socialTax = taxesCalculator.getTaxTypeAmount({
      totalWithoutTaxes,
      taxContext,
      productType,
      category: 'social',
    });
    totalWithTaxes =
      totalWithoutTaxes + vatTax + socialTax - taxesRoundingDiscount;
    isOk = totalWithTaxes === price;
    if (!isOk && i < 10) {
      if (totalWithTaxes < price) {
        totalWithoutTaxes++; // au augmente le prix HT de 1, car le total HT + taxes est inférieur au prix de vente total
      } else if (totalWithTaxes > price) {
        taxesRoundingDiscount = totalWithTaxes - price;
      }
    }
  }
  if (!isOk) {
    console.error('Error calculating taxes from price:', {
      taxesRoundingDiscount,
      totalWithTaxes,
      totalWithoutTaxes,
      vatTax,
      socialTax,
    });
  }
  return {
    taxesRoundingDiscount,
    totalWithTaxes,
    totalWithoutTaxes,
    vatTax,
    socialTax,
  };
}

function getTotalWithoutTaxes({
  price,
  productType,
  taxContext,
}: {
  price: number;
  productType: RegionalTaxProductType;
  taxContext: RegionalTaxContext;
}): number {
  if (price === undefined || price === null || isNaN(price)) {
    return undefined;
  }
  const taxResumes = taxContext.taxeResumesByProductType[productType];

  const { totalRatePercent } = taxResumes;

  if (totalRatePercent === 0) {
    return price;
  }
  if (totalRatePercent === 1) {
    return 0;
  }
  const totalWithoutTaxes = price / (1 + totalRatePercent / 100);

  const currency = APP_CURRENCIES.find(
    (x) => x.isoCode === taxContext.generalSettings.currencyIsoCode,
  );
  return numberFormatter.roundWithCents(totalWithoutTaxes, currency.cents);
}

function getTaxTypeAmount({
  totalWithoutTaxes,
  productType,
  category,
  taxContext,
}: {
  totalWithoutTaxes: number;
  productType: RegionalTaxProductType;
  category: RegionalTaxCategory;
  taxContext: RegionalTaxContext;
}): number {
  const taxResume = taxContext.taxeResumesByProductType[productType];

  const { categoriesMap } = taxResume;

  if (totalWithoutTaxes === 0) {
    return 0;
  }

  const categoryResume: RegionalTaxResumeCategory = categoriesMap[category];
  if (!categoryResume) {
    // console.error(
    //   `[taxesCalculator] ${category} not found in :`,
    //   categoriesMap,
    // );
    return 0;
  }
  const totalRatePercent = categoryResume.totalRatePercent;

  if (totalRatePercent === 0) {
    return 0;
  }

  const totalTaxes = totalWithoutTaxes * (totalRatePercent / 100);

  const currency = APP_CURRENCIES.find(
    (x) => x.isoCode === taxContext.generalSettings.currencyIsoCode,
  );
  return numberFormatter.roundWithCents(totalTaxes, currency.cents);
}
