import {
  ClubSettingsPlanning,
  ClubSettingsPlanningDiveTour,
  ClubSettingsPlanningPeriodConfig,
} from '@mabadive/app-common-model';
import { dataSorter } from '../../../data';
import { dateService } from '../../../data/date/date.service';

export const defaultPlanningPeriodConfigsSelector = {
  getPeriodConfigsDiveTours,
};

function getPeriodConfigsDiveTours({
  date,
  settingsPlanning,
}: {
  date: Date;

  settingsPlanning: ClubSettingsPlanning;
}): ClubSettingsPlanningDiveTour[] {
  const periodConfigs: ClubSettingsPlanningPeriodConfig[] = selectPeriodConfigs(
    {
      date,
      settingsPlanning,
    },
  );

  const isoWeekDay = dateService.getISODay(date);

  const diveToursArrays: ClubSettingsPlanningDiveTour[][] = periodConfigs.map(
    (x) => x.weekDays.find((x) => x.isoWeekDay === isoWeekDay)?.diveTours ?? [],
  );

  const diveTours = ([] as ClubSettingsPlanningDiveTour[]).concat(
    ...diveToursArrays,
  );

  return diveTours;
}

// voir aussi de clubStaffMemberAvailabilitySelector
function selectPeriodConfigs({
  date,
  settingsPlanning,
}: {
  date: Date;
  settingsPlanning: ClubSettingsPlanning;
}): ClubSettingsPlanningPeriodConfig[] {
  const periodConfigs = settingsPlanning?.periodConfigs.filter(
    (x) =>
      x.enabled &&
      (!x.beginDate ||
        dateService.isBefore(x.beginDate, date) ||
        dateService.isSameDayUTC(x.beginDate, date)) &&
      (!x.endDate ||
        dateService.isFutureDayUTC(x.endDate, date) ||
        dateService.isSameDayUTC(x.endDate, date)),
  );
  if (periodConfigs.length === 0) {
    return [];
  }
  {
    const exclusivePeriodConfigs = periodConfigs.filter(
      (x) => x.conflictMode === 'exclusive',
    );
    if (exclusivePeriodConfigs.length > 0) {
      return selectFirstPeriod(exclusivePeriodConfigs);
    }
  }
  {
    const cumulativePeriodConfigs = periodConfigs.filter(
      (x) => x.conflictMode === 'cumul',
    );
    if (cumulativePeriodConfigs.length > 0) {
      return cumulativePeriodConfigs;
    }
  }
  {
    const defaultPeriodConfigs = periodConfigs.filter(
      (x) => x.conflictMode === 'default',
    );
    if (defaultPeriodConfigs.length > 0) {
      return selectFirstPeriod(defaultPeriodConfigs);
    }
  }
}

function selectFirstPeriod(
  exclusivePeriodConfigs: ClubSettingsPlanningPeriodConfig[],
) {
  const [first] = dataSorter.sortMultiple(exclusivePeriodConfigs, {
    getSortAttributes: (c) => {
      return [
        {
          value: c.beginDate ? new Date(c.beginDate)?.getTime() : undefined,
          asc: false,
        },
        {
          value: c.endDate ? new Date(c.endDate).getTime() : undefined,
          asc: true,
        },
        {
          value: c._id,
        },
      ];
    },
  });
  return [first];
}
