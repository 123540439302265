import {
  AppInquiryQuestionAnswerFormat,
  AppInquiryQuestionAnswerTextShortFormat,
  AppInquiryType,
} from '@mabadive/app-common-model';
export const inquiryFormatter = {
  formatInquiryType,
  formatTextShortFormat,
  formatQuestionAnswerFormat,
};

function formatInquiryType(type: AppInquiryType): string {
  switch (type) {
    case 'medical-form':
      return 'Formulaire médical';
    case 'parental-consent':
      return 'Autorisation parentale';
    default:
      return type;
  }
}

function formatQuestionAnswerFormat(
  answerFormat: AppInquiryQuestionAnswerFormat,
) {
  switch (answerFormat) {
    case 'yes-no':
      return 'Oui / Non';
    case 'agreement':
      return 'Accord (case à cocher)';
    case 'text-short':
      return 'Texte court';
    case 'text-long':
      return 'Texte multi-lignes';
    case 'date':
      return 'Date';
    default:
      return answerFormat;
  }
}
function formatTextShortFormat(
  textShortFormat: AppInquiryQuestionAnswerTextShortFormat,
) {
  switch (textShortFormat) {
    case 'email': {
      return 'Email';
    }
    case 'phone': {
      return 'Telephone';
    }
    default:
      return textShortFormat;
  }
}
