import { DiveServiceOfferEquipment, DiveServiceOfferSupervision, DiveServicePlan } from '@mabadive/app-common-model';

export const diveServicePlanNameBuilder = {
  getPlanName,
};

function getPlanName(plan: Pick<DiveServicePlan, 'supervision' | 'equipment'>, format: 'short' | 'long') {
  const options: string[] = [];
  const supervision = formatDiveServiceOfferSupervision(plan.supervision, format);
  if (supervision) {
    options.push(supervision);
  }
  const equipment = formatDiveServiceOfferEquipment(plan.equipment, format);
  if (equipment) {
    options.push(equipment);
  }
  const name = options.length ? ` ${options.join('/')}` : ' enfant';
  return name;
}

function formatDiveServiceOfferSupervision(supervision: DiveServiceOfferSupervision, format: 'short' | 'long') {
  switch (supervision) {
    case 'supervised':
      if (format === 'short') {
        return 'PE';
      }
      return 'encadré';
    case 'autonomous':
      if (format === 'short') {
        return 'PA';
      }
      return 'autonome';
    case 'any-supervision':
      return undefined;
  }
}

function formatDiveServiceOfferEquipment(equipment: DiveServiceOfferEquipment, format: 'short' | 'long') {
  switch (equipment) {
    case 'equipped':
      if (format === 'short') {
        return 'EQ';
      }
      return 'équipé';
    case 'not-equipped':
      if (format === 'short') {
        return 'NEQ';
      }
      return 'non-équipé';
    case 'partially-equipped':
      if (format === 'short') {
        return 'PEQ';
      }
      return 'partiellement équipé';
    case 'any-equipment':
      return undefined;
  }
}
