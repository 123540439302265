import { useCallback, useState } from 'react';
import { appLogger } from '../../business/_core/modules/root/logger';
import { fileApiClient } from './fileApiClient.service';

export function useAppInputFileUpload<T>({
  onSuccess,
  onError,
}: {
  onSuccess: (result: T, file: File) => void;
  onError?: (err: Error) => void;
}) {
  const [isFileLoading, setIsFileLoading] = useState(false);
  const uploadFile = useCallback(
    async (file: File, { url, json }: { url: string; json?: any }) => {
      setIsFileLoading(true);
      try {
        const result = await fileApiClient.uploadFile<T>(file, {
          url,
          json,
        });
        onSuccess(result, file);
      } catch (err) {
        appLogger.error('Error uploading file', {
          err: err as Error,
        });
        onError && onError(err as Error);
      } finally {
        setIsFileLoading(false);
      }
    },
    [onError, onSuccess],
  );
  return { isFileLoading, uploadFile };
}
