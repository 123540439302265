/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  APP_COMPANY_DEFAULT_LANGUAGES,
  APP_LANGUAGES_CODES,
  AppLanguageCode,
} from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
//import { RegionalTaxLabel } from '../company/RegionalTax/RegionalTaxLabel';
import { appLanguageFormater } from '@mabadive/app-common-services';

export function useLanguagesOptions({
  scope,
}: {
  scope: 'company' | 'all';
}): ValueLabel<AppLanguageCode, string>[] {
  return useMemo(() => {
    const languagesCodes: AppLanguageCode[] =
      scope === 'company' ? APP_COMPANY_DEFAULT_LANGUAGES : APP_LANGUAGES_CODES;
    return languagesCodes.map((lang) => {
      const o: ValueLabel<AppLanguageCode, string> = {
        value: lang,
        label: appLanguageFormater.formatLanguageLabel(lang, {
          prependFlag: true,
        }),
      };
      return o;
    });
  }, [scope]);
}
