"use strict";
// remplace DiveServiceTrainingType, et est complété par DiveServiceTrainingTechnicalType
exports.__esModule = true;
exports.diveServiceTrainingTechnicalCategories = void 0;
exports.diveServiceTrainingTechnicalCategories = [
    {
        id: 'beginner',
        label: 'Débutants',
        specialities: ['child', 'discover']
    },
    {
        id: 'main',
        label: 'Cursus principal',
        specialities: ['main']
    },
    {
        id: 'gas',
        label: 'Mélange de gaz',
        specialities: ['gas_blending']
    },
    {
        id: 'equipment',
        label: 'Equipment',
        specialities: ['rebreather', 'dry_suit', 'equipment']
    },
    {
        id: 'tek_env',
        label: 'Environnement technique',
        specialities: [
            'deep',
            'altitude',
            'cold_water',
            'ice',
            'cave',
            'wreck',
            'drift',
            'technical',
            'specialty',
        ]
    },
    {
        id: 'safety',
        label: 'Sécurité',
        specialities: ['safety', 'navigation']
    },
    {
        id: 'instructor',
        label: 'Initiateur / Instructeur',
        specialities: ['instructor']
    },
    {
        id: 'photo_video',
        label: 'Photo & vidéo',
        specialities: ['photo', 'video']
    },
    {
        id: 'science',
        label: 'Science',
        specialities: ['biology', 'scientific']
    },
];
