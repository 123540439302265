import { jsonParser, SimpleStore } from '@mabadive/app-common-services';
import { HTTPError } from 'ky';
import { from, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  delay,
  first,
  map,
  retryWhen,
  switchMap,
} from 'rxjs/operators';
import { appLogger } from 'src/business/_core/modules/root/logger';
import {
  httpClient,
  HttpClientFetchOptions,
  HttpClientMethodRead,
  HttpClientMethodWrite,
} from '../http';
import { ApiClientHttpError } from './ApiClientHttpError.type';
import {
  ApiClientRequestOptions,
  ApiClientRequestWithBodyOptions,
} from './ApiClientRequestOptions.model';
import { apiClientStoreProvider } from './apiClientStoreProvider.service';

export type ApiClientErrorCallback = ({
  httpStatus,
  err,
}: {
  httpStatus: number;
  err: Error;
}) => void;

const config: {
  errorCallback: ApiClientErrorCallback;
} = {
  errorCallback: ({ httpStatus, err }: { httpStatus: number; err: Error }) => {
    // default callback
  },
};

export const apiClient = {
  init,
  get,
  put,
  post,
  delete_,
};

export type ApiClientConfiguration = {
  baseStore: SimpleStore<any>;
  baseUrl: string;
  appClientId: string;
  appVersion: string;
  errorCallback: ApiClientErrorCallback;
};

function init({
  baseStore,
  baseUrl,
  appClientId,
  appVersion,
  errorCallback,
}: ApiClientConfiguration) {
  const store = apiClientStoreProvider.init(baseStore);

  store.baseUrl.set(baseUrl);
  store.appClientId.set(appClientId);
  store.appVersion.set(appVersion);
  if (errorCallback) {
    config.errorCallback = errorCallback;
  }
}

function fetch<T>({
  resource,
  method,
  options,
}:
  | {
      resource: string;
      method: HttpClientMethodWrite;
      options: ApiClientRequestWithBodyOptions;
    }
  | {
      resource: string;
      method: HttpClientMethodRead;
      options: ApiClientRequestOptions;
    }): Observable<T> {
  const apiClientStore = apiClientStoreProvider.get();

  return apiClientStore.baseUrl.get().pipe(
    first(),
    switchMap(
      () =>
        buildHttpClientFetchOptions({
          options,
          method,
        }),
      (baseUrl, httpClientFetchOptions) => ({
        baseUrl,
        httpClientFetchOptions,
      }),
    ),
    switchMap(({ baseUrl, httpClientFetchOptions }) =>
      httpClient.fetch(`${baseUrl}${resource}`, httpClientFetchOptions),
    ),
    switchMap((response) => {
      return from(response.text()).pipe(
        map((text) => {
          try {
            return jsonParser.parseJSONWithDates<T>(text);
          } catch (err) {
            appLogger.error('[apiClient] Error parsing response', err);
            throw err;
          }
        }),
      );
    }),
    first(),
    catchError((err: any) => {
      const httpStatus =
        err?.response?.status ??
        err?.response?.statusText ??
        (err as any)?.status;
      if (httpStatus === 304) {
        // status 304 (non modifié) = pas de contenu dans la réponse : on n'essaie pas de le parser, et on ne log pas d'erreur
        return throwError(err);
      }
      if (config.errorCallback) {
        config.errorCallback({
          httpStatus,
          err,
        });
      }
      if (err.response) {
        appLogger.warn(
          `[apiClient] Http error response ${err.response.status}`,
          err.response,
        );
        return throwError(
          new ApiClientHttpError({
            response: err.response,
            cause: err,
          }),
        );
      } else {
        appLogger.warn('[apiClient] Http error', err);
        return throwError(err);
      }
    }),
    retryWhen((errors$) => {
      return errors$.pipe(
        switchMap((err: HTTPError, i) => {
          if (
            err.name === 'TimeoutError' ||
            err.name === 'SyntaxError' ||
            err.message === 'The network connection was lost.' ||
            err.message === 'cannot parse response'
          ) {
            // network timeout
            if (method === 'get' && i < (options.maxTries ?? 1)) {
              // NOTE: ne pas ré-essayer si ce n'est pas un GET, sinon risque d'effets de bords!
              // NOTE 2: il y a un mécanisme de refresh dans "ky" (désactivé pour le moment)
              return of(err).pipe(delay(500 * (1 + i))); // retry after delay
            } else {
              // TODO: gérer correctement un message d'erreur (global comme pour le errorCallback ???)
              return throwError(err);
            }
          } else {
            return throwError(err);
          }
        }),
      );
    }),
  );
}

function buildHttpClientFetchOptions({
  options,
  method,
}:
  | {
      method: HttpClientMethodWrite;
      options: ApiClientRequestWithBodyOptions;
    }
  | {
      method: HttpClientMethodRead;
      options: ApiClientRequestOptions;
    }): Observable<HttpClientFetchOptions> {
  const httpClientFetchOptions: HttpClientFetchOptions = {
    ...options,
    method,
    timeout: options.timeout ?? 60000, // 60s par default (on laisse le serveur ajuster le timeout globalement ou par requête dans app.config.server.ts)
  } as unknown;

  const apiClientStore = apiClientStoreProvider.get();

  return of(options).pipe(
    switchMap((options) => {
      if (options.authenticate) {
        return apiClientStore.authenticationToken.get().pipe(
          first(),
          map((authenticationToken) => ({
            ...httpClientFetchOptions,
            headers: {
              ...httpClientFetchOptions.headers,
              authorization: `Bearer ${authenticationToken}`,
            },
          })),
        );
      }
      return of(httpClientFetchOptions);
    }),
    switchMap((httpClientFetchOptions) => {
      return apiClientStore.appClientId.get().pipe(
        first(),
        map((appClientId) => ({
          ...httpClientFetchOptions,
          headers: {
            ...httpClientFetchOptions.headers,
            'app-client-id': appClientId,
          },
        })),
      );
    }),
    switchMap((httpClientFetchOptions) => {
      return apiClientStore.appVersion.get().pipe(
        first(),
        map((appVersion) => ({
          ...httpClientFetchOptions,
          headers: {
            ...httpClientFetchOptions.headers,
            'app-version': appVersion,
          },
        })),
      );
    }),
  );
}

function get<T>(resource: string, options: ApiClientRequestOptions = {}) {
  return fetch<T>({
    resource,
    method: 'get',
    options,
  });
}

function post<T>(
  resource: string,
  options: ApiClientRequestWithBodyOptions = {},
) {
  return fetch<T>({
    resource,
    method: 'post',
    options,
  });
}

function put<T>(
  resource: string,
  options: ApiClientRequestWithBodyOptions = {},
) {
  return fetch<T>({
    resource,
    method: 'put',
    options,
  });
}

function delete_<T>(
  resource: string,
  options: ApiClientRequestWithBodyOptions = {},
) {
  return fetch<T>({
    resource,
    method: 'delete',
    options,
  });
}
