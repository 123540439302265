import { Booking } from '@mabadive/app-common-model';
import { bookingStatusFormatter } from '@mabadive/app-common-services';
import React from 'react';

export const BookingStatusLabel = ({
  booking,
}: {
  booking: Pick<Booking, 'active' | 'bookingStatus'>;
}) => {
  return (
    <span
      className={`whitespace-nowrap text-xs mr-2 sm:mr-4 md:mr-5 ${
        booking.active
          ? booking.bookingStatus === 'confirmed'
            ? 'px-1 font-bold bg-status-success text-white'
            : booking.bookingStatus === 'pending'
            ? 'px-1 font-bold bg-status-warn text-white'
            : 'px-1 font-bold bg-status-error text-white'
          : 'font-bold text-red-500'
      }
    }`}
    >
      {booking.active
        ? bookingStatusFormatter
            .formatStatus(booking.bookingStatus)
            ?.toUpperCase()
        : 'ARCHIVÉE'}
    </span>
  );
};
