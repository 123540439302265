"use strict";
exports.__esModule = true;
exports.DEFAULT_EQUIPMENT_SETTINGS_REBREATHER = void 0;
exports.DEFAULT_EQUIPMENT_SETTINGS_REBREATHER = {
    type: 'rebreather',
    enabled: false,
    club: {
        enabled: false,
        models: []
    },
    self: {
        enabled: false,
        models: []
    }
};
