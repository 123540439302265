import { createStore, Store } from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension/developmentOnly';
import { Observable } from 'rxjs';
import { ReduceAction } from './ReduceAction.model';
import { SimpleStore } from './SimpleStore.model';
import { StoreActionDescriptor } from './StoreActionDescriptor.model';

function createRxjsStore<T>(enableDevToolsEnhancer: boolean): {
  store: Store<T>;
  state$: Observable<T>;
} {
  // (action: StoreActionDescriptor, reduceFn: (state: T) => T) => void
  const reducer = (state = {} as T, action: ReduceAction) => {
    if (action.type.indexOf('@@') !== 0 && action.payload) {
      const reduceFn = action.payload;
      return reduceFn(state);
    }

    return state;
  };

  const enhancer = enableDevToolsEnhancer ? devToolsEnhancer({}) : undefined;

  const store: Store<T> = createStore<T, ReduceAction, any, any>(reducer, enhancer);

  // from https://medium.com/@fahad19/streaming-redux-state-as-an-observable-with-rxjs-390a8f7bc08c
  const state$ = new Observable<T>((observer) => {
    // emit the current state as first value:
    observer.next(store.getState());
    const unsubscribe = store.subscribe(() => {
      // emit on every new state changes
      observer.next(store.getState());
    }); // let's return the function that will be called
    // when the Observable is unsubscribed
    return unsubscribe;
  });

  return { store, state$ };
}

function create<T>(enableDevToolsEnhancer: boolean): SimpleStore<T> {
  const { store, state$ }: {
    store: Store<T>;
    state$: Observable<T>;
  } = createRxjsStore(enableDevToolsEnhancer);
  // const state$ = from<Observable<T>>(store);

  function reduceState(action: StoreActionDescriptor, reduceFn: (state: T) => T): void {
    store.dispatch({ type: `${action.target}_${action.id}`, payload: reduceFn });
  }

  const simpleStore = {
    reduceState,
    getState: () => state$,
    getStateSnapshot: () => store.getState(),
  };

  return simpleStore;
}

export const simpleStoreFactory = {
  create,
};
