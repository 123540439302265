import Slider from '@material-ui/core/Slider';
import clsx from 'clsx';
import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import { Area, Point } from 'react-easy-crop/types';
import { AppFormLabel } from '../AppFormLabel';
import { appImageConverter } from './appImageConverter.service';

export const AppImageCropper = ({
  imageSrc,
  aspectRatio,
  className,
  onCropped,
}: {
  imageSrc: string;
  aspectRatio: number;
  className?: string;
  onCropped: (
    convertedImage: string,
    attributes: {
      width: number;
      height: number;
    },
  ) => void;
}) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>(undefined);
  const [convertedImage, setConvertedImage] = useState<string>(undefined);
  const onCropComplete = async (croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
    const convertedImage = await appImageConverter.cropImage(
      imageSrc,
      croppedAreaPixels,
    );
    setConvertedImage(convertedImage);
    onCropped(convertedImage, {
      width: croppedAreaPixels.width,
      height: croppedAreaPixels.height,
    });
  };

  return (
    <div className={clsx('grid gap-4 overflow-hidden relative', className)}>
      <div
        className="block relative overflow-hidden min-w-[200px] min-h-[250px]"
        // style={{
        //   width: '100px',
        //   height: '50px',
        // }}
      >
        <Cropper
          image={imageSrc}
          crop={crop}
          zoom={zoom}
          aspect={aspectRatio}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>

      <AppFormLabel label="Zoom" className="w-full app-px-content">
        <Slider
          className="w-full"
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e, zoom) => setZoom(Number(zoom))}
          classes={{ root: 'slider' }}
        />
      </AppFormLabel>
    </div>
  );
};
