import React from 'react';
import { Switch } from 'react-router-dom';
import { AppRoute } from 'src/business/_core/modules/router/AppRoute/AppRoute';
import useRouter from 'use-react-router';
import { TopBarRoot } from '../../../_core/modules/layout';
import { DiverAppLandingPage } from './DiverAppLandingPage';

export const AuthRootSpace = () => {
  const { match } = useRouter();

  return (
    <>
      <TopBarRoot />
      <main className="h-full w-full">
        <Switch>
          <AppRoute path={'/'} exact component={DiverAppLandingPage} />
          <AppRoute path={`${match.url}/*`} redirectToUrl="/" />
        </Switch>
      </main>
    </>
  );
};
