import {
  ClubStaffMember,
  ClubStaffMemberRole,
} from '@mabadive/app-common-model';
import { nameFormatter } from '../string/nameFormatter.service';

export type StaffMemberStringFormat = 'name' | 'name-diving-level';

export const staffMemberFormatter = {
  formatStaffMember,
  formatStaffLevelRole,
  formatStaffLevel,
  formatStaffRoleShort,
};

function formatStaffMember(
  staffMember: Pick<ClubStaffMember, 'scubaDivingInstructor' | 'profile'>,
  { format }: { format: StaffMemberStringFormat },
): string {
  if (
    format === 'name' ||
    !(
      staffMember.scubaDivingInstructor &&
      staffMember.scubaDivingInstructor?.degree?.level
    )
  ) {
    return nameFormatter.formatFullName(staffMember.profile);
  }
  return `${nameFormatter.formatFullName(staffMember.profile)} (${
    staffMember.scubaDivingInstructor?.degree?.level
  })`;
}

function formatStaffLevelRole({
  staffMember,
  role,
}: {
  staffMember: Pick<ClubStaffMember, 'profile' | 'scubaDivingInstructor'>;
  role: ClubStaffMemberRole;
}) {
  if (role === 'scuba-diving-instructor') {
    const level = staffMember.scubaDivingInstructor?.degree?.level;
    if (level) {
      if (staffMember.scubaDivingInstructor?.degree?.isTrainee) {
        return `S-${level}`;
      }
      return level;
    }
    if (staffMember.scubaDivingInstructor?.degree?.isTrainee) {
      return 'stag';
    }
    if (staffMember.scubaDivingInstructor?.degree?.name === 'INSTRUCTEUR') {
      return 'inst';
    }
    if (staffMember.profile.roles.includes('scuba-diving-instructor')) {
      return 'M';
    }
    if (staffMember.profile.roles.includes('surface-security')) {
      return 'secu';
    }
  } else if (role === 'surface-security') {
    return 'secu';
  }
  return '';
}
function formatStaffLevel({
  staffMember,
}: {
  staffMember: Pick<ClubStaffMember, 'profile' | 'scubaDivingInstructor'>;
}) {
  const level = staffMember.scubaDivingInstructor?.degree?.level;
  if (level) {
    if (staffMember.scubaDivingInstructor?.degree?.isTrainee) {
      return `S-${level}`;
    }
    return level;
  }
  if (staffMember.scubaDivingInstructor?.degree?.isTrainee) {
    return 'stag';
  }
  if (staffMember.scubaDivingInstructor?.degree?.name === 'INSTRUCTEUR') {
    return 'inst';
  }
  if (staffMember.profile.roles.includes('scuba-diving-instructor')) {
    return 'M';
  }
  if (staffMember.profile.roles.includes('surface-security')) {
    return 'secu';
  }
  return '';
}

function formatStaffRoleShort(staffRoleRef: ClubStaffMemberRole): string {
  switch (staffRoleRef) {
    case 'surface-security':
      return 'SS';
    case 'diving-director':
      return 'DP';
    case 'scuba-diving-instructor':
      return 'GP';
    case 'free-diving-instructor':
      return 'GP apnée';
  }
  return '';
}
