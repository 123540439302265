import { ClubDiver } from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';

export function ParticipantDiverAgeLabel({
  diver,
  refDate,
  filter = 'none',
  onClick,
  className,
}: {
  diver?: Pick<ClubDiver, 'birthdate' | 'age'>;
  refDate: Date;
  filter?: 'none' | 'young-only';
  onClick?: () => void;
  className?: string;
}) {
  const age = useMemo(
    () =>
      diver && diver.birthdate
        ? dateService.getAge(diver.birthdate, refDate)
        : diver.age,
    [refDate, diver],
  );

  const displayAge = useMemo(() => {
    return (
      age !== undefined && age > 0 && !(filter === 'young-only' && age >= 18)
    );
  }, [age, filter]);

  return displayAge ? (
    <div
      className={`font-normal text-xs 
        ${age < 18 ? 'text-red-400' : 'text-gray-500'}
      ${className ?? ''}`}
      onClick={onClick}
    >
      {`${age} ans`}
    </div>
  ) : null;
}
