/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppEntityUpdatePatch,
  Booking,
  ClubDiver,
  ClubDiverConsentAcceptance,
  DiverBookingUpdatePayload,
} from '@mabadive/app-common-model';
import {
  changeDescriptorManager,
  jsonPatcher,
} from '@mabadive/app-common-services';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { diverBookingUpdatorClient } from 'src/business/_core/data/app-operation';
import { useDefaultConsentAcceptance } from '../../components';
import {
  ClubCustomerConsent_Config,
  useCustomerConsentConfig,
} from '../../components/useCustomerConsentConfig';
import {
  DiveCenterBookingPageAggregatedBookingResume,
  DiveCenterBookingPageUpdateState,
} from '../DiveCenterBookingPage/model';
import { BookingCheckoutEditMemberSubmitPanelFormModel } from './panels';

export type DiveCenterBookingCheckoutConfirmWithConsents = ReturnType<
  typeof useDiveCenterBookingCheckoutConfirmWithConsents
>;

export function useDiveCenterBookingCheckoutConfirmWithConsents({
  updateState: initialUpdateState,
  setUpdateState,
  aggregatedBookingResume,
}: {
  updateState: DiveCenterBookingPageUpdateState;
  setUpdateState: Dispatch<SetStateAction<DiveCenterBookingPageUpdateState>>;
  aggregatedBookingResume: DiveCenterBookingPageAggregatedBookingResume;
}) {
  const clubResume = aggregatedBookingResume?.clubResume;
  const clubSettings = clubResume?.clubSettings;

  const consentsSettings =
    clubSettings?.publicSettings?.customerSpace?.consents;

  const consentConfig_GDPR: ClubCustomerConsent_Config =
    useCustomerConsentConfig({
      type: 'gdpr',
      clubSettings,
    });

  const defaultConsent_GDPR: ClubDiverConsentAcceptance =
    useDefaultConsentAcceptance({
      consentConfig: consentConfig_GDPR,
    });

  const consentConfig_ValidInfo: ClubCustomerConsent_Config =
    useCustomerConsentConfig({
      type: 'valid-info',
      clubSettings,
    });

  const defaultConsent_ValidInfo: ClubDiverConsentAcceptance =
    useDefaultConsentAcceptance({
      consentConfig: consentConfig_ValidInfo,
    });

  const saveDraftChanges = useCallback(
    async ({
      formValue,
    }: {
      formValue: BookingCheckoutEditMemberSubmitPanelFormModel;
    }) => {
      const consents: ClubDiverConsentAcceptance[] = [];
      const now = new Date();
      if (formValue.consents.gdpr.accepted) {
        consents.push({
          ...defaultConsent_GDPR,
          accepted: true,
          date: now,
        });
      }
      if (formValue.consents.validInfo.accepted) {
        consents.push({
          ...defaultConsent_ValidInfo,
          accepted: true,
          date: now,
        });
      }

      const updateState = buildChanges({
        consents,
        updateState: initialUpdateState,
        aggregatedBookingResume,
      });

      const payload: DiverBookingUpdatePayload = {
        isDraft: false,
        updateState,
        consents,
      };
      await diverBookingUpdatorClient.update(payload);
    },
    [
      aggregatedBookingResume,
      defaultConsent_GDPR,
      defaultConsent_ValidInfo,
      initialUpdateState,
    ],
  );

  return saveDraftChanges;
}

function buildChanges({
  consents,
  updateState: initialUpdateState,
  aggregatedBookingResume,
}: {
  consents: ClubDiverConsentAcceptance[];
  updateState: DiveCenterBookingPageUpdateState;
  aggregatedBookingResume: DiveCenterBookingPageAggregatedBookingResume;
}): DiveCenterBookingPageUpdateState {
  if (aggregatedBookingResume) {
    const diverPatches: AppEntityUpdatePatch[] = [];
    {
      // patch contact diver with consents
      const initialDiver =
        aggregatedBookingResume.bookingMemberContactFull.diver;
      const finalDiver: ClubDiver = {
        ...initialDiver,
      };
      if (!finalDiver.details) {
        finalDiver.details = {};
      } else {
        finalDiver.details = { ...finalDiver.details };
      }
      if (!finalDiver.details.consents) {
        finalDiver.details.consents = [];
      }
      finalDiver.details.consents =
        finalDiver.details.consents.concat(consents);
      const patchOperations = jsonPatcher.compareObjects(
        initialDiver,
        finalDiver,
        {
          // else, value won't be deleted by typeorm
          // https://github.com/typeorm/typeorm/issues/2934
          replaceDeleteByNullValue: false,
        },
      );

      if (patchOperations.length) {
        const patch: AppEntityUpdatePatch = {
          pk: initialDiver._id,
          patchOperations,
        };
        diverPatches.push(patch);
      }
    }

    const bookingPatches: AppEntityUpdatePatch[] = [];
    {
      // patch contact diver with consents
      const initialBooking = aggregatedBookingResume.booking;
      const finalBooking: Booking = {
        ...initialBooking,
      };
      if (!finalBooking.details) {
        finalBooking.details = {};
      } else {
        finalBooking.details = { ...finalBooking.details };
      }
      if (!finalBooking.details.consents) {
        finalBooking.details.consents = [];
      }
      finalBooking.details.consents =
        finalBooking.details.consents.concat(consents);
      const patchOperations = jsonPatcher.compareObjects(
        initialBooking,
        finalBooking,
        {
          // else, value won't be deleted by typeorm
          // https://github.com/typeorm/typeorm/issues/2934
          replaceDeleteByNullValue: false,
        },
      );
      if (patchOperations.length) {
        const patch: AppEntityUpdatePatch = {
          pk: initialBooking._id,
          patchOperations,
        };
        diverPatches.push(patch);
      }
    }

    if (diverPatches.length || bookingPatches.length) {
      const updateStateLocal: DiveCenterBookingPageUpdateState = {
        ...initialUpdateState,
      };

      updateStateLocal.diversChanges = changeDescriptorManager.updateMany(
        diverPatches,
        {
          changeDescriptors: updateStateLocal.diversChanges,
        },
      );
      updateStateLocal.bookingsChanges = changeDescriptorManager.updateMany(
        bookingPatches,
        {
          changeDescriptors: updateStateLocal.bookingsChanges,
        },
      );
      return updateStateLocal;
    }
  }
  return initialUpdateState;
}
