"use strict";
exports.__esModule = true;
exports.EXPORT_DAILY_GROUP_PARAM_COLUMN_HIDDEN_BY_DEFAULT_FIRST_DIVE = exports.EXPORT_DAILY_GROUP_PARAM_COLUMN = void 0;
exports.EXPORT_DAILY_GROUP_PARAM_COLUMN = [
    'timeStart',
    'timeEnd',
    'timeDuration',
    'depth',
    'dtr',
    'decoOptional',
    'decoStage1',
    'decoStage2',
    'decoStage3',
    'surfaceIntervalDuration',
];
exports.EXPORT_DAILY_GROUP_PARAM_COLUMN_HIDDEN_BY_DEFAULT_FIRST_DIVE = [
    'decoOptional',
    'dtr',
    'decoStage1',
    'decoStage2',
    'decoStage3',
    'surfaceIntervalDuration',
];
