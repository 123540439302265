"use strict";
exports.__esModule = true;
exports.TEMPLATE_EXPORT_SHEET_DIVES = exports.TEMPLATE_EXPORT_SHEET_DIVES_ITEM_SESSIONS = void 0;
var equipment_1 = require("../../../../equipment");
var excel_export_1 = require("../../../../excel-export");
var sheetDiverColumnsIds = [
    'bookingSessionParticipantsPurchase',
    'level',
    'aptitude',
    // 'totalDive',
    // 'lastDive',
    // 'medicalFormFilled', // Formulaire médical (bookingMember.details.medicalForm.filled)
    // 'gaz',
    'wetsuit',
    'jacket',
    'tank',
    'name',
    // 'gazPressure',
    // 'tankNumber',
    // 'jacket',
    // 'wetsuit',
    // 'fins',
    // 'scubaRegulator',
    // 'mask',
    // 'signature',
];
['level', 'sessionParticipantDivesCount', 'aptitude', 'gaz', 'name'];
var paramColumnsIds = [
    'timeStart',
    'timeEnd',
    'timeDuration',
    'depth',
    'dtr',
    'decoOptional',
    'decoStage1',
    'decoStage2',
    'decoStage3',
    'surfaceIntervalDuration',
];
exports.TEMPLATE_EXPORT_SHEET_DIVES_ITEM_SESSIONS = {
    id: 'sessions',
    // columns: 2,
    items: [
        {
            id: 'title',
            enabled: true,
            number: true,
            name: true,
            time: true
        },
        {
            id: 'participants-count',
            enabled: true,
            divers: true,
            staff: true,
            total: true
        },
        {
            id: 'comments',
            enabled: true,
            // label: 'Notes',
            importantNotes: true,
            comment: true,
            tide: true,
            weather: true
        },
        {
            id: 'equipement',
            enabled: false,
            types: equipment_1.EQUIPMENT_TYPES,
            columns: excel_export_1.EXPORT_DAILY_CONFIG_CONTENT_SESSIONS_EQUIPMENT_COLUMNS
        },
        {
            id: 'dives',
            enabled: true,
            items: [
                {
                    id: 'title',
                    number: true,
                    time: true
                },
                {
                    id: 'title',
                    diveSite: true,
                    boat: true
                },
                {
                    id: 'title',
                    divingDirector: true,
                    surfaceSecurity: true
                },
                {
                    id: 'dive-groups',
                    diverColumns: sheetDiverColumnsIds.map(function (x) { return ({ id: x }); }),
                    paramColumns: paramColumnsIds.map(function (x) { return ({
                        id: x,
                        firstDive: !excel_export_1.EXPORT_DAILY_GROUP_PARAM_COLUMN_HIDDEN_BY_DEFAULT_FIRST_DIVE.includes(x)
                    }); }),
                    paramsPosition: 'down',
                    addEmptyRows: {
                        maxEmptyRows: 1,
                        maxTotalRows: 4,
                        maxTotalRowsForFirstDive: 1
                    },
                    addEmptyGroups: {
                        maxEmptyGroups: 1,
                        emptyRowsPerGroup: 4,
                        maxTotalGroups: undefined
                    }
                },
            ]
        },
    ]
};
// TODO adapter dynamiquement en fonction de la config
var configSheetWidthCellsCount = 36;
exports.TEMPLATE_EXPORT_SHEET_DIVES = {
    tab: {
        name: 'Fiche sécu',
        color: '#248af0',
        comment: 'Détails des plongées et des palanquées'
    },
    content: {
        title: 'Fiche de sécurité',
        items: [
            {
                id: 'events'
            },
            exports.TEMPLATE_EXPORT_SHEET_DIVES_ITEM_SESSIONS,
        ]
    },
    settings: {
        defaultHeight: 20,
        widthCellsCount: configSheetWidthCellsCount,
        orientation: 'portrait'
    }
};
