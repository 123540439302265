"use strict";
exports.__esModule = true;
exports.DIVE_TRAININGS = void 0;
var offer_1 = require("../../../offer");
var DIVE_CERTIFICATIONS_REFERENCE_MAP_const_1 = require("../dive-certification/DIVE_CERTIFICATIONS_REFERENCE_MAP.const");
var dive_organization_1 = require("../dive-organization");
var index = 0;
exports.DIVE_TRAININGS = dive_organization_1._DIVE_ORGANIZATIONS_DATA
    .reduce(function (acc, data) {
    return acc.concat(data.trainings.map(function (training) {
        var organization = dive_organization_1.DIVE_ORGANIZATIONS.find(function (org) { return org.reference === data.orgGroupReference; });
        var organizationv2 = offer_1.DIVE_SERVICE_ORGANIZATIONS.find(function (org) { return org.orgGroupReferencev2 === data.orgGroupReferencev2; });
        var result = {
            sortReference: buildSortReference(organization, training),
            organization: organization,
            organizationv2: organizationv2,
            reference: training.reference,
            commercialCategoryId: training.commercialCategoryId,
            specialityType: training.specialityType,
            label: training.label,
            targetCertification: !training.certificationReference
                ? undefined
                : DIVE_CERTIFICATIONS_REFERENCE_MAP_const_1.DIVE_CERTIFICATIONS_REFERENCE_MAP[training.certificationReference],
            maxDepth: training.maxDepth,
            nitrox: training.nitrox,
            diveMode: training.diveMode,
            trainingDivesTotalCount: training.trainingDivesTotalCount,
            compatibilityGroups: training.compatibilityGroups,
            trainingType: training.trainingType,
            defaultAptitude: training.defaultAptitude
        };
        return result;
    }));
}, [])
    .filter(function (x) { return !!x; });
function buildSortReference(org, training) {
    var suffix = org.reference === 'cmas'
        ? '1-cmas'
        : org.reference === 'padi'
            ? '2-padi'
            : org.reference === 'special'
                ? '3-special'
                : '4-unknown';
    return "".concat(training.maxDepth < 10 ? '0' : '').concat(training.maxDepth, "m-is-nitrox-").concat(training.nitrox ? 1 : 0).concat(suffix, "-").concat(index++);
}
