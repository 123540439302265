import { BookingProduct } from '@mabadive/app-common-model';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { CUS_BookingParticipantFull } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/models';
import { DiveCenterBookingPageDataCore } from '../../model';

export const diveCenterBookingPageAggregatedBookingResumeBuilderParticipantFull =
  {
    buildParticipantFull,
  };

function buildParticipantFull({
  dataCore,
  bookingProduct,
}: {
  dataCore: DiveCenterBookingPageDataCore;
  bookingProduct: BookingProduct;
}): CUS_BookingParticipantFull {
  const booking = dataCore.bookings.find(
    (x) => x._id === bookingProduct.bookingId,
  );
  if (!booking) {
    appLogger.error(
      `[buildParticipantFull] booking ${bookingProduct.bookingId} not fount`,
      dataCore.bookings,
    );
  }
  const bookingMember = dataCore.bookingMembers.find(
    (x) => x._id === bookingProduct.bookingMemberId,
  );
  if (!bookingMember) {
    appLogger.error(
      `[buildParticipantFull] bookingMember ${bookingProduct?.bookingMemberId} not fount`,
      dataCore.bookingMembers,
    );
  }
  const diver = dataCore.clubDivers.find(
    (x) => x._id === bookingMember?.diverId,
  );
  if (!diver) {
    appLogger.error(
      `[buildParticipantFull] diver ${bookingMember?.diverId} not fount`,
      dataCore.clubDivers,
    );
  }
  const bookingSession = dataCore.bookingSessions.find(
    (x) => x._id === bookingProduct.bookingSessionId,
  );
  if (!bookingSession) {
    appLogger.error(
      `[buildParticipantFull] bookingSession ${bookingProduct?.bookingSessionId} not fount`,
      dataCore.bookingSessions,
    );
  }
  const diveSession = dataCore.diveSessions.find(
    (x) => x.reference === bookingSession?.diveSessionReference,
  );
  if (!diveSession) {
    appLogger.error(
      `[buildParticipantFull] diveSession ${bookingSession?.diveSessionReference} not fount`,
      dataCore.diveSessions,
    );
  }
  const bookingSessionParticipant = dataCore.bookingSessionParticipants.find(
    (x) => x._id === bookingProduct.bookingSessionParticipantId,
  );
  if (!bookingSessionParticipant) {
    appLogger.error(
      `[buildParticipantFull] bookingSessionParticipant ${bookingProduct?.bookingSessionParticipantId} not fount`,
      dataCore.bookingSessionParticipants,
    );
  }
  const diveSessionParticipant = dataCore.clubParticipants.find(
    (x) => x._id === bookingSessionParticipant?.participantId,
  );
  if (!diveSessionParticipant) {
    appLogger.error(
      `[buildParticipantFull] diveSessionParticipant ${bookingSessionParticipant?.participantId} not fount`,
      dataCore.clubParticipants,
    );
  }

  const full: CUS_BookingParticipantFull = {
    diveSession,
    booking,
    bookingSession,
    bookingProductDive: bookingProduct,
    diver,
    diveSessionParticipant,
    bookingSessionParticipant,
    bookingMember,
    entityState: 'original', // TODO prendre en compte si besoin l'état 'updated'
  };
  return full;
}
