// import { AppIconsDiveEquipment } from './AppIconsDiveEquipment.const';
// import { AppIconsSocialMedia } from './AppIconsSocialMedia.const';
import { AppIconsEcommerce } from './AppIconsEcommerce.const';
import { AppIconsSocialMedia } from './AppIconsSocialMedia.const';
import { AppIconsUI } from './AppIconsUI.const';

export const AppIcons = {
  socialMedia: AppIconsSocialMedia,
  // diveEquipment: AppIconsDiveEquipment,
  ui: AppIconsUI,
  ecommerce: AppIconsEcommerce,
};
