"use strict";
// Timezone - liste restreinte, il faut passer à de l'autocomplete pour avoir toute la liste
// https://en.wikipedia.org/wiki/ISO_8601
// https://en.wikipedia.org/wiki/Time_zone
// https://raw.githubusercontent.com/moment/moment-timezone/develop/data/unpacked/latest.json
// https://abbreviations.yourdictionary.com/articles/common-world-time-zone-abbreviations.html
// NOTE: le fichier original contient aussi un mapping CODE_PAYS => timezones disponibles
exports.__esModule = true;
exports.APP_TIME_ZONES = void 0;
exports.APP_TIME_ZONES = [
    {
        name: 'Western European Time',
        zones: [
            {
                abbreviation: 'WET',
                offset: 0
            },
        ],
        countries: []
    },
    {
        name: 'Central European Time',
        zones: [
            {
                abbreviation: 'CET',
                offset: 1
            },
        ],
        countries: []
    },
    {
        name: 'Central Standard Time',
        zones: [
            {
                abbreviation: 'CST',
                offset: -6
            },
        ],
        countries: []
    },
    {
        name: 'East Africa Time',
        zones: [
            {
                abbreviation: 'EAT',
                offset: -6
            },
        ],
        countries: []
    },
    {
        name: 'Eastern European Time',
        zones: [
            {
                abbreviation: 'EET',
                offset: +2
            },
        ],
        countries: []
    },
    {
        name: 'Eastern Standard Time',
        zones: [
            {
                abbreviation: 'EST',
                offset: -5
            },
        ],
        countries: []
    },
    {
        name: 'Australia Eastern Standard Time',
        zones: [
            {
                abbreviation: 'AEST',
                offset: 10
            },
        ],
        countries: []
    },
    {
        name: 'Pacific Standard Time',
        zones: [
            {
                abbreviation: 'PST',
                offset: -8
            },
        ],
        countries: []
    },
    {
        name: 'Pacific Standard Time',
        zones: [
            {
                abbreviation: 'PST',
                offset: -8
            },
        ],
        countries: []
    },
    {
        name: ' West Africa Time',
        zones: [
            {
                abbreviation: 'WAT',
                offset: 1
            },
        ],
        countries: []
    },
    {
        name: 'Atlantic Standard Time',
        zones: [
            {
                abbreviation: 'AST',
                offset: -4
            },
        ],
        countries: []
    },
    {
        name: 'Australia Western Standard Time',
        zones: [
            {
                abbreviation: 'AWST',
                offset: 9
            },
        ],
        countries: []
    },
    {
        name: 'Central Africa Time',
        zones: [
            {
                abbreviation: 'CAT',
                offset: 2
            },
        ],
        countries: []
    },
    {
        name: 'Europe/Paris',
        countries: ['FR', 'MC'],
        zones: [
            {
                abbreviation: 'CET',
                offset: 'UTC+1'
            },
            {
                abbreviation: 'CEST',
                offset: 'UTC+2'
            },
        ]
    },
    {
        name: 'Europe/Zurich',
        countries: ['CH', 'DE', 'LI']
    },
    {
        name: 'Europe/Brussels',
        countries: ['BE', 'LU', 'NL']
    },
    {
        name: 'Europe/Madrid',
        countries: ['ES']
    },
    {
        name: 'Europe/Rome',
        countries: ['IT', 'SM', 'VA']
    },
    {
        name: 'America/Guadeloupe',
        countries: ['GP']
    },
    {
        name: 'America/Martinique',
        countries: ['MQ']
    },
    {
        name: 'Indian/Reunion',
        countries: ['RE']
    },
    {
        name: 'Indian/Mayotte',
        countries: ['YT']
    },
    {
        name: 'Pacific/Tahiti',
        countries: ['PF']
    },
    {
        name: 'Pacific/Noumea',
        countries: ['NC']
    },
    {
        name: 'Atlantic/Canary',
        countries: ['ES']
    },
    {
        name: 'America/Nassau',
        countries: ['BS']
    },
    {
        name: 'Asia/Manila',
        countries: ['PH']
    },
    {
        name: 'Indian/Mauritius',
        countries: ['MU']
    },
    {
        name: 'Etc/Greenwich',
        countries: []
    },
    {
        name: 'Africa/Dakar',
        countries: ['SN']
    },
    {
        name: 'Africa/Abidjan',
        countries: [
            'CI',
            'BF',
            'GH',
            'GM',
            'GN',
            'IS',
            'ML',
            'MR',
            'SH',
            'SL',
            'SN',
            'TG',
        ]
    },
];
