import clsx from 'clsx';
import React from 'react';
import { AppHackableCalendarVM } from './model';
import { AppHackableCalendarProps } from './props';
import { useAppHackableCalendarWeeksPanelVM } from './useAppHackableCalendarVM.hook';
import { AppHackableCalendarWeeksPanel } from './weeks-panel';
import { AppHackableCalendarYearsPanel } from './years-panel';

export const AppHackableCalendar = (props: AppHackableCalendarProps) => {
  const vm: AppHackableCalendarVM = useAppHackableCalendarWeeksPanelVM({
    props,
  });
  return (
    <div
      className={clsx(
        'text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 xl:col-start-9',
        props.className,
      )}
    >
      {vm.view === 'weeks' && <AppHackableCalendarWeeksPanel {...vm} />}
      {vm.view === 'years' && <AppHackableCalendarYearsPanel {...vm} />}
    </div>
  );
};
