import { stackLogLevelTester } from '../logger/stack-log-level-tester.service';
import { stackLogAppRuleBuilder } from './stack-log-app-rule-builder.service';
import { StackLogConfigCustomRule } from './stack-log-config-custom-rule.model';
import { StackLogInnerConfigCustom } from './stack-log-inner-config-custom.model';
import { StackLogInnerSeverityConfig } from './stack-log-inner-severity-config.model';
import { StackLogSeverityConfig } from './stack-log-severity-config.model';

class StackLogConfigurer {

  public buildInnerSeverityConfig(config: StackLogSeverityConfig): StackLogInnerSeverityConfig {

    const rules = stackLogAppRuleBuilder.buildRules(config);

    const customConfig: StackLogInnerConfigCustom = this.buildSeverityConfig(rules);

    const defaultConfig = this.getDefaultInnerSeverity();

    const innerSeverityConfig: StackLogInnerSeverityConfig = {
      defaultLogLevel: config.defaultLogLevel,
      custom: customConfig,
      maxStackSize: config.maxStackSize ? config.maxStackSize : defaultConfig.maxStackSize,
    };

    return innerSeverityConfig;
  }

  private buildSeverityConfig(rules: StackLogConfigCustomRule[]): StackLogInnerConfigCustom {
    return rules.filter(x => x != null)
      .reduce((acc: StackLogInnerConfigCustom, rule: StackLogConfigCustomRule) => {
        rule.matchers.forEach(matcher => {
          const key = stackLogLevelTester.buildLogLevelContextKey(matcher);
          acc.levels[key] = rule.logLevel;
        });
        if (!acc.hasAppLevelConfig) {
          acc.hasAppLevelConfig = rule.matchers.find(matcher => matcher.app != null) !== undefined;
        }
        if (!acc.hasModuleLevelConfig) {
          acc.hasModuleLevelConfig = rule.matchers.find(matcher => matcher.module != null) !== undefined;
        }
        if (!acc.hasMethodLevelConfig) {
          acc.hasMethodLevelConfig = rule.matchers.find(matcher => matcher.method != null) !== undefined;
        }
        return acc;
      }, this.getDefaultCustomLogLevels());
  }

  public getDefaultCustomLogLevels() {
    return {
      hasAppLevelConfig: false,
      hasModuleLevelConfig: false,
      hasMethodLevelConfig: false,
      levels: {},
    };
  }
  public getDefaultInnerSeverity(): StackLogInnerSeverityConfig {
    return {
      defaultLogLevel: 'disabled',
      custom: this.getDefaultCustomLogLevels(),
      maxStackSize: 50,
    };
  }

}

export const stackLogConfigurer = new StackLogConfigurer();