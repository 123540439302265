/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubServicesGlobalSettingsOrgGroup,
  DiveCertificationReference,
  DIVE_CERTIFICATIONS,
  GAZ_DIVE_CERTIFICATIONS_REFERENCES,
} from '@mabadive/app-common-model';
import {
  certificationFormatter,
  dataSorter,
} from '@mabadive/app-common-services';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

export const mainCertificationOptionBuilder = { buildOptions };

const MAIN_CERTIFICATION_OPTIONS_REFS_TO_IGNORE = (
  GAZ_DIVE_CERTIFICATIONS_REFERENCES as string[]
).concat(['BPT', 'BPT++', 'BPT-XL']);

const MAIN_CERTIFICATIONS = DIVE_CERTIFICATIONS.filter(
  (x) => MAIN_CERTIFICATION_OPTIONS_REFS_TO_IGNORE.indexOf(x.reference) === -1,
);

function buildOptions(
  {
    orgGroupReferencev2,
  }: {
    orgGroupReferencev2?: ClubServicesGlobalSettingsOrgGroup;
  } = {
    orgGroupReferencev2: undefined,
  },
) {
  const items = orgGroupReferencev2
    ? MAIN_CERTIFICATIONS.filter(
        (x) => x.organizationv2?.orgGroupReferencev2 === orgGroupReferencev2,
      )
    : MAIN_CERTIFICATIONS;
  const options = dataSorter
    .sortMultiple(items, {
      getSortAttributes: (cert) => {
        const org = cert.organizationv2;
        return [
          {
            // sort by organisation
            value: org?.sortReference ?? 0,
          },
          {
            // then by sortReference
            value: cert.sortReference,
          },
        ];
      },
    })
    .map((cert) => {
      const option: ValueLabel<DiveCertificationReference> = {
        // label: cert.sortReference,
        label: certificationFormatter.formatCertification(cert, {
          format: 'ref-name',
        }),
        value: cert.reference,
      };
      return option;
    });

  return options;
}
