import { StackLogWebAppenderOptions } from '..';
import { StackLogInnerConfig } from '../../main/models/stack-log-inner-config.model';
import { StackLogWebProcessHistory } from '../models/stack-log-web-process-history.model';
import { StackLogWebLoggerOptions } from './stack-log-web-logger-options';

class StackLogWebHistoryBuilder {

  public buildLogHistory(params: {
    processLogsHistory: StackLogWebProcessHistory,
    globalLogsHistory: StackLogWebAppenderOptions[],
    innerConfig: StackLogInnerConfig,
    loggerOptions: StackLogWebLoggerOptions,
  }) {

    const processLogsHistory: StackLogWebProcessHistory = params.processLogsHistory;
    const globalLogsHistory: StackLogWebAppenderOptions[] = params.globalLogsHistory;
    const innerConfig: StackLogInnerConfig = params.innerConfig;
    const loggerOptions: StackLogWebLoggerOptions = params.loggerOptions;

    const maxLogs = innerConfig.global.maxStackSize;
    
    const processLogs = this.extractProcessLogs(processLogsHistory, maxLogs);

    const globalLogs = this.extractGlobalLogs(loggerOptions, globalLogsHistory, maxLogs, processLogs);

    return processLogs.concat(globalLogs).sort(this.sortByTimestamp());
  }

  private extractGlobalLogs(loggerOptions: StackLogWebLoggerOptions, globalLogsHistory: StackLogWebAppenderOptions[], maxLogs: number, processLogs: StackLogWebAppenderOptions[]) {
    let allowedTypes: any;
    if (loggerOptions.context.type && loggerOptions.context.type !== 'lib') {
      allowedTypes = ['lib', loggerOptions.context.type];
    }
    const globalLogs = globalLogsHistory.reverse().slice(0, maxLogs)
      .filter(globalLog => processLogs.find(processLog => processLog.id === globalLog.id) === undefined)
      .filter(globalLog => allowedTypes ? allowedTypes.indexOf(globalLog.context.type) !== -1 : true)
      .slice(0, maxLogs - processLogs.length);
    return globalLogs;
  }

  private extractProcessLogs(processLogsHistory: StackLogWebProcessHistory, maxLogs: number) {
    return processLogsHistory.logs.reverse().slice(0, maxLogs);
  }

  private sortByTimestamp(): (a: StackLogWebAppenderOptions, b: StackLogWebAppenderOptions) => number {
    return (a, b) => {
      const pA = a.timestamp.getTime();
      const pB = b.timestamp.getTime();
      if (pA !== pB) {
        // reverse sort
        return pA > pB ? -1 : 1;
      }
      return 0;
    };
  }
}

export const stackLogWebHistoryBuilder = new StackLogWebHistoryBuilder();