"use strict";
exports.__esModule = true;
exports.DIVE_MODES_PURCHASABLE = void 0;
exports.DIVE_MODES_PURCHASABLE = [
    'autonomous',
    'supervised',
    'training',
    'first-dive',
    'observer',
    'free-dive',
    'free-dive-auto',
    'free-dive-training',
    'snorkeling',
    'snorkelingSupervised',
    'watchingTour',
    'autoSupervised',
    'instructor',
];
