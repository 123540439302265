"use strict";
exports.__esModule = true;
exports.EQUIPMENT_TYPES_DEFAULT_CUSTOMER_SPACE_FULL_EQUIPMENT = exports.EQUIPMENT_TYPES_DEFAULT_CUSTOMER_SPACE = exports.EQUIPMENT_TYPES = void 0;
exports.EQUIPMENT_TYPES = [
    'wetsuit',
    'jacket',
    'fins',
    'scubaRegulator',
    'divingBoots',
    'mask',
    'flashLight',
    'computer',
    'tank',
    'compass',
    'rebreather',
    'underwaterScooter',
];
// les équipements à demander par défaut sur l'espace client
exports.EQUIPMENT_TYPES_DEFAULT_CUSTOMER_SPACE = [
    'wetsuit',
    'jacket',
    'fins',
    'scubaRegulator',
    'mask',
    'flashLight',
    'computer',
];
exports.EQUIPMENT_TYPES_DEFAULT_CUSTOMER_SPACE_FULL_EQUIPMENT = ['wetsuit', 'jacket', 'fins', 'scubaRegulator', 'mask'];
