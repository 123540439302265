import {
  AppDocUploadContext,
  AppTranslationContentItemDoc,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useState } from 'react';
import { Control, FieldPath, UseFormSetValue, useWatch } from 'react-hook-form';
import { AppFormControlRHF, AppInputRHF } from 'src/lib/form';
import { AppButton } from '../../../../business/_core/modules/layout';
import {
  AppDropzoneFileTypeIcon,
  AppIconsAction,
} from '../../../../business/_core/modules/layout/icons';
import { AppDocResumeFileUploader } from './AppDocResumeFileUploader';

export function AppInputFileRHF<T>({
  control,
  name: nameInput,
  required,
  label,
  setValue,
  appDocUploadContext,
  // onChange,
  className,
  isRemovable = true,
}: {
  control: Control<T>;
  name: FieldPath<T>;
  required?: boolean;
  label: string;
  setValue: UseFormSetValue<T>;
  appDocUploadContext: AppDocUploadContext;
  // onChange?: (appDoc: PRO_AppDocResume) => any;
  className?: string;
  isRemovable?: boolean;
}) {
  const name = nameInput as any;
  const [contentFileAny] = useWatch({
    control,
    name: [name],
  });
  const contentFile = contentFileAny as AppTranslationContentItemDoc;

  const Icon = contentFile?.fileType
    ? AppDropzoneFileTypeIcon[contentFile?.fileType]
    : null;

  const [showFileSelector, setShowFileSelector] = useState(false);

  return !appDocUploadContext ? null : (
    <>
      <AppFormControlRHF
        className={clsx('min-w-32', className)}
        label={label}
        control={control}
        name={`${name}._id` as any}
        required={required}
        renderComponent={(props) => (
          <>
            <div className="hidden">
              <AppInputRHF {...props} />
            </div>
            <div
              className="cursor-pointer flex gap-4 items-end"
              onClick={() => setShowFileSelector(true)}
            >
              {Icon && <Icon className="text-gray-700 size-6" />}
              {isRemovable && contentFile?._id ? (
                <AppButton
                  icon={AppIconsAction.delete}
                  color={'danger-outline-light'}
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    setValue(name, null, {
                      shouldDirty: true,
                      shouldTouch: true,
                      shouldValidate: true,
                    });
                  }}
                />
              ) : (
                <AppButton
                  icon={AppDropzoneFileTypeIcon.richText}
                  color={'primary-outline-light'}
                  size="small"
                >
                  {contentFile?._id ? 'Remplacer' : 'Ajouter fichier'}
                </AppButton>
              )}
            </div>
          </>
        )}
      />

      {showFileSelector && (
        <AppDocResumeFileUploader
          uploadUrl="/customer/docs/upload"
          uploadContext={appDocUploadContext}
          onSuccess={(appDoc) => {
            setShowFileSelector(false);
            const contentFile: AppTranslationContentItemDoc = {
              _id: appDoc._id,
              fileType: appDoc.fileType,
              publicUrl: appDoc.publicUrl,
            };
            setValue(name, contentFile as any, {
              shouldDirty: true,
              shouldTouch: true,
              shouldValidate: true,
            });
          }}
          onCancel={() => {
            setShowFileSelector(false);
          }}
        />
      )}
    </>
  );
}
