import { AppBar, Box, IconButton, Tab, Tabs, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { distinctUntilChanged, map } from 'rxjs/operators';
import {
  AppAuth,
  authenticationChecker,
  authenticationClient,
  authenticationStore,
} from 'src/business/auth/services';
import { useLoadable } from 'src/business/_core/data/hooks';
import { appStylesHelper, useCacheableClasses } from '../../../root/theme';
import { AppIconsMaterial } from '../../icons';
import { useMediaSizeMaterialUI } from '../../services';
import { responsiveAttributesValues } from '../../services/responsiveAttributesValues.service';
import { AppTabBarTheme } from './AppTabBarTheme.type';
import { AppTabsBarTab } from './AppTabsBarTab.type';

export const AppTabsBar = <T extends string, C = any>({
  tabs,
  disableNavigation,
  context,
  onChange,
  value,
  theme,
  closeButton,
  children,
  className,
}: {
  tabs: AppTabsBarTab<T, C>[];
  disableNavigation?: boolean;
  context?: C;
  onChange?: (tab: AppTabsBarTab<T, C>) => void;
  value?: T;
  theme?: AppTabBarTheme;
  closeButton?: {
    enabled?: boolean;
    onClick?: () => void;
  };
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}) => {
  const classes = useCacheableClasses({
    cacheKey: 'AppTabsBarTab',
    buildStyles,
  });

  function selectTab(tabId: T) {
    const tab = tabs.find((t) => t.id === tabId);
    if (onChange && tab && !disableNavigation) {
      onChange(tab);
    }
  }

  const { _loaded, filteredTabs } = useLoadable({
    debugName: 'AppTabsBarTab',
    initialValueFromLoadSnapshot: true,
    load: () =>
      authenticationStore.auth.get().pipe(
        distinctUntilChanged(authenticationClient.appAuthEquals),
        map((auth) => ({
          filteredTabs: filterTabs<T, C>({ tabs, auth, context }),
        })),
      ),
  });

  const mediaSize = useMediaSizeMaterialUI();

  return !_loaded || filteredTabs.length <= 1 ? null : (
    <AppBar
      position="static"
      className={`${classes.root}
      bg-gradient-to-b ${
        theme === 'dive-center-booking'
          ? 'from-[#50b1d8] to-[#11678b]'
          : 'from-[#197297] to-[#49beec]'
      } over-drawer
       ${className ?? ''}`}
    >
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="no-wrap"
        whiteSpace="nowrap"
      >
        <Tabs value={value} onChange={(e, tabId) => selectTab(tabId)}>
          {filteredTabs.map((tab) => (
            <Tab
              disabled={disableNavigation}
              key={tab.id}
              value={tab.id}
              label={
                <span className="text-app-xxs md:text-xs lg:text-sm">
                  {tab.label}
                </span>
              } // différence par rapport à l'appli pro, qui n'affiche pas les labels sur mobile
              icon={tab.icon}
            />
          ))}
        </Tabs>
        {closeButton && closeButton.enabled !== false ? (
          <div className={classes.grow} />
        ) : null}
        {closeButton && closeButton.enabled !== false ? (
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={() =>
              closeButton && closeButton.onClick
                ? closeButton.onClick()
                : undefined
            }
            className="close-button"
          >
            <AppIconsMaterial.close />
          </IconButton>
        ) : null}
        {children && children}
      </Box>
    </AppBar>
  );
};

function filterTabs<T extends string, C>({
  tabs,
  auth,
  context,
}: {
  tabs: AppTabsBarTab<T, C>[];
  auth: AppAuth;
  context: C;
}): AppTabsBarTab<T, C>[] {
  return tabs.filter((tab) => {
    if (tab.auth && tab.auth.authenticationRequired) {
      if (!auth.isAuthenticated) {
        return false;
      }
    }
    if (tab.auth && tab.auth.requiredRoles) {
      if (
        !authenticationChecker.hasRequiredRoles(
          auth.user,
          tab.auth.requiredRoles,
        )
      ) {
        return false;
      }
    }
    if (tab.auth && tab.auth.requiredRolesOneOf) {
      if (
        !authenticationChecker.hasRequiredRolesOneOf(
          auth.user,
          tab.auth.requiredRolesOneOf,
        )
      ) {
        return false;
      }
    }
    if (tab.filter && !tab.filter(context)) {
      return false;
    }
    return true;
  });
}

function buildStyles(theme: Theme) {
  return makeStyles({
    grow: {
      flexGrow: 1,
    },
    root: {
      '&.MuiAppBar-root': {
        zIndex: 800,
      },
      '& .MuiTab-root': {
        ...responsiveAttributesValues(theme, {
          fontSize: appStylesHelper.getFontSizeResponsive('sm'),
          minWidth: { values: [60, 110, 130, 140, 160], suffix: 'px' },
        }),
      },
      '& .close-button': {
        width: '50px',
        height: '50px',
        color: 'rgba(0, 0, 0, 0.54)',
        backgroundColor: 'rgb(227, 227, 227)',
        '&:hover': {
          backgroundColor: '#ffffff',
        },
        marginTop: '10px',
        marginRight: '10px',
        [theme.breakpoints.only('xs')]: {
          width: '45px',
          height: '45px',
          marginTop: '2px',
          marginRight: '2px',
        },
      },
    },
  });
}
