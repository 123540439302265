import React, { useMemo } from 'react';
import { AppMessage, AppMessageLight } from 'src/business/_core/modules/layout';
import { DiveCenterBookingSessionsTable } from 'src/business/dive-center/components';
import { diveCenterBookingParticipantsBySessionModelBuilder } from 'src/business/dive-center/components/DiveCenterBookingSessionsTable/diveCenterBookingParticipantsBySessionModelBuilder.service';
import { DiveCenterBookingPageLocalState } from '../../useDiveCenterBookingPageLocalState.hook';

export function DiveCenterBookingPageTab4BookingSessionsPast({
  localState,
}: {
  localState: DiveCenterBookingPageLocalState;
}) {
  const { aggregatedBookingResume, currentTab, setCurrentTab } = localState;

  const participantsBySession = useMemo(
    () =>
      diveCenterBookingParticipantsBySessionModelBuilder.buildParticipantsBySession(
        { bookingResume: aggregatedBookingResume, period: 'past' },
      ),
    [aggregatedBookingResume],
  );

  const customerSpaceSettings =
    localState?.clubSettings?.publicSettings?.customerSpace;

  return (
    <div className="app-card app-p-content grid gap-4">
      <h3 className="text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
        Prestations passées
      </h3>
      {participantsBySession?.length ? (
        <DiveCenterBookingSessionsTable
          className="mt-2"
          bookingResume={aggregatedBookingResume}
          participantsBySessions={participantsBySession}
          config={customerSpaceSettings?.display?.divesPast?.config}
        />
      ) : (
        <AppMessage
          title="Aucune plongée passée"
          message={
            <div>
              <p>Vous n'avez aucune plongée passée.</p>
            </div>
          }
        />
      )}

      <AppMessageLight type="info">
        <b>NOTE</b>: il se peut que le centre n'ait pas encore mis à jour
        l'ensemble des plongées de votre réservation
      </AppMessageLight>
    </div>
  );
}
