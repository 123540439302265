import {
  ClubParticipant,
  ClubResumeStaffMember,
  ClubSettings,
  ClubStaffMember,
  ClubStaffMemberRole,
  DiveSession,
  DiveSessionResumeFull,
  DiveSessionResumeGroup,
  DiveSessionStaffConfigMember,
  DiveSessionStaffMemberTableModelStaffMemberSession,
  MultipleDiveSessionNumber,
  StaffMemberDailyAvailabilityResume,
  StaffMemberSessionAssigmentResume,
} from '@mabadive/app-common-model';
import { arrayBuilder, dateService } from '../../data';

export const staffMemberSessionAssignmentBuilder = {
  buildSessionAssignment,
  getSessionStaffMembersScubaInstructor,
};

function getSessionStaffMembersScubaInstructor({
  session,
  staffMembers,
}: {
  session: Pick<DiveSessionResumeFull, 'groups' | 'staffConfig'>;
  staffMembers: ClubResumeStaffMember[];
}): ClubResumeStaffMember[] {
  // TODO: le mieux est d'ajouter un compteur dans l'objet, et de décider plus tard ce qu'on affiche (surtout que c'est le même traitement pour l'export)

  const instructorsStaffMembers = staffMembers.filter((staffMember) => {
    if (
      session.groups.find(
        (x) =>
          x.diveTourGroupSession1?.instructor?.staffId === staffMember._id ||
          x.diveTourGroupSession2?.instructor?.staffId === staffMember._id,
      ) !== undefined
    ) {
      // l'instructeur est assigné à une palanquée
      return true;
    }
    if (
      session.staffConfig?.staffMembers?.find(
        (x) =>
          x.staffMemberId === staffMember._id &&
          x.assignedRoles.includes('scuba-diving-instructor'),
      ) !== undefined
    ) {
      // l'instructeur est assigné à la session avec un rôle de moniteur
      return true;
    }
  });
  return instructorsStaffMembers;
}

function buildSessionAssignment({
  staffMemberDailyAvailabilityResume,
  staffMember,
  diveSession,
  clubParticipants,
  groups,
  clubSettings,
}: {
  staffMemberDailyAvailabilityResume: StaffMemberDailyAvailabilityResume;
  staffMember: Pick<ClubStaffMember, '_id' | 'profile' | 'availabilities'>;
  diveSession: Pick<
    DiveSession,
    'staffConfig' | 'diveTourSession1' | 'diveTourSession2' | 'time'
  >;
  clubParticipants: Pick<ClubParticipant, 'diveSessionGroupId'>[];
  groups: DiveSessionResumeGroup[];
  clubSettings: Pick<ClubSettings, 'ui'>;
}): StaffMemberSessionAssigmentResume {
  const session1 = buildMemberSessionModel({
    staffMember,
    groups,
    sessionNumber: 1,
    clubParticipants,
    diveSession,
  });
  const session2 = buildMemberSessionModel({
    staffMember,
    groups,
    sessionNumber: 2,
    clubParticipants,
    diveSession,
  });

  const activeRoles: ClubStaffMemberRole[] = getSessionRoles({
    session1,
    session2,
  });

  const staffMemberConfig: DiveSessionStaffConfigMember = (
    diveSession.staffConfig?.staffMembers ?? []
  ).find((x) => x.staffMemberId === staffMember._id);

  const isSessionForceAssigned = staffMemberConfig?.assigned === true;
  const isSessionForceUnassigned = staffMemberConfig?.assigned === false;

  const assignedRoles: ClubStaffMemberRole[] = [
    ...(staffMemberConfig?.assignedRoles ?? []),
  ];

  const activeOrAssignedRoles: ClubStaffMemberRole[] =
    arrayBuilder.filterDuplicated([...assignedRoles, ...activeRoles]);

  const availableRoles =
    staffMemberDailyAvailabilityResume?.availableRoles ?? [];
  const isActiveOnSession = activeOrAssignedRoles.length !== 0;

  const staffPeriods = clubSettings?.ui?.planning?.staffPeriods;

  const isAvailableOnSessionFor =
    staffMemberDailyAvailabilityResume?.availabilityPeriod === 'day' ||
    (staffMemberDailyAvailabilityResume?.availabilityPeriod === 'am' &&
      dateService.isDateWithinPeriodHoursMinutes(
        diveSession.time,
        staffPeriods?.am,
      )) ||
    (staffMemberDailyAvailabilityResume?.availabilityPeriod === 'pm' &&
      dateService.isDateWithinPeriodHoursMinutes(
        diveSession.time,
        staffPeriods?.pm,
      ));

  // NOTE: ici on
  const isAvailableOnSession =
    isActiveOnSession ||
    (isAvailableOnSessionFor && !isSessionForceUnassigned) ||
    isSessionForceAssigned;

  const isAvailableOnSessionDay =
    isActiveOnSession ||
    (staffMemberDailyAvailabilityResume?.isAvailableToday &&
      !isSessionForceUnassigned) ||
    isSessionForceAssigned;

  return {
    availableRoles,
    isAvailableOnSessionDay,
    isAvailableOnSession,
    isActiveOnSession,
    activeRoles,
    assignedRoles,
    activeOrAssignedRoles,
    staffMemberConfig,
    session1,
    session2,
  };
}
function getSessionRoles({
  session1,
  session2,
}: {
  session1: DiveSessionStaffMemberTableModelStaffMemberSession;
  session2: DiveSessionStaffMemberTableModelStaffMemberSession;
}): ClubStaffMemberRole[] {
  const roles: ClubStaffMemberRole[] = [];
  if (session1?.isSurfaceSecurity || session2?.isSurfaceSecurity) {
    roles.push('surface-security');
  }
  if (session1?.isInstructor || session2?.isInstructor) {
    roles.push('scuba-diving-instructor');
  }
  if (session1?.isDivingDirector || session2?.isDivingDirector) {
    roles.push('diving-director');
  }
  return roles;
}

function isSessionInUse({
  session1,
  session2,
}: {
  session1: DiveSessionStaffMemberTableModelStaffMemberSession;
  session2: DiveSessionStaffMemberTableModelStaffMemberSession;
}) {
  return getSessionRoles({ session1, session2 }).length > 0;
}

function buildMemberSessionModel({
  staffMember,
  groups,
  sessionNumber,
  clubParticipants,
  diveSession,
}: {
  staffMember: Pick<ClubStaffMember, '_id' | 'profile' | 'availabilities'>;
  groups: DiveSessionResumeGroup[];
  sessionNumber: MultipleDiveSessionNumber;
  clubParticipants: Pick<ClubParticipant, 'diveSessionGroupId'>[];
  diveSession: Pick<
    DiveSession,
    'diveTourSession1' | 'diveTourSession2' | 'staffConfig' | 'time'
  >;
}): DiveSessionStaffMemberTableModelStaffMemberSession {
  const instructorStaffIds = [
    ...new Set(
      groups
        .map((x) =>
          sessionNumber === 1 || diveSession.diveTourSession2?.sameStaffSession1
            ? x.diveTourGroupSession1?.instructor?.staffId
            : x.diveTourGroupSession2?.instructor?.staffId,
        )
        .filter((x) => !!x),
    ),
  ];
  let isInstructor = instructorStaffIds.includes(staffMember._id);

  const surfaceSecurityStaffId =
    sessionNumber === 1 || diveSession.diveTourSession2?.sameStaffSession1
      ? diveSession.diveTourSession1?.surfaceSecurityStaffId
      : diveSession.diveTourSession2?.surfaceSecurityStaffId;
  const divingDirectorStaffId =
    sessionNumber === 1 || diveSession.diveTourSession2?.sameStaffSession1
      ? diveSession.diveTourSession1?.divingDirector?.staffId
      : diveSession.diveTourSession2?.divingDirector?.staffId;

  const staffMemberSessionConfig: DiveSessionStaffConfigMember = (
    diveSession.staffConfig?.staffMembers ?? []
  ).find((x) => x.staffMemberId === staffMember._id);

  let isSurfaceSecurity = surfaceSecurityStaffId === staffMember._id;
  let isDivingDirector = divingDirectorStaffId === staffMember._id;

  if (staffMemberSessionConfig?.assigned) {
    // mise à jour 25/09/2024 on prend aussi en compte les rôles globaux sur la session, juste pour le staff
    const assignedRoles: ClubStaffMemberRole[] =
      staffMemberSessionConfig?.assignedRoles ?? [];
    // isSurfaceSecurity =
    //   isSurfaceSecurity || assignedRoles.includes('surface-security');
    // isDivingDirector =
    //   isDivingDirector || assignedRoles.includes('diving-director');
    isInstructor =
      isInstructor || assignedRoles.includes('scuba-diving-instructor');
  }

  return {
    isSurfaceSecurity,
    isDivingDirector,
    isInstructor,
    groups: groups
      .filter(
        (x) =>
          (sessionNumber === 1 ||
          diveSession.diveTourSession2?.sameStaffSession1
            ? x.diveTourGroupSession1?.instructor?.staffId
            : x.diveTourGroupSession2?.instructor?.staffId) === staffMember._id,
      )
      .map((g) => ({
        _id: g._id,
        diveMode: g.diveMode,
        participantsCount: clubParticipants.filter(
          (p) => p.diveSessionGroupId === g._id,
        ).length,
      })),
  };
}
