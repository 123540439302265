import { DiveServiceOfferEquipment } from '@mabadive/app-common-model';

export const offerEquipmentFormatter = {
  formatOfferEquipment,
};

function formatOfferEquipment(
  equipment: DiveServiceOfferEquipment,
  {
    format,
  }: {
    format: 'short' | 'full';
  },
): string {
  switch (equipment) {
    case 'equipped':
      return format === 'short' ? 'perso' : 'Equipement perso';
    case 'not-equipped':
      return format === 'short' ? 'club' : 'Equipement club';
    case 'partially-equipped':
      return format === 'short' ? 'perso+club' : 'Equipement perso+club';
    case 'any-equipment':
      return format === 'short' ? 'club/perso' : 'Equipement club ou perso';
  }
}
