/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppBreadcrumbBar,
  AppIcons,
  AppLoadableContentContainer,
  AppPageContainer,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import { DiveCenterEcommerceHomePageLocalState } from '../../useDiveCenterEcommerceHomePageLocalState.hook';
import { CustomerProductsPage_CategoryContentCard } from '../02.cards';
import { DiveCenterBackButton } from './DiveCenterBackButton';

export const CustomerProductsPage_CategoryViewPanel = ({
  localState,
}: {
  localState: DiveCenterEcommerceHomePageLocalState;
}) => {
  const redirectTo = useRedirect();

  const { state, data, actions } = localState;

  const categoryId = state.viewState?.categoryId;

  return (
    <>
      <AppPageContainer
        className="bg-gray-50 overflow-y-auto"
        {...state.loadableContent}
      >
        <AppBreadcrumbBar
          classNameInner="max-w-6xl mx-auto"
          firstItemClassName="ml-0"
          color={'#1a7498'}
          // color={data.appUiTheme?.text?.textColor ?? '#444556'}
          items={[
            {
              icon: AppIcons.ecommerce.onlineBooking,
              label: 'Catalogue',
              onClick: () => actions.showCategories(),
            },
            {
              label: state.selectedCategory?.name,
            },
          ]}
        />
        <AppPageContentContainer
          paddingBottom={false}
          className="bg-white app-p-content w-full"
        >
          <AppLoadableContentContainer {...state.loadableContent}>
            <div className="w-full max-w-6xl mx-auto relative">
              <DiveCenterBackButton
                className="absolute z-10 top-1 left-1 "
                onClick={() => {
                  actions.showCategories();
                }}
              />
              <CustomerProductsPage_CategoryContentCard
                categoryId={categoryId}
                localState={localState}
              />
            </div>
          </AppLoadableContentContainer>
        </AppPageContentContainer>
      </AppPageContainer>
    </>
  );
};
