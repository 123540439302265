"use strict";
exports.__esModule = true;
exports.DIVE_CENTER_SETTINGS_EXPORTS_DAILY_RESUME = void 0;
var TEMPLATE_EXPORT_SHEET_DIVES_type_1 = require("./TEMPLATE_EXPORT_SHEET_DIVES.type");
var TEMPLATE_EXPORT_SHEET_EQUIPMENT_type_1 = require("./TEMPLATE_EXPORT_SHEET_EQUIPMENT.type");
var TEMPLATE_EXPORT_SHEET_RESUME_type_1 = require("./TEMPLATE_EXPORT_SHEET_RESUME.type");
var settings = {
    colorsScheme: 'black-white',
    defaultTargetDurations: {}
};
var exportDailyConfig = {
    baseFileName: 'daily-export-activity',
    sheets: [
        TEMPLATE_EXPORT_SHEET_DIVES_type_1.TEMPLATE_EXPORT_SHEET_DIVES,
        TEMPLATE_EXPORT_SHEET_EQUIPMENT_type_1.TEMPLATE_EXPORT_SHEET_EQUIPMENT,
        TEMPLATE_EXPORT_SHEET_RESUME_type_1.TEMPLATE_EXPORT_SHEET_RESUME,
    ],
    settings: settings
};
exports.DIVE_CENTER_SETTINGS_EXPORTS_DAILY_RESUME = {
    exportRef: 'daily-export-activity',
    label: 'Fiche de sécurité',
    description: 'Résumé, équipements, fiche de sécurité',
    exportConfig: exportDailyConfig,
    enabled: true,
    visibility: {
        dashboardDaily: true,
        planningDaily: true,
        planningSession: true
    }
};
