import { APP_COLORS } from '@mabadive/app-common-model';
import {
  Backdrop,
  Box,
  Button,
  Grid,
  LinearProgress,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FC, default as React, useMemo } from 'react';
import { useHistory } from 'react-router';
import {
  LoadableContentActionStatus,
  LoadableContentState,
} from 'src/business/_core/data/app-loading';
import { useCacheableClasses, useGlobalClasses } from '../../../root/theme';
import { AppIconsMaterial } from '../../icons';

export const AppLoadableContentContainer: FC<{
  children?: React.ReactNode | React.ReactNode[];
  contentState?: LoadableContentState;
  lastActionStatus?: LoadableContentActionStatus;
  retry?: () => void;
}> = ({
  children,
  retry,
  lastActionStatus = 'success',
  contentState = 'full',
}) => {
  const classes = useCacheableClasses({
    cacheKey: 'AppLoadableContentContainer',
    buildStyles,
  });

  const globalClasses = useGlobalClasses();

  const showErrorMessage = useMemo(
    () => contentState === 'none' && lastActionStatus === 'error',
    [contentState, lastActionStatus],
  );

  const history = useHistory();

  return (
    <>
      {lastActionStatus === 'in-progress' && (
        <LinearProgress
          className={`${classes.progress} action-${lastActionStatus} content-${contentState}`}
          variant={lastActionStatus === 'in-progress' ? 'query' : 'determinate'}
          value={100}
        />
      )}
      {contentState !== 'none' && <>{children}</>}

      <Backdrop className={classes.backdrop} open={showErrorMessage}>
        <Box component={Paper} p={5}>
          <Typography variant="h6">
            Erreur innatendue. Veuillez vérifier votre connexion Internet et
            ré-essayer. Si cela persiste, merci de nous contacter.
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box mt={2} p={3} textAlign="center">
                <Button
                  startIcon={<AppIconsMaterial.back />}
                  variant="outlined"
                  tabIndex={500}
                  className={globalClasses.buttonCancel}
                  onClick={() => history.goBack()}
                >
                  Retour
                </Button>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box mt={2} p={3} textAlign="center">
                {retry ? (
                  <Button
                    startIcon={<AppIconsMaterial.refresh />}
                    variant="outlined"
                    tabIndex={500}
                    className={globalClasses.buttonNavigate}
                    onClick={() => retry()}
                  >
                    Ré-essayer
                  </Button>
                ) : (
                  <Button
                    startIcon={<AppIconsMaterial.refresh />}
                    variant="outlined"
                    tabIndex={500}
                    className={globalClasses.buttonNavigate}
                    onClick={() => window.location.reload()}
                  >
                    Rafraîchir la page
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Backdrop>
    </>
  );
};

function buildStyles(theme: Theme) {
  return makeStyles({
    progress: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      zIndex: 2000,
      '&.action-error .MuiLinearProgress-barColorPrimary': {
        backgroundColor: APP_COLORS.error,
      },
      '&.action-in-progress .MuiLinearProgress-barColorPrimary': {
        backgroundColor: APP_COLORS.orange,
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  });
}
