import React from 'react';
import { Control, Path, useController } from 'react-hook-form';
import {
  AppSingleAutocomplete2,
  AppSingleAutocomplete2Props,
} from './AppSingleAutocomplete2';

// TODO une fois complètement migré vers react-hook-form, migrer les composants de 'material-ui' vers '@headlessui/react' (voir https://tailwindui.com/components/application-ui/forms)
export function AppSingleAutocomplete2RHF<S, T>({
  control,
  name,
  onChange,
  className,
  ...extraProps
}: {
  control: Control<T>;
  name: Path<T>;
  className?: string;
} & AppSingleAutocomplete2Props<S>) {
  const {
    field: { ref, onChange: onChangeRHF, value, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = useController<T>({
    name,
    control,
    rules: {
      required: extraProps.required,
    },
  });

  return (
    <AppSingleAutocomplete2<S>
      {...extraProps}
      {...inputProps}
      value={value as unknown as S}
      onChange={(value: S) => {
        onChangeRHF(value);
        onChange && onChange(value);
      }}
    />
  );
}
