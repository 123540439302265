

import { Theme } from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/styles/withStyles';
import { responsiveAttributesValues } from '../../../layout';

export function buildGlobalButtonsBar(theme: Theme): CreateCSSProperties<{}> {
  return {
    display: 'flex',
    width: '100%',
    ...responsiveAttributesValues(theme, {
      maxWidth: { values: ['100%', '100%', '800px', '800px', '800px'] },
    }),
    justifyContent: 'space-around',
  };
}