import { appLogger } from 'src/business/_core/modules/root/logger';
import useRouter from 'use-react-router';

export function useRedirect() {
  const { history } = useRouter();

  return (
    route: string,
    { replace }: { replace?: boolean } = { replace: false },
  ) => {
    if (replace) {
      appLogger.info(`[useRedirect] redirect/replace to "${route}"`);
      history.replace(route);
    } else {
      appLogger.info(`[useRedirect] redirect to "${route}"`);
      history.push(route);
    }
  };
}
