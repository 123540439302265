"use strict";
exports.__esModule = true;
exports.DEFAULT_EQUIPMENT_SETTINGS_SCUBA_REGULATOR = void 0;
var DEFAULT_SIZES_CLUB = [
    { ref: '1x', label: 'simple sortie' },
    { ref: '2x', label: 'double sortie' },
];
var DEFAULT_SIZES_SELF = [
    { ref: '1x', label: 'simple sortie' },
    { ref: '1x DIN', label: 'simple sortie DIN' },
    { ref: '1x INT', label: 'simple sortie INT (Étrier)' },
    { ref: '2x', label: 'double sortie' },
    { ref: '2x DIN', label: 'double sortie DIN' },
    { ref: '2x INT', label: 'double sortie INT (Étrier)' },
];
exports.DEFAULT_EQUIPMENT_SETTINGS_SCUBA_REGULATOR = {
    type: 'scubaRegulator',
    enabled: true,
    club: {
        enabled: false,
        models: DEFAULT_SIZES_CLUB.map(function (_a) {
            var ref = _a.ref, label = _a.label;
            return ({
                ref: ref,
                label: label
            });
        }),
        visibleOnSession: true
    },
    self: {
        autoSetOnGlobal: true,
        enabled: false,
        models: DEFAULT_SIZES_SELF.map(function (_a) {
            var ref = _a.ref, label = _a.label;
            return ({
                ref: ref,
                label: label
            });
        }),
        visibleOnSession: true
    }
};
