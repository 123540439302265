"use strict";
exports.__esModule = true;
exports.DIVE_SERVICE_PLANS = void 0;
// reference: `x${divesNumber};${DiveServiceOfferSupervision};${DiveServiceOfferEquipment}`
// DiveServiceOfferSupervision: 'supervised' | 'autonomous' | 'any-supervision'
// DiveServiceOfferEquipment: 'equipped' | 'not-equipped' | 'partially-equipped' | 'any-equipment'
exports.DIVE_SERVICE_PLANS = [
    // forfaits x5
    {
        reference: 'x5;any-supervision;any-equipment',
        divesNumber: 5,
        supervision: 'any-supervision',
        equipment: 'any-equipment'
    }, {
        reference: 'x5;supervised;not-equipped',
        divesNumber: 5,
        supervision: 'supervised',
        equipment: 'not-equipped'
    }, {
        reference: 'x5;supervised;equipped',
        divesNumber: 5,
        supervision: 'supervised',
        equipment: 'equipped'
    }, {
        reference: 'x5;autonomous;not-equipped',
        divesNumber: 5,
        supervision: 'autonomous',
        equipment: 'not-equipped'
    }, {
        reference: 'x5;autonomous;equipped',
        divesNumber: 5,
        supervision: 'autonomous',
        equipment: 'equipped'
    },
    // forfaits x10
    {
        reference: 'x10;any-supervision;any-equipment',
        divesNumber: 10,
        supervision: 'any-supervision',
        equipment: 'any-equipment'
    }, {
        reference: 'x10;supervised;not-equipped',
        divesNumber: 10,
        supervision: 'supervised',
        equipment: 'not-equipped'
    }, {
        reference: 'x10;supervised;equipped',
        divesNumber: 10,
        supervision: 'supervised',
        equipment: 'equipped'
    }, {
        reference: 'x10;autonomous;not-equipped',
        divesNumber: 10,
        supervision: 'autonomous',
        equipment: 'not-equipped'
    }, {
        reference: 'x10;autonomous;equipped',
        divesNumber: 10,
        supervision: 'autonomous',
        equipment: 'equipped'
    },
    // forfaits x20
    {
        reference: 'x20;supervised;not-equipped',
        divesNumber: 20,
        supervision: 'supervised',
        equipment: 'not-equipped'
    }, {
        reference: 'x20;supervised;equipped',
        divesNumber: 20,
        supervision: 'supervised',
        equipment: 'equipped'
    }, {
        reference: 'x20;autonomous;not-equipped',
        divesNumber: 20,
        supervision: 'autonomous',
        equipment: 'not-equipped'
    }, {
        reference: 'x20;autonomous;equipped',
        divesNumber: 20,
        supervision: 'autonomous',
        equipment: 'equipped'
    },
    // forfaits x40
    {
        reference: 'x40;autonomous;equipped',
        divesNumber: 40,
        supervision: 'autonomous',
        equipment: 'equipped'
    }
];
