import { ClubCustomerConsent_Content } from '@mabadive/app-common-model';
import { clsx } from 'clsx';
import React, { useCallback } from 'react';
import { useWatch } from 'react-hook-form';
import {
  AppInputBooleanSwitchRHF,
  AppSignaturePadRHF,
} from '../../../../../lib/form';
import {
  AppButton,
  AppFormAutoSubmit,
  AppIconsAction,
  AppMessageSingleLine,
  AppPageContainerWithFixedBars,
} from '../../../../_core/modules/layout';
import { uiStore } from '../../../../_core/store';
import { AppMultilineText } from '../../../../club/modules/_common/form';
import { DiveCenterBookingInquiryResponsePage_FormHeader } from '../DiveCenterBookingInquiryResponsePage_FillFormPanel';
import { DiveCenterBookingInquiryResponsePage_FormModel } from '../model';
import { DiveCenterBookingInquiryResponsePageLocalState } from '../useDiveCenterBookingInquiryResponsePageLocalState.hook';

export const CONSENT_VALID_INFO_CONTENT: ClubCustomerConsent_Content = {
  title: 'Êtes-vous sûr des informations renseignées?',
  description: undefined,
  question: "Je confirme l'exactitude des informations renseignées",
};

export const DiveCenterBookingInquiryResponsePage_ConfirmFormPanel = ({
  localState,
}: {
  localState: DiveCenterBookingInquiryResponsePageLocalState;
}) => {
  const {
    state: { form, viewState, setViewState, clubResume, consentConfig_GDPR },
    data: { loadableContent, aggregatedBookingResume, inquiry },
    actions,
  } = localState;

  const { control } = form;
  const [validInfoConsent, gdprConsent] = useWatch({
    control: control,
    name: ['consents.validInfo.accepted', 'consents.gdpr.accepted'],
  });
  const clubPublicSettings = clubResume?.clubSettings?.publicSettings;

  const displaySignaturePad =
    inquiry?.settings?.consents?.padSignature?.enabled;

  const submitForm = useCallback(async () => {
    try {
      return form.handleSubmit(
        function onValid(
          formValue: DiveCenterBookingInquiryResponsePage_FormModel,
        ) {
          actions.submitInquiryResponse({
            formValue,
            status: 'submitted',
            nextAction: 'back-to-booking',
          });
        },
        function onInvalid(err) {
          uiStore.snackbarMessage.set({
            type: 'error',
            content:
              'Formulaire invalide. Veuillez corriger les erreurs ci-dessus.',
          });
          throw err;
        },
      )();
    } catch (err) {
      // message already displayed
    }
  }, [actions, form]);
  const onBack = useCallback(async () => {
    setViewState({
      mode: 'fill-form',
    });
  }, [setViewState]);

  return (
    <AppFormAutoSubmit className="w-full" onSubmit={submitForm}>
      <AppPageContainerWithFixedBars
        contentState={loadableContent.contentState}
        lastActionStatus={loadableContent.lastActionStatus}
        marginBottom={true}
        paddingBottom={true}
        contentClassName="bg-gray-200 app-p-content"
        footerBar={() => (
          <div className="lg:hidden border-t border-gray-600 bg-gray-400 pt-2 pb-2 px-4">
            <div className="mx-auto w-full max-w-sm flex justify-center items-stretch xs:justify-center gap-4 md:gap-8">
              <AppButton
                type="button"
                className="flex-grow text-sm md:text-base font-bold uppercase"
                size="normal"
                fullWidth={false}
                icon={AppIconsAction.back}
                // iconPosition="right"
                color="gray-outline-light"
                onClick={() => {
                  onBack();
                }}
              >
                Retour
              </AppButton>
              <AppButton
                type="submit"
                className="flex-grow text-sm md:text-base font-bold uppercase"
                size="normal"
                icon={AppIconsAction.confirm}
                // iconPosition="right"
                color="primary-bg"
              >
                Confirmer
              </AppButton>
            </div>
          </div>
        )}
      >
        <div className="w-full grid gap-4">
          <DiveCenterBookingInquiryResponsePage_FormHeader
            className="w-full"
            inquiry={inquiry}
            localState={localState}
            form={form}
          />
          <h3 className="text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
            {"Confirmer l'envoi de mes informations"}
          </h3>

          <div className="app-card app-p-content text-base grid gap-4">
            <div className="grid gap-2">
              <AppMessageSingleLine type="info" className="font-bold">
                {CONSENT_VALID_INFO_CONTENT.title}
              </AppMessageSingleLine>

              <AppInputBooleanSwitchRHF
                control={control}
                className="my-4"
                name={'consents.validInfo.accepted'}
                required={true}
                theme={'success'}
                label={
                  <div
                    className={clsx(
                      'sm:text-sm sm:ml-1',
                      validInfoConsent
                        ? 'font-bold text-app-blue'
                        : 'font-bold text-status-error',
                    )}
                  >
                    {CONSENT_VALID_INFO_CONTENT.question}
                  </div>
                }
              />
            </div>

            {consentConfig_GDPR.enabled && (
              <div className="grid gap-2">
                <AppMessageSingleLine type="info" className="font-bold">
                  {consentConfig_GDPR.content?.title}
                </AppMessageSingleLine>

                <blockquote className="mt-2 relative p-4 text-base bg-gray-50 text-gray-600 border border-l-4 border-gray-100 border-l-gray-400 quote">
                  <AppMultilineText
                    className="my-2"
                    lineClassName="my-0.5 sm:my-1 md:my-1.5 quote"
                    text={consentConfig_GDPR.content?.description}
                  />
                </blockquote>
                <AppInputBooleanSwitchRHF
                  control={control}
                  className="my-4"
                  name={'consents.gdpr.accepted'}
                  required={true}
                  theme={'success'}
                  label={
                    <div
                      className={clsx(
                        'sm:text-sm sm:ml-1',
                        gdprConsent
                          ? 'font-bold text-app-blue'
                          : 'font-bold text-status-error',
                      )}
                    >
                      {consentConfig_GDPR.content?.question}
                    </div>
                  }
                />
              </div>
            )}
          </div>
          {displaySignaturePad && (
            <div className="app-card app-p-content grid gap-2 h-[300px]">
              <AppMessageSingleLine type="info" className="font-bold">
                Signature électronique
              </AppMessageSingleLine>
              <p>
                Veuillez reproduire votre signature manuscrite dans le cadre
                ci-dessous.
              </p>
              <AppSignaturePadRHF
                padClassName=" max-h-[200px] max-w-[300px]"
                control={control}
                name={'signature'}
                required={true}
              />
            </div>
          )}
          <div className="hidden lg:flex mt-8 w-full max-w-sm justify-center items-stretch xs:justify-center gap-4 md:gap-8">
            <AppButton
              type="button"
              className="flex-grow text-sm md:text-base font-bold uppercase"
              size="normal"
              fullWidth={false}
              icon={AppIconsAction.back}
              // iconPosition="right"
              color="gray-outline-light"
              onClick={() => {
                onBack();
              }}
            >
              Retour
            </AppButton>
            <AppButton
              type="submit"
              className="flex-grow text-sm md:text-base font-bold uppercase"
              size="normal"
              icon={AppIconsAction.confirm}
              // iconPosition="right"
              color="primary-bg"
            >
              Confirmer
            </AppButton>
          </div>
        </div>
      </AppPageContainerWithFixedBars>
    </AppFormAutoSubmit>
  );
};
