import {
  BookingStatus,
  ParticipantActionDescription,
  ParticipantBookingStatus,
} from '@mabadive/app-common-model';
import { useMemo } from 'react';

export function useMergedBookingParticipantStatus({
  bookingState,
  bookingStatus,
}: {
  bookingState: ParticipantActionDescription<ParticipantBookingStatus>;
  bookingStatus: ParticipantBookingStatus;
}): BookingStatus {
  return useMemo(() => {
    if (bookingState?.value === 'cancelled') {
      return 'cancelled';
    }
    if (bookingState?.value === 'pending') {
      return 'pending';
    }
    return bookingStatus;
  }, [bookingState?.value, bookingStatus]);
}
