import { DiveSessionResumeGql_Customer } from '@mabadive/app-common-model';
import { editionBookingParticipantResumeAttributesBuilder } from '../edition-booking/editionBookingParticipantResumeAttributesBuilder.service';
import { bookingMemberResumeAttributesBuilder } from './bookingMemberResumeAttributesBuilder.service';

export const bookingProductResumeAttributesBuilder = {
  buildAttributes,
};

/**
 * Build graphql attributes for BookingResumeProduct
 */
function buildAttributes() {
  return `
  _id
  _createdAt
  _updatedAt
  _version
  clubReference
  diveCenterId
  bookingId
  bookingMemberId
  bookingSessionId
  bookingSessionParticipantId
  bookingDate
  bookingLastUpdateDate
  type
  attributes
  purchasePackageId
  bookingProductStatus
  bookingProductState
  bookingProductHistory
  purchaseStatus
 bookingMember {
   ${bookingMemberResumeAttributesBuilder.buildAttributes()}
 }
 bookingSession {
   _id
   diveSessionReference
   bookingSessionStatus
   diveSession {
     ${DiveSessionResumeGql_Customer}
   }
 }
 bookingSessionParticipant {
  ${editionBookingParticipantResumeAttributesBuilder.buildAttributes()}
 }
 `;
}
