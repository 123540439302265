import {
  AppFakerPublicFakeId,
  AppFakerPublicResponseOnlineBookingContact,
  AppFakerPublicResponseOnlineBookingParticipants,
} from '@mabadive/app-common-model';
import { apiClient } from 'src/_common-browser';

export const fakerClient = {
  generateOnlineBookingContact,
  generateOnlineBookingParticipants,
};

function generateOnlineBookingContact({
  clubReference,
}: {
  clubReference: string;
}): Promise<AppFakerPublicResponseOnlineBookingContact> {
  // send remote operation
  const fakeId: AppFakerPublicFakeId = 'online-booking-contact';
  return apiClient
    .post<any>('/faker/public', {
      authenticate: true,
      json: {
        fakeId,
        data: {
          clubReference,
        },
      },
    })
    .toPromise();
}

function generateOnlineBookingParticipants({
  clubReference,
  participantsCount,
}: {
  clubReference: string;
  participantsCount: number;
}): Promise<AppFakerPublicResponseOnlineBookingParticipants> {
  // send remote operation
  const fakeId: AppFakerPublicFakeId = 'online-booking-participants';
  return apiClient
    .post<any>('/faker/public', {
      authenticate: true,
      json: {
        fakeId,
        data: {
          clubReference,
          participantsCount,
        },
      },
    })
    .toPromise();
}
