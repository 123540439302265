import { MabadiveProWebConfig } from '../model';

const appVersion = process.env.REACT_APP_MABADIVE_TAG;

const cdnUrl = 'https://mabadive.fra1.cdn.digitaloceanspaces.com';
export const mabadiveDiverWebConfig_DEV: MabadiveProWebConfig = {
  envId: 'dev',
  appVersion,
  applications: {
    publicWebUrl: 'http://localhost:3600',
    docsWebUrl: 'http://localhost:3700',
    diverWebUrl: 'http://localhost:3009',
    cdnUrl,
  },
  debug: {
    showEnvId: true,
    isEnvDemoOrTest: true,
    checkNewVersion: false,
    showAppVersion: false,
    showMediaSize: true,
    showErrorStackTrace: true,
    debugStore: true,
    autoFill: true,
  },
  authentication: {
    defaultAuthLogin: {
      login: 'toub',
      // password: '',
    },
  },
  data: {
    api: {
      baseUrl: 'http://localhost:5000/api',
      // baseUrl: 'http://192.168.1.10:5000/api',
    },
    graphql: {
      enabled: true,
      // NOTE: docker-host is defined as 0.0.0.0 in /etc/hosts
      hostname: 'docker-host:8080',
      // hostname: '192.168.1.10:8000',
      context: 'v1/graphql',
      httpProtocol: 'http',
      wsProtocol: 'ws',
    },
  },
  log: {
    level: 'info',
    sentry: {
      enabled: false,
      configString:
        'https://a2ea92e9fa3a4dca96d79bc022757310@o300584.ingest.sentry.io/4503925953724416',
    },
    console: {
      appRules: {
        // console logger levels
        app: {
          level: 'info',
          modules: {
            // 'app-common-browser': 'debug',
            // 'security': 'info',
            // 'patch': 'info',
          },
        },
        'app-common-browser': {
          level: 'debug',
          modules: {
            // 'app-common-browser': 'debug',
            // 'security': 'info',
            // 'patch': 'info',
          },
        },
      },
    },
  },
  c: {
    e: [['contact'], ['mabadive', 'com']],
    p: ['+33', '7', '44', '70', '30', '55'],
  },
};
