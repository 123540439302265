import { cloner } from '@mabadive/app-common-services';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { SimpleStore } from '../core/SimpleStore.model';
import { AttributeStore } from './AttributeStore.model';

function setAttribute<T>(
  store: SimpleStore<any>,
  name: string,
  item: T,
  actionId: string,
): void {
  return reduce(store, name, () => item, actionId);
}

function reduce<T>(
  store: SimpleStore<any>,
  name: string,
  reduceFn: (state: T) => T,
  actionId: string,
): void {
  const action = {
    id: actionId,
    target: name,
  };

  store.reduceState(action, (state) => {
    const newState = {
      ...state,
    } as any;

    newState[name] = reduceFn(newState ? newState[name] : undefined);

    return newState;
  });
}

function getAttribute<T>(store: SimpleStore<any>, name: string): Observable<T> {
  return store.getState().pipe(
    map((state: any) => state[name] as T),
    distinctUntilChanged(),
    map((attr) => cloner.clone(attr)),
  );
}

function getAttributeSnapshot<T>(store: SimpleStore<any>, name: string): T {
  const state = store.getStateSnapshot();
  const attr = state ? (state[name] as T) : undefined;
  return cloner.clone(attr);
}

function create<T>(
  store: SimpleStore<any>,
  name: string,
  initialValue?: T,
): AttributeStore<T> {
  const actions = {
    getSnapshot: () => getAttributeSnapshot<T>(store, name),
    get: () => getAttribute<T>(store, name),
    set: (value: T, actionId?: string) =>
      setAttribute<T>(store, name, value, actionId || 'set'),
    remove: (actionId?: string) =>
      setAttribute<T>(store, name, undefined, actionId || 'remove'),
    reduce: (reduceFn: (state: T) => T, actionId?: string) =>
      reduce<T>(store, name, reduceFn, actionId || 'reduce'),
  };
  actions.set(initialValue, 'init');

  return actions;
}

export const attributeStoreFactory = {
  create,
};
