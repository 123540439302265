import {
  ClubPublicSettings,
  FIRST_DIVES_REFERENCE_MAP,
  FirstDiveTrainingReference,
  OrgDataFirstDive,
} from '@mabadive/app-common-model';

export const diveServiceFirstDiveFormatter = {
  format,
  formatLabel,
  formatFirstDive,
};
export type FirstDiveStringFormat = 'ref' | 'name' | 'ref-name';

function format(
  firstDiveTrainingReference: FirstDiveTrainingReference,
  {
    publicSettings,
    format,
  }: {
    publicSettings: ClubPublicSettings;
    format: FirstDiveStringFormat;
  },
) {
  const firstDive = FIRST_DIVES_REFERENCE_MAP[firstDiveTrainingReference];

  return formatFirstDive(firstDive, { publicSettings, format });
}

function formatLabel(
  firstDiveTrainingReference: FirstDiveTrainingReference,
  {
    publicSettings,
  }: {
    publicSettings: ClubPublicSettings;
  },
) {
  const customType = publicSettings?.services?.firstDives?.customize
    ? publicSettings?.services?.firstDives?.customTypes.find(
        (x) => x.originalRef === firstDiveTrainingReference,
      )
    : undefined;

  if (customType) {
    return customType.labelLong;
  }

  const firstDive = FIRST_DIVES_REFERENCE_MAP[firstDiveTrainingReference];

  return firstDive?.label ?? firstDiveTrainingReference;
}

function formatFirstDive(
  firstDive: OrgDataFirstDive,
  {
    publicSettings,
    format,
  }: {
    publicSettings: ClubPublicSettings;
    format: FirstDiveStringFormat;
  },
): string {
  if (!firstDive) {
    return '';
  }
  const customType = publicSettings?.services?.firstDives?.customize
    ? publicSettings?.services?.firstDives?.customTypes.find(
        (x) => x.originalRef === firstDive?.reference,
      )
    : undefined;

  if (format === 'ref') {
    return customType?.labelShort ?? firstDive.reference;
  }
  if (format === 'name') {
    return customType?.labelLong ?? firstDive.label;
  }
  if (format === 'ref-name') {
    return `${customType?.labelShort ?? firstDive.reference} - ${
      customType?.labelLong ?? firstDive.label
    }`;
  }
}
