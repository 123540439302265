import {
  AppInquiryQuestion,
  AppInquiryResponse_ExpectedResponseStatus,
  AppInquiryResponse_Question,
} from '../../../../app-common-model/src';

export const appInquiryResponseBuilder = {
  buildExpectedResponseStatus,
};

function buildExpectedResponseStatus({
  responseQuestion,
  inquiryQuestion,
}: {
  responseQuestion: Pick<AppInquiryResponse_Question, 'answer'>;
  inquiryQuestion: Pick<AppInquiryQuestion, 'answerDetails'>;
}): AppInquiryResponse_ExpectedResponseStatus {
  let expectedResponseStatus: AppInquiryResponse_ExpectedResponseStatus;
  const isExpectedValue =
    inquiryQuestion.answerDetails.format === 'yes-no'
      ? inquiryQuestion.answerDetails.formatOptions.yesNo.expectedValue
      : null;
  const responseValue = responseQuestion.answer.bool;
  if (isDefined(isExpectedValue) && isDefined(responseValue)) {
    // une "bonne réponse" est attendue
    if (isExpectedValue === responseValue) {
      expectedResponseStatus = 'valid';
    } else {
      expectedResponseStatus = 'invalid';
    }
  }
  return expectedResponseStatus;
}
function isDefined(isExpectedValue: boolean) {
  return isExpectedValue !== null && isExpectedValue !== undefined;
}
