import React from 'react';
import { CUS_BookingMemberFull } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/models';
import { DiveCenterBookingPageAggregatedBookingResume } from '../../../model/DiveCenterBookingPageAggregatedBookingResume.type';
import {
  BookingResumeMembersTableOnClickMemberAction,
  DiveCenterBookingMembersListItem,
} from './DiveCenterBookingMembersListItem';

export function DiveCenterBookingMembersList({
  enableCustomerUpdate,
  aggregatedBookingResume,
  onClick,
  className,
}: {
  enableCustomerUpdate: boolean;
  aggregatedBookingResume: DiveCenterBookingPageAggregatedBookingResume;
  onClick: (
    bookingMembersFull: CUS_BookingMemberFull,
    options: {
      action: BookingResumeMembersTableOnClickMemberAction;
      participantsIndex: number;
    },
  ) => any;
  className?: string;
}) {
  const { bookingMembersFull } = aggregatedBookingResume;

  return (
    <>
      <div className={`overflow-hidden text-xs ${className}`}>
        <ul className="divide-y divide-gray-200">
          {bookingMembersFull.map((bookingMemberFull, participantsIndex) => (
            <DiveCenterBookingMembersListItem
              key={bookingMemberFull.bookingMember._id}
              enableCustomerUpdate={enableCustomerUpdate}
              aggregatedBookingResume={aggregatedBookingResume}
              bookingMemberFull={bookingMemberFull}
              onClick={(bookingMember, action) => {
                onClick &&
                  onClick(bookingMember, { action, participantsIndex });
              }}
            />
          ))}
        </ul>
      </div>
    </>
  );
}
