"use strict";
exports.__esModule = true;
exports.TEMPLATE_EXPORT_SHEET_RESUME = void 0;
var configSheet1WidthCellsCount = 40;
exports.TEMPLATE_EXPORT_SHEET_RESUME = {
    tab: {
        name: 'Résumé',
        color: '#9f3909',
        comment: 'Résumé des plongées par moniteur'
    },
    content: {
        title: 'Récapitulatif de la journée',
        items: [
            {
                id: 'events'
            },
            {
                id: 'pickup'
            },
            {
                id: 'sessions',
                // columns: 2,
                items: [
                    {
                        id: 'title',
                        enabled: true,
                        number: true,
                        time: true
                    },
                    {
                        id: 'participants-count',
                        enabled: true,
                        divers: true,
                        staff: true,
                        total: true
                    },
                    {
                        id: 'staff-groups',
                        enabled: true
                    },
                ]
            },
        ]
    },
    settings: {
        defaultHeight: 20,
        widthCellsCount: configSheet1WidthCellsCount,
        orientation: 'portrait'
    }
};
