import {
  ClubDiver,
  ClubDiverCertificationLabels,
  ClubDiverDivingDetailsOrgCertification,
} from '@mabadive/app-common-model';
import { certificationFormatter } from '../certification';

export const diverCertificationFormatter = { formatCertificationLabels };

function formatCertificationLabels({
  diver,
  format,
}: {
  diver: ClubDiver;
  format: 'short' | 'full';
}): ClubDiverCertificationLabels {
  const orgReference = diver.divingCertification1?.orgReference;

  const mainCertificationLabel = [
    formatDivingCertificationMain({
      divingCertification: diver.divingCertification1,
      format,
    }),
    formatDivingCertificationMain({
      divingCertification: diver.divingCertification2,
      format,
    }),
  ]
    .filter((x) => !!x)
    .join(' / ');

  const gazCertificationLabel = [
    formatDivingCertificationGaz(diver.divingCertification1),
    formatDivingCertificationGaz(diver.divingCertification2),
  ]
    .filter((x) => !!x)
    .join(' / ');

  const hasCertification =
    !!orgReference?.trim().length ||
    !!mainCertificationLabel?.trim().length ||
    !!gazCertificationLabel?.trim().length;

  return {
    hasCertification,
    orgReference,
    mainCertificationLabel,
    gazCertificationLabel,
  };
}

function formatDivingCertificationMain({
  divingCertification,
  format,
}: {
  divingCertification: ClubDiverDivingDetailsOrgCertification;
  format: 'short' | 'full';
}) {
  if (divingCertification) {
    return certificationFormatter.formatCertificationReference(
      divingCertification?.mainCertificationReference,
      {
        format: format === 'full' ? 'name-org' : 'ref',
        deepDiver: divingCertification?.specialties?.deepDiver,
      },
    );
  }
}

function formatDivingCertificationGaz(
  divingCertification: ClubDiverDivingDetailsOrgCertification,
) {
  if (divingCertification) {
    return divingCertification.gazCertificationReference;
  }
}
