/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppLoadableContentContainer,
  AppPageContainer,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import { DiveCenterBrandCard } from '../../../components';
import { DiveCenterEcommerceHomePageLocalState } from '../../useDiveCenterEcommerceHomePageLocalState.hook';
import { CustomerProductsPage_CategoryCoverCard } from '../02.cards';

export const CustomerProductsPage_CategoryListPanel = ({
  localState,
}: {
  localState: DiveCenterEcommerceHomePageLocalState;
}) => {
  const redirectTo = useRedirect();

  const { state, data, actions } = localState;

  const clubSettings = data.clubSettings;
  const clubPublicSettings = data.clubPublicSettings;
  const diveCenter = data.diveCenter;
  const clubPublicReference = data.clubPublicReference;

  const categories = useMemo(() => {
    return data.categories ?? [];
    // dataSorter.sortMultiple(data.categories ?? [], {
    //   getSortAttributes: (x) => [
    //     // todo permettre de trier les catégories
    //     // {
    //     //   value: x.sortIndex,
    //     // },
    //     {
    //       value: x.name,
    //     },
    //   ],
    // });
  }, [data.categories]);

  return (
    <>
      {/* {!!operationInCustomergressMessage && (
        <div className={'app-card app-p-content--lg'}>
          <LoadingCustomergressBar>{operationInCustomergressMessage}</LoadingCustomergressBar>
        </div>
      )} */}
      <AppPageContainer
        className="bg-gray-50 overflow-y-auto"
        {...state.loadableContent}
      >
        <AppPageContentContainer
          paddingBottom={false}
          className="bg-gray-50 app-p-content"
        >
          <AppLoadableContentContainer {...state.loadableContent}>
            <div className="flex flex-col justify-between gap-8 md:gap-16">
              {!state.isIframe && (
                <>
                  <DiveCenterBrandCard
                    className="app-card app-p-content hidden sm:block"
                    clubPublicData={clubSettings?.publicData}
                    clubPublicSettings={clubPublicSettings}
                    diveCenterPublicData={diveCenter?.publicData}
                    clubPublicReference={clubPublicReference}
                    showHeader={true}
                    showContactLinks={true}
                  />
                  <DiveCenterBrandCard
                    className="app-card app-p-content sm:hidden"
                    clubPublicData={clubSettings?.publicData}
                    clubPublicSettings={clubPublicSettings}
                    diveCenterPublicData={diveCenter?.publicData}
                    clubPublicReference={clubPublicReference}
                    showHeader={true}
                    showContactLinks={false} // sur mobile, on masque les numéros, ils sont visibles en bas de page
                    showLegalInformation={false} // sur mobile, on masque les infos légales, ils sont visibles en bas de page
                  />
                </>
              )}
              <div className="w-full max-w-6xl mx-auto">
                {categories.length > 0 && (
                  <div className="flex flex-col gap-4 md:gap-6">
                    <h2
                      className="text-lg font-bold leading-4 uppercase"
                      style={{
                        color: data.appUiTheme.title1.textColor,
                      }}
                    >
                      Choisissez une catégorie
                    </h2>
                    <div className="grid xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                      {(categories ?? []).map((c) => (
                        <CustomerProductsPage_CategoryCoverCard
                          key={c._id}
                          category={c}
                          localState={localState}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <DiveCenterBrandCard
                className="mt-8 app-card app-p-content sm:hidden"
                clubPublicData={clubSettings?.publicData}
                clubPublicSettings={clubPublicSettings}
                diveCenterPublicData={diveCenter?.publicData}
                clubPublicReference={clubPublicReference}
                showHeader={true}
                showContactLinks={true}
              />
            </div>
          </AppLoadableContentContainer>
        </AppPageContentContainer>
      </AppPageContainer>
    </>
  );
};
