import { logTracker } from './logTracker';
import { MatomoArgs, matomoTracker } from './matomoTracker';
import { StackTracker } from './model/StackTracker.type';
import { StackTrackerType } from './model/StackTrackerType.type';

export const stackTracker = {

  build({
    type,
    matomo,
    log,
  }: {
    type: StackTrackerType;
    matomo?: MatomoArgs;
    log?: (text: string, ...args: any) => void;
  }): StackTracker {
    if (type === 'matomo') {
      return matomoTracker.build(matomo);
    } if (type === 'log') {
      return logTracker.build({
        log: log
          ? (text: string, ...args: any) => log(text, args)
          : (text: string, ...args: any) => console.info(text, args),
      });
    }
    // disabled
    return logTracker.build({
      log: () => { },
    });
  },

};
