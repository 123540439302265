import { useContext } from 'react';
import { DiveCenterShoppingCartContext } from './DiveCenterShoppingCartProvider.context';

export const useDiveCenterShoppingCart = () => {
  const context = useContext(DiveCenterShoppingCartContext);
  if (context === undefined) {
    throw new Error(
      'useDiveCenterShoppingCart must be used within a DiveCenterShoppingCartProvider',
    );
  }
  return context;
};
