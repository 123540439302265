"use strict";
exports.__esModule = true;
exports.ONLINE_BOOKING_SOURCES = void 0;
exports.ONLINE_BOOKING_SOURCES = [
    'website',
    'facebook',
    'instagram',
    'tripadvisor',
    'google',
    'yelp',
    'mail',
    'whatsapp',
    'partner',
    'blog',
    'other',
];
