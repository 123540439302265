// import ms, { StringValue } from 'ms';
export type AppDurationParserString =
  | '2 days'
  | '7 days'
  | '5 minutes'
  | '1 minute';
// export type AppDurationParserString = `${number} ${
//   // from 'ms' source code
//   | 'years'
//     | 'year'
//     | 'yrs'
//     | 'yr'
//     | 'y'
//     | 'weeks'
//     | 'week'
//     | 'w'
//     | 'days'
//     | 'day'
//     | 'd'
//     | 'hours'
//     | 'hour'
//     | 'hrs'
//     | 'hr'
//     | 'h'
//     | 'minutes'
//     | 'minute'
//     | 'mins'
//     | 'min'
//     | 'm'
//     | 'seconds'
//     | 'second'
//     | 'secs'
//     | 'sec'
//     | 's'
//     | 'milliseconds'
//     | 'millisecond'
//     | 'msecs'
//     | 'msec'
//     | 'ms'
// }`;
export const durationParser = {
  parseTimestamp,
};
function parseTimestamp(duration: AppDurationParserString): number {
  let milliseconds: number;

  switch (duration) {
    case '1 minute':
      milliseconds = 1 * 60 * 1000;
      break;
      case '5 minutes':
        milliseconds = 5 * 60 * 1000;
        break;
    case '2 days':
      milliseconds = 2 * 24 * 60 * 60 * 1000;
      break;
    case '7 days':
      milliseconds = 7 * 24 * 60 * 60 * 1000;
      break;
    default:
      throw new Error(`Invalid duration string ${duration}`);
  }

  return milliseconds;
}
