/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CustomerEcommerceProductArticleFull,
  EcommerceCategoryGql_Customer,
  EcommerceProductGql_Customer,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { AppButton, AppIconsEcommerce } from '../../../../_core/modules/layout';
import { DiveCenterBrandCardContactLinks } from '../../../components/DiveCenterBrandCard/DiveCenterBrandCardContactLinks';
import { DiveCenterEcommerceHomePageLocalState } from '../../useDiveCenterEcommerceHomePageLocalState.hook';
import {
  AppTranslationContentPanel,
  EcommerceProductArticlePreviewCover,
} from '../03.content';

export const CustomerProductsPage_CategoryProductArticleCoverCard = ({
  category,
  product,
  productArticleFull,
  showCoverImage,
  showContent,
  localState,
  className,
}: {
  category: EcommerceCategoryGql_Customer;
  product: EcommerceProductGql_Customer;
  productArticleFull: CustomerEcommerceProductArticleFull;
  localState: DiveCenterEcommerceHomePageLocalState;
  showCoverImage: boolean;
  showContent: boolean;
  className?: string;
}) => {
  const clubPublicSettings = localState.data.clubPublicSettings;
  const enableBooking =
    clubPublicSettings?.customerSpace?.onlineBooking?.enableCatalog &&
    clubPublicSettings?.customerSpace?.onlineBooking?.enableBooking;
  const { state, data, actions } = localState;

  return (
    <div
      className={clsx('app-card border-t-xl border-app-primary', className)}
      // onClick={(e) => {
      //   e.stopPropagation();
      //   actions.selectCategoryProductArticle({
      //     categoryId: category._id,
      //     productId: product._id,
      //   });
      // }}
    >
      {/* <h2 className="text-left text-sm font-bold uppercase p-2 text-app-secondary">
        {product.name}
      </h2> */}
      <EcommerceProductArticlePreviewCover
        className="w-full"
        showCoverImage={showCoverImage}
        showTitle={true}
        productArticleFull={productArticleFull}
        clubPublicSettings={data.clubPublicSettings}
        showPrices={true}
        onClick={
          productArticleFull.bookingAvailable && enableBooking
            ? (e) => {
                e.stopPropagation();
                actions.startBookingArticle({
                  categoryId: category._id,
                  productId: product._id,
                  articleId: productArticleFull.article._id,
                });
              }
            : undefined
        }
      >
        <div className="flex flex-col gap-4">
          {productArticleFull.bookingAvailable && enableBooking ? (
            <AppButton
              className="uppercase"
              color="primary-bg"
              size="normal"
              icon={AppIconsEcommerce.onlineBooking}
              onClick={(e) => {
                e.stopPropagation();
                actions.startBookingArticle({
                  categoryId: category._id,
                  productId: product._id,
                  articleId: productArticleFull.article._id,
                });
              }}
            >
              Réserver
            </AppButton>
          ) : (
            <DiveCenterBrandCardContactLinks
              label={
                <div className="text-center font-bold">
                  INFOS ET RÉSERVATION
                </div>
              }
              contact={data.diveCenterPublicData?.contact}
              clubPublicSettings={data.clubPublicSettings}
            />
          )}
        </div>
        {showContent && (
          <div className="h-full flex-col gap-8">
            {(
              productArticleFull?.articleBookletPage?.mainContent?.content
                ?.items ?? []
            ).map((item, i) => (
              <AppTranslationContentPanel
                key={i}
                item={item}
                appUiTheme={data.appUiTheme}
              />
            ))}
          </div>
        )}
      </EcommerceProductArticlePreviewCover>
    </div>
  );
};
