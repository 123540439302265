/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubPublicSettingsCustomerSpaceDisplaySessionConfig,
  DiveSessionTimeDayPeriod,
} from '@mabadive/app-common-model';
import { clubDiveSessionThemeBuilder } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { AggregatedBookingSessionFull } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/models';
import { diveSessionStyleBuilder } from 'src/business/club/modules/club-planning-light/components/ClubPlanningLightSession/diveSessionStyleBuilder.service';
import {
  ClubDiveSessionHeaderStatus,
  ClubDiveSessionHeaderTimeAndDiveSite,
  ClubDiveSessionHeaderTitle,
  ClubDiveSessionHeaderTitleWithPrefix,
} from './components';

export const DiveSessionSecuritySheetCardHeader = ({
  bookingSessionFull,
  config,
}: {
  bookingSessionFull: AggregatedBookingSessionFull;
  config: ClubPublicSettingsCustomerSpaceDisplaySessionConfig;
}) => {
  const session = bookingSessionFull.diveSession;

  const timeDayPeriod: DiveSessionTimeDayPeriod = useMemo(
    () => clubDiveSessionThemeBuilder.buildTimeDayPeriod(session.time),
    [session.time],
  );

  const isMultiSession = !!session.diveTourSession2;
  const groupDiveSite1AndTitle = !isMultiSession && !session.diveSiteId;

  const sessionBorderStyle = diveSessionStyleBuilder.buildBorderStyleFromTime({
    isVirtual: false,
    time: session.time,
  });

  return (
    <div
      className={`ring-opacity-30 ring-day-period-${timeDayPeriod}-dark hover:ring-2`}
    >
      {/* <ClubDiveSessionHeaderCard className="my-1" diveSession={session} /> */}
      <div
        className={`mb-2 text-sm text-gray-600 hover:bg-gray-50 relative border-b ${sessionBorderStyle}`}
      >
        {groupDiveSite1AndTitle ? (
          <ClubDiveSessionHeaderTitleWithPrefix
            className={`border-t-0 ${sessionBorderStyle}`}
            diveSession={session}
            sessionIndex={1}
          />
        ) : (
          <>
            <ClubDiveSessionHeaderTimeAndDiveSite
              className={`border-t-0 ${sessionBorderStyle}`}
              diveSiteId={session.diveSiteId}
              time={session.time}
              isMultiSession={isMultiSession}
              sessionIndex={1}
              config={config}
            />
            {isMultiSession && (
              <ClubDiveSessionHeaderTimeAndDiveSite
                className={`border-t ${sessionBorderStyle}`}
                diveSiteId={session.diveTourSession2?.diveSiteId}
                time={session.diveTourSession2?.time}
                isMultiSession={isMultiSession}
                sessionIndex={2}
                config={config}
              />
            )}
            <ClubDiveSessionHeaderTitle
              diveSession={session}
              className={`py-1 border-t ${sessionBorderStyle}`}
              config={config}
            />
          </>
        )}
        <ClubDiveSessionHeaderStatus className={'py-1'} diveSession={session} />
      </div>
    </div>
  );
};
