export const arrayBuilder = {
  bySize,
  filterDuplicated,
  mergeArrays,
  areArraysEquals,
  buildSeries,
  mergeArraysByKey,
  getChunks,
};
function getChunks<T>(array: T[], { chunkSize }: { chunkSize: number }): T[][] {
  const chunks: T[][] = [];

  let i: number;
  const length = array.length;
  for (i = 0; i < length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}

function areArraysEquals<T = number | string>(
  array1: T[],
  array2: T[],
): boolean {
  // from https://stackoverflow.com/questions/7837456/how-to-compare-arrays-in-javascript#19746771
  const array2Sorted = array2.slice().sort();
  return (
    array1.length === array2.length &&
    array1
      .slice()
      .sort()
      .every((value, index) => {
        return value === array2Sorted[index];
      })
  );
}

function buildSeries({
  count,
  first,
}: {
  count: number;
  first: number;
}): any[] {
  if (count > 0) {
    const series = bySize(count).map((x, i) => first + i);
    return series;
  }
  return [];
}
function bySize(count: number): any[] {
  if (count > 0) {
    return Array.apply(null, Array(count));
  }
  return [];
}

function filterDuplicated<T>(
  array: T[],
  { compare }: { compare?: (o1: T, o2: T) => boolean } = { compare: undefined },
): T[] {
  // filter duplicated
  if (compare) {
    return array.reduce((acc, o1) => {
      if (!acc.some((o2) => compare(o1, o2))) {
        acc.push(o1);
      }
      return acc;
    }, [] as T[]);
  }
  return [...new Set(array)];
}

function mergeArrays<T>(
  a: T[],
  b: T[],
  { compare }: { compare: (x: T, y: T) => boolean },
): T[] {
  const biggest = a.length > b.length ? a : b;
  const smallest = (a.length > b.length ? b : a).concat([]);
  if (!smallest.length) {
    return biggest.concat([]);
  }
  return biggest
    .reduce((acc, x) => {
      const matchIndex = smallest.findIndex((y) => compare(x, y));
      if (matchIndex !== -1) {
        const match = smallest.splice(matchIndex, 1)[0];
        acc.push(match);
      } else {
        acc.push(x);
      }
      return acc;
    }, [] as T[])
    .concat(smallest);
}

function mergeArraysByKey<T>(
  arr1: T[],
  arr2: T[],
  compareFn: (item1: T, item2: T) => boolean,
): T[] {
  const newArray = [...arr1]; // Create a copy of the original array

  arr2.forEach((newItem) => {
    const existingIndex = newArray.findIndex((item) =>
      compareFn(item, newItem),
    );

    if (existingIndex !== -1) {
      // Replace the existing item
      newArray[existingIndex] = newItem;
    } else {
      // Append the new item
      newArray.push(newItem);
    }
  });

  return newArray;
}
