import { JsonPatchOperation } from '@mabadive/app-common-model';
import { applyPatch, compare } from 'fast-json-patch';
import { jsonParser } from './jsonParser.service';
import { jsonPatcherNullValueProcessor } from './jsonPatcherNullValueProcessor.service';
import { jsonPatcherReplaceFullyProcessor } from './jsonPatcherReplaceFullyProcessor.service';

export const jsonPatcher = {
  applyPatchOperations,
  compareObjects,
};

function applyPatchOperations<T>(
  original: T,
  patchOperations: JsonPatchOperation[],
  {
    mutateOriginal,
  }: {
    mutateOriginal: boolean;
  } = {
    mutateOriginal: false,
  },
): T {
  try {
    const objectWithDatesAsString = applyPatch(
      original,
      patchOperations,
      false,
      mutateOriginal ?? false, // IMPORTANT, carla valeur par défaut dans applyPatch est "true"
    ).newDocument;

    const result = jsonParser.parseJSONWithDates<T>(
      JSON.stringify(objectWithDatesAsString),
    );

    return result;
  } catch (err) {
    console.error(
      `[${new Date().toISOString()}] Error trying to apply patchOperations`,
      patchOperations,
      original,
    );
    throw err;
  }
}

function compareObjects<T>(
  object1: Partial<T>,
  object2: Partial<T>,
  {
    replaceDeleteByNullValue,
    attributesToIgnore,
    attributesToReplaceFully,
  }: {
    replaceDeleteByNullValue?: boolean;
    attributesToIgnore?: (keyof T)[];
    attributesToReplaceFully?: (keyof T)[];
  },
): JsonPatchOperation[] {
  // console.log('[jsonPatcher.compareObjects] object1:', object1);
  // console.log('[jsonPatcher.compareObjects] object2:', object2);

  const tree1 = cleanBeforeCompare(
    withoutAttributes(object1, attributesToIgnore),
  );
  const tree2 = cleanBeforeCompare(
    withoutAttributes(object2, attributesToIgnore),
  );

  const patches: JsonPatchOperation[] = compare(tree1, tree2);

  // console.log('[jsonPatcher.compareObjects] patches:', patches);

  const patches2 = jsonPatcherNullValueProcessor.applyReplaceDeleteByNullValue({
    replaceDeleteByNullValue,
    patches,
  });

  return jsonPatcherReplaceFullyProcessor
    .applyReplaceFully({
      patches: patches2,
      attributesToReplaceFully,
      object2,
    })
    .filter(
      // on supprime les ajout de sous-attributs nulls
      (x) => !(x.op === 'add' && (x.value === null || x.value === undefined)),
    );
}

function withoutAttributes<T>(
  item: Partial<T>,
  attributesToIgnore?: (keyof T)[],
): Partial<T> {
  return !item
    ? {}
    : !attributesToIgnore
    ? item
    : Object.keys(item).reduce((acc, attr) => {
        const key = attr as keyof T;
        if (attributesToIgnore.indexOf(key) === -1) {
          acc[key] = item[key];
        }
        return acc;
      }, {} as Partial<T>);
}

function cleanBeforeCompare<T>(item: T): any {
  // convert Date => timestamp
  try {
    return !item ? {} : JSON.parse(JSON.stringify(item));
  } catch (err) {
    console.error('[jsonPatcher.cleanBeforeCompare] JSON error', err);
    return item;
  }
}
