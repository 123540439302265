/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';
import { AppButton, AppIconsUI } from 'src/business/_core/modules/layout';
import { DiveCenterBookingMemberEditPanelLocalState } from './useDiveCenterBookingMemberEditPanelLocalState';

export const BookingCheckoutEditMembersPanelPreviousOrCancelButton: FC<{
  localState: DiveCenterBookingMemberEditPanelLocalState;
}> = ({ localState }) => {
  const { viewState, form, actions, editableParticipantsCount } = localState;

  return (
    <div className="flex gap-x-2 sm:gap-x-4 gap-y-2 flex-wrap">
      {viewState.participantsIndex !== 0 && editableParticipantsCount > 3 && (
        <AppButton
          type="button"
          className="uppercase font-bold"
          disabled={!form.formState.isValid}
          size="normal"
          icon={AppIconsUI.navLeftFast}
          color={'danger-outline-light'}
          onClick={() => {
            actions.firstParticipant();
          }}
        >
          <div className="hidden sm:block">{'Premier'}</div>
        </AppButton>
      )}
      <AppButton
        type="button"
        className={'uppercase font-bold'}
        disabled={!form.formState.isValid}
        size="normal"
        icon={AppIconsUI.navLeft}
        color="primary-outline-light"
        onClick={() => {
          actions.previousParticipant();
        }}
      >
        {viewState.participantsIndex === 0 ? (
          <>{'Retour'}</>
        ) : (
          <>
            <span className="sm:hidden">{'Préc'}</span>
            <span className="hidden sm:block">{'Précédent'}</span>
          </>
        )}
      </AppButton>
    </div>
  );
};
