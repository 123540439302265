import { StackLogLevel } from '@mabadive/stack-log-core';
import { StackLogWebAppenderOptions } from '../../main/models/stack-log-web-appender-options.model';


export interface SentryLogMessage {
  level: StackLogLevel;
  message: string;
  options: StackLogWebAppenderOptions;
}

export interface SentryBreadcrumbMessage {
  level: StackLogLevel;
  message: string;
  options: StackLogWebAppenderOptions;
}

class WebSentryFormatter {

  constructor() {
  }

  formatLogMessage(logForm: SentryLogMessage): string {

    const options = logForm.options;
    const context = options.context;

    const modulePrefix = context.module ? `[${context.module}]` : '';
    const filenamePrefix = context.filename ? `[${context.filename}]` : '';
    const methodPrefix = context.method ? `[.${context.method}()]` : '';

    const contextString = `${modulePrefix}${filenamePrefix}${methodPrefix}`;
    const messageString = logForm.message;

    return `${messageString} at ${contextString}`;
  }

  formatBreadcrumbMessage(logForm: SentryBreadcrumbMessage): string {

    const options = logForm.options;
    const context = options.context;
    const processIdPrefix = context.process ? `[${context.process.processId}]` : '';
    const modulePrefix = context.module ? `[${context.module}]` : '';
    const filenamePrefix = context.filename ? `[${context.filename}]` : '';
    const methodPrefix = context.method ? `[.${context.method}()]` : '';

    const contextString = `${processIdPrefix}${modulePrefix}${filenamePrefix}${methodPrefix}`;
    const messageString = logForm.message;

    return `${messageString} at ${contextString}`;
  }
}

export const stackLogWebSentryFormatter = new WebSentryFormatter();