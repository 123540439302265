import { StackLogLevel } from '../model/stack-logger-level.type';
import { StackLogConfigCustomRule } from './stack-log-config-custom-rule.model';
import { StackLogSeverityConfig } from './stack-log-severity-config.model';

class StackLogAppRuleBuilder {
  public buildRules(
    config: StackLogSeverityConfig,
  ): StackLogConfigCustomRule[] {
    let rules: StackLogConfigCustomRule[] = [];
    if (config.appRules) {
      Object.keys(config.appRules).forEach((appName) => {
        const appConfig = config.appRules[appName];
        if (appConfig) {
          rules = rules.concat(this.buildAppRules(appConfig, appName));
        }
      });
    }
    // console.log('rules:', JSON.stringify(rules, undefined, 2))
    return rules;
  }

  private buildAppRules(
    appConfig:
      | StackLogLevel
      | {
          level: StackLogLevel;
          modules?: { [moduleId: string]: StackLogLevel };
        },
    appName: string,
  ): StackLogConfigCustomRule[] {
    const appRules: StackLogConfigCustomRule[] = [];
    if ((appConfig as any)['level'] as StackLogLevel) {
      const appLogLevel = (appConfig as any)['level'] as StackLogLevel;
      appRules.push(this.buildAppRuleObject(appName, appLogLevel));
      const appModulesConfig: any = (appConfig as any)['modules'];
      if (appModulesConfig) {
        Object.keys(appModulesConfig).forEach((moduleName) => {
          const moduleConfig = appModulesConfig[moduleName];
          if (moduleConfig) {
            const moduleLogLevel = moduleConfig as StackLogLevel;
            appRules.push(
              this.buildAppModuleRuleObject(
                appName,
                moduleName,
                moduleLogLevel,
              ),
            );
          }
        });
      }
    } else {
      const appLogLevel = appConfig as StackLogLevel;
      appRules.push(this.buildAppRuleObject(appName, appLogLevel));
    }
    return appRules;
  }

  private buildAppRuleObject(
    appName: string,
    logLevel: StackLogLevel,
  ): StackLogConfigCustomRule {
    return {
      logLevel,
      matchers: [
        {
          app: appName,
        },
      ],
    };
  }

  private buildAppModuleRuleObject(
    appName: string,
    moduleName: string,
    logLevel: StackLogLevel,
  ): StackLogConfigCustomRule {
    return {
      logLevel,
      matchers: [
        {
          app: appName,
          module: moduleName,
        },
      ],
    };
  }
}

export const stackLogAppRuleBuilder = new StackLogAppRuleBuilder();
