// ISO 3166-1 codes - https://github.com/umpirsky/country-list

import { COUNTRIES_LABELS_BY_CODE } from '@mabadive/app-common-model';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

const FR: ValueLabel<string, string> = {
  value: 'FR',
  label: 'France',
};

// FR: 'France' is added twice (also in TOP)
export const COUNTRY_OPTIONS: ValueLabel<string, string>[] = [FR].concat(
  Object.keys(COUNTRIES_LABELS_BY_CODE).map((key) => {
    const o: ValueLabel<string, string> = {
      value: key,
      label: (COUNTRIES_LABELS_BY_CODE as any)[key as any] as any,
    };
    return o;
  }),
);
