export const diverLinkParametersToReplaceRenderer = {
  renderTemplate,
};
function renderTemplate({
  template,
  paramsToReplace,
}: {
  template: string;
  paramsToReplace: { [key: string]: string };
}) {
  if (!template) {
    return template;
  }
  const extraParamsToReplace: { [key: string]: string } = {};

  Object.keys(paramsToReplace).forEach((key) => {
    if (paramsToReplace[key] === '') {
      // on ignore le paramètre, et on supprimé l'éventuel espace précédent
      extraParamsToReplace[key] = '';
    }
  });
  return replaceCore({
    paramsToReplace,
    template: replaceCore({
      paramsToReplace: extraParamsToReplace,
      template,
      replacePrefix: ' ',
    }),
  });
}
function replaceCore({
  paramsToReplace,
  template,
  replacePrefix = '',
}: {
  paramsToReplace: { [key: string]: string };
  template: string;
  replacePrefix?: string;
}) {
  return Object.keys(paramsToReplace).reduce((acc, key) => {
    if (!acc) {
      return acc;
    }
    const newValue = ((paramsToReplace as any)[key] as unknown as string) ?? '';
    return acc.replace(new RegExp(`${replacePrefix}${key}`, 'g'), newValue);
  }, template);
}
