import {
  BookingGroup,
  OnlineBookingContact,
  OnlineBookingDetails,
  OnlineBookingOrderArticle,
  OnlineBookingParticipant,
} from '@mabadive/app-common-model';
import { jsonParser } from '@mabadive/app-common-services';
import React, {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAppRouter } from '../../../../_core/data/hooks/useAppRouter';
import { appWebConfig } from '../../../../_core/modules/root/config';
import { appLogger } from '../../../../_core/modules/root/logger';
import { diveCenterBookingUrlBuilder } from '../../../BO-booking/diveCenterBookingUrlBuilder.service';
import { DiveCenterShoppingCartState } from '../_model';
import { useUserActivityTimeout } from './useUserActivityTimeout.hook';

const isDevEnv = appWebConfig.envId === 'dev';

const isDevEnvTestTimeout = false; // timeout très court, pour tester en local

// TIMEOUT 1: temps total depuis l'ajout du premier produit dans le panier
const shoppingCartExpirationTimeOutInSeconds = isDevEnv
  ? isDevEnvTestTimeout
    ? 60 * 1 // 1 mn // très court, pour tester les timeout
    : 60 * 60 * 48 // 48 heures // très long, pour être à l'aise en développement
  : 60 * 20; // 20 minutes

// TIMEOUT 2: temps d'inactivité de l'utilisateur
const shoppingCartInactivityTimeOutInSeconds = isDevEnv
  ? isDevEnvTestTimeout
    ? 60 * 0.5 // 30 secondes // très court, pour tester les timeout
    : 60 * 60 * 48 // 48 heures // très long, pour être à l'aise en développement
  : 60 * 5; // 5 minutes

type DiveCenterShoppingCartContext_ClearCartFn = ({
  cause,
}: {
  cause: string;
}) => void;

type DiveCenterShoppingCartContext_AddOrderArticleFn = ({
  orderArticle,
  bookingParticipants,
}: {
  orderArticle: OnlineBookingOrderArticle;
  bookingParticipants: OnlineBookingParticipant[];
}) => void;

type DiveCenterShoppingCartContext_UpdateBookingParticipantsFn = ({
  bookingParticipants,
  bookingContact,
  bookingGroup,
  comment,
}: {
  bookingParticipants: OnlineBookingParticipant[];
  bookingContact: OnlineBookingContact;
  bookingGroup: BookingGroup;
  comment: string;
}) => void;

export type DiveCenterShoppingCartContextActions = {
  updateBookingParticipants: DiveCenterShoppingCartContext_UpdateBookingParticipantsFn;
  clearCart: DiveCenterShoppingCartContext_ClearCartFn;
  addOrderArticle: DiveCenterShoppingCartContext_AddOrderArticleFn;
  removeFromCart: (itemId: string) => void;
};
export type DiveCenterShoppingCartContextProps = {
  cart: DiveCenterShoppingCartState;
  remainingTime: {
    hours: number;
    minutes: number;
    seconds: number;
  };
  actions: DiveCenterShoppingCartContextActions;
};

const CURRENT_DATA_FORMAT_VERSION = 1;
export const DIVE_CENTER_SHOPPING_CART_STATE_EMPTY: DiveCenterShoppingCartState =
  {
    dataFormatVersion: CURRENT_DATA_FORMAT_VERSION,
    orderArticles: [],
    actionSteps: [],
    consentDetails: {
      customerConsents: [],
    },
    bookingDetails: undefined,
    persistedOnlineBooking: undefined,
  };

export const DiveCenterShoppingCartContext =
  createContext<DiveCenterShoppingCartContextProps | undefined>(undefined);

export const DiveCenterShoppingCartProvider = ({
  clubPublicReference,
  children,
}: {
  clubPublicReference: string;
  children: ReactNode;
}) => {
  const localStorageKey = `dive-center-${clubPublicReference}-shopping-cart`;

  const initialStateFromLocalForage: DiveCenterShoppingCartState =
    useInitialStateFromLocalForage({ clubPublicReference, localStorageKey });

  const appRouter = useAppRouter();

  const [state, setState] = useState<DiveCenterShoppingCartState>(
    initialStateFromLocalForage,
  );

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(state));
  }, [state, localStorageKey]);

  const addOrderArticle: DiveCenterShoppingCartContext_AddOrderArticleFn =
    useCallback(
      ({
        orderArticle,
        bookingParticipants: orderArticleParticipants,
      }: {
        orderArticle: OnlineBookingOrderArticle;
        bookingParticipants: OnlineBookingParticipant[];
      }) => {
        // merge previous and new participants
        const existingParticipants: OnlineBookingParticipant[] = (
          state.bookingDetails?.bookingParticipants ?? []
        ).map((p) => {
          // NOTE: pour le moment on ne peut pas ré-utiliser un participant, donc ce cas ne doit pas arriver,
          // mais on va devoir le supporter rapidement pour pouvoir commander depuis un client existant
          const orderArticleParticipant = orderArticleParticipants.find(
            (oap) => oap.diver?._id === p.diver?._id,
          );
          if (orderArticleParticipant) {
            // mise à jour du participant existant
            return orderArticleParticipant;
          } else {
            return p;
          }
        });
        const existingParticipantsDiverIds = existingParticipants.map(
          (p) => p.diver?._id,
        );
        const newParticipants = orderArticleParticipants.filter(
          (oap) =>
            existingParticipantsDiverIds.includes(oap.diver?._id) === false,
        );
        const bookingParticipants =
          existingParticipants.concat(newParticipants);

        const now = new Date();
        setState({
          ...state,
          firstChangeTimestamp: state.firstChangeTimestamp ?? now,
          lastChangeTimestamp: now,
          orderArticles: [...state.orderArticles, orderArticle],
          actionSteps: [
            ...state.actionSteps,
            {
              date: new Date(),
              label: `Confirm quantity ${orderArticle.categoryId}/${orderArticle.productId}`,
              meta: { orderArticle },
            },
          ],
          bookingDetails: {
            ...(state.bookingDetails ?? ({} as OnlineBookingDetails)),
            bookingParticipants,
          },
        });
      },
      [state],
    );

  const updateBookingParticipants: DiveCenterShoppingCartContext_UpdateBookingParticipantsFn =
    useCallback(
      ({
        bookingParticipants,
        bookingContact,
        bookingGroup,
        comment,
      }: {
        bookingParticipants: OnlineBookingParticipant[];
        bookingContact: OnlineBookingContact;
        bookingGroup: BookingGroup;
        comment: string;
      }) => {
        const now = new Date();

        const bookingDetails: OnlineBookingDetails = {
          ...(state.bookingDetails ?? ({} as OnlineBookingDetails)),
          bookingContact: bookingContact,
          bookingGroup: bookingGroup,
          comment: comment,
          bookingParticipants,
        };

        setState({
          ...state,
          firstChangeTimestamp: state.firstChangeTimestamp ?? now,
          lastChangeTimestamp: now,
          bookingDetails,
          actionSteps: [
            ...state.actionSteps,
            {
              date: new Date(),
              label: 'Update booking participants',
              meta: { bookingDetails },
            },
          ],
        });
      },
      [state],
    );

  const removeFromCart = (itemId: string) => {
    // dispatch({ type: 'REMOVE_FROM_CART', itemId });
  };

  const clearCart: DiveCenterShoppingCartContext_ClearCartFn = useCallback(
    ({ cause }: { cause: string }) => {
      if (state.actionSteps.length !== 0 || !!state.firstChangeTimestamp) {
        appLogger.warn(
          `[DiveCenterShoppingCartProvider] clear cart (cause: ${cause})`,
        );
        setState({
          ...DIVE_CENTER_SHOPPING_CART_STATE_EMPTY,
        });
      }
    },
    [state.actionSteps.length, state.firstChangeTimestamp],
  );

  const isEmptyShoppingCart =
    (state.bookingDetails?.bookingParticipants?.length ?? 0) +
      (state.orderArticles?.length ?? 0) ===
    0;

  const expireShoppingCartAndRedirect = useCallback(
    ({ cause }: { cause: string }) => {
      clearCart({
        cause,
      });
      appRouter.navigate(
        // TODO passer un paramètre dans l'URL pour afficher un message comme quoi ça a expiré
        diveCenterBookingUrlBuilder.buildDiveCenterShoppingCartUrl({
          clubPublicReference,
        }),
        {
          cause,
        },
      );
    },
    [appRouter, clearCart, clubPublicReference],
  );

  const timeoutHandler = useUserActivityTimeout({
    timerId: 'shopping-cart-expiration',
    timeOutInMinutes: shoppingCartInactivityTimeOutInSeconds,
    // désactivé tant que le panier est vide
    disabled: isEmptyShoppingCart,
    onTimeout: () => {
      expireShoppingCartAndRedirect({
        cause: `Cart expired after ${shoppingCartInactivityTimeOutInSeconds}s of inactivity`,
      });
    },
  });

  const remainingActivityTimeInSeconds =
    timeoutHandler.useRemainingTimeInSeconds({
      periodInSeconds: 1,
    });
  const timeEllapsedInMsSinceFirstChange =
    useEllapsedTimeSinceFirstChangeInMsInner({
      firstChangeTimestamp: state.firstChangeTimestamp,
      periodInSeconds: 1,
    });

  const remainingAgeTimeInSeconds =
    timeEllapsedInMsSinceFirstChange > 0
      ? shoppingCartExpirationTimeOutInSeconds -
        Math.round(timeEllapsedInMsSinceFirstChange / 1000)
      : undefined;

  const minimumRemainingTimeInSeconds =
    timeEllapsedInMsSinceFirstChange > 0
      ? Math.min(remainingAgeTimeInSeconds, remainingActivityTimeInSeconds)
      : remainingActivityTimeInSeconds;

  useEffect(() => {
    if (!isEmptyShoppingCart && remainingAgeTimeInSeconds <= 0) {
      expireShoppingCartAndRedirect({
        cause: `Cart expired after ${shoppingCartExpirationTimeOutInSeconds}s of age`,
      });
    }
  }, [
    appRouter,
    clearCart,
    clubPublicReference,
    expireShoppingCartAndRedirect,
    isEmptyShoppingCart,
    remainingAgeTimeInSeconds,
  ]);

  const remainingTime = {
    seconds: minimumRemainingTimeInSeconds,
    minutes: Math.ceil(minimumRemainingTimeInSeconds / 60),
    hours: Math.ceil(minimumRemainingTimeInSeconds / 3600),
  };
  const actions: DiveCenterShoppingCartContextActions = {
    clearCart,
    removeFromCart,
    addOrderArticle,
    updateBookingParticipants,
  };
  return (
    <DiveCenterShoppingCartContext.Provider
      value={{
        cart: state,
        remainingTime,
        actions,
      }}
    >
      {children}
    </DiveCenterShoppingCartContext.Provider>
  );
};

function useInitialStateFromLocalForage({
  clubPublicReference,
  localStorageKey,
}: {
  clubPublicReference: string;
  localStorageKey: string;
}): DiveCenterShoppingCartState {
  return useMemo(() => {
    if (clubPublicReference) {
      const localStorageCartRaw = localStorage.getItem(localStorageKey);
      if (localStorageCartRaw) {
        try {
          const localStorageCart =
            jsonParser.parseJSONWithDates<DiveCenterShoppingCartState>(
              localStorageCartRaw,
            );
          if (
            localStorageCart.dataFormatVersion === CURRENT_DATA_FORMAT_VERSION
          ) {
            return localStorageCart;
          } else {
            appLogger.warn(
              `Invalid shopping cart version ${localStorageCart.dataFormatVersion} !== ${CURRENT_DATA_FORMAT_VERSION} from local storage key "${localStorageKey}"`,
            );
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
          appLogger.error(
            `Error parsing shopping cart from local storage key "${localStorageKey}"`,
          );
        }
      }
    }
    return {
      ...DIVE_CENTER_SHOPPING_CART_STATE_EMPTY,
    };
  }, [clubPublicReference, localStorageKey]);
}

const useEllapsedTimeSinceFirstChangeInMsInner = ({
  firstChangeTimestamp,
  periodInSeconds,
}: {
  firstChangeTimestamp: Date;
  periodInSeconds: number;
}) => {
  const [ellapsedTimeInMs, setEllapsedTimeInMs] =
    useState<number | undefined>(undefined);

  useEffect(() => {
    // Définir une fonction pour obtenir le temps restant
    const getEllapsedTime = () => {
      if (firstChangeTimestamp) {
        const ellapsedTime =
          new Date().getTime() - firstChangeTimestamp.getTime();
        setEllapsedTimeInMs(ellapsedTime);
      }
    };

    // Exécuter la fonction toutes les N secondes
    const intervalId = setInterval(getEllapsedTime, periodInSeconds * 1000);

    // Nettoyer l'intervalle lorsque le hook est démonté
    return () => clearInterval(intervalId);
  }, [firstChangeTimestamp, periodInSeconds]); // Recréer l'effet si idleTimer change

  return ellapsedTimeInMs;
};
