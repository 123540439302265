import { ClubDiverFakeName } from '../../../../app-common-model/src';
import { numberFormatter } from './numberFormatter.service';
import { stringFormatter } from './stringFormatter.service';

export const nameFormatter = {
  formatFullName,
  formatFullNameChunks,
  formatFirstName,
  formatLastName,
  formatInitials,
};

function formatFullName(
  person: {
    firstName: string;
    lastName: string;
    fakeName?: ClubDiverFakeName;
  },
  { format }: { format: 'lastName-first' | 'firstName-first' } = {
    format: 'lastName-first',
  },
): string {
  const { firstName, lastName } = formatFullNameChunks(person);
  return person
    ? format === 'lastName-first'
      ? `${lastName} ${firstName}`.trim()
      : `${firstName} ${lastName}`.trim()
    : '';
}
function formatFullNameChunks(person: {
  firstName: string;
  lastName: string;
  fakeName?: ClubDiverFakeName;
}): {
  firstName: string;
  lastName: string;
} {
  if (!person) {
    person = {
      firstName: undefined,
      lastName: undefined,
    };
  }
  if (
    !person?.firstName?.trim()?.length &&
    !person?.lastName?.trim()?.length &&
    !!person?.fakeName
  ) {
    person = {
      firstName: buildFakeFirstName(person?.fakeName),
      lastName: person?.fakeName?.baseName,
    };
  }
  return {
    lastName: formatLastName(person),
    firstName: formatFirstName(person),
  };
}

function buildFakeFirstName(fakeName?: ClubDiverFakeName): string {
  if (fakeName?.memberIndex > 0) {
    return `N°${numberFormatter.toFixedIntegerString(
      fakeName?.memberIndex,
      2,
    )}`;
  }
  return '';
}

function formatFirstName(person: {
  firstName: string;
  fakeName?: ClubDiverFakeName;
}) {
  return person && person.firstName
    ? stringFormatter.toUpperCaseFirstEveryWord(person.firstName)
    : person?.fakeName
    ? buildFakeFirstName(person?.fakeName)
    : '';
}

function formatLastName(person: { lastName: string }): string {
  return person && person.lastName ? person.lastName.toUpperCase() : '';
}
function formatInitials(person: {
  firstName?: string;
  lastName?: string;
  fakeName?: ClubDiverFakeName;
}): string {
  const firstNameInitials = getNamesInitials(person.firstName);
  const lastNameInitials = getNamesInitials(person.lastName);

  if (firstNameInitials.length > 0) {
    // prénom seulement
    return firstNameInitials;
  } else if (lastNameInitials.length > 0) {
    // nom seulement
    return lastNameInitials;
  } else {
    // fake name
    return `N°${numberFormatter.toFixedIntegerString(
      person.fakeName.memberIndex,
      2,
    )}`;
  }
}

function getNamesInitials(names: string) {
  // Étape 1 : Préparer les tableaux des mots pour le nom ou prénom
  const chunks = (names?.trim()?.toLowerCase() ?? '')
    .replace('-', ' ')
    .split(/\s+/)
    .filter((x) => x?.length > 0);

  // Étape 2 : Nettoyer les mots du prénom et du nom
  const ignoredWords = ['de', 'du', 'le', 'la'];

  // Supprimer les mots ignorés, sauf si c'est le seul mot
  let words = chunks.filter((word) => !ignoredWords.includes(word));
  if (words.length === 0) {
    words = chunks; // Si tous les mots sont ignorés, on garde le mot ignoré
  }

  // Étape 3 : Appliquer les règles de formatage

  let initials = '';

  if (words.length > 0) {
    // PRENOM 1
    initials += words[0][0].toUpperCase();
    if (words.length > 1) {
      // PRENOM 2
      initials += words[1][0].toUpperCase();
    } else if (words[0].length > 1) {
      // PRENOM 1 (2ème lettre)
      initials += words[0][1].toLowerCase();
      if (words[0].length > 2) {
        // PRENOM 1 (3ème lettre)
        initials += words[0][2].toLowerCase();
      }
    }
  }
  return initials;
}
