import { search } from '@mabadive/app-common-services';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { appSingleAutocompleteChunkify } from './appSingleAutocompleteChunkify';
import { AppSingleAutocompleteProps } from './AppSingleAutocompleteProps.model';
import { SingleAutocomplete } from './SingleAutocomplete';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

function filterSuggestions<S>(
  suggestions: S[],
  {
    searchText,
    filterAttributes,
    filterMaxResults,
  }: {
    searchText: string;
    filterAttributes: (keyof S)[];
    filterMaxResults?: number;
  },
): S[] {
  const maxResults =
    filterMaxResults && filterMaxResults > 0 ? filterMaxResults : 50;

  if (!searchText || !searchText.trim().length) {
    return suggestions.slice(0, maxResults);
  }

  return search.filter(suggestions, {
    searchText,
    attributesNames: filterAttributes,
    maxResults: maxResults,
    sortResultsByBestMatch: true,
  });
}

export function AppSingleAutocomplete<S>({
  suggestions,
  defaultValue,
  filterAttributes,
  filterMaxResults,
  getSuggestionDisplayText,
  getSuggestionHoverText,
  renderSuggestionListItem,
  onChange,
  name,
  fullWidth,
  identifySuggestion,
  autoFocus,
  openOnFocus,
  disabled,
  required,
  type,
  placeholder,
}: AppSingleAutocompleteProps<S>) {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      {/* <SingleAutocomplete2 getSuggestions={getSuggestions} /> */}
      <SingleAutocomplete
        autoSelect={true}
        name={name}
        fullWidth={fullWidth}
        identifySuggestion={identifySuggestion}
        autoFocus={autoFocus}
        openOnFocus={openOnFocus}
        disabled={disabled}
        required={required}
        type={type}
        placeholder={placeholder}
        getSuggestions={(searchText) =>
          filterSuggestions(suggestions, {
            searchText,
            filterAttributes,
            filterMaxResults,
          })
        }
        getSuggestionInputLabel={getSuggestionDisplayText}
        getSuggestionPlaceholder={getSuggestionHoverText}
        getSuggestionListItem={(suggestion) => (searchText) => {
          const displayText = getSuggestionDisplayText(suggestion);

          return renderSuggestionListItem
            ? renderSuggestionListItem({ suggestion, searchText, displayText })
            : defaultRenderSuggestionListItem({
                suggestion,
                searchText,
                displayText,
              });
        }}
        defaultValue={defaultValue}
        onChange={onChange}
      />
    </div>
  );

  function defaultRenderSuggestionListItem({
    suggestion,
    searchText,
    displayText,
  }: {
    suggestion: S;
    searchText: string;
    displayText: string;
  }): React.ReactNode {
    return <>{appSingleAutocompleteChunkify(displayText, searchText)}</>;
  }
}
