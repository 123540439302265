import { useEffect, useMemo, useState } from 'react';
import { BehaviorSubject, NEVER, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { rxjsUtilWeb } from 'src/lib/reactive';
import { Loadable } from './Loadable.model';

const DEBUG_NAME_TO_LOG = 'useAppRoot';

export function useLoadable<T>(
  options: {
    load: () => Observable<T>;
    debugName: string;
  } & (
    | {
        initialValue?: T;
      }
    | {
        initialValueFromLoadSnapshot?: boolean;
      }
  ),
  dependencies: any[] = [], // TODO rendre ce champ obligatoire
): T & Loadable {
  const { load, debugName } = options;
  try {
    const { stop, stopOrContinue$ } = useMemo(() => {
      const stop$ = new BehaviorSubject(false);

      const stopOrContinue$ = stop$.pipe(
        distinctUntilChanged(),
        switchMap((stop) => (stop ? NEVER : of(true))),
      );

      function stop() {
        stop$.next(true);
      }

      return { stop, stopOrContinue$ };
    }, []);

    const initialValue = useMemo(() => {
      if ((options as any).initialValueFromLoadSnapshot) {
        return rxjsUtilWeb.getSnapshot(
          load().pipe(
            catchError((err) => {
              appLogger.warn(
                `[useLoadable] ${
                  debugName ? `[${debugName}]` : ''
                } error loading snapshot data`,
                err,
              );
              return NEVER;
            }),
          ),
        );
      }
      return (options as any).initialValue;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);

    appLogger.debug(
      `[useLoadable] ${
        debugName ? `[${debugName}]` : ''
      } initialValue: ${initialValue}`,
    );

    const [value, setValue] = useState<T & Loadable>(
      buildLoadableState<T>(initialValue, stop),
    );

    useEffect(() => {
      const subscription = stopOrContinue$
        .pipe(
          map(() => new Date()),
          tap((startDate) => {
            if (debugName === DEBUG_NAME_TO_LOG) {
              appLogger.warn(
                `[useLoadable] ${debugName ? `[${debugName}]` : ''} startDate:`,
                startDate,
              );
            }
          }),
          switchMap((startDate) =>
            load().pipe(
              tap((value) => {
                if (debugName === DEBUG_NAME_TO_LOG) {
                  appLogger.warn(
                    `[useLoadable] ${debugName ? `[${debugName}]` : ''} value:`,
                    value,
                  );
                }
              }),
              map((value) => ({
                startDate,
                value,
              })),
              catchError((err) => {
                appLogger.warn(
                  `[useLoadable] ${debugName ? `[${debugName}]` : ''} err:`,
                  err,
                );
                console.error(err);
                return throwError(err);
              }),
            ),
          ),
          tap(({ startDate, value }) => {
            // const duration = new Date().getTime() - startDate.getTime();
            // if (duration > 50 && duration < 500) {
            //   appLogger.warn(`[useLoadable] ${debugName ? `[${debugName}]` : ''} loading duration: ${duration}ms`);
            // }
            if (debugName === DEBUG_NAME_TO_LOG) {
              appLogger.warn(
                `[useLoadable] ${debugName ? `[${debugName}]` : ''} value:`,
                value,
              );
            }
          }),
          distinctUntilChanged(),
          startWith({
            startDate: new Date(),
            value: initialValue,
          }),
          map(({ startDate, value }) =>
            buildLoadableState<T>(value, stop, startDate),
          ),
          catchError((err) => {
            appLogger.warn(
              `[useLoadable] ${debugName ? `[${debugName}]` : ''} err 2:`,
              err,
            );
            console.error(err);
            return throwError(err);
          }),
        )
        .subscribe(
          (newValue) => {
            if (debugName === DEBUG_NAME_TO_LOG) {
              appLogger.warn(
                `[useLoadable] [${debugName}] new value:`,
                newValue,
              );
            }
            setValue(newValue);
          },
          (err) => {
            appLogger.warn(
              `[useLoadable] ${
                debugName ? `[${debugName}]` : ''
              } error loading data`,
              err,
            );
            setValue({
              loaded: false,
              _loaded: false,
              _error: true,
            } as T & Loadable);
          },
        );

      return () => {
        if (debugName === DEBUG_NAME_TO_LOG) {
          appLogger.warn(
            `[useLoadable] [${debugName}] UNSUSCRIBE`,
            subscription,
          );
        }
        subscription.unsubscribe();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);

    return value;
  } catch (err) {
    console.warn(`[useLoadable] [${debugName}] error`);
    console.error(err);
    throw err;
  }
}

function buildValue<T>(value?: T): T {
  if (value !== undefined && value !== null) {
    return value;
  }
  // FIXME si c'est un array, il faut renvoyer un array non?
  return {} as T;
}

function buildLoadableState<T>(
  value: T,
  stop: () => void,
  startDate?: Date,
): T & Loadable {
  // appLogger.log('buildLoadableState:', value)
  const loaded = value !== undefined && value !== null;
  const endDate = new Date();
  const durationInMs = startDate
    ? endDate.getTime() - startDate.getTime()
    : undefined;
  return {
    ...buildValue(value),
    loaded,
    _loaded: loaded,
    _error: false,
    stop,
    startDate,
    endDate,
    durationInMs,
  };
}
