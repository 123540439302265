"use strict";
exports.__esModule = true;
exports.DEFAULT_CLUB_SETTINGS_CUSTOMER_SPACE = exports.buildDefaultRGPDAgreementMessage = void 0;
var dive_1 = require("../../../../dive");
var defaultBookingConfig = {
    customerUi: {
        enableCustomerUpdate: false,
        messageToDisplay: undefined
    },
    pendingList: {
        diversContactInfo: true,
        diversDivingInfo: true,
        diversEquipmentInfo: true,
        diversPostalAddressInfo: false,
        diversExpectedDiveInfo: false
    }
};
var display = {
    participants: {
        enabled: true
    },
    divesFuture: {
        enabled: true,
        config: {
            showDate: true,
            showTime: true,
            showBoat: true,
            showDiveSite: true
        }
    },
    divesPast: {
        enabled: true,
        config: {
            showDate: true,
            showTime: true,
            showBoat: true,
            showDiveSite: true
        }
    }
};
var displayFields = {
    diversContactInfo: {
        firstName: {
            edit: true
        },
        lastName: {
            edit: true
        },
        nickName: {
            edit: false
        },
        profilePhoto: {
            edit: true
        },
        phoneNumber: {
            edit: true
        },
        emailAddress: {
            edit: true
        },
        birthdate: {
            edit: true
        },
        languageCode: {
            edit: true
        },
        emergencyContact: {
            edit: true
        }
    },
    diversPostalAddressInfo: {
        line1: {
            edit: true
        },
        postalCode: {
            edit: true
        },
        city: {
            edit: true
        },
        state: {
            edit: true
        },
        country: {
            edit: true
        }
    },
    diversDivingInfo: {
        hasCertification: {
            edit: true
        },
        mainCertificationReference: {
            edit: true
        },
        mainCertificationDoc: {
            edit: true
        },
        deepDiver: {
            edit: true
        },
        gazCertificationReference: {
            edit: true
        },
        gazCertificationDoc: {
            edit: true
        },
        orgReference: {
            edit: true
        },
        lastDiveDate: {
            edit: true
        },
        totalDivesCount: {
            edit: true
        },
        medicalCertificateDate: {
            edit: true
        },
        medicalCertificateDoc: {
            edit: true
        }
    },
    diversEquipmentInfo: {},
    diversExpectedDiveInfo: {
        expectedDiveModesBeginner: dive_1.DIVE_MODE_BEGINNER,
        expectedDiveModesWithCertification: dive_1.DIVE_MODE_WITH_CERTIFICATION,
        comment: {
            edit: true
        }
    }
};
function buildDefaultRGPDAgreementMessage(_a) {
    var brandFullName = _a.brandFullName, contactEmailAddress = _a.contactEmailAddress;
    var message = "\n      Les donn\u00E9es collect\u00E9es ont \u00E9t\u00E9 recueillies \u00E0 des fins d\u2019inscription et de participation aux activit\u00E9s propos\u00E9es par ".concat(brandFullName, ".\n\n      Conform\u00E9ment au RGPD, je peux exercer un droit d\u2019opposition, de rectification et d\u2019effacement des donn\u00E9es me concernant en contactant ").concat(brandFullName, " \u00E0 ").concat(contactEmailAddress, ".\n\n      En cas de violation du RGPD, j\u2019ai le droit d\u2019introduire une r\u00E9clamation aupr\u00E8s de la CNIL.\n    ");
    return message;
}
exports.buildDefaultRGPDAgreementMessage = buildDefaultRGPDAgreementMessage;
exports.DEFAULT_CLUB_SETTINGS_CUSTOMER_SPACE = {
    enabled: true,
    defaultBookingConfig: defaultBookingConfig,
    consents: {
        gdpr: {
            type: 'gdpr',
            enabled: true,
            customize: false,
            consentAgreement: undefined
        }
    },
    features: {
        insurance: {
            enabled: false,
            customize: false,
            message: undefined
        },
        newsletter: {
            enabled: false,
            customize: false,
            message: undefined
        }
    },
    display: display,
    displayFields: displayFields,
    newAccounts: {
        enabled: false
    }
};
