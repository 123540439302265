/* eslint-disable @typescript-eslint/no-unused-vars */
import { EcommerceProductArticleGql_Customer } from '@mabadive/app-common-model';
import React from 'react';

export const CustomerProductsPage_ProductArticleParticipantsCount = ({
  className,
  participantsCount,
  productArticle,
}: {
  participantsCount: number;
  productArticle: EcommerceProductArticleGql_Customer;

  className?: string;
}) => {
  const min = participantsCount * productArticle?.details?.minParticipantsCount;
  const max = participantsCount * productArticle?.details?.maxParticipantsCount;

  return (
    <span className={className}>
      {min === max ? (
        <>{max} participants</>
      ) : (
        <>
          {min}-{max} participants
        </>
      )}
    </span>
  );
};
