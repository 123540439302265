import { DiveSessionResumeFull } from '@mabadive/app-common-model';

export const diveSessionResumeFullGqlTransformer = {
  transformResult,
};

// on renseigne participant.booking + bookingJourney à partir de bookingSessionParticipant
// TODO: revoir le fetch et le model pour n'avoir qu'un objet participant qui contient tout
function transformResult(diveSessionResumeFull: DiveSessionResumeFull) {
  diveSessionResumeFull.participants.forEach((p, j) => {
    const bookingSessionParticipant =
      diveSessionResumeFull.bookingSessionParticipants.find(
        (bsp) => bsp.participantId === p._id,
      );
    if (bookingSessionParticipant) {
      // console.log(
      //   'xxx bookingSessionParticipant.booking:',
      //   bookingSessionParticipant.booking,
      // );
      if (bookingSessionParticipant.booking) {
        bookingSessionParticipant.booking.membersCount = (
          bookingSessionParticipant.booking as any
        )?.members?.aggregate?.count as number;
        (bookingSessionParticipant.booking as any).members = undefined;
      }
      p.booking = bookingSessionParticipant.booking;
      p.bookingJourney = bookingSessionParticipant.bookingJourney;
      const bookingProduct = bookingSessionParticipant.bookingProducts.find(
        (x) => x.type === 'dive',
      );
      if (bookingProduct) {
        p.purchasePaymentStatus =
          bookingProduct.purchasePackage?.purchasePaymentStatus;
        p.purchasePaymentPending =
          bookingProduct.purchasePackage?.purchasePaymentPending;
        p.purchasePaymentWithCreditNote =
          bookingProduct.purchasePackage?.purchasePaymentWithCreditNote;
      }
    }
  });
}

//////////

// BIZARRE, purchasePaymentPending est null en BaseAudioContext, alors que depositState est à 'draft'
// TODO: vérifier AVANT le paiement l'était du booking

// VISIBLEMENT, la requête met à jour purchasePaymentPending, mais pas depositState dans ce cas!

// TODO: vérifier si ça marche toujours pour un acompte classique

// UPDATE club_commerce_booking_deposit b_deposit
// SET
//     "purchasePaymentPending" =
//           CASE
//             WHEN (tmp."pendingCount" > 0) THEN true
//             ELSE null -- on laisse la valeur à null sinon
//           END,
//     "depositState" =
//           CASE
//             WHEN (
//                 (tmp."pendingCount" > 0 OR b_deposit."purchasePaymentStatus" <> 'done')
//                 AND b_deposit."depositState" = 'active')
//               THEN 'draft'
//             WHEN (tmp."pendingCount" = 0 AND b_deposit."purchasePaymentStatus" = 'done' AND b_deposit."depositState" = 'draft') THEN 'active'
//             ELSE b_deposit."depositState" -- on laisse la valeur inchangé sinon
//           END
// from (
//   select
//     b_deposit._id as "bookingDepositId",
//     b_deposit."depositState",
//     b_deposit."purchasePaymentStatus",
//     coalesce(pending_payments."pendingCount", 0) as "pendingCount"
//   from club_commerce_booking_deposit b_deposit
//   left join (
//     select b_deposit_details."bookingDepositId", count(b_deposit_details._id) as "pendingCount"
//     from club_commerce_purchase_payment_booking_deposit_details b_deposit_details
//     join club_commerce_purchase_payment p_pay on p_pay._id = b_deposit_details."purchasePaymentId"
//     where p_pay."paymentState" = 'pending'
//      AND p_pay."_id" <> '3e965239-9327-4545-9abc-4aad1cccbffc'
//     group by b_deposit_details."bookingDepositId"
//   ) as pending_payments on pending_payments."bookingDepositId" = b_deposit._id
// ) as tmp
// where
// exists (
//   select 1 from club_commerce_purchase_payment_booking_deposit_details b_deposit_details
//   where
//         b_deposit_details."purchasePaymentId"= '3e965239-9327-4545-9abc-4aad1cccbffc'
//     AND b_deposit_details."bookingDepositId" = b_deposit."_id"
// )
// and tmp."bookingDepositId" = b_deposit._id
// and coalesce(b_deposit."purchasePaymentPending", false) <> ((tmp."pendingCount" > 0)::bool);

// ---------------------------------------------------

// select "depositState", coalesce(b_deposit."purchasePaymentPending", false), * from club_commerce_booking_deposit b_deposit
// where  exists (
//   select 1 from club_commerce_purchase_payment_booking_deposit_details b_deposit_details
//   where
//         b_deposit_details."purchasePaymentId"= '3e965239-9327-4545-9abc-4aad1cccbffc'
//     AND b_deposit_details."bookingDepositId" = b_deposit."_id"
// )
