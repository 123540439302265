import { RadioGroup } from '@headlessui/react';
import clsx from 'clsx';
import React from 'react';
import { AppToggleButtonGroupProps } from './AppToggleButtonGroupProps.type';

export function AppToogleButtonGroupTailwind<
  T extends string | number | boolean,
>({
  fullWidthMobile,
  optionClassName,
  optionClassNameUnselected,
  customClassName,
  selected,
  onChanges,
  items,
  className,
  color,
  theme,
}: AppToggleButtonGroupProps<T>) {
  return (
    <RadioGroup
      value={selected}
      onChange={(item) => {
        onChanges((item as any)?.value);
      }}
      className={`mx-0.5 mt-2 ${className}`}
    >
      <RadioGroup.Label className="sr-only">Choose an option</RadioGroup.Label>
      <div
        className={clsx(
          'gap-x-3 gap-y-1',
          customClassName ??
            (items.length === 2
              ? 'grid grid-cols-2'
              : 'flex flex-col sm:grid sm:grid-cols-3'),
        )}
      >
        {items.map((option) => (
          <RadioGroup.Option
            key={option.label}
            value={option}
            className={({ active, checked }) =>
              clsx(
                optionClassName,
                !option.disabled
                  ? 'cursor-pointer focus:outline-none'
                  : 'opacity-25 cursor-not-allowed',
                active
                  ? `ring-2 ring-offset-2 ${
                      color === 'dive-mode'
                        ? `ring-dive-mode-${option.value}`
                        : color === 'boolean'
                        ? option.value === true
                          ? 'ring-green-500'
                          : 'ring-red-500'
                        : color === 'blue'
                        ? 'ring-blue-500'
                        : 'ring-green-500'
                    }`
                  : '',
                option.value === selected
                  ? color === 'dive-mode'
                    ? `bg-dive-mode-${option.value}  border-transparent text-white hover:bg-dive-mode-${option.value}-dark`
                    : color === 'boolean'
                    ? option.value === true
                      ? 'bg-green-500 border-transparent text-white hover:bg-green-600'
                      : 'bg-red-500 border-transparent text-white hover:bg-red-600'
                    : color === 'blue'
                    ? 'bg-blue-500 border-transparent text-white hover:bg-blue-600'
                    : 'bg-green-500 border-transparent text-white hover:bg-green-600'
                  : optionClassNameUnselected ??
                      'bg-white border-gray-400 text-gray-700 hover:bg-gray-50',
                'border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1',
              )
            }
            disabled={option.disabled}
          >
            <RadioGroup.Label as="span">{option.label}</RadioGroup.Label>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
