"use strict";
// remplace DiveServiceTrainingType, et est complété par DiveServiceTrainingTechnicalType
exports.__esModule = true;
exports.DIVE_SERVICE_TRAINING_COMMERCIAL_CATEGORIES_SORT_ORDER = void 0;
exports.DIVE_SERVICE_TRAINING_COMMERCIAL_CATEGORIES_SORT_ORDER = [
    'discover',
    'beginner',
    'advanced',
    'tek',
    'specialty',
    'instructor',
    'security',
    'other',
];
// TODO: ce qu'il faut faire, c'est déjà créer en bdd toutes les catégories commerciales
// ssi: https://www.divessi.com/fr/advanced-training/extended-range
// padi: https://www.padi.com/fr/cours-techniques
// anmp: https://www.anmp-plongee.com/formation.html
// et associer à chaque formation les tags que l'on souhaite (cave, gaz...)
// D'un autre côté, on a aussi besoin de pouvoir définir les équivalences entres les organismes, mais ça peut se faire à travers les catégories ou les tags aussi
