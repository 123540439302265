import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import React, { useMemo } from 'react';
import { dateServiceCore } from 'src/stories/services';
import { AppHackableCalendarVM } from '../model';

export const AppHackableCalendarWeeksNavPanel = (
  props: AppHackableCalendarVM,
) => {
  const { labels } = props;

  const { monthLabel, year } = useMemo(() => {
    return {
      monthLabel: props.labels.months[props.viewDate.getUTCMonth()],
      year: props.viewDate.getUTCFullYear(),
    };
  }, [props.labels.months, props.viewDate]);

  return (
    <div className="flex justify-between px-1 items-center text-gray-900">
      <div className="flex">
        <button
          type="button"
          className="flex flex-none items-center justify-center p-1.5 text-gray-400 border border-gray-200 hover:text-gray-500 rounded hover:bg-gray-200"
          onClick={() =>
            props.setViewDate(dateServiceCore.addYear(props.viewDate, -1))
          }
        >
          <span className="sr-only">{labels.nav.previousMonth}</span>
          <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          type="button"
          className="ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 border border-gray-200 hover:text-gray-500 rounded hover:bg-gray-200"
          onClick={() =>
            props.setViewDate(dateServiceCore.add(props.viewDate, -1, 'month'))
          }
        >
          <span className="sr-only">{labels.nav.previousMonth}</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div>
        <button
          type="button"
          className="py-1.5 px-5 flex-auto font-semibold uppercase text-gray-500  hover:text-gray-500 rounded hover:bg-gray-100"
          onClick={() => props.setView('years')}
        >
          <div className="hidden sm:inline">
            {monthLabel} {year}
          </div>
          <div className="sm:hidden">
            <div>{monthLabel}</div>
            <div>{year}</div>
          </div>
        </button>
      </div>
      <div className="flex">
        <button
          type="button"
          className="mr-2 flex flex-none items-center justify-center p-1.5 text-gray-400 border border-gray-200 hover:text-gray-500 rounded hover:bg-gray-200"
          onClick={() =>
            props.setViewDate(dateServiceCore.add(props.viewDate, 1, 'month'))
          }
        >
          <span className="sr-only">{labels.nav.nextMonth}</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          type="button"
          className="flex flex-none items-center justify-center p-1.5 text-gray-400 border border-gray-200 hover:text-gray-500 rounded hover:bg-gray-200"
          onClick={() =>
            props.setViewDate(dateServiceCore.addYear(props.viewDate, 1))
          }
        >
          <span className="sr-only">{labels.nav.nextMonth}</span>
          <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};
