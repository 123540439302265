/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DiveCertificationReference,
  GAZ_DIVE_CERTIFICATIONS_REFERENCES,
} from '@mabadive/app-common-model';
import { certificationFormatter } from '@mabadive/app-common-services';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

export const NITROX_CERTIFICATION_OPTIONS =
  GAZ_DIVE_CERTIFICATIONS_REFERENCES.map((reference) => {
    const option: ValueLabel<DiveCertificationReference> = {
      label: certificationFormatter.formatCertificationReference(reference, {
        format: 'ref-name',
      }),
      value: reference,
    };
    return option;
  });
