import { ClubProductPackageAttributesDivePriceType } from '@mabadive/app-common-model';

export const divePriceTypeFormatter = {
  formatDivePriceType,
};

function formatDivePriceType(
  divePriceType: ClubProductPackageAttributesDivePriceType,
): string {
  switch (divePriceType) {
    case 'single':
      return 'Tarif unitaire';
    case 'package':
      return 'Forfait';
    case 'successive':
      return 'Plongées successives';
  }
  return divePriceType;
}
