"use strict";
exports.__esModule = true;
exports.DEFAULT_EQUIPMENT_SETTINGS_COMPASS = void 0;
exports.DEFAULT_EQUIPMENT_SETTINGS_COMPASS = {
    type: 'compass',
    enabled: false,
    club: {
        enabled: false,
        models: []
    },
    self: {
        enabled: false,
        models: []
    }
};
