/* eslint-disable jsx-a11y/alt-text */
import {
  AppBookletPageGql_Customer,
  ClubPublicSettings,
  EcommerceCategoryGql_Customer,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { useAppCurrencyMain } from '../../../../../../pages';
import { AppPriceLabel } from '../../../../../club/modules/_common/ui';

export const EcommerceCategoryPreviewCover = ({
  clubPublicSettings,
  category,
  appBookletPage,
  productsLength,
  pricesBounds,
  children,
  className,
}: {
  clubPublicSettings: ClubPublicSettings;
  category: Pick<EcommerceCategoryGql_Customer, 'name' | 'details'>;
  appBookletPage: Partial<AppBookletPageGql_Customer>;
  productsLength: number;
  pricesBounds: {
    min: number;
    max: number;
  };
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
}) => {
  const appUiTheme = clubPublicSettings?.appUiTheme;

  const cover = appBookletPage?.mainContent?.cover;
  const mainCurrency = useAppCurrencyMain({ clubPublicSettings });

  return (
    category &&
    cover && (
      <div className={clsx('w-full flex flex-col overflow-y-auto', className)}>
        {cover.image && (
          <img
            className="relative object-center w-full rounded-t-xl"
            width={1000} // TODO à stocker dans l'image
            height={400} // TODO à stocker dans l'image
            src={cover.image.publicUrl}
          />
        )}
        <div className="h-full py-2 px-4 flex-grow flex flex-col gap-2 md:gap-4 xl:gap-6 2xl:gap-8 justify-between">
          <div>
            <div
              className={clsx(
                'text-base font-bold w-full text-left',
                // displayWidth === 'mobile'
                //   ? 'text-md'
                //   : 'text-md lg:text-lg',
              )}
              style={{ color: appUiTheme.title1.textColor }}
            >
              {cover?.title?.toUpperCase()}
            </div>
            {cover?.subTitle && (
              <div
                className={clsx('text-sm font-medium w-full text-left')}
                style={{ color: appUiTheme.title2.textColor }}
              >
                {cover?.subTitle}
              </div>
            )}
            <div className="my-4 text-sm font-normal text-gray-600">
              {productsLength > 0 && (
                <span>
                  <b>
                    {productsLength} activité{productsLength > 1 ? 's' : ''}{' '}
                  </b>
                  {category.details.minAge > 0 && (
                    <span> dès {category.details.minAge} ans</span>
                  )}
                </span>
              )}
              {pricesBounds?.min > 0 && (
                <span className="">
                  {pricesBounds?.min === pricesBounds?.max ? (
                    <span>
                      {' - '}
                      <AppPriceLabel
                        className="font-bold"
                        amount={pricesBounds?.min}
                        mainCurrency={mainCurrency}
                      />
                    </span>
                  ) : (
                    <span>
                      {' '}
                      à partir de{' '}
                      <AppPriceLabel
                        className="font-bold"
                        amount={pricesBounds?.min}
                        mainCurrency={mainCurrency}
                      />
                    </span>
                  )}
                </span>
              )}
            </div>
          </div>
          {children && children}
        </div>
      </div>
    )
  );
};
