import React from 'react';
import { CUS_BookingMemberFull } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/models';
import { BookingResumeMembersTableOnClickMemberAction } from 'src/business/dive-center/components/DiveCenterBookingMembersTable/DiveCenterBookingMembersTableRow';

import { DiveCenterBookingPageLocalState } from '../../useDiveCenterBookingPageLocalState.hook';
import { DiveCenterBookingPageTab1BookingResumeStartCheckoutPanel } from '../DiveCenterBookingPageTab1BookingResume/DiveCenterBookingPageTab1BookingResumeStartCheckoutPanel';
import { DiveCenterBookingMembersList } from './DiveCenterBookingMembersList';

export function DiveCenterBookingPageTab2BookingParticipants({
  localState,
  onClickMember,
}: {
  localState: DiveCenterBookingPageLocalState;
  onClickMember: (
    bookingMembersFull: CUS_BookingMemberFull,
    options: {
      action: BookingResumeMembersTableOnClickMemberAction;
      participantsIndex: number;
    },
  ) => any;
}) {
  const { aggregatedBookingResume, currentTab, setCurrentTab, aggregatedData } =
    localState;

  return (
    <div className="grid gap-4">
      {/* <div className="uppercase font-bold text-sm text-app-blue">
        Participants
      </div> */}
      {/* {enableCustomerUpdate && (
        <div className="mb-4 uppercase font-bold text-sm text-gray-600">
          Je renseigne la fiche de{' '}
          <span className="text-red-600">chaque participant</span> :
        </div>
      )} */}

      <div className="app-card app-p-content grid gap-4">
        {localState.az?.view?.otherParticipantsDetails && (
          <h3 className="text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
            Liste des participants
          </h3>
        )}
        <DiveCenterBookingMembersList
          className="mt-2"
          enableCustomerUpdate={false}
          aggregatedBookingResume={aggregatedBookingResume}
          onClick={undefined}
        />
      </div>

      <DiveCenterBookingPageTab1BookingResumeStartCheckoutPanel
        localState={localState}
      />

      {/* {enableCustomerUpdate && (
        <div className="mt-8 mb-4 text-sm text-gray-600">
          ℹ️{' '}
          <i className="italic">
            Cliquer sur chaque participant pour remplir sa fiche.
          </i>
        </div>
      )} */}
    </div>
  );
}
