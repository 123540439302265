import { ClubEventPriority } from '@mabadive/app-common-model';

export const clubEventPriorityFormatter = {
  formatClubEventPriority,
};

function formatClubEventPriority(priority: ClubEventPriority): string {
  switch (priority) {
    case 'low': {
      return 'Mineur';
    }
    case 'normal': {
      return 'Normale';
    }
    case 'high': {
      return 'Important';
    }
  }
  return '';
}
