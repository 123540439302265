export type DateServiceIsoWeekDay = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export const dateServiceCore = {
  getUTCWeekDayIso,
  add,
  addDays,
  addMonth,
  addYear,
  toISOStringDate,
  truncateTimeUTC,
};
function truncateTimeUTC(date: Date): Date {
  if (!date) {
    return undefined;
  } else if (!date.getUTCFullYear) {
    date = new Date(date);
  }
  return new Date(
    Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()),
  );
}
function getUTCWeekDayIso(date: Date): DateServiceIsoWeekDay {
  return (((date.getUTCDay() + 6) % 7) + 1) as DateServiceIsoWeekDay;
}
function add(date: Date, nb: number, unit: 'day' | 'month' | 'year'): Date {
  if (unit === 'day') {
    return addDays(date, nb);
  }
  if (unit === 'month') {
    return addMonth(date, nb);
  }
  if (unit === 'year') {
    return addYear(date, nb);
  }
}
function addDays(date: Date, nb: number): Date {
  date = new Date(date);
  date.setUTCDate(date.getUTCDate() + nb);
  return date;
}
function addMonth(date: Date, nb: number): Date {
  date = new Date(date);
  date.setUTCMonth(date.getUTCMonth() + nb);
  return date;
}
function addYear(date: Date, nb: number): Date {
  date = new Date(date);
  date.setFullYear(date.getFullYear() + nb);
  return date;
}
function toISOStringDate(date: Date): string {
  return date.toISOString().substring(0, 10);
}
