import {
  ClubParticipant,
  ClubPublicSettings,
  ClubSettingsUIDiveMode,
} from '@mabadive/app-common-model';
import { diveSessionServiceFormatter } from '../session/diveSessionServiceFormatter.service';
import { diveModeFormatter } from './diveModeFormatter.service';

export type DiveTypeFullStringFormat =
  | 'short'
  | 'deep-role'
  | 'divetype-deep-role';

export const diveTypeFullFormatter = {
  formatDiveTypeFull,
};

function formatDiveTypeFull(
  participant: Pick<
    ClubParticipant,
    | 'targetDeep'
    | 'trainingReference'
    | 'certificationReference'
    | 'firstDiveReference'
    | 'diveMode'
  >,
  {
    format,
    clubReference,
    clubPublicSettings,
    diveModesConf,
  }: {
    format: DiveTypeFullStringFormat;
    clubReference: string;
    clubPublicSettings: ClubPublicSettings;
    diveModesConf: ClubSettingsUIDiveMode;
  },
): string {
  if (!participant) {
    return '';
  }

  if (format === 'short') {
    return diveSessionServiceFormatter.formatServiceFromParticipant(
      { diver: undefined, participant },
      { format: 'short-ref', clubReference, clubPublicSettings, diveModesConf },
    );
  }
  if (format === 'deep-role') {
    return `${formatTargetDeep(participant)}${formatDiveMode(participant, {
      diveModesConf,
    })}`;
  }
  if (format === 'divetype-deep-role') {
    return `${diveSessionServiceFormatter.formatServiceFromParticipant(
      { diver: undefined, participant },
      { format: 'short-ref', clubReference, clubPublicSettings, diveModesConf },
    )}${formatTargetDeep(participant)}${
      participant.diveMode
        ? ` (${formatDiveMode(participant, { diveModesConf })}`
        : ''
    }`;
  }
}

function formatTargetDeep(participant: Pick<ClubParticipant, 'targetDeep'>) {
  return participant.targetDeep ? ` (${participant.targetDeep}m)` : '';
}

function formatDiveMode(
  participant: Pick<ClubParticipant, 'diveMode'>,
  {
    diveModesConf,
  }: {
    diveModesConf?: ClubSettingsUIDiveMode;
  },
) {
  return participant.diveMode
    ? diveModeFormatter.formatDiveMode(participant.diveMode, {
        format: 'short-label',
        diveModesConf,
      })
    : '';
}
