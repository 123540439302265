import { ClubDiverConsentAcceptance } from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { ClubCustomerConsent_Config } from './useCustomerConsentConfig';

export function useDefaultConsentAcceptance({
  consentConfig,
}: {
  consentConfig: ClubCustomerConsent_Config;
}): ClubDiverConsentAcceptance {
  const defaultConsentAcceptance: ClubDiverConsentAcceptance = useMemo(
    () => ({
      type: consentConfig.type,
      consentAgreementId: consentConfig.agreementId,
      accepted: false,
      date: new Date(),
      content: consentConfig.content,
    }),
    [consentConfig],
  );
  return defaultConsentAcceptance;
}
