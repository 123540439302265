import { ClubDiverCertificationLabels } from '@mabadive/app-common-model';
import {
  dateService,
  diverCertificationFormatter,
  nameFormatter,
} from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { CUS_BookingMemberFull } from '../../../../../club/modules/club-diver-participant/pages/DiverBookingPage/models';
import { DiveCenterBookingMemberCertification } from '../DiveCenterBookingPageTab2BookingParticipants/DiveCenterBookingMembersList/DiveCenterBookingMemberCertification';

export function DiveCenterBookingPageTab5Inquiries_MemberNameLabel({
  bookingMemberFull,
  className = '',
}: {
  bookingMemberFull: CUS_BookingMemberFull;
  className?: string;
}) {
  const diver = bookingMemberFull?.diver;
  const age = useMemo(
    () =>
      bookingMemberFull.diver.birthdate
        ? dateService.getAge(bookingMemberFull.diver.birthdate)
        : bookingMemberFull.diver.age,
    [bookingMemberFull.diver.age, bookingMemberFull.diver.birthdate],
  );

  const certificationLabels: ClubDiverCertificationLabels = useMemo(
    () =>
      diverCertificationFormatter.formatCertificationLabels({
        diver,
        format: 'short',
      }),
    [diver],
  );

  return (
    <div className={`flex flex-col justify-center text-left ${className}`}>
      <p
        className={`leading-4 font-bold text-sm  text-app-blue whitespace-nowrap ${
          bookingMemberFull?.bookingMember?.details?.creationStatus === 'draft'
            ? 'italic'
            : ''
        }`}
      >
        {nameFormatter.formatFullName(bookingMemberFull.diver, {
          format: 'firstName-first',
        })}{' '}
        {age > 0 && <span className="font-bold text-gray-500">{age} ans</span>}
      </p>
      <p className="leading-4 flex gap-2">
        {certificationLabels.hasCertification && (
          <span className="uppercase text-gray-600 font-bold">
            <DiveCenterBookingMemberCertification
              certificationLabels={certificationLabels}
            />
          </span>
        )}
      </p>
    </div>
  );
}
