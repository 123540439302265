import { AppCompanyMessageLocalized } from '@mabadive/app-common-model';

export const whatzappLinkBuilder = {
  buildLink,
  cleanPhoneNumber,
  buildMessageFromChunks,
};

function cleanPhoneNumber(phoneNumber: string) {
  if (phoneNumber) {
    phoneNumber = phoneNumber.trim();
    if (!phoneNumber?.length) {
      return;
    }
    // https://stackoverflow.com/questions/30344476/web-link-to-specific-whatsapp-contact
    if (phoneNumber.charAt(0) === '+') {
      return cleanNumberString(phoneNumber.substring(1));
    } else {
      const cleanPhoneNumber = cleanNumberString(phoneNumber);
      if (
        cleanPhoneNumber.length === 10 &&
        (cleanPhoneNumber.substring(0, 2) === '06' ||
          cleanPhoneNumber.substring(0, 2) === '07')
      ) {
        // remplace 0 par 33
        return cleanNumberString('33' + cleanPhoneNumber.substring(1));
      }
    }
  }
}

function buildMessageFromChunks({
  title,
  body,
  signature,
}: Pick<AppCompanyMessageLocalized, 'title' | 'body' | 'signature'>) {
  const content = [title, body, signature]
    .map((x) => x?.trim())
    .filter((x) => x?.length > 0)
    .join('\r\n');
  return content;
}
function buildLink({
  phoneNumber: phoneNumberInput,
  message,
  isMobile,
  skipCleanPhoneNumber,
}: {
  phoneNumber: string;
  message: string;
  isMobile: boolean;
  skipCleanPhoneNumber?: boolean;
}) {
  const phoneNumber = skipCleanPhoneNumber
    ? phoneNumberInput
    : cleanPhoneNumber(phoneNumberInput);
  if (phoneNumber) {
    const encodedMessage = encodeURIComponent(message);

    // https://faq.whatsapp.com/iphone/how-to-link-to-whatsapp-from-a-different-app/?lang=en
    if (isMobile) {
      // testé OK sur firefox+chrome sous android (même si numéro pas dans les contacts)
      return `whatsapp://send?phone=${phoneNumber}?&text=${encodedMessage}`;
    } else {
      // testé OK sur firefox+chrome sous linux (même si numéro pas dans les contacts)
      return `https://web.whatsapp.com/send?phone=${phoneNumber}?&text=${encodedMessage}`;
    }
    // NOTE: les liens suivant ne fonctionnent pas correctement avec Firefox: https://bugzilla.mozilla.org/show_bug.cgi?id=1444997
    // return `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    // return `https://api.whatsapp.com/send?phone=${phoneNumber}?&text=${encodedMessage}`;
  }
}

function cleanNumberString(s: string) {
  return s.replace(/\D/g, '');
}
