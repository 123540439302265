import { DiveSessionStatus } from '@mabadive/app-common-model';

export const diveSessionStatusFormatter = {
  formatStatus,
};

function formatStatus(
  status: DiveSessionStatus,
  { isFull }: { isFull: boolean } = { isFull: false },
): string {
  switch (status) {
    case 'off':
      return 'fermée';
    case 'deleted':
      return 'supprimée';
    case 'cancelled':
      return 'annulée';
  }
  if (isFull) {
    return 'complet';
  }
  // default
  return status;
}
