import React, { FC, useMemo } from 'react';
import { AppHeroIcons } from '../../../icons';
import { AppGenericStatus } from './AppGenericStatus.type';

export const AppGenericStatusIcon: FC<{
  status: AppGenericStatus;
  className?: string;
  successColor?: boolean;
}> = ({ status, className }) => {
  const StatusIcon = useMemo(() => {
    switch (status) {
      case 'success': {
        return AppHeroIcons.success;
      }
      case 'warn': {
        return AppHeroIcons.warn;
      }
      case 'error': {
        return AppHeroIcons.error;
      }
      case 'favourite': {
        return AppHeroIcons.star;
      }
      case 'active': {
        return AppHeroIcons.active;
      }
      case 'info':
      default: {
        return AppHeroIcons.check;
      }
    }
  }, [status]);

  return <StatusIcon className={`${className ?? ''}`} />;
};
