import {
  ClubDiver,
  ClubParticipant,
  DIVE_CERTIFICATIONS_REFERENCE_MAP,
  DIVE_TRAININGS_REFERENCE_MAP,
  DiveAptitude,
} from '@mabadive/app-common-model';

export const participantAptitudeBuilder = {
  formatAptitude,
};

function formatAptitude(
  {
    diver,
    participant,
  }: {
    diver: Partial<Pick<ClubDiver, 'divingCertification1'>>;
    participant: Partial<
      Pick<
        ClubParticipant,
        | 'trainingReference'
        | 'certificationReference'
        | 'certificationDeepDiver'
        | 'diveMode'
        | 'aptitude'
        | 'autoSupervisedDetails'
      >
    >;
  },
  { clubReference }: { clubReference: string },
): DiveAptitude {
  if (!participant) {
    return '' as DiveAptitude;
  }

  switch (participant.diveMode) {
    case 'first-dive': {
      return `` as DiveAptitude;
    }
    case 'training': {
      return getTrainingAptitude({ diver, participant, clubReference });
    }
    case 'supervised': {
      return getSupervisedAptitude({ diver, participant });
    }
    case 'instructor':
    case 'autonomous': {
      return getAutonomousAptitude({ diver, participant });
    }
    case 'autoSupervised': {
      return getAutoSupervisedAptitude(
        { diver, participant },
        { clubReference },
      );
    }
  }
  return '' as DiveAptitude;
}

function getSupervisedAptitude({
  diver,
  participant,
}: {
  diver: Partial<Pick<ClubDiver, 'divingCertification1'>>;
  participant: Partial<
    Pick<
      ClubParticipant,
      | 'trainingReference'
      | 'certificationReference'
      | 'certificationDeepDiver'
      | 'diveMode'
      | 'aptitude'
    >
  >;
}): DiveAptitude {
  if (participant.aptitude) {
    return participant.aptitude;
  }
  if (participant.certificationDeepDiver) {
    return 'PE40';
  }
  if (
    participant.certificationReference === 'SD' &&
    diver?.divingCertification1?.mainCertificationReference === 'SD' &&
    diver?.divingCertification1?.orgReference === 'naui'
  ) {
    console.log('HACK NAUI SD');
    // ATTENTION HACK, le Scuba Diver de chez NAUI correspond à un PADI Open Water, donc il peut plonger à 12m
    // => https://www.naui.org/certifications/entry-level/scuba-diver/
    return 'PE20' as DiveAptitude;
  }
  const certification =
    DIVE_CERTIFICATIONS_REFERENCE_MAP[participant.certificationReference];
  return certification?.supervised?.defaultAptitude ?? `PE20`;
}
function getAutonomousAptitude({
  diver,
  participant,
}: {
  diver: Partial<Pick<ClubDiver, 'divingCertification1'>>;
  participant: Partial<
    Pick<
      ClubParticipant,
      | 'trainingReference'
      | 'certificationReference'
      | 'certificationDeepDiver'
      | 'diveMode'
      | 'aptitude'
    >
  >;
}): DiveAptitude {
  if (participant.aptitude) {
    return participant.aptitude;
  }
  if (participant.certificationDeepDiver) {
    return 'PA40';
  }
  const certification =
    DIVE_CERTIFICATIONS_REFERENCE_MAP[participant.certificationReference];
  return certification?.autonomous?.defaultAptitude ?? `PA20`;
}
function getTrainingAptitude({
  diver,
  participant,
  clubReference,
}: {
  diver: Partial<Pick<ClubDiver, 'divingCertification1'>>;
  participant: Partial<
    Pick<
      ClubParticipant,
      | 'trainingReference'
      | 'certificationReference'
      | 'certificationDeepDiver'
      | 'diveMode'
      | 'aptitude'
    >
  >;
  clubReference: string;
}): DiveAptitude {
  if (participant.aptitude) {
    return participant.aptitude;
  }
  if (
    clubReference === 'seacretdive-port-louis-98825' &&
    (participant.trainingReference === 'FN1' ||
      participant.trainingReference === 'FOW')
  ) {
    return 'PE12'; // hack seacret dive
  }
  const training = DIVE_TRAININGS_REFERENCE_MAP[participant.trainingReference];
  return training?.defaultAptitude ?? `PE20`;
}

function getAutoSupervisedAptitude(
  {
    diver,
    participant,
  }: {
    diver: Partial<Pick<ClubDiver, 'divingCertification1'>>;
    participant: Partial<
      Pick<
        ClubParticipant,
        | 'trainingReference'
        | 'certificationReference'
        | 'certificationDeepDiver'
        | 'diveMode'
        | 'aptitude'
        | 'autoSupervisedDetails'
      >
    >;
  },
  { clubReference }: { clubReference: string },
): DiveAptitude {
  if (!participant) {
    return '' as DiveAptitude;
  }

  const asDetails = participant.autoSupervisedDetails;
  const autoSupervisedDiveMode = asDetails?.diveMode;
  const participantAutoSupervised = {
    ...participant,
    trainingReference: asDetails?.trainingReference,
    certificationReference: asDetails?.certificationReference,
    certificationDeepDiver: asDetails?.certificationDeepDiver,
  };
  if (autoSupervisedDiveMode === 'training') {
    return getTrainingAptitude({
      diver,
      participant: participantAutoSupervised,
      clubReference,
    });
  } else if (autoSupervisedDiveMode === 'supervised') {
    return getSupervisedAptitude({
      diver,
      participant: participantAutoSupervised,
    });
  } else if (autoSupervisedDiveMode === 'autonomous') {
    return getAutonomousAptitude({
      diver,
      participant: participantAutoSupervised,
    });
  }
  return '' as DiveAptitude;
}
