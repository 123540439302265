/* eslint-disable @typescript-eslint/no-unused-vars */
import { OnlineBookingParticipant } from '@mabadive/app-common-model';
import { useCallback, useMemo } from 'react';
import { useAppCurrencyMain } from '../../../pages';
import { useAppRouter } from '../../_core/data/hooks/useAppRouter';
import { diveCenterBookingUrlBuilder } from '../BO-booking/diveCenterBookingUrlBuilder.service';
import { useCustomerEcommerceDataLoadedContent } from '../DiveCenterEcommerceHomePage/_data';
import {
  DiveCenterShoppingCartContextProps,
  useDiveCenterShoppingCart,
} from '../DiveCenterShoppingCartPage';
import { CustomerProductsPage_FillContactPanel_FormModel } from './CustomerProductsPage_FillContactPanel/model';
export function useDiveCenterBookingContactPageLocalState({
  clubPublicReference,
}: {
  clubPublicReference: string;
}) {
  const { loadedContent, loadableContent, refetch } =
    useCustomerEcommerceDataLoadedContent({
      clubPublicReference,
    });
  const shoppingCartContext: DiveCenterShoppingCartContextProps =
    useDiveCenterShoppingCart();

  const { cart, actions } = shoppingCartContext;
  const appRouter = useAppRouter();

  const clubSettings = loadedContent?.clubResume?.clubSettings;
  const clubReference = loadedContent?.clubResume?.club?.reference;
  const diveCenters = loadedContent?.clubResume?.diveCenters;
  const clubPublicSettings = clubSettings?.publicSettings;
  const mainCurrency = useAppCurrencyMain({ clubPublicSettings });

  const appUiTheme = clubPublicSettings?.appUiTheme;

  const diveCenter = diveCenters?.length ? diveCenters[0] : undefined;
  const clubPublicData = clubSettings?.publicData;
  const diveCenterPublicData = diveCenter?.publicData;
  const diveCenterId = diveCenter?._id;

  const documentTitle = useMemo(() => {
    const baseName = diveCenter?.publicData?.brand?.name;
    const chunks = [baseName];
    chunks.push('Contact de la réservation');
    return chunks.filter(Boolean).join(' - ');
  }, [diveCenter?.publicData?.brand?.name]);
  document.title = documentTitle;

  const confirmBookingContact = useCallback(
    async (formValue: CustomerProductsPage_FillContactPanel_FormModel) => {
      let bookingParticipants = cart.bookingDetails?.bookingParticipants ?? [];
      const existingParticipant: OnlineBookingParticipant =
        bookingParticipants.find(
          (x) => x.diver._id === formValue.bookingContact.diverId,
        );

      if (existingParticipant) {
        //  update bookingParticipants
        bookingParticipants = bookingParticipants.map((x) => {
          if (x.diver._id === formValue.bookingContact.diverId) {
            return {
              ...x,
              diver: formValue.bookingContactDiver,
            };
          }
          return x;
        });
      } else {
        // add contact diver
        bookingParticipants = [
          ...bookingParticipants,
          {
            diver: formValue.bookingContactDiver,
          },
        ];
      }
      actions.updateBookingParticipants({
        bookingParticipants,
        bookingContact: formValue.bookingContact,
        bookingGroup: formValue.bookingGroup,
        comment: formValue.comment,
      });
      appRouter.navigate(
        diveCenterBookingUrlBuilder.buildDiveCenterShoppingBookingSubmitUrl({
          clubPublicReference,
        }),
        {
          cause: 'confirmBookingContact',
        },
      );
    },
    [
      actions,
      appRouter,
      cart.bookingDetails?.bookingParticipants,
      clubPublicReference,
    ],
  );

  return {
    state: {
      clubReference,
      diveCenterId,
      loadableContent,
      mainCurrency,
    },
    actions: {
      refetch,
      confirmBookingContact,
    },
    data: {
      ...(loadedContent ?? {}),
      clubSettings,
      clubPublicSettings,
      clubPublicReference,
      diveCenter,
      clubPublicData,
      diveCenterPublicData,
      appUiTheme,
    },
  };
}
export type DiveCenterBookingContactPageLocalState = ReturnType<
  typeof useDiveCenterBookingContactPageLocalState
>;
