import clsx from 'clsx';
import React, { useEffect, useMemo } from 'react';
import { AppPageContainer } from 'src/business/_core/modules/layout';
import { DiveCenterBrandCard } from '../components';
import { useDiveCenterHomePageLocalState } from './useDiveCenterHomePageLocalState.hook';

export const DiveCenterHomePage = ({
  navigationContext,
}: {
  navigationContext: {
    clubPublicReference: string;
  };
}) => {
  const clubPublicReference = navigationContext.clubPublicReference;

  const localState = useDiveCenterHomePageLocalState({
    clubPublicReference,
  });

  const { loadableContent, loadedContent } = localState;

  const clubSettings = loadedContent?.clubResume?.clubSettings;
  const diveCenters = loadedContent?.clubResume?.diveCenters;
  const clubPublicSettings = clubSettings?.publicSettings;

  const diveCenter = diveCenters?.length ? diveCenters[0] : undefined;

  const documentTitle = useMemo(() => {
    const baseName = diveCenter?.publicData?.brand?.name;
    const chunks = [baseName, 'Accueil'];

    return chunks.filter(Boolean).join(' - ');
  }, [diveCenter?.publicData?.brand?.name]);

  useEffect(() => {
    document.title = documentTitle;
  }, [documentTitle]);

  return (
    <AppPageContainer
      className="bg-gray-50 app-p-content overflow-y-auto"
      {...loadableContent}
    >
      <div className="h-full bg-gray-50 w-full">
        <div
          className={clsx(
            'mt-8 app-card app-p-content flex flex-col gap-8 justify-center xs:justify-start',
          )}
        >
          <DiveCenterBrandCard
            className=""
            clubPublicData={clubSettings?.publicData}
            clubPublicSettings={clubPublicSettings}
            diveCenterPublicData={diveCenter?.publicData}
            clubPublicReference={clubPublicReference}
            showHeader={true}
            showContactLinks={false}
            showLegalInformation={false}
            showActivities={true}
          />

          <DiveCenterBrandCard
            className=""
            clubPublicData={clubSettings?.publicData}
            clubPublicSettings={clubPublicSettings}
            diveCenterPublicData={diveCenter?.publicData}
            clubPublicReference={clubPublicReference}
            showHeader={false}
            showContactLinks={true}
          />
        </div>
      </div>
    </AppPageContainer>
  );
};
