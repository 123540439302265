import {
  AppCompanyMessageContent,
  AppCompanyMessageLocalized,
  AppCompanyMessageSchedule,
  AppCompanyMessageScheduleContextBooking,
  AppCompanyMessageScheduleContextSession,
  AppCompanyMessageScheduleTarget,
  AppCompanyMessageScheduleTargetMessage,
  AppCompanyMessageScheduleTargetMessageRecipient,
  AppCompanyMessageTarget,
  AppCompanyMessageTargetMode,
  AppCompanyMessageTemplate,
  Booking,
  ClubSettings,
  DiveSession,
} from '../../../../app-common-model/src';

export const companyMessageBuilder = {
  buildMessageSchedule,
  buildMessageContextBooking,
  buildMessageContextSession,
  buildMainTarget,
  buildLocalizedMessage,
  buildMessageContentFromTemplate,
};
function buildMessageContentFromTemplate(
  content: AppCompanyMessageContent,
  {
    clubSettings,
  }: {
    clubSettings: Pick<ClubSettings, 'communication'>;
  },
): AppCompanyMessageLocalized {
  const { appendSignature, message, target } = content;

  const msg: AppCompanyMessageLocalized = {
    languageCode: 'fr',
    title: message.title,
    body: message.body,
    signature: '',
  };

  if (appendSignature) {
    // NOTE on n'utilise plus la signature par template ("message.signature")
    if (target === 'email') {
      const emailSignatureConfig =
        clubSettings.communication?.templates?.emailSignature;
      const signatureEnabled =
        clubSettings.communication?.channels?.email?.directMode
          ?.directManualEnabled || emailSignatureConfig?.enabledIfManual;

      if (signatureEnabled && emailSignatureConfig?.content?.length > 0) {
        msg.signature = emailSignatureConfig.content;
      }
    } else if (target === 'sms') {
      const smsSignatureConfig =
        clubSettings.communication?.templates?.smsSignature;
      if (smsSignatureConfig?.content?.length > 0) {
        msg.signature = smsSignatureConfig.content;
      }
    } else if (target === 'whatsapp') {
      const whatsAppSignatureConfig =
        clubSettings.communication?.templates?.whatsAppSignature;
      if (whatsAppSignatureConfig?.content?.length > 0) {
        msg.signature = whatsAppSignatureConfig.content;
      }
    }
  }
  return msg;
}

function buildLocalizedMessage(
  messageContent: AppCompanyMessageLocalized,
  recipients: AppCompanyMessageScheduleTargetMessageRecipient[],
): AppCompanyMessageScheduleTargetMessage {
  return {
    format: 'text-only',
    title: messageContent.title,
    body: messageContent.body,
    languageCode: messageContent.languageCode,
    sendDate: new Date(),
    recipients,
  };
}

function buildMessageSchedule({
  messageScheduleId,
  clubReference,
  diveCenterId,
  messageTemplateId,
  messageTemplate,
  mainTarget,
  bookingContext,
  sessionContext,
  recipients,
  targetMode,
  sendDateExpected,
}: {
  messageScheduleId: string;
  clubReference: string;
  diveCenterId: string;
  messageTemplateId: string;
  messageTemplate: AppCompanyMessageTemplate;
  mainTarget: AppCompanyMessageScheduleTarget;
  bookingContext: AppCompanyMessageScheduleContextBooking;
  sessionContext: AppCompanyMessageScheduleContextSession;
  recipients: AppCompanyMessageScheduleTargetMessageRecipient[];
  targetMode: string;
  sendDateExpected: Date;
}): AppCompanyMessageSchedule {
  return {
    _id: messageScheduleId,
    clubReference,
    diveCenterId,
    templateId: messageTemplateId,
    template: messageTemplate,
    mainTarget,
    context: messageTemplate.context,
    bookingContext,
    sessionContext,
    recipientDiversIds: recipients.map((x) => x.diver?._id).filter((x) => !!x),
    // FIXME faire tout ça côté serveur
    processStatus: targetMode === 'auto' ? 'pending' : 'sent',
    processInitalScheduledDate: sendDateExpected,
    processNextScheduledDate: sendDateExpected,
    processErrorCount: 0,
  };
}

function buildMessageContextBooking(
  booking: Pick<
    Booking,
    | '_id'
    | 'bookingContactDiverId'
    | 'bookingCustomerConfig'
    | 'bookingCustomerSpacePrivate'
    | 'bookingJourney'
  >,
): AppCompanyMessageScheduleContextBooking {
  return {
    booking: {
      _id: booking?._id,
      bookingCustomerConfig: booking?.bookingCustomerConfig,
      bookingCustomerSpacePrivate: booking?.bookingCustomerSpacePrivate,
      bookingContactDiverId: booking?.bookingContactDiverId,
      bookingJourney: booking?.bookingJourney,
    },
  };
}

function buildMessageContextSession(
  diveSession?: Pick<DiveSession, '_id' | 'time'>,
): AppCompanyMessageScheduleContextSession {
  return {
    diveSession: {
      _id: diveSession?._id,
      time: diveSession?.time,
    },
  };
}

function buildMainTarget({
  clubReference,
  diveCenterId,
  messageScheduleId,
  messageTarget,
  targetMode,
  messageContent,
  localizedMessage,
  sendDateExpected,
  bccRecipients,
}: {
  clubReference: string;
  diveCenterId: string;
  messageScheduleId: string;
  messageTarget: AppCompanyMessageTarget;
  targetMode: AppCompanyMessageTargetMode;
  messageContent: AppCompanyMessageLocalized;
  localizedMessage: AppCompanyMessageScheduleTargetMessage;
  sendDateExpected: Date;
  bccRecipients?: string[];
}): AppCompanyMessageScheduleTarget {
  return {
    clubReference,
    diveCenterId,
    messageScheduleId,
    target: messageTarget,
    targetMode,
    originalMessage: messageContent,
    // translations: AppCompanyMessageLocalized[];
    localizedMessages: [localizedMessage],
    sendDateExpected,
    sendDateReal: targetMode === 'auto' ? undefined : sendDateExpected,
    sendStatus: targetMode === 'auto' ? 'pending' : 'sent',
    bccRecipients,
  };
}
