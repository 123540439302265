"use strict";
exports.__esModule = true;
exports.DEFAULT_MESSAGES_TEMPLATES_BOOKING = void 0;
exports.DEFAULT_MESSAGES_TEMPLATES_BOOKING = [
    {
        name: 'A.1) Réservation confirmée',
        style: 'success',
        enabled: true,
        emailContent: {
            title: 'Votre réservation à CENTRE_NOM',
            body: "Bonjour PARTICIPANT_PRENOM, \n\r\nPour visualiser votre r\u00E9servation, connectez-vous au lien suivant: ESPACE_CLIENT_LIEN_RESA\n\r\n\u00C0 tr\u00E8s bient\u00F4t!"
        },
        smsContent: {
            title: 'RÉSERVATION CENTRE_NOM',
            body: "Bonjour PARTICIPANT_PRENOM, \n\r\nPour visualiser votre r\u00E9servation, connectez-vous au lien suivant: ESPACE_CLIENT_LIEN_RESA\n\r\n\u00C0 tr\u00E8s bient\u00F4t!"
        },
        whatsAppContent: {
            title: 'RÉSERVATION CENTRE_NOM',
            body: "Bonjour PARTICIPANT_PRENOM, \n\r\nPour visualiser votre r\u00E9servation, connectez-vous au lien suivant: ESPACE_CLIENT_LIEN_RESA\n\r\n\u00C0 tr\u00E8s bient\u00F4t!"
        }
    },
    {
        name: 'A.2) Remplissez vos informations',
        style: 'warning',
        enabled: true,
        emailContent: {
            title: 'Réservation CENTRE_NOM: remplissez vos informations',
            body: "Bonjour PARTICIPANT_PRENOM, \n  \r\nPour visualiser votre r\u00E9servation, connectez-vous au lien suivant: ESPACE_CLIENT_LIEN_RESA\n  \r\nMerci de compl\u00E9ter toutes les informations pour chacune des personnes inscrites \u00E0 cette sortie.\n  \r\n\u00C0 tr\u00E8s bient\u00F4t!"
        },
        smsContent: {
            title: 'RÉSERVATION CENTRE_NOM',
            body: "Bonjour PARTICIPANT_PRENOM, \n  \r\nPour visualiser votre r\u00E9servation, connectez-vous au lien suivant: ESPACE_CLIENT_LIEN_RESA\n  \r\nMerci de compl\u00E9ter toutes les informations pour chacune des personnes inscrites \u00E0 cette sortie.\n  \r\n\u00C0 tr\u00E8s bient\u00F4t!"
        },
        whatsAppContent: {
            title: 'RÉSERVATION CENTRE_NOM',
            body: "Bonjour PARTICIPANT_PRENOM, \n  \r\nPour visualiser votre r\u00E9servation, connectez-vous au lien suivant: ESPACE_CLIENT_LIEN_RESA\n  \r\nMerci de compl\u00E9ter toutes les informations pour chacune des personnes inscrites \u00E0 cette sortie.\n  \r\n\u00C0 tr\u00E8s bient\u00F4t!"
        }
    },
    {
        name: 'A.3) Acompte à payer',
        style: 'warning',
        enabled: true,
        emailContent: {
            title: 'Paiement de votre acompte pour CENTRE_NOM',
            body: "Bonjour PARTICIPANT_PRENOM, \n\r\nNous vous rappelons que pour confirmer votre r\u00E9servation, un acompte doit \u00EAtre vers\u00E9. \n\r\nVeuillez proc\u00E9der au paiement en suivant ce lien : ESPACE_CLIENT_LIEN_RESA.\n\r\nMerci et \u00E0 bient\u00F4t chez CENTRE_NOM!"
        },
        smsContent: {
            title: 'PAIEMENT CENTRE_NOM',
            body: "Bonjour PARTICIPANT_PRENOM, \n\r\nNous vous rappelons que pour confirmer votre r\u00E9servation, un acompte doit \u00EAtre vers\u00E9. \n\r\nVeuillez proc\u00E9der au paiement en suivant ce lien : ESPACE_CLIENT_LIEN_RESA.\n\r\nMerci et \u00E0 bient\u00F4t chez CENTRE_NOM!"
        },
        whatsAppContent: {
            title: 'PAIEMENT CENTRE_NOM',
            body: "Bonjour PARTICIPANT_PRENOM, \n\r\nNous vous rappelons que pour confirmer votre r\u00E9servation, un acompte doit \u00EAtre vers\u00E9. \n\r\nVeuillez proc\u00E9der au paiement en suivant ce lien : ESPACE_CLIENT_LIEN_RESA.\n\r\nMerci et \u00E0 bient\u00F4t chez CENTRE_NOM!"
        }
    },
    {
        name: 'A.4) Rappel paiement en attente',
        style: 'warning',
        enabled: true,
        emailContent: {
            title: 'Paiement en attente CENTRE_NOM',
            body: "Bonjour PARTICIPANT_PRENOM, \n\r\nNous vous rappelons que le paiement pour votre r\u00E9servation est toujours en attente. \n\r\nPour finaliser votre r\u00E9servation, veuillez proc\u00E9der au paiement en suivant ce lien : ESPACE_CLIENT_LIEN_RESA.\n\r\nMerci et \u00E0 bient\u00F4t chez CENTRE_NOM!"
        },
        smsContent: {
            title: 'PAIEMENT CENTRE_NOM',
            body: "Bonjour PARTICIPANT_PRENOM, \n\r\nNous vous rappelons que le paiement pour votre r\u00E9servation est toujours en attente. \n\r\nPour finaliser votre r\u00E9servation, veuillez proc\u00E9der au paiement en suivant ce lien : ESPACE_CLIENT_LIEN_RESA.\n\r\nMerci et \u00E0 bient\u00F4t chez CENTRE_NOM!"
        },
        whatsAppContent: {
            title: 'PAIEMENT CENTRE_NOM',
            body: "Bonjour PARTICIPANT_PRENOM, \n\r\nNous vous rappelons que le paiement pour votre r\u00E9servation est toujours en attente. \n\r\nPour finaliser votre r\u00E9servation, veuillez proc\u00E9der au paiement en suivant ce lien : ESPACE_CLIENT_LIEN_RESA.\n\r\nMerci et \u00E0 bient\u00F4t chez CENTRE_NOM!"
        }
    },
    {
        name: 'B.1) Réservation annulée',
        style: 'danger',
        enabled: true,
        emailContent: {
            title: 'Annulation de votre réservation à CENTRE_NOM',
            body: "Bonjour PARTICIPANT_PRENOM, \n\r\nNous sommes d\u00E9sol\u00E9s de vous informer que votre r\u00E9servation a \u00E9t\u00E9 annul\u00E9e. \n\r\nPour toute question ou information suppl\u00E9mentaire, veuillez nous contacter.\n\r\nNous esp\u00E9rons pouvoir vous accueillir une prochaine fois."
        },
        smsContent: {
            title: 'ANNULATION CENTRE_NOM',
            body: "Bonjour PARTICIPANT_PRENOM, \n\r\nNous sommes d\u00E9sol\u00E9s de vous informer que votre r\u00E9servation a \u00E9t\u00E9 annul\u00E9e. \n\r\nPour toute question ou information suppl\u00E9mentaire, veuillez nous contacter.\n\r\nNous esp\u00E9rons pouvoir vous accueillir une prochaine fois."
        },
        whatsAppContent: {
            title: 'ANNULATION CENTRE_NOM',
            body: "Bonjour PARTICIPANT_PRENOM, \n\r\nNous sommes d\u00E9sol\u00E9s de vous informer que votre r\u00E9servation a \u00E9t\u00E9 annul\u00E9e. \n\r\nPour toute question ou information suppl\u00E9mentaire, veuillez nous contacter.\n\r\nNous esp\u00E9rons pouvoir vous accueillir une prochaine fois."
        }
    },
    {
        name: 'C.1) Rappel début de séjour',
        style: 'warning',
        enabled: true,
        emailContent: {
            title: 'Votre séjour à CENTRE_NOM commence bientôt!',
            body: "Cher(e) PARTICIPANT_PRENOM, \n\r\nNous vous attendons avec impatience pour votre s\u00E9jour de plong\u00E9e qui commence le DATE_DEBUT_SEJOUR. \n\r\nN'oubliez pas de v\u00E9rifier tous les d\u00E9tails de votre r\u00E9servation sur ESPACE_CLIENT_LIEN_RESA.\n\r\n\u00C0 tr\u00E8s bient\u00F4t!"
        },
        smsContent: {
            title: 'SÉJOUR CENTRE_NOM',
            body: "Cher(e) PARTICIPANT_PRENOM, \n\r\nNous vous attendons avec impatience pour votre s\u00E9jour de plong\u00E9e qui commence le DATE_DEBUT_SEJOUR. \n\r\nN'oubliez pas de v\u00E9rifier tous les d\u00E9tails de votre r\u00E9servation sur ESPACE_CLIENT_LIEN_RESA.\n\r\n\u00C0 tr\u00E8s bient\u00F4t!"
        },
        whatsAppContent: {
            title: 'SÉJOUR CENTRE_NOM',
            body: "Cher(e) PARTICIPANT_PRENOM, \n\r\nNous vous attendons avec impatience pour votre s\u00E9jour de plong\u00E9e qui commence le DATE_DEBUT_SEJOUR. \n\r\nN'oubliez pas de v\u00E9rifier tous les d\u00E9tails de votre r\u00E9servation sur ESPACE_CLIENT_LIEN_RESA.\n\r\n\u00C0 tr\u00E8s bient\u00F4t!"
        }
    },
    {
        name: 'D.1) Réservation reçue - SANS ACOMPTE',
        style: 'success',
        enabled: true,
        _doNotCreateOnNewAccounts: true,
        emailContent: {
            title: 'Votre demande de réservation à CENTRE_NOM',
            body: "Bonjour PARTICIPANT_PRENOM, \n\r\nNous avons bien re\u00E7u votre demande de r\u00E9servation. \n\r\nNous vous contacterons si besoin et vous tiendrons inform\u00E9s d\u00E8s qu'elle sera confirm\u00E9e.\n\r\nPour visualiser votre demande, connectez-vous au lien suivant: ESPACE_CLIENT_LIEN_RESA\n\r\n\u00C0 tr\u00E8s bient\u00F4t!"
        },
        smsContent: {
            disabledByDefault: true,
            title: 'RÉSERVATION CENTRE_NOM',
            body: "Bonjour PARTICIPANT_PRENOM, \n\r\nNous avons bien re\u00E7u votre demande de r\u00E9servation. \n\r\nNous vous contacterons si besoin et vous tiendrons inform\u00E9s d\u00E8s qu'elle sera confirm\u00E9e.\n\r\nPour visualiser votre demande, connectez-vous au lien suivant: ESPACE_CLIENT_LIEN_RESA\n\r\n\u00C0 tr\u00E8s bient\u00F4t!"
        },
        whatsAppContent: {
            disabledByDefault: true,
            title: 'RÉSERVATION CENTRE_NOM',
            body: "Bonjour PARTICIPANT_PRENOM, \n\r\nNous avons bien re\u00E7u votre demande de r\u00E9servation. \n\r\nNous vous contacterons si besoin et vous tiendrons inform\u00E9s d\u00E8s qu'elle sera confirm\u00E9e.\n\r\nPour visualiser votre demande, connectez-vous au lien suivant: ESPACE_CLIENT_LIEN_RESA\n\r\n\u00C0 tr\u00E8s bient\u00F4t!"
        }
    },
    {
        name: 'D.2) Réservation reçue - AVEC ACOMPTE',
        style: 'success',
        enabled: true,
        _doNotCreateOnNewAccounts: true,
        emailContent: {
            title: 'Votre demande de réservation à CENTRE_NOM',
            body: "Bonjour PARTICIPANT_PRENOM, \n\r\nNous avons bien re\u00E7u votre demande de r\u00E9servation. \n\r\nNous vous contacterons si besoin et vous tiendrons inform\u00E9s d\u00E8s qu'elle sera confirm\u00E9e.\n\r\nSi ce n'est pas d\u00E9j\u00E0 fait, merci de proc\u00E9der au r\u00E9glement de l'acompte demand\u00E9 depuis votre espace client.\n\r\nPour visualiser votre demande et r\u00E9gler votre acompte, connectez-vous au lien suivant: ESPACE_CLIENT_LIEN_RESA\n\r\n\u00C0 tr\u00E8s bient\u00F4t!"
        },
        smsContent: {
            disabledByDefault: true,
            title: 'RÉSERVATION CENTRE_NOM',
            body: "Bonjour PARTICIPANT_PRENOM, \n        \r\nNous avons bien re\u00E7u votre demande de r\u00E9servation. \n        \r\nNous vous contacterons si besoin et vous tiendrons inform\u00E9s d\u00E8s qu'elle sera confirm\u00E9e.\n        \r\nSi ce n'est pas d\u00E9j\u00E0 fait, merci de proc\u00E9der au r\u00E9glement de l'acompte demand\u00E9 depuis votre espace client.\n        \r\nPour visualiser votre demande et r\u00E9gler votre acompte, connectez-vous au lien suivant: ESPACE_CLIENT_LIEN_RESA\n        \r\n\u00C0 tr\u00E8s bient\u00F4t!"
        },
        whatsAppContent: {
            disabledByDefault: true,
            title: 'RÉSERVATION CENTRE_NOM',
            body: "Bonjour PARTICIPANT_PRENOM, \n        \r\nNous avons bien re\u00E7u votre demande de r\u00E9servation. \n        \r\nNous vous contacterons si besoin et vous tiendrons inform\u00E9s d\u00E8s qu'elle sera confirm\u00E9e.\n        \r\nSi ce n'est pas d\u00E9j\u00E0 fait, merci de proc\u00E9der au r\u00E9glement de l'acompte demand\u00E9 depuis votre espace client.\n        \r\nPour visualiser votre demande et r\u00E9gler votre acompte, connectez-vous au lien suivant: ESPACE_CLIENT_LIEN_RESA\n        \r\n\u00C0 tr\u00E8s bient\u00F4t!"
        }
    },
    {
        name: 'Z.1) Remerciements séjour',
        style: 'success',
        enabled: true,
        emailContent: {
            title: 'Merci de votre séjour à CENTRE_NOM',
            body: "Bonjour PARTICIPANT_PRENOM, \n      \r\nNous esp\u00E9rons que vous avez appr\u00E9ci\u00E9 votre s\u00E9jour chez nous. Merci d'avoir choisi CENTRE_NOM pour votre exp\u00E9rience de plong\u00E9e!\n      \r\nNous serions ravis de vous revoir bient\u00F4t."
        },
        smsContent: {
            title: 'SÉJOUR CENTRE_NOM',
            body: "Bonjour PARTICIPANT_PRENOM, \n      \r\nNous esp\u00E9rons que vous avez appr\u00E9ci\u00E9 votre s\u00E9jour chez nous. Merci d'avoir choisi CENTRE_NOM pour votre exp\u00E9rience de plong\u00E9e!\n      \r\nNous serions ravis de vous revoir bient\u00F4t."
        },
        whatsAppContent: {
            title: 'SÉJOUR CENTRE_NOM',
            body: "Bonjour PARTICIPANT_PRENOM, \n      \r\nNous esp\u00E9rons que vous avez appr\u00E9ci\u00E9 votre s\u00E9jour chez nous. Merci d'avoir choisi CENTRE_NOM pour votre exp\u00E9rience de plong\u00E9e!\n      \r\nNous serions ravis de vous revoir bient\u00F4t."
        }
    },
];
