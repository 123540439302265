import { BookingGroupType } from '@mabadive/app-common-model';

export const bookingGroupTypeFormatter = {
  format,
};

function format(
  type: BookingGroupType,
  {
    format,
  }: {
    format: 'short' | 'long';
  },
) {
  switch (type) {
    case 'club': {
      return 'Club';
    }
    case 'individual': {
      return 'Particulier';
    }
    case 'company': {
      return format === 'long' ? 'Entreprise' : 'Ent.';
    }
    case 'collectivity': {
      return format === 'long' ? 'Collectivité' : 'Col.';
    }
    case 'association': {
      return format === 'long' ? 'Association' : 'Asso';
    }

    default:
      return type ?? '';
  }
}
