import * as sprintf from 'sprintf-js';
import { stackLogObjectTypeChecker } from './stack-log-object-type-checker.service';

class StackLogMessageFormatter {
  public formatMessage(
    message: any,
    optionalParams: any[],
    options: {
      formatJSON: boolean;
    },
  ): {
    message: string;
    extraParameters?: any[];
  } {
    try {
      if (
        stackLogObjectTypeChecker.isObject(message) ||
        Array.isArray(message)
      ) {
        message = this.stringify(message, { formatJSON: options.formatJSON });
        return {
          message: message,
        };
      }

      const formattedMessage = this.vsprintf(message, optionalParams);

      return {
        message: formattedMessage,
        extraParameters: this.getMessageExtraParameters(
          message,
          optionalParams,
        ),
      };
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error(
        '[LogMessageFormatter.formatMessage] error formatting message "%s": %s',
        message,
        (err as any).message as any,
      );
      // return unformated message
      return message;
    }
  }

  private vsprintf(message: any, optionalParams: any) {
    try {
      return sprintf.vsprintf(message, optionalParams);
    } catch (err) {
      console.error(
        '[ERROR] [StackLogMessageFormatter] Error formatting message: ',
        message,
        optionalParams,
      );
      return message;
    }
  }

  public stringify(
    p: any,
    options: {
      formatJSON: boolean;
    },
  ): string {
    try {
      let s = p;
      if (p != null) {
        if (stackLogObjectTypeChecker.isObject(p)) {
          // format json objects
          if (options.formatJSON) {
            try {
              s = JSON.stringify(p, null, 2);
            } catch (err) {
              console.error('[LogMessageFormatter.stringify] JSON error', err);
            }
          } else {
            s =
              '{' +
              Object.keys(p)
                .map((key) => {
                  return `${key};${this.stringify(p[key], {
                    formatJSON: options.formatJSON,
                  })}`;
                })
                .join(' ,') +
              '}';
          }
        } else if (Array.isArray(p)) {
          // format json objects
          s =
            '[' +
            (p as any[])
              .map((o) => this.stringify(o, { formatJSON: options.formatJSON }))
              .join(',') +
            ']';
        }
      }
      return s;
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error(
        '[LogMessageFormatter.stringify] error formatting object:',
        err,
      );
      // return unformated message
      return '';
    }
  }

  public getMessageExtraParameters(message: any, optionalParams: any[]): any[] {
    if (optionalParams && optionalParams.length) {
      // FIXME support all types of parameters: https://github.com/alexei/sprintf.js
      const argsCount =
        (message.match(/%s/g) || []).length +
        (message.match(/%d/g) || []).length;
      const remainingArgs = optionalParams.length - argsCount;
      if (remainingArgs > 0) {
        // make a copy of parameters and skip parameters formated by sprintf
        return optionalParams.slice(argsCount).filter((p) => p != null);
      }
    }
    return [];
  }
}

export const stackLogMessageFormatter = new StackLogMessageFormatter();
