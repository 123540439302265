import {
  MonthDate,
  MonthPlanning,
  WeekPlanning,
} from '@mabadive/app-common-model';
import { DateServiceLocaleMode, dateService } from '../../../data';
import { weekPlanningBuilder } from './weekPlanningBuilder.service';

export const monthPlanningBuilder = {
  buildMonthPlanning,
};

function buildMonthPlanning({
  focusDate,
  mode,
}: {
  focusDate: Date;
  mode: DateServiceLocaleMode;
}): MonthPlanning {
  if (!focusDate) {
    return undefined;
  }
  const monthDates: MonthDate[] = dateService.getMonthDates(focusDate, {
    mode,
  });

  // Determine the first and last date
  const firstDate = monthDates[0].date;
  const lastDate = monthDates[monthDates.length - 1].date;

  // Build weeksPlanning
  let weeksPlanning: WeekPlanning[] = [];
  let currentDate = new Date(firstDate);

  while (currentDate <= lastDate) {
    let weekPlan = weekPlanningBuilder.buildWeekPlanning({
      focusDate: currentDate,
    });
    weeksPlanning.push(weekPlan);

    // Move to the next week
    if (mode === 'local') {
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 7));
    } else {
      currentDate = new Date(
        currentDate.setUTCDate(currentDate.getUTCDate() + 7),
      );
    }
  }

  const now = new Date();
  const isCurrentMonth =
    focusDate.getUTCMonth() === now.getUTCMonth() &&
    focusDate.getUTCFullYear() === now.getUTCFullYear();

  const monthPlanning: MonthPlanning = {
    monthDates,
    weeksPlanning,
    firstDate,
    lastDate,
    isCurrentMonth,
  };
  return monthPlanning;
}
