import { ClubResumePublicDiveCenter } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { AppUiThemeTextCard } from 'src/business/_core/modules/layout';

export function DiveCenterPublicBadge({
  diveCenter,
  className,
}: {
  diveCenter: ClubResumePublicDiveCenter;
  className?: string;
}) {
  return !diveCenter ? null : (
    <AppUiThemeTextCard
      border={false}
      className={clsx(
        'border text-center text-app-xxs leading-3 uppercase font-bold whitespace-nowrap truncate rounded-sm',
        className,
      )}
      theme={diveCenter?.publicSettings?.diveCenterUiTheme?.location}
    >
      {diveCenter?.publicData?.brand?.locationResume}
    </AppUiThemeTextCard>
  );
}
