import { ClubCommercePurchasePaymentGql_Customer } from '@mabadive/app-common-model';
import { clubDiverAttributesBuilder } from './clubDiverAttributesBuilder.service';

export const bookingMemberResumeAttributesBuilder = {
  buildAttributes,
};
/**
 * Build graphql attributes for BookingResumeMember
 */
function buildAttributes() {
  return `
    _id
    _createdAt
    _updatedAt
    _version
    clubReference
    diveCenterId
    bookingId
    diverId
    mainCertificationReference
    details
    config
    diver {
      ${clubDiverAttributesBuilder.buildAttributes()}
      payments {
        ${ClubCommercePurchasePaymentGql_Customer}
      }
    }
    products_aggregate(where: {type: {_eq: "dive"}}) {
      aggregate {
        count
      }
    }
`;
}
