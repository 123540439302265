import { useEffect, useMemo, useState } from 'react';
import { BehaviorSubject, NEVER, Observable, of } from 'rxjs';
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  filter,
  map,
  tap,
} from 'rxjs/operators';
import { appWebConfig } from 'src/business/_core/modules/root/config';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { rxjsUtilWeb } from 'src/lib/reactive';
import { LoadableContent } from './LoadableContent.type';
import { LoadableContentActions } from './LoadableContentActions.type';
import { loadableContentBuilder } from './loadableContentBuilder.service';
import { LoadableContentPartial } from './LoadableContentPartial.type';

let lastStartDate: Date;

const DEBUG_NAME_TO_LOG = 'xxxxx';

export function useLoadableContent<T>(
  load: () => Observable<LoadableContentPartial<T>>,
  dependencies: any[],
  {
    initialValue,
    defaultValue,
    useSnapshot,
    debugName,
  }: {
    initialValue?: LoadableContentPartial<T>;
    defaultValue?: T;
    useSnapshot?: boolean;
    debugName?: string;
  },
): LoadableContent<T> & LoadableContentActions {
  const { actions, enabled$ } = useMemo(() => {
    const enabledSubject$ = new BehaviorSubject(true);

    const enabled$ = enabledSubject$.pipe(
      distinctUntilChanged(),
      filter((enabled) => !!enabled),
    );

    function stop() {
      enabledSubject$.next(false);
    }

    function retry() {
      enabledSubject$.next(false);
      enabledSubject$.next(true);
    }

    const actions: LoadableContentActions = {
      stop,
      retry,
    };

    return { actions, enabled$ };
  }, []);

  const initialComputedValue = useInitialComputedValue();

  const [value, setValue] = useState<LoadableContent<T>>(initialComputedValue);

  const state = useMemo(() => {
    return {
      ...actions,
      ...value,
    };
  }, [value, actions]);

  useEffect(() => {
    const subscription = enabled$
      .pipe(
        map(() => new Date()),
        tap((startDate) => {
          lastStartDate = startDate;
        }),
        tap((startDate) => {
          if (debugName === DEBUG_NAME_TO_LOG && appWebConfig.envId === 'dev') {
            // eslint-disable-next-line no-console
            console.info(
              `[useLoadableContent] ${
                debugName ? `[${debugName}]` : ''
              } startDate:`,
              startDate,
            );
          }
        }),
        concatMap((startDate) =>
          load().pipe(
            tap((value) => {
              const duration = new Date().getTime() - lastStartDate.getTime();
              // if (duration > 50 && duration < 500) {
              //   appLogger.warn(`[useLoadableContent] ${debugName ? `[${debugName}]` : ''} loading duration: ${duration}ms`);
              // }
              if (
                debugName === DEBUG_NAME_TO_LOG &&
                appWebConfig.envId === 'dev'
              ) {
                // eslint-disable-next-line no-console
                console.info(
                  `[useLoadableContent] ${
                    debugName ? `[${debugName}]` : ''
                  } value:`,
                  value,
                );
              }
              // re-init start date for next emission
              lastStartDate = new Date();
            }),
            distinctUntilChanged(),
            map((loaded) => {
              return loadableContentBuilder.buildLoadableContentFromPartial<T>(
                loaded,
                { defaultValue, startDate },
              );
            }),
            catchError((err) => {
              console.error(err);
              appLogger.warn(
                `[useLoadableContent] ${
                  debugName ? `[${debugName}]` : ''
                } error loading data`,
                err,
              );
              const endDate = new Date();
              const durationInMs = startDate
                ? endDate.getTime() - startDate.getTime()
                : undefined;
              const loadableContent: LoadableContent<T> = {
                content: value.content,
                contentState: value.contentState ? value.contentState : 'none',
                lastActionStatus: 'error',
                startDate,
                endDate,
                durationInMs,
              };
              return of(loadableContent);
            }),
          ),
        ),
      )
      .subscribe((newValue) => {
        if (debugName === DEBUG_NAME_TO_LOG && appWebConfig.envId === 'dev') {
          // eslint-disable-next-line no-console
          console.info(
            `[useLoadableContent] [${debugName}] new value:`,
            newValue,
          );
        }
        setValue(newValue);
      });

    return () => {
      if (debugName === DEBUG_NAME_TO_LOG && appWebConfig.envId === 'dev') {
        // eslint-disable-next-line no-console
        console.info(
          `[useLoadableContent] [${debugName}] UNSUSCRIBE`,
          subscription,
        );
      }
      subscription.unsubscribe();
    };
    // RUN only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  function useInitialComputedValue() {
    return useMemo(() => {
      if (initialValue) {
        return loadableContentBuilder.buildLoadableContentFromPartial(
          initialValue,
          { defaultValue, startDate: new Date() },
        );
      }
      const snapshot = useSnapshot
        ? rxjsUtilWeb.getSnapshot(
            load().pipe(
              catchError((err) => {
                appLogger.warn(
                  `[useLoadableContent] ${
                    debugName ? `[${debugName}]` : ''
                  } error loading snapshot data`,
                  err,
                );
                return NEVER;
              }),
            ),
          )
        : undefined;

      if (snapshot) {
        return loadableContentBuilder.buildLoadableContentFromPartial(
          snapshot,
          { defaultValue, startDate: new Date() },
        );
      }
      const defaultContent: LoadableContent<T> = {
        content: defaultValue,
        contentState: 'none',
        lastActionStatus: 'in-progress',
        startDate: new Date(),
      };

      return defaultContent;
      // RUN only once
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
  }

  return state;
}
