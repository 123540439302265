"use strict";
exports.__esModule = true;
exports.EMPTY_BOOKLET_EDIT_RESULT = void 0;
exports.EMPTY_BOOKLET_EDIT_RESULT = {
    appBookletPages: {
        created: [],
        updated: [],
        deletedIds: []
    }
};
