import { CustomerCreateOnlineBooking } from '@mabadive/app-common-model';
import { apiClient } from 'src/_common-browser';

export const customerOnlineBookingClient = {
  createOnlineBooking,
};

function createOnlineBooking({
  booking,
}: {
  booking: CustomerCreateOnlineBooking;
}): Promise<{
  targetLink: {
    targetDomain: string;
    targetRelativeUrl: string;
  };
  authToken: string;
}> {
  return apiClient
    .put<any>('/customer/online-booking/create', {
      authenticate: true,
      json: {
        booking,
      },
    })
    .toPromise();
}
