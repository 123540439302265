import {
  ClubResumePublicDiveSite,
  DiveSessionTimeDayPeriod,
} from '@mabadive/app-common-model';
import { clubDiveSessionThemeBuilder } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';
import { useClubResumePublic } from 'src/business/_core/store';
import { AppEmojiIcon } from 'src/lib/form/components/AppEmojiPicker';

export const ClubDiveSessionHeaderDiveSite = function ({
  diveSiteId,
  sessionIndex,
  time,
  isMultiSession,
  className,
}: {
  diveSiteId: string;
  sessionIndex: number;
  time: Date;
  isMultiSession: boolean;
  className?: string;
}) {
  const clubResume = useClubResumePublic();

  const diveSite: ClubResumePublicDiveSite = useMemo(() => {
    if (diveSiteId) {
      return clubResume?.diveSites.find((ds) => ds._id === diveSiteId);
    }
  }, [clubResume?.diveSites, diveSiteId]);

  const timeDayPeriod: DiveSessionTimeDayPeriod = useMemo(
    () => clubDiveSessionThemeBuilder.buildTimeDayPeriod(time),
    [time],
  );
  return (
    <div
      className={clsx(
        'flex gap-0.5 sm:gap-1 items-center overflow-hidden',
        className,
      )}
    >
      {isMultiSession && <AppHeroIcons.diveSite className={'w-4 h-4'} />}
      {isMultiSession && <span>N°{sessionIndex} </span>}

      {diveSite?.emojiId && (
        <div
          className={clsx(
            `rounded-full bg-white border border-day-period-${timeDayPeriod}-dark`,
            'ml-1 sm:ml-2 px-0.5 ',
          )}
        >
          <AppEmojiIcon id={diveSite.emojiId} size={14} />
        </div>
      )}
      {diveSite?.name && (
        <div className={'truncate text-ellipsis'}>
          {diveSite.name.toUpperCase()}
        </div>
      )}
    </div>
  );
};
