import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { libLogger } from '../../libLogger.service';
import { graphqlClientProvider } from '../graphqlClientProvider.service';
import { graphqlClientStoreProvider } from '../graphqlClientStoreProvider.service';

export const graphqlClientAuth = {
  login,
  logout,
};

function login(jwtToken: string) {
  if (!jwtToken) {
    return graphqlClientAuth.logout();
  }

  libLogger.debug('[graphql][graphqlClientAuth.login] new jwtToken');

  // @see https://www.apollographql.com/docs/react/recipes/authentication/#reset-store-on-logout
  return graphqlClientProvider.clearStore().pipe(
    tap(() => {
      const graphqlClientStore = graphqlClientStoreProvider.get();
      graphqlClientStore.authenticationToken.set(
        jwtToken,
        'graphqlClientAuth.login',
      );
    }),
    tap(() => libLogger.debug('[graphql][graphqlClientAuth.login] end')),
    catchError((err) => {
      libLogger.error('[graphql][graphqlClientAuth.login] end', err);
      return throwError(err);
    }),
  );
}

function logout() {
  libLogger.info('[graphql][graphqlClientAuth.logout]');

  const graphqlClientStore = graphqlClientStoreProvider.get();
  graphqlClientStore.authenticationToken.unload('graphqlClientAuth.logout');

  // @see https://www.apollographql.com/docs/react/recipes/authentication/#reset-store-on-logout
  return graphqlClientProvider.clearStore().pipe(
    tap(() => libLogger.debug('[graphql][graphqlClientAuth.logout] end')),
    catchError((err) => {
      libLogger.error('[graphql][graphqlClientAuth.logout] end', err);
      return throwError(err);
    }),
  );
}
