/*----------- Render Prop -----------*/

import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import React, { Fragment, useRef } from 'react';
import { AppHackableCalendar } from './AppHackableCalendar';
import { AppHackableCalendarProps } from './props';

export const AppHackableCalendarDialog: React.FC<
  AppHackableCalendarProps & {
    open: boolean;
    setOpen: (open: boolean) => void;
    children?: React.ReactNode | React.ReactNode[];
  }
> = ({ dialogZIndex = 'z-10', children, open, setOpen, ...props }) => {
  // const [referenceElement, setReferenceElement] = useState<any>();
  // const [popperElement, setPopperElement] = useState<any>();
  // const { styles, attributes } = usePopper(referenceElement, popperElement);

  const cancelButtonRef = useRef(null);

  return (
    <>
      {children && children}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className={clsx('fixed inset-0 overflow-y-auto', dialogZIndex)}
          open={open}
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-[100dvh] pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-[100dvh]"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <AppHackableCalendar
                  className="w-96 max-w-full mt-2 sm:mt-6 md:mt-10 lg:mt-9"
                  {...props}
                  onSelectDate={(d) => {
                    props.onSelectDate && props.onSelectDate(d);
                    setOpen(false);
                  }}
                />
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-app-primary text-base font-medium text-white hover:bg-app-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-app-primary sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Fermer
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
