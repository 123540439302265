import {
  ClubSettingsServices,
  DiveServiceOrganizationReference,
  DiveServiceOrganizationReferenceFrench,
  DiveServiceOrganizationReferenceInternational,
  OrgGroupReference,
} from '@mabadive/app-common-model';

export const diveServiceOrganizationFilter = {
  isOrgEnabled,
  isOrgGroupEnabled,
  getDefaultOrganizationReference,
};

function isOrgEnabled(
  organizationReference: DiveServiceOrganizationReference,
  { services }: { services: ClubSettingsServices },
) {
  return (
    (services.french.enabled
      && services.french.organizationReferences.includes(
        organizationReference as DiveServiceOrganizationReferenceFrench,
      ))
    || (services.international.enabled
      && services.international.organizationReferences.includes(
        organizationReference as DiveServiceOrganizationReferenceInternational,
      ))
  );
}
function isOrgGroupEnabled(
  orgGroupReference: OrgGroupReference,
  { services }: { services: ClubSettingsServices },
) {
  return (
    (services.french.enabled && orgGroupReference === 'cmas')
    || (services.international.enabled && orgGroupReference === 'padi')
  );
}

function getDefaultOrganizationReference({
  services,
}: {
  services: ClubSettingsServices;
}) {
  return services.defaultOrgGroup === 'french'
    && services.french.defaultOrganizationReference
    ? services.french.defaultOrganizationReference
    : services.defaultOrgGroup === 'international'
      && services.international.defaultOrganizationReference
      ? services.international.defaultOrganizationReference
      : 'ffessm';
}
