import { OnlineBookingState } from '@mabadive/app-common-model';

export const onlineBookingStateFormatter = {
  formatState,
};

function formatState(state: OnlineBookingState): string {
  if (!state) {
    return '';
  }
  switch (state) {
    case 'draft':
      return 'En attente paiement';
    case 'new':
      return 'Nouveau';
    case 'pending-club':
      return 'À valider';
    case 'pending-customer':
      return 'En attente client';
    case 'confirmed':
      return 'Confirmée';
    case 'cancelled':
      return 'Annulée';
  }
}
