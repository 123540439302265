import { DiveMode, DiveSessionGroupDiveMode } from '@mabadive/app-common-model';

export const diveSessionGroupDiveModeBuilder = {
  buildFromParticipantsDiveModes,
};

const PRIORITY_DIVE_MODE: DiveMode[] = [
  'first-dive', // plongée bouteille - baptême
  'training', // plongée bouteille - formation
  'supervised', // plongée bouteille - exploration - encadré
  'autonomous', // plongée bouteille - exploration - autonome
  'autoSupervised', // auto-encadrés (clubs autonomes)
  'instructor', // plongée bouteille - exploration - guide de palanquée
  'free-dive-training', // formation apnée
  'free-dive', // apnée (encadré ou non) (+ formation)
  'free-dive-auto', // apnée autonome
  'snorkeling', // PMT (non-encadré) (+ formation)
  'snorkelingSupervised', // Randonnée palmée (PMT encadré) (+ formation)
  'watchingTour', // excursion d'observation
  'theoretical-training', // formation théorique
  'observer', // accompagnateur
  'unknown', // ???
];

function buildFromParticipantsDiveModes({
  diveModes,
  hasDiveGuide,
  hasInstructor,
  debugContext,
}: {
  diveModes: DiveMode[];
  hasInstructor: boolean;
  hasDiveGuide: boolean;
  debugContext?: string;
}): DiveSessionGroupDiveMode {
  const diveMode = diveModes.reduce((acc, diveMode) => {
    if (!acc) {
      return diveMode;
    }
    if (acc !== diveMode) {
      const priorityDiveMode = PRIORITY_DIVE_MODE.find(
        (priorityDiveMode) =>
          acc === priorityDiveMode || diveMode === priorityDiveMode,
      );
      if (priorityDiveMode) {
        return priorityDiveMode;
      }
    }
    return acc;
  }, undefined);
  if (hasInstructor && diveMode === 'autonomous') {
    return 'supervised';
  }

  if (
    hasDiveGuide &&
    (diveMode === 'supervised' ||
      diveMode === 'training' ||
      diveMode === 'first-dive')
  ) {
    return 'autonomous';
  }
  return diveMode as DiveSessionGroupDiveMode;
}
