import { ClubStaffMemberAvailability } from '@mabadive/app-common-model';
import { dataSorter, dateService } from '../../data';

// inspiré de defaultPlanningPeriodConfigsSelector
export const clubStaffMemberAvailabilitySelector = {
  isAvailabilityValidForDate,
  selectAvailabilityPeriod,
};

function selectAvailabilityPeriod({
  date,
  availabilities,
}: {
  date: Date;
  availabilities: ClubStaffMemberAvailability[];
}): ClubStaffMemberAvailability {
  const filteredAvailabilities = availabilities.filter((x) => {
    return isAvailabilityValidForDate({ availability: x, date });
  });
  if (filteredAvailabilities.length === 0) {
    return undefined;
  }
  const sortedAvailabilities = dataSorter.sortMultiple(filteredAvailabilities, {
    getSortAttributes: (c) => {
      return [
        {
          value: c.beginDate ? new Date(c.beginDate)?.getTime() : undefined,
          asc: false,
        },
        {
          value: c.endDate ? new Date(c.endDate).getTime() : undefined,
          asc: true,
        },
        {
          value: c._id,
        },
      ];
    },
  });
  if (sortedAvailabilities.length > 0) {
    return selectNearestPeriod({ availabilities: sortedAvailabilities, date });
  }
}

function isAvailabilityValidForDate({
  availability,
  date,
}: {
  availability: ClubStaffMemberAvailability;
  date: Date;
}): unknown {
  return (
    (!availability.beginDate ||
      dateService.isBefore(availability.beginDate, date) ||
      dateService.isSameDayUTC(availability.beginDate, date)) &&
    (!availability.endDate ||
      dateService.isFutureDayUTC(availability.endDate, date) ||
      dateService.isSameDayUTC(availability.endDate, date))
  );
}

function selectNearestPeriod({
  date,
  availabilities,
}: {
  date: Date;
  availabilities: ClubStaffMemberAvailability[];
}): ClubStaffMemberAvailability {
  const sortedAvailabilities = dataSorter.sortMultiple(availabilities, {
    getSortAttributes: (c) => {
      // en triant par date de début, ça suffit (et par date de fin inversée en cas de périodes avec même date de début)
      return [
        {
          value: c.beginDate ? new Date(c.beginDate)?.getTime() : undefined,
          asc: false,
        },
        {
          value: c.endDate ? new Date(c.endDate).getTime() : undefined,
          asc: true,
        },
        {
          value: c._id,
        },
      ];
    },
  });

  return sortedAvailabilities?.[0];
}
