/* eslint-disable @typescript-eslint/no-unused-vars */
import { Customer_ClubSettingsPublicCustomer } from '@mabadive/app-common-model';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useAppSecurityUser } from 'src/business/auth/services';
import { COUNTRY_OPTIONS } from 'src/business/dive-center/components';
import { AppInputRHF } from 'src/lib/form';
import { AppFormControlRHF } from 'src/lib/form/components/AppFormControl/AppFormControlRHF';
import { AppSingleAutocomplete2RHF } from 'src/lib/form/components/AppSingleAutocomplete';
import { DiveCenterBookingMemberEditFormModel } from '../model';

export const DiveCenterBookingMemberEditFormAddress = ({
  form,
  participantsIndex,
  clubSettings,
  className = '',
}: {
  form: UseFormReturn<DiveCenterBookingMemberEditFormModel>;
  participantsIndex: number;
  clubSettings: Customer_ClubSettingsPublicCustomer;
  className?: string;
}) => {
  const securityUser = useAppSecurityUser();

  const baseFormName = `participants.${participantsIndex}` as const;

  const { control, setValue } = form;

  const displayFields =
    clubSettings?.publicSettings?.customerSpace?.displayFields;

  return (
    <div
      className={`w-full flex flex-col sm:col-span-2 md:col-span-4 sm:grid sm:grid-cols-4 gap-4 ${className}`}
    >
      {displayFields?.diversPostalAddressInfo?.line1?.edit && (
        <AppFormControlRHF
          className={'sm:col-span-2 w-full'}
          label="Adresse postale"
          control={control}
          required={displayFields?.diversPostalAddressInfo?.line1?.required}
          name={`${baseFormName}.diver.homeAddress.line1` as const}
          renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
        />
      )}
      <div className={'sm:col-span-2 w-full flex flex-row gap-4'}>
        {displayFields?.diversPostalAddressInfo?.postalCode?.edit && (
          <AppFormControlRHF
            label="Code postal"
            control={control}
            name={`${baseFormName}.diver.homeAddress.postalCode` as const}
            required={
              displayFields?.diversPostalAddressInfo?.postalCode?.required
            }
            renderComponent={(props) => (
              <AppInputRHF {...props} className={'w-24'} />
            )}
          />
        )}
        {displayFields?.diversPostalAddressInfo?.city?.edit && (
          <AppFormControlRHF
            className={'w-full'}
            label="Ville"
            control={control}
            name={`${baseFormName}.diver.homeAddress.city` as const}
            required={displayFields?.diversPostalAddressInfo?.city?.required}
            renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
          />
        )}
      </div>
      <div className={'sm:col-span-2 w-full flex flex-row gap-4'}>
        {displayFields?.diversPostalAddressInfo?.state?.edit && (
          <AppFormControlRHF
            className={'w-full'}
            label="État"
            control={control}
            name={`${baseFormName}.diver.homeAddress.state` as const}
            required={displayFields?.diversPostalAddressInfo?.state?.required}
            renderComponent={(props) => <AppInputRHF {...props} fullWidth />}
          />
        )}
        {displayFields?.diversPostalAddressInfo?.country?.edit && (
          <AppFormControlRHF
            className={'w-full'}
            label="Pays"
            control={control}
            name={`${baseFormName}.diver.homeAddress.country` as const}
            required={displayFields?.diversPostalAddressInfo?.country?.required}
            renderComponent={(props) => (
              <AppSingleAutocomplete2RHF
                {...props}
                options={COUNTRY_OPTIONS}
                fullWidth
              />
            )}
          />
        )}
      </div>
    </div>
  );
};
