import { ClubOfferState } from '@mabadive/app-common-model';

export const clubOfferStateFormatter = {
  formatClubOfferState,
};

function formatClubOfferState(state: ClubOfferState): string {
  switch (state) {
    case 'draft': {
      return 'Nouveau compte';
    }
    case 'trial': {
      return `Offre d'essai gratuite`;
    }
    case 'active': {
      return 'Forfait actif';
    }
    case 'archived': {
      return 'Compte expiré';
    }
    case 'demo': {
      return 'Compte de démo';
    }
  }
  return state;
}
