import {
  ClubPlanningLightSessionDef,
  ClubStaffMemberDailyAvailabilityPeriod,
  DiveCenterDailyConfig,
  DiveCenterResume,
  DiveSessionStaffMemberTableModelStaffMember,
  StaffMemberDailyAvailabilityResume,
  StaffMemberResumeSessionsDayModel,
  StaffMemberResumeSessionsModel,
} from '@mabadive/app-common-model';
import {
  commonDiveSessionReferenceParser,
  diveSessionsSorter,
} from '../../business';
import { arrayBuilder } from '../../data';
import {
  staffMemberAvailabilityFinder,
  staffMemberDefaultAvailabilityFinder,
} from '../../pro-web/staffMembers';
import { diveSessionStaffMembersTableModelBuilder } from './diveSessionStaffMembersTableModelBuilder.service';
import { staffMemberResumeSessionsSorter } from './staffMemberResumeSessionsSorter.service';

export const staffMemberResumeSessionsBuilder = {
  buildStaffMemberResumeSessions,
};

function buildStaffMemberResumeSessions({
  diveCenterResume,
  sessions, // week or day sessions
  staffFirstNameBefore,
  staffScope,
  dailyConfigs,
  days,
}: {
  diveCenterResume: DiveCenterResume;
  dailyConfigs: DiveCenterDailyConfig[];
  sessions: ClubPlanningLightSessionDef[];
  staffFirstNameBefore: boolean;
  staffScope: 'all-available' | 'assigned' | 'all-with-active-working-period';
  days: {
    date: Date;
    dayReference: string;
  }[];
}): {
  diveSessions: ClubPlanningLightSessionDef[];
  staffMembersResumeSessions: StaffMemberResumeSessionsModel[];
} {
  const sortedSessions = diveSessionsSorter.sortDiveSessionsBy(sessions, {
    attributeName: 'time',
    asc: true,
  });

  const staffMembersResumeSessionsTemp: StaffMemberResumeSessionsModel[] = (
    diveCenterResume?.staffMembers ?? []
  ).map((staffMember) => {
    const model: StaffMemberResumeSessionsModel = {
      staffMember,
      activeOnAnySession: false,
      availableOnAnySession: false,
      availableInPeriod: false,
      inWorkingPeriod: false,
      days: days.map((day) => {
        const date = day.date;
        const dayReference = day.dayReference;
        const dailyConfig = dailyConfigs.find(
          (x) => x.dateReference === dayReference,
        );

        const dailyAvailabilityResume: StaffMemberDailyAvailabilityResume =
          staffMemberAvailabilityFinder.getDailyAvailabilityResume({
            dailyConfig,
            date,
            staffMember,
          });

        const availableToday = dailyAvailabilityResume?.isAvailableToday;

        const availabilityPeriod: ClubStaffMemberDailyAvailabilityPeriod =
          dailyAvailabilityResume?.availabilityPeriod;

        const resumeSessionsDay: StaffMemberResumeSessionsDayModel = {
          dailyConfig,
          dayReference,
          date,
          activeToday: false,
          availableToday: availableToday,
          availableOrActiveToday: availableToday,
          availabilityPeriod,
          sessionsDetails: [],
          dayTotalSessionsCount: sortedSessions.filter(
            (x) => x.dayReference === dayReference,
          ).length,
          dailyAvailableRoles: [],
          dailyActiveRoles: [],
          dailyAssignedRoles: [],
        };
        return resumeSessionsDay;
      }),
    };
    return model;
  });

  sortedSessions.forEach((session) => {
    const date = commonDiveSessionReferenceParser.parseDayReference(
      session.dayReference,
    );
    const staffMembersFull: DiveSessionStaffMemberTableModelStaffMember[] =
      diveSessionStaffMembersTableModelBuilder.buildStaffMembersFull({
        staffMembers: diveCenterResume?.staffMembers,
        filterMode: 'all',
        diveSession: session,
        clubParticipants: session.participants,
        groups: session.groups,
        staffFirstNameBefore: true,
        dailyConfigs,
        clubSettings: diveCenterResume?.clubResume?.clubSettings,
      });

    staffMembersResumeSessionsTemp.forEach((d) => {
      let resumeSessionsDay: StaffMemberResumeSessionsDayModel = d.days.find(
        (day) => day.dayReference === session.dayReference,
      );

      const staffMemberFull = staffMembersFull.find(
        (s) => s.staffMember._id === d.staffMember._id,
      );

      if (!resumeSessionsDay) {
        // ne doit pas arriver, sauf si on n'a pas passé "days" en paramète (ce qu'on fait uniquement dans le contexte d'édition de session)
        const availableToday =
          staffMemberFull.dailyAvailabilityResume?.isAvailableToday;

        const availabilityPeriod: ClubStaffMemberDailyAvailabilityPeriod =
          staffMemberFull.dailyAvailabilityResume?.availabilityPeriod;

        resumeSessionsDay = {
          dailyConfig: staffMemberFull.dailyConfig,
          dayReference: session.dayReference,
          date,
          activeToday: false,
          availableToday: availableToday,
          availableOrActiveToday: availableToday,
          availabilityPeriod,
          sessionsDetails: [],
          dayTotalSessionsCount: sortedSessions.filter(
            (x) => x.dayReference === session.dayReference,
          ).length,
          dailyAvailableRoles: [],
          dailyActiveRoles: [],
          dailyAssignedRoles: [],
        };
        d.days.push(resumeSessionsDay);
      }

      if (staffMemberFull) {
        resumeSessionsDay.sessionsDetails.push({
          session,
          staffMemberFull,
        });
        if (staffMemberFull.meta.active) {
          resumeSessionsDay.activeToday = true;
          resumeSessionsDay.availableOrActiveToday = true;
        }
        if (staffMemberFull.meta.available) {
          resumeSessionsDay.availableToday = true;
          resumeSessionsDay.availableOrActiveToday = true;
        }

        resumeSessionsDay.dailyAvailableRoles = arrayBuilder.filterDuplicated(
          resumeSessionsDay.dailyAvailableRoles.concat(
            staffMemberFull.sessionAssigmentResume.availableRoles,
          ),
        );
        resumeSessionsDay.dailyActiveRoles = arrayBuilder.filterDuplicated(
          resumeSessionsDay.dailyActiveRoles.concat(
            staffMemberFull.sessionAssigmentResume.activeRoles,
          ),
        );
        resumeSessionsDay.dailyAssignedRoles = arrayBuilder.filterDuplicated(
          resumeSessionsDay.dailyAssignedRoles.concat(
            staffMemberFull.sessionAssigmentResume.assignedRoles,
          ),
        );
      }
    });
  });

  staffMembersResumeSessionsTemp.forEach((rs) => {
    rs.days.forEach((d) => {
      if (d.activeToday) {
        rs.activeOnAnySession = true;
      }
      if (d.availableOrActiveToday) {
        rs.availableOnAnySession = true;
      }
      if (d.availableOrActiveToday) {
        rs.availableInPeriod = true;
      }

      const availability: StaffMemberDailyAvailabilityResume =
        staffMemberDefaultAvailabilityFinder.getDefaultDailyAvailability(
          rs.staffMember,
          {
            date: d.date,
          },
        );
      if (availability.isAvailableOnPeriod) {
        rs.inWorkingPeriod = true;
      }
    });
  });

  const filteredStaffMembersResumeSessions: StaffMemberResumeSessionsModel[] =
    staffMembersResumeSessionsTemp.filter((x) => {
      if (staffScope === 'all-with-active-working-period') {
        if (
          x.activeOnAnySession ||
          x.availableOnAnySession ||
          x.inWorkingPeriod
        ) {
          return true;
        }
      } else if (staffScope === 'all-available') {
        return x.activeOnAnySession || x.availableOnAnySession;
      } else {
        return x.activeOnAnySession;
      }
    });
  const staffMembersResumeSessions =
    staffMemberResumeSessionsSorter.sortByAvailability(
      filteredStaffMembersResumeSessions,
      { staffFirstNameBefore },
    );
  const result = {
    diveSessions: sortedSessions,
    staffMembersResumeSessions,
  };

  return result;
}
