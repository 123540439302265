import { CommercePaymentPlatformForClubResumeGql_Company } from '@mabadive/app-common-model';
import { dateService } from '../../data';

export type PaymentPlatformFormatterFormat = 'simple' | 'full-technical';

export const paymentPlatformFormatter = {
  formatPaymentPlatform,
};

function formatPaymentPlatform(
  paymentPlatform: CommercePaymentPlatformForClubResumeGql_Company,
  { format }: { format: PaymentPlatformFormatterFormat },
) {
  if (!paymentPlatform) {
    return;
  }
  if (format === 'simple') {
    return paymentPlatform.platformRef;
  }
  const chunks: string[] = [
    paymentPlatform.platformRef,
    paymentPlatform.platformEnv,
    dateService.formatLocal(paymentPlatform._createdAt, 'DD/MM/YYYY HH:mm'),
    paymentPlatform.enabled ? 'actif' : 'inactif',
  ];
  return chunks.filter((x) => !!x?.trim().length).join(' - ');
}
