import {
  ClubSettingsGeneral,
  RegionalTax,
  RegionalTaxCategory,
  RegionalTaxContext,
  RegionalTaxProductType,
  RegionalTaxResume,
  RegionalTaxResumeCategory,
} from '@mabadive/app-common-model';
import { regionalSettingsBuilder } from '../../business/localisation';
import { arrayBuilder, objectMapBuilder } from '../../data';

export const taxesContextBuilder = {
  buildTaxContext,
};

function buildTaxContext({
  generalSettings,
  effectiveDate,
}: {
  generalSettings: ClubSettingsGeneral;
  effectiveDate: Date;
}): RegionalTaxContext {
  const taxes = generalSettings.taxes;

  if (!taxes.enabled) {
    return { ...EMPTY_REGIONAL_CONTEXT, generalSettings };
  }

  const regionalSettings = regionalSettingsBuilder.buildByCountryCode({
    effectiveDate,
    countryIsoCode: generalSettings.countryIsoCode,
  });

  // const divingTaxesTva = divingTaxes.filter((x) => x.type === 'vat');
  // const divingTaxesSocial = divingTaxes.filter((x) => x.type === 'social');

  // const totalDivingTaxesRatePercent = divingTaxes.reduce(
  //   (acc, x) => acc + x.ratePercent,
  //   0,
  // );

  const taxeResumes: RegionalTaxResume[] = Object.keys(
    taxes.taxesReferencesByProduct,
  ).map((productType) => {
    // pour chaque type de produit
    const taxesReferences: string[] = taxes.enabled
      ? (taxes.taxesReferencesByProduct as any)[productType]
      : [];

    // on récupère les taxes associées
    const productTaxes: Omit<RegionalTax, '_id' | 'regionLocalisationId'>[] =
      taxesReferences.map((taxReference) =>
        (regionalSettings.taxes ?? []).find(
          (t) => t.reference === taxReference,
        ),
      );

    // puis on les regroupe par catégorie (tva, social...)
    const productTaxesByCategory = objectMapBuilder.buildMapCollection(
      productTaxes,
      'category',
    );
    // et on en fait une synthèse: RegionalTaxResume
    const categories: RegionalTaxResumeCategory[] = Object.keys(
      productTaxesByCategory,
    ).map((taxCategory) => ({
      taxCategory: taxCategory as RegionalTaxCategory,
      totalRatePercent: productTaxesByCategory[taxCategory].reduce(
        (acc, x) => acc + x.ratePercent,
        0,
      ),
    }));

    const categoriesMap = objectMapBuilder.buildMap(
      categories,
      'taxCategory',
    ) as {
      [taxCategory in RegionalTaxCategory]: RegionalTaxResumeCategory;
    };

    const totalRatePercent = productTaxes.reduce(
      (acc, x) => acc + x.ratePercent,
      0,
    );
    const taxResume: RegionalTaxResume = {
      productType: productType as RegionalTaxProductType,
      categories,
      categoriesMap,
      totalRatePercent,
    };
    return taxResume;
  });

  const taxeResumesByProductType = objectMapBuilder.buildMap(
    taxeResumes,
    'productType',
  ) as {
    [productType in RegionalTaxProductType]: RegionalTaxResume;
  };

  const enabledCategories = arrayBuilder.filterDuplicated(
    taxeResumes.reduce(
      (acc, t) => acc.concat(t.categories.map((c) => c.taxCategory)),
      [] as RegionalTaxCategory[],
    ),
  );

  return {
    enabledCategories,
    enabled: taxes.enabled,
    taxeResumes,
    taxeResumesByProductType,
    generalSettings,
  };
}
const EMPTY_CATEGORIES_MAP: {
  [taxCategory in RegionalTaxCategory]: RegionalTaxResumeCategory;
} = {
  social: {
    taxCategory: 'social',
    totalRatePercent: 0,
  },
  vat: {
    taxCategory: 'vat',
    totalRatePercent: 0,
  },
};
const EMPTY_REGIONAL_CONTEXT: Omit<RegionalTaxContext, 'generalSettings'> = {
  enabled: false,
  enabledCategories: [],
  taxeResumes: [],
  taxeResumesByProductType: {
    diving: {
      productType: 'diving',
      totalRatePercent: 0,
      categories: [],
      categoriesMap: EMPTY_CATEGORIES_MAP,
    },
    shop: {
      productType: 'shop',
      totalRatePercent: 0,
      categories: [],
      categoriesMap: EMPTY_CATEGORIES_MAP,
    },
    rental: {
      productType: 'rental',
      totalRatePercent: 0,
      categories: [],
      categoriesMap: EMPTY_CATEGORIES_MAP,
    },
    food: {
      productType: 'food',
      totalRatePercent: 0,
      categories: [],
      categoriesMap: EMPTY_CATEGORIES_MAP,
    },
    accommodation: {
      productType: 'accommodation',
      totalRatePercent: 0,
      categories: [],
      categoriesMap: EMPTY_CATEGORIES_MAP,
    },
  },
};
