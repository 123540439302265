import {
  ClubProductPackageOfferView,
  DiveTrainingReference,
} from '@mabadive/app-common-model';
import { arrayBuilder } from '../../../data/array/arrayBuilder.service';
import { DiveServiceOfferFilterCriteria } from '../../offer/DiveServiceOfferFilterCriteria.type';
import { diveServiceOrganizationReferenceSorter } from '../../offer/diveServiceOrganizationReferenceSorter.service';

export type ClubProductPackageOfferViewPlanGroup = {
  divesCount: number;
  successiveDivesCount: number;
  minDivesCount?: number;
  maxDivesCount?: number;
};
export const clubDiveServiceOfferViewTransformer = {
  buildDiveTrainingReferencesFromOffers,
  buildOrganizationReferencesFromOffers,
  findByCriteria,
  filterByCriteria,
};

function buildDiveTrainingReferencesFromOffers(
  productPackageOffers: ClubProductPackageOfferView[],
) {
  return arrayBuilder.filterDuplicated(
    productPackageOffers.map(
      (productPackageOffer) =>
        productPackageOffer.productPackage.trainingAttributes
          ?.diveTrainingReference,
    ),
  );
}

function buildOrganizationReferencesFromOffers(
  productPackageOffers: ClubProductPackageOfferView[],
  {
    diveTrainingReference,
  }: { diveTrainingReference?: DiveTrainingReference } = {},
) {
  if (diveTrainingReference) {
    // display only matching organizations
    productPackageOffers = filterByCriteria(productPackageOffers, {
      training: { diveTrainingReference },
    });
  }
  const organizationReferences = arrayBuilder.filterDuplicated(
    productPackageOffers.map(
      (productPackageOffer) =>
        productPackageOffer.productPackage.productAttributes
          .defaultOrganizationReference,
    ),
  );

  return diveServiceOrganizationReferenceSorter.sortOrganizationReferences(
    organizationReferences,
  );
}

function findByCriteria(
  productPackageOffers: ClubProductPackageOfferView[],
  criteria: DiveServiceOfferFilterCriteria,
) {
  return productPackageOffers.find((offer) => matchCriteria(offer, criteria));
}

function filterByCriteria(
  productPackageOffers: ClubProductPackageOfferView[],
  criteria: DiveServiceOfferFilterCriteria,
) {
  return productPackageOffers.filter((offer) => matchCriteria(offer, criteria));
}

function matchCriteria(
  offer: ClubProductPackageOfferView,
  { reference, plan, training }: DiveServiceOfferFilterCriteria,
) {
  const { trainingAttributes } = offer.productPackage;
  const { diveAttributes } = offer.productPackage;
  const { productAttributes } = offer.productPackage;

  if (training) {
    return (
      (!training?.diveTrainingReference ||
        trainingAttributes?.diveTrainingReference ===
          training.diveTrainingReference) &&
      (!training?.organizationReference ||
        (productAttributes.organizationReferences ?? []).includes(
          training.organizationReference,
        ))
    );
  }
  return (
    (!plan?.divesCount ||
      (diveAttributes && diveAttributes.divesCount === plan.divesCount)) &&
    (!plan?.equipment ||
      (diveAttributes && diveAttributes.equipment === plan.equipment)) &&
    (!plan?.supervision ||
      (diveAttributes && diveAttributes.supervision === plan.supervision)) &&
    (!reference || offer.reference === reference)
  );
}
