import { ClubDiveSiteGroup } from '@mabadive/app-common-model';
import { dataSorter } from '../../data/sorter/dataSorter.service';
import { ClubDiveSiteGroupSortByAttribute } from './ClubDiveSiteGroupSortByAttribute.type';

export const clubDiveSiteGroupsSorter = {
  sortDiveSiteGroupsBy,
};

function sortDiveSiteGroupsBy(
  diveSiteGroups: ClubDiveSiteGroup[],
  {
    attributeName,
    asc,
  }: {
    attributeName: ClubDiveSiteGroupSortByAttribute;
    asc: boolean;
  },
): ClubDiveSiteGroup[] {
  const type = isFullText() ? 'full-text' : 'default';

  return dataSorter.sortMultiple(diveSiteGroups, {
    getSortAttributes: (x: ClubDiveSiteGroup) => [
      {
        value: getAttribute(x, { attributeName }),
        type,
      },
      {
        value: x.name,
        type: 'full-text',
      },
    ],
    asc,
  });

  function isFullText() {
    const textAttributes: ClubDiveSiteGroupSortByAttribute[] = ['name'];
    return textAttributes.includes(attributeName);
  }

  function getAttribute(
    x: Partial<ClubDiveSiteGroup>,
    { attributeName }: { attributeName: ClubDiveSiteGroupSortByAttribute },
  ) {
    return x[attributeName] as any;
  }
}
