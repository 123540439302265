import React from 'react';
import {
  AppButton,
  AppIconsAction,
  AppMessageLight,
} from 'src/business/_core/modules/layout';
import { DiveCenterBrandCard } from '../../components';
import { DiveCenterBookingPaymentBackPageLocalState } from './useDiveCenterBookingPaymentBackPageLocalState.hook';

export const DiveCenterBookingPaymentBackPageContent = ({
  localState,
}: {
  localState: DiveCenterBookingPaymentBackPageLocalState;
}) => {
  const { aggregatedBookingResume, clubSettings, paymentResult } = localState;

  const { diveCenter, checkoutState, links } = aggregatedBookingResume;
  const diveCenterPublicData = diveCenter?.publicData;
  const clubPublicData = clubSettings?.publicData;
  const clubPublicSettings = clubSettings?.publicSettings;
  const clubPublicReference = clubSettings?.clubPublicReference;

  return (
    <div className="grid gap-4">
      <div className="app-card app-p-content grid gap-4">
        {/* <DiveCenterBookingCheckoutStateInfo checkoutState={checkoutState} /> */}

        <h3 className="text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
          {paymentResult === 'success' ? 'Paiement réussi' : 'Paiement annulé'}
        </h3>

        <div>
          {paymentResult === 'success' ? (
            <AppMessageLight>
              Votre paiement a bien été enregistré.
            </AppMessageLight>
          ) : (
            <AppMessageLight type="danger">
              Votre paiement a été annulé.
            </AppMessageLight>
          )}
        </div>
        <div className="my-8 flex flex-col-reverse md:flex-row gap-4 justify-between items-center">
          <AppButton
            type="button"
            className="w-full max-w-md text-sm md:text-base font-bold uppercase"
            size="normal"
            fullWidth={true}
            icon={AppIconsAction.confirm}
            // iconPosition="right"
            color="gray-outline-light"
            href={links.bookingHomeUrl}
          >
            Retour à la réservation
          </AppButton>
        </div>
      </div>

      <DiveCenterBrandCard
        className="app-card app-p-content "
        clubPublicData={clubPublicData}
        clubPublicSettings={clubPublicSettings}
        diveCenterPublicData={diveCenterPublicData}
        clubPublicReference={clubPublicReference}
      />
    </div>
  );
};
