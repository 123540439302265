import queryString from 'query-string';
import React, { useEffect } from 'react';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { useAppSecurityUser } from 'src/business/auth/services';
import { DiveCenterBookingPageViewTabs } from './DiveCenterBookingPageViewTabs';
import {
  DiveCenterBookingPageLocalState,
  useDiveCenterBookingPageLocalState,
} from './useDiveCenterBookingPageLocalState.hook';

export const DiveCenterBookingPage = ({
  navigationContext,
}: {
  navigationContext: {
    clubPublicReference: string;
    bookingId: string;
  };
}) => {
  const securityUser = useAppSecurityUser();

  const clubPublicReference = navigationContext?.clubPublicReference;
  const bookingId = navigationContext?.bookingId;
  const diverId = securityUser.diver?.diverId;
  const queryParams = queryString.parse(window.location.search) as any;

  const localState: DiveCenterBookingPageLocalState =
    useDiveCenterBookingPageLocalState({
      bookingId,
      diverId,
    });

  const {
    loadableContent,
    aggregatedData,
    aggregatedBookingResume,
    clubSettings,
  } = localState;

  useEffect(() => {
    if (
      loadableContent.lastActionStatus === 'success' &&
      loadableContent.contentState === 'full'
    ) {
      if (!aggregatedBookingResume) {
        appLogger.warn(
          '[DiveCenterBookingPage] bookingResume not found, redirect back',
          aggregatedData,
        );
        // TODO redirectTo(backUrl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadableContent.contentState, loadableContent.lastActionStatus]);

  return aggregatedBookingResume ? (
    <DiveCenterBookingPageViewTabs
      {...loadableContent}
      localState={localState}
    />
  ) : null;
};
