import { ExportDailyConfigContentSessionsGroupParamColumnId } from '@mabadive/app-common-model';

export type GroupParamColumnStringFormat = 'excel' | 'option-label';

export const exportGroupParamColumnFormatter = {
  formatColumnName,
};

function formatColumnName(
  columnId: ExportDailyConfigContentSessionsGroupParamColumnId,
  { format }: { format: GroupParamColumnStringFormat },
): string {
  switch (columnId) {
    case 'timeStart':
      return format === 'option-label' ? 'Heure de début' : 'H. début';
    case 'timeEnd':
      return format === 'option-label' ? 'Heure de fin' : 'H. fin';
    case 'timeDuration':
      return format === 'option-label' ? 'Durée' : 'Durée';
    case 'depth':
      return format === 'option-label' ? 'Profondeur' : 'Prof.';
    case 'decoOptional':
      return format === 'option-label' ? 'Palier de sécu' : 'Pal sécu';
    case 'dtr':
      return format === 'option-label'
        ? 'Durée totale de remontée (DTR)'
        : 'DTR';
    case 'decoStage1':
      return 'Palier 1';
    case 'decoStage2':
      return 'Palier 2';
    case 'decoStage3':
      return 'Palier 3';
    case 'surfaceIntervalDuration':
      return format === 'option-label' ? 'Intervalle surface' : 'Int. surface';
  }
  return columnId;
}
