import clsx from 'clsx';
import React, { useEffect } from 'react';
import { AppPageContainer } from 'src/business/_core/modules/layout';
import { useAppRouter } from '../../../_core/data/hooks/useAppRouter';
import { useClubSettingsPublic } from '../../../_core/store';
import { diveCenterBookingUrlBuilder } from '../../../dive-center/BO-booking/diveCenterBookingUrlBuilder.service';

export const DiverAppLandingPage = () => {
  const clubSettingsPublic = useClubSettingsPublic();
  const appRouter = useAppRouter();
  useEffect(() => {
    if (clubSettingsPublic?.clubPublicReference) {
      appRouter.navigate(
        diveCenterBookingUrlBuilder.buildDiveCenterHomeUrl({
          clubPublicReference: clubSettingsPublic?.clubPublicReference,
        }),
        {
          cause: 'DiverAppLandingPage',
        },
      );
    }
  }, [appRouter, clubSettingsPublic?.clubPublicReference]);

  return (
    <AppPageContainer className="h-[100dvh] bg-gray-50 app-p-content overflow-y-hidden">
      <div className="h-full bg-gray-50 w-full sm:max-w-lg sm:mx-auto">
        <div className={clsx('flex justify-center xs:justify-start')}>
          <div className="mt-16 app-card app-p-content--lg ">
            Pour vous connecter, veuillez cliquer sur le lien qui vous a été
            envoyé par message.
          </div>
        </div>
      </div>
    </AppPageContainer>
  );
};
