import { JsonPatchOperation } from '@mabadive/app-common-model';
import { jsonPatcher } from '../json/jsonPatcher.service';
import { ObjectMap } from './ObjectMap.model';
import { objectMapReader } from './objectMapReader.service';

export const objectMapWriter = {
  addOne,
  addMany,
  removeOne,
  patchOne,
  patchMany,
};

function addOne<T>(map: ObjectMap<T>, { value, keyAttribute }: {
  value: T;
  keyAttribute: keyof T;
}): ObjectMap<T> {
  const newMap = {
    ...map,
  };

  const key = value[keyAttribute] as unknown as string;
  newMap[key] = value;

  return newMap;
}

function addMany<T>(map: ObjectMap<T>, { values, keyAttribute }: {
  values: T[];
  keyAttribute: keyof T;
}): ObjectMap<T> {
  const newMap = {
    ...map,
  };

  values.forEach((value) => {
    const key = value[keyAttribute] as unknown as string;
    newMap[key] = value;
  });

  return newMap;
}

function removeOne<T>(map: ObjectMap<T>, { criteria, keyAttribute }: {
  criteria: Partial<T>;
  keyAttribute: keyof T;
}): [ObjectMap<T>, T] {
  const newMap = {
    ...map,
  };

  const deleted = objectMapReader.findByCriteria(map, { criteria, keyAttribute });

  if (deleted) {
    const key = deleted[keyAttribute] as unknown as string;
    delete newMap[key];
  }

  return [newMap, deleted];
}

function patchOne<T>(map: ObjectMap<T>, { patch, keyAttribute, postPatchHook }: {
  patch: {
    criteria: Partial<T>;
    patchOperations: JsonPatchOperation[];
  };
  keyAttribute: keyof T;
  postPatchHook: (patched: T) => T;
}): {
  map: ObjectMap<T>;
  original: T;
  patched: T;
} {
  const newMap = {
    ...map,
  };

  let result: T;

  const original = objectMapReader.findByCriteria(map, { criteria: patch.criteria, keyAttribute });

  if (original) {
    const key = original[keyAttribute] as unknown as string;
    const patched = jsonPatcher.applyPatchOperations({
      ...original,
    }, patch.patchOperations);
    result = postPatchHook(patched);
    newMap[key] = result;
  }

  return {
    map: newMap,
    original,
    patched: result,
  };
}

function patchMany<T>(map: ObjectMap<T>, { patches, keyAttribute, postPatchHook }: {
  patches: {
    criteria: Partial<T>;
    patchOperations: JsonPatchOperation[];
  }[];
  keyAttribute: keyof T;
  postPatchHook: (patched: T) => T;
}): {
  map: ObjectMap<T>;
  items: {
    original: T;
    patched: T;
  }[];
} {
  const res = {
    map: {
      ...map,
    },
    items: [] as {
      original: T;
      patched: T;
    }[],
  };

  patches.forEach((patch) => {
    const original = objectMapReader.findByCriteria(map, { criteria: patch.criteria, keyAttribute });

    if (original) {
      const key = original[keyAttribute] as unknown as string;
      const patched = jsonPatcher.applyPatchOperations({
        ...original,
      }, patch.patchOperations);
      const result = postPatchHook(patched);
      res.map[key] = result;
      res.items.push({
        original,
        patched: result,
      });
    }
  });

  return res;
}
