"use strict";
exports.__esModule = true;
exports.DIVE_APTITUDES_CODE_SPORT = void 0;
exports.DIVE_APTITUDES_CODE_SPORT = [
    'PE12',
    'PA12',
    'PE20',
    'PA20',
    'PE40',
    'PA40',
    'PE60',
    'PA60',
];
