"use strict";
exports.__esModule = true;
exports.SESSION_WEATHER_TAG = void 0;
exports.SESSION_WEATHER_TAG = {
    reference: 'meteo',
    avatar: {
        label: 'Mé',
        color: '#fff',
        background: '#d78215'
    },
    content: {
        label: 'M2téo',
        color: '#fff',
        background: '#d78215'
    }
};
