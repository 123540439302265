import { MQueryDescription } from 'src/_common-browser';
import { bookingResumeAttributesBuilder } from '../ecommerce';
import { DiverBookingCriteria, DiverBookingGraphqlResult } from './model';

export function diverBookingGraphqlQuery(
  criteria: DiverBookingCriteria,
): MQueryDescription<DiverBookingGraphqlResult> {
  const bookingFilters: string[] = [];
  bookingFilters.push(`_id: {_eq: "${criteria.bookingId}"}`);
  // NOTE: on supprime ça, car maintenant tous les plongeurs peuvent y avoir accès
  // bookingFilters.push(`bookingContactDiverId: {_eq: "${criteria.diverId}"}`);
  const where = `{${bookingFilters.join(',')}}`;

  const returnAttributes = bookingResumeAttributesBuilder.buildAttributes({
    bookingProducts: 'all',
    limitToDiverId: criteria.limitToDiverId,
  });

  const queryDescription: MQueryDescription<DiverBookingGraphqlResult> = {
    returnName: 'bookingResumes',
    queryName: 'club_commerce_booking',
    returnType: 'first',
    where,
    orderBy: '{bookingDate: asc}',
    returnAttributes,
  };

  return queryDescription;
}
