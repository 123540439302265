export const diveCenterGraphqlClubAttributesBuilder = {
  buildAttributes: (): string =>
    `
    reference
    clubSettings { 
      clubPublicReference
      publicData
      publicSettings
      general
    }
    diveCenters {
      _id
      publicData
      publicSettings
    }
  `,
};
// FIXME il ne faudrait pas fetcher tout "general"
// general: Pick<ClubSettingsGeneral, 'currencyIsoCode'>;
