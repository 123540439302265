import React from 'react';
import {
  AppBreadcrumbBar,
  AppButton,
  AppIconsAction,
  AppPageContainerWithFixedBars,
} from 'src/business/_core/modules/layout';
import { CUS_BookingMemberFull } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/models';
import {
  DiveCenterBookingCheckoutStateInfo,
  DiveCenterBrandCard,
} from 'src/business/dive-center/components';
import { DiveCenterBookingMembersList } from '../../../DiveCenterBookingPage/tabs/DiveCenterBookingPageTab2BookingParticipants/DiveCenterBookingMembersList';
import { BookingResumeMembersTableOnClickMemberAction } from '../../../DiveCenterBookingPage/tabs/DiveCenterBookingPageTab2BookingParticipants/DiveCenterBookingMembersList/DiveCenterBookingMembersListItem';
import { DiveCenterBookingCheckoutPageLocalState } from '../../useDiveCenterBookingCheckoutPageLocalState.hook';

export const BookingCheckoutEditMemberStartPanel = ({
  localState,
}: {
  localState: DiveCenterBookingCheckoutPageLocalState;
}) => {
  const {
    loadableContent,
    aggregatedBookingResume,
    clubSettings,
    setViewState,
    breadcrumbItems,
    actions,
  } = localState;
  const { diveCenter, links, checkoutState } = aggregatedBookingResume;

  const diveCenterPublicData = diveCenter?.publicData;
  const clubPublicData = clubSettings?.publicData;
  const clubPublicSettings = clubSettings?.publicSettings;
  const clubPublicReference = clubSettings?.clubPublicReference;

  return (
    <AppPageContainerWithFixedBars
      contentState={loadableContent.contentState}
      lastActionStatus={loadableContent.lastActionStatus}
      marginBottom={true}
      paddingBottom={true}
      contentClassName="bg-gray-50 app-p-content"
      headerBar={() => (
        <AppBreadcrumbBar
          className="my-4"
          color={'#333'}
          items={breadcrumbItems}
        />
      )}
    >
      <div className="grid gap-4">
        <div className="app-card app-p-content grid gap-4">
          <DiveCenterBookingCheckoutStateInfo checkoutState={checkoutState} />
          {checkoutState.customerUpdateState.enableUpdate && (
            <div className="text-center md:text-left">
              <AppButton
                type="button"
                className="my-8 w-full max-w-md text-sm md:text-base font-bold uppercase"
                // disabled={!formState.isValid}
                size="normal"
                fullWidth={true}
                icon={AppIconsAction.confirm}
                // iconPosition="right"
                color="primary-outline-light"
                onClick={() => {
                  // if (currentTab === 'booking-participants') {
                  // setMemberEditDialogState({
                  //   isOpen: true,
                  //   updateState,
                  //   bookingResume: initialBookingResume,
                  //   participantsIndex: 0,
                  // });
                  setViewState({
                    mode: 'checkout-edit-members',
                    participantsIndex: 0,
                  });
                  // } else {
                  //   setCurrentTab('booking-participants');
                  // }
                }}
              >
                Commencer
              </AppButton>
            </div>
          )}

          {localState.az?.view?.otherParticipantsDetails && (
            <h3 className="text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
              Liste des participants
            </h3>
          )}
          <DiveCenterBookingMembersList
            enableCustomerUpdate={true}
            aggregatedBookingResume={aggregatedBookingResume}
            onClick={(
              bookingMembersFull: CUS_BookingMemberFull,
              options: {
                action: BookingResumeMembersTableOnClickMemberAction;
                participantsIndex: number;
              },
            ) => {
              actions.editParticipantByIndex(options.participantsIndex);
            }}
          />
        </div>

        <DiveCenterBrandCard
          className="app-card app-p-content "
          clubPublicData={clubPublicData}
          clubPublicSettings={clubPublicSettings}
          diveCenterPublicData={diveCenterPublicData}
          clubPublicReference={clubPublicReference}
        />
        <div className="my-8 flex flex-col-reverse md:flex-row gap-4 justify-between items-center">
          <AppButton
            type="button"
            className="w-full max-w-md text-sm md:text-base font-bold uppercase"
            size="normal"
            fullWidth={true}
            icon={AppIconsAction.confirm}
            // iconPosition="right"
            color="gray-outline-light"
            href={links.bookingHomeUrl}
          >
            Retour à la réservation
          </AppButton>
          {checkoutState.customerUpdateState.enableUpdate && (
            <AppButton
              type="button"
              className="w-full max-w-md text-sm md:text-base font-bold uppercase"
              size="normal"
              fullWidth={true}
              icon={AppIconsAction.confirm}
              // iconPosition="right"
              color="primary-outline-light"
              onClick={() => {
                // if (currentTab === 'booking-participants') {
                // setMemberEditDialogState({
                //   isOpen: true,
                //   updateState,
                //   bookingResume: initialBookingResume,
                //   participantsIndex: 0,
                // });
                setViewState({
                  mode: 'checkout-edit-members',
                  participantsIndex: 0,
                });
                // } else {
                //   setCurrentTab('booking-participants');
                // }
              }}
            >
              {localState.az?.actions?.editOtherParticipantsDetails
                ? 'Compléter la fiche des participants'
                : 'Compléter ma fiche participant'}
            </AppButton>
          )}
        </div>
      </div>
    </AppPageContainerWithFixedBars>
  );
};
