"use strict";
exports.__esModule = true;
exports.BOOKING_COMMENT_TAG = void 0;
exports.BOOKING_COMMENT_TAG = {
    reference: 'reservation',
    avatar: {
        label: 'R',
        color: '#fff',
        background: '#555'
    },
    content: {
        label: 'Réservation',
        color: '#fff',
        background: '#999'
    }
};
