import { AppCompanyMessageStyle } from '@mabadive/app-common-model';

export const appCompanyMessageStyleFormatter = {
  formatStyle,
};

function formatStyle(style: AppCompanyMessageStyle): string {
  switch (style) {
    case 'success':
      return 'Confirmation';
    case 'warning':
      return 'Attention';
    case 'danger':
      return 'Important';
  }
  // default
  return style;
}
