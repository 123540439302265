"use strict";
exports.__esModule = true;
exports.DEFAULT_CLUB_SETTINGS_COMMUNICATION = void 0;
var subject = "Votre r\u00E9servation de plong\u00E9e \u00E0 CENTRE_NOM";
var bodyBase = "Bonjour PARTICIPANT_PRENOM, \r\n\r\nPour visualiser votre r\u00E9servation, connectez-vous au lien suivant: ESPACE_CLIENT_LIEN_RESA\r\n\r\n";
bodyBase += 'À très bientôt!';
var bodyCustomerUpdateRequested = "Bonjour PARTICIPANT_PRENOM, \r\n\r\nPour visualiser votre r\u00E9servation, connectez-vous au lien suivant: ESPACE_CLIENT_LIEN_RESA\r\n\r\n";
bodyCustomerUpdateRequested +=
    'Merci de compléter toutes les informations pour chacune des personnes inscrites à cette sortie.\r\n\r\n';
bodyCustomerUpdateRequested += 'À très bientôt!';
var signature = "CENTRE_NOM\r\nCENTRE_LIEU\r\nCENTRE_TELEPHONE";
exports.DEFAULT_CLUB_SETTINGS_COMMUNICATION = {
    channels: {
        // activation globale
        whatsApp: { enabled: true },
        sms: { enabled: true },
        phone: { enabled: true },
        email: {
            enabled: true,
            directMode: {
                directManualEnabled: false,
                directAutoEnabled: false
            }
        }
    },
    customerSpace: {
        individualLinksEnabled: false
    },
    translation: {
        enabledManual: true
    },
    templates: {
        emailSignature: {
            content: signature,
            enabledIfManual: true
        },
        smsSignature: {
            content: signature
        },
        whatsAppSignature: {
            content: signature
        }
    },
    customer: {
        showMessageButtons: true,
        emailButton: { enabled: true },
        phoneButton: { enabled: true },
        whatsAppButton: { enabled: true }
    },
    booking: {
        enableMessage: true,
        recipients: {
            contactOnlyAsDefault: false,
            allowToContactAllRecipients: true,
            personalMessageSwitchEnabled: false
        },
        email: {
            enabled: true,
            mode: 'manual'
        },
        sms: {
            enabled: true
        },
        whatsApp: {
            enabled: true
        },
        confirmation: {
            manual: false,
            autoOnCreate: false
        },
        reminder: {
            auto: false,
            delayInHours: 48
        }
    },
    session: {
        enableMessage: true,
        email: {
            enabled: true,
            mode: 'manual'
        },
        sms: {
            enabled: true
        },
        whatsApp: {
            enabled: true
        }
    }
};
