import { APP_COLORS } from '@mabadive/app-common-model';
import { Theme } from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/styles/withStyles';
import { FlexDirectionProperty, PositionProperty } from 'csstype';
import { responsiveAttributesValues } from '../../../layout';

export function buildGlobalDialogStyles(theme: Theme): CreateCSSProperties<{}> {
  return {
    '& .MuiGrid-spacing-xs-2 > .MuiGrid-item': {
      [theme.breakpoints.only('xs')]: {
        padding: 0,
      },
    },
    '& .MuiPaper-root': {
      maxWidth: '1500px',
      // height: '100vh',
      overflow: 'auto',
    },
    '& .MuiDialogContent-root': {
      background: '#f0f0f0',
      minWidth: '50vw',
      ...responsiveAttributesValues(theme, {
        padding: {
          values: [0, 0, theme.spacing(1), theme.spacing(2), theme.spacing(3)],
          suffix: 'px',
        },
      }),
    },
    '& .MuiAppBar-root.dialog-top-bar': {
      position: 'relative' as PositionProperty,
      background: APP_COLORS.topBar,
      ...responsiveAttributesValues(theme, {
        marginBottom: {
          values: [0, 0, theme.spacing(1), theme.spacing(2), theme.spacing(2)],
        },
      }),
    },
    '& .title': {
      display: 'flex',
      flex: 1,
      flexDirection: 'row' as FlexDirectionProperty,
    },
  };
}
