import { ClubResumeStaffMember } from '@mabadive/app-common-model';
import { dataSorter } from '../../data/sorter/dataSorter.service';
import { StaffMemberSortByAttribute } from './StaffMemberSortByAttribute.type';

export const staffMembersSorter = {
  sortStaffMembersBy,
  isActiveStaff,
};

function sortStaffMembersBy(
  staffMembers: ClubResumeStaffMember[],
  {
    attributeName,
    asc,
    activeStaffFirst,
  }: {
    attributeName: StaffMemberSortByAttribute;
    asc: boolean;
    activeStaffFirst: boolean;
  },
): ClubResumeStaffMember[] {
  const type = isFullText() ? 'full-text' : 'default';

  const now = new Date();
  return dataSorter.sortMultiple(staffMembers, {
    getSortAttributes: (x: ClubResumeStaffMember) => [
      {
        value: activeStaffFirst ? isActiveStaff(x, now) : undefined,
        type,
        asc: false,
      },
      {
        value: getAttribute(x, { attributeName }),
        type,
      },
      {
        value: x.profile.lastName,
        type: 'full-text',
      },
      {
        value: x.profile.firstName,
        type: 'full-text',
      },
    ],
    asc,
  });

  function isFullText() {
    const textAttributes: StaffMemberSortByAttribute[] = [
      'firstName',
      'lastName',
      'scubaDivingInstructorLevel',
    ];
    return textAttributes.includes(attributeName);
  }

  function getAttribute(
    x: Partial<ClubResumeStaffMember>,
    { attributeName }: { attributeName: StaffMemberSortByAttribute },
  ) {
    if (attributeName === 'scubaDivingInstructorLevel') {
      return x.scubaDivingInstructor
        ? x.scubaDivingInstructor?.degree?.level
        : undefined;
    }
    if (attributeName === 'firstName') {
      return x.profile?.firstName;
    }
    if (attributeName === 'lastName') {
      return x.profile?.lastName;
    }
    return x[attributeName] as any;
  }
}

export function isActiveStaff(
  staff: ClubResumeStaffMember,
  now: Date,
): boolean {
  return !staff.endDate || staff.endDate.getTime() >= now.getTime();
}
