"use strict";
exports.__esModule = true;
exports.STAFF_ROLE_TAGS = exports.STAFF_ROLE_DIVING_DIRECTOR = exports.STAFF_ROLE_FREE_DIVING_INSTRUCTOR = exports.STAFF_ROLE_SCUBA_DIVING_INSTRUCTOR = exports.STAFF_ROLE_SURFACE_SECURITY = void 0;
exports.STAFF_ROLE_SURFACE_SECURITY = {
    reference: 'surface-security',
    avatar: {
        label: 'S',
        color: '#fff',
        background: '#425495'
    },
    content: {
        label: 'Sécurité surface',
        color: '#fff',
        background: '#8498de'
    }
};
exports.STAFF_ROLE_SCUBA_DIVING_INSTRUCTOR = {
    reference: 'scuba-diving-instructor',
    avatar: {
        label: 'MP',
        color: '#fff',
        background: '#425495'
    },
    content: {
        label: 'Moniteur de plongée',
        color: '#fff',
        background: '#8498de'
    }
};
exports.STAFF_ROLE_FREE_DIVING_INSTRUCTOR = {
    reference: 'free-diving-instructor',
    avatar: {
        label: 'MA',
        color: '#fff',
        background: '#425495'
    },
    content: {
        label: "Moniteur d'apnée",
        color: '#fff',
        background: '#8498de'
    }
};
exports.STAFF_ROLE_DIVING_DIRECTOR = {
    reference: 'diving-director',
    avatar: {
        label: 'DP',
        color: '#fff',
        background: '#425495'
    },
    content: {
        label: 'Directeur de plongée',
        color: '#fff',
        background: '#8498de'
    }
};
exports.STAFF_ROLE_TAGS = [
    exports.STAFF_ROLE_SURFACE_SECURITY,
    exports.STAFF_ROLE_DIVING_DIRECTOR,
    exports.STAFF_ROLE_SCUBA_DIVING_INSTRUCTOR,
    exports.STAFF_ROLE_FREE_DIVING_INSTRUCTOR,
];
