import React, { createContext, useMemo, useState } from 'react';
import { AppDialogModal } from './AppDialogModal';

type AppDialogModalOpenAttributes = {
  close: () => any;
};

type AppDialogModalOpenMethodFn = (attrs: AppDialogModalOpenAttributes) => {
  content: React.ReactNode | React.ReactNode[];
};

type AppDialogModalOpenMethod = (fn: AppDialogModalOpenMethodFn) => any;

export type AppDialogModalContextModel = {
  openDialog: AppDialogModalOpenMethod;
};

export type AppDialogModalInnerModel = {
  isOpen: boolean;
  fn?: AppDialogModalOpenMethodFn;
  // content?: React.ReactNode | React.ReactNode[];
};

const initialValue: AppDialogModalContextModel = {
  openDialog: () => {},
};
export const AppDialogModalContext =
  createContext<AppDialogModalContextModel>(initialValue);

export const AppDialogModalProvider: React.FC<{
  children?: React.ReactNode | React.ReactNode[];
}> = ({ children }) => {
  const [model, setModel] = useState<AppDialogModalInnerModel>({
    isOpen: false,
  });
  const context = useMemo<AppDialogModalContextModel>(
    () => ({
      openDialog: (fn: AppDialogModalOpenMethodFn) => {
        setModel({
          isOpen: true,
          fn,
        });
      },
    }),
    [],
  );

  const { content } = useMemo(() => {
    const attributes: AppDialogModalOpenAttributes = {
      close: () =>
        setModel({
          isOpen: false,
        }),
    };
    return model.isOpen && model.fn
      ? model.fn(attributes)
      : {
          content: <></>,
        };
  }, [model]);

  // const { isOpen, content } = appDialogContext;
  return (
    <AppDialogModalContext.Provider value={context}>
      {children}
      <AppDialogModal
        open={model.isOpen}
        setOpen={(isOpen) =>
          setModel({
            isOpen,
          })
        }
        // eslint-disable-next-line react/no-children-prop
        children={content}
      />
    </AppDialogModalContext.Provider>
  );
};
