"use strict";
exports.__esModule = true;
exports.DIVE_MODES_SCUBA = void 0;
exports.DIVE_MODES_SCUBA = [
    'first-dive',
    'supervised',
    'autonomous',
    'instructor',
    'autoSupervised',
    'training',
];
