/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useMemo, useState } from 'react';
import { LoadableContent } from 'src/business/_core/data/app-loading';
import { DiverBookingCriteria } from 'src/business/_core/data/store-repository/diver-booking/model';
import { useAppSecurityUser } from 'src/business/auth/services';
import {
  DiveCenterBookingPageAggregatedBookingResume,
  DiveCenterBookingPageLoadedContent,
  DiveCenterBookingPageUpdateState,
} from '../DiveCenterBookingPage/model';
import { diveCenterBookingPageAggregatedBookingResumeBuilder } from '../DiveCenterBookingPage/services';
import { useDiveCenterBookingPageLoadedContent } from '../DiveCenterBookingPage/useDiveCenterBookingPageLoadedContent.hook';

export type DiveCenterBookingPaymentBackPageLocalState = ReturnType<
  typeof useDiveCenterBookingPaymentBackPageLocalState
>;

export function useDiveCenterBookingPaymentBackPageLocalState({
  bookingId,
  diverId,
}: DiverBookingCriteria) {
  const securityUser = useAppSecurityUser();
  const clubPublicReference = securityUser?.diver?.clubPublicReference;

  const params = new URLSearchParams(window.location.search);

  // NOTE: on pourrait afficher les infos du paiement en plus sur cette page
  const clubPurchasePaymentId: string = params.get('clubPurchasePaymentId');
  const paymentResult: 'success' | 'cancel' = params.get('payment-result') as
    | 'success'
    | 'cancel';

  const {
    content: loadedContent,
    ...loadableContent
  }: LoadableContent<DiveCenterBookingPageLoadedContent> = useDiveCenterBookingPageLoadedContent(
    {
      bookingId,
      diverId,
    },
  );

  const [isSubmited, setIsSubmited] = useState(false);

  const [bookingMembersIdsInitialOrder, setBookingMembersIdsInitialOrder] =
    useState<string[]>();

  const { dataCore, aggregatedDataCore } =
    diveCenterBookingPageAggregatedBookingResumeBuilder.aggregatedDataCore({
      loadedContent,
      bookingMembersIdsInitialOrder,
      securityUser,
    });

  const initialBookingResume: DiveCenterBookingPageAggregatedBookingResume =
    useMemo(
      () =>
        (aggregatedDataCore?.bookingResumes ?? []).find(
          (x) => x.booking?._id === bookingId,
        ),
      [aggregatedDataCore?.bookingResumes, bookingId],
    );

  const [updateState, setUpdateState] =
    useState<DiveCenterBookingPageUpdateState>({
      diversChanges: [],
      clubParticipantsChanges: [],
      bookingsChanges: [],
      bookingSessionsChanges: [],
      bookingMembersChanges: [],
      bookingSessionParticipantsChanges: [],
      bookingProductsChanges: [],
    });

  const hasAnyChange = useMemo(() => {
    const {
      diversChanges,
      clubParticipantsChanges,
      bookingsChanges,
      bookingSessionsChanges,
      bookingMembersChanges,
      bookingSessionParticipantsChanges,
      bookingProductsChanges,
    } = updateState;

    const changesCount =
      diversChanges?.length +
      clubParticipantsChanges?.length +
      bookingsChanges?.length +
      bookingSessionsChanges?.length +
      bookingMembersChanges?.length +
      bookingSessionParticipantsChanges?.length +
      bookingProductsChanges?.length;
    return changesCount !== 0;
  }, [updateState]);

  const aggregatedData =
    diveCenterBookingPageAggregatedBookingResumeBuilder.aggregateAll({
      dataCore,
      updateState,
      bookingMembersIdsInitialOrder,
      securityUser,
    });

  const scrollToTop = useCallback(() => {
    window.document.getElementById('app-page-content-container')?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }, []);

  const aggregatedBookingResume = useMemo(
    () =>
      aggregatedData.bookingResumes.find(
        (x) => x.booking?._id === initialBookingResume?.booking?._id,
      ),
    [aggregatedData.bookingResumes, initialBookingResume?.booking?._id],
  );
  const clubSettings = aggregatedBookingResume?.clubResume?.clubSettings;
  const clubResume = aggregatedBookingResume?.clubResume;

  const actions = {
    scrollToTop,
  };

  return {
    loadableContent,
    updateState,
    setUpdateState,
    aggregatedData,
    initialBookingResume,
    aggregatedBookingResume,
    actions,
    hasAnyChange,
    isSubmited,
    setIsSubmited,
    clubSettings,
    clubResume,
    paymentResult,
  };
}
