import { SimpleStore } from '@mabadive/app-common-services';
import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient, { DefaultOptions } from 'apollo-client';
import { from, Observable, of, throwError } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { libLogger } from '../libLogger.service';
import { apolloClientBuilder } from './apollo';
import { graphqlClientStoreProvider } from './graphqlClientStoreProvider.service';

const logger = libLogger.child({
  module: 'graphql',
  filename: 'graphqlClientProvider.service',
});

export const graphqlClientProvider = {
  init,
  get,
  clearStore,
};

let apolloClient: ApolloClient<unknown> = undefined;

const cache = new InMemoryCache();

export type AppApolloClientConfig = {
  baseStore: SimpleStore<any>;
  baseHttpUrl: string;
  baseWsUrl: string;
};

function buildApolloClient(): ApolloClient<any> {
  logger.info('[graphql][graphqlClientProvider.buildApolloClient]');
  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  };
  return new ApolloClient({
    link: apolloClientBuilder.buildApolloLink(),
    cache: cache,
    defaultOptions: defaultOptions,
  });
}

function get(): Observable<ApolloClient<any>> {
  if (!apolloClient) {
    return throwError(
      new Error('Call `init()` method first to initialize GraphqlClientStore.'),
    );
  }
  return of(apolloClient);
}

function clearStore(): Observable<any> {
  logger.warn('[graphql][graphqlClientProvider.clearStore], apolloClient');

  cache.reset();

  if (!apolloClient) {
    return of(undefined);
  }
  return of(undefined).pipe(
    switchMap(() => {
      logger.info('[graphql][graphqlClientProvider.clearStore] Clear store');
      return from(apolloClient.clearStore()).pipe(
        tap(() => {
          // rebuild client or subscription won't be updated with new authentication
          apolloClient = buildApolloClient();
        }),
      );
    }),
  );
}

function init({
  baseStore,
  baseHttpUrl,
  baseWsUrl,
}: AppApolloClientConfig): Observable<ApolloClient<any>> {
  try {
    logger.info('[graphql][graphqlClientProvider.clearStore] init');

    configure(baseStore, baseHttpUrl, baseWsUrl);

    apolloClient = buildApolloClient();
  } catch (err) {
    logger.warn(
      '[graphql][graphqlClientProvider.clearStore] Unexpected error initializing graphql',
      baseHttpUrl,
      baseWsUrl,
    );
    logger.warn(
      '[graphql][graphqlClientProvider.clearStore] Unexpected error initializing graphql',
      err,
    );
    logger.error(
      '[graphql][graphqlClientProvider.clearStore] Unexpected error initializing graphql',
    );
    return throwError('Unexpected error initializing graphql');
  }

  logger.info('[graphql][graphqlClientProvider.clearStore] end');

  return of(apolloClient);
}

function configure(
  baseStore: SimpleStore<any>,
  baseHttpUrl: string,
  baseWsUrl: string,
) {
  logger.info(
    '[graphql][graphqlClientProvider.configure] baseHttpUrl="%s", baseWsUrl="%s"',
    baseHttpUrl,
    baseWsUrl,
  );

  const graphqlClientStore = graphqlClientStoreProvider.init(baseStore);
  graphqlClientStore.baseHttpUrl.set(baseHttpUrl);
  graphqlClientStore.baseWsUrl.set(baseWsUrl);
}
