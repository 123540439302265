import {
  AppRichTextElementTextAlign,
  AppUiTheme,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { appRichTextRenderer } from '../services';

export function AppRichTextH2<A>({
  attributes = {} as any,
  textAlign,
  theme,
  children,
  className,
}: {
  attributes?: A;
  textAlign?: AppRichTextElementTextAlign;
  theme: AppUiTheme;
  children: any;
  className?: string;
}) {
  const style: React.CSSProperties = useMemo(
    () => ({ color: theme.title2.textColor }),
    [theme.title2.textColor],
  );

  const alignmentClassName = useMemo(() => {
    return [appRichTextRenderer.getAlignmentClass(textAlign)]
      .filter((x) => !!x)
      .join(' ');
  }, [textAlign]);

  return (
    <h2
      className={clsx(
        'font-bold text-base lg:text-lg',
        className,
        alignmentClassName,
      )}
      {...attributes}
      style={style}
    >
      {children}
    </h2>
  );
}
