import {
  DiverBookingUpdateOperation,
  DiverBookingUpdatePayload,
} from '@mabadive/app-common-model';
import { apiClient } from 'src/_common-browser';

export const diverBookingUpdatorClient = {
  update,
};

function update(payload: DiverBookingUpdatePayload): Promise<void> {
  const operation: DiverBookingUpdateOperation = {
    id: 'diver-booking-update',
    payload,
  };

  // send remote operation
  return apiClient
    .post<any>('/operations', {
      authenticate: true,
      json: {
        operations: [operation],
      },
    })
    .toPromise()
    .catch((err) => {
      throw err;
    });
}
