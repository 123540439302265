import { colorGenerator } from '@mabadive/app-common-services';
import React, { FC, useMemo } from 'react';
import { AppButtonUnstyled, AppButtonUnstyledProps } from './AppButtonUnstyled';

export type AppButtonFreeColorProps = AppButtonUnstyledProps & {
  bgColor?: string;
  textColor?: string;
  className?: string;
  size?: 'normal' | 'small' | 'hamburger';
  outline?: boolean;
};

export const AppButtonFreeColor: FC<AppButtonFreeColorProps> = ({
  className,
  bgColor,
  textColor,
  children,
  size = 'normal',
  outline = true,
  ...props
}) => {
  const { style, hoverStyle } = useMemo(() => {
    const isLightBgColor = colorGenerator.isLight(bgColor);
    const isLightTextColor = colorGenerator.isLight(textColor);

    if (outline) {
      const style: React.CSSProperties = {
        backgroundColor: textColor,
        borderColor: bgColor,
        color: bgColor,
      };
      const hoverStyle: React.CSSProperties = {
        backgroundColor: isLightTextColor
          ? colorGenerator.lighten(textColor, 0.3)
          : colorGenerator.darken(textColor, 0.3),
        borderColor: colorGenerator.darken(bgColor, 0.8),
        color: colorGenerator.darken(bgColor, 0.8),
      };

      return {
        style,
        hoverStyle,
      };
    } else {
      // fill with BG color
      const style: React.CSSProperties = {
        backgroundColor: bgColor,
        borderColor: colorGenerator.darken(bgColor, 0.5),
        color: textColor ?? (isLightBgColor ? '#000' : '#fff'),
      };
      const hoverStyle: React.CSSProperties = {
        backgroundColor: colorGenerator.darken(bgColor, 0.3),
        borderColor: colorGenerator.darken(bgColor, 0.8),
        color: textColor ?? (isLightBgColor ? '#000' : '#fff'),
      };

      return {
        style,
        hoverStyle,
      };
    }
  }, [bgColor, outline, textColor]);

  return (
    <AppButtonUnstyled
      {...props}
      gap={size === 'small' || size === 'hamburger' ? 1 : 2}
      className={`group text-sm items-start rounded-sm  px-2 py-2 text-center font-medium
      ${props.icon ? 'justify-start' : 'justify-around'}
      ${props.disabled ? 'text-opacity-60 hover:text-opacity-60' : ''}
      ${className ?? ''}
  `}
      // iconStyleClass={`ml-3 -mr-7 h-5 w-5`}
      iconStyleClass={
        size === 'small'
          ? 'h-4 w-4'
          : size === 'hamburger'
          ? 'h-6 w-6'
          : 'h-4 w-4'
      }
      style={style}
      hoverStyle={hoverStyle}
    >
      {children}
    </AppButtonUnstyled>
  );
};
