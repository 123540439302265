import { AppCurrency } from '@mabadive/app-common-model';
import React from 'react';

export const AppPriceLabel = ({
  amount,
  mainCurrency,
  preserveSignClass,
  className = '',
}: {
  amount: number;
  mainCurrency: AppCurrency;
  preserveSignClass?: boolean;
  className?: string;
}) => {
  const signFontSize = preserveSignClass
    ? undefined
    : mainCurrency?.sign?.length > 1
    ? 'text-app-xxs'
    : 'text-xs';
  return amount === null || amount === undefined ? null : (
    <span className={`inline-block ${className}`}>
      <span className="">{amount.toFixed(mainCurrency?.cents ?? 2)}</span>
      <span
        className={`${signFontSize} ml-0.5 text-bold ${
          preserveSignClass ? '' : 'text-gray-600'
        }`}
      >
        {mainCurrency?.sign}
      </span>
    </span>
  );
};
