import { DiveServiceOfferSupervision } from '@mabadive/app-common-model';

export const offerSupervisionFormatter = {
  formatOfferSupervision,
};

function formatOfferSupervision(
  supervision: DiveServiceOfferSupervision,
  {
    format,
  }: {
    format: 'short' | 'full';
  },
): string {
  switch (supervision) {
    case 'supervised':
      return format === 'short' ? 'E' : 'Encadré';
    case 'autonomous':
      return format === 'short' ? 'A' : 'Autonome';
    case 'any-supervision':
      return format === 'short' ? '?' : 'Encadré ou non';
  }
}
