/* eslint-disable jsx-a11y/alt-text */
import { CustomerEcommerceProductArticleFull } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
export const EcommerceProductArticlePreviewCover_Image = ({
  productArticleFull,
  className,
}: {
  productArticleFull: CustomerEcommerceProductArticleFull;
  className?: string;
}) => {
  const articleCover =
    productArticleFull.articleBookletPage?.mainContent?.cover;
  const productCover =
    productArticleFull.productBookletPage?.mainContent?.cover;

  // si pas d'image, on utilise celle du produit
  const coverImage = articleCover.image ?? productCover?.image;

  return coverImage ? (
    <img
      className={clsx('relative object-center w-full rounded-t-xl', className)}
      width={1000} // TODO à stocker dans l'image
      height={400} // TODO à stocker dans l'image
      src={coverImage.publicUrl}
    />
  ) : null;
};
