import { AppDocFileType } from '@mabadive/app-common-model';
import React from 'react';
import { AppDropzoneFileTypeIcon } from '../../icons';

export function AppDocFileTypeIcon({
  fileType,
  className,
}: {
  fileType: AppDocFileType;
  className?: string;
}) {
  const Icon = AppDropzoneFileTypeIcon[fileType];

  return Icon && <Icon className={className} />;
}
