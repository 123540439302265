import {
  ArchiveBoxIcon,
  DocumentDuplicateIcon,
  DocumentTextIcon,
  PhotoIcon,
  PresentationChartBarIcon,
  TableCellsIcon,
} from '@heroicons/react/24/outline';
import { AppDocFileType } from '@mabadive/app-common-model';

export const AppDropzoneFileTypeIcon: Record<AppDocFileType, any> = {
  text: DocumentTextIcon,
  richText: DocumentDuplicateIcon,
  spreadSheet: TableCellsIcon,
  presentation: PresentationChartBarIcon,
  image: PhotoIcon,
  archive: ArchiveBoxIcon,
};
