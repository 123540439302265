/**
Styles
  - status-error
  - status-warn
  - status-success
  - status-info
  - status-active
  - status-favourite

Elements
  - bg
  - text
  - border
  - ring
  
Variants:
  - group-hover
  - hover
  - focus
 */

export const TAILWIND_COLORS_GENERIC_STATUS = `
  bg-status-error hover:bg-status-error group-hover:bg-status-error focus:bg-status-error text-status-error hover:text-status-error group-hover:text-status-error focus:text-status-error border-status-error hover:border-status-error group-hover:border-status-error focus:border-status-error ring-status-error hover:ring-status-error group-hover:ring-status-error focus:ring-status-error
  bg-status-warn hover:bg-status-warn group-hover:bg-status-warn focus:bg-status-warn text-status-warn hover:text-status-warn group-hover:text-status-warn focus:text-status-warn border-status-warn hover:border-status-warn group-hover:border-status-warn focus:border-status-warn ring-status-warn hover:ring-status-warn group-hover:ring-status-warn focus:ring-status-warn
  bg-status-success hover:bg-status-success group-hover:bg-status-success focus:bg-status-success text-status-success hover:text-status-success group-hover:text-status-success focus:text-status-success border-status-success hover:border-status-success group-hover:border-status-success focus:border-status-success ring-status-success hover:ring-status-success group-hover:ring-status-success focus:ring-status-success
  bg-status-info hover:bg-status-info group-hover:bg-status-info focus:bg-status-info text-status-info hover:text-status-info group-hover:text-status-info focus:text-status-info border-status-info hover:border-status-info group-hover:border-status-info focus:border-status-info ring-status-info hover:ring-status-info group-hover:ring-status-info focus:ring-status-info
  bg-status-active hover:bg-status-active group-hover:bg-status-active focus:bg-status-active text-status-active hover:text-status-active group-hover:text-status-active focus:text-status-active border-status-active hover:border-status-active group-hover:border-status-active focus:border-status-active ring-status-active hover:ring-status-active group-hover:ring-status-active focus:ring-status-active
  bg-status-favourite hover:bg-status-favourite group-hover:bg-status-favourite focus:bg-status-favourite text-status-favourite hover:text-status-favourite group-hover:text-status-favourite focus:text-status-favourite border-status-favourite hover:border-status-favourite group-hover:border-status-favourite focus:border-status-favourite ring-status-favourite hover:ring-status-favourite group-hover:ring-status-favourite focus:ring-status-favourite
 `;
