/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  OnlineBookingOrderArticle,
  OnlineBookingParticipant,
} from '@mabadive/app-common-model';
import { uuidGenerator } from '@mabadive/app-common-services';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { useAppCurrencyMain } from '../../../pages';
import { useAppRouter } from '../../_core/data/hooks/useAppRouter';
import { appLogger } from '../../_core/modules/root/logger';
import { diveCenterBookingUrlBuilder } from '../BO-booking/diveCenterBookingUrlBuilder.service';
import { useDiveCenterShoppingCart } from '../DiveCenterShoppingCartPage';
import { useCustomerEcommerceDataLoadedContent } from './_data';
import { ClubSettingsOnlineBookingProductsPageViewState } from './_model';
import {
  diveCenterEcommerceHomePageLocalStateUrlManager,
  useDiveCenterEcommerceHomePageUpdateURL,
} from './_services';

export function useDiveCenterEcommerceHomePageLocalState({
  clubPublicReference,
}: {
  clubPublicReference: string;
}) {
  const { loadedContent, loadableContent, refetch } =
    useCustomerEcommerceDataLoadedContent({
      clubPublicReference,
    });

  const appRouter = useAppRouter();

  const clubSettings = loadedContent?.clubResume?.clubSettings;
  const clubReference = loadedContent?.clubResume?.club?.reference;
  const diveCenters = loadedContent?.clubResume?.diveCenters;
  const clubPublicSettings = clubSettings?.publicSettings;
  const mainCurrency = useAppCurrencyMain({ clubPublicSettings });

  const appUiTheme = clubPublicSettings?.appUiTheme;

  const diveCenter = diveCenters?.length ? diveCenters[0] : undefined;
  const clubPublicData = clubSettings?.publicData;
  const diveCenterPublicData = diveCenter?.publicData;
  const diveCenterId = diveCenter?._id;

  const location = useLocation();
  const queryParams =
    diveCenterEcommerceHomePageLocalStateUrlManager.getQueryParameters();

  const defaultViewState: ClubSettingsOnlineBookingProductsPageViewState =
    useMemo(() => {
      // NOTE: si on rafraichit sur la page de contact, on redirige sur la liste
      // TODO: local storage
      const mode = !!queryParams.mode ? queryParams.mode : 'categories-list';

      return {
        // TODO séparer les données de navigation des données du panier (et impacter correctement le useEffect)
        onlineBookingId: uuidGenerator.random(),
        mode,
        timestamp: queryParams.timestamp ?? Date.now(), // si timestamp change, on met à jour l'historique de navigation (et le back va fonctionner)
        categoryId: queryParams.categoryId,
        productId: queryParams.productId,
        articleId: queryParams.articleId,
        context: queryParams.context,
        // ci dessous, données du panier
        isPersisted: false,
        actionSteps: [],
      };
    }, [
      queryParams.articleId,
      queryParams.categoryId,
      queryParams.context,
      queryParams.mode,
      queryParams.productId,
      queryParams.timestamp,
    ]);
  const [viewState, setViewState] =
    useState<ClubSettingsOnlineBookingProductsPageViewState>(defaultViewState);

  const isIframe = viewState.context === 'iframe';

  useEffect(() => {
    if (
      defaultViewState.timestamp &&
      defaultViewState.timestamp !== viewState.timestamp
    ) {
      // si on fait back, on met à jour l'état
      appLogger.info(
        '[useDiveCenterEcommerceHomePageLocalState] back triggered',
        defaultViewState.timestamp,
      );
      setViewState({
        ...defaultViewState,

        // ci dessous, données du panier
        actionSteps: [
          ...viewState.actionSteps,
          {
            date: new Date(),
            label: `Back from ${viewState.mode}`,
            // meta
          },
        ],
      });
    }
    // on ne déclenche que si le paramètre defaultViewState.timestamp a changé dans la route
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultViewState.timestamp]);

  const showCategories = useCallback(() => {
    setViewState({
      ...viewState,
      timestamp: Date.now(),
      mode: 'categories-list',
      actionSteps: [
        ...viewState.actionSteps,
        {
          date: new Date(),
          label: 'Show categories',
          // meta
        },
      ],
    });
  }, [viewState]);
  const selectCategory = useCallback(
    (categoryId: string) => {
      setViewState({
        ...viewState,
        timestamp: Date.now(),
        mode: 'category-view',
        categoryId,
        actionSteps: [
          ...viewState.actionSteps,
          {
            date: new Date(),
            label: `Open category ${categoryId}`,
            // meta
          },
        ],
      });
    },
    [viewState],
  );
  const selectCategoryProduct = useCallback(
    ({ productId, categoryId }: { productId: string; categoryId: string }) => {
      setViewState({
        ...viewState,
        timestamp: Date.now(),
        mode: 'product-view',
        categoryId,
        productId,
        actionSteps: [
          ...viewState.actionSteps,
          {
            date: new Date(),
            label: `Open product ${categoryId}/${productId}`,
            // meta
          },
        ],
      });
    },
    [viewState],
  );

  const {
    actions: { addOrderArticle },
  } = useDiveCenterShoppingCart();
  const confirmOrderArticle = useCallback(
    ({
      orderArticle,
      bookingParticipants,
    }: {
      orderArticle: OnlineBookingOrderArticle;
      bookingParticipants: OnlineBookingParticipant[];
    }) => {
      // TODO: il faudrait ici persister le nouvel article (et la commande, si elle n'est pas encore créee)
      // => ou plutot la stocker dans le localstorage
      addOrderArticle({
        orderArticle,
        bookingParticipants,
      });
      // on redirige vers le panier
      appRouter.navigate(
        diveCenterBookingUrlBuilder.buildDiveCenterShoppingCartUrl({
          clubPublicReference,
        }),
        {
          cause: 'produit ajouté',
        },
      );
      // setViewState({
      //   ...viewState,
      //   timestamp: Date.now(),
      //   mode: 'fill-contact',
      // });
    },
    [addOrderArticle, appRouter, clubPublicReference],
  );
  const [operationInProgress, setOperationInProgress] =
    useState<{
      type: 'loading' | 'persist';
      message: string;
    }>();

  const selectedCategory = useMemo(
    () =>
      (loadedContent?.categories ?? []).find(
        (x) => x._id === viewState.categoryId,
      ),
    [loadedContent?.categories, viewState.categoryId],
  );

  const selectedProduct = useMemo(
    () =>
      (loadedContent?.products ?? []).find(
        (x) => x._id === viewState.productId,
      ),
    [loadedContent?.products, viewState.productId],
  );

  const selectedArticle = useMemo(
    () =>
      (loadedContent?.productArticles ?? []).find(
        (x) => x._id === viewState.articleId,
      ),
    [loadedContent?.productArticles, viewState.articleId],
  );

  const startBookingArticle = useCallback(
    ({
      productId,
      categoryId,
      articleId,
    }: {
      productId: string;
      categoryId: string;
      articleId: string;
    }) => {
      setViewState({
        ...viewState,
        timestamp: Date.now(),
        mode: 'book-product',
        categoryId,
        productId,
        articleId,
        actionSteps: [
          ...viewState.actionSteps,
          {
            date: new Date(),
            label: 'Start booking article',
            meta: {
              productId,
              categoryId,
              articleId,
            },
          },
        ],
      });
    },
    [viewState],
  );

  const documentTitle = useMemo(() => {
    const baseName = diveCenter?.publicData?.brand?.name;
    const chunks = [baseName];
    if (selectedProduct) {
      chunks.push(selectedProduct.name);
    } else if (selectedCategory) {
      chunks.push(selectedCategory.name);
    } /*else if (viewState.currentBookingArticle) {
      chunks.push(selectedCategory.name);
    } */ else {
      chunks.push('Activités');
    }
    return chunks.filter(Boolean).join(' - ');
  }, [diveCenter?.publicData?.brand?.name, selectedCategory, selectedProduct]);

  useDiveCenterEcommerceHomePageUpdateURL(viewState, {
    enableRouteParams: true,
    documentTitle,
  });

  return {
    state: {
      clubReference,
      diveCenterId,
      viewState,
      loadableContent,
      selectedCategory,
      selectedProduct,
      startBookingArticle,
      mainCurrency,
      operationInProgress,
      isIframe,
    },
    actions: {
      refetch,
      showCategories,
      selectCategory,
      selectCategoryProduct,
      startBookingArticle,
      confirmOrderArticle,
    },
    data: {
      ...(loadedContent ?? {}),
      clubSettings,
      clubPublicSettings,
      clubPublicReference,
      diveCenter,
      clubPublicData,
      diveCenterPublicData,
      appUiTheme,
    },
  };
}
export type DiveCenterEcommerceHomePageLocalState = ReturnType<
  typeof useDiveCenterEcommerceHomePageLocalState
>;
