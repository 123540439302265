import React from 'react';
import {
  AppButton,
  AppIconsAction,
  AppMessageLight,
} from 'src/business/_core/modules/layout';
import { DiveCenterBrandCard } from '../../components';
import { BookingPaymentCard } from './components/BookingPaymentCard';
import { DiveCenterBookingPaymentPageLocalState } from './useDiveCenterBookingPaymentPageLocalState.hook';

export const DiveCenterBookingPaymentPageContent = ({
  localState,
}: {
  localState: DiveCenterBookingPaymentPageLocalState;
}) => {
  const { aggregatedBookingResume, clubSettings } = localState;

  const { diveCenter, checkoutState, links } = aggregatedBookingResume;
  const diveCenterPublicData = diveCenter?.publicData;
  const clubPublicData = clubSettings?.publicData;
  const clubPublicSettings = clubSettings?.publicSettings;
  const clubPublicReference = clubSettings?.clubPublicReference;

  return (
    <div className="grid gap-4">
      <div className="app-card app-p-content grid gap-4">
        {/* <DiveCenterBookingCheckoutStateInfo checkoutState={checkoutState} /> */}

        <h3 className="text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
          Paiement en attente
        </h3>

        {checkoutState.paymentsState.pendingOnlinePayments.length === 0 ? (
          <div>
            <AppMessageLight>Aucun paiement en attente</AppMessageLight>
          </div>
        ) : (
          <div className="grid gap-4 md:grid-cols-2 xl:grid-cols-3 items-stretch">
            {checkoutState.paymentsState.pendingOnlinePayments.map(
              (purchasePayment, i) => (
                <BookingPaymentCard
                  className="h-full"
                  key={i}
                  clubSettings={clubSettings}
                  purchasePayment={purchasePayment}
                  diveCenter={diveCenter}
                />
              ),
            )}
          </div>
        )}
      </div>

      <DiveCenterBrandCard
        className="app-card app-p-content "
        clubPublicData={clubPublicData}
        clubPublicSettings={clubPublicSettings}
        diveCenterPublicData={diveCenterPublicData}
        clubPublicReference={clubPublicReference}
      />
      <div className="my-8 flex flex-col-reverse md:flex-row gap-4 justify-between items-center">
        <AppButton
          type="button"
          className="w-full max-w-md text-sm md:text-base font-bold uppercase"
          size="normal"
          fullWidth={true}
          icon={AppIconsAction.confirm}
          // iconPosition="right"
          color="gray-outline-light"
          href={links.bookingHomeUrl}
        >
          Retour à la réservation
        </AppButton>
      </div>
    </div>
  );
};
