import React from 'react';
import { Switch } from 'react-router-dom';
import { of } from 'rxjs';
import { AuthRootSpace } from 'src/business/auth/pages/AuthRootSpace';
import { ShortLinkResolverComponent } from 'src/business/club/modules/short-link-resolver';
import { DiveCenterRootSpace } from 'src/business/dive-center/DiveCenterRootSpace';
import AppSnackbar from '../../../layout/components/AppSnackBar/AppSnackbar';
import { ConfirmDialogManager } from '../../../layout/components/ConfirmDialog';
import { AppRoute } from '../../../router/AppRoute/AppRoute';
import { LoadingProgressBar } from './LoadingProgressBar';
import { appRouteBuilder } from './appRouteBuilder.service';
import { useAppRoot } from './useAppRoot.hook';

const NEVER_RE_RENDER = () => true;

export const AppRoot = React.memo(function AppRootMemo() {
  const { loaded, _error } = useAppRoot();

  return (
    <div className="flex bg-[#f0f0f0] min-h-[100dvh] w-screen items-start">
      {loaded ? (
        <Switch>
          {/* <AppRoute path="/auth" component={AuthRootSpace} /> */}
          {/* <AppRoute path="/demo" exact component={LaunchDemoPage} /> */}
          <AppRoute
            path="/s/:aliasKey"
            component={ShortLinkResolverComponent} // fake component
          />
          <AppRoute
            path="/dive-center/:clubPublicReference"
            component={DiveCenterRootSpace}
            // auth={{ requiredRoles: ['club'] }}
          />
          <AppRoute
            path="/"
            component={AuthRootSpace}
            exact
            redirectTo={({ appAuth }) =>
              appAuth && appAuth.isAuthenticated
                ? appRouteBuilder.getDefaultRouteFromAuth({ appAuth })
                : of(undefined)
            }
          />

          {/* default route */}
          <AppRoute
            path="/*"
            redirectTo={({ appAuth }) =>
              appRouteBuilder.getDefaultRouteFromAuth({ appAuth })
            }
          />
        </Switch>
      ) : (
        <LoadingProgressBar hasError={_error} />
      )}
      <AppSnackbar />
      <ConfirmDialogManager />
    </div>
  );
}, NEVER_RE_RENDER);
