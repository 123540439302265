import { ThemeProvider } from '@material-ui/core/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'dayjs/locale/fr';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';
import { appWebConfig } from 'src/business/_core/modules/root/config';
import 'tippy.js/dist/tippy.css';
import { globalTheme } from './AppTheme';
import { TAILWIND_COLORS_DIVE_MODE } from './TAILWIND_COLORS_DIVE_MODE.const';
import { TAILWIND_COLORS_GENERIC_STATUS } from './TAILWIND_COLORS_GENERIC_STATUS.const';
import { AppDialogModalProvider } from './business/_core/modules/layout';
import { AppScrollToTop } from './business/_core/modules/layout/components/AppScrollToTop';
import { ErrorFallback } from './business/_core/modules/layout/components/ErrorFallbackMessage/ErrorFallbackMessage';
import { AppRoot } from './business/_core/modules/root/pages';

function App() {
  return appWebConfig.debug.showErrorStackTrace ? (
    <>{renderApp()}</>
  ) : (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {renderApp()}
    </ErrorBoundary>
  );
}

// react query
const queryClient = new QueryClient();

function renderApp() {
  const theme = globalTheme;

  return (
    <div id="app-full-screen-container">
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          {/*      tailwind dynamically used classes */}
          <i className={`hidden ${TAILWIND_COLORS_DIVE_MODE}`}></i>
          <i className={`hidden ${TAILWIND_COLORS_GENERIC_STATUS}`}></i>
          <i className="hidden bg-day-period-morning-light bg-day-period-morning-light2 bg-day-period-morning-dark bg-day-period-morning-dark2 bg-day-period-afternoon-light bg-day-period-afternoon-light2 bg-day-period-afternoon-dark bg-day-period-afternoon-dark2 bg-day-period-night-light bg-day-period-night-light2 bg-day-period-night-dark bg-day-period-night-dark2"></i>
          <i className="hidden text-day-period-morning-light text-day-period-morning-light2 text-day-period-morning-dark text-day-period-morning-dark2 text-day-period-afternoon-light text-day-period-afternoon-light2 text-day-period-afternoon-dark text-day-period-afternoon-dark2 text-day-period-night-light text-day-period-night-light2 text-day-period-night-dark text-day-period-night-dark2"></i>
          <i className="hidden border-day-period-morning-light border-day-period-morning-light2 border-day-period-morning-dark border-day-period-morning-dark2 border-day-period-afternoon-light border-day-period-afternoon-light2 border-day-period-afternoon-dark border-day-period-afternoon-dark2 border-day-period-night-light border-day-period-night-light2 border-day-period-night-dark border-day-period-night-dark2"></i>
          <i className="hidden ring-day-period-morning-light ring-day-period-morning-light2 ring-day-period-morning-dark ring-day-period-morning-dark2 ring-day-period-afternoon-light ring-day-period-afternoon-light2 ring-day-period-afternoon-dark ring-day-period-afternoon-dark2 ring-day-period-night-light ring-day-period-night-light2 ring-day-period-night-dark ring-day-period-night-dark2"></i>
          <i className="hidden hover:bg-day-period-morning-light hover:bg-day-period-morning-light2 hover:bg-day-period-morning-dark hover:bg-day-period-morning-dark2 hover:bg-day-period-afternoon-light hover:bg-day-period-afternoon-light2 hover:bg-day-period-afternoon-dark hover:bg-day-period-morning-dark2 hover:bg-day-period-afternoon-light hover:bg-day-period-afternoon-light2 hover:bg-day-period-afternoon-dark hover:bg-day-period-night-light hover:bg-day-period-night-dark hover:bg-day-period-night-dark2"></i>

          <i className="hidden border-app-red border-app-dark-red border-app-orange border-app-dark-orange"></i>
          <BrowserRouter>
            <AppDialogModalProvider>
              <AppScrollToTop />
              <AppRoot />
            </AppDialogModalProvider>
          </BrowserRouter>
        </ThemeProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
