/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CustomerFetchAvailabilyCriteria,
  CustomerFetchAvailabilyResult,
} from '@mabadive/app-common-model';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { apiClient } from 'src/_common-browser';

export type FetchAppDocUrlResult = {
  url: string;
};

// cache de 15mn
const expiration = 15 * 60 * 1000;

export function useCustomerFetchAvailability(
  criteria: CustomerFetchAvailabilyCriteria,
) {
  const query: UseQueryResult<CustomerFetchAvailabilyResult, unknown> =
    useQuery({
      queryKey: ['POST', '/customer/online-booking/availabilities', criteria],
      queryFn: async () => {
        const results = await apiClient
          .post<any>('/customer/online-booking/availabilities', {
            authenticate: true,
            json: {
              criteria,
            },
          })
          .toPromise();
        return results;
      },
      gcTime: expiration, // on garde ça en cach
      staleTime: 10 * 1000, // on rafraichit toujours, après l'utilisation du cache (sauf si - de 10s)
      refetchOnWindowFocus: true,
      refetchInterval: 15 * 60 * 1000, // refresh chaque 15mn
      enabled: !!criteria,
    });
  const { isLoading: loadingInProgress, data, refetch } = query;

  return {
    data,
    refetch,
    loadingInProgress,
  };
}
