import { useWindowSize } from './useWindowSize.hook';

export type TailwindBreakpoint =
  | 'xxs'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl';

export function useMediaSizeTailwind(
  defaultValueWhileNotInitialized: TailwindBreakpoint = undefined,
): TailwindBreakpoint {
  const pageWidth = useWindowSize()?.width;

  if (pageWidth === undefined || pageWidth === null || pageWidth === 0) {
    return defaultValueWhileNotInitialized;
  }

  // https://v2.tailwindcss.com/docs/responsive-design
  // https://tailwindcss.com/docs/responsive-design#overview
  if (pageWidth < 475) return 'xxs'; // NOTE: custom breakpoint ajouté dans tailwind.config.js
  if (pageWidth < 640) return 'xs';
  if (pageWidth < 768) return 'sm';
  if (pageWidth < 1024) return 'md';
  if (pageWidth < 1280) return 'lg';
  if (pageWidth < 1536) return 'xl';
  return '2xl';
}
