import React, { useEffect } from 'react';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { useAppSecurityUser } from 'src/business/auth/services';
import {
  BookingCheckoutEditMemberPreviewPanel,
  BookingCheckoutEditMembersPanel,
  BookingCheckoutEditMemberStartPanel,
  BookingCheckoutEditMemberSubmitPanel,
} from './panels';
import {
  DiveCenterBookingCheckoutPageLocalState,
  useDiveCenterBookingCheckoutPageLocalState,
} from './useDiveCenterBookingCheckoutPageLocalState.hook';

export const DiveCenterBookingCheckoutPage = ({
  navigationContext,
}: {
  navigationContext: {
    clubPublicReference: string;
    bookingId: string;
  };
}) => {
  const securityUser = useAppSecurityUser();

  const bookingId = navigationContext?.bookingId;
  const diverId = securityUser.diver?.diverId;

  const localState: DiveCenterBookingCheckoutPageLocalState =
    useDiveCenterBookingCheckoutPageLocalState({
      bookingId,
      diverId,
    });

  const {
    loadableContent,
    aggregatedData,
    aggregatedBookingResume,
    viewState,
  } = localState;

  useEffect(() => {
    if (
      loadableContent.lastActionStatus === 'success' &&
      loadableContent.contentState === 'full'
    ) {
      if (!aggregatedBookingResume) {
        appLogger.warn(
          '[DiveCenterBookingCheckoutPage] bookingResume not found, redirect back',
          aggregatedData,
        );
        // TODO redirectTo(backUrl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadableContent.contentState, loadableContent.lastActionStatus]);

  return aggregatedBookingResume ? (
    <>
      {viewState.mode === 'checkout-edit-member-start' && (
        <BookingCheckoutEditMemberStartPanel localState={localState} />
      )}
      {viewState.mode === 'checkout-edit-members' && (
        <BookingCheckoutEditMembersPanel localState={localState} />
      )}
      {viewState.mode === 'checkout-edit-member-preview' && (
        <BookingCheckoutEditMemberPreviewPanel localState={localState} />
      )}
      {viewState.mode === 'checkout-edit-member-submit' && (
        <BookingCheckoutEditMemberSubmitPanel localState={localState} />
      )}
    </>
  ) : null;
};
