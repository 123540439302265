/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import {
  AppSingleSelect2HeadlessUIPropsColor,
  AppSingleSelect2HeadlessUIPropsPaletOption,
} from './AppSingleSelect2HeadlessUI';

export const appSingleSelectOptionColorsPaleteBuilder = {
  buildColorsPaletOption,
};
function buildColorsPaletOption<
  T,
  L extends string | React.ReactNode = string,
>({
  color,
  option,
}: {
  color: AppSingleSelect2HeadlessUIPropsColor;
  option: ValueLabel<T, L>;
}): AppSingleSelect2HeadlessUIPropsPaletOption {
  let palet: AppSingleSelect2HeadlessUIPropsPaletOption = {
    option: {},
    selected: {},
  };

  const iconStyle = option?.iconStyle;

  palet.option.icon =
    iconStyle === 'success'
      ? 'text-status-success'
      : iconStyle === 'info'
      ? 'text-status-info'
      : iconStyle === 'warn'
      ? 'text-status-warn'
      : iconStyle === 'danger'
      ? 'text-status-error'
      : 'text-gray-500';

  palet.option.label =
    color === 'blue'
      ? 'text-blue-600'
      : color === 'green'
      ? 'text-green-600'
      : color === 'fuchsia'
      ? 'text-fuchsia-600'
      : 'text-gray-600';

  palet.option. selectedCheckIcon = color === 'blue'
  ? 'text-blue-600'
  : color === 'green'
  ? 'text-green-600'
  : color === 'fuchsia'
  ? 'text-fuchsia-600'
  : 'text-gray-600'

  // couleur de l'icone
  palet.selected.icon =
    iconStyle === 'success'
      ? 'text-status-success'
      : iconStyle === 'info'
      ? 'text-status-info'
      : iconStyle === 'warn'
      ? 'text-status-warn'
      : iconStyle === 'danger'
      ? 'text-status-error'
      : color === 'blue'
      ? 'text-blue-600'
      : color === 'green'
      ? 'text-green-600'
      : color === 'fuchsia'
      ? 'text-fuchsia-600'
      : 'text-gray-600';

  // couleur du texte
  palet.selected.label =
    color === 'blue'
      ? 'text-blue-600'
      : color === 'green'
      ? 'text-green-600'
      : color === 'fuchsia'
      ? 'text-fuchsia-600'
      : color === 'theme'
      ? 'text-gray-800'
      : 'text-gray-600';

  return palet;
}

function getSelectedOption<T, L extends string | React.ReactNode = string>({
  options,
  value,
}: {
  options: ValueLabel<T, L>[];
  value: T;
}): ValueLabel<T, L> {
  if (options && value !== null && value !== undefined) {
    const selectedOption = options.find((o) => o.value === value);
    if (selectedOption) {
      return selectedOption;
    }
  }
  return null;
}
