/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubProductPackageOfferView,
  PurchaseResumeOffer,
} from '@mabadive/app-common-model';
import { dataSorter } from '../../../data';
import { DiverPurchaseTrainingEditorDialogMatchingOfferCriteria } from './model';

export const clubProductPackageOfferMatcherBestTraining = {
  findBestMatchingOfferTraining,
};

function findBestMatchingOfferTraining({
  criteria,
  productPackageOffers,
}: {
  criteria?: DiverPurchaseTrainingEditorDialogMatchingOfferCriteria;
  productPackageOffers: ClubProductPackageOfferView[];
}): PurchaseResumeOffer {
  if (!criteria) {
    return undefined;
  }

  const matchingOffers: ClubProductPackageOfferView[] = excludeNonBestOffers({
    productPackageOffers,
    criteria,
  });

  // sort by total count, then by min/max distance
  const sortedMatchingOffers = dataSorter.sortMultiple(matchingOffers, {
    getSortAttributes: (o) => {
      const diveAttributes = o.productPackage.diveAttributes;
      const productAttributes = o.productPackage.productAttributes;
      const salesCriteria = o.productPackage.salesCriteria;
      return [
        {
          value: productAttributes?.minAge > 0 ? 0 : 1, // on met en priorité les offres qui ont un filtre d'âge (elles ont été filtrées avant si elles ne matchen pas)
        },
        {
          value: productAttributes?.maxAge > 0 ? 0 : 1, // on met en priorité les offres qui ont un filtre d'âge (elles ont été filtrées avant si elles ne matchen pas)
        },
        {
          value: o.price,
          asc: false, // par défaut, on facture la prestation la plus chère
        },
        {
          value: o.reference,
          type: 'full-text',
        },
        {
          value: o._id,
          type: 'full-text',
        },
      ];
    },
    asc: true,
  });

  const bestMatchingOffer: ClubProductPackageOfferView =
    sortedMatchingOffers?.length ? sortedMatchingOffers[0] : undefined;

  // par défaut: on ne choisit aucune offre non exclue, car la liste contient toutes les formations!

  return bestMatchingOffer;
}

function excludeNonBestOffers({
  productPackageOffers,
  criteria,
}: {
  productPackageOffers: ClubProductPackageOfferView[];
  criteria: DiverPurchaseTrainingEditorDialogMatchingOfferCriteria;
}): ClubProductPackageOfferView[] {
  return productPackageOffers.filter((o) => {
    const diveAttributes = o.productPackage.diveAttributes;
    const productAttributes = o.productPackage.productAttributes;
    const salesCriteria = o.productPackage.salesCriteria;

    if (salesCriteria?.dontSelectByDefault) {
      return false;
    }

    if (
      o.productPackage.trainingAttributes?.diveTrainingReference !==
      criteria.defaultTrainingReference
    ) {
      return false;
    }
    if (
      productAttributes?.minAge > 0 &&
      criteria.age &&
      criteria.age < productAttributes?.minAge
    ) {
      // âge mini : si âge du participant inconnu, on ignore le critère
      return false;
    }
    if (
      productAttributes?.maxAge > 0 &&
      ((criteria.age && criteria.age > productAttributes?.maxAge) ||
        (!criteria.age && productAttributes?.maxAge >= 18))
    ) {
      // âge mini : si âge du participant inconnu,
      // on ignore le critère seulement si maxAge >=18 (les offres enfants sont exclues)
      return false;
    }

    return true;
  });
}
