import {
  ClubParticipantEquipment,
  ClubStaffMemberEquipment,
  DiveCenterEquipmentConfig,
  DiveCenterPrivateSettingsEquipment,
} from '@mabadive/app-common-model';

export const equipmentVisibilityBuilder = {
  isAnyEquipmentVisibleOnSession,
  isEquipmentVisibleOnSession,
  isStaffEquipmentVisibleOnSession,
};

function isAnyEquipmentVisibleOnSession({
  equipment,
  equipmentsSettings,
}: {
  equipment?: ClubParticipantEquipment;
  equipmentsSettings: DiveCenterPrivateSettingsEquipment;
}): boolean {
  return (
    equipment &&
    equipmentsSettings.equipments.find((equipmentConfig) =>
      isEquipmentVisibleOnSession({
        equipmentConfig,
        equipment,
      }),
    ) !== undefined
  );
}

function isEquipmentVisibleOnSession({
  equipmentConfig,
  equipment,
}: {
  equipmentConfig: DiveCenterEquipmentConfig;
  equipment: ClubParticipantEquipment;
}): unknown {
  return (
    equipment &&
    equipmentConfig.enabled &&
    ((equipmentConfig.club.visibleOnSession &&
      equipment?.[equipmentConfig.type]?.clubEquipped) ||
      (equipmentConfig.self.visibleOnSession &&
        equipment?.[equipmentConfig.type]?.selfEquipped))
  );
}

function isStaffEquipmentVisibleOnSession({
  equipmentConfig,
  equipment,
}: {
  equipmentConfig: DiveCenterEquipmentConfig;
  equipment: ClubStaffMemberEquipment;
}): unknown {
  return (
    equipment &&
    equipmentConfig.enabled &&
    equipmentConfig.enableStaffEquipment &&
    ((equipmentConfig.club.visibleOnSession &&
      equipment?.[equipmentConfig.type]?.clubEquipped) ||
      (equipmentConfig.self.visibleOnSession &&
        equipment?.[equipmentConfig.type]?.selfEquipped))
  );
}
