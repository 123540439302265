import {
  AppAuthenticatedCustomerAuthorizations,
  AppAuthenticatedUser,
  Booking,
  BookingCustomerCheckoutState,
} from '@mabadive/app-common-model';
import {
  bookingMemberFullSorter,
  dataSorter,
  dateService,
} from '@mabadive/app-common-services';
import {
  AggregatedBookingSessionFull,
  CUS_BookingMemberFull,
  CUS_BookingParticipantFull,
} from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/models';
import {
  DiveCenterBookingPageAggregatedBookingResume,
  DiveCenterBookingPageDataCore,
} from '../../model';
import { checkoutStateBuilder } from '../checkoutStateBuilder.service';
import {
  DiveCenterBookingCheckoutPageLinks,
  diveCenterBookingLinksBuilder,
} from '../diveCenterBookingLinksBuilder.service';

export const diveCenterBookingPageAggregatedBookingResumeBuilderBookingFull = {
  buildBookingFull,
};

function buildBookingFull({
  dataCore,
  booking,
  bookingParticipantsFull: participantFullProductsAll,
  bookingMembersIdsInitialOrder,
  securityUser,
}: {
  dataCore: DiveCenterBookingPageDataCore;
  booking: Booking;
  bookingParticipantsFull: CUS_BookingParticipantFull[];
  bookingMembersIdsInitialOrder: string[];
  securityUser: AppAuthenticatedUser;
}): DiveCenterBookingPageAggregatedBookingResume {
  if (!booking || !dataCore) {
    return undefined;
  }
  const { clubReference, diveCenter, clubPublicReference } = dataCore;

  const bookingParticipantsFull =
    sortEditionBookingResumeMembersByBirthDateDesc(
      participantFullProductsAll.filter((x) => x.booking?._id === booking._id),
    );

  const bookingSessionParticipants = dataCore.bookingSessionParticipants.filter(
    (x) => x.bookingId === booking._id,
  );
  const participantIds = bookingSessionParticipants.map((x) => x.participantId);

  const bookingSessions = dataCore.bookingSessions.filter(
    (x) => x.bookingId === booking._id,
  );
  const bookingMembers = dataCore.bookingMembers.filter(
    (x) => x.bookingId === booking._id,
  );
  const bookingMembersFullUnsorted: CUS_BookingMemberFull[] =
    bookingMembers.map((bookingMember) => {
      const diver = dataCore.clubDivers.find(
        (x) => x._id === bookingMember.diverId,
      );
      const memberFull: CUS_BookingMemberFull = {
        booking,
        bookingMember,
        diver,
      };
      return memberFull;
    });

  const bookingSessionsFull: AggregatedBookingSessionFull[] = bookingSessions
    .map((bookingSession) => {
      const diveSession = dataCore.diveSessions.find(
        (x) => x.reference === bookingSession.diveSessionReference,
      );

      const bookingSessionFull: AggregatedBookingSessionFull = {
        booking,
        diveSession,
        bookingSession,
        entityState: 'original', // TODO prendre en compte si besoin l'état 'updated'
      };
      return bookingSessionFull;
    })
    .filter(
      (bs) => !!bs.diveSession, // HACK: lors de la sauvegarde massive, il peut arriver que diveSession n'ait pas été trouvée, juste après le refresh post-enregistrement, donc on ignore la session pour ne pas tout casser
    );
  const clubParticipants = dataCore.clubParticipants.filter((x) =>
    participantIds.includes(x._id),
  );

  const clubResume = dataCore.clubResumes.find(
    (x) => x.club.reference === clubReference,
  );

  const datesRange: { minDate?: Date; maxDate?: Date } =
    bookingSessionsFull.reduce((acc, bs) => {
      const date = dateService.getUTCDateSetTime(bs.diveSession.time);
      if (!acc.minDate || dateService.isBefore(date, acc.minDate)) {
        acc.minDate = date;
      }
      if (!acc.maxDate || dateService.isBefore(acc.maxDate, date)) {
        acc.maxDate = date;
      }
      return acc;
    }, {} as { minDate?: Date; maxDate?: Date });

  const bookingCustomerUpdates = dataCore.bookingCustomerUpdates.filter(
    (b) => b.bookingId === booking._id,
  );

  let allBookingMembersFull: CUS_BookingMemberFull[] =
    bookingMemberFullSorter.sort(bookingMembersFullUnsorted, {
      bookingContactDiverId: booking.bookingContactDiverId,
      bookingMembersIdsInitialOrder,
    });

  if (!bookingMembersIdsInitialOrder) {
    bookingMembersIdsInitialOrder = allBookingMembersFull.map(
      (x) => x.diver._id,
    );
  }

  const bookingMemberContactFull = allBookingMembersFull.find(
    (x) => x.diver._id === booking.bookingContactDiverId,
  );

  const appUserDiverId = securityUser.diver.diverId;
  const isAppUserBookingContact =
    appUserDiverId === booking.bookingContactDiverId;

  const az: AppAuthenticatedCustomerAuthorizations =
    securityUser?.diver?.authorizations;

  const bookingMembersFull: CUS_BookingMemberFull[] = az?.view
    ?.otherParticipantsDetails
    ? allBookingMembersFull
    : allBookingMembersFull.filter((x) => x.diver._id === appUserDiverId);

  const totalDivesCount = clubParticipants
    .filter((x) => x.bookingState?.value !== 'cancelled')
    .map((x) => x.divesCount ?? 1)
    .reduce((acc, x) => acc + x, 0);

  const checkoutState: BookingCustomerCheckoutState =
    checkoutStateBuilder.buildCheckoutState({
      booking,
      bookingCustomerUpdates,
      bookingPayments: dataCore.bookingPayments,
    });

  const links: DiveCenterBookingCheckoutPageLinks =
    diveCenterBookingLinksBuilder.buildLinks({
      clubPublicReference,
      bookingId: booking._id,
      checkoutState,
    });

  const aggregated: DiveCenterBookingPageAggregatedBookingResume = {
    clubResume,
    diveCenter,
    datesRange,
    booking: {
      _id: booking._id,
      diveCenterId: booking.diveCenterId,
      bookingDate: booking.bookingDate,
      bookingLastUpdateDate: booking.bookingLastUpdateDate,
      active: booking.active,
      bookingStatus: booking.bookingStatus,
      bookingContactDiverId: booking.bookingContactDiverId,
      bookingJourney: booking.bookingJourney,
      bookingInitialPlan: booking.bookingInitialPlan,
      bookingCustomerConfig: booking.bookingCustomerConfig,
      bookingGroup: booking.bookingGroup,

      clubReference,
    },
    bookingMembersIdsInitialOrder,
    bookingParticipantsFull,
    clubParticipants,
    bookingSessions,
    bookingMembers,
    bookingMembersFull,
    bookingMemberContactFull,
    bookingSessionsFull,
    participantsCount: bookingMembers.length,
    bookingInquiryResponses: dataCore.inquiryResponses,
    editableParticipantsCount: az?.actions?.editOtherParticipantsDetails
      ? bookingMembers.length
      : az?.actions?.editSelfDetails
      ? 1
      : 0,
    isUniqueMember: bookingMembers.length === 1,
    bookingCustomerUpdates,
    totalDivesCount,
    totalSessionsCount: bookingSessions.length,
    checkoutState,
    links,
  };

  return aggregated;
}

function sortEditionBookingResumeMembersByBirthDateDesc(
  items: CUS_BookingParticipantFull[],
): CUS_BookingParticipantFull[] {
  return dataSorter.sortMultiple(items, {
    // eslint-disable-next-line no-sparse-arrays
    getSortAttributes: (x: CUS_BookingParticipantFull) => [
      {
        value: x?.diver?.birthdate,
        asc: false,
      },
      {
        value: x?.diver?.lastName,
        type: 'full-text',
        asc: true,
      },
      ,
      {
        value: x?.diver?.firstName,
        type: 'full-text',
        asc: true,
      },
    ],
    asc: true,
  });
}
