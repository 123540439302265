import { useMemo } from 'react';

export function useIsTouchDevice() {
  return useMemo(() => {
    // https://thewebdev.info/2021/02/27/how-to-detect-a-touch-[100dvh]-device-using-javascript/
    return (
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      (navigator as any).msMaxTouchPoints > 0
    );
  }, []);
}
