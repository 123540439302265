import { ClubBoat } from '@mabadive/app-common-model';
import { dataSorter } from '../../data/sorter/dataSorter.service';
import { ClubBoatSortByAttribute } from './ClubBoatSortByAttribute.type';

export const clubBoatsSorter = {
  sortClubBoatsBy,
};

function sortClubBoatsBy(
  boats: ClubBoat[],
  {
    attributeName,
    asc,
  }: {
    attributeName: ClubBoatSortByAttribute;
    asc: boolean;
  },
): ClubBoat[] {
  const type = isFullText() ? 'full-text' : 'default';

  return dataSorter.sortMultiple(boats, {
    getSortAttributes: (x: ClubBoat) => [
      {
        value: getAttribute(x, { attributeName }),
        type,
      },
      {
        value: x.name,
        type: 'full-text',
      },
      {
        value: x.immatriculation,
        type: 'full-text',
      },
    ],
    asc,
  });

  function isFullText() {
    const textAttributes: ClubBoatSortByAttribute[] = [
      'name',
      'immatriculation',
    ];
    return textAttributes.includes(attributeName);
  }

  function getAttribute(
    x: Partial<ClubBoat>,
    { attributeName }: { attributeName: ClubBoatSortByAttribute },
  ) {
    return x[attributeName] as any;
  }
}
