"use strict";
exports.__esModule = true;
exports.CLUB_DEFAULT_BOOKLET_THEME = void 0;
exports.CLUB_DEFAULT_BOOKLET_THEME = {
    title1Color: '#366292',
    title2Color: '#333',
    textColor: '#555',
    buttonPrimaryBgColor: '#2a4b92',
    buttonPrimaryTextColor: '#ffffff',
    buttonCancelBgColor: '#fafafa',
    buttonCancelTextColor: '#555'
};
