import { AppGenericStatus } from './AppGenericStatus.type';

export const appGenericStatusStyleBuilder = {
  build,
};

function build(
  status: AppGenericStatus,
  options: {
    hover: boolean;
  } = {
    hover: false,
  },
): string {
  return `text-status-${status} ${
    options.hover && `hover:text-status-${status}-dark`
  }`;
}
