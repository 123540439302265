/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubSettingsGeneralOnlineBookingSessionDiveModeConfig,
  CustomerFetchAvailabilityDiveSession,
  CustomerFetchAvailabilyCriteria,
  CustomerFetchAvailabilyResultDay,
} from '@mabadive/app-common-model';
import {
  dateService,
  effectiveDateBoundsBuilder,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useCallback, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppHackableCalendar } from '../../../../../../stories/components';
import {
  AppButton,
  AppIconsAction,
  AppLoader,
  AppMessageLight,
} from '../../../../../_core/modules/layout';
import { CustomerProductsPage_ProductArticleParticipantsCount } from './CustomerProductsPage_BookProductPanel_ChooseQuantity/CustomerProductsPage_ProductArticleParticipantsCount';
import { useCustomerFetchAvailability } from './CustomerProductsPage_BookProductPanel_ChooseQuantity/useCustomerFetchAvailability.hook';
import { useAppHackableCalendarCustomizeDayFromSessionAvailability } from './useAppHackableCalendarCustomizeDayFromSessionAvailability.hook';
import { CustomerProductsPage_BookProductPanelLocalState } from './useCustomerProductsPage_BookProductPanelLocalState.hook';

export const CustomerProductsPage_BookProductPanel_ChooseDateAndSession = ({
  localState,
  className,
}: {
  localState: CustomerProductsPage_BookProductPanelLocalState;
  className?: string;
}) => {
  const redirectTo = useRedirect();

  const { state, data, actions } = localState;
  const control = state.form.control;

  const { viewState, clubReference, diveCenterId, sessionDiveMode } = state;
  const { onlineBookingId } = viewState;

  const productArticleFull = data.productArticleFull;
  const onlineBooking = localState.data?.clubSettings?.general?.onlineBooking;
  const sessionDiveModeConfig: ClubSettingsGeneralOnlineBookingSessionDiveModeConfig =
    state.sessionDiveModeConfig;

  const [activityDate, diveSessionDateTime, diveSessionReference, items] =
    useWatch({
      control,
      name: [
        'article.activityDate',
        'article.diveSessionDateTime',
        'article.diveSessionReference',
        'article.items',
      ],
    });

  const { effectiveFirstDate, effectiveLastDate } =
    effectiveDateBoundsBuilder.getBoundsFromDateConstraints(
      sessionDiveModeConfig,
      {
        firstDate: new Date(), // aujourd'hui
        lastDate: dateService.add(new Date(), 2, 'year'), // date maxi en dur: dans 2 ans
      },
    );

  const [viewDate, setViewDate] = useState<Date>(
    activityDate ?? effectiveFirstDate,
  );
  const [selectedDate, setSelectedDate] = useState<Date>(activityDate);
  const participantsCount = useMemo(() => {
    const participantsCount = (items ?? []).reduce((acc, p) => {
      // const unitQuantiy = p.
      return acc + p.quantity;
    }, 0);
    return participantsCount;
  }, [items]);
  const criteria = useMemo(() => {
    const criteria: CustomerFetchAvailabilyCriteria = {
      onlineBookingId,
      clubReference,
      diveCenterId,
      sessionDiveMode,
      participantsCount,
      refDate: viewDate,
    };
    return criteria;
  }, [
    clubReference,
    diveCenterId,
    onlineBookingId,
    participantsCount,
    sessionDiveMode,
    viewDate,
  ]);

  const {
    data: availabilityResult,
    loadingInProgress,
    refetch,
  } = useCustomerFetchAvailability(criteria);

  const customizeDay =
    useAppHackableCalendarCustomizeDayFromSessionAvailability({
      availabilityResult,
    });
  const sessionsAll: CustomerFetchAvailabilityDiveSession[] = useMemo(
    () =>
      (availabilityResult?.days ?? []).reduce(
        (acc, d) => acc.concat(d.sessions),
        [],
      ),
    [availabilityResult?.days],
  );
  const sessionsAvailable: CustomerFetchAvailabilityDiveSession[] = useMemo(
    () => sessionsAll.filter((x) => x.status === 'ok'),
    [sessionsAll],
  );

  const onlyDaysWithAvailableSessions = true;
  const onlyAvailableSessions = false;

  const filteredDays: CustomerFetchAvailabilyResultDay[] = useMemo(
    () =>
      (availabilityResult?.days ?? [])
        .map((d) => ({
          ...d,
          sessions: d.sessions.filter((x) =>
            onlyAvailableSessions && !selectedDate
              ? x.status === 'ok' || x.status === 'partial'
              : x.status === 'ok' ||
                x.status === 'no' ||
                x.status === 'partial',
          ),
        }))
        .filter((d) => {
          if (selectedDate) {
            // jour sélectionné, on filtre sur cette journée
            return dateService.isSameDayUTC(d.date, selectedDate);
          }
          if (onlyDaysWithAvailableSessions) {
            return (
              d.sessions.filter(
                (x) => x.status === 'ok' || x.status === 'partial',
              ).length > 0
            );
          }
          return d.sessions.length > 0;
        }),
    [
      availabilityResult?.days,
      onlyAvailableSessions,
      onlyDaysWithAvailableSessions,
      selectedDate,
    ],
  );

  const onClickSession = useCallback(
    (session: CustomerFetchAvailabilityDiveSession) => {
      if (session.status === 'ok') {
        state.form.setValue(
          'article.activityDate',
          dateService.getUTCDateSetTime(session.time),
        );
        state.form.setValue('article.diveSessionDateTime', session.time);
        state.form.setValue('article.diveSessionReference', session.reference);
        actions.goToNextStep();
      }
    },
    [actions, state.form],
  );

  return (
    <div
      className={clsx(
        'flex flex-col md:grid md:grid-cols-2 items-stretch gap-4 md:gap-16',
        className,
      )}
    >
      {!selectedDate && (
        <div
          className={clsx(
            'flex flex-col gap-4',
            // sur mobile, on masque le calendrier une fois que la date est sélectionnée
            selectedDate ? 'hidden md:flex' : '',
          )}
        >
          <h2 className="uppercase font-medium text-app-primary">
            Choisissez une date{' '}
            <span className="text-sm text-gray-600">
              (
              <CustomerProductsPage_ProductArticleParticipantsCount
                participantsCount={participantsCount}
                productArticle={productArticleFull.article}
              />
              )
            </span>
          </h2>
          <AppHackableCalendar
            className="w-full md:w-[36rem] max-w-full"
            minDate={effectiveFirstDate}
            maxDate={effectiveLastDate}
            initialViewDate={viewDate}
            selectedDate={undefined}
            onViewDateChange={(viewDate) => {
              setSelectedDate(undefined);
              setViewDate(viewDate);
            }}
            onSelectDate={(activityDate) => {
              setSelectedDate(activityDate);
              setViewDate(activityDate);
            }}
            customizeDay={customizeDay}
          />
          {loadingInProgress && <AppLoader className="my-4" />}

          <AppButton
            className=""
            size="large"
            icon={AppIconsAction.open}
            color="gray-outline-light"
            type="button"
            onClick={() => actions.setCurrentStep('choose-quantity')}
          >
            Retour
          </AppButton>
        </div>
      )}
      {availabilityResult && (
        <div className="flex flex-col gap-4">
          <h2 className="uppercase font-medium text-app-primary">
            Choisissez un horaire
          </h2>
          {sessionsAvailable.length === 0 && (
            <AppMessageLight type="danger">
              Aucune sortie disponible
            </AppMessageLight>
          )}
          {/* // TODO afficher la prochaine dispo + filtrer par dispo */}
          <div className="flex flex-col gap-4">
            {filteredDays.map((day) => (
              <div key={day.date.getTime()} className="flex flex-col gap-2">
                <div className="font-medium text-gray-800">
                  {dateService
                    .formatUTC(day.date, 'dddd DD MMMM YYYY')
                    ?.toUpperCase()}
                </div>

                <div className="flex gap-2 flex-wrap justify-start">
                  {day.sessions.map((session) => (
                    <div
                      key={session.time.getTime()}
                      className={clsx(
                        'w-20 h-12 border border-gray-200 text-center',
                        'text-gray-500 hover:text-gray-600',
                        'flex flex-col justify-around items-center rounded',
                        session.status === 'ok'
                          ? 'cursor-pointer hover:border-gray-400 hover:bg-gray-50'
                          : 'cursor-not-allowed hover:grayscale',
                        // isSelected && 'ring ring-app-primary ',
                      )}
                      onClick={() => {
                        onClickSession(session);
                      }}
                    >
                      <div className="font-medium">
                        {dateService.formatUTC(session.time, 'HH:mm')}
                      </div>
                      {session.status === 'partial' ? (
                        <div className="text-xs font-bold text-orange-600 uppercase">
                          {session.availableParticipants === 1
                            ? '1 place'
                            : `${session.availableParticipants} places`}
                        </div>
                      ) : session.status === 'closed' ? (
                        <div className="text-xs font-bold text-gray-600 uppercase">
                          FERMÉ
                        </div>
                      ) : session.status === 'no' ? (
                        <div className="text-xs font-bold text-red-600 uppercase">
                          COMPLET
                        </div>
                      ) : (
                        <div className="text-xs font-bold text-green-600 uppercase">
                          DISPO
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          {selectedDate && (
            <AppButton
              className="mt-8"
              size="large"
              icon={AppIconsAction.open}
              color="gray-outline-light"
              onClick={() => setSelectedDate(undefined)}
            >
              Retour
            </AppButton>
          )}
        </div>
      )}

      {/* Si on a fait back, et que la date est renseignée, on peut ajouter ce bouton : */}
      {/* <div
        className={clsx(
          'my-6 text-center',
          state.shoppingCart.totalQuantity > 0 ? '' : 'invisible',
        )}
      >
        <AppButton
          className="uppercase"
          size="large"
          icon={AppHeroIcons.actionConfirm}
          color="primary-bg"
          onClick={() => actions.confirmQuantity()}
        >
          Confirmer
        </AppButton>
      </div> */}
    </div>
  );
};
