import clsx from 'clsx';
import React, { FC } from 'react';
import { AppBreadcrumbBarItem } from './AppBreadcrumbBarItem';
import { AppBreadcrumbItem } from './model';

export const AppBreadcrumbBar: FC<{
  children?: React.ReactNode | React.ReactNode[];
  items: AppBreadcrumbItem[];
  color?: string;
  className?: string;
  classNameInner?: string;
  firstItemClassName?: string;
}> = ({
  items,
  color,
  className,
  classNameInner,
  firstItemClassName = 'ml-4',
  children,
}) => {
  // const hoverColor = useMemo(() => colorGenerator.darken(color, 0.5), [color]);

  return (
    <nav
      className={clsx('w-full flex items-center leading-3', className)}
      aria-label="Breadcrumb"
      style={{ backgroundColor: color }}
    >
      <ol
        className={clsx(
          'w-full flex items-center space-x-2 md:space-x-4 rounded-md shadow uppercase',
          classNameInner,
        )}
      >
        {items.map((item, i) => (
          <AppBreadcrumbBarItem
            key={i}
            item={item}
            isFirst={i === 0}
            isActive={i === items.length - 1}
            firstItemClassName={firstItemClassName}
          />
        ))}
      </ol>
      {children && children}
    </nav>
  );
};
