import {
  CLUB_DEFAULT_UI_DIVE_MODE,
  ClubSettingsUIDiveMode,
  DiveMode,
} from '@mabadive/app-common-model';

export type DiveModeStringFormat =
  | 'short-ref'
  | 'short-label'
  | 'long-label'
  | 'short-ref-label';

export const diveModeFormatter = {
  formatDiveMode,
};

function formatDiveMode(
  diveMode: DiveMode,
  {
    format,
    diveModesConf,
  }: { format: DiveModeStringFormat; diveModesConf?: ClubSettingsUIDiveMode },
): string {
  const clubDiveModeConf = diveModesConf?.[diveMode];
  const defaultDiveModeConf = CLUB_DEFAULT_UI_DIVE_MODE[diveMode];
  if (format === 'short-ref') {
    const ref = clubDiveModeConf?.ref;
    if (ref) {
      return ref;
    }
    return defaultDiveModeConf?.ref;
  }
  if (format === 'short-label') {
    const labelShort = clubDiveModeConf?.labelShort;
    if (labelShort) {
      return labelShort;
    }
    return defaultDiveModeConf?.labelShort;
  }
  if (format === 'long-label') {
    const labelLong = clubDiveModeConf?.labelLong;
    if (labelLong) {
      return labelLong;
    }
    return defaultDiveModeConf?.labelLong;
  }
  const ref = clubDiveModeConf?.ref;
  const labelLong = clubDiveModeConf?.labelLong;
  if (labelLong) {
    return `${ref} - ${labelLong}`;
  }
  return `${defaultDiveModeConf?.ref} - ${defaultDiveModeConf?.labelShort}`;
}
