"use strict";
exports.__esModule = true;
exports.SAFETY_CERTIFICATIONS = void 0;
exports.SAFETY_CERTIFICATIONS = [
    {
        reference: 'rifap',
        label: 'RIFAP',
        description: 'FFESSM',
        diveOrganizationReference: 'ffessm'
    },
    {
        reference: 'padi-efr',
        label: 'PADI EFR',
        description: 'Emergency First Response',
        diveOrganizationReference: 'padi'
    },
    // NON, c'est un niveau de plongée
    // {
    //   reference: 'padi-rd',
    //   label: 'PADI RD',
    //   description: 'Rescue Diver',
    //   diveOrganizationReference: 'padi',
    // },
    // NON, c'est un niveau de plongée
    // {
    //   reference: 'ssi-sr',
    //   label: 'SSI SR',
    //   description: 'Stress & Rescue',
    //   diveOrganizationReference: 'ssi',
    // },
    // https://www.daneurope.org/fr/formation
    {
        reference: 'dan-bls',
        label: 'DAN BLS',
        description: 'Basic Life Support'
    },
    {
        reference: 'dan-pbls',
        label: 'DAN PBLS',
        description: 'Paediatric Basic Life Support'
    },
    {
        reference: 'dan-aed',
        label: 'DAN AED',
        description: 'Automated External defibrillator'
    },
    {
        reference: 'dan-fa',
        label: 'DAN FA',
        description: 'First Aid'
    },
    {
        reference: 'dan-ofa',
        label: 'DAN OFA',
        description: 'Oxygen First Aid'
    },
    {
        reference: 'adip-dm-o2',
        label: 'ADIP DM-O2',
        description: 'Dive Médic +  O2',
        diveOrganizationReference: 'adip'
    },
    {
        reference: 'tdi-sdi-erd-tender',
        label: 'TDI-SDI ERD Tender',
        diveOrganizationReference: 'tdi-sdi'
    },
    {
        reference: 'tdi-sdi-erd-1',
        label: 'TDI-SDI ERD 1',
        diveOrganizationReference: 'tdi-sdi'
    },
    {
        reference: 'tdi-sdi-erd-2',
        label: 'TDI-SDI ERD 2',
        diveOrganizationReference: 'tdi-sdi'
    },
    {
        reference: 'psc1',
        label: 'PSC1'
    },
];
