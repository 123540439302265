/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { FC } from 'react';
import { BookingCheckoutEditMembersPanelNextOrFinishButton } from './BookingCheckoutEditMembersPanelNextOrFinishButton';
import { BookingCheckoutEditMembersPanelPreviousOrCancelButton } from './BookingCheckoutEditMembersPanelPreviousOrCancelButton';
import { DiveCenterBookingMemberEditPanelLocalState } from './useDiveCenterBookingMemberEditPanelLocalState';

export const BookingCheckoutEditMembersPanelNavbar: FC<{
  localState: DiveCenterBookingMemberEditPanelLocalState;
  className?: string;
}> = ({ localState, className }) => {
  const { viewState, editableParticipantsCount } = localState;

  return (
    <div
      className={clsx(
        'bg-white app-px-content flex justify-between gap-4',
        className,
      )}
    >
      <BookingCheckoutEditMembersPanelPreviousOrCancelButton
        localState={localState}
      />
      {editableParticipantsCount > 1 && (
        <div>
          <div className="py-2 text-gray-600 font-bold text-sm md:text-lg uppercase">
            <span className="hidden sm:inline">Participant</span>{' '}
            {viewState.participantsIndex + 1}/{editableParticipantsCount}
          </div>
        </div>
      )}
      <BookingCheckoutEditMembersPanelNextOrFinishButton
        localState={localState}
      />
    </div>
  );
};
