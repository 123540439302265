import { APP_COLORS } from '@mabadive/app-common-model';
import { Theme } from '@material-ui/core';
import { darken, lighten } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/styles/withStyles';
import { responsiveAttributesValues } from '../../../layout';
import { appStylesHelper } from './appStylesHelper.service';

export function buildGlobalButtonCancelStyles(
  theme: Theme,
): CreateCSSProperties<{}> {
  return {
    ...buildButtonStyles(theme),
    '&.MuiButton-root': {
      color: lighten(theme.palette.common.black, 0.5),
      borderColor: lighten(theme.palette.common.black, 0.5),
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        color: lighten(theme.palette.common.black, 0.2),
        borderColor: lighten(theme.palette.common.black, 0.2),
        backgroundColor: theme.palette.common.white,
      },
    },
  };
}
export function buildGlobalButtonSaveStyles(
  theme: Theme,
): CreateCSSProperties<{}> {
  return {
    ...buildButtonStyles(theme),
    '&.MuiButton-root': {
      backgroundColor: APP_COLORS.white,
      color: darken(APP_COLORS.update, 0.3),
      '&:hover': {
        backgroundColor: APP_COLORS.white,
        color: darken(APP_COLORS.update, 0.5),
      },
    },
  };
}
export function buildGlobalButtonRemoveStyles(
  theme: Theme,
): CreateCSSProperties<{}> {
  return {
    '&.MuiButton-root': {
      ...buildButtonStyles(theme),
      color: lighten(theme.palette.common.black, 0.5),
      borderColor: lighten(theme.palette.common.black, 0.5),
      '&:hover': {
        color: theme.palette.common.white,
        borderColor: lighten(theme.palette.common.black, 0.2),
        backgroundColor: APP_COLORS.red,
      },
    },
  };
}
export function buildGlobalButtonNavigateStyles(
  theme: Theme,
): CreateCSSProperties<{}> {
  return {
    ...buildButtonStyles(theme),
  };
}

function buildButtonStyles(theme: Theme): CreateCSSProperties<{}> {
  return {
    cursor: 'pointer',
    // maxWidth: '50%',
    ...responsiveAttributesValues(theme, {
      // width: { values: [160, 200, 220, 240, 260], suffix: 'px' },
      padding: {
        values: ['3px 8px', '4px 10px', '5px 12px', '6px 14px', '6px 16px'],
      },
      fontSize: appStylesHelper.getFontSizeResponsive('xs'),
    }),
    '& .icon': {
      ...responsiveAttributesValues(theme, {
        marginRight: {
          values: [
            theme.spacing(0.2),
            theme.spacing(0.4),
            theme.spacing(0.6),
            theme.spacing(0.8),
            theme.spacing(1),
          ],
          suffix: 'px',
        },
      }),
    },
  };
}
