import { AppDocStatus } from '@mabadive/app-common-model';

export const appDocStatusFormatter = {
  formatAppDocStatus: formatAppDocStatus,
};

function formatAppDocStatus(appDocStatus: AppDocStatus): string {
  switch (appDocStatus) {
    case 'submitted':
      return 'A vérifier';
    case 'validated':
      return 'Validé';
    case 'rejected':
      return 'Refusé';
    default:
      return '';
  }
}
