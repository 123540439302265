import { EquipmentType } from '@mabadive/app-common-model';
import { FunctionComponent, SVGProps } from 'react';
import { GiChelseaBoot as divingBoots } from 'react-icons/gi';
import { ReactComponent as compass } from 'src/assets/icons/equipment/compass.svg';
import { ReactComponent as fins } from 'src/assets/icons/equipment/diving-fins.svg';
import { ReactComponent as scubaRegulator } from 'src/assets/icons/equipment/diving-regulator.svg';
import { ReactComponent as wetsuit } from 'src/assets/icons/equipment/drysuit.svg';
import { ReactComponent as flashLight } from 'src/assets/icons/equipment/flashlight.svg';
import { ReactComponent as jacket } from 'src/assets/icons/equipment/lifeguard-jacket.svg';
import { ReactComponent as tank } from 'src/assets/icons/equipment/oxygen-cylinder.svg';
import { ReactComponent as rebreather } from 'src/assets/icons/equipment/oxygen-tank.svg';
import { ReactComponent as mask } from 'src/assets/icons/equipment/scuba-mask-2905978.svg';
import { ReactComponent as computer } from 'src/assets/icons/equipment/smartwatch.svg';
import { ReactComponent as underwaterScooter } from 'src/assets/icons/equipment/underwater-scooter.svg';
import { ReactComponent as weighting } from 'src/assets/icons/equipment/weighting.svg';
import { ReactComponent as all } from 'src/assets/icons/scuba-diver.svg';
export const AppIconsDiveEquipmentType: {
  [type in EquipmentType]: FunctionComponent<SVGProps<SVGSVGElement>>;
} = {
  wetsuit,
  fins,
  jacket,
  tank,
  scubaRegulator,
  divingBoots,
  mask,
  flashLight,
  computer,
  compass,
  rebreather,
  underwaterScooter,
  weighting,
};

export const AppIconsDiveEquipment = {
  all,
  ...AppIconsDiveEquipmentType,
};
