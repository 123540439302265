/* eslint-disable jsx-a11y/alt-text */
import {
  ClubPublicSettings,
  CustomerEcommerceProductArticleFull,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
export const EcommerceProductArticlePreviewCover_SubTitle = ({
  productArticleFull,
  clubPublicSettings,
  className,
}: {
  productArticleFull: CustomerEcommerceProductArticleFull;
  clubPublicSettings: ClubPublicSettings;
  className?: string;
}) => {
  const articleCover =
    productArticleFull.articleBookletPage?.mainContent?.cover;

  const appUiTheme = clubPublicSettings?.appUiTheme;

  return articleCover?.subTitle?.trim() ? (
    <div
      className={clsx('text-sm font-medium w-full text-left', className)}
      style={{ color: appUiTheme.title2.textColor }}
    >
      {articleCover?.subTitle}
    </div>
  ) : null;
};
