"use strict";
exports.__esModule = true;
// > & {
//   bookingContact: Pick<
//     ClubDiver,
//     | '_id'
//     | 'firstName'
//     | 'lastName'
//     | 'fakeName'
//     | 'phoneNumber'
//     | 'emailAddress'
//     | 'birthdate'
//     | 'age'
//     | 'mainCertificationReference'
//     | '_updatedAt'
//   >;
// } & {
//   bookingSessionsMeta: {
//     aggregate: {
//       count: number;
//       minDiveSession: {
//         diveSessionReference: string;
//       };
//       maxDiveSession: {
//         diveSessionReference: string;
//       };
//     };
//   };
//   bookingMembersMeta: {
//     aggregate: {
//       count: number;
//     };
//   };
//   bookingSessionParticipantsMeta: {
//     aggregate: {
//       count: number;
//     };
//   };
//   bookingCustomerUpdates: BookingCustomerUpdate[];
// };
