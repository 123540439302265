import {
  AppCurrency,
  Customer_ClubSettingsPublicCustomer,
  OnlineBookingDetailsArticleOrderItem,
  OnlineBookingOrderArticleFull,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { useAppCurrencyMain } from '../../../../../pages';
import { AppPriceLabel } from '../../../../club/modules/_common/ui';
import { useDiveCenterShoppingCart } from '../services';

export const DiveCenterShoppingCartOrderArticleItem = ({
  orderArticleFull,
  orderArticleItem: item,
  clubSettings,
  className,
}: {
  orderArticleFull: OnlineBookingOrderArticleFull;
  orderArticleItem: OnlineBookingDetailsArticleOrderItem;
  clubSettings: Customer_ClubSettingsPublicCustomer;
  className?: string;
}) => {
  const { cart } = useDiveCenterShoppingCart();

  const clubPublicSettings = clubSettings?.publicSettings;
  const mainCurrency: AppCurrency = useAppCurrencyMain({ clubPublicSettings });
  const productArticleFull = orderArticleFull?.productArticleFull;

  const totalPrice = item.price.unitPrice * item.participants.length;

  const articleBookletPage =
    orderArticleFull?.productArticleFull?.articleBookletPage;
  const content = articleBookletPage?.mainContent?.content;

  return (
    <div className={clsx('py-4 w-full flex gap-2 items-start')}>
      <div className="flex-shrink-0">
        {content?.image2?.publicUrl ? (
          <img
            alt="Product illustration"
            src={content?.image2?.publicUrl}
            className="brightness-125 w-20 bg-gray-400 rounded-md"
          />
        ) : (
          <img
            alt="Product illustration"
            src={
              'https://mabadive.fra1.cdn.digitaloceanspaces.com/images%2Fproducts%2Fscuba-diving-300x300.png'
            }
            className="grayscale brightness-125 w-20 bg-gray-400 rounded-md"
          />
        )}
      </div>
      <div className="w-full flex flex-col gap-2">
        <div className="">
          <div className="w-full flex flex-row gap-4 justify-between items-start">
            <div className="uppercase font-bold text-gray-600">
              <div className="text-app-primary">
                {productArticleFull?.product?.name}
              </div>
              <div className="text-xs sm:text-sm text-gray-500">
                {item.price.label}
              </div>
            </div>
            <AppPriceLabel
              className="text-gray-500 font-bold"
              amount={item.price.unitPrice}
              mainCurrency={mainCurrency}
            />
          </div>
          <div className="italic text-gray-500">{item.price.comment}</div>
        </div>
        <div className="text-right text-sm font-medium text-app-primary uppercase">
          {item.quantity !== item.participants?.length ? (
            <>
              <div className="text-gray-600">
                {item.participants?.length} PARTICIPANTS
              </div>
              <div className="text-app-primary">Quantité: {item.quantity}</div>
            </>
          ) : (
            <>{item.quantity} PARTICIPANTS</>
          )}
        </div>
      </div>
    </div>
  );
  // <li className={clsx('flex py-6', className)}>
  //   <div className="flex flex-1 flex-col">
  //     <div className="flex">
  //       <div className="min-w-0 flex-1">
  //         <h4 className="text-base">
  //           <span className="font-bold text-gray-600">
  //             {orderArticleItem.price.label}
  //           </span>
  //         </h4>
  //       </div>
  //     </div>

  //     <div className="flex flex-1 items-end justify-between pt-2">
  //       <div className="mt-1 text-sm font-medium text-gray-900">
  //         <AppPriceLabelRatio
  //           className="text-gray-600"
  //           amount={orderArticleItem.price.unitPrice}
  //           total={
  //             orderArticleItem.price.unitPrice *
  //             orderArticleItem.participants.length
  //           }
  //           mainCurrency={mainCurrency}
  //           hideTotalIfSameAmount={true}
  //         />
  //       </div>
  //     </div>
  //   </div>
  // </li>
};
