"use strict";
exports.__esModule = true;
exports.DEFAULT_APP_UI_THEME = void 0;
exports.DEFAULT_APP_UI_THEME = {
    title1: {
        bgColor: undefined,
        textColor: '#1a7498'
    },
    title2: {
        bgColor: undefined,
        textColor: '#333'
    },
    buttonPrimary: {
        bgColor: '#1a7498',
        textColor: '#ffffff'
    },
    buttonCancel: {
        bgColor: '#eee',
        textColor: '#555'
    },
    text: {
        bgColor: '#fff',
        textColor: '#555'
    }
};
