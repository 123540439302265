import { StackTracker, TrackEventArgs, TrackPageViewArgs } from '../model/StackTracker.type';

export const logTracker = {

  build({
    log,
  }: {
    log: (text: string, ...args: any) => void;
  }): StackTracker {
    return {
      trackPageView,
      trackEvent,
    };

    function trackPageView(args: TrackPageViewArgs) {
      log(`[logTracker] trackPageView "${formatArgs(args)}"`);
    }

    function trackEvent(args: TrackEventArgs) {
      log(`[logTracker] trackEvent "${formatArgs(args)}"`);
    }
  },

};

function formatArgs(args: Object) {
  if (!args) {
    return '';
  }
  return Object.keys(args).map((key) => `"${key}"="${(args as any)[key] as any}"`).join(', ');
}
