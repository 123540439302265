/* eslint-disable @typescript-eslint/no-unused-vars */
import { CheckIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppSingleSelect2HeadlessUIOptionProps } from './AppSingleSelect2HeadlessUIOption';
import { appSingleSelectOptionColorsPaleteBuilder } from './appSingleSelectOptionColorsPaleteBuilder.service';

export function AppSingleSelect2HeadlessUIOptionContent<
  T,
  L extends string | React.ReactNode = string,
>({
  option,
  color,
  showIcons = true,
  selected,
  active,
  className,
}: AppSingleSelect2HeadlessUIOptionProps<T, L> & {
  selected: boolean;
  active: boolean;
}) {
  const OptionIcon = option?.icon;

  const optionColors = useMemo(
    () =>
      appSingleSelectOptionColorsPaleteBuilder.buildColorsPaletOption({
        option,
        color,
      })?.option,
    [color, option],
  );

  return (
    <div className={clsx('flex gap-2', className)}>
      {showIcons && OptionIcon && (
        <OptionIcon
          className={clsx(
            'h-4 w-4',
            active ? 'text-white' : optionColors.selectedCheckIcon,
          )}
        />
      )}
      <span
        className={clsx(
          // selected ? 'font-semibold' : 'font-normal',
          'w-full block text-xs truncate',
        )}
      >
        {option.label}
      </span>

      {selected ? (
        <span
          className={clsx(
            active ? 'text-white' : optionColors.selectedCheckIcon,
            'absolute inset-y-0 left-0 flex items-center pl-1.5',
          )}
        >
          <CheckIcon className="h-5 w-5" aria-hidden="true" />
        </span>
      ) : null}
    </div>
  );
}
