"use strict";
exports.__esModule = true;
exports.DEMO_CLUB_PARTICIPANT_TAG_1 = void 0;
exports.DEMO_CLUB_PARTICIPANT_TAG_1 = {
    reference: 'attention',
    avatar: {
        label: 'At',
        color: '#FFFFFF',
        background: '#ac5a1d'
    },
    content: {
        label: 'Attention',
        color: '#FFFFFF',
        background: '#c5861b'
    }
};
