import { stringFormatter } from './stringFormatter.service';

export const numberFormatter = {
  toFixedIntegerString,
  convertFloatToInteger,
  percent,
  roundWithCents,
};
function roundWithCents(nb: number, cents: number): number {
  if (cents === 0) {
    return Math.round(nb);
  } else {
    const multiplier = Math.pow(10, cents);
    return Math.round(multiplier * nb) / multiplier;
  }
}

function toFixedIntegerString(
  nb: number,
  length: number,
  {
    defaultValue,
  }: {
    defaultValue?: number;
  } = {},
): string {
  if (nb !== null && nb !== undefined) {
    return (
      stringFormatter.buildStringWithCharacterRepetition('0', length) +
      (nb ?? defaultValue)
    ).slice(-length);
  }
  return '';
}

function convertFloatToInteger(
  num: number,
  options?: { multiplierIfDecimal: number },
): number {
  if (num !== null && num !== undefined) {
    if (options && options.multiplierIfDecimal && Math.round(num) !== num) {
      return Math.round(num * options.multiplierIfDecimal);
    }
    return Math.round(num);
  }
  return num;
}

function percent(
  nb: number,
  total: number,
  { decimals }: { decimals: number } = { decimals: 0 },
): number {
  if (!total) {
    return 0;
  }
  const ratio = nb / total;
  const multi = Math.pow(10, decimals);
  return Math.round(100 * multi * ratio) / multi;
}
