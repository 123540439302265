import { DiveSessionTimeDayPeriod } from '@mabadive/app-common-model';
import React, { FC } from 'react';
import { AppIcon } from '../../../icons';
import { AppButtonStyleColor } from './AppButtonStyleColor.type';
import { AppButtonUnstyled } from './AppButtonUnstyled';
import {
  AppButtonStyleSize,
  appButtonSylesBuilder,
} from './appButtonSylesBuilder.service';

export type AppButtonProps = {
  id?: string;
  className?: string;
  icon?: AppIcon;
  iconPosition?: 'left' | 'right';
  color: AppButtonStyleColor;
  href?: string;
  onClick?: (
    e?:
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => any;
  disabled?: boolean;
  padding?: string;
  size?: AppButtonStyleSize;
  fullWidth?: boolean;
  tabIndex?: number;
  style?: React.CSSProperties;
  timeDayPeriod?: DiveSessionTimeDayPeriod;
  setReferenceElement?: React.LegacyRef<HTMLAnchorElement | HTMLButtonElement>;
  type?: 'button' | 'submit' | 'reset';
  target?: string;
  fontSize?: string;
  rel?: string;
  children?: React.ReactNode | React.ReactNode[];
};

export const AppButton: FC<AppButtonProps> = ({
  id,
  children,
  icon,
  iconPosition = 'left',
  color,
  className,
  href,
  onClick,
  disabled,
  padding,
  size = 'normal',
  fullWidth,
  tabIndex,
  style,
  timeDayPeriod,
  setReferenceElement,
  type = 'button',
  target,
  fontSize,
  rel,
}) => {
  return (
    <AppButtonUnstyled
      id={id}
      gap={
        size === 'small' || size === 'hamburger' ? 1 : size === 'normal' ? 2 : 3
      }
      className={`group 
    ${appButtonSylesBuilder.style({
      color,
      disabled,
      size,
      padding,
      fullWidth,
      withIcon: !!icon,
      timeDayPeriod,
      fontSize,
    })}
    ${className ?? ''}
  `}
      type={type}
      icon={icon}
      iconPosition={iconPosition}
      iconStyleClass={
        size === 'small'
          ? 'h-4 w-4'
          : size === 'hamburger'
          ? 'h-6 w-6'
          : size === 'normal'
          ? 'h-4 w-4'
          : 'h-5 w-5'
      }
      onClick={(e) => {
        if (!disabled && onClick) {
          onClick(e);
        }
      }}
      href={href}
      tabIndex={tabIndex}
      style={style}
      setReferenceElement={setReferenceElement}
      target={target}
      rel={rel}
    >
      {children}
    </AppButtonUnstyled>
  );
};
