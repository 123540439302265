"use strict";
exports.__esModule = true;
exports.WAITING_INFO_CLUB_TAG = exports.WAITING_INFO_DIVER_TAG = void 0;
exports.WAITING_INFO_DIVER_TAG = {
    reference: 'en-attente-infos-plongeur',
    avatar: {
        label: 'I',
        color: '#fff',
        background: '#425495'
    },
    content: {
        label: "En attente d'infos (plongeur)",
        color: '#fff',
        background: '#8498de'
    }
};
exports.WAITING_INFO_CLUB_TAG = {
    reference: 'en-attente-infos-club',
    avatar: {
        label: 'I',
        color: '#fff',
        background: '#000000'
    },
    content: {
        label: "En attente d'infos (club)",
        color: '#fff',
        background: '#000000'
    }
};
