"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.FIRST_DIVES = void 0;
var dive_organization_1 = require("../dive-organization");
var index = 0;
exports.FIRST_DIVES = dive_organization_1._DIVE_ORGANIZATIONS_DATA.reduce(function (acc, data) {
    return acc.concat(data.firstDives.map(function (firstDive) {
        var org = dive_organization_1.DIVE_ORGANIZATIONS.find(function (org) { return org.reference === data.orgGroupReference; });
        var ext = __assign(__assign({}, firstDive), { sortReference: buildSortReference(org, firstDive), organization: org });
        return ext;
    }));
}, []);
function buildSortReference(org, firstDive) {
    var suffix = org.reference === 'cmas'
        ? '1-cmas'
        : org.reference === 'padi'
            ? '2-padi'
            : '4-unknown';
    return "".concat(firstDive.maxDepth < 10 ? '0' : '').concat(firstDive.maxDepth, "m-").concat(suffix, "-").concat(index++);
}
