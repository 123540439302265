import { AppHackableCalendarPropsLabels } from './AppHackableCalendarPropsLabels.props';

export const APP_HACKABLE_CALENDAR_PROPS_DEFAULT_LABELS_FR: AppHackableCalendarPropsLabels =
  {
    months: [
      'janvier',
      'février',
      'Mars',
      'avril',
      'mai',
      'juin',
      'juillet',
      'août',
      'septembre',
      'octobre',
      'novembre',
      'décembre',
    ],
    weekDays: [
      'lundi',
      'mardi',
      'mercredi',
      'jeudi',
      'vendredi',
      'samedi',
      'dimanche',
    ],
    nav: {
      previousMonth: 'Mois précédent',
      nextMonth: 'Mois suivant',
      year: 'Année',
    },
  };
