import {
  APP_DOC_UPLOAD_FILE_TYPE_EXTENSIONS_MAP,
  AppDocFileType,
  AppDocUploadExtension,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { AppDocFileTypeIcon } from './AppDocFileTypeIcon';

const APP_DROPZONE_MIME_TYPES_MAP: Record<string, AppDocUploadExtension[]> = {
  // Texte
  'text/plain': ['.txt'],
  // Documents PDF
  'application/pdf': ['.pdf'],
  // Documents Word et LibreOffice Writer
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx',
  ],
  'application/vnd.oasis.opendocument.text': ['.odt'], // LibreOffice Writer
  // Feuilles de calcul Excel et LibreOffice Calc
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
  'application/vnd.oasis.opendocument.spreadsheet': ['.ods'], // LibreOffice Calc
  // Présentations PowerPoint et LibreOffice Impress
  'application/vnd.ms-powerpoint': ['.ppt'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [
    '.pptx',
  ],
  'application/vnd.oasis.opendocument.presentation': ['.odp'], // LibreOffice Impress
  // Images (optionnel)
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/png': ['.png'],
  'image/gif': ['.gif'],
  'image/webp': ['.webp'],
  // Archives (optionnel, pour zip par exemple)
  'application/zip': ['.zip'],
  'application/x-rar-compressed': ['.rar'],
  'application/x-7z-compressed': ['.7z'],
  'application/x-gzip': ['.gz'],
  'application/x-bzip2': ['.bz2'],
  'application/x-xz': ['.xz'],
  'application/x-tar': ['.tar'],
  'application/x-gtar': ['.tgz'],
};

// Derive a type that maps file extensions to MIME types

export type AppDropzoneLoadedFile = {
  content: string;
  file: File;
  fileType: AppDocFileType;
};

export function AppDropzone({
  maxFileSizeLabel,
  onFileLoaded,
  acceptFileMimeTypes: acceptFileMimeTypesInput,
  acceptFileExtensions: acceptFileExtensionsInput,
  acceptFileTypes: acceptFileTypesInput,
  label,
  showAcceptedExtensions,
  className,
}: {
  maxFileSizeLabel: string;
  onFileLoaded: (loadedFile: AppDropzoneLoadedFile) => void;
  acceptFileMimeTypes?: {
    [mimeType: string]: AppDocUploadExtension[];
  };
  acceptFileExtensions?: AppDocUploadExtension[];
  acceptFileTypes?: AppDocFileType[];
  label: string;
  showAcceptedExtensions?: boolean;
  className?: string;
}) {
  const acceptFileExtensions: AppDocUploadExtension[] = useMemo(() => {
    let acceptFileExtensions = acceptFileExtensionsInput ?? [];
    if (acceptFileTypesInput) {
      acceptFileExtensions = Object.keys(
        APP_DOC_UPLOAD_FILE_TYPE_EXTENSIONS_MAP,
      )
        .filter((fileType) =>
          acceptFileTypesInput.includes(fileType as AppDocFileType),
        )
        .reduce(
          (acc, fileType) =>
            acc.concat(
              APP_DOC_UPLOAD_FILE_TYPE_EXTENSIONS_MAP[
                fileType as AppDocFileType
              ],
            ),
          [] as AppDocUploadExtension[],
        );
    }
    return acceptFileExtensions;
  }, [acceptFileExtensionsInput, acceptFileTypesInput]);

  const acceptFileMimeTypes = useMemo(() => {
    if (acceptFileMimeTypesInput) {
      return acceptFileMimeTypesInput;
    }

    if (acceptFileExtensions) {
      return Object.keys(APP_DROPZONE_MIME_TYPES_MAP)
        .filter((mimeType) => {
          const extensions = APP_DROPZONE_MIME_TYPES_MAP[mimeType];
          return acceptFileExtensions.some((ext) => extensions.includes(ext));
        })
        .reduce((acc, mimeType) => {
          acc[mimeType] = APP_DROPZONE_MIME_TYPES_MAP[mimeType].filter((ext) =>
            acceptFileExtensions.includes(ext),
          );
          return acc;
        }, {} as { [mimeType: string]: AppDocUploadExtension[] });
    }
  }, [acceptFileExtensions, acceptFileMimeTypesInput]);

  const acceptFileTypes = acceptFileTypesInput;

  const onDrop = useCallback(
    (selectedFiles: File[]) => {
      // Do something with the files
      selectedFiles.forEach((file: File) => {
        const reader = new FileReader();

        // reader.onabort = () => console.log('file reading was aborted')
        // reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
          // Do whatever you want with the file contents
          const binaryStr = reader.result as string;
          const dataUrl = binaryStr.substring(0);
          const content = dataUrl.substring(dataUrl.indexOf(',') + 1);
          if (onFileLoaded) {
            const fileType: AppDocFileType = getFileType(file.name);
            const loadedFile = { content, file, fileType };
            // encodé en "base64"
            onFileLoaded(loadedFile);
          }
        };
        reader.readAsDataURL(file);
      });
    },
    [onFileLoaded],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptFileMimeTypes,
  });

  return (
    <div
      {...getRootProps({
        className: clsx(
          'max-w-screen-sm cursor-pointer flex flex-col gap-4 items-start',
          'p-[20px] border-[2px] border-dashed border-gray-500 bg-white text-gray-500 outline-none transition-[border_.24s_ease-in-out]',
          className,
        ),
      })}
    >
      <input {...getInputProps()} />

      <div className=" grid gap-2">
        <div className="flex justify-start gap-4 items-center text-app-primary uppercase">
          {acceptFileTypes?.length > 0 && (
            <div className="hidden md:flex">
              {acceptFileTypes.map((fileType) => (
                <div key={fileType} className="flex gap-1 items-end">
                  <AppDocFileTypeIcon
                    fileType={fileType}
                    className="text-app-primary w-6 h-6"
                  />
                </div>
              ))}
            </div>
          )}
          <div className="flex-grow font-bold text-base md:text-lg">
            {label}
          </div>
        </div>
      </div>

      <p className="text-gray-800">
        {isDragActive
          ? 'Déposez vos fichiers ici...'
          : 'Déposez vos fichiers ici, ou cliquez pour sélectionner les fichiers'}
      </p>
      <div className="text-sm text-gray-600">
        <ul>
          <li className="py-1">
            <b className=" font-bold">Taille maxi</b>: {maxFileSizeLabel}
          </li>
          {showAcceptedExtensions && acceptFileTypes?.length > 0 && (
            <>
              <li className="py-1">
                <b className=" font-bold">Fichiers acceptés</b>:
                <ul className="ml-2 my-2">
                  {acceptFileTypes.map((fileType) => (
                    <li
                      key={fileType}
                      className="py-1 hidden sm:flex gap-1 items-end"
                    >
                      <AppDocFileTypeIcon
                        fileType={fileType}
                        className="text-app-primary w-6 h-6"
                      />
                      <div
                        key={fileType}
                        className="hidden sm:flex gap-0.5 text-sm"
                      >
                        {acceptFileExtensions
                          .filter((ext) =>
                            APP_DOC_UPLOAD_FILE_TYPE_EXTENSIONS_MAP[
                              fileType
                            ].includes(ext),
                          )
                          .map((ext) => (
                            <div key={ext}>{ext}</div>
                          ))}
                      </div>
                    </li>
                  ))}
                </ul>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
}

const getFileType = (filename: string): AppDocFileType | null => {
  const extension = filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
  const foundType: AppDocFileType = Object.entries(
    APP_DOC_UPLOAD_FILE_TYPE_EXTENSIONS_MAP,
  ).find(([type, extensions]) => {
    if (extensions.includes(`.${extension}` as AppDocUploadExtension)) {
      return true;
    }
  })?.[0] as AppDocFileType;
  return foundType;
};
