import clsx from 'clsx';
import { FC, default as React } from 'react';
import { AppPageContentContainer } from '../AppPageContentContainer';
import { AppPageContainer } from './AppPageContainer';
import { AppPageContainerProps } from './AppPageContainerProps.type';

export const AppPageContainerWithFixedBars: FC<
  AppPageContainerProps & {
    headerBar?: () => JSX.Element | JSX.Element[];
    footerBar?: () => JSX.Element | JSX.Element[];
    contentClassName?: string;
    paddingBottom?: boolean;
    marginBottom?: boolean;
  }
> = ({
  headerBar,
  footerBar,
  overflow,
  children,
  contentClassName,
  paddingBottom = true,
  marginBottom = true,
  ...pageContainerProps
}) => {
  return (
    <AppPageContainer className="bg-white relative" {...pageContainerProps}>
      {headerBar && <div className="sticky top-0 z-10">{headerBar()}</div>}

      <AppPageContentContainer
        overflow={overflow}
        className={clsx('h-full relative ', contentClassName)}
        paddingBottom={paddingBottom}
      >
        {children}
        <div className={clsx(marginBottom && 'mb-8')} />
        {/* 'mb-8' pour être sûr que le contenu apparait en bas, sur ipad - TODO mutualiser tout ça proprement dans un composant */}
      </AppPageContentContainer>

      {footerBar && (
        <>
          <div className="flex-grow" />
          <div className="sticky bottom-0 z-10">{footerBar()}</div>
        </>
      )}
    </AppPageContainer>
  );
};
