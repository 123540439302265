import React, { useMemo } from 'react';
import { appWebConfig } from '../../../../../_core/modules/root/config';
import { DiveCenterBookingPageLocalState } from '../../useDiveCenterBookingPageLocalState.hook';
import { DiveCenterBookingPageTab5Inquiries_Member } from './DiveCenterBookingPageTab5Inquiries_Member';
import { cuAppInquiriesByMemberBuilder } from './cuAppInquiriesByMemberBuilder';
const isDev = appWebConfig.envId === 'dev';

export function DiveCenterBookingPageTab5Inquiries({
  localState,
}: {
  localState: DiveCenterBookingPageLocalState;
}) {
  const { aggregatedBookingResume } = localState;
  const {
    booking,
    clubResume,
    diveCenter,
    links,
    checkoutState,
    bookingParticipantsFull,
    bookingMembersFull,
  } = aggregatedBookingResume;

  const clubSettings = clubResume?.clubSettings;
  const allInquiries = clubResume?.inquiries;
  const diveCenterPublicData = diveCenter?.publicData;
  const clubPublicData = clubSettings?.publicData;
  const clubPublicSettings = clubSettings?.publicSettings;
  const clubPublicReference = clubSettings?.clubPublicReference;

  const inquiriesByMember = useMemo(() => {
    return cuAppInquiriesByMemberBuilder.buildInquiriesByMemberModel({
      aggregatedBookingResume,
      clubResume,
    });
  }, [aggregatedBookingResume, clubResume]);

  return (
    <div className="grid gap-4">
      <div className="grid gap-2">
        {inquiriesByMember.map((memberInquiriesDetails) => (
          <DiveCenterBookingPageTab5Inquiries_Member
            className="app-card app-p-content"
            key={memberInquiriesDetails.bookingMemberFull.bookingMember._id}
            memberInquiriesDetails={memberInquiriesDetails}
            localState={localState}
          />
        ))}
      </div>
    </div>
  );
}
