import {
  COUNTRIES_LABELS_BY_CODE,
  PostalAddress,
} from '@mabadive/app-common-model';

export const postalAddressFormatter = {
  format,
};

function format(address: PostalAddress): string {
  if (!address) {
    return '';
  }
  const attributesWithoutCountry = [
    address.line1,
    address.postalCode,
    address.city,
    address.state,
  ].filter((x) => x?.trim()?.length);
  const country: string = (COUNTRIES_LABELS_BY_CODE as any)[
    address.country
  ]?.toUpperCase();
  if (!attributesWithoutCountry.length) {
    if (country) {
      return country;
    }
    return '';
  }
  const label = attributesWithoutCountry.join(' ');
  if (country) {
    return `${label} - ${country}`;
  } else {
    return label;
  }
}
