import { ClubParticipantSpecialDiveType } from '@mabadive/app-common-model';

export const participantSpecialDiveTypeFormatter = {
  formatSpecialDiveType,
};

function formatSpecialDiveType(
  type: ClubParticipantSpecialDiveType,
  {
    format,
  }: {
    format: 'short' | 'long' | 'single-letter';
  },
): string {
  switch (type) {
    case 'rehabilitation':
      return format === 'single-letter'
        ? 'R'
        : format === 'short'
        ? 'Réadaptation'
        : 'Plongée de réadaptation';
    case 'technical':
      return format === 'single-letter'
        ? 'T'
        : format === 'short'
        ? 'Technique'
        : 'Plongée technique';
  }
}
