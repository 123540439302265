/* eslint-disable jsx-a11y/alt-text */
import {
  AppBookletPageGql_Customer,
  ClubPublicSettings,
  EcommerceProductGql_Customer,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { BookletEditorDisplayWidth } from '../../../_model';

export const EcommerceProductPreviewContent = ({
  clubPublicSettings,
  displayWidth,
  product,
  appBookletPage,
  className,
  children,
}: {
  clubPublicSettings: ClubPublicSettings;
  displayWidth: BookletEditorDisplayWidth;
  product: Pick<EcommerceProductGql_Customer, 'name'>;
  appBookletPage: Partial<AppBookletPageGql_Customer>;
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}) => {
  const appUiTheme = clubPublicSettings?.appUiTheme;
  const content = appBookletPage?.mainContent?.content;

  return (
    product &&
    content && (
      <div className={`w-full overflow-y-auto ${className}`}>
        <>
          <div className="flex flex-col gap-8">
            {content.image && (
              <div
              // className="lg:max-w-[45lvw] md:max-h-[35lvw]"
              >
                <img
                  className="w-full rounded-xl"
                  src={content.image.publicUrl}
                />
              </div>
            )}
            <div className="w-full flex-grow">
              <div
                className={clsx(
                  'uppercase font-bold w-full text-left',
                  displayWidth === 'mobile'
                    ? 'text-base'
                    : 'text-lg sm:text-xl md:text-2xl xl:text-3xl',
                )}
                style={{ color: appUiTheme.title1.textColor }}
              >
                {content?.title}
              </div>
              {content?.subTitle && (
                <div
                  className={clsx(
                    'uppercase font-medium w-full text-left',
                    displayWidth === 'mobile'
                      ? 'text-sm'
                      : 'text-sm sm:text-base xl:text-lg 2xl:text-2xl',
                  )}
                  style={{ color: appUiTheme.title2.textColor }}
                >
                  {content?.subTitle}
                </div>
              )}
              {/* {content.image && (
                  <img
                    className="xs:hidden mb-4 relative w-full rounded-xl max-w-[50lvw] max-h-[50lvw]"
                    src={content.image.publicUrl}
                  />
                )} */}
              {children && <div className="my-4">{children}</div>}
            </div>
          </div>
        </>
      </div>
    )
  );
};
