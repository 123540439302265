import {
  ClubParticipant,
  DiveMode,
  DiveSessionGroupDiveMode,
} from '@mabadive/app-common-model';
import { diveSessionGroupDiveModeBuilder } from './diveSessionGroupDiveModeBuilder.service';
import { participantCompatibilityBuilder } from './participantCompatibilityBuilder.service';

export const participantGroupsBuilder = {
  autoGroupParticipants,
};

export type ParticipantWithGroupsDef<T extends Partial<ClubParticipant>> = {
  participant: T;
  compatibilityGroups: string[];
};

export type ParticipantsGroup<T extends Partial<ClubParticipant>> = {
  diveMode: DiveSessionGroupDiveMode;
  participants: T[];
};

export type ParticipantsTmpGroup<T extends Partial<ClubParticipant>> = {
  diveMode: DiveSessionGroupDiveMode;
  compatibilityGroups: string[];
  participantsWithGroupsDef: ParticipantWithGroupsDef<T>[];
};

type StepResult<T extends Partial<ClubParticipant>> = {
  remainingItems: ParticipantWithGroupsDef<T>[];
  groups: ParticipantsTmpGroup<T>[];
  noGroupItems: ParticipantsTmpGroup<T>[];
};

function autoGroupParticipants<
  T extends Pick<
    ClubParticipant,
    'diveMode' | 'targetDeep' | 'trainingReference' | 'certificationReference'
  >,
>(
  participants: T[],
  { clubReference, debugName }: { clubReference: string; debugName?: string },
) {
  const participantsWithCompatibilityGroups = participants
    .filter((participant) =>
      ['first-dive', 'training', 'supervised', 'autonomous'].includes(
        participant.diveMode,
      ),
    )
    .map((participant) => ({
      participant,
      compatibilityGroups:
        participantCompatibilityBuilder.buildCompatibilityGroups(participant, {
          clubReference,
        }),
    }));

  return groupByCompatibility(
    participantsWithCompatibilityGroups,
    debugName,
  ).map((group) => ({
    diveMode: diveSessionGroupDiveModeBuilder.buildFromParticipantsDiveModes({
      diveModes: group.participantsWithGroupsDef.map(
        (p) => p.participant.diveMode,
      ),
      hasDiveGuide: false,
      hasInstructor: false,
      debugContext: 'groupByCompatibility',
    }),
    participants: group.participantsWithGroupsDef.map((p) => p.participant),
  }));
}

function groupByCompatibility<T extends Pick<ClubParticipant, 'diveMode'>>(
  participantWithGroupsDef: ParticipantWithGroupsDef<T>[],
  debugName: string,
): ParticipantsTmpGroup<T>[] {
  const step1Result = step1GroupItemsWithSingleCompatibilityGroup({
    participantsWithGroupsDef: participantWithGroupsDef,
    debugName,
  });

  if (debugName === 'auto-group')
    console.debug('[STEP 1] RESULTS:', step1Result);

  const step2Result = step2FillExistingGroupsWithMultiGroupItems(
    step1Result,
    {
      exactMatchOnly: true,
    },
    debugName,
  );

  const results = step2Result;

  if (debugName === 'auto-group')
    console.debug('[STEP 2] RESULTS:', step2Result);

  while (results.remainingItems.length) {
    const { groups, remainingItems } = step3CreateExtraGroupWithRemainingItems(
      results,
      { exactMatchOnly: true },
      debugName,
    );
    results.groups = groups;
    results.remainingItems = remainingItems;
    if (debugName === 'auto-group')
      console.debug('[STEP 3] ITERATION RESULTS:', results);
  }

  // return results.groups;

  const step4ResultGroups = step4ConcatAutonomousGroupsIfAlone(
    results.groups,
    debugName,
  );

  return step4ResultGroups.concat(step1Result.noGroupItems);
}

function step1GroupItemsWithSingleCompatibilityGroup<
  T extends Pick<ClubParticipant, 'diveMode'>,
>({
  participantsWithGroupsDef,
  debugName,
}: {
  participantsWithGroupsDef: ParticipantWithGroupsDef<T>[];
  debugName: string;
}): StepResult<T> {
  return participantsWithGroupsDef.reduce(
    (acc, participantWithGroupsDef) => {
      const groups = participantWithGroupsDef.compatibilityGroups;
      if (groups.length) {
        if (groups.length === 1) {
          // only one group: add item to first existing group with free space
          const { diveMode } = participantWithGroupsDef.participant;
          const bestGroupSize = getBestGroupSize(diveMode);

          const groupWithFreeSpace = findGroupWithFreeSpace(
            {
              groups: acc.groups,
              compatibilityGroups: participantWithGroupsDef.compatibilityGroups,
              maxGroupSize: bestGroupSize,
              exactMatchOnly: true,
            },
            debugName,
          );

          if (groupWithFreeSpace) {
            // add to group
            groupWithFreeSpace.participantsWithGroupsDef.push(
              participantWithGroupsDef,
            );
          } else {
            // create new group
            acc.groups.push(
              createNewGroup({
                firstParticipantWithGroupsDef: participantWithGroupsDef,
                diveMode,
              }),
            );
          }
        } else {
          // multiple groups: keep it for later
          acc.remainingItems.push(participantWithGroupsDef);
        }
      } else {
        const { diveMode } = participantWithGroupsDef.participant;
        acc.noGroupItems.push(
          createNewGroup({
            diveMode,
            firstParticipantWithGroupsDef: participantWithGroupsDef,
          }),
        );
      }
      return acc;
    },
    {
      noGroupItems: [],
      remainingItems: [],
      groups: [],
    } as {
      noGroupItems: ParticipantsTmpGroup<T>[];
      remainingItems: ParticipantWithGroupsDef<T>[];
      groups: ParticipantsTmpGroup<T>[];
    },
  );
}

function findGroupWithFreeSpace<T extends Partial<ClubParticipant>>(
  {
    groups,
    compatibilityGroups,
    maxGroupSize,
    indexToIgnore,
    exactMatchOnly,
  }: {
    groups: ParticipantsTmpGroup<T>[];
    compatibilityGroups: string[];
    maxGroupSize: number;
    indexToIgnore?: number;
    exactMatchOnly: boolean;
  },
  debugName: string,
): ParticipantsTmpGroup<T> {
  return groups
    .map((group, i) => {
      if (indexToIgnore === undefined || indexToIgnore !== i) {
        if (exactMatchOnly) {
          if (
            compareCompatibilityGroups(
              group.compatibilityGroups,
              compatibilityGroups,
              debugName,
            ) &&
            group.participantsWithGroupsDef.length < maxGroupSize
          ) {
            return group;
          }
        } else {
          const matchingCompatibilityGroups = intersectCompatibilityGroups(
            group.compatibilityGroups,
            compatibilityGroups,
            debugName,
          );
          if (
            matchingCompatibilityGroups.length &&
            group.participantsWithGroupsDef.length < maxGroupSize
          ) {
            return {
              ...group,
              compatibilityGroups: matchingCompatibilityGroups,
            };
          }
        }
      }
      return undefined;
    })
    .find((x) => !!x);
}

function step2FillExistingGroupsWithMultiGroupItems<
  T extends Partial<ClubParticipant>,
>(
  stepResult: StepResult<T>,
  {
    exactMatchOnly,
  }: {
    exactMatchOnly: boolean;
  },
  debugName: string,
): StepResult<T> {
  return stepResult.remainingItems.reduce(
    (acc, participantWithGroupsDef) => {
      const { compatibilityGroups } = participantWithGroupsDef;

      const { diveMode } = participantWithGroupsDef.participant;
      const bestGroupSize = getBestGroupSize(diveMode);

      const groupWithFreeSpace = findGroupWithFreeSpace(
        {
          compatibilityGroups,
          groups: acc.groups,
          maxGroupSize: bestGroupSize,
          exactMatchOnly,
        },
        debugName,
      );

      if (groupWithFreeSpace) {
        // add to group
        groupWithFreeSpace.participantsWithGroupsDef.push(
          participantWithGroupsDef,
        );
      } else {
        acc.remainingItems.push(participantWithGroupsDef);
      }

      return acc;
    },
    {
      remainingItems: [],
      groups: stepResult.groups.concat([]),
      noGroupItems: stepResult.noGroupItems,
    } as {
      remainingItems: ParticipantWithGroupsDef<T>[];
      groups: ParticipantsTmpGroup<T>[];
      noGroupItems: ParticipantsTmpGroup<T>[];
    },
  );
}

function compareCompatibilityGroups(
  compatibilityGroups1: string[],
  compatibilityGroups2: string[],
  debugName: string,
): boolean {
  return (
    compatibilityGroups1.length === compatibilityGroups2.length &&
    compatibilityGroups1.length ===
      intersectCompatibilityGroups(
        compatibilityGroups1,
        compatibilityGroups2,
        debugName,
      ).length
  );
}

function intersectCompatibilityGroups(
  compatibilityGroups1: string[],
  compatibilityGroups2: string[],
  debugName: string,
): string[] {
  const intersect = compatibilityGroups1.filter((g1) =>
    compatibilityGroups2.includes(g1),
  );
  if (debugName === 'auto-group' && !intersect.length) {
    console.debug('!intersect compatibilityGroups1', compatibilityGroups1);
    console.debug('!intersect compatibilityGroups2', compatibilityGroups2);
  }
  return intersect;
}

function getBestGroupSize(diveMode: string) {
  return diveMode === 'autonomous'
    ? 2
    : diveMode === 'supervised' || diveMode === 'training'
    ? 4
    : 1;
}

function getMaxGroupSize(diveMode: string) {
  return diveMode === 'autonomous'
    ? 3
    : diveMode === 'supervised' || diveMode === 'training'
    ? 4
    : 1;
}

function step3CreateExtraGroupWithRemainingItems<
  T extends Partial<ClubParticipant>,
>(
  stepResult: StepResult<T>,
  {
    exactMatchOnly,
  }: {
    exactMatchOnly: boolean;
  },
  debugName: string,
) {
  return stepResult.remainingItems.reduce(
    (acc, participantWithGroupsDef) => {
      // tous les groupes sont pleins, on en créé de nouveaux

      const { diveMode } = participantWithGroupsDef.participant;
      const bestGroupSize = getBestGroupSize(diveMode);

      const { compatibilityGroups } = participantWithGroupsDef;

      const groupWithFreeSpace = findGroupWithFreeSpace(
        {
          compatibilityGroups,
          groups: acc.groups,
          maxGroupSize: bestGroupSize,
          exactMatchOnly,
        },
        debugName,
      );

      if (groupWithFreeSpace) {
        // add to group
        groupWithFreeSpace.participantsWithGroupsDef.push(
          participantWithGroupsDef,
        );
      } else {
        // create new group
        if (!acc.newGroupCreated) {
          // no group create in this iteration for now
          const { diveMode } = participantWithGroupsDef.participant;
          acc.groups.push(
            createNewGroup({
              firstParticipantWithGroupsDef: participantWithGroupsDef,
              diveMode,
            }),
          );
          acc.newGroupCreated = true;
        } else {
          // group already created: wait for next iteration
          acc.remainingItems.push(participantWithGroupsDef);
        }
      }

      return acc;
    },
    {
      newGroupCreated: false,
      groups: stepResult.groups.concat([]),
      remainingItems: [],
    } as {
      newGroupCreated: boolean;
      groups: ParticipantsTmpGroup<T>[];
      remainingItems: ParticipantWithGroupsDef<T>[];
    },
  );
}

function step4ConcatAutonomousGroupsIfAlone<T extends Partial<ClubParticipant>>(
  groups: ParticipantsTmpGroup<T>[],
  debugName: string,
) {
  return groups
    .map((group, i) => {
      if (
        group.diveMode === 'autonomous' &&
        group.participantsWithGroupsDef.length === 1
      ) {
        const participantWithGroupsDef = group.participantsWithGroupsDef[0];
        const { compatibilityGroups } = participantWithGroupsDef;
        const maxGroupSize = getMaxGroupSize(group.diveMode);
        const bestGroupSize = getBestGroupSize(group.diveMode);
        const configurations = [
          {
            groupSize: bestGroupSize,
            exactMatchOnly: true,
          },
          {
            groupSize: bestGroupSize,
            exactMatchOnly: false,
          },
          {
            groupSize: maxGroupSize,
            exactMatchOnly: true,
          },
          {
            groupSize: maxGroupSize,
            exactMatchOnly: false,
          },
        ];

        const groupWithFreeSpace = configurations.reduce(
          (acc, configuration) => {
            if (acc) {
              return acc;
            }
            return findGroupWithFreeSpace(
              {
                compatibilityGroups,
                groups,
                maxGroupSize: configuration.groupSize,
                indexToIgnore: i,
                exactMatchOnly: configuration.exactMatchOnly,
              },
              debugName,
            );
          },
          undefined as ParticipantsTmpGroup<T>,
        );

        if (groupWithFreeSpace) {
          // add participant to existing group
          groupWithFreeSpace.participantsWithGroupsDef.push(
            group.participantsWithGroupsDef[0],
          );
          // remove group
          return undefined;
        }
      }
      return group;
    })
    .filter((group) => !!group);
}

function createNewGroup<T extends Partial<ClubParticipant>>({
  firstParticipantWithGroupsDef,
  diveMode,
}: {
  diveMode: DiveMode;
  firstParticipantWithGroupsDef: ParticipantWithGroupsDef<T>;
}): ParticipantsTmpGroup<T> {
  return {
    diveMode: diveMode as DiveSessionGroupDiveMode,
    compatibilityGroups: firstParticipantWithGroupsDef.compatibilityGroups,
    participantsWithGroupsDef: [firstParticipantWithGroupsDef],
  };
}
