import { ClubPublicData } from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { AppIcons } from 'src/business/_core/modules/layout';

export function DiveCenterBrandSocialIcons({
  publicData,
  className = '',
}: {
  publicData: ClubPublicData;
  className?: string;
}) {
  const iconClassName = `
      rounded w-9 h-9 
      border border-[#1d98c9] 
      fill-white text-[#1c87b1] 
      hover:text-[#1a7498] hover:fill-white 
      hover:border-[#1c87b1] hover:bg-[#b6e4f6]"
  `;

  return !publicData ? null : (
    <div className={clsx('flex gap-1', className)}>
      {publicData?.links?.webSite?.url && (
        <a
          href={publicData?.links?.webSite?.url}
          target="_blank"
          rel="noopener noreferrer"
          className=" underline"
          title="Site Web"
        >
          <AppIcons.socialMedia.website className={iconClassName} />
        </a>
      )}
      {publicData?.links?.facebook?.url && (
        <a
          href={publicData?.links?.facebook?.url}
          target="_blank"
          rel="noopener noreferrer"
          className=" underline"
          title="Facebook"
        >
          <AppIcons.socialMedia.facebook className={iconClassName} />
        </a>
      )}
      {publicData?.links?.instagram?.url && (
        <a
          href={publicData?.links?.instagram?.url}
          target="_blank"
          rel="noopener noreferrer"
          className=" underline"
          title="Instagram"
        >
          <AppIcons.socialMedia.instagram className={iconClassName} />
        </a>
      )}
      {publicData?.links?.tripAdvisor?.url && (
        <a
          href={publicData?.links?.tripAdvisor?.url}
          target="_blank"
          rel="noopener noreferrer"
          className=" underline"
          title="Trip Advisor"
        >
          <AppIcons.socialMedia.tripAdvisor className={iconClassName} />
        </a>
      )}
      {publicData?.links?.youtube?.url && (
        <a
          href={publicData?.links?.youtube?.url}
          target="_blank"
          rel="noopener noreferrer"
          className=" underline"
          title="Youtube"
        >
          <AppIcons.socialMedia.youtube className={iconClassName} />
        </a>
      )}
    </div>
  );
}
