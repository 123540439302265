"use strict";
exports.__esModule = true;
exports.CLUB_DEFAULT_DIVE_SITE_GROUPS = void 0;
exports.CLUB_DEFAULT_DIVE_SITE_GROUPS = [
    {
        name: 'Plongées fréquentes',
        sites: [
            {
                name: 'Jardin de corail'
            },
            {
                name: 'Plage'
            },
        ]
    },
    {
        name: 'Plongées spéciales',
        sites: [
            {
                name: 'Dérivante'
            },
            {
                name: 'Epave'
            },
        ]
    },
];
