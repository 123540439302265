import { ClubResumeGql_Public } from '@mabadive/app-common-model';
import { MQueryDescription } from 'src/_common-browser';
import { DiveCenterCriteria } from './model';

export function diveCenterGraphqlQuery(
  criteria: DiveCenterCriteria,
): MQueryDescription<ClubResumeGql_Public> {
  const bookingFilters: string[] = [];
  bookingFilters.push(
    `clubSettings: {clubPublicReference: {_eq: "${criteria.clubPublicReference}"}}`,
  );
  const where = `{${bookingFilters.join(',')}}`;

  const queryDescription: MQueryDescription<ClubResumeGql_Public> = {
    returnName: 'diveCenter',
    queryName: 'club',
    returnType: 'first',
    where,
    returnAttributes: ClubResumeGql_Public,
  };

  return queryDescription;
}
