import React, { useMemo } from 'react';
import { AppHackableCalendarVM } from '../model';
import { AppHackableCalendarWeeksPanelVMDay } from './model';

import clsx from 'clsx';

export type AppHackableCalendarWeeksPanelClasses = {
  dateBorderColor: string;
  dateBgColor: string;
  cellBgColor: string;
  fontWeight: string;
  textColor: string;
};
export type AppHackableCalendarWeeksPanelDayBaseAttributes = {
  isInPeriodRange: boolean;
  classes: AppHackableCalendarWeeksPanelClasses;
};
export type AppHackableCalendarWeeksPanelDayAttributes = {
  isSelectable: boolean;
  isVisible: boolean;
  classes: AppHackableCalendarWeeksPanelClasses;
};

export type AppHackableCalendarWeeksPanelDayBuildAttributesFn = (
  day: AppHackableCalendarWeeksPanelVMDay,
  attrs: AppHackableCalendarWeeksPanelDayBaseAttributes,
) => AppHackableCalendarWeeksPanelDayAttributes;

const customizeDayDefault: AppHackableCalendarWeeksPanelDayBuildAttributesFn = (
  day: AppHackableCalendarWeeksPanelVMDay,
  attrs: AppHackableCalendarWeeksPanelDayBaseAttributes,
) => {
  const attributes: AppHackableCalendarWeeksPanelDayAttributes = {
    isSelectable: attrs.isInPeriodRange,
    isVisible: true,
    classes: attrs.classes,
  };
  return attributes;
};
export const AppHackableCalendarWeeksPanelDay = ({
  vm,
  day,
  dayIndex,
  daysCount,
  customizeDay: customizeDayInput,
}: {
  vm: AppHackableCalendarVM;
  day: AppHackableCalendarWeeksPanelVMDay;
  dayIndex: number;
  daysCount: number;
  customizeDay?: AppHackableCalendarWeeksPanelDayBuildAttributesFn;
}) => {
  const customizeDay: AppHackableCalendarWeeksPanelDayBuildAttributesFn =
    customizeDayInput ?? customizeDayDefault;

  const dayCorner = useMemo(() => {
    return dayIndex === 0
      ? 'top-left'
      : dayIndex === 6
      ? 'top-right'
      : dayIndex === daysCount - 7
      ? 'bottom-left'
      : dayIndex === daysCount - 1
      ? 'bottom-right'
      : undefined;
  }, [dayIndex, daysCount]);

  const dayCornerClass = useMemo(() => {
    switch (dayCorner) {
      case 'top-left':
        return 'rounded-tl-lg';
      case 'top-right':
        return 'rounded-tr-lg';
      case 'bottom-left':
        return 'rounded-bl-lg';
      case 'bottom-right':
        return 'rounded-br-lg';
    }
  }, [dayCorner]);

  const baseAttributes = useMemo(() => {
    const isInPeriodRange = buildIsInPeriodRange({ day, vm });
    const bgColor =
      day.isCurrentViewMonth && isInPeriodRange
        ? 'bg-white hover:bg-gray-200'
        : 'bg-gray-50';

    const fontWeight =
      day.isSelected || day.isToday || day.isCurrentViewMonth
        ? 'font-semibold'
        : undefined;

    const textColor = buildTextColor({ day, isInPeriodRange });

    const dateBgColor = day.isSelected && day.isToday && 'bg-sky-500';
    const dateBorderColor =
      day.isSelected && !day.isToday ? 'border-sky-500' : 'border-transparent';

    const classes: AppHackableCalendarWeeksPanelClasses = {
      cellBgColor: bgColor,
      fontWeight,
      textColor,
      dateBgColor,
      dateBorderColor,
    };

    const baseAttributes: AppHackableCalendarWeeksPanelDayBaseAttributes = {
      isInPeriodRange,
      classes,
    };
    return baseAttributes;
  }, [day, vm]);

  const { isSelectable, isVisible, classes } = useMemo(
    () => customizeDay(day, baseAttributes),
    [baseAttributes, customizeDay, day],
  );

  return (
    <button
      disabled={!isSelectable}
      key={day.date.getTime()}
      type="button"
      className={clsx(
        !isVisible && 'invisible',
        'group py-0.5 focus:z-10',
        isSelectable ? 'cursor-pointer' : 'cursor-not-allowed',
        dayCornerClass,
        classes.cellBgColor,
        classes.fontWeight,
        classes.textColor,
      )}
      onClick={() => {
        if (isSelectable) {
          vm.props.onSelectDate && vm.props.onSelectDate(day.date);
        }
      }}
    >
      <time
        dateTime={day.dateIsoString}
        className={clsx(
          'mx-auto flex h-8 w-8 items-center justify-center rounded-full border-2',
          classes.dateBgColor,
          classes.dateBorderColor,
        )}
      >
        {day.date.getDate()}
      </time>
    </button>
  );
};

function buildTextColor({
  day,
  isInPeriodRange,
}: {
  day: AppHackableCalendarWeeksPanelVMDay;
  isInPeriodRange: boolean;
}) {
  if (day.isSelected) {
    return day.isToday ? 'text-white' : 'text-gray-500';
  }
  if (day.isCurrentViewMonth) {
    if (day.isToday && !day.isSelected) {
      return 'text-sky-500';
    } else {
      return isInPeriodRange ? 'text-gray-500' : 'text-gray-400';
    }
  } else {
    return 'text-gray-400';
  }
}

function buildIsInPeriodRange({
  day,
  vm,
}: {
  day: AppHackableCalendarWeeksPanelVMDay;
  vm: AppHackableCalendarVM;
}) {
  if (vm.minDate && vm.minDate.getTime() > day.date.getTime()) {
    return false;
  }
  if (vm.maxDate && vm.maxDate.getTime() < day.date.getTime()) {
    return false;
  }
  return true;
}
