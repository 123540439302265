import {
  AppRichTextElementTextAlign,
  AppUiTheme,
} from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import { appRichTextRenderer } from '../services';

export function AppRichTextParagraph<A>({
  attributes = {} as any,
  textAlign,
  theme,
  children,
}: {
  attributes?: A;
  textAlign?: AppRichTextElementTextAlign;
  theme: AppUiTheme;
  children: any;
}) {
  const style: React.CSSProperties = useMemo(
    () => ({ color: theme.text.textColor }),
    [theme.text.textColor],
  );

  const className = useMemo(() => {
    return [appRichTextRenderer.getAlignmentClass(textAlign)]
      .filter((x) => !!x)
      .join(' ');
  }, [textAlign]);

  return (
    <p className={`mt-0.5 ${className}`} {...attributes} style={style}>
      {children}
    </p>
  );
}
