import { apiClient } from './apiClient.service';
export const fileApiClient = {
  uploadFile,
};

async function uploadFile<T>(
  file: File,
  {
    url,
    formFieldName = 'file',
    json,
  }: {
    url: string;
    formFieldName?: string;
    json?: any;
  },
): Promise<T> {
  const formData: FormData = new FormData();
  formData.append(formFieldName, file, file.name);
  if (json) {
    formData.append('__data__', JSON.stringify(json));
  }
  return apiClient
    .post<T>(url, {
      body: formData,
      authenticate: true,
      maxTries: 1,
      timeout: 120000,
    })
    .toPromise();
}
