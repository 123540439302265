import { APP_COLORS } from '@mabadive/app-common-model';
import { Theme } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/styles/withStyles';
import { FontWeightProperty } from 'csstype';
import { AppTheme } from '../AppTheme.type';
import { buildThemeDefaultClasses } from '../buildThemeDefaultClasses.service';

export function buildGlobalClassesTableStyles(
  theme: Theme,
): CreateCSSProperties<{}> {
  const themeDefault = buildThemeDefaultClasses(theme);

  return {
    width: '100%',
    tableLayout: 'fixed',
    '&.not-full-width': {
      width: 'auto',
    },
    '& .MuiTableCell-root': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon':
      {
        color: APP_COLORS.red,
      },
    ...buildThemeClasses(theme, themeDefault),
  };
}

export function buildThemeClasses(
  theme: Theme,
  appTheme: AppTheme,
): CreateCSSProperties<{}> {
  return {
    '& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon':
      {
        color: APP_COLORS.red,
      },
    '& tbody .MuiTableRow-root': {
      backgroundColor: appTheme.base.bg.main,
      // '&:nth-of-type(odd)': {
      //   backgroundColor: appTheme.base.bg.alt,
      // },
      '& .MuiTableCell-root': {
        '&.MuiTableCell-body': {
          color: appTheme.base.text.main,
        },
      },
      // '&.time-tag-past': {
      //   '& .MuiTableCell-root': {
      //     '&.MuiTableCell-body': {
      //       color: darken(appTheme.base.text.main, 0.5),
      //     },
      //   },
      // },
      '&.time-tag-future': {
        '& .MuiTableCell-root': {
          '&.MuiTableCell-body': {
            color: lighten(appTheme.base.text.main, 0.3),
          },
        },
      },
      '&.time-tag-today': {
        '& .MuiTableCell-root': {
          '&.MuiTableCell-body': {
            color: APP_COLORS.orange,
          },
        },
      },
      '&.highlighted': {
        fontWeight: 'bold' as FontWeightProperty,
        '& .MuiTableCell-root': {
          fontWeight: 'bold' as FontWeightProperty,
        },
        backgroundColor: appTheme.highlighted.bg.main,
        // '&:nth-of-type(odd)': {
        //   backgroundColor: appTheme.highlighted.bg.alt,
        // },
        '&.time-tag-today': {
          backgroundColor: lighten(APP_COLORS.orange, 0.5),
          '& .MuiTableCell-root': {
            '&.MuiTableCell-body': {
              color: APP_COLORS.white,
            },
          },
        },
      },
    },
    '& tbody.clickable .MuiTableRow-root, & tbody .MuiTableRow-root.clickable':
      {
        '&:hover': {
          cursor: 'pointer',
        },
      },
    '& tbody.hoverable .MuiTableRow-root, & tbody .MuiTableRow-root.hoverable':
      {
        '&:hover': {
          backgroundColor: appTheme.hover.bg.main,
          // '&:nth-of-type(odd)': {
          //   backgroundColor: appTheme.hover.bg.alt,
          // },
          '& .MuiTableCell-root': {
            '&.MuiTableCell-body': {
              color: APP_COLORS.white,
            },
          },
          '&.time-tag-today': {
            backgroundColor: appTheme.hover.bg.main,
            // '&:nth-of-type(odd)': {
            //   backgroundColor: appTheme.hover.bg.alt,
            // },
          },
        },
      },
  };
}
