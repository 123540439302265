import {
  AppCurrency,
  ClubPurchasePaymentPackage,
  Customer_ClubSettingsPublicCustomer,
} from '@mabadive/app-common-model';
import { productPackageFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React from 'react';
import { AppPriceLabelRatio } from 'src/business/club/modules/_common/ui';

export const BookingPaymentCardProduct = ({
  clubSettings,
  purchasePaymentPackage,
  paymentCurrency,
  className,
}: {
  clubSettings: Customer_ClubSettingsPublicCustomer;
  purchasePaymentPackage: ClubPurchasePaymentPackage;
  paymentCurrency: AppCurrency;
  className?: string;
}) => {
  return (
    <li className={clsx('flex py-6', className)}>
      <div className="flex-shrink-0">
        <img
          alt="Product illustration"
          src={
            'https://mabadive.fra1.cdn.digitaloceanspaces.com/images%2Fproducts%2Fscuba-diving-300x300.png'
          }
          className="grayscale brightness-125 w-20 bg-gray-400 rounded-md"
        />
      </div>

      <div className="ml-6 flex flex-1 flex-col">
        <div className="flex">
          <div className="min-w-0 flex-1">
            <h4 className="text-base">
              <span className="font-bold text-gray-600">
                {productPackageFormatter.formatDescription(
                  purchasePaymentPackage.purchaseProductPackage,
                  {
                    diveModesConf: null, // pas encore supporté côté client
                  },
                )}
              </span>
            </h4>
          </div>
        </div>

        <div className="flex flex-1 items-end justify-between pt-2">
          <div className="mt-1 text-sm font-medium text-gray-900">
            <AppPriceLabelRatio
              className="text-gray-600"
              amount={purchasePaymentPackage.amount}
              total={purchasePaymentPackage.purchasePackage.price}
              mainCurrency={paymentCurrency}
              hideTotalIfSameAmount={true}
            />
          </div>
        </div>
      </div>
    </li>
  );
};
