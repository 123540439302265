"use strict";
exports.__esModule = true;
exports.DEFAULT_EQUIPMENT_SETTINGS_WEIGHTING = void 0;
var DEFAULT_SIZES_CLUB = [
    '0.0 Kg',
    '0.5 Kg',
    '1.0 Kg',
    '1.5 Kg',
    '2.0 Kg',
    '2.5 Kg',
    '3.0 Kg',
    '3.5 Kg',
    '4.0 Kg',
    '4.5 Kg',
    '5.0 Kg',
    '5.5 Kg',
    '6.0 Kg',
    '6.5 Kg',
    '7.0 Kg',
    '7.5 Kg',
    '8.0 Kg',
    '8.5 Kg',
    '9.0 Kg',
    '9.5 Kg',
    '10.0 Kg',
    '10.5 Kg',
    '11.0 Kg',
    '11.5 Kg',
    '12.0 Kg',
];
exports.DEFAULT_EQUIPMENT_SETTINGS_WEIGHTING = {
    type: 'weighting',
    enabled: false,
    club: {
        enabled: true,
        models: DEFAULT_SIZES_CLUB.map(function (ref) { return ({
            ref: ref
        }); }),
        visibleOnSession: true
    },
    self: {
        autoSetOnGlobal: true,
        enabled: false,
        models: [],
        visibleOnSession: true
    }
};
