import { StackLogGlobalHistoryService, stackLogLevelTester, StackLogProcessHistoryService } from '@mabadive/stack-log-core';
import { stackLogGlobalConfigHolder } from '../../main/models/stack-log-global-config-holder.service';
import { StackLogWebAppenderOptions } from '../models/stack-log-web-appender-options.model';
import { stackLogWebHistoryBuilder } from './stack-log-web-history-builder.service';
import { StackLogWebLoggerOptions } from './stack-log-web-logger-options';



export const stackLogGlobalHistoryService = new StackLogGlobalHistoryService<StackLogWebAppenderOptions>();
export const stackLogProcessHistoryService = new StackLogProcessHistoryService<StackLogWebAppenderOptions>();
class StackLogWebLoggerDispatcher {

  private eventId = 1;

  private getUtcDate(date: Date): Date {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  }

  public dispatchLog(options: StackLogWebLoggerOptions) {

    const config = stackLogGlobalConfigHolder.getGlobalConfig();

    if (stackLogLevelTester.isEnabled(options, config.global)) {

      if (config.appenders && config.appenders.length) {

        const timestamp = this.getUtcDate(new Date());

        // get process history
        const processLogsHistory = stackLogProcessHistoryService.getHistory(options, timestamp);
        // get global history
        const globalLogsHistory = stackLogGlobalHistoryService.getHistory();

        const logs = stackLogWebHistoryBuilder.buildLogHistory({
          processLogsHistory,
          globalLogsHistory,
          innerConfig: config,
          loggerOptions: options
        });

        const logOptions: StackLogWebAppenderOptions = {
          message: options.message,
          level: options.level,
          params: options.params,
          timestamp,
          context: options.context,
          id: this.eventId++,
          type: options.context.type,
        };

        // call all appenders
        config.appenders.forEach(appender => appender.log(logOptions, {
          ...processLogsHistory,
          logs,
        }));

        // add to process history (if processId is defined)
        stackLogProcessHistoryService.appendToHistory(processLogsHistory, logOptions, config.global.maxStackSize);
        // add to global history (we keep the double of max stack size as it can be filtered)
        stackLogGlobalHistoryService.appendToHistory(logOptions, config.global.maxStackSize * 2);
      }

    }
  }
}

export const stackLogWebLoggerDispatcher = new StackLogWebLoggerDispatcher();