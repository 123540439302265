import React from 'react';
import { BookingStatusLabel } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/components/BookingLabelsComponents';
import { DiveCenterBookingPageAggregatedBookingResume } from '../../model';
import { DiveCenterBookingPageTab1BookingResumeDates } from './DiveCenterBookingPageTab1BookingResumeDates';

export function DiveCenterBookingPageTab1BookingResumeCard({
  aggregatedBookingResume,
  children,
}: {
  aggregatedBookingResume: DiveCenterBookingPageAggregatedBookingResume;
  children?: React.ReactNode | React.ReactNode[];
}) {
  const { booking, diveCenter, clubResume } = aggregatedBookingResume;

  const diveCenterPublicData = diveCenter?.publicData;
  const clubPublicSettings = clubResume?.clubSettings?.publicSettings;

  return (
    <div className="app-card app-p-content md:w-3/4 lg:w-1/2 md:flex-grow flex flex-col md:justify-between">
      <div>
        <div className="flex gap-2 justify-between items-start">
          <div className="uppercase font-bold text-base text-gray-800">
            <span className="whitespace-nowrap">Votre réservation à</span>{' '}
            <span className="text-app-primary whitespace-nowrap">
              {diveCenterPublicData?.brand?.name}
            </span>
          </div>
          <BookingStatusLabel booking={booking} />
        </div>

        <div className="mt-4 text-gray-600 grid gap-2 text-sm md:text-base">
          <DiveCenterBookingPageTab1BookingResumeDates
            aggregatedBookingResume={aggregatedBookingResume}
          />
        </div>
      </div>
      {children && children}
    </div>
  );
}
