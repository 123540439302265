import {
  DIVE_TRAININGS_REFERENCE_MAP,
  DiveServiceTraining,
  DiveTraining,
  DiveTrainingReference,
} from '@mabadive/app-common-model';

export type DiveServiceTrainingStringFormat =
  | 'xs'
  | 'short'
  | 'long'
  | 'long-no-prefix'
  | 'certification-like'
  | 'label';

export type TrainingStringFormat = 'ref' | 'name' | 'ref-name';

export const diveServiceTrainingFormatter = {
  formatDiveServiceTraining,
  formatDiveServiceTrainingCertificationLike,
  formatTrainingLabel,
  formatTraining,
};

function formatTraining(
  training: DiveTraining,
  {
    format,
  }: {
    format: TrainingStringFormat;
  },
): string {
  if (!training) {
    return '';
  }

  if (format === 'ref') {
    return training.reference;
  }
  if (format === 'name') {
    return training.label;
  }
  if (format === 'ref-name') {
    return `${training.reference} - ${training.label}`;
  }
}
function formatDiveServiceTraining(
  training: DiveServiceTraining,
  { format }: { format: DiveServiceTrainingStringFormat },
) {
  if (format === 'long') {
    return `${training?.label} ${training.organizationReference}`;
  }
  if (format === 'xs' || format === 'short') {
    return training.diveTrainingReference;
  }
  return training.label;
}

function formatDiveServiceTrainingCertificationLike(
  diveTrainingReference: DiveTrainingReference,
) {
  return `${diveTrainingReference} - ${formatTrainingLabel(
    diveTrainingReference,
  )}`;
}

function formatTrainingLabel(diveTrainingReference: DiveTrainingReference) {
  const training = DIVE_TRAININGS_REFERENCE_MAP[diveTrainingReference];

  return training?.label ?? diveTrainingReference ?? 'Formation';
}
