import {
  ClubDiver,
  ClubParticipant,
  DiveSession,
} from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import {
  ColoredTagCardBar,
  participantTagsBuilder,
} from 'src/business/club/modules/club-planning/components/ParticipantTags';
import { useClubResumePublic } from 'src/business/_core/store';

export function ParticipantDiveTags({
  participant,
  diver,
  diveSession,
  includeComment = true,
  includeBookingState = true,
  className,
  onClick,
}: {
  participant: Pick<ClubParticipant, 'tags' | 'comment' | 'bookingState'>;
  diver: Pick<ClubDiver, 'birthdate'>;
  diveSession: Pick<DiveSession, 'time'>;

  includeComment?: boolean;
  includeBookingState?: boolean;
  className?: string;
  onClick?: () => void;
}) {
  const clubResume = useClubResumePublic();

  const tags = useMemo(
    () =>
      !participant
        ? []
        : participantTagsBuilder.getParticipantTags({
            club: clubResume?.club,
            participant,
            diver,
            diveSession,
            includeComment,
            includeBookingState,
          }),
    [
      clubResume,
      diveSession,
      diver,
      includeBookingState,
      includeComment,
      participant,
    ],
  );

  return tags.length !== 0 ? (
    <div
      className={`flex justify-center ${onClick ? 'cursor-pointer' : ''} ${
        className ?? ''
      }`}
      onClick={onClick}
    >
      <ColoredTagCardBar tags={tags} avatarSize="small" avatarOnly={true} />
    </div>
  ) : null;
}
