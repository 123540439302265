import { DiveMode } from '@mabadive/app-common-model';
import React, { CSSProperties, FC } from 'react';

export const DiveModeBadge: FC<{
  children?: React.ReactNode | React.ReactNode[];
  diveMode: DiveMode;
  onClick?: () => void;
  className?: string;
  style?: CSSProperties;
}> = ({ diveMode, className, style, onClick, children }) => {
  return (
    <div
      style={style}
      className={`text-center px-0.5 py-0.5 rounded text-white 
      bg-dive-mode-${diveMode} 
      ${className ?? ''}
    `}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
