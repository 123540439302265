import React from 'react';

export const AppLoaderSpinner = ({
  className = '',
}: {
  className?: string;
}) => {
  return (
    <div
      style={{ borderTopColor: 'transparent' }}
      className={`border-4 border-blue-400 border-solid rounded-full animate-spin ${className}`}
    ></div>
  );
};
