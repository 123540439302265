import { JwtTokenClaims } from '@mabadive/app-common-model';
import { appRolesBuilder } from '@mabadive/app-common-services';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { AppSecurityUser } from './model';

export const tokenParser = {
  parse,
};

function parse(token: string): AppSecurityUser {
  if (token) {
    const claims = getTokenClaims(token);
    appLogger.info('[tokenParser] claims: ', claims);

    const roles = appRolesBuilder.getAppRoles(claims.authenticatedUser);
    const authUser: AppSecurityUser = {
      ...claims.authenticatedUser,
      roles,
      tokenInfo: {
        version: claims.version,
        issueDate: new Date(1000 * claims.iat),
        initialIssueDate: new Date(
          claims.iiat ? 1000 * claims.iiat : undefined,
        ),
        expirationDate: new Date(1000 * claims.exp),
      },
    };

    return authUser;
  }
}

function getTokenClaims(token: string): JwtTokenClaims {
  if (!token || !token.split) {
    appLogger.warn('[getTokenClaims] Invalid token: ', token);
    throw new Error('Invalid token');
  }

  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  const claims: JwtTokenClaims = JSON.parse(window.atob(base64));
  return claims;
}
