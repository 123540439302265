import { BaseEntity } from '@mabadive/app-common-model';
import { uuidGenerator } from './uuidGenerator.service';

export const commonEntityBuilder = {
  buildEntity,
};

function buildEntity<T extends BaseEntity>(item: Partial<T>, now = new Date()) {
  const newItem = {
    ...item,
    _id: item._id ?? uuidGenerator.random(),
    _createdAt: item._createdAt ?? now,
    _updatedAt: now,
    _version: (item._version ?? 0) + 1,
  } as T;
  return newItem;
}
