import {
  AppEntityUpdateDescriptor,
  AppEntityUpdatePatch,
} from '@mabadive/app-common-model';

export const changeDescriptorManager = {
  getChangeDescriptor,
  createOne,
  createMany,
  addOneOriginal,
  addManyOriginals,
  updateOneOnSingleDescriptor,
  updateOne,
  updateMany,
  deleteOne,
  deleteMany,
  deleteOneSingle,
};

function getChangeDescriptor<T>(
  changeDescriptors: AppEntityUpdateDescriptor<T>[] = [],
  pk: string,
): {
  updatedChangeDescriptors: AppEntityUpdateDescriptor<T>[];
  changeDescriptor: AppEntityUpdateDescriptor<T>;
} {
  const updatedChangeDescriptors = [...changeDescriptors];
  let changeDescriptor = updatedChangeDescriptors.find((x) => x.pk === pk);
  if (!changeDescriptor) {
    changeDescriptor = {
      pk,
    };
    updatedChangeDescriptors.push(changeDescriptor);
    return { updatedChangeDescriptors, changeDescriptor };
  } else {
    return {
      updatedChangeDescriptors,
      changeDescriptor,
    };
  }
}

function createOne<T extends { _id?: string }>(
  item: T,
  options: {
    changeDescriptors: AppEntityUpdateDescriptor<T>[];
    getPk?: (item: T) => string;
  },
): AppEntityUpdateDescriptor<T>[] {
  return createMany([item], options);
}

function createMany<T extends { _id?: string }>(
  items: T[],
  {
    changeDescriptors,
    getPk = (item) => item._id,
  }: {
    changeDescriptors: AppEntityUpdateDescriptor<T>[];
    getPk?: (item: T) => string;
  },
): AppEntityUpdateDescriptor<T>[] {
  for (const item of items) {
    const pk = getPk(item);
    const { changeDescriptor, updatedChangeDescriptors } = getChangeDescriptor(
      changeDescriptors,
      pk,
    );
    changeDescriptors = updatedChangeDescriptors;
    changeDescriptor.created = item;
  }
  return changeDescriptors;
}

function addOneOriginal<T extends { _id?: string }>(
  item: T,
  options: {
    changeDescriptors: AppEntityUpdateDescriptor<T>[];
    getPk?: (item: T) => string;
  },
): AppEntityUpdateDescriptor<T>[] {
  return addManyOriginals([item], options);
}

function addManyOriginals<T extends { _id?: string }>(
  items: T[],
  {
    changeDescriptors,
    getPk = (item) => item._id,
  }: {
    changeDescriptors: AppEntityUpdateDescriptor<T>[];
    getPk?: (item: T) => string;
  },
): AppEntityUpdateDescriptor<T>[] {
  for (const item of items) {
    const pk = getPk(item);
    const { changeDescriptor, updatedChangeDescriptors } = getChangeDescriptor(
      changeDescriptors,
      pk,
    );
    changeDescriptors = updatedChangeDescriptors;
    changeDescriptor.original = item;
  }
  return changeDescriptors;
}

function updateOneOnSingleDescriptor<T>(
  update: AppEntityUpdatePatch,
  options: {
    changeDescriptor: AppEntityUpdateDescriptor<T>;
  },
): AppEntityUpdateDescriptor<T> {
  return updateMany([update], {
    changeDescriptors: [options.changeDescriptor],
  })[0];
}

function updateOne<T>(
  update: AppEntityUpdatePatch,
  options: {
    changeDescriptors: AppEntityUpdateDescriptor<T>[];
    optimizePatches?: boolean;
  },
): AppEntityUpdateDescriptor<T>[] {
  return updateMany([update], options);
}

function updateMany<T>(
  updates: AppEntityUpdatePatch[],
  {
    changeDescriptors,
    optimizePatches,
  }: {
    changeDescriptors: AppEntityUpdateDescriptor<T>[];
    optimizePatches?: boolean;
  },
): AppEntityUpdateDescriptor<T>[] {
  for (const update of updates) {
    const pk = update.pk;
    const { changeDescriptor, updatedChangeDescriptors } = getChangeDescriptor(
      changeDescriptors,
      pk,
    );
    changeDescriptors = updatedChangeDescriptors;
    if (changeDescriptor.updated) {
      // merge with previous updates
      const patchOperations = changeDescriptor.updated.patchOperations.concat(
        update.patchOperations,
      );
      if (optimizePatches) {
        // NOTE: on ne peut pas simplement faire "jsonPatcher.applyPatchOperations" + re-créer les patchs,
        // car il faut gérer les comportements spéciaux de jsonPatcher.compareObjects(): replaceDeleteByNullValue, attributesToIgnore, attributesToReplaceFully
        // NOTE 2: en plus, cette méthode est plus simple et plus rapide
        const filteredPatchOperations = patchOperations.filter((x, i) => {
          const laterReplacePatchIndex = patchOperations.findIndex(
            (x2, i2) =>
              i2 > i &&
              x.op === 'replace' &&
              x.op === x2.op &&
              x.path === x2.path,
          );
          return laterReplacePatchIndex === -1;
        });
        changeDescriptor.updated = {
          ...changeDescriptor.updated,
          patchOperations: filteredPatchOperations,
        };
      } else {
        changeDescriptor.updated = {
          ...changeDescriptor.updated,
          patchOperations,
        };
      }
    } else {
      changeDescriptor.updated = update;
    }
  }
  return changeDescriptors;
}

function deleteOne<T>(
  pk: string,
  {
    changeDescriptors,
  }: {
    changeDescriptors: AppEntityUpdateDescriptor<T>[];
  },
): AppEntityUpdateDescriptor<T>[] {
  const { changeDescriptor, updatedChangeDescriptors } = getChangeDescriptor(
    changeDescriptors,
    pk,
  );
  changeDescriptors = updatedChangeDescriptors;
  if (changeDescriptor.created) {
    // remove changeDescriptor (= don't create new item)
    changeDescriptors = changeDescriptors.filter((x) => x.pk !== pk);
  } else {
    changeDescriptor.updated = undefined; // cancel updates
    changeDescriptor.deleted = true;
  }
  return changeDescriptors;
}
function deleteMany<T>(
  pks: string[],
  {
    changeDescriptors: changeDescriptorsLocal,
  }: {
    changeDescriptors: AppEntityUpdateDescriptor<T>[];
  },
): AppEntityUpdateDescriptor<T>[] {
  if (pks.length) {
    // remove duplicated from pks
    pks = [...new Set(pks)];
    for (const pk of pks) {
      const { changeDescriptor, updatedChangeDescriptors } =
        getChangeDescriptor(changeDescriptorsLocal, pk);
      // on met à jour la variable locale avec l'objet à jour
      changeDescriptorsLocal = updatedChangeDescriptors;

      if (changeDescriptor.created) {
        // remove changeDescriptor (= don't create new item)
        changeDescriptorsLocal = changeDescriptorsLocal.filter(
          (x) => x.pk !== pk,
        );
      } else {
        changeDescriptor.updated = undefined; // cancel updates
        changeDescriptor.deleted = true;
      }
    }
  }
  return changeDescriptorsLocal;
}

function deleteOneSingle<T>(
  pk: string,
  {
    changeDescriptor,
  }: {
    changeDescriptor: AppEntityUpdateDescriptor<T>;
  },
): AppEntityUpdateDescriptor<T> {
  if (changeDescriptor.created) {
    // remove changeDescriptor (= don't create new item)
    return undefined;
  } else {
    changeDescriptor.updated = undefined; // cancel updates
    changeDescriptor.deleted = true;
  }
  return changeDescriptor;
}
