"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.DEFAULT_CLUB_SETTINGS_UI_PLANNING_NEW_ACCOUNT = exports.DEFAULT_CLUB_SETTINGS_UI_PLANNING = void 0;
exports.DEFAULT_CLUB_SETTINGS_UI_PLANNING = {
    participantFirstNameBefore: false,
    showInactiveParticipants: true,
    showPaymentFlag: false,
    showPaymentFlagPastOnly: false,
    showPaymentFlagNotPayed: false,
    showPaymentFlagNotPurchased: false,
    showPaymentFlagNotPurchasedPass: false,
    showPaymentFlagNotPurchasedPassMode: 'consumed',
    showPaymentFlagTraining: true,
    showPaymentFlagPayed: false,
    showInactiveSessions: true,
    showStaff: true,
    showStaffCounts: true,
    showStaffCountsPerDay: true,
    showStaffCountsPerDayDP: true,
    showStaffCountsPerDayGP: true,
    showStaffCountsPerDaySS: false,
    staffFirstNameBefore: false,
    displayGroupLevelDetails: false,
    displayPastSessions: false,
    displayEmptySessionsPast: false,
    displayEmptySessionsFuture: true,
    staffPeriods: {
        am: {
            start: {
                hours: 0,
                minutes: 0
            },
            end: {
                hours: 11,
                minutes: 59
            }
        },
        pm: {
            start: {
                hours: 12,
                minutes: 0
            },
            end: {
                hours: 23,
                minutes: 59
            }
        }
    }
};
exports.DEFAULT_CLUB_SETTINGS_UI_PLANNING_NEW_ACCOUNT = __assign(__assign({}, exports.DEFAULT_CLUB_SETTINGS_UI_PLANNING), { showPaymentFlag: true, showPaymentFlagPastOnly: false, showPaymentFlagNotPayed: true, showPaymentFlagNotPurchased: true, showPaymentFlagNotPurchasedPass: true, showPaymentFlagTraining: true, showPaymentFlagPayed: true });
