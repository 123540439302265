import { ClubSettingsGeneralOnlineBookingSource } from '@mabadive/app-common-model';

export const onlineBookingSourceFormatter = {
  formatSource,
};

function formatSource(source: ClubSettingsGeneralOnlineBookingSource) {
  switch (source) {
    case 'website':
      return 'Site Internet';
    case 'facebook':
      return 'Facebook';
    case 'instagram':
      return 'Instagram';
    case 'tripadvisor':
      return 'Tripadvisor';
    case 'google':
      return 'Google';
    case 'yelp':
      return 'Yelp';
    case 'mail':
      return 'Email';
    case 'whatsapp':
      return 'Whatsapp';
    case 'partner':
      return 'Partenaire';
    case 'blog':
      return 'Blog';
    case 'other':
      return 'Autre';
    default:
      return source;
  }
}
