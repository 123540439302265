import { split } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import { ServerParseError } from 'apollo-link-http-common';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { OperationDefinitionNode } from 'graphql';
import { libLogger } from '../../libLogger.service';
import { graphqlClientStoreProvider } from '../graphqlClientStoreProvider.service';

export const apolloClientBuilder = {
  buildApolloLink,
};

function buildApolloLink() {

  const authHttpLink = buildAuthHttpLink();

  const authWsLink = buildAuthWsLink();

  const link = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode;
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    authWsLink,
    authHttpLink,
  );

  return link;

}

function buildAuthWsLink() {
  const authWsLink = new WebSocketLink({
    uri: graphqlClientStoreProvider.get().baseWsUrl.getSnapshot(),
    options: {
      lazy: true, // https://github.com/apollographql/apollo-client/issues/3967
      reconnect: true,
      connectionParams: () => {
        const token = graphqlClientStoreProvider.get().authenticationToken.getSnapshot();
        // libLogger.debug('[graphql][apolloClientBuilder.buildAuthWsLink] token: ', token);
        return {
          headers: token ? {
            authorization: `Bearer ${token}`,
          } : {},
        };
      },
    },
  });
  return authWsLink;
}

function buildAuthHttpLink() {

  const logoutLink = onError(({ graphQLErrors, networkError }) => {
    if (networkError) {
      const statusCode = (networkError as ServerParseError).statusCode;

      if (statusCode === 401 || statusCode === 403) {
        libLogger.warn('[apolloClientBuilder] Security error', networkError);
        // TODO logout
      } else {
        libLogger.warn('[apolloClientBuilder] Unexpected error', networkError);
      }
    }
  });

  const httpLink = createHttpLink({
    uri: graphqlClientStoreProvider.get().baseHttpUrl.getSnapshot(),
  });
  const httpLinkUpdate = setContext((_, { headers }) => {
    const token = graphqlClientStoreProvider.get().authenticationToken.getSnapshot();
    // libLogger.debug('[graphql][apolloClientBuilder.buildAuthHttpLink] token: ', token);
    return {
      headers: token ? {
        ...headers,
        authorization: `Bearer ${token}`,
      } : headers,
    };
  });
  return logoutLink.concat(httpLinkUpdate.concat(httpLink));
}

