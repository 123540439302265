import { Emoji, EmojiSet } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import React from 'react';
import { DEFAULT_EMOJI_SET } from './DEFAULT_EMOJI_SET.const';

// https://unicode.org/emoji/charts/full-emoji-list.html

export const AppEmojiIcon = ({
  id,
  size,
  set = DEFAULT_EMOJI_SET,
  className,
}: {
  id: string;
  size: number;
  set?: EmojiSet;
  className?: string;
}) => {
  return !id ? null : (
    <div className={className}>
      <Emoji set={set} emoji={id} size={size} />
    </div>
  );
};
