import { ClubParticipantGaz, DiveGazType } from '@mabadive/app-common-model';

export const diveGazFormatter = {
  formatGaz,
  formatGazType,
};

function formatGaz(
  gaz: ClubParticipantGaz,
  {
    format,
    appendDescription = false,
    appendQuantity = false,
  }: {
    format: 'short' | 'long';
    appendDescription?: boolean;
    appendQuantity?: boolean;
  },
): string {
  const chunks: string[] = [];
  if (gaz?.gazType) {
    chunks.push(formatGazType(gaz?.gazType, { format }));
  }
  if (appendDescription && gaz?.gazDescription) {
    chunks.push(gaz?.gazDescription);
  }
  if (appendQuantity && gaz?.gazQuantity) {
    chunks.push(`${gaz?.gazQuantity}L`);
  }

  return chunks.join(' ');
}

function formatGazType(
  diveGazType: DiveGazType,
  { format }: { format: 'short' | 'long' },
): string {
  if (format === 'long') {
    switch (diveGazType) {
      case 'air':
        return 'Air';
      case 'simple-nitrox':
        return 'Nitrox simple';
      case 'confirmed-nitrox':
        return 'Nitrox confirmé';
      case 'trimix':
        return 'Trimix';
      case 'helium':
        return 'Hélium';
    }
  } else {
    // short
    switch (diveGazType) {
      case 'air':
        return 'AIR';
      case 'simple-nitrox':
        return 'NX';
      case 'confirmed-nitrox':
        return 'NXC';
      case 'trimix':
        return 'TRI';
      case 'helium':
        return 'HEL';
    }
  }
}
