"use strict";
exports.__esModule = true;
exports.DEFAULT_EQUIPMENT_SETTINGS_JACKET = void 0;
var DEFAULT_SIZES_CLUB = ['XXXS', 'XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL'];
exports.DEFAULT_EQUIPMENT_SETTINGS_JACKET = {
    type: 'jacket',
    enabled: true,
    club: {
        enabled: true,
        models: DEFAULT_SIZES_CLUB.map(function (ref) { return ({
            ref: ref
        }); }),
        visibleOnSession: true
    },
    self: {
        autoSetOnGlobal: true,
        enabled: false,
        models: [],
        visibleOnSession: true
    }
};
