import { BookingAgencyLocationType } from '@mabadive/app-common-model';

export const bookingAgencyFormatter = {
  formatLocationType,
};

function formatLocationType(locationType: BookingAgencyLocationType): string {
  switch (locationType) {
    case 'local': {
      return 'Locale';
    }
    case 'foreign': {
      return 'Étrangère';
    }
  }

  return locationType;
}
