"use strict";
exports.__esModule = true;
exports.DIVE_CERTIFICATION_DEBUTANT = void 0;
exports.DIVE_CERTIFICATION_DEBUTANT = {
    organization: undefined,
    organizationv2: undefined,
    defaultDiveMode: 'first-dive',
    reference: 'DEB',
    label: 'Débutant',
    referenceDescription: undefined,
    supervised: {
        maxDepth: 12,
        defaultAptitude: undefined
    },
    supervisor: false,
    nitrox: undefined,
    sortReference: '0-1-supervised-12m-undefined-0',
    nextSuggestedTrainingReference: 'FN1'
};
// NOTE: pour seacret dive, il y aurait aussi: jamais baptisé + baptisé
