/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BOOKING_GROUP_TYPES,
  BookingGroupType,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import {
  AppFormControlRHF,
  AppFormControlRHF_Deprecated,
  AppInputRHF,
  AppSingleSelect2HeadlessUIRHF,
} from '../../../../lib/form';
import { ValueLabel } from '../../../club/modules/_common/form';
import { BookingGroupTypeLabel } from './BookingGroupTypeLabel';
import { CustomerProductsPage_FillContactPanel_FormModel } from './model';

export const CustomerProductsPage_FillContactPanelForm = ({
  form,
  className = '',
}: {
  form: UseFormReturn<CustomerProductsPage_FillContactPanel_FormModel>;
  className?: string;
}) => {
  const { control } = form;

  const bookingGroupTypesOptions = useMemo(
    () =>
      BOOKING_GROUP_TYPES.map((type) => {
        const option: ValueLabel<BookingGroupType, React.ReactNode> = {
          value: type,
          label: (
            <BookingGroupTypeLabel
              className="px-1 py-px text-xs mr-2 sm:mr-4 md:mr-5"
              type={type}
              showIndividualWithoutLabel={true}
              format="long"
            />
          ),
        };
        return option;
      }),
    [],
  );

  const [bookingGroupType] = useWatch({
    control,
    name: ['bookingGroup.type'],
  });

  return (
    <div className={clsx('grid gap-2', className)}>
      <div
        className={
          'w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4 gap-4'
        }
      >
        <AppFormControlRHF
          className={'md:col-span-2 w-full'}
          label="Nom"
          control={control}
          name={'bookingContactDiver.lastName'}
          required={true}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF
          className={'md:col-span-2 w-full'}
          label="Prénom"
          control={control}
          required={true}
          name={'bookingContactDiver.firstName'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF
          className={'md:col-span-2 w-full'}
          label="Téléphone"
          control={control}
          name={'bookingContactDiver.phoneNumber'}
          required={true}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
        <AppFormControlRHF
          className={'md:col-span-2 w-full'}
          label="Adresse e-mail"
          required={true}
          control={control}
          validation={{
            email: true,
          }}
          name={'bookingContactDiver.emailAddress'}
          renderComponent={(props) => (
            <AppInputRHF {...props} fullWidth type="text" />
          )}
        />
      </div>{' '}
      <div
        className={
          'w-full flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-4 gap-4'
        }
      >
        <AppFormControlRHF
          className={'w-full'}
          label="Type de client"
          control={control}
          name={'bookingGroup.type'}
          renderComponent={(props) => (
            <AppSingleSelect2HeadlessUIRHF
              disableClearButton={true}
              theme={'tailwind'}
              className={'w-full'}
              options={bookingGroupTypesOptions}
              {...props}
            />
          )}
        />
        {bookingGroupType !== 'individual' && (
          <AppFormControlRHF_Deprecated
            className={'w-full'}
            label={
              bookingGroupType === 'club'
                ? 'Nom club'
                : bookingGroupType === 'company'
                ? 'Nom entreprise'
                : 'Nom réservation'
            }
            control={control}
            name={'bookingGroup.name'}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="text" />
            )}
          />
        )}
      </div>
    </div>
  );
};
