/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CustomerEcommerceProductArticleFull,
  OnlineBookingDetailsArticleOrderItem,
} from '@mabadive/app-common-model';
import { uuidGenerator } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { fakerClient } from '../../../../../../_core/data/app-operation';
import { useAutoFill } from '../../../../../../_core/data/hooks';
import {
  AppButton,
  AppHeroIcons,
  AppIconsAction,
} from '../../../../../../_core/modules/layout';
import {
  CustomerProductsPage_BookProductPanelFormModel_Item,
  CustomerProductsPage_BookProductPanelFormModel_Participant,
} from '../_model';
import { CustomerProductsPage_BookProductPanelLocalState } from '../useCustomerProductsPage_BookProductPanelLocalState.hook';
import { CustomerProductsPage_BookProductPanel_FillParticipants_PriceParticipants } from './CustomerProductsPage_BookProductPanel_FillParticipants_PriceParticipants';
import { CustomerProductsPage_FormModel } from './model';

export const CustomerProductsPage_BookProductPanel_FillParticipants = ({
  localState,
  className = '',
}: {
  localState: CustomerProductsPage_BookProductPanelLocalState;
  className?: string;
}) => {
  const { state, actions } = localState;

  const parentForm = state.form;

  const mainCurrency = localState.state.mainCurrency;

  const [parentFormItems] = useWatch({
    control: parentForm.control,
    name: ['article.items'],
  });

  const productArticleFull: CustomerEcommerceProductArticleFull =
    localState.data.productArticleFull;

  const productArticle = productArticleFull?.article;

  const minParticipantsCount = productArticle?.details?.minParticipantsCount;
  const maxParticipantsCount = productArticle?.details?.maxParticipantsCount;

  const defaultValues: CustomerProductsPage_FormModel = useMemo(() => {
    const items = parentFormItems
      .filter((x) => x.quantity > 0)
      .map((item: OnlineBookingDetailsArticleOrderItem) => {
        const participants: CustomerProductsPage_BookProductPanelFormModel_Participant[] =
          [];
        const minQuantity = minParticipantsCount * item.quantity;
        const maxQuantity = Math.min(30, maxParticipantsCount * item.quantity); // on limite à 50 pour ne pas faire péter la page si mauvaise config
        for (let i = 0; i < maxQuantity; i++) {
          const existingFormParticipant = participants?.[i];
          const p: CustomerProductsPage_BookProductPanelFormModel_Participant =
            {
              diverId:
                existingFormParticipant?.diverId ?? uuidGenerator.random(),
              firstName: existingFormParticipant?.firstName ?? '',
              lastName: existingFormParticipant?.lastName ?? '',
              age: existingFormParticipant?.age,
              requiredParticipant: i < minQuantity,
            };
          participants.push(p);
        }
        const priceParticipant: CustomerProductsPage_BookProductPanelFormModel_Item =
          {
            price: item.price,
            quantity: item.quantity,
            participants,
          };
        return priceParticipant;
      });
    return {
      items,
    };
  }, [maxParticipantsCount, minParticipantsCount, parentFormItems]);

  const localForm = useForm({
    defaultValues,
  });

  const items = useWatch({
    control: localForm.control,
    name: 'items',
  });
  const autoFill = useAutoFill();

  const participantsCount = useMemo(
    () =>
      (items ?? [])
        .filter((x) => x.quantity > 0)
        .reduce((acc, price) => acc + price.quantity, 0),
    [items],
  );

  const onSubmit = useCallback(() => {
    localForm.handleSubmit(
      (formValue: CustomerProductsPage_FormModel) => {
        const updatedItems: CustomerProductsPage_BookProductPanelFormModel_Item[] =
          formValue.items.map(({ participants, price, quantity }, i) => {
            // const originalPrice = items[i];
            // const formPrice: CustomerOnlineBookingStateArticlePrice = {};

            const item: CustomerProductsPage_BookProductPanelFormModel_Item = {
              price,
              participants: participants.filter(
                (x) => x.firstName?.trim()?.length > 0,
              ),
              quantity,
            };
            return item;
          });
        parentForm.setValue('article.items', updatedItems, {
          shouldDirty: true,
          shouldValidate: true,
          shouldTouch: true,
        });
        actions.goToNextStep();
      },
      (err) => {
        // TODO show msg
      },
    )();
  }, [actions, localForm, parentForm]);

  return (
    <div className={clsx('grid gap-2 relative', className)}>
      {autoFill && (
        <div className="absolute -right-1 top-10 p-1 z-50">
          <AppButton
            className="z-50"
            color={'primary-outline-light'}
            size="normal"
            icon={AppHeroIcons.autoFill}
            onClick={async () => {
              const { bookingParticipants } =
                await fakerClient.generateOnlineBookingParticipants({
                  clubReference: localState.state.clubReference,
                  participantsCount,
                });
              localForm.setValue(
                'items',
                items.map((x, xIndex) => {
                  const model: CustomerProductsPage_BookProductPanelFormModel_Item =
                    {
                      ...x,
                      participants: x.participants.map((p, pIndex) => {
                        const bp = bookingParticipants[pIndex]?.diver;
                        const formParticipant: CustomerProductsPage_BookProductPanelFormModel_Participant =
                          {
                            ...p,
                            firstName:
                              p?.firstName?.trim()?.length > 0
                                ? p?.firstName
                                : bp?.firstName,
                            lastName:
                              p?.lastName?.trim()?.length > 0
                                ? p?.lastName
                                : bp?.lastName,
                            age: p?.age > 0 ? p?.age : bp?.age,
                          };
                        return formParticipant;
                      }),
                    };
                  return model;
                }),
                {
                  shouldDirty: true,
                  shouldTouch: true,
                  shouldValidate: true,
                },
              );
            }}
          ></AppButton>
        </div>
      )}
      {items.map((priceParticipant, participantIndex) => {
        return (
          <CustomerProductsPage_BookProductPanel_FillParticipants_PriceParticipants
            key={participantIndex}
            localState={localState}
            localForm={localForm}
            priceIndex={participantIndex}
            priceParticipant={priceParticipant}
            productArticleFull={productArticleFull}
            mainCurrency={mainCurrency}
          />
        );
      })}
      <div className={clsx('my-6 flex gap-8 justify-around')}>
        <AppButton
          className="uppercase"
          size="large"
          icon={AppIconsAction.back}
          color="gray-outline-light"
          onClick={() => actions.backToPreviousStep()}
        >
          Retour
        </AppButton>
        <AppButton
          className="uppercase"
          size="large"
          icon={AppHeroIcons.actionConfirm}
          color="primary-bg"
          disabled={!localForm.formState.isValid}
          onClick={() => onSubmit()}
        >
          Confirmer
        </AppButton>
      </div>
    </div>
  );
};
