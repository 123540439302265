import { ClubDiverTechnicalLevel } from '../../../../app-common-model/src';

export const diverTechnicalLevelFormatter = {
  formatTechnicalLevel,
};
function formatTechnicalLevel(
  technicalLevel: ClubDiverTechnicalLevel,
  {
    format,
  }: {
    format: 'short' | 'long';
  },
) {
  switch (technicalLevel) {
    case 'beginner':
      return format === 'short' ? 'Deb' : 'Débutant';
    case 'intermediate':
      return format === 'short' ? 'Int' : 'Intermédiaire';
    case 'advanced':
      return format === 'short' ? 'Conf' : 'Confirmé';
    case 'tek':
      return format === 'short' ? 'Deb' : 'Tek';
    default:
      return technicalLevel;
  }
}
