import { AppCurrency, ClubPublicSettings } from '@mabadive/app-common-model';
import { useAppCurrency } from './useAppCurrency.hook';

export function useAppCurrencyMain({
  clubPublicSettings,
}: {
  clubPublicSettings: ClubPublicSettings;
}): AppCurrency {
  const currencyIsoCode = clubPublicSettings?.general?.currencyIsoCode;
  return useAppCurrency(currencyIsoCode);
}
