import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MQueryDescription, graphqlClient } from 'src/_common-browser';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { diverBookingGraphqlQuery } from './diverBookingGraphqlQuery.fn';
import { DiverBookingCriteria } from './model';
import { DiverBookingGraphqlResult } from './model/DiverBookingGraphqlResult.type';

export const diverBookingGraphqlFetcher = {
  findOne,
};

function findOne(
  criteria: DiverBookingCriteria,
  {
    type,
    name = 'resume',
  }: {
    type: 'subscription' | 'query';
    name?: string;
  },
): Observable<DiverBookingGraphqlResult> {
  if (!criteria.diverId || !criteria.bookingId) {
    return of(undefined);
  }

  const query: MQueryDescription<any> = diverBookingGraphqlQuery(criteria);

  return graphqlClient.query
    .runOne<DiverBookingGraphqlResult>(query, {
      type,
      name,
    })
    .pipe(
      catchError((err) => {
        console.error(err);
        appLogger.warn('[diverBookingGraphqlFetcher] error fetching data', err);
        return throwError(err);
      }),
    );
}
