import {
  ClubProductPackageMeta,
  ClubPurchasePackage,
  ClubPurchasePackageMeta,
} from '@mabadive/app-common-model';
import { clubProductPackageMetaReader } from '../product-package/clubProductPackageMetaReader.service';

export const clubPurchasePackageMetaReader = {
  readMeta,
};

function readMeta(
  purchasePackage: Pick<ClubPurchasePackage, 'credits' | 'productPackageOffer'>,
): ClubPurchasePackageMeta {
  if (!purchasePackage) {
    return undefined;
  }

  const credits = purchasePackage?.credits;

  const { productPackage } = purchasePackage.productPackageOffer;

  const productMeta: ClubProductPackageMeta =
    clubProductPackageMetaReader.readMeta(productPackage);

  const meta: ClubPurchasePackageMeta = {
    ...productMeta,
    credits,
  };
  return meta;
}
