import {
  COUNTRIES_LABELS_BY_CODE,
  CountryCodeIso31661,
  DIVE_APTITUDES_CODE_SPORT,
  DIVE_APTITUDES_POLYNESIE,
  RegionalSettings,
} from '@mabadive/app-common-model';

export const regionalSettingsBuilder = {
  buildByCountryCode,
};

function buildByCountryCode({
  effectiveDate,
  countryIsoCode,
}: {
  effectiveDate: Date; // en fonction de la date, les taxes et la réglementation évoluent
  countryIsoCode: CountryCodeIso31661;
}): RegionalSettings {
  const countryLabel = COUNTRIES_LABELS_BY_CODE[countryIsoCode];

  // défaut: France
  const regionalSettings: RegionalSettings = {
    countryIsoCode,
    countryLabel,
    defaultCurrencyIsoCode: 'euro',
    divingLegislation: {
      aptitudes: DIVE_APTITUDES_CODE_SPORT,
    },
    taxes: [
      {
        reference: 'vat-normal',
        category: 'vat',
        categoryLabel: 'TVA',
        rateLabel: 'taux normal',
        ratePercent: 20,
      },
      {
        reference: 'vat-intermediate',
        category: 'vat',
        categoryLabel: 'TVA',
        rateLabel: 'taux intermédiaire',
        ratePercent: 10,
      },
      {
        reference: 'vat-reduced',
        category: 'vat',
        categoryLabel: 'TVA',
        rateLabel: 'taux réduit',
        ratePercent: 5.5,
      },
    ],
  };

  switch (countryIsoCode) {
    case 'PF': // Polynésie Française
      regionalSettings.defaultCurrencyIsoCode = 'xof';
      regionalSettings.divingLegislation.aptitudes = DIVE_APTITUDES_POLYNESIE;
      regionalSettings.taxes = [
        {
          reference: 'vat-normal',
          category: 'vat',
          categoryLabel: 'TVA',
          rateLabel: 'taux normal',
          ratePercent: 16,
        },
        {
          reference: 'vat-intermediate',
          category: 'vat',
          categoryLabel: 'TVA',
          rateLabel: 'taux intermédiaire',
          ratePercent: 13,
        },
        {
          reference: 'vat-reduced',
          category: 'vat',
          categoryLabel: 'TVA',
          rateLabel: 'taux réduit',
          ratePercent: 5,
        },
        {
          reference: 'cps',
          category: 'social',
          categoryLabel: 'CPS',
          ratePercent: 1,
        },
      ];
      break;
    case 'MT': // Malte
      regionalSettings.taxes = [
        {
          reference: 'vat-normal',
          category: 'vat',
          categoryLabel: 'TVA',
          rateLabel: 'taux normal',
          ratePercent: 18,
        },
        {
          reference: 'vat-intermediate',
          category: 'vat',
          categoryLabel: 'TVA',
          rateLabel: 'taux intermédiaire',
          ratePercent: 7,
        },
        {
          reference: 'vat-reduced',
          category: 'vat',
          categoryLabel: 'TVA',
          rateLabel: 'taux réduit',
          ratePercent: 5,
        },
      ];
      break;
    case 'CH': // Suisse
      regionalSettings.defaultCurrencyIsoCode = 'chf';
      if (effectiveDate.getTime() < Date.parse('2024-01-01')) {
        regionalSettings.taxes = [
          {
            reference: 'vat-normal',
            category: 'vat',
            categoryLabel: 'TVA',
            rateLabel: 'taux normal',
            ratePercent: 7.7, // 8.1 en 2024
          },
          {
            reference: 'vat-intermediate',
            category: 'vat',
            categoryLabel: 'TVA',
            rateLabel: 'taux intermédiaire',
            ratePercent: 3.7, // 3.8 en 2024
          },
          {
            reference: 'vat-reduced',
            category: 'vat',
            categoryLabel: 'TVA',
            rateLabel: 'taux réduit',
            ratePercent: 2.7, // 2.6 en 2024
          },
        ];
      } else {
        // changement en 2024
        regionalSettings.taxes = [
          {
            reference: 'vat-normal',
            category: 'vat',
            categoryLabel: 'TVA',
            rateLabel: 'taux normal',
            ratePercent: 8.1,
          },
          {
            reference: 'vat-intermediate',
            category: 'vat',
            categoryLabel: 'TVA',
            rateLabel: 'taux intermédiaire',
            ratePercent: 3.8,
          },
          {
            reference: 'vat-reduced',
            category: 'vat',
            categoryLabel: 'TVA',
            rateLabel: 'taux réduit',
            ratePercent: 2.6,
          },
        ];
      }

      break;
    case 'ES': // Espagne
      regionalSettings.taxes = [
        {
          reference: 'vat-normal',
          category: 'vat',
          categoryLabel: 'TVA',
          rateLabel: 'taux normal',
          ratePercent: 21,
        },
        {
          reference: 'vat-intermediate',
          category: 'vat',
          categoryLabel: 'TVA',
          rateLabel: 'taux intermédiaire',
          ratePercent: 10,
        },
        {
          reference: 'vat-reduced',
          category: 'vat',
          categoryLabel: 'TVA',
          rateLabel: 'taux réduit',
          ratePercent: 4,
        },
      ];
      break;
    case 'GP': // Guadeloupe
    case 'MQ': // Martinique
    case 'RE': // La Réunion
      regionalSettings.taxes = [
        {
          reference: 'vat-normal',
          category: 'vat',
          categoryLabel: 'TVA',
          rateLabel: 'taux normal',
          ratePercent: 8.5,
        },
        {
          reference: 'vat-reduced',
          category: 'vat',
          categoryLabel: 'TVA',
          rateLabel: 'taux réduit',
          ratePercent: 2.1,
        },
      ];
      break;
    case 'YT': // 'Mayotte',
    case 'GF': // 'Guyanne Française',
      regionalSettings.taxes = []; // pas de TVA
      break;
    case 'MU': // 'Maurice',
      regionalSettings.defaultCurrencyIsoCode = 'mur';
      regionalSettings.taxes = [
        {
          reference: 'vat-normal',
          category: 'vat',
          categoryLabel: 'TVA',
          rateLabel: 'taux normal',
          ratePercent: 15,
        },
      ];
      break;
    case 'PH': // 'Philippines',
      regionalSettings.defaultCurrencyIsoCode = 'php';
      regionalSettings.taxes = [
        {
          reference: 'vat-normal',
          category: 'vat',
          categoryLabel: 'TVA',
          rateLabel: 'taux normal',
          ratePercent: 12,
        },
      ];
      break;
    case 'SN': // 'Sénégal',
      regionalSettings.defaultCurrencyIsoCode = 'xof';
      regionalSettings.taxes = [
        {
          reference: 'vat-normal',
          category: 'vat',
          categoryLabel: 'TVA',
          rateLabel: 'taux normal',
          ratePercent: 18,
        },
        {
          reference: 'vat-reduced',
          category: 'vat',
          categoryLabel: 'TVA',
          rateLabel: 'taux réduit',
          ratePercent: 10,
        },
      ];
      break;
    case 'NC': // 'Nouvelle Calédonie',
      regionalSettings.defaultCurrencyIsoCode = 'xpf';
      regionalSettings.taxes = [
        {
          reference: 'vat-increased',
          category: 'vat',
          categoryLabel: 'TGC',
          rateLabel: 'taux majoré',
          ratePercent: 22,
        },
        {
          reference: 'vat-normal',
          category: 'vat',
          categoryLabel: 'TGC',
          rateLabel: 'taux normal',
          ratePercent: 11,
        },
        {
          reference: 'vat-intermediate',
          category: 'vat',
          categoryLabel: 'TGC',
          rateLabel: 'taux spécifique',
          ratePercent: 6,
        },
        {
          reference: 'vat-reduced',
          category: 'vat',
          categoryLabel: 'TGC',
          rateLabel: 'taux réduit',
          ratePercent: 3,
        },
      ];
      break;
  }

  return regionalSettings;
}
