//https://react-icons.github.io/react-icons/icons?name=md
import {
  ChatBubbleBottomCenterTextIcon,
  MapPinIcon,
  NoSymbolIcon,
  PhotoIcon,
} from '@heroicons/react/24/outline';
import { MdFullscreen, MdFullscreenExit } from 'react-icons/md';

import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
export const AppIconsUI = {
  fullscreen: MdFullscreen,
  fullscreenExit: MdFullscreenExit,
  emptyPhoto: PhotoIcon,
  navLeft: ChevronLeftIcon,
  navLeftFast: ChevronDoubleLeftIcon,
  navRight: ChevronRightIcon,
  navRightFast: ChevronDoubleRightIcon,
  comment: ChatBubbleBottomCenterTextIcon,
  disabled: NoSymbolIcon,
  location: MapPinIcon,
};
