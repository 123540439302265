"use strict";
exports.__esModule = true;
exports.DIVE_APTITUDES_POLYNESIE = void 0;
exports.DIVE_APTITUDES_POLYNESIE = [
    'PE12',
    'PA12',
    'PE20',
    'PA20',
    'PE30',
    'PA30',
    'PE40',
    'PA40',
    'PE50',
    'PA50',
    'PE60',
    'PA60', // à supprimer?
];
