import { useMemo } from 'react';
import { DiveCenterShoppingCartState } from '../_model';

export function useDiveCenterShoppingCartTotalDetails({
  cart,
}: {
  cart: DiveCenterShoppingCartState;
}): { amountToPayNow: number; amountToPayLater: number } {
  return useMemo(
    () => buildDiveCenterShoppingCartTotalDetails({ cart }),
    [cart],
  );
}
export function buildDiveCenterShoppingCartTotalDetails({
  cart,
}: {
  cart: DiveCenterShoppingCartState;
}): { amountToPayNow: number; amountToPayLater: number } {
  return cart.orderArticles.reduce(
    (acc, orderArticle) => {
      const price = orderArticle.items.reduce(
        (acc, item) => acc + item.price.unitPrice * item.participants.length,
        0,
      );
      if (orderArticle.diveSessionReference) {
        acc.amountToPayNow += price;
      } else {
        acc.amountToPayLater += price;
      }
      return acc;
    },
    {
      amountToPayNow: 0,
      amountToPayLater: 0,
    },
  );
}
