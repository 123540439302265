import {
  CLUB_STAFF_MEMBER_DAILY_AVAILABILITY_PERIODS_AVAILABLE,
  ClubStaffMember,
  ClubStaffMemberDailyAvailabilities,
  ClubStaffMemberRole,
  StaffMemberDailyAvailabilityResume,
} from '@mabadive/app-common-model';
import { ClubStaffMemberDailyAvailability } from '../../../../app-common-model/src/entities/club-staff-member/ClubStaffMemberDailyAvailability.type';
import { clubStaffMemberAvailabilitySelector } from '../../business';
import { dateService } from '../../data';

export const staffMemberDefaultAvailabilityFinder = {
  getDefaultDailyAvailability,
};

function getDefaultDailyAvailability(
  staffMember: Pick<ClubStaffMember, 'profile' | 'availabilities'>,
  {
    date = new Date(),
  }: {
    date: Date;
  },
): StaffMemberDailyAvailabilityResume {
  // const date = dateService.getUTCDateSetTime(inputDate); // on supprime les heures
  const availableRoles: ClubStaffMemberRole[] =
    staffMember.profile?.roles ?? [];

  const availabilities = staffMember.availabilities.filter(
    (x) =>
      clubStaffMemberAvailabilitySelector.isAvailabilityValidForDate({
        availability: x,
        date,
      }),
    // dateService.isBefore(x.beginDate, date) &&
    // (!x.endDate || dateService.isBefore(date, x.endDate)),
  );
  if (availabilities.length === 0) {
    // la plupart du temps, l'info jobType sera bonne si on prend la première dispo
    const jobType = staffMember.availabilities[0]?.jobType;
    return {
      isAvailableOnPeriod: false,
      isAvailableToday: false,
      availabilityPeriod: 'none',
      availableRoles,
      jobType,
      defaultWorkingDays: {} as ClubStaffMemberDailyAvailabilities,
    };
  }

  const selectedAvailability =
    clubStaffMemberAvailabilitySelector.selectAvailabilityPeriod({
      date,
      availabilities,
    });

  const todayIso = dateService.getUTCWeekDayIso(date);

  const defaultDailyAvailability: ClubStaffMemberDailyAvailability =
    selectedAvailability.defaultWorkingDays[todayIso];
  const isAvailableToday =
    CLUB_STAFF_MEMBER_DAILY_AVAILABILITY_PERIODS_AVAILABLE.includes(
      defaultDailyAvailability?.availabilityPeriod,
    );

  const resume: StaffMemberDailyAvailabilityResume = {
    isAvailableOnPeriod: true,
    isAvailableToday,
    availableRoles,
    jobType: selectedAvailability.jobType,
    defaultWorkingDays: selectedAvailability.defaultWorkingDays,
    availabilityPeriod: defaultDailyAvailability?.availabilityPeriod,
  };
  return resume;
}
