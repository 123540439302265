import { DiveSessionResumeFull } from '@mabadive/app-common-model';
import { dataSorter } from '../../data/sorter/dataSorter.service';

export const diveCenterDailyResumeModelBuilder = { buildSessions };

function buildSessions(
  diveSessionResumes: DiveSessionResumeFull[],
): DiveSessionResumeFull[] {
  const sessionsFiltered = diveSessionResumes.filter(
    (x) =>
      x.status === 'on' &&
      x.participants.filter((p) => p.bookingState?.value !== 'cancelled')
        .length !== 0,
  );
  return dataSorter.sortMultiple(sessionsFiltered, {
    getSortAttributes: (x) => [
      {
        value: x.time,
      },
      {
        value: x._id,
      },
    ],
  });
}
