import { DiveMode, DIVE_CERTIFICATIONS_REFERENCE_MAP } from '@mabadive/app-common-model';

const EMPTY_VALUE = '';
const SEPARATOR = '***';

export const diveTypeReferenceParser = {
  buildDiveTypeReference,
  parseDiveTypeReference,
};

function buildDiveTypeReference({ diveMode, certificationReference, trainingReference }: { diveMode: DiveMode; certificationReference?: string; trainingReference?: string; }): string {
  if (diveMode === 'supervised' || diveMode === 'autonomous') {
    const certification = DIVE_CERTIFICATIONS_REFERENCE_MAP[certificationReference];
    return `${certification.defaultDiveMode}${SEPARATOR}${certificationReference || EMPTY_VALUE}${SEPARATOR}${trainingReference || EMPTY_VALUE}`;
  }

  return `${diveMode}${SEPARATOR}${certificationReference || EMPTY_VALUE}${SEPARATOR}${trainingReference || EMPTY_VALUE}`;
}

function parseDiveTypeReference(diveTypeReference: string) {
  if (!diveTypeReference) {
    return undefined;
  }
  const chunks = diveTypeReference ? diveTypeReference.split(SEPARATOR) : undefined;
  const diveType = {
    diveMode: getChunk<DiveMode>(chunks, 0),
    certificationReference: getChunk(chunks, 1),
    trainingReference: getChunk(chunks, 2),
  };
  return diveType;
}

function getChunk<T = string>(chunks: any[], index: number): T {
  return (chunks && chunks[index] !== EMPTY_VALUE ? chunks[index] : undefined);
}
