import { BookingDepositState } from '@mabadive/app-common-model';

export const bookingDepositStateFormatter = {
  formatDepositState,
};

function formatDepositState(
  bookingDepositState: BookingDepositState,
  { isPaymentPending }: { isPaymentPending: boolean },
): string {
  if (isPaymentPending) {
    return 'En cours';
  }
  switch (bookingDepositState) {
    case 'draft':
      return 'En attente';
    case 'active':
      return 'À utiliser';
    case 'used':
      return 'Utilisé';
    case 'archived':
      return 'Archivé';
  }
}
