"use strict";
exports.__esModule = true;
exports.PARTICIPANT_COMMENT_TAG = void 0;
exports.PARTICIPANT_COMMENT_TAG = {
    reference: 'comment',
    avatar: {
        label: 'C',
        color: '#fff',
        background: '#555'
    },
    content: {
        label: 'Commentaire',
        color: '#fff',
        background: '#999'
    }
};
