import imageBlobReduce from 'image-blob-reduce';
import { Area } from 'react-easy-crop';
import { appLogger } from '../../../../business/_core/modules/root/logger';

export const appImageConverter = { getImageDataUrl, cropImage, dataURLToFile };

function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}
function rotateSize(width: number, height: number, rotation: number) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

async function getImageDataUrl(
  file: Blob,
  {
    maxWidthOrLength,
  }: {
    maxWidthOrLength?: number;
  },
) {
  let resizedFile: Blob = file;

  if (maxWidthOrLength > 0) {
    // try to resize
    try {
      resizedFile = await imageBlobReduce().toBlob(file, {
        max: maxWidthOrLength, // largeur / hauteur maxi de l'image
      });
    } catch (err) {
      // erreur lors du redimentionnement de l'image
      // eslint-disable-next-line no-console
      console.error(err);
      appLogger.warn('Error resizing file');
    }
  }
  return await readBlobAsDataURL(resizedFile);
}
async function readBlobAsDataURL(blob: Blob) {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => resolve(reader.result as string),
      false,
    );
    reader.readAsDataURL(blob);
  });
}
function cropImage(imageDataUrl: string, cropArea: Area): Promise<string> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Set canvas size to the crop area size
      canvas.width = cropArea.width;
      canvas.height = cropArea.height;

      // Draw the image on the canvas, applying the crop area
      // Note: The source rectangle is defined by the crop area (cropArea.x, cropArea.y, cropArea.width, cropArea.height)
      // The destination rectangle covers the entire canvas
      ctx.drawImage(
        img,
        cropArea.x,
        cropArea.y,
        cropArea.width,
        cropArea.height,
        0,
        0,
        canvas.width,
        canvas.height,
      );

      // Convert the canvas to a data URL and resolve the promise
      resolve(canvas.toDataURL());
    };
    img.onerror = (error) => {
      reject(error);
    };
    img.src = imageDataUrl;
  });
}
async function dataURLToFile(
  dataUrl: string,
  {
    fileName,
    maxWidthOrLength,
  }: {
    fileName: string;
    maxWidthOrLength?: number;
  },
): Promise<File> {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  let file = new File([u8arr], fileName, { type: mime });

  if (maxWidthOrLength > 0) {
    // try to resize
    try {
      const blob = await imageBlobReduce().toBlob(file, {
        max: maxWidthOrLength, // largeur / hauteur maxi de l'image
      });
      file = new File([blob], fileName);
    } catch (err) {
      // erreur lors du redimentionnement de l'image
      // eslint-disable-next-line no-console
      console.error(err);
      appLogger.warn('Error resizing file');
    }
  }

  return file;
}
