import clsx from 'clsx';
import React, { useCallback, useEffect, useRef } from 'react';
import SignaturePad, { PointGroup } from 'signature_pad';
import { AppHeroIcons } from '../../../../business/_core/modules/layout';

export type AppSignatureExtended = {
  data?: PointGroup[];
  isValid: boolean;
};

export const AppSignaturePad = ({
  className,
  padClassName,
  minPoints = 10,
  initialData,
  onChange,
}: {
  className?: string;
  padClassName?: string;
  minPoints?: number;
  initialData?: PointGroup[];
  onChange?: (
    signature: AppSignatureExtended,
    opts: {
      signaturePad: SignaturePad;
    },
  ) => void;
}) => {
  const [signature, setSignature] = React.useState<AppSignatureExtended>({
    isValid: false,
    data: initialData || [], // Utilisez initialData si disponible
  });

  const signaturePadRef = useRef<SignaturePad>(null);
  const signaturePadCanvasRef = useRef<HTMLCanvasElement>(null);

  const updateData = useCallback(
    (signature: { data: PointGroup[] }) => {
      if (signature?.data) {
        const pointsCount = signature?.data.reduce(
          (acc, x) => acc + x.points?.length ?? 0,
          0,
        );
        // valide si au moins 10 points
        const isValid = pointsCount >= minPoints;
        if (isValid) {
          // signatureData
        }
        const signatureExt: AppSignatureExtended = {
          data: signature?.data,
          isValid,
        };
        setSignature(signatureExt);
        onChange &&
          onChange(signatureExt, { signaturePad: signaturePadRef.current });
      } else {
        const signatureExt: AppSignatureExtended = {
          data: undefined,
          isValid: false,
        };
        setSignature(signatureExt);
        onChange &&
          onChange(signatureExt, { signaturePad: signaturePadRef.current });
      }
    },
    [minPoints, onChange],
  );

  const onEndStroke = useCallback(() => {
    const signaturePad = signaturePadRef.current;
    if (signaturePad) {
      const dataTmp = signaturePad?.toData();
      const signatureData = dataTmp ? [...dataTmp] : [];
      updateData({
        data: signatureData,
      });
    }
  }, [updateData]);

  // let signaturePad: SignaturePad;
  useEffect(() => {
    const canvas = signaturePadCanvasRef.current;
    if (canvas) {
      const signaturePad = new SignaturePad(canvas, {
        penColor: '#1E3A8A',
        backgroundColor: '#fafafa',
      });
      signaturePadRef.current = signaturePad;

      if (initialData?.length) {
        signaturePad.fromData(initialData); // Chargez la signature initiale si elle existe
      }
      // Attacher les événements
      signaturePad.addEventListener('endStroke', onEndStroke, { once: false });
    }

    // Nettoyage lors du démontage du composant
    return () => {
      const signaturePad = signaturePadRef.current;
      if (signaturePad) {
        signaturePad.off();
      }
    };
  }, [initialData, onEndStroke]);

  const clearSignature = async () => {
    const signaturePad = signaturePadRef.current;
    if (signaturePad) {
      try {
        signaturePad.clear();
      } catch (error) {
        // error
      } finally {
        updateData(undefined);
      }
    }
  };
  return (
    <div className={className}>
      <canvas
        ref={signaturePadCanvasRef}
        className={clsx(
          'border-2',
          padClassName,
          signature.isValid ? 'border-green-400' : 'border-red-400',
        )}
      />

      <div className="flex gap-8">
        <button
          className="uppercase p-2 text-status-error"
          onClick={clearSignature}
        >
          <AppHeroIcons.actionDelete className="inline-block w-5 h-5" />
          Effacer
        </button>
      </div>
    </div>
  );
};
