import { DiveSession, DiveSessionResume } from '@mabadive/app-common-model';

export const diveSessionResumeToDiveSessionConvertor = {
  convert,
};

function convert(
  dsr: DiveSessionResume,
  {
    clubReference,
  }: {
    clubReference: string;
  },
): DiveSession {
  const diveSession: DiveSession = {
    _id: dsr._id,
    name: dsr.name,
    reference: dsr.reference,
    diveCenterId: dsr.diveCenterId,
    clubReference,
    dayReference: dsr.dayReference,
    isVirtual: dsr.isVirtual,
    virtualDiveSessionReference: dsr.virtualDiveSessionReference,
    virtualDiveTourReference: dsr.virtualDiveTourReference,
    time: dsr.time,
    status: dsr.status,
    boatsIds: dsr.boatsIds,
    special: dsr.special,
    diveSiteId: dsr.diveSiteId,
    tide: dsr.tide,
    importantNotes: dsr.importantNotes,
    weather: dsr.weather,
    comment: dsr.comment,
    diveTourSession1: dsr.diveTourSession1,
    diveTourSession2: dsr.diveTourSession2,
    bookingConfig: dsr.bookingConfig,
    sessionsCount: dsr.sessionsCount,
    emojiId: dsr.emojiId,
    details: dsr.details,
    theme: dsr.theme,
    type: dsr.type,
    // staffConfig: dsr.staffConfig,
  };

  return diveSession;
}
