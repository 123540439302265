import { CustomerFetchAvailabilyResult } from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import { useCallback } from 'react';
import {
  AppHackableCalendarWeeksPanelDayAttributes,
  AppHackableCalendarWeeksPanelDayBaseAttributes,
  AppHackableCalendarWeeksPanelDayBuildAttributesFn,
} from '../../../../../../stories/components/03-advanced/AppHackableCalendar/weeks-panel/AppHackableCalendarWeeksPanelDay';
import { AppHackableCalendarWeeksPanelVMDay } from '../../../../../../stories/components/03-advanced/AppHackableCalendar/weeks-panel/model';

export function useAppHackableCalendarCustomizeDayFromSessionAvailability({
  availabilityResult,
}: {
  availabilityResult: CustomerFetchAvailabilyResult;
}): AppHackableCalendarWeeksPanelDayBuildAttributesFn {
  const customizeDay: AppHackableCalendarWeeksPanelDayBuildAttributesFn =
    useCallback(
      (
        day: AppHackableCalendarWeeksPanelVMDay,
        attrs: AppHackableCalendarWeeksPanelDayBaseAttributes,
      ) => {
        const attributes: AppHackableCalendarWeeksPanelDayAttributes = {
          isSelectable: attrs.isInPeriodRange,
          isVisible: day.isCurrentViewMonth, // on masque les jours des autres mois, pour ne pas laisser penser qu'il n'y a pas de place
          classes: { ...attrs.classes },
        };
        if (attrs.isInPeriodRange) {
          const availabilityDay = (availabilityResult?.days ?? []).find((d) =>
            dateService.isSameDayUTC(d.date, day.date),
          );

          const status = availabilityDay?.status ?? 'closed';
          switch (status) {
            case 'ok':
              // attributes.classes.cellBgColor = 'bg-green-100';
              attributes.classes.dateBgColor =
                'bg-green-400 group-hover:bg-green-500';
              attributes.classes.textColor = 'text-white';
              attributes.isSelectable = true;
              break;
            case 'closed':
              // attributes.classes.cellBgColor = 'bg-white'; // no hover effect
              attributes.classes.cellBgColor = 'bg-gray-50';
              attributes.isSelectable = false;
              break;
            case 'no':
              attributes.classes.cellBgColor = 'bg-gray-50';
              attributes.classes.dateBgColor = 'bg-red-200';
              attributes.isSelectable = true;
              break;
            case 'partial':
              attributes.classes.cellBgColor = 'bg-gray-50';
              attributes.classes.dateBgColor = 'bg-orange-200';
              attributes.isSelectable = true;
          }
          if (!attributes.isSelectable) {
            attributes.classes.fontWeight = 'font-normal'; // no bold
          } else {
            attributes.classes.fontWeight = 'font-bold'; // no bold
          }
          if (day.isToday) {
            // attributes.classes.cellBgColor = 'bg-blue-100';
            attributes.classes.dateBorderColor = 'border-gray-400';
          }
        }

        return attributes;
      },
      [availabilityResult],
    );

  return customizeDay;
}
