import { Input, InputAdornment, InputProps } from '@material-ui/core';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { Control, Path, useController } from 'react-hook-form';
import { AppHeroIcons } from 'src/business/_core/modules/layout/icons';

export type AppInputRHFType = 'password' | 'text' | 'number';

// TODO une fois complètement migré vers react-hook-form, migrer les composants de 'material-ui' vers '@headlessui/react' (voir https://tailwindui.com/components/application-ui/forms)
export function AppInputRHF<T>({
  clearable,
  control,
  name,
  className,
  type,
  onChange: onChangeEmit,
  showIncrementButtons,
  showIncrementButtonsClassName = 'h-8 w-8',
  minValue,
  maxValue,
  ...extraProps
}: {
  control: Control<T>;
  name: Path<T>;
  className?: string;
  clearable?: boolean;
  onChange?: (value: string | number) => void;
  type?: AppInputRHFType;
  showIncrementButtons?: boolean;
  showIncrementButtonsClassName?: string;
  minValue?: number;
  maxValue?: number;
} & Omit<InputProps, 'type' | 'onChange'>) {
  const {
    field: { ref, value, onChange, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = useController<T>({
    name,
    control,
    rules: {
      required: extraProps.required,
    },
  });

  const isEmptyValue = useMemo(
    () => !value || ((value as string).trim && (value as string).trim() === ''),
    [value],
  );

  const intValue = useMemo(() => parseInt(`${value}`, 10), [value]);

  const isMinValue = minValue !== undefined && minValue >= intValue;
  const isMaxValue = maxValue !== undefined && maxValue <= intValue;

  return (
    <div className={clsx('flex gap-2', extraProps.fullWidth && 'w-full')}>
      <Input
        {...extraProps}
        {...inputProps}
        disabled={extraProps.disabled}
        type={type}
        className={`${isEmptyValue ? 'empty' : ''} ${className ?? ''}`}
        style={{
          fontStyle: 'bold',
        }}
        value={convertUndefinedToEmpty(value)}
        onChange={(e) => {
          const value = convertEmptyToNull<T>(e.target.value, type);
          onChange(value);
          if (onChangeEmit) {
            onChangeEmit(value as any);
          }
        }}
        error={invalid}
        autoComplete="off"
        startAdornment={
          !clearable ? null : (
            <InputAdornment position="start">
              <AppHeroIcons.actionCancel
                className="text-gray-600 hover:text-gray-800 cursor-pointer"
                onClick={() => onChange(null)}
              />
            </InputAdornment>
          )
        }
      />
      {showIncrementButtons && (
        <div className="flex gap-1">
          <AppHeroIcons.actionIncrement
            className={clsx(
              showIncrementButtonsClassName,
              isMinValue
                ? 'text-gray-300 cursor-not-allowed'
                : 'text-app-primary hover:text-app-primary-dark cursor-pointer',
            )}
            onClick={() => {
              if (isNaN(intValue) || isMinValue) {
                onChange(minValue ?? 0);
              } else {
                onChange(intValue - 1);
              }
            }}
          />
          <AppHeroIcons.actionDecrement
            className={clsx(
              showIncrementButtonsClassName,
              isMaxValue
                ? 'text-gray-300 cursor-not-allowed'
                : 'text-app-primary hover:text-app-primary-dark cursor-pointer',
            )}
            onClick={() => {
              if (isNaN(intValue) || isMaxValue) {
                onChange(maxValue ?? (minValue ?? 0) + 1);
              } else {
                onChange(intValue + 1);
              }
            }}
          />
        </div>
      )}
    </div>
  );
}
function convertEmptyToNull<T>(value: string, type: AppInputRHFType): T {
  if (type === 'number') {
    if (value == null) {
      return null;
    }
    const number = parseFloat(value);
    if (isNaN(number)) {
      return null;
    }
    return number as unknown as T;
  }
  const str = value && value.length ? value : null;
  return str as unknown as T;
}

function convertUndefinedToEmpty<T>(value: T) {
  return value != null ? value : '';
}
