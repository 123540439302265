export const phoneLinkBuilder = {
  buildLink,
  cleanPhoneNumber,
  formatPhoneNumber,
};

function cleanPhoneNumber(phoneNumber: string) {
  if (phoneNumber) {
    phoneNumber = phoneNumber.trim();
    if (!phoneNumber?.length) {
      return;
    }
    return phoneNumber;
  }
}

function buildLink({ phoneNumber: phoneNumberInput }: { phoneNumber: string }) {
  const phoneNumber = cleanPhoneNumber(phoneNumberInput);
  if (phoneNumber) {
    return `tel:${phoneNumber}`;
  }
}

function formatPhoneNumber(phoneNumber: string): string {
  // Note: y'a aussi une librairie gogole, mais ça va allourdir le code : https://github.com/google/libphonenumber
  if (!phoneNumber) {
    return phoneNumber;
  }
  const cleaned = phoneNumber.replace(/\D/g, '');
  let formatted = '';

  if (phoneNumber.startsWith('+')) {
    // Format for international numbers with +
    const intlPrefixLength = cleaned[0] === '1' ? 3 : 2; // Check if the international prefix is 3 digits
    const intlPattern =
      intlPrefixLength === 3
        ? /(\d{3})(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/
        : /(\d{2})(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/;
    formatted = '+' + cleaned.replace(intlPattern, `$1 $2 $3 $4 $5 $6`);
  } else if (
    cleaned.length === 10 &&
    (cleaned.startsWith('06') || cleaned.startsWith('07'))
  ) {
    // Format for French numbers starting with 06 or 07
    formatted = cleaned.replace(
      /(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
      '$1 $2 $3 $4 $5',
    );
  } else if (cleaned.startsWith('00')) {
    // Format for international numbers with 00
    const intlPrefixLength = cleaned[2] === '1' ? 3 : 2; // Check if the international prefix is 3 digits
    const intlPattern =
      intlPrefixLength === 3
        ? /(\d{3})(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/
        : /(\d{2})(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/;
    formatted =
      '+' + cleaned.slice(2).replace(intlPattern, `$1 $2 $3 $4 $5 $6`);
  } else {
    formatted = phoneNumber; // If it doesn't match any pattern, return the original number
  }

  return formatted;
}
