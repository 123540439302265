import { DiveMode, DiveSessionTheme } from '@mabadive/app-common-model';

export const diveSessionThemeAttributesFormatter = {
  getDefaultAttributes,
};

function getDefaultAttributes(theme: DiveSessionTheme): {
  defaultDiveMode?: DiveMode;
  emojiId?: string;
} {
  switch (theme) {
    case 'night':
      return {
        emojiId: 'crescent_moon', // 🌙
      };
    case 'shark':
      return {
        defaultDiveMode: 'watchingTour',
        emojiId: 'shark', // 🦈
      };
    case 'whale':
      return {
        defaultDiveMode: 'watchingTour',
        emojiId: 'whale', // 🐋
      };
    case 'turtle':
      return {
        defaultDiveMode: 'watchingTour',
        emojiId: 'turtle', // 🐢
      };
    case 'dolphin':
      return {
        defaultDiveMode: 'watchingTour',
        emojiId: 'dolphin', // 🐬
      };
    case 'biology':
      return {
        defaultDiveMode: undefined,
        emojiId: 'microscope', // 🔬
      };
    case 'ccr': // Closed-Circuit Rebreather
      return {
        defaultDiveMode: undefined,
        emojiId: 'hammer_and_wrench', // 🛠️
      };
    case 'photo':
      return {
        defaultDiveMode: undefined,
        emojiId: 'camera', // 📷
      };
    case 'tek': // Technical diving
      return {
        defaultDiveMode: undefined,
        emojiId: 'wrench', // 🔧
      };
    case 'afterwork':
      return {
        defaultDiveMode: undefined,
        emojiId: 'beer', // 🍺
      };
    case 'private':
      return {
        defaultDiveMode: undefined,
        emojiId: 'lock', // 🔒
      };

    default: {
      return {
        defaultDiveMode: undefined,
        emojiId: undefined,
      };
    }
  }
}
