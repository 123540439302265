import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { appLogger } from 'src/business/_core/modules/root/logger';

export function useAppRouter() {
  const history = useHistory();

  const navigate = useCallback(
    (path: string, { cause }: { cause: string }) => {
      appLogger.info(
        `[useAppRouter] navigate: ${path}${cause ? ` (cause: ${cause})` : ''}`,
      );
      history?.push(path);
    },
    [history],
  );

  const navigateAfterDelay = useCallback(
    (path: string, options: { cause: string }) => {
      setTimeout(() => navigate(path, options), 1000);
    },
    [navigate],
  );

  const goBack = useCallback(
    ({ cause }: { cause?: string } = {}) => {
      appLogger.info(
        `[useAppRouter] navigate backward${cause ? ` (cause: ${cause})` : ''}`,
      );
      history.goBack();
    },
    [history],
  );

  // const scrollToTop = useCallback(() => {
  //   window.document.getElementById('app-scrollable-container')?.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: 'auto',
  //   });
  // }, []);

  const scrollToTop = useCallback(
    (
      scrollableContainerId = 'app-scrollable-container',
      options: {
        behavior?: ScrollOptions['behavior'];
      },
    ) => {
      const container = window.document.getElementById(scrollableContainerId);
      container?.scrollTo({
        top: 0,
        left: 0,
        behavior: options.behavior ?? 'auto',
      });
    },
    [],
  );

  return useMemo(
    () => ({
      navigate,
      navigateAfterDelay,
      goBack,
      scrollToTop,
    }),
    [goBack, navigate, navigateAfterDelay, scrollToTop],
  );
}
