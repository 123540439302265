import { ClubPurchasePackagePaymentStatus } from '@mabadive/app-common-model';

export const customerPaymentStatusFormatter = {
  formatCustomerPaymentStatus,
};

function formatCustomerPaymentStatus(status: ClubPurchasePackagePaymentStatus) {
  switch (status) {
    case 'done':
      return 'payé';
    case 'partial':
      return 'payé partiellement';
    case 'todo':
      return 'non payé';
  }
}
