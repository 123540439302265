import { jsonParser } from '@mabadive/app-common-services';
import { OutgoingHttpHeaders } from 'http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { GraphqlClientQueryType } from '../apollo/GraphqlClientQueryType.type';
import { rxApolloClient } from '../apollo/rxApolloClient.service';
import { GraphqlQuery } from './GraphqlQuery.model';
import { GraphqlQueryResult } from './GraphqlQueryResult.model';
import {
  MQueryDescription,
  QueryDescription,
  graphqlClientQueryBuilder,
} from './graphqlClientQueryBuilder.service';

export const graphqlClientQueryRunner = {
  runOne,
  runMany,
};

function runOne<T>(
  queryDescription: QueryDescription<any>,
  {
    type,
    headers,
    name,
  }: {
    type: GraphqlClientQueryType;
    headers?: OutgoingHttpHeaders;
    name?: string;
  } = {
    type: 'query',
    headers: undefined,
  },
): Observable<T> {
  const query = graphqlClientQueryBuilder.buildOne<T, any>(queryDescription, {
    type,
  });

  return run<T>(query, {
    type,
    headers,
    name: name ?? queryDescription.queryName,
  }).pipe(
    catchError((err) => {
      appLogger.warn('[graphqlClientQueryRunner] error runMany', err);
      return throwError(err);
    }),
  );
}

function runMany<T>(
  queries: MQueryDescription<any>[],
  {
    type,
    name,
  }: {
    type: 'query'; // can not run multiple subscriptions (single root element required: https://spec.graphql.org/draft/#sec-Single-root-field)
    headers?: OutgoingHttpHeaders;
    name: string;
  },
): Observable<T> {
  const query = graphqlClientQueryBuilder.buildMany<T>(queries, { type });

  return run<T>(query, {
    type,
    headers: undefined,
    name,
  }).pipe(
    catchError((err) => {
      appLogger.warn('[graphqlClientQueryRunner] error runMany', err);
      return throwError(err);
    }),
  );
}

function run<T>(
  query: GraphqlQuery<any>,
  {
    type,
    headers,
    name,
  }: {
    type: GraphqlClientQueryType;
    headers: OutgoingHttpHeaders;
    name: string;
  },
): Observable<T> {
  return rxApolloClient
    .fetch<GraphqlQueryResult<T>>(query.queryString, {
      type,
      headers,
      name,
    })
    .pipe(
      // tap(x => appLogger.warn('[graphqlClientQueryRunner] x', x)),
      catchError((err) => {
        appLogger.warn('[graphqlClientQueryRunner] error run', err);
        return throwError(err);
      }),
      map((x) => query.mapResult(x)),
      map((x) => parseJSONDates<T>(x)),
    );
}

function parseJSONDates<T>(item: T): T {
  return jsonParser.parseJSONWithDates<T>(JSON.stringify(item));
}
