import { ClubStaffMemberJobType } from '@mabadive/app-common-model';

export const clubStaffMemberJobTypeFormatter = {
  formatJobType,
};

function formatJobType(jobType: ClubStaffMemberJobType): string {
  switch (jobType) {
    case 'casual':
      return 'Occasionnel / freelance';
    case 'regular':
      return 'Régulier / salarié';
  }
}
