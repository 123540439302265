/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubPublicSettingsCustomerSpaceConsent,
  CustomerCreateOnlineBooking,
  OnlineBookingCustomerConsent,
  OnlineBookingDetails,
  OnlineBookingOrderArticleFull,
  buildDefaultRGPDAgreementMessage,
} from '@mabadive/app-common-model';
import {
  onlineBookingOrderArticleFullBuilder,
  uuidGenerator,
} from '@mabadive/app-common-services';
import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { useAppCurrencyMain } from '../../../pages';
import { customerOnlineBookingClient } from '../../_core/data/app-operation';
import { useAppRouter } from '../../_core/data/hooks/useAppRouter';
import { appLogger } from '../../_core/modules/root/logger';
import { uiStore } from '../../_core/store';
import { authenticationClient } from '../../auth/services';
import { useCustomerEcommerceDataLoadedContent } from '../DiveCenterEcommerceHomePage/_data';
import { diveCenterEcommerceHomePageLocalStateUrlManager } from '../DiveCenterEcommerceHomePage/_services';
import { useDiveCenterShoppingCart } from './DiveCenterShoppingCart';
export function useDiveCenterShoppingCartPageLocalState({
  clubPublicReference,
  context,
}: {
  clubPublicReference: string;
  context: 'shopping-cart' | 'shopping-card-submit';
}) {
  const { loadedContent, loadableContent, refetch } =
    useCustomerEcommerceDataLoadedContent({
      clubPublicReference,
    });
  const { cart, actions: cartActions } = useDiveCenterShoppingCart();

  const content = loadedContent;

  const orderArticlesFull: OnlineBookingOrderArticleFull[] = useMemo(
    () =>
      onlineBookingOrderArticleFullBuilder.buildArticles({
        orderArticles: cart?.orderArticles,
        productArticlesFull: content?.productArticlesFull,
      }),
    [cart.orderArticles, content?.productArticlesFull],
  );

  const appRouter = useAppRouter();

  const clubSettings = loadedContent?.clubResume?.clubSettings;
  const clubReference = loadedContent?.clubResume?.club?.reference;
  const diveCenters = loadedContent?.clubResume?.diveCenters;
  const clubPublicSettings = clubSettings?.publicSettings;
  const mainCurrency = useAppCurrencyMain({ clubPublicSettings });

  const appUiTheme = clubPublicSettings?.appUiTheme;

  const diveCenter = diveCenters?.length ? diveCenters[0] : undefined;
  const clubPublicData = clubSettings?.publicData;
  const diveCenterPublicData = diveCenter?.publicData;
  const diveCenterId = diveCenter?._id;

  const location = useLocation();
  const queryParams =
    diveCenterEcommerceHomePageLocalStateUrlManager.getQueryParameters();

  const documentTitle = useMemo(() => {
    const baseName = diveCenter?.publicData?.brand?.name;
    const chunks = [baseName];
    chunks.push(
      context === 'shopping-cart' ? 'Mon panier' : 'Validation commande',
    );
    return chunks.filter(Boolean).join(' - ');
  }, [context, diveCenter?.publicData?.brand?.name]);

  document.title = documentTitle;

  const consents: ClubPublicSettingsCustomerSpaceConsent[] = useMemo(() => {
    const consents: ClubPublicSettingsCustomerSpaceConsent[] = [];
    if (clubPublicSettings?.customerSpace?.consents?.gdpr?.enabled) {
      consents.push({
        ...clubPublicSettings?.customerSpace?.consents?.gdpr,
        type: 'gdpr',
        consentAgreement: {
          type: 'gdpr',
          creationDate: new Date(),
          _version: 1,
          version: '1.0.0',
          message: buildDefaultRGPDAgreementMessage({
            brandFullName: clubPublicData?.brand?.name,
            contactEmailAddress: clubPublicData?.contact?.emailAddress,
          }),
        },
      });
    }
    return consents;
  }, [
    clubPublicData?.brand?.name,
    clubPublicData?.contact?.emailAddress,
    clubPublicSettings?.customerSpace?.consents?.gdpr,
  ]);

  const consentsToAccept: OnlineBookingCustomerConsent[] = useMemo(() => {
    return consents
      .map((consent) => {
        const customerConsents = cart.consentDetails?.customerConsents ?? [];
        const customerConsent = customerConsents.find(
          (x) => x.type === consent.type,
        );
        if (!customerConsent?.accepted) {
          if (customerConsent) {
            return customerConsent; // on modifie le consentement refusé (pas possible actuellement)
          }
          const newCustomerConsent: OnlineBookingCustomerConsent = {
            type: consent.type,
            accepted: false,
            version: consent.consentAgreement?.version,
            acceptedDate: null,
          };
          return newCustomerConsent;
        }
        return null;
      })
      .filter((x) => !!x);
  }, [consents, cart.consentDetails?.customerConsents]);

  const [operationInProgress, setOperationInProgress] =
    useState<{
      type: 'loading' | 'persist';
      message: string;
    }>();

  const [currentView, setCurrentView] =
    useState<'resume' | 'consent'>('resume');

  const persistBooking = useCallback(async () => {
    // on persiste

    if (operationInProgress) {
      return;
    }

    setOperationInProgress({
      type: 'persist',
      message: 'Enregistrement en cours...',
    });

    // TODO il faut vider le panier local

    try {
      let bookingParticipants = cart.bookingDetails?.bookingParticipants ?? [];

      const bookingDetails: OnlineBookingDetails = cart.bookingDetails;
      const booking: CustomerCreateOnlineBooking = {
        onlineBookingId: uuidGenerator.random(),
        clubReference,
        diveCenterId,
        orderArticles: cart.orderArticles,
        bookingDetails: bookingDetails,
        actionSteps: [
          ...cart.actionSteps,
          {
            date: new Date(),
            label: 'Confirm booking',
            meta: { bookingDetails },
          },
        ], // TODO faire remonter les étapes d'inscriptions
      };
      const { targetLink, authToken } =
        await customerOnlineBookingClient.createOnlineBooking({
          booking,
        });

      if (authToken && targetLink) {
        await authenticationClient.authenticateByToken(authToken).toPromise();
        appRouter.navigateAfterDelay(targetLink.targetRelativeUrl, {
          cause: 'online-booking',
        });
        setTimeout(() => {
          // on vide le panier après un timeout, pour ne pas avoir un "saut visuel" sur la page à cause du panier vide
          cartActions.clearCart({
            cause: 'submit-online-booking',
          });
        }, 1000);
        // NOTE: on ne ré-initialise pas setOperationInProgress car on redirige
      } else {
        setOperationInProgress(undefined);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      appLogger.error(
        'Erreur innatendue lors de la création du paiement Stripe',
      );
      uiStore.snackbarMessage.set({
        type: 'error',
        content: 'Erreur innatendue lors de la création du paiement!',
      });
      setOperationInProgress(undefined);
    }
  }, [
    appRouter,
    cart.actionSteps,
    cart.bookingDetails,
    cart.orderArticles,
    cartActions,
    clubReference,
    diveCenterId,
    operationInProgress,
  ]);
  const confirmOrder = useCallback(async () => {
    if (consentsToAccept?.length > 0) {
      // si il reste des consentements à accepter, on les affiche
      // TODO à activer quand ça sera prêt, refactor d'abord du même traitement côté renseignement de sa fiche
      // setCurrentView('consent');

      // sinon, on enregistre
      await persistBooking();
    } else {
      // sinon, on enregistre
      await persistBooking();
    }
  }, [consentsToAccept, persistBooking]);
  return {
    state: {
      clubReference,
      diveCenterId,
      loadableContent,
      mainCurrency,
      cart,
      operationInProgress,
      currentView,
      consentsToAccept,
    },
    actions: {
      refetch,
      cartActions,
      confirmOrder,
      persistBooking,
    },
    data: {
      ...(loadedContent ?? {}),
      clubSettings,
      clubPublicSettings,
      clubPublicReference,
      diveCenter,
      clubPublicData,
      diveCenterPublicData,
      appUiTheme,
      orderArticlesFull,
    },
  };
}
export type DiveCenterShoppingCartPageLocalState = ReturnType<
  typeof useDiveCenterShoppingCartPageLocalState
>;
