import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';

export const AppFormLabel: React.FC<{
  id?: string;
  required?: boolean;
  label: React.ReactNode;
  labelFontSize?: string;
  requiredStyle?: 'show-optional-label';
  hasError?: boolean;
  disabled?: boolean;
  helpDescription?: string;
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}> = ({
  id,
  label,
  labelFontSize = 'text-xs',
  required,
  requiredStyle,
  hasError,
  disabled,
  helpDescription,
  className = '',
  children,
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(undefined);
  const toggleTooltip = useCallback(() => {
    if (helpDescription) {
      // une fois passé en mode "manuel" (activation au clic), il le reste
      setIsTooltipVisible(!isTooltipVisible);
    }
  }, [helpDescription, isTooltipVisible]);

  const labelComponent = (
    <label
      className={clsx(
        'mb-1 font-normal uppercase block text-left truncate',
        labelFontSize,
        hasError
          ? 'text-status-error'
          : disabled
          ? 'text-gray-400 italic'
          : 'text-gray-500 ',
      )}
      htmlFor={id}
    >
      {label}{' '}
      {required ? (
        <span className="text-red-400">*</span>
      ) : requiredStyle === 'show-optional-label' ? (
        <span className="text-gray-400 font-normal">({'facultatif'})</span>
      ) : null}
    </label>
  );

  return (
    <div className={`focus:outline-none ${className}`}>
      {label && (
        <div
          className={clsx(helpDescription && 'cursor-pointer')}
          onClick={() => toggleTooltip()}
        >
          {helpDescription ? (
            <Tippy
              visible={isTooltipVisible}
              disabled={!helpDescription?.trim()}
              delay={[100, 100]}
              placement="right"
              content={helpDescription}
              onClickOutside={() => setIsTooltipVisible(false)}
            >
              {labelComponent}
            </Tippy>
          ) : (
            labelComponent
          )}
        </div>
      )}
      {children}
    </div>
  );
};
