import { getEnvIdFromUrl } from '../getEnvIdFromUrl.service';
import { MabadiveProWebConfig } from '../model';

const envId = getEnvIdFromUrl();

const isEnvAlpha = envId === 'alpha';
const isEnvNext = envId === 'next';

const isEnvProd = envId === 'prod';
const isEnvDemo =
  envId === 'demo468' || envId === 'demo976' || envId === 'secours';

const appVersion = process.env.REACT_APP_MABADIVE_TAG;
const cdnUrl = 'https://mabadive.fra1.cdn.digitaloceanspaces.com';

const publicWebUrl =
  envId !== 'prod' ? `https://${envId}.mabadive.com` : 'https://mabadive.com';

const docsWebUrl =
  envId !== 'prod'
    ? `https://docs.${envId}.mabadive.com`
    : 'https://docs.mabadive.com';

const diverWebUrl =
  envId !== 'prod'
    ? `https://diver.${envId}.mabadive.com`
    : 'https://diver.mabadive.com';

export const mabadiveDiverWebConfig_DIST: MabadiveProWebConfig = {
  envId,
  appVersion,
  applications: {
    publicWebUrl,
    docsWebUrl,
    diverWebUrl,
    cdnUrl,
  },
  debug: {
    showMediaSize: false,
    checkNewVersion: true,
    showAppVersion: !isEnvProd && !isEnvNext,
    showEnvId: !isEnvProd && !isEnvNext,
    isEnvDemoOrTest: isEnvDemo || isEnvAlpha,
    showErrorStackTrace: isEnvAlpha,
    debugStore: false,
    autoFill: false,
  },
  authentication: {},
  data: {
    api: {
      baseUrl: '/api',
    },
    graphql: {
      enabled: true,
      context: 'v1/graphql',
      httpProtocol: 'https',
      wsProtocol: 'wss',
    },
  },
  log: {
    level: 'error',
    sentry: {
      enabled: isEnvProd || isEnvNext,
      configString:
        'https://a2ea92e9fa3a4dca96d79bc022757310@o300584.ingest.sentry.io/4503925953724416',
    },
    console: {
      appRules: {
        // console logger levels
        app: {
          level: !isEnvProd && !isEnvNext ? 'info' : 'warn',
          modules: {
            // 'app-common-browser': 'warn',
            // 'security': 'info',
            // 'patch': 'info',
          },
        },
      },
    },
  },
  c: {
    e: [['contact'], ['mabadive', 'com']],
    p: ['+33', '7', '44', '70', '30', '55'],
  },
};
