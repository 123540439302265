import { DiveServiceTrainingCommercialCategoryId } from '@mabadive/app-common-model';

export const trainingCommercialCategoryFormatter = {
  formatCommercialCategory,
};

function formatCommercialCategory(
  commercialCategoryId: DiveServiceTrainingCommercialCategoryId,
): string {
  switch (commercialCategoryId) {
    case 'discover': {
      return 'découverte';
    }
    case 'beginner': {
      return 'débutant';
    }
    case 'advanced': {
      return 'avancé';
    }
    case 'tek': {
      return 'tek';
    }
    case 'specialty': {
      return 'spécialité';
    }
    case 'instructor': {
      return 'enseignement';
    }
    case 'security': {
      return 'sécurité';
    }
    case 'other': {
      return 'autres';
    }
  }
}
