import {
  CANCEL_CLUB_TAG,
  CANCEL_DIVER_TAG,
  ClubDiver,
  ClubParticipant,
  ClubPublicCustomer,
  ColoredTag,
  DiveSession,
  PARTICIPANT_BIRTHDAY_TAG,
  PARTICIPANT_COMMENT_TAG,
  ParticipantActionDescription,
  ParticipantBookingStatus,
  WAITING_INFO_CLUB_TAG,
  WAITING_INFO_DIVER_TAG,
} from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';

export const participantTagsBuilder = {
  getParticipantTags,
  getParticipantBookingStateTag,
  getParticipantSpecialDiveTypeTag,
};

function getParticipantTags({
  club,
  participant,
  diver,
  diveSession,
  includeComment,
  includeBookingState,
}: {
  club: ClubPublicCustomer;
  participant: Pick<ClubParticipant, 'tags' | 'comment' | 'bookingState'>;
  diver: Pick<ClubDiver, 'birthdate'>;
  diveSession: Pick<DiveSession, 'time'>;
  includeComment: boolean;
  includeBookingState: boolean;
}): ColoredTag[] {
  if (!participant || !club || !diveSession) {
    return [];
  }
  const tags: ColoredTag<string>[] = [];

  if (includeBookingState && participant.bookingState) {
    const bookingStateTag = getParticipantBookingStateTag({
      bookingState: participant.bookingState,
    });
    if (bookingStateTag) {
      tags.push(bookingStateTag);
    }
  }

  if (diver && diver.birthdate) {
    if (dateService.isBirthday(diver.birthdate, diveSession.time)) {
      tags.push(PARTICIPANT_BIRTHDAY_TAG);
    }
  }

  if (includeComment && participant.comment) {
    tags.push(PARTICIPANT_COMMENT_TAG);
  }

  return tags;
}

export function getParticipantBookingStateTag({
  bookingState,
}: {
  bookingState: ParticipantActionDescription<ParticipantBookingStatus>;
}): ColoredTag {
  if (!bookingState) {
    return;
  }

  if (bookingState.value === 'pending') {
    if (bookingState.user === 'club') {
      return WAITING_INFO_CLUB_TAG;
    } else {
      return WAITING_INFO_DIVER_TAG;
    }
  } else if (bookingState.value === 'cancelled') {
    if (bookingState.user === 'club') {
      return CANCEL_CLUB_TAG;
    } else {
      return CANCEL_DIVER_TAG;
    }
  }

  return;
}

function getParticipantSpecialDiveTypeTag({
  participant,
}: {
  participant: Pick<ClubParticipant, 'details'>;
}): ColoredTag {
  // TODO utiliser participantSpecialDiveTypeFormatter
  if (participant?.details?.specialDiveType) {
    switch (participant?.details?.specialDiveType) {
      case 'rehabilitation': {
        return {
          reference: 'rehabilitation',
          avatar: {
            label: 'R',
            color: '#fff',
            background: '#ee1111',
            appIcon: 'rehabilitation',
          },
          content: {
            label: 'Réadaptation',
            color: '#fff',
            background: '#ee1111',
          },
          // tooltip:'Plongée de réadaptation'
        };
      }
      case 'technical': {
        return {
          reference: 'technical',
          avatar: {
            label: 'T',
            color: '#fff',
            background: '#ee1111',
            appIcon: 'special-dive',
          },
          content: {
            label: 'Technique',
            color: '#fff',
            background: '#ee1111',
          },
        };
      }
    }
  }
}
