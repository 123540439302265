import { ClubPaymentPlatformLinkInfo } from '@mabadive/app-common-model';
import { apiClient } from 'src/_common-browser';

export const customerPaymentPlatformClient = {
  getPaymentLink,
};

function getPaymentLink({
  clubPurchasePaymentId,
}: {
  clubPurchasePaymentId: string;
}): Promise<ClubPaymentPlatformLinkInfo> {
  return apiClient
    .put<any>('/customer/payment-platforms/get-payment-link', {
      authenticate: true,
      json: {
        clubPurchasePaymentId,
      },
    })
    .toPromise();
}
