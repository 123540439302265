import { ObjectMap } from './ObjectMap.model';

export const objectMapReader = {
  findByCriteria,
  findByFilter,
  findManyByCriteria,
  findManyByFilter,
  isCriteriaMapKey,
  identifyByCriteria,
};

function findByCriteria<T>(map: ObjectMap<T>, { criteria, keyAttribute }: {
  criteria?: Partial<T>;
  keyAttribute: keyof T;
}): T {
  if (!criteria) {
    return undefined;
  }

  if (isCriteriaMapKey({ criteria, keyAttribute })) {
    // criteria is map key: don't browse the map but return the value immediately instead
    const key = criteria[keyAttribute] as unknown as string;
    return map[key];
  }
  return Object.values(map).find((item) => identifyByCriteria(criteria, item));
}

function findByFilter<T>(map: ObjectMap<T>, { filter, keyAttribute }: {
  filter?: (x: T) => boolean;
  keyAttribute: keyof T;
}): T {
  if (!filter) {
    return undefined;
  }

  return Object.values(map).find((item) => filter(item));
}

function findManyByCriteria<T>(map: ObjectMap<T>, { criteria, keyAttribute }: {
  criteria?: Partial<T>;
  keyAttribute: keyof T;
}): T[] {
  if (!criteria) {
    return Object.values(map);
  }
  if (isCriteriaMapKey({ criteria, keyAttribute })) {
    // criteria is map key: don't browse the map but return the value immediately instead
    const key = criteria[keyAttribute] as unknown as string;
    return [map[key]];
  }
  return Object.values(map).filter((item) => identifyByCriteria(criteria, item));
}

function findManyByFilter<T>(map: ObjectMap<T>, { filter, keyAttribute }: {
  filter?: (x: T) => boolean;
  keyAttribute: keyof T;
}): T[] {
  if (!filter) {
    return Object.values(map);
  }
  return Object.values(map).filter((item) => filter(item));
}

function isCriteriaMapKey<T>({ criteria, keyAttribute }: {
  criteria: Partial<T>;
  keyAttribute: keyof T;
}) {
  const criteriaKeys = Object.keys(criteria);
  if (criteriaKeys.length === 1 && criteriaKeys[0] === keyAttribute) {
    // citeria is exactly Map KEY
    return true;
  }
  return false;
}

function identifyByCriteria<T>(criteria: Partial<T>, item: T): boolean {
  return Object.keys(criteria).reduce((match, attributeName) => {
    if (match) {
      const a1 = (item as any)[attributeName] as any;
      const a2 = (criteria as any)[attributeName] as any;
      return a1 === a2;
    }
    return match;
  }, true as boolean);
}
