/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppAuthenticatedCustomerAuthorizations,
  AppInquiryFullGql_Customer,
  AppInquiryResponse,
  AppInquiryResponse_Consents,
  AppInquiryResponse_Question,
  AppInquiryResponse_Section,
  AppInquiryResponse_Status,
  ClubDiverConsentAcceptance,
  CustomerInquiryResponsePayload,
  CustomerInquiryResponseState,
  INQUIRY_RESPONSE_EDITABLE_STATUSES,
} from '@mabadive/app-common-model';
import {
  commonEntityBuilder,
  jsonPatcher,
} from '@mabadive/app-common-services';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router';
import { LoadableContent } from 'src/business/_core/data/app-loading';
import { useAppRouter } from 'src/business/_core/data/hooks/useAppRouter';
import { DiverBookingCriteria } from 'src/business/_core/data/store-repository/diver-booking/model';
import {
  useAppSecurityUser,
  useAppSecurityUserDiverAuthorizations,
} from 'src/business/auth/services';
import { customerInquiryClient } from '../../../_core/data/app-operation/inquiries';

import { useDefaultConsentAcceptance } from '../../components';
import {
  ClubCustomerConsent_Config,
  useCustomerConsentConfig,
} from '../../components/useCustomerConsentConfig';
import {
  DiveCenterBookingPageAggregatedBookingResume,
  DiveCenterBookingPageLoadedContent,
} from '../DiveCenterBookingPage/model';
import { diveCenterBookingPageAggregatedBookingResumeBuilder } from '../DiveCenterBookingPage/services';
import { useDiveCenterBookingPageLoadedContent } from '../DiveCenterBookingPage/useDiveCenterBookingPageLoadedContent.hook';
import { diveCenterBookingUrlBuilder } from '../diveCenterBookingUrlBuilder.service';
import {
  DiveCenterBookingInquiryResponsePage_EditMode,
  DiveCenterBookingInquiryResponsePage_FormModel,
} from './model';
import { DiveCenterBookingInquiryResponsePage_ViewMode } from './model/DiveCenterBookingInquiryResponsePage_ViewMode.type';
import { useDiveCenterBookingInquiryResponsePage_FetchData } from './useDiveCenterBookingInquiryResponsePage_FetchData.hook';
export type DiveCenterBookingInquiryResponsePageLocalState = ReturnType<
  typeof useDiveCenterBookingInquiryResponsePageLocalState
>;

export function useDiveCenterBookingInquiryResponsePageLocalState({
  bookingId,
  diverId,
  inquiryId,
  inquiryResponseId: inquiryResponseIdParam,
}: DiverBookingCriteria & {
  inquiryId: string;
  inquiryResponseId?: string; // si c'est une mise à jour
}) {
  const securityUser = useAppSecurityUser();
  const az: AppAuthenticatedCustomerAuthorizations =
    useAppSecurityUserDiverAuthorizations();

  const history = useHistory();

  const appRouter = useAppRouter();

  const {
    content: loadedContent,
    ...loadableContent
  }: LoadableContent<DiveCenterBookingPageLoadedContent> = useDiveCenterBookingPageLoadedContent(
    {
      bookingId,
      diverId,
    },
  );

  const [inquiryResponseId, setInquiryResponseId] = useState<string>(
    inquiryResponseIdParam,
  );

  const { data, updateData, status } =
    useDiveCenterBookingInquiryResponsePage_FetchData({
      inquiryId,
      inquiryResponseId,
    });

  const { inquiry, inquiryResponse } = data;

  const [editMode, setEditMode] =
    useState<DiveCenterBookingInquiryResponsePage_EditMode>(
      !!inquiryResponseId ? 'update' : 'create',
    );

  const [isSubmited, setIsSubmited] = useState(false);

  const [bookingMembersIdsInitialOrder, setBookingMembersIdsInitialOrder] =
    useState<string[]>();

  const { dataCore, aggregatedDataCore } =
    diveCenterBookingPageAggregatedBookingResumeBuilder.aggregatedDataCore({
      loadedContent,
      bookingMembersIdsInitialOrder,
      securityUser,
    });

  const [viewState, setViewStateInner] = useState<{
    mode: DiveCenterBookingInquiryResponsePage_ViewMode;
    participantsIndex?: number; // if (mode === 'checkout-edit-members')
  }>({
    mode: 'fill-form',
  });

  const setViewState = useCallback(
    (args: { mode: DiveCenterBookingInquiryResponsePage_ViewMode }) => {
      setViewStateInner(args);
    },
    [],
  );

  const initialBookingResume: DiveCenterBookingPageAggregatedBookingResume =
    useMemo(
      () =>
        (aggregatedDataCore?.bookingResumes ?? []).find(
          (x) => x.booking?._id === bookingId,
        ),
      [aggregatedDataCore?.bookingResumes, bookingId],
    );

  // const inquiriesByMember = useMemo(
  //   () =>
  //     cuAppInquiriesByMemberBuilder.buildInquiriesByMemberModel({
  //       aggregatedBookingResume: initialBookingResume,
  //       clubResume: initialBookingResume?.clubResume,
  //     }),
  //   [initialBookingResume],
  // );

  // const inquiry = inquiriesByMember
  //   ?.find((x) => x.bookingMemberFull.bookingMember._id === bookingMemberId)
  //   ?.activeInquiries?.find((x) => x._id === inquiryId);

  const aggregatedBookingResume = useMemo(
    () =>
      aggregatedDataCore?.bookingResumes.find(
        (x) => x.booking?._id === initialBookingResume?.booking?._id,
      ),
    [aggregatedDataCore?.bookingResumes, initialBookingResume?.booking?._id],
  );
  const clubSettings = aggregatedBookingResume?.clubResume?.clubSettings;
  const clubResume = aggregatedBookingResume?.clubResume;

  const consentConfig_GDPR: ClubCustomerConsent_Config =
    useCustomerConsentConfig({
      type: 'gdpr',
      clubSettings,
      inquirySettings: inquiry?.settings,
    });

  const defaultConsent_GDPR: ClubDiverConsentAcceptance =
    useDefaultConsentAcceptance({
      consentConfig: consentConfig_GDPR,
    });

  const consentConfig_ValidInfo: ClubCustomerConsent_Config =
    useCustomerConsentConfig({
      type: 'valid-info',
      clubSettings,
      inquirySettings: inquiry?.settings,
    });

  const defaultConsent_ValidInfo: ClubDiverConsentAcceptance =
    useDefaultConsentAcceptance({
      consentConfig: consentConfig_ValidInfo,
    });

  const initialFormValue: DiveCenterBookingInquiryResponsePage_FormModel =
    useMemo(() => {
      return buildDefaultFormValue({
        inquiry,
        inquiryResponse,
        defaultConsent_GDPR,
        defaultConsent_ValidInfo,
        editMode,
      });
    }, [
      inquiry,
      inquiryResponse,
      defaultConsent_GDPR,
      defaultConsent_ValidInfo,
      editMode,
    ]);

  const form = useForm<DiveCenterBookingInquiryResponsePage_FormModel>({
    values: initialFormValue, // se met à jour si initialFormValue change
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const formValue = useWatch({
    control: form.control,
  }) as unknown as DiveCenterBookingInquiryResponsePage_FormModel;

  const { scrollToTop: scrollToTopCore } = useAppRouter();
  const scrollToTop = useCallback(() => {
    scrollToTopCore('app-page-content-container', {});
    // window.document.getElementById('app-page-content-container')?.scrollTo({
    //   top: 0,
    //   left: 0,
    //   behavior: 'auto',
    // });
  }, [scrollToTopCore]);

  const backToBookingHome = useCallback(() => {
    const url = diveCenterBookingUrlBuilder.buildBookingHomeUrl({
      clubPublicReference: clubResume?.clubSettings?.clubPublicReference,
      bookingId: aggregatedBookingResume?.booking?._id,
    });
    appRouter.navigate(url, {
      cause: 'back from inquiry response',
    });
  }, [
    appRouter,
    aggregatedBookingResume?.booking?._id,
    clubResume?.clubSettings?.clubPublicReference,
  ]);
  useEffect(() => {
    if (
      inquiryResponse?.status &&
      !INQUIRY_RESPONSE_EDITABLE_STATUSES.includes(inquiryResponse?.status) &&
      aggregatedBookingResume?.booking?._id
    ) {
      backToBookingHome();
    }
  }, [
    aggregatedBookingResume?.booking?._id,
    backToBookingHome,
    inquiryResponse?.status,
  ]);
  const submitInquiryResponse = useCallback(
    async ({
      formValue,
      status,
      nextAction,
    }: {
      formValue: DiveCenterBookingInquiryResponsePage_FormModel;
      status: Extract<AppInquiryResponse_Status, 'submitted' | 'draft'>;
      nextAction: 'back-to-booking' | 'go-to-confirm-form';
    }) => {
      if (editMode === 'create') {
        const newInquiryResponse: AppInquiryResponse =
          commonEntityBuilder.buildEntity<AppInquiryResponse>({
            bookingId,
            diverId,
            diveCenterId: aggregatedBookingResume?.booking?.diveCenterId,
            inquiryId: inquiry?._id,
            inquiryDetails: formValue.inquiryDetails,
            responseDetails: formValue.responseDetails,
            status,
            // consents: formValue.consents,
            // signature: formValue.signature,
          });
        if (status === 'submitted') {
          newInquiryResponse.submitDate = new Date();
        }
        const updateState: CustomerInquiryResponseState = {
          appInquiryResponse: {
            created: [newInquiryResponse],
          },
        };
        const payload: CustomerInquiryResponsePayload = {
          isDraft: false,
          updateState,
        };
        const { inquiryResponse } =
          await customerInquiryClient.postInquiryResponse(payload);
        if (inquiryResponse?._id) {
          updateData.inquiryResponse.create(inquiryResponse); // est-ce utile, vu qu'on met à jour le paramètre?

          // update url params
          const params = new URLSearchParams(window.location.search);
          params.set('inquiryResponseId', inquiryResponse._id);

          history.replace({
            pathname: window.location.pathname,
            search: params.toString(),
          });

          setInquiryResponseId(inquiryResponse._id);
          setEditMode('update');
        }
      } else if (editMode === 'update') {
        const patchOperations = jsonPatcher.compareObjects<AppInquiryResponse>(
          inquiryResponse,
          {
            ...inquiryResponse,
            ...formValue,
            status: 'submitted',
            submitDate: new Date(),
          },
          {
            // else, value won't be deleted by typeorm
            // https://github.com/typeorm/typeorm/issues/2934
            replaceDeleteByNullValue: false,
            attributesToReplaceFully: [
              'inquiryDetails',
              'responseDetails',
              'consents',
              'signature',
            ],
          },
        );
        if (patchOperations.length) {
          const updateState: CustomerInquiryResponseState = {
            appInquiryResponse: {
              updated: [
                {
                  pk: inquiryResponse._id,
                  patchOperations,
                },
              ],
            },
          };
          const payload: CustomerInquiryResponsePayload = {
            isDraft: false,
            updateState,
          };
          const { inquiryResponse: updatedInquiryResponse } =
            await customerInquiryClient.postInquiryResponse(payload);
        }
        if (nextAction === 'back-to-booking') {
          backToBookingHome();
        } else if (nextAction === 'go-to-confirm-form') {
          setViewState({
            mode: 'confirm-form',
          });
        }
      }
    },
    [
      aggregatedBookingResume?.booking?.diveCenterId,
      backToBookingHome,
      bookingId,
      diverId,
      editMode,
      history,
      inquiry?._id,
      inquiryResponse,
      setViewState,
      updateData.inquiryResponse,
    ],
  );

  return {
    state: {
      securityUser,
      az,
      viewState,
      setViewState,
      isSubmited,
      setIsSubmited,
      clubSettings,
      clubResume,
      form,
      formValue,
      consentConfig_GDPR,
      defaultConsent_GDPR,
      consentConfig_ValidInfo,
      defaultConsent_ValidInfo,
    },
    data: {
      ...data,
      loadableContent,
      aggregatedDataCore,
      initialBookingResume,
      aggregatedBookingResume,
    },
    actions: {
      scrollToTop,
      submitInquiryResponse,
      backToBookingHome,
    },
  };
}

function buildDefaultFormValue({
  inquiry,
  inquiryResponse,
  editMode,
  defaultConsent_GDPR,
  defaultConsent_ValidInfo,
}: {
  inquiry: AppInquiryFullGql_Customer;
  inquiryResponse?: AppInquiryResponse;
  editMode: DiveCenterBookingInquiryResponsePage_EditMode;
  defaultConsent_GDPR: ClubDiverConsentAcceptance;
  defaultConsent_ValidInfo: ClubDiverConsentAcceptance;
}) {
  const defaultConsents: AppInquiryResponse_Consents = {
    gdpr: defaultConsent_GDPR,
    validInfo: defaultConsent_ValidInfo,
  };
  if (editMode === 'create') {
    const value: DiveCenterBookingInquiryResponsePage_FormModel = {
      inquiryDetails: {
        name: inquiry?.name,
        type: inquiry?.type,
        settings: inquiry?.settings,
      },
      consents: defaultConsents,
      responseDetails: {
        creationDate: new Date(),
        languageCode: 'fr', // TODO gérer la localisation
        sections: (inquiry?.sections ?? []).map((s) => {
          const formSection: AppInquiryResponse_Section = {
            sectionId: s.sectionId,
            description: s.description,
            questions: s.questions.map((q) => {
              const formQuestion: AppInquiryResponse_Question = {
                questionId: q.questionId,
                description: q.description,
                answer: {
                  format: q.answerDetails.format,
                  formatOptions: q.answerDetails.formatOptions,
                },
              };
              return formQuestion;
            }),
          };
          return formSection;
        }),
      },
    };
    return value;
  } else if (editMode === 'update') {
    const value: DiveCenterBookingInquiryResponsePage_FormModel = {
      inquiryDetails: {
        name: inquiry?.name,
        type: inquiry?.type,
        settings: inquiry?.settings,
      },
      consents: defaultConsents, // on force à ré-accepter "valid-info" et "gdpr"

      signature: null, // on force à re-signer
      responseDetails: {
        ...inquiryResponse?.responseDetails,
      },
    };
    return value;
  }
}
