import { DiveSession } from '@mabadive/app-common-model';
import { clubBoatFormatter } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { IoBoat } from 'react-icons/io5';
import { useClubResumePublic } from 'src/business/_core/store';

export const ClubDiveSessionBoats = function <
  S extends Pick<DiveSession, 'boatsIds'>,
>({ diveSession: session }: { diveSession: S }) {
  const clubResume = useClubResumePublic();

  const boats = useMemo(() => {
    return session.boatsIds
      .map((boatId) => clubResume?.boats.find((b) => b._id === boatId))
      .filter((x) => !!x);
  }, [clubResume?.boats, session.boatsIds]);

  return boats.length > 0 ? (
    <div className={'flex items-center gap-1 sm:gap-2 overflow-hidden'}>
      {boats.map((boat) => (
        <div key={boat._id} className={'flex items-center gap-0.5 sm:gap-1'}>
          <IoBoat
            className="w-3 h-3 text-gray-500"
            style={{
              color: boat.color,
            }}
          />
          <div className={'font-bold truncate'}>
            {clubBoatFormatter.formatClubBoat(boat, { format: 'name' })}
          </div>
        </div>
      ))}
    </div>
  ) : null;
};
