import { StaffMemberResumeSessionsModel } from '@mabadive/app-common-model';
import { dataSorter } from '../../data';

export const staffMemberResumeSessionsSorter = {
  sortByName,
  sortByAvailability,
  sortByInitialSortIndex,
};

function sortByAvailability(
  staffMembersResumeSessions: StaffMemberResumeSessionsModel[],
  {
    staffFirstNameBefore,
  }: {
    staffFirstNameBefore: boolean;
  },
): StaffMemberResumeSessionsModel[] {
  return dataSorter.sortMultiple(staffMembersResumeSessions, {
    // TODO configurer le tri en paramètre
    getSortAttributes: (x: StaffMemberResumeSessionsModel) => {
      return [
        {
          value: x.activeOnAnySession,
          asc: false,
        },
        {
          value: x.availableOnAnySession,
          asc: false,
        },
        {
          value: x.inWorkingPeriod,
          asc: false,
        },
        {
          value: staffFirstNameBefore
            ? x.staffMember.profile.firstName
            : x.staffMember.profile.lastName,
          asc: true,
          type: 'full-text',
        },
        {
          value: staffFirstNameBefore
            ? x.staffMember.profile.lastName
            : x.staffMember.profile.firstName,
          asc: true,
          type: 'full-text',
        },
        {
          value: x.staffMember?._id,
          asc: true,
        },
      ];
    },
  });
}

function sortByName(
  staffMembersResumeSessions: StaffMemberResumeSessionsModel[],
  {
    staffFirstNameBefore,
  }: {
    staffFirstNameBefore: boolean;
  },
): StaffMemberResumeSessionsModel[] {
  return dataSorter.sortMultiple(staffMembersResumeSessions, {
    getSortAttributes: (x) => [
      {
        value: staffFirstNameBefore
          ? x.staffMember.profile.firstName
          : x.staffMember.profile.lastName,
        asc: true,
        type: 'full-text',
      },
      {
        value: staffFirstNameBefore
          ? x.staffMember.profile.lastName
          : x.staffMember.profile.firstName,
        asc: true,
        type: 'full-text',
      },
      {
        value: x.staffMember?._id,
        asc: true,
      },
    ],
  });
}
function sortByInitialSortIndex(
  staffMembersResumeSessions: StaffMemberResumeSessionsModel[],
  {
    initialStaffMemberIdsSorted,
  }: {
    initialStaffMemberIdsSorted: string[];
  },
): StaffMemberResumeSessionsModel[] {
  return dataSorter.sortMultiple(staffMembersResumeSessions, {
    getSortAttributes: (x) => [
      {
        value: initialStaffMemberIdsSorted.findIndex(
          (staffMemberId) => staffMemberId === x.staffMember._id,
        ),
        asc: true,
      },
    ],
  });
}
