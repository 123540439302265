import React from 'react';
import { dateServiceCore } from 'src/stories/services';
import { AppHackableCalendarVM } from '../model';
import { AppHackableCalendarWeeksNavPanel } from './AppHackableCalendarWeeksNavPanel';
import { AppHackableCalendarWeeksPanelDay } from './AppHackableCalendarWeeksPanelDay';
import { AppHackableCalendarWeeksPanelVM } from './model';
import { useAppHackableCalendarWeeksPanelVM } from './useAppHackableCalendarWeeksPanelVM.hook';

export const AppHackableCalendarWeeksPanel = (props: AppHackableCalendarVM) => {
  const vm: AppHackableCalendarWeeksPanelVM =
    useAppHackableCalendarWeeksPanelVM(props);
  return (
    <div className="w-full">
      <AppHackableCalendarWeeksNavPanel {...props} />
      <div className="text-left">
        <button
          type="button"
          className="inline uppercase text-xs mt-2 p-0.5 text-blue-400 hover:text-blue-500 rounded hover:bg-gray-100"
          onClick={() =>
            props.setViewDate(dateServiceCore.truncateTimeUTC(new Date()))
          }
        >
          Aujourd'hui
        </button>
      </div>
      <div className="mt-2 grid grid-cols-7 text-xs leading-6 text-gray-400 uppercase font-bold">
        {vm.headerDays.map((day, dayIndex) => (
          <div key={day.isoWeekDay}>{day.label.substring(0, 1)}</div>
        ))}
      </div>
      <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
        {vm.days.map((day, dayIndex) => (
          <AppHackableCalendarWeeksPanelDay
            key={day.date.getTime()}
            day={day}
            dayIndex={dayIndex}
            daysCount={vm.days.length}
            vm={props}
            customizeDay={props.props.customizeDay}
          />
        ))}
      </div>
    </div>
  );
};
