import { ExportSheetSettingsOrientation } from '@mabadive/app-common-model';

export const exportSheetSettingsOrientationFormatter = {
  formatOrientation,
};

function formatOrientation(
  orientation: ExportSheetSettingsOrientation,
): string {
  switch (orientation) {
    case 'landscape':
      return 'paysage';
    case 'portrait':
      return 'portrait';
  }
  return orientation;
}
