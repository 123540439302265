import React, { useEffect } from 'react';
import { useAppSecurityUser } from 'src/business/auth/services';
import { AppPageContainerWithFixedBars } from 'src/business/_core/modules/layout';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { DiveCenterBookingPaymentBackPageContent } from './DiveCenterBookingPaymentPageBackContent';
import {
  DiveCenterBookingPaymentBackPageLocalState,
  useDiveCenterBookingPaymentBackPageLocalState,
} from './useDiveCenterBookingPaymentBackPageLocalState.hook';

export const DiveCenterBookingPaymentBackPage = ({
  navigationContext,
}: {
  navigationContext: {
    clubPublicReference: string;
    bookingId: string;
  };
}) => {
  const securityUser = useAppSecurityUser();

  const bookingId = navigationContext?.bookingId;
  const diverId = securityUser.diver?.diverId;

  const localState: DiveCenterBookingPaymentBackPageLocalState =
    useDiveCenterBookingPaymentBackPageLocalState({
      bookingId,
      diverId,
    });

  const { loadableContent, aggregatedData, aggregatedBookingResume } =
    localState;

  useEffect(() => {
    if (
      loadableContent.lastActionStatus === 'success' &&
      loadableContent.contentState === 'full'
    ) {
      if (!aggregatedBookingResume) {
        appLogger.warn(
          '[DiveCenterBookingPaymentBackPage] bookingResume not found, redirect back',
          aggregatedData,
        );
        // TODO redirectTo(backUrl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadableContent.contentState, loadableContent.lastActionStatus]);

  return (
    <AppPageContainerWithFixedBars
      contentState={loadableContent.contentState}
      lastActionStatus={loadableContent.lastActionStatus}
      marginBottom={true}
      paddingBottom={true}
      contentClassName="bg-gray-50 app-p-content"
    >
      {aggregatedBookingResume && (
        <DiveCenterBookingPaymentBackPageContent localState={localState} />
      )}
    </AppPageContainerWithFixedBars>
  );
};
