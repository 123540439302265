import {
  AppCompanyMessageContent,
  AppCompanyMessageContext,
  AppCompanyMessageLocalized,
  AppCompanyMessageTemplate,
  AppCompanyMessageTemplateDef,
} from '@mabadive/app-common-model';

export const clubDefaultMessageTemplatesBuilder = {
  buildMessageTemplatesFromDefault,
  buildMessageTemplateFromDefault,
  buildNewMessageTemplate,
};

function buildNewMessageTemplate(
  params: Pick<
    AppCompanyMessageTemplate,
    'clubReference' | 'diveCenterId' | 'context'
  >,
): AppCompanyMessageTemplate {
  const messageTemplate: AppCompanyMessageTemplate = {
    ...params,
    isMultiDiveCenter: false,
    name: 'Nouveau message',
    style: 'success',
    enabled: true,
    archived: false,
    sourceLanguageCode: 'fr',
    emailContent: {
      enabled: true,
      message: {
        title: '',
        body: '',
        languageCode: 'fr',
        signature: '',
      },
      format: 'text-only',
      target: 'email',
      translations: [],
      appendSignature: false,
    },
    smsContent: {
      enabled: false,
      message: {
        title: '',
        body: '',
        languageCode: 'fr',
        signature: '',
      },
      format: 'text-only',
      target: 'email',
      translations: [],
      appendSignature: false,
    },
    whatsAppContent: {
      enabled: false,
      message: {
        title: '',
        body: '',
        languageCode: 'fr',
        signature: '',
      },
      format: 'text-only',
      target: 'email',
      translations: [],
      appendSignature: false,
    },
  };
  return messageTemplate;
}

function buildMessageTemplatesFromDefault({
  templateDefs,
  clubReference,
  context,
}: {
  templateDefs: AppCompanyMessageTemplateDef[];
  clubReference: string;
  context: AppCompanyMessageContext;
}): AppCompanyMessageTemplate[] {
  return templateDefs.map((templateDef) => {
    const template: AppCompanyMessageTemplate = buildMessageTemplateFromDefault(
      {
        templateDef,
        clubReference,
        context,
      },
    );

    return template;
  });
}

function buildMessageTemplateFromDefault({
  templateDef,
  clubReference,
  context,
}: {
  templateDef: AppCompanyMessageTemplateDef;
  clubReference: string;
  context: AppCompanyMessageContext;
}) {
  const emailContent: AppCompanyMessageContent = buildEmailContent({
    templateDef,
  });
  const smsContent: AppCompanyMessageContent = buildSmsContent({
    templateDef,
  });
  const whatsAppContent: AppCompanyMessageContent = buildWhatsappContent({
    templateDef,
  });

  const template: AppCompanyMessageTemplate = {
    clubReference,
    diveCenterId: undefined,
    isMultiDiveCenter: true,
    archived: false,
    enabled: templateDef.enabled,
    name: templateDef.name,
    context,
    sourceLanguageCode: 'fr',
    smsContent,
    emailContent,
    whatsAppContent,
    style: templateDef.style,
  };
  return template;
}

function buildEmailContent({
  templateDef,
}: {
  templateDef: AppCompanyMessageTemplateDef;
}) {
  const content = templateDef.emailContent;
  const localizedMessageFr: AppCompanyMessageLocalized = {
    languageCode: 'fr',
    ...content,
  };
  const msgContent: AppCompanyMessageContent = {
    enabled: true,
    format: 'text-only',
    target: 'email',
    message: localizedMessageFr,
    translations: [],
    appendSignature: true,
  };
  return msgContent;
}

function buildSmsContent({
  templateDef,
}: {
  templateDef: AppCompanyMessageTemplateDef;
}) {
  const content = templateDef.smsContent;
  const localizedMessageFr: AppCompanyMessageLocalized = {
    languageCode: 'fr',
    ...content,
  };
  const msgContent: AppCompanyMessageContent = {
    enabled: true,
    format: 'text-only',
    target: 'sms',
    message: localizedMessageFr,
    translations: [],
    appendSignature: true,
  };
  return msgContent;
}

function buildWhatsappContent({
  templateDef,
}: {
  templateDef: AppCompanyMessageTemplateDef;
}) {
  const content = templateDef.whatsAppContent;
  const localizedMessageFr: AppCompanyMessageLocalized = {
    languageCode: 'fr',
    ...content,
  };
  const msgContent: AppCompanyMessageContent = {
    enabled: true,
    format: 'text-only',
    target: 'whatsapp',
    message: localizedMessageFr,
    translations: [],
    appendSignature: true,
  };
  return msgContent;
}
