import { Theme } from '@material-ui/core';
import { darken, lighten } from '@material-ui/core/styles';
import { AppTheme } from './AppTheme.type';

export function buildThemeDefaultClasses(theme: Theme) {
  const appTheme: AppTheme = {
    base: {
      bg: {
        main: theme.palette.background.default,
        alt: darken(theme.palette.background.default, 0.05),
      },
      text: {
        main: '#333',
        alt: '#333',
      },
    },
    highlighted: {
      bg: {
        main: lighten(theme.palette.primary.main, 0.7),
        alt: lighten(theme.palette.primary.main, 0.7),
      },
      text: {
        main: '#333',
        alt: '#333',
      },
    },
    hover: {
      bg: {
        main: lighten(theme.palette.primary.light, 0.2),
        alt: lighten(theme.palette.primary.light, 0.2),
      },
      text: {
        main: '#333',
        alt: '#333',
      },
    },
  };

  return appTheme;
}
