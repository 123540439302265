import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

export const YES_NO_OPTIONS: ValueLabel<boolean, string>[] = [
  {
    value: true,
    label: 'Oui',
  },
  {
    value: false,
    label: 'Non',
  },
];
