import {
  AppInquiryFullGql_Customer,
  AppInquiryResponse,
  AppInquiryResponseFullGql_Customer,
} from '@mabadive/app-common-model';
import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { MQueryDescription, graphqlClient } from '../../../../_common-browser';

type DiveCenterBookingInquiryResponsePage_FetchDataModel = {
  inquiry: AppInquiryFullGql_Customer;
  inquiryResponse?: AppInquiryResponseFullGql_Customer;
};

export function useDiveCenterBookingInquiryResponsePage_FetchData({
  inquiryId,
  inquiryResponseId,
}: {
  inquiryId: string;
  inquiryResponseId?: string;
}) {
  const queryClient = useQueryClient();

  const cacheKey = ['customer-inquiry-response', inquiryId, inquiryResponseId];
  const query = useQuery({
    queryKey: cacheKey,
    queryFn: async () => {
      const q1: MQueryDescription<AppInquiryFullGql_Customer> = {
        returnName: 'inquiry',
        queryName: 'inquiry',
        returnType: 'first',
        where: `{_id: {_eq: "${inquiryId}"}}`,
        returnAttributes: AppInquiryFullGql_Customer,
      };

      const queries = [q1];
      if (inquiryResponseId) {
        const q2: MQueryDescription<AppInquiryResponseFullGql_Customer> = {
          returnName: 'inquiryResponse',
          queryName: 'inquiry_response',
          returnType: 'first',
          where: `{_id: {_eq: "${inquiryResponseId}"}}`,
          returnAttributes: AppInquiryResponseFullGql_Customer,
        };
        queries.push(q2);
      }

      const graphqlQuery$ =
        graphqlClient.query.runMany<DiveCenterBookingInquiryResponsePage_FetchDataModel>(
          queries,
          {
            type: 'query',
            name: 'useDiveCenterBookingInquiryResponsePageLocalState',
          },
        );

      const fetchedData = await graphqlQuery$?.toPromise();
      // on converti les données dans un format simple, plus facile à exploiter
      // pour la mise à jour du cache et l'aggrégation des modifications locales
      return fetchedData;
    },
    enabled: !!inquiryId,
    gcTime: 1000 * 60 * 60 * 24 * 2, // on garde ça en cache 2 jours
    staleTime: 60, // on ne rafraichit qu'au bout de 1mn
    refetchOnWindowFocus: false, // mais pas si la fenêtre reprend le focus
    refetchInterval: 30 * 60 * 1000, // et toutes les 30 minutes
    // placeholderData: queryKeysMap.current.get(mainDiverId),
  });
  const inquiry = query?.data?.inquiry;
  const inquiryResponse = query?.data?.inquiryResponse;

  const updateData_InquiryResponse = useUpdateData_InquiryResponse({
    queryClient,
    cacheKey,
  });

  return {
    status: query.status,
    refetch: query.refetch,
    data: {
      inquiry,
      inquiryResponse,
    },
    updateData: {
      inquiryResponse: updateData_InquiryResponse,
    },
  };
}

export function useUpdateData_InquiryResponse({
  queryClient,
  cacheKey,
}: {
  queryClient: QueryClient;
  cacheKey: (string | number)[];
}) {
  return useMemo(
    () => ({
      update: (updatedInquiryResponse: AppInquiryResponse) => {
        const data: DiveCenterBookingInquiryResponsePage_FetchDataModel =
          queryClient.getQueryData(cacheKey);
        if (data && data?.inquiry) {
          queryClient.setQueryData(cacheKey, {
            ...data,
            inquiryResponse: updatedInquiryResponse,
          });
        }
      },
      create: (createdInquiryResponse: AppInquiryResponse) => {
        const data: DiveCenterBookingInquiryResponsePage_FetchDataModel =
          queryClient.getQueryData(cacheKey);
        if (data && data?.inquiry) {
          queryClient.setQueryData(cacheKey, {
            ...data,
            inquiryResponse: createdInquiryResponse,
          });
        }
      },
    }),
    [queryClient, cacheKey],
  );
}
