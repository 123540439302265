export const mapBuilder = {
  fromArray,
  fromArrayGroup,
  fromArrayToObjectMap,
};

function fromArray<T, K extends keyof T>(array: T[], {
  keyAttribute,
}: {
  keyAttribute: K;
}): Map<T[K], T> {
  return new Map(array.map((v) => [v[keyAttribute], v]));
}

function fromArrayToObjectMap<T, K extends keyof T>(array: T[], {
  keyAttribute,
}: {
  keyAttribute: K;
}): { [key: string]: T } {
  return array.reduce((acc, item) => {
    const key = item[keyAttribute] as unknown as string;
    acc[key] = item;
    return acc;
  }, {} as { [key: string]: T });
}

function fromArrayGroup<T, K extends keyof T>(array: T[], {
  keyAttribute,
}: {
  keyAttribute: K;
}): Map<T[K], T[]> {
  const map = new Map();

  array.forEach((value) => {
    const key = value[keyAttribute];
    const list = map.get(key);
    if (list) {
      list.push(value);
    } else {
      map.set(keyAttribute, [value]);
    }
  });
  return map;
}
