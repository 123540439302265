import {
  AppCurrency,
  AppCurrencyIsoCode,
  APP_CURRENCIES,
} from '@mabadive/app-common-model';
import { useMemo } from 'react';

export function useAppCurrency(
  currencyIsoCode: AppCurrencyIsoCode,
): AppCurrency {
  const mainCurrency = useMemo(() => {
    return APP_CURRENCIES.find((x) => x.isoCode === currencyIsoCode);
  }, [currencyIsoCode]);

  return mainCurrency;
}
