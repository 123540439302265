import {
  ClubDiver,
  ClubParticipant,
  ClubSettings,
  DIVE_CERTIFICATIONS_REFERENCE_MAP,
} from '@mabadive/app-common-model';

export const participantTrainingMainLevelFormatter = {
  formatMainLevelForTraining,
};

function formatMainLevelForTraining({
  diver,
  participant,
  clubSettings
}: {
  diver: Partial<Pick<ClubDiver, 'divingCertification1'>>;
  participant: Partial<Pick<ClubParticipant, 'diveMode'>>;
  clubSettings: ClubSettings
}): string {
  if (
    clubSettings.ui?.planning?.displayMainLevelForTraining &&
    participant?.diveMode === 'training' &&
    diver.divingCertification1?.mainCertificationReference
  ) {
    const levelToDisplay =
      DIVE_CERTIFICATIONS_REFERENCE_MAP[
        diver.divingCertification1?.mainCertificationReference
      ]?.reference;
    return levelToDisplay;
  }
}
