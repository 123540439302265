/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DialogContent,
  DialogTitle,
  withMobileDialog,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import React, { useEffect, useMemo, useState } from 'react';
import { useGlobalClasses } from 'src/AppTheme';
import { AppInputBoolean } from 'src/lib/form';
import { AppHeroIcons, AppIcon } from '../../icons';
import {
  AppButton,
  AppButtonStyleColor,
  AppConfirmDialog,
  AppMessage,
} from '../_tailwind';
import { ConfirmDialogType } from './ConfirmDialogType.model';

export const ConfirmDialog = withMobileDialog({ breakpoint: 'xs' })(
  ({
    fullScreen,
    isTailwind = false,
    open,
    closeDialog,
    title,
    message,
    consentMessage,
    consentMessage2,
    consentMessage2Description,
    type,
  }: {
    fullScreen: boolean;
    isTailwind?: boolean;
    open: boolean;
    uiIndex: number;
    title: string;
    message: string | React.ReactNode;
    consentMessage?: string;
    consentMessage2?: string;
    consentMessage2Description?: string;
    type: ConfirmDialogType;
    closeDialog: (confirmed: boolean) => void;
  }) => {
    const globalClasses = useGlobalClasses();

    const [showProgress, setShowProgress] = useState(false);

    function showProgressAndClose(result: boolean) {
      setShowProgress(true);
      closeDialog(result);
    }

    const [consent, setConsent] = useState<boolean>(true);

    useEffect(() => {
      setConsent(!!consentMessage ? false : true);
    }, [consentMessage]);

    const [consent2, setConsent2] = useState<boolean>(true);

    useEffect(() => {
      setConsent2(!!consentMessage2 ? false : true);
    }, [consentMessage2]);

    const actions: {
      cancel?: {
        icon?: AppIcon;
        label: string;
        color: AppButtonStyleColor;
      };
      confirm: {
        icon?: AppIcon;
        label: string;
        color: AppButtonStyleColor;
      };
    } = useMemo(() => {
      switch (type) {
        case 'cancel':
          return {
            cancel: {
              // icon: AppHeroIcons.actionClose,
              label: 'Non',
              color: 'gray-outline-light',
            },
            confirm: {
              icon: AppHeroIcons.actionCancel,
              label: 'Oui',
              color: 'danger-bg',
            },
          };
        case 'remove':
          return {
            cancel: {
              icon: AppHeroIcons.actionCancel,
              label: 'Annuler',
              color: 'gray-outline-light',
            },
            confirm: {
              icon: AppHeroIcons.actionDelete,
              label: 'Supprimer',
              color: 'danger-bg',
            },
          };
        case 'noYesInfo':
          return {
            cancel: {
              icon: AppHeroIcons.actionCancel,
              label: 'Non',
              color: 'gray-outline-light',
            },
            confirm: {
              icon: AppHeroIcons.actionConfirm,
              label: 'Oui',
              color: 'primary-bg',
            },
          };
        case 'noYesDanger':
          return {
            cancel: {
              icon: AppHeroIcons.actionCancel,
              label: 'Non',
              color: 'gray-outline-light',
            },
            confirm: {
              icon: AppHeroIcons.warn,
              label: 'Oui',
              color: 'danger-bg',
            },
          };
        case 'cancelChangesOrSave':
          return {
            cancel: {
              icon: AppHeroIcons.actionCancel,
              label: 'Annuler',
              color: 'danger-bg',
            },
            confirm: {
              icon: AppHeroIcons.actionConfirm,
              label: 'Sauvegarder',
              color: 'primary-bg',
            },
          };
        case 'alert':
          return {
            cancel: undefined,
            confirm: {
              icon: AppHeroIcons.actionConfirm,
              label: 'Ok',
              color: 'primary-outline-light',
            },
          };
      }
      return {
        cancel: {
          icon: AppHeroIcons.actionCancel,
          label: 'Annuler',
          color: 'gray-outline-light',
        },
        confirm: {
          icon: AppHeroIcons.actionSave,
          label: 'Confirmer',
          color: 'primary-bg',
        },
      };
    }, [type]);

    return isTailwind ? (
      <AppConfirmDialog
        open={open}
        onClickClose={() => showProgressAndClose(false)}
      >
        <h2 className="my-2 text-xl font-bold text-gray-700"> {title}</h2>
        <div className={open ? '' : 'invisible'}>
          <div className="mt-5 mb-2 text-gray-700">{message}</div>

          {consentMessage && (
            <AppInputBoolean
              value={consent}
              onChange={() => setConsent(!consent)}
              className="my-5"
              labelColorClassName={`font-bold ${
                consent ? 'text-app-blue' : 'text-red-800'
              }`}
              type="checkbox"
              description={consentMessage}
            />
          )}
          {consentMessage2 && (
            <>
              <AppInputBoolean
                value={consent2}
                onChange={() => setConsent2(!consent2)}
                className="mt-2"
                labelColorClassName={`font-bold ${
                  consent2 ? 'text-app-blue' : 'text-red-800'
                }`}
                type="checkbox"
                description={consentMessage2}
              />
              <AppMessage
                title="En savoir plus"
                isExpanded={false}
                message={consentMessage2Description}
              />
            </>
          )}
          {/* <Box style={{ visibility: showProgress ? 'visible' : 'hidden' }}><CircularProgress /></Box> */}
          <div className="mt-8 mb-2 flex-grow flex gap-2 justify-around">
            {actions.cancel && (
              <>
                <AppButton
                  className={'text-base font-bold uppercase'}
                  style={{ minWidth: '6rem' }}
                  color={actions.cancel.color}
                  icon={
                    actions.cancel.icon
                      ? () => <actions.cancel.icon className="w-6 h-6 mr-2" />
                      : undefined
                  }
                  tabIndex={500}
                  onClick={(e) => {
                    // e.preventDefault();
                    // e.stopPropagation();
                    showProgressAndClose(false);
                  }}
                >
                  {actions.cancel.label}
                </AppButton>
              </>
            )}
            <AppButton
              className="text-base font-bold uppercase"
              style={{ minWidth: '6rem' }}
              color={actions.confirm.color}
              icon={
                actions.confirm.icon
                  ? () => <actions.confirm.icon className="w-6 h-6 mr-2" />
                  : undefined
              }
              disabled={!consent}
              onClick={(e) => {
                if (consent) {
                  // e.preventDefault();
                  // e.stopPropagation();
                  showProgressAndClose(true);
                }
              }}
            >
              {actions.confirm.label}
            </AppButton>
          </div>
        </div>
      </AppConfirmDialog>
    ) : (
      <Dialog
        open={open}
        onClose={() => showProgressAndClose(false)}
        fullScreen={fullScreen}
      >
        <DialogTitle>
          <> {title}</>
        </DialogTitle>
        <DialogContent>
          <div className={open ? '' : 'invisible'}>
            <div>{message}</div>
            {consentMessage && (
              <AppInputBoolean
                value={consent}
                onChange={() => setConsent(!consent)}
                className="my-5"
                labelColorClassName={`font-bold ${
                  consent ? 'text-app-blue' : 'text-red-800'
                }`}
                type="checkbox"
                description={consentMessage}
              />
            )}
            {consentMessage2 && (
              <>
                <AppInputBoolean
                  value={consent2}
                  onChange={() => setConsent2(!consent2)}
                  className="mt-2"
                  labelColorClassName={`font-bold ${
                    consent2 ? 'text-app-blue' : 'text-red-800'
                  }`}
                  type="checkbox"
                  description={consentMessage2}
                />
                <AppMessage
                  title="En savoir plus"
                  isExpanded={false}
                  message={consentMessage2Description}
                />
              </>
            )}

            {/* <Box style={{ visibility: showProgress ? 'visible' : 'hidden' }}><CircularProgress /></Box> */}
            <div className="mt-8 mb-2 flex-grow flex gap-2 justify-around">
              {actions.cancel && (
                <>
                  <AppButton
                    className={'text-base font-bold uppercase'}
                    style={{ minWidth: '6rem' }}
                    color={actions.cancel.color}
                    icon={
                      actions.cancel.icon
                        ? () => <actions.cancel.icon className="w-6 h-6 mr-2" />
                        : undefined
                    }
                    tabIndex={500}
                    onClick={() => showProgressAndClose(false)}
                  >
                    {actions.cancel.label}
                  </AppButton>
                </>
              )}
              <AppButton
                disabled={!consent || !consent2}
                className="text-base font-bold uppercase"
                style={{ minWidth: '6rem' }}
                color={actions.confirm.color}
                icon={
                  actions.confirm.icon
                    ? () => <actions.confirm.icon className="w-6 h-6 mr-2" />
                    : undefined
                }
                onClick={() =>
                  consent && consent2 && showProgressAndClose(true)
                }
              >
                {actions.confirm.label}
              </AppButton>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  },
);
