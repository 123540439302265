/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';

export const AppButtonsBar: FC<{
  children?: React.ReactNode | React.ReactNode[];
  noBackground?: boolean;
  hasChanges?: boolean;
  className?: string;
}> = ({ noBackground, hasChanges, children, className = '' }) => {
  return (
    <div className={`max-w-screen-2xl w-full  bg-white ${className}`}>
      <div
        className={`w-full flex gap-4 ${
          noBackground
            ? ''
            : hasChanges
            ? 'bg-status-info-light'
            : 'bg-status-info'
        } px-5 py-2 flex justify-around items-center`}
      >
        {children}
      </div>
    </div>
  );
};
