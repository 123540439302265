import { DiveSessionTimeDayPeriod } from '@mabadive/app-common-model';
import {
  clubDiveSessionThemeBuilder,
  dateService,
} from '@mabadive/app-common-services';

export const diveSessionStyleBuilder = {
  buildBorderStyle,
  buildBorderStyleFromTime,
};

function buildBorderStyle({
  isVirtual,
  isPast,
  selectionColor,
  timeDayPeriod,
}: {
  isVirtual: boolean;
  isPast: boolean;
  selectionColor?: string;
  timeDayPeriod: DiveSessionTimeDayPeriod;
}) {
  return `${
    isVirtual ? (isPast ? 'opacity-80 border-dashed' : 'border-dashed') : ''
  }
     ${
       selectionColor
         ? `border-app-${selectionColor} ring-1 ring-app-dark-${selectionColor}`
         : `border-day-period-${timeDayPeriod}-dark`
     }
      `;
}

function buildBorderStyleFromTime({
  isVirtual,
  selectionColor,
  time,
}: {
  isVirtual: boolean;
  selectionColor?: string;
  time: Date;
}) {
  if (time && !time?.getUTCHours) {
    time = new Date(time);
  }
  const timeDayPeriod: DiveSessionTimeDayPeriod =
    clubDiveSessionThemeBuilder.buildTimeDayPeriod(time);
  const isPast = !dateService.isTodayUTC(time) && dateService.isPastUTC(time);

  return buildBorderStyle({
    isVirtual,
    isPast,
    selectionColor,
    timeDayPeriod,
  });
}
