import {
  AppInquiryResponse,
  CustomerInquiryResponsePayload,
} from '@mabadive/app-common-model';
import { apiClient } from 'src/_common-browser';

export const customerInquiryClient = {
  postInquiryResponse,
};

function postInquiryResponse(
  payload: CustomerInquiryResponsePayload,
): Promise<{ inquiryResponse: AppInquiryResponse }> {
  // send remote operation
  return apiClient
    .put<any>('/customer/inquiry-response/', {
      authenticate: true,
      json: {
        payload,
      },
    })
    .toPromise()
    .catch((err) => {
      throw err;
    });
}
