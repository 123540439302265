import {
  ClubDiver,
  ClubParticipant,
  DiveSessionGroup,
  DiveSessionResumeFull,
  DiveSessionResumeFullBookingSessionParticipant,
  DiveSessionResumeGroup,
  DiveSessionResumeParticipant,
  DiveSessionResumeParticipantsByGroup,
  DiveSessionResumeParticipantsGroupExtended,
  ExportDailyConfig,
  ExportDailyConfigContentSessionsGroupParamValues,
  ExportParticipantsGroupDefaultTargetDurations,
  MultipleDiveSessionNumber,
  SessionDiveGroupDetails,
  SessionDiveGroupResume,
  SessionDiveGroupSupervision,
} from '@mabadive/app-common-model';
import { diveSessionDiveSupervisionBuilder } from './diveSessionDiveSupervisionBuilder.service';

export const diveSessionDiveGroupBuilder = {
  buildDiveGroupResume,
  buildGroupTargetDeep,
  buildFirstDiveReference,
  buildDefaultTargetDuration,
  isVisibleParticipant,
};

function buildDiveGroupResume({
  groupExtended,
  sessionDiveNumber,
  session,
  diveSessionParticipantsByGroup,
  diveTourSessionNumber,
  exportDailyConfig,
  isDebugEnabled,
}: {
  groupExtended: DiveSessionResumeParticipantsGroupExtended;
  sessionDiveNumber: MultipleDiveSessionNumber;
  session: DiveSessionResumeFull;
  diveSessionParticipantsByGroup: DiveSessionResumeParticipantsByGroup;
  diveTourSessionNumber: MultipleDiveSessionNumber;
  exportDailyConfig: ExportDailyConfig;
  isDebugEnabled: boolean;
}) {
  const bookingSessionParticipants: DiveSessionResumeFullBookingSessionParticipant[] =
    session.bookingSessionParticipants;

  const { type, participants, group } = groupExtended;

  const participantsExts = participants.map((p) => {
    const bookingSessionParticipant: DiveSessionResumeFullBookingSessionParticipant =
      bookingSessionParticipants.find((x) => p._id === x.participantId);
    const participantsExt: {
      diver: Pick<ClubDiver, 'firstName' | 'lastName' | 'divingCertification1'>;
      participant: Pick<
        ClubParticipant,
        '_id' | 'certificationReference' | 'diveMode' | 'autoSupervisedDetails'
      >;
      bookingSessionParticipant: DiveSessionResumeFullBookingSessionParticipant;
    } = {
      diver: p.diver,
      participant: p,
      bookingSessionParticipant,
    };
    return participantsExt;
  });
  const diveSupervision: SessionDiveGroupSupervision =
    diveSessionDiveSupervisionBuilder.buildDiveSupervision({
      sessionDiveNumber,
      diveSession: session,
      group,
      divingInstructorsStaffs:
        diveSessionParticipantsByGroup.staffMembers.divingInstructorsStaffs,
      participantsExts,
    });

  const divingInstructors: SessionDiveGroupSupervision[] =
    diveSessionDiveSupervisionBuilder.buildDivingInstructors({
      sessionDiveNumber,
      diveSession: session,
      group,
      divingInstructorsStaffs:
        diveSessionParticipantsByGroup.staffMembers.divingInstructorsStaffs,
    });

  const targetDeep = buildGroupTargetDeep(participants);

  const firstDiveReference = buildFirstDiveReference({
    participants,
    group,
  });

  // TODO: ajouter defaultTargetDurations dans le modèle
  const targetDuration = buildDefaultTargetDuration({
    firstDiveReference,
    defaultTargetDurations: exportDailyConfig.settings.defaultTargetDurations,
  });

  const expectedParams: ExportDailyConfigContentSessionsGroupParamValues = {
    timeDuration: targetDuration,
    depth: targetDeep,
  };

  const details: SessionDiveGroupDetails = {
    diveMode: group?.diveMode,
    immersionStart: group?.immersionStart,
    targetDeep,
    targetDuration,
    firstDiveReference,
    expectedParams,
  };

  const filteredParticipants: DiveSessionResumeParticipant[] =
    participants.filter((x) => {
      if (!isVisibleParticipant({ participant: x, sessionDiveNumber, group })) {
        if (isDebugEnabled) {
          console.log(
            `[buildDiveGroupResume] ignore invisible participant ${
              x.diver.lastName
            } ${x.diver.firstName} (bookingState: ${!!x.bookingState
              ?.value}, diveSessionGroupDiveGuide: ${!!x.diveSessionGroupDiveGuide})`,
          );
        }
        return false; // on ignore les participants annulés ainsi que les dive-guide (car ils apparaissent en tant qu'encadrant)
      }
      if (x.details.multiSessionsPresenceNumbers?.length) {
        const isSessionIncluded =
          x.details.multiSessionsPresenceNumbers.includes(
            diveTourSessionNumber,
          );
        if (!isSessionIncluded) {
          if (isDebugEnabled) {
            console.log(
              `[buildDiveGroupResume]  ignore session dive N°"${diveTourSessionNumber}" participant ${
                x.diver.lastName
              } ${
                x.diver.firstName
              } (dives: ${x.details.multiSessionsPresenceNumbers.join(',')})`,
            );
          }
        }
        return isSessionIncluded;
      }
      return true;
    });
  const groupResume: SessionDiveGroupResume = {
    type,
    supervision: diveSupervision,
    divingInstructors,
    details,
    participants: filteredParticipants.map((diveSessionResumeParticipant) => {
      const bookingSessionParticipant: DiveSessionResumeFullBookingSessionParticipant =
        bookingSessionParticipants.find(
          (x) => diveSessionResumeParticipant._id === x.participantId,
        );
      const x: {
        diveSessionResumeParticipant: DiveSessionResumeParticipant;
        bookingSessionParticipant: DiveSessionResumeFullBookingSessionParticipant;
      } = {
        diveSessionResumeParticipant,
        bookingSessionParticipant,
      };
      return x;
    }),
  };
  return groupResume;
}

function buildGroupTargetDeep(
  groupParticipants: Pick<ClubParticipant, 'targetDeep'>[],
): number {
  const targetDeeps = groupParticipants.map((p) => p.targetDeep);
  const targetDeep = targetDeeps.reduce((acc, targetDeep) => {
    // on ne l'utilise que si c'est le même pour tous les participants
    if (acc === null) {
      return targetDeep;
    } else {
      if (acc !== targetDeep) {
        return undefined;
      }
    }
    return acc;
  }, null);
  return targetDeep;
}
function buildFirstDiveReference({
  participants,
  group,
}: {
  participants: Pick<ClubParticipant, 'firstDiveReference'>[];
  group: Pick<DiveSessionGroup, 'diveMode'>;
}): string {
  return participants.length && group?.diveMode === 'first-dive'
    ? participants[0].firstDiveReference
    : undefined;
}
function buildDefaultTargetDuration({
  firstDiveReference,
  defaultTargetDurations,
}: {
  firstDiveReference: string;
  defaultTargetDurations?: ExportParticipantsGroupDefaultTargetDurations;
}): number {
  const targetDuration =
    firstDiveReference === 'BPT'
      ? defaultTargetDurations.BPT
      : firstDiveReference === 'BPT-XL'
      ? defaultTargetDurations.BPT_XL
      : defaultTargetDurations.EXPLO;

  return targetDuration;
}

// NOTE: mutualisation du traitement entre l'ancien export de la fiche de sécurité et le nouvel export journalier
function isVisibleParticipant({
  group,
  participant,
  sessionDiveNumber,
}: {
  group: DiveSessionResumeGroup;
  participant: Pick<ClubParticipant, 'bookingState' | '_id'>;
  sessionDiveNumber: MultipleDiveSessionNumber;
}): boolean {
  return (
    participant.bookingState.value !== 'cancelled' &&
    !(
      // on ignore les participants annulés ainsi que les dive-guide (car ils apparaissent en tant qu'encadrant)
      (
        (sessionDiveNumber === 1 &&
          !group?.diveTourGroupSession1?.instructor &&
          participant._id ===
            group?.diveTourGroupSession1?.diveGuide?.participantId) ||
        (sessionDiveNumber === 2 &&
          !group?.diveTourGroupSession2?.instructor &&
          participant._id ===
            group?.diveTourGroupSession2?.diveGuide?.participantId)
      )
    )
  );
}
