import { AuthUserRole } from '@mabadive/app-common-model';
import { useAppSecurityUser } from './useAppSecurityUser.hook';

export function useAppSecurityUserHasRole(role: AuthUserRole): boolean {
  const securityUser = useAppSecurityUser();
  if (!securityUser || !securityUser.roles) {
    return false;
  }
  const hasRole = securityUser?.roles.includes(role);

  return hasRole;
}
