import { JsonPatchOperation } from '@mabadive/app-common-model';
import { ReplaceOperation } from 'fast-json-patch';

export const jsonPatcherNullValueProcessor = {
  applyReplaceDeleteByNullValue,
};

function applyReplaceDeleteByNullValue({
  patches,
  replaceDeleteByNullValue,
}: {
  patches: JsonPatchOperation[];
  replaceDeleteByNullValue: boolean;
}): JsonPatchOperation[] {
  return replaceDeleteByNullValue ? patches.map((patch) => {
    if (patch.op === 'remove') {
      const op: ReplaceOperation<any> = {
        op: 'replace',
        path: patch.path,
        value: null,
      };
      return op;
    }
    return patch;
  }) : patches;
}
