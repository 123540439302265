/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React from 'react';
import { FieldArrayWithId, UseFormReturn, useWatch } from 'react-hook-form';
import { AppFormControlRHF, AppInputRHF } from '../../../../../../../lib/form';
import { CustomerProductsPage_FormModel } from './model';

export const CustomerProductsPage_BookProductPanel_FillParticipants_PriceParticipants_item =
  ({
    participant,
    participantIndex,
    localForm,
    priceIndex,
    className = '',
  }: {
    participant: FieldArrayWithId<
      CustomerProductsPage_FormModel,
      `items.${number}.participants`,
      'id'
    >;
    participantIndex: number;
    localForm: UseFormReturn<CustomerProductsPage_FormModel, any, undefined>;
    priceIndex: number;
    className?: string;
  }) => {
    const control = localForm.control;

    const baseParticipantFormName = `items.${priceIndex}.participants` as const;

    const [firstName] = useWatch({
      control,
      name: [
        `${baseParticipantFormName}.${participantIndex}.firstName` as const,
      ],
    });

    const showAllFields =
      participant.requiredParticipant || firstName?.trim()?.length > 0;

    return (
      <div
        key={participantIndex}
        className={clsx('w-full flex flex-col gap-4 text-gray-600', className)}
      >
        <div className="text-lg font-bold text-app-secondary uppercase">
          Participant {participantIndex + 1}{' '}
          {!participant.requiredParticipant && (
            <span className="normal-case font-normal text-app-primary">
              (facultatif)
            </span>
          )}
        </div>
        <div
          className={
            'w-full flex flex-col sm:grid sm:grid-cols-7 md:grid-cols-5 gap-4'
          }
        >
          <AppFormControlRHF
            className={'sm:col-span-3 md:col-span-2 w-full'}
            label="Prénom"
            control={localForm.control}
            name={`${baseParticipantFormName}.${participantIndex}.firstName`}
            required={participant.requiredParticipant}
            renderComponent={(props) => (
              <AppInputRHF {...props} fullWidth type="text" />
            )}
          />
          {showAllFields && (
            <>
              <AppFormControlRHF
                className={'sm:col-span-3 md:col-span-2 w-full'}
                label="Nom"
                control={localForm.control}
                name={`${baseParticipantFormName}.${participantIndex}.lastName`}
                required={false}
                renderComponent={(props) => (
                  <AppInputRHF {...props} fullWidth type="text" />
                )}
              />
              <AppFormControlRHF
                className={'w-full'}
                label="Âge"
                control={localForm.control}
                name={`${baseParticipantFormName}.${participantIndex}.age`}
                required={false}
                renderComponent={(props) => (
                  <AppInputRHF {...props} fullWidth type="number" />
                )}
              />
            </>
          )}
        </div>
      </div>
    );
  };
