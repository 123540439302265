import {
  ClubResumeGql_Public,
  EcommerceCategoryWithBookletGql_Customer,
  EcommerceProductFullGql_Customer,
} from '@mabadive/app-common-model';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { MQueryDescription, graphqlClient } from '../../../../_common-browser';
import {
  LoadableContent,
  reactQueryToLoadableConverter,
} from '../../../_core/data/app-loading';
import { DiveCenterHomePageLoadedContent } from '../../DiveCenterHomePage/model';
import { useDiveCenterHomePageLoadedContent } from '../../DiveCenterHomePage/useDiveCenterHomePageLoadedContent.hook';
import {
  CustomerEcommerceDataFetchedData,
  CustomerEcommerceDataLoadedContent,
} from '../_model';
import { customerEcommerceDataLoadedContentBuilder } from './customerEcommerceDataLoadedContentBuilder.service';

export function useCustomerEcommerceDataLoadedContentFetch({
  clubPublicReference,
}: {
  clubPublicReference: string;
}) {
  const {
    content: loadedContentBase,
    ...loadableContent
  }: LoadableContent<DiveCenterHomePageLoadedContent> = useDiveCenterHomePageLoadedContent(
    {
      clubPublicReference,
    },
  );

  const diveCenterGraphqlResult: ClubResumeGql_Public =
    loadedContentBase?.diveCenterGraphqlResult;

  const clubReference = loadedContentBase?.clubResume?.club?.reference;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const startDate = useMemo(() => new Date(), [clubReference]);
  const query: UseQueryResult<CustomerEcommerceDataLoadedContent, unknown> =
    useQuery({
      queryKey: [clubReference],
      queryFn: async () => {
        const q1: MQueryDescription<EcommerceCategoryWithBookletGql_Customer> =
          {
            returnName: 'categoriesWithBooklet',
            queryName: 'club_ecommerce_category', // : ClubEcommerceCategory[];
            returnType: 'all',
            where: `{clubReference: {_eq: "${clubReference}"}}`,
            orderBy: '{sortIndex: asc}',
            returnAttributes: EcommerceCategoryWithBookletGql_Customer,
          };

        const q2: MQueryDescription<EcommerceProductFullGql_Customer> = {
          returnName: 'productsFull',
          queryName: 'club_ecommerce_product', // : ClubEcommerceCategory[];
          returnType: 'all',
          where: `{clubReference: {_eq: "${clubReference}"}}`,
          // orderBy: '{sortIndex: asc}',
          returnAttributes: EcommerceProductFullGql_Customer,
        };

        const graphqlQuery$ =
          graphqlClient.query.runMany<CustomerEcommerceDataFetchedData>(
            [q1, q2],
            {
              type: 'query',
              name: 'useCustomerEcommerceDataLoadedContentFetch',
            },
          );

        const fetchedData: CustomerEcommerceDataFetchedData =
          await graphqlQuery$?.toPromise();
        // on converti les données dans un format simple, plus facile à exploiter
        // pour la mise à jour du cache et l'aggrégation des modifications locales

        const loadedContent: CustomerEcommerceDataLoadedContent =
          customerEcommerceDataLoadedContentBuilder.buildContent({
            fetchedData,
            diveCenterGraphqlResult,
          });

        return loadedContent;
      },
      enabled: !!clubReference, // on déclenche la requête seulement une fois que la ref du club est connue (TODO: l'alternative serait de générer un token de connexion spécifique pour ce club)
      gcTime: 1000 * 60 * 60 * 24 * 2, // on garde ça en cache 2 jours
      staleTime: 0, // mais au chargement, on rafraichi systématiquement les données
      refetchOnWindowFocus: true, // ainsi que si la fenêtre reprend le focus
      refetchInterval: 5 * 60 * 1000, // et toutes les 5 minutes
      // placeholderData: queryKeysMap.current.get(mainDiverId),
    });

  const loadableContentMulti =
    reactQueryToLoadableConverter.convertReactQueryToLoadableContent({
      query,
      startDate,
    });

  return {
    ...loadableContentMulti,
  };
}
