import { useCustomerEcommerceDataLoadedContentFetch } from './useCustomerEcommerceDataLoadedContentFetch.hook';

export function useCustomerEcommerceDataLoadedContent({
  clubPublicReference,
}: {
  clubPublicReference: string;
}) {
  const loadableFetchedResult = useCustomerEcommerceDataLoadedContentFetch({
    clubPublicReference,
  });

  const {
    content: loadedContent,
    refetch,
    ...loadableContent
  } = loadableFetchedResult;

  return {
    loadableContent,
    loadedContent,
    refetch: loadableFetchedResult.refetch,
  };
}
