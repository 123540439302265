import React from 'react';
import { Switch } from 'react-router-dom';
import { appRouteBuilder } from 'src/business/_core/modules/root/pages';
import { AppRoute } from 'src/business/_core/modules/router/AppRoute/AppRoute';
import useRouter from 'use-react-router';
import { DiveCenterBookingCheckoutPage } from './DiveCenterBookingCheckoutPage';
import { DiveCenterBookingInquiryResponsePage } from './DiveCenterBookingInquiryResponsePage';
import { DiveCenterBookingPage } from './DiveCenterBookingPage';
import { DiveCenterBookingPaymentBackPage } from './DiveCenterBookingPaymentBackPage';
import { DiveCenterBookingPaymentPage } from './DiveCenterBookingPaymentPage';

export const DiveCenterBookingRoutes = React.memo(
  function DiveCenterBookingRoutesMemo({
    navigationContext,
  }: {
    navigationContext: {
      clubPublicReference: string;
      bookingId?: string;
    };
  }) {
    // const { match } =
    //   useRouter<{
    //     clubPublicReference: string;
    //   }>();

    const { match } =
      useRouter<{
        bookingId: string;
      }>();

    navigationContext.bookingId = match.params.bookingId;

    return (
      <>
        <Switch>
          <AppRoute
            path={`${match.url}`}
            exact
            component={DiveCenterBookingPage}
            navigationContext={navigationContext}
            auth={{ requiredRoles: ['diver-booking'] }}
          />
          <AppRoute
            path={`${match.url}/checkout`}
            component={DiveCenterBookingCheckoutPage}
            navigationContext={navigationContext}
            auth={{ requiredRoles: ['diver-booking'] }}
          />
          <AppRoute
            path={`${match.url}/inquiry`}
            component={DiveCenterBookingInquiryResponsePage}
            navigationContext={navigationContext}
            auth={{ requiredRoles: ['diver-booking'] }}
          />
          <AppRoute
            path={`${match.url}/online-payments`}
            exact={true}
            component={DiveCenterBookingPaymentPage}
            navigationContext={navigationContext}
            auth={{ requiredRoles: ['diver-booking'] }}
          />
          <AppRoute
            path={`${match.url}/online-payment-back`}
            component={DiveCenterBookingPaymentBackPage}
            navigationContext={navigationContext}
            auth={{ requiredRoles: ['diver-booking'] }}
          />
          {/* default route */}
          <AppRoute
            path={`${match.url}/`}
            redirectTo={({ appAuth }) =>
              appRouteBuilder.getDefaultRouteFromAuth({ appAuth })
            }
          />
        </Switch>
      </>
    );
  },
);
