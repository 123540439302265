import { ExportDailyConfigContentSessionsGroupDiverColumnId } from '@mabadive/app-common-model';

export type GroupDiverColumnStringFormat = 'excel' | 'option-label';

export const exportGroupDiverColumnFormatter = {
  formatColumnName,
};

function formatColumnName(
  columnId: ExportDailyConfigContentSessionsGroupDiverColumnId,
  { format }: { format: GroupDiverColumnStringFormat },
): string {
  switch (columnId) {
    case 'bookingSessionParticipantsPurchase':
      return format === 'option-label' ? 'Paiement' : 'Statut';
    case 'sessionParticipantDivesCount':
      return format === 'option-label' ? 'NB plongées (si 2-tank)' : '';
    case 'level':
      return format === 'option-label' ? 'Niveau' : 'NIV';

    case 'aptitude':
      return format === 'option-label' ? 'Aptitude (APT)' : 'APT';

    case 'totalDive':
      return format === 'option-label'
        ? 'Total plongées'
        : 'Total Pl.'.toUpperCase();

    case 'lastDive':
      return format === 'option-label'
        ? 'Dernière plongée'
        : 'Dern. Pl.'.toUpperCase();

    case 'gaz': {
      return 'GAZ';
    }
    case 'gazMod': {
      return format === 'option-label' ? 'MOD' : 'MOD'; // TODO c'est quoi ça?
    }
    case 'gazPressure': {
      return format === 'option-label' ? ' Pression (P°)' : ' P°';
    }
    case 'tank': {
      return ' Bloc';
    }
    case 'tankNumber': {
      return format === 'option-label' ? ' Numéro de bloc (N°)' : ' N°';
    }
    case 'signature': {
      return format === 'option-label' ? 'Signature' : 'Sign';
    }
    case 'jacket': {
      return 'Stab';
    }
    case 'wetsuit': {
      return format === 'option-label' ? 'Combinaison' : 'Combi';
    }
    case 'fins':
      return 'Palmes';

    case 'scubaRegulator':
      return format === 'option-label' ? 'Détendeur' : 'Détend.';
    case 'divingBoots':
      return format === 'option-label' ? 'Bottillons' : 'Bot.';
    case 'compass':
      return format === 'option-label' ? 'Compas' : 'Compas';
    case 'weighting':
      return format === 'option-label' ? 'Lestage' : 'Lest.';
    case 'rebreather':
      return format === 'option-label' ? 'Recycleur' : 'Recyc.';
    case 'underwaterScooter':
      return format === 'option-label' ? 'Scooter' : 'Scoot.';

    case 'mask':
      return 'Masque';

    case 'name':
      return 'Nom';

    case 'medicalFormFilled':
      return format === 'option-label' ? 'Formulaire médical' : 'F. med.';
    case 'medicalCertificate':
      return format === 'option-label' ? 'Certificat médical' : 'C. med.';
    case 'insurance':
      return format === 'option-label' ? 'Assurance' : 'Assur.';
  }
}
