import { APP_COLORS } from '@mabadive/app-common-model';
import { AppBar, Theme, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { default as React } from 'react';
import { useFullscreen } from 'rooks';
import { NEVER } from 'rxjs';
import { catchError, first, switchMap, tap } from 'rxjs/operators';
import { useRedirect } from 'src/business/_core/data/hooks';
import { diveCenterStore } from 'src/business/_core/store/diveCenterStore.service';
import { uiStore } from 'src/business/_core/store/uiStore.service';
import { useClubSettingsPublic } from 'src/business/_core/store/useClubSettingsPublic.hook';
import {
  authenticationClient,
  authenticationStore,
  useAppSecurityIsAuthenticated,
  useAppSecurityUser,
  useAppSecurityUserHasRole,
} from 'src/business/auth/services';
import DiveCenterShoppingCartIconWithBadge from '../../../../../dive-center/DiveCenterShoppingCartPage/DiveCenterShoppingCart/components/DiveCenterShoppingCartIconWithBadge';
import { appWebConfig } from '../../../root/config';
import { appRouteBuilder } from '../../../root/pages';
import { useCacheableClasses } from '../../../root/theme';
import { AppIcons, AppIconsMaterial } from '../../icons';
import { useMediaSizeTailwind } from '../../services';
import { Customer_TopBarDiveCenter } from './Customer_TopBarDiveCenter';

export const TopBarRootDiveCenter = ({
  clubPublicReference,
}: {
  clubPublicReference?: string;
}) => {
  const fullScreen = useFullscreen({
    target: {
      current: document.body,
    },
  });
  const redirectTo = useRedirect();

  const classes = useCacheableClasses({
    cacheKey: 'TopBar',
    buildStyles,
  });

  const user = useAppSecurityUser();

  const mediaSize = useMediaSizeTailwind();

  const isAuthenticated = useAppSecurityIsAuthenticated();
  const isClub = useAppSecurityUserHasRole('club');
  const isProfileEnabled = useAppSecurityUserHasRole('account-profile');
  const isImpersonate = useAppSecurityUserHasRole('impersonate-back');

  // const showDemoWarning = appWebConfig.debug.showDemoWarning;

  const theme = appWebConfig?.debug.isEnvDemoOrTest ? 'test' : 'prod';

  const clubSettingsPublic = useClubSettingsPublic();

  return (
    <AppBar
      className={`${classes.root} bg-gradient-to-b ${
        theme === 'test'
          ? 'from-[#ffffff] to-[#ebf9ff]'
          : 'from-[#ffffff] to-[#ebf9ff]'
      } over-drawer`}
      position="fixed"
    >
      <Toolbar className={`max-w-screen-2xl ${classes.toolBar}`}>
        {/* <a href={appWebConfig.applications.publicWebUrl}>
          <img
            className={`w-[40px] h-[40px] max-w-none mr-2`}
            alt=""
            src="/images/brand/logo-mabadive_80x80.png"
          />
        </a> */}
        {clubSettingsPublic && (
          <Customer_TopBarDiveCenter
            className="flex-shrink"
            key={clubSettingsPublic.clubPublicReference}
            clubSettingsPublic={clubSettingsPublic}
          />
        )}

        <div className="w-full"></div>

        <DiveCenterShoppingCartIconWithBadge
          clubPublicReference={clubPublicReference}
          className="flex-shrink-0 mt-1 mr-2"
        />

        <div className="flex-grow flex gap-1 sm:gap-2 mr-2">
          {isAuthenticated && isProfileEnabled && !isImpersonate ? (
            <button
              type="button"
              className="px-1 sm:px-2 text-gray-400 hover:text-gray-600 font-bold cursor-pointer"
              onClick={() => redirectTo('/account')}
            >
              <div className="block text-center gap-1">
                <AppIconsMaterial.account />
                <span className="hidden sm:inline-block text-xs truncate uppercase">
                  Profil
                </span>
              </div>
            </button>
          ) : null}
          {fullScreen?.isFullscreenAvailable && (
            <button
              type="button"
              className="px-1 sm:px-2 text-gray-400 hover:text-gray-600 font-bold cursor-pointer"
              onClick={() => {
                fullScreen.toggleFullscreen();
              }}
            >
              <div className="block text-center gap-1">
                {fullScreen.isFullscreenEnabled ? (
                  <AppIcons.ui.fullscreenExit className="inline text-2xl hover:text-orange-400" />
                ) : (
                  <AppIcons.ui.fullscreen className="inline text-2xl hover:text-orange-400" />
                )}
                <span className="hidden sm:inline-block text-xs truncate uppercase">
                  Plein écran
                </span>
              </div>
            </button>
          )}
          {isAuthenticated && (
            <button
              type="button"
              className="px-1 sm:px-2 text-gray-400 hover:text-gray-600 font-bold cursor-pointer"
              onClick={() => logout()}
            >
              <div className="block text-center gap-1">
                <AppIconsMaterial.logout />
                <span className="hidden sm:inline-block text-xs truncate uppercase">
                  {isImpersonate ? 'Retour' : 'Se déconnecter'}
                </span>
              </div>
            </button>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );

  function logout() {
    if (isImpersonate) {
      authenticationClient
        .impersonateBack()
        .pipe(
          switchMap(() => {
            uiStore.snackbarMessage.set({
              type: 'success',
              content: 'Impersonate back OK!',
            });
            return authenticationStore.auth.get().pipe(
              first(),
              switchMap((appAuth) =>
                appRouteBuilder.getDefaultRouteFromAuth({ appAuth }),
              ),
              tap((defaultRoute) => {
                redirectTo(defaultRoute);
              }),
            );
          }),
          catchError(() => {
            uiStore.snackbarMessage.set({
              type: 'error',
              content: 'Une erreur innatendue est survenue...',
            });
            return NEVER;
          }),
        )
        .subscribe();
    } else {
      const clubSettings =
        diveCenterStore.clubResume.getSnapshot()?.clubSettings;
      if (clubSettings?.clubPublicReference) {
        redirectTo(`/dive-center/${clubSettings?.clubPublicReference}`);
      }
      authenticationClient.logout().subscribe();
    }
  }
};

function buildStyles(theme: Theme) {
  return makeStyles({
    root: {
      '&.over-drawer': {
        zIndex: 0,
        // zIndex: theme.zIndex.drawer + 1,
      },
      '& .MuiToolbar-root.MuiToolbar-gutters': {
        padding: '0 10px',
      },
      '& .menu-button.MuiButton-root': {
        color: 'white',
        '&:hover': {
          color: APP_COLORS.orange,
        },
      },
      // background: APP_COLORS.topBar,
    },
    toolBar: {
      '&.MuiToolbar-regular': {
        minHeight: '50px',
      },
    },
    logoutButton: {
      color: theme.palette.common.white,
      '&:hover': {
        background: 'transparent',
        color: APP_COLORS.orange,
      },
    },
    grow: {
      flexGrow: 1,
    },
  });
}
