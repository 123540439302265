import {
  ClubSettingsPlanning,
  DiveSession,
  DiveSessionResumeFull,
} from '@mabadive/app-common-model';
import { virtualDiveSessionBuilder } from './virtualDiveSessionBuilder.service';
export const calendarWeekModelBuilder = {
  addVirtualSessions,
  buildVirtualSessions,
  sortSessionsByTimeThenReference,
};

function addVirtualSessions({
  realDiveSessions,
  dayReference,
  clubReference,
  diveCenterId,
  settingsPlanning,
}: {
  realDiveSessions: DiveSessionResumeFull[];
  dayReference: string;
  clubReference: string;
  diveCenterId: string;
  settingsPlanning: ClubSettingsPlanning;
}): DiveSessionResumeFull[] {
  const realDiveSessionsVirtualReferences = realDiveSessions
    .map((x) => x.virtualDiveSessionReference)
    .filter((x) => !!x);

  const virtualDiveSessions = buildVirtualSessions({
    realDiveSessionsVirtualReferences,
    dayReference,
    clubReference,
    diveCenterId,
    settingsPlanning,
  });

  const diveSessions = [...realDiveSessions, ...virtualDiveSessions];

  // sort sessions by time, then by reference
  sortSessionsByTimeThenReference(diveSessions);
  return diveSessions;
}

function buildVirtualSessions({
  realDiveSessionsVirtualReferences,
  dayReference,
  clubReference,
  diveCenterId,
  settingsPlanning,
  sortByTime: sortSessions,
}: {
  realDiveSessionsVirtualReferences: string[];
  dayReference: string;
  clubReference: string;
  diveCenterId: string;
  settingsPlanning: ClubSettingsPlanning;
  sortByTime?: boolean;
}): DiveSessionResumeFull[] {
  const diveSessions: DiveSessionResumeFull[] = [];

  const virtualDiveSessions =
    virtualDiveSessionBuilder.buildDailyVirtualSessions({
      dayReference,
      clubReference,
      diveCenterId,
      settingsPlanning,
    });

  for (const virtualDiveSession of virtualDiveSessions) {
    if (
      !realDiveSessionsVirtualReferences.includes(
        virtualDiveSession.virtualDiveSessionReference,
      )
    ) {
      const virtualDiveSessionResumeFull: DiveSessionResumeFull =
        virtualDiveSessionBuilder.convertToDiveSessionResumeFull(
          virtualDiveSession,
        );
      diveSessions.push(virtualDiveSessionResumeFull);
    }
  }

  if (sortSessions) {
    // sort sessions by time, then by reference
    sortSessionsByTimeThenReference(diveSessions);
  }
  return diveSessions;
}

function sortSessionsByTimeThenReference<
  T extends Pick<DiveSession, 'time' | 'reference'>,
>(diveSessions: T[]) {
  // sort sessions by time, then by reference
  diveSessions.sort((a, b) =>
    a.time > b.time
      ? 1
      : a.time < b.time
      ? -1
      : a.reference > b.reference
      ? 1
      : a.reference < b.reference
      ? -1
      : 0,
  );
}
