import { makeStyles, Theme } from '@material-ui/core';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { AppIconsMaterial } from '../../icons';
import {
  AppSnackbarVariant,
  AppSnackbarVariantIcons,
} from './AppSnackbarVariant.model';

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warn: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));
export function AppSnackbarContentWrapper({
  className,
  message,
  onClose,
  variant,
}: {
  className?: string;
  message?: string;
  onClose?: () => void;
  variant: AppSnackbarVariant;
}) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const Icon = AppSnackbarVariantIcons[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <AppIconsMaterial.close className={classes.icon} />
        </IconButton>,
      ]}
      // {...other}
    />
  );
}
