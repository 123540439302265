import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import React, { Fragment, useEffect, useState } from 'react';

export type AppDialogModalProps = {
  open?: boolean;
  setOpen?: (open: boolean) => void;
  defaultOpen?: boolean;
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
  expandWidth?: boolean;
  expandHeight?: boolean;
  maxHeight?: 'md:max-h-[800px]' | 'md:max-h-[900px]' | 'md:max-h-[1000px]';
  maxWidth?: string;
};

export const AppDialogModal = ({
  children,
  open,
  setOpen = () => {},
  defaultOpen = false,
  expandWidth = false, // NOTE: on pourrait imaginer avoir plusieurs options: expandWidth='md', expandWidth='max',...
  // ou alors maxExpandWitdh = 3xl
  expandHeight = false,
  maxHeight,
  maxWidth = 'md:max-w-2xl lg:max-w-3xl',
  className,
}: AppDialogModalProps) => {
  const [isOpen, setIsOpen] = useState(open !== undefined ? open : defaultOpen);
  useEffect(() => {
    if (open !== undefined) {
      setIsOpen(open);
    }
  }, [open]);
  return (
    // https://tailwindui.com/components/application-ui/overlays/modals
    // https://headlessui.dev/react/dialog
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={clsx('fixed z-50 inset-0 overflow-y-auto', className)}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-[100dvh] pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-[100dvh]"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={clsx(
                'z-50 relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle',
                expandWidth && 'w-[99vw] md:w-vw ',
                expandHeight && 'h-[90vh] md:h-[70vh]',
                maxHeight,
                maxWidth,
              )}
            >
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
