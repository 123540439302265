/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useRedirect } from 'src/business/_core/data/hooks';

import {
  AppButton,
  AppHeroIcons,
} from '../../../../../../_core/modules/layout';
import { AppTranslationContentPanel } from '../../../03.content';
import { CustomerProductsPage_BookProductPanelLocalState } from '../useCustomerProductsPage_BookProductPanelLocalState.hook';
import { CustomerProductsPage_BookProductPanel_ChooseQuantityPrices } from './CustomerProductsPage_BookProductPanel_ChooseQuantityPrices';

export const CustomerProductsPage_BookProductPanel_ChooseQuantity = ({
  localState,
  className,
}: {
  localState: CustomerProductsPage_BookProductPanelLocalState;
  className?: string;
}) => {
  const redirectTo = useRedirect();

  const { state, data, actions } = localState;

  const productBookletPage = data.productArticleFull?.productBookletPage;
  const articleBookletPage = data.productArticleFull?.articleBookletPage;
  const form = state.form;

  const [items] = useWatch({
    control: form.control,
    name: ['article.items'],
  });

  const totalQuantity = useMemo(
    () =>
      (items ?? [])
        .filter((x) => x.quantity > 0)
        .reduce((acc, price) => acc + price.quantity, 0),
    [items],
  );

  return (
    <div
      className={clsx('flex flex-col items-stretch sm:flex gap-4', className)}
    >
      <CustomerProductsPage_BookProductPanel_ChooseQuantityPrices
        form={form}
        clubPublicSettings={data.clubPublicSettings}
        productArticleFull={data.productArticleFull}
      />
      <div
        className={clsx(
          'my-6 text-center',
          totalQuantity > 0 ? '' : 'invisible',
        )}
      >
        <AppButton
          className="uppercase"
          size="large"
          icon={AppHeroIcons.actionConfirm}
          color="primary-bg"
          onClick={() => actions.goToNextStep()}
        >
          Confirmer
        </AppButton>
      </div>
      <div className="hidden md:flex flex-col gap-8">
        <div className="flex-grow">
          {(productBookletPage?.mainContent?.content?.items ?? []).map(
            (item, i) => (
              <AppTranslationContentPanel
                key={i}
                item={item}
                appUiTheme={data.appUiTheme}
              />
            ),
          )}
        </div>
        <div className="flex-grow">
          {(articleBookletPage?.mainContent?.content?.items ?? []).map(
            (item, i) => (
              <AppTranslationContentPanel
                key={i}
                item={item}
                appUiTheme={data.appUiTheme}
              />
            ),
          )}
        </div>
      </div>
      {/* <div TODO si bcp de contenu, il faudrait ajouter le bouton Continuer en bas
        className={clsx(
          'my-6 text-center',
          totalQuantity > 0 ? '' : 'invisible',
        )}
      >
        <AppButton
          className="uppercase"
          size="large"
          icon={AppHeroIcons.actionConfirm}
          color="primary-bg"
          onClick={() => actions.goToNextStep()}
        >
          Confirmer
        </AppButton>
      </div> */}
    </div>
  );
};
