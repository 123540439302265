import { BookingMemberCreationStatus } from '@mabadive/app-common-model';

export const bookingMemberCreationStatusFormatter = {
  formatStatus,
};

function formatStatus(status: BookingMemberCreationStatus): string {
  switch (status) {
    case 'draft':
      return 'À renseigner';
    case 'to-validate':
      return 'En cours de validation';
    case 'ok':
      return 'Validé';
    case 'ko':
      return 'Invalide';
  }
  // default
  return status;
}
