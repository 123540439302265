import { AppIconsMaterial } from '../../icons';

export const AppSnackbarVariantIcons = {
  success: AppIconsMaterial.success,
  warn: AppIconsMaterial.warning,
  error: AppIconsMaterial.error,
  info: AppIconsMaterial.info,
};

export type AppSnackbarVariant = keyof typeof AppSnackbarVariantIcons;
