import { useFetchAppDocUrl } from './useFetchAppDocUrl.hook';

export const useAppDocImageUrl = ({
  appDocId,
  publicUrl,
  triggerFetchIndex = 1,
}: {
  appDocId: string;
  publicUrl?: string;
  triggerFetchIndex?: number;
}) => {
  // const profilePhoto: PRO_AppDocResume = useMemo(() => {
  //   const photos = (docResumes ?? []).filter(
  //     (dr) => dr.docRef === 'personal-photo',
  //   );
  //   const sortedPhotos = dataSorter.sortMultiple(photos, {
  //     getSortAttributes: (x) => [
  //       {
  //         value: x.uploadDate,
  //         asc: false,
  //       },
  //       {
  //         value: x._id,
  //       },
  //     ],
  //   });
  //   return sortedPhotos[0];
  // }, [docResumes]);
  const { data: appDocUrlData } = useFetchAppDocUrl({
    appDocId,
    enabled: !!appDocId && !publicUrl,
    triggerFetchIndex,
  });
  const imageUrl = publicUrl ?? appDocUrlData?.url;

  return imageUrl;
};
