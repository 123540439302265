import { ClubBoat, ClubBoatDepartureType } from '@mabadive/app-common-model';

export const clubBoatFormatter = {
  formatClubBoat,
  formatDepartureType,
};

function formatClubBoat(
  boat: Pick<ClubBoat, 'name' | 'immatriculation'>,
  { format }: { format: 'name' | 'name-immat' },
): string {
  if (!boat) {
    return undefined;
  }
  return boat.immatriculation && format === 'name-immat'
    ? `${boat.name?.toUpperCase()} (${boat.immatriculation})`
    : boat.name?.toUpperCase();
}

function formatDepartureType(type: ClubBoatDepartureType): string {
  if (type) {
    switch (type) {
      case 'boat':
        return 'Bateau';
      case 'border':
        return 'Départ du bord';
      case 'pool':
        return 'Piscine / fosse';
      case 'room':
        return 'Salle / théorie';
    }
    return type;
  }
}
