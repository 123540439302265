/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubProductPackageOfferView } from '@mabadive/app-common-model';
import { clubProductPackageMetaReader } from './clubProductPackageMetaReader.service';

export const clubProductPackageOfferMatcherBestCommon = {
  getFirstMatchingCountOffer,
};

export function getFirstMatchingCountOffer({
  divesCount,
  offers,
}: {
  divesCount: number;
  offers: ClubProductPackageOfferView[];
}): ClubProductPackageOfferView {
  if (!offers.length) {
    return undefined;
  }
  // nombre de plongées de la première offre
  const firstOfferTotalCount = offers.length
    ? clubProductPackageMetaReader.readMeta(offers[0].productPackage)
        ?.productDefaultTotalMultipleCount
    : 0;
  if (firstOfferTotalCount === 1) {
    // la première offre est une plongée simple
    // recherche du premier forfait
    const firstBestMatchingOfferNonSingle = offers.find((o) => {
      const meta = clubProductPackageMetaReader.readMeta(o.productPackage);
      const totalCount = meta?.productDefaultTotalMultipleCount ?? 0;
      return totalCount > 1;
    });
    // nombre de plongées du premier forfait
    const firstNonSingleOfferTotalCount = clubProductPackageMetaReader.readMeta(
      firstBestMatchingOfferNonSingle?.productPackage,
    )?.productDefaultTotalMultipleCount;

    if (firstNonSingleOfferTotalCount > divesCount) {
      // return single offer if count does not reach first non-single offer count
      return offers[0];
    }
  }
  return offers.find((o) => {
    // sinon on renvoit la première offre qui a assez de plongées
    const meta = clubProductPackageMetaReader.readMeta(o.productPackage);
    const totalCount = meta.productDefaultTotalMultipleCount ?? 0;
    return totalCount >= divesCount;
  });
}
