import {
  ClubParticipant,
  ClubResumeStaffMember,
  DiveCenterEquipmentConfig,
  DiveCenterPrivateSettingsEquipment,
  DiveSession,
  EQUIPMENT_TYPES,
  EquipmentDescription,
  EquipmentType,
  MultipleDiveSessionNumber,
  SessionEquipmentStats,
  SingleEquipmentStats,
} from '@mabadive/app-common-model';
import { dataSorter } from '../../data';
import { EquipmentsStatsParticipant } from './EquipmentsStatsParticipant.type';
import { equipmentDescriptionFormatter } from './equipmentDescriptionFormatter.service';
import { equipmentDescriptionSorter } from './equipmentDescriptionSorter.service';

export const equipmentStatsBuilder = {
  buildEquipmentsStatsArray,
  buildEquipmentsStats,
};

function buildEquipmentsStatsArray({
  participants,
  settingsEquipment,
  session,
  instructorsStaffMembers,
}: {
  participants: EquipmentsStatsParticipant[];
  settingsEquipment: DiveCenterPrivateSettingsEquipment;
  session: Pick<DiveSession, 'sessionsCount'>;
  instructorsStaffMembers: ClubResumeStaffMember[];
}): SingleEquipmentStats[] {
  const filteredParticipants = participants.filter(
    (p) => p.bookingState.value !== 'cancelled',
  );

  return EQUIPMENT_TYPES.map((equipmentType) =>
    buildParticipantsSingleEquipmentsStats(filteredParticipants, {
      equipmentType,
      settingsEquipment,
      session,
      instructorsStaffMembers,
    }),
  ).filter((x) => x.totalCount > 0);
}

function buildParticipantsSingleEquipmentsStats(
  filteredParticipants: EquipmentsStatsParticipant[],
  {
    equipmentType,
    settingsEquipment,
    session,
    instructorsStaffMembers,
  }: {
    equipmentType: EquipmentType;
    settingsEquipment: DiveCenterPrivateSettingsEquipment;
    session: Pick<DiveSession, 'sessionsCount'>;
    instructorsStaffMembers: ClubResumeStaffMember[];
  },
) {
  const initialValue: SingleEquipmentStats = {
    equipmentType,
    hasAltSizes: false,
    equipments: [],
    totalClubCount: 0,
    totalSelfCount: 0,
    totalClubInstructorCount: 0,
    totalSelfInstructorCount: 0,
    divesCounts: {
      isSeparateDiveCount:
        session.sessionsCount > 1 && equipmentType === 'tank',
      1: {
        club: 0,
        self: 0,
        clubInstructor: 0,
        selfInstructor: 0,
      },
      2: {
        club: 0,
        self: 0,
        clubInstructor: 0,
        selfInstructor: 0,
      },
    },
    totalCount: 0,
    unknownCount: 0,
    clubEquipmentDescriptions: [],
    clubEquipmentDescriptionsSum: 0,
    selfEquipmentDescriptions: [],
    selfEquipmentDescriptionsSum: 0,
  };
  const equipmentsStats = filteredParticipants.reduce(
    (singleEquipmentStats, participant) => {
      let participantDives: MultipleDiveSessionNumber[];
      if (participant.divesCount === 2) {
        if (participant.details?.multiSessionsPresenceNumbers?.length > 0) {
          participantDives = participant.details?.multiSessionsPresenceNumbers;
        } else {
          participantDives = [1, 2];
        }
      } else {
        participantDives = [1];
      }
      appendSingleEquipmentStatsParticipant({
        equipmentType,
        equipment: participant.equipment?.[
          equipmentType
        ] as EquipmentDescription,
        singleEquipmentStats,
        participantDives,
      });
      return singleEquipmentStats;
    },
    initialValue,
  );

  if (equipmentType === 'tank') {
    // si 2-tank, on considère qu'il faut 2 blocs pour le moniteur
    const instructorDives: MultipleDiveSessionNumber[] =
      session.sessionsCount === 2 ? [1, 2] : [1];
    instructorsStaffMembers.forEach((x) => {
      if (x.equipment?.tank) {
        appendSingleEquipmentStatsInstructor({
          equipmentType,
          equipment: x.equipment?.tank,
          singleEquipmentStats: equipmentsStats,
          instructorDives,
        });
      }
    });
  }

  const orderedSizes: string[] = buildOrderedSizes({
    settingsEquipment,
    equipmentType,
  });

  equipmentsStats.equipments = dataSorter.sortMultiple(
    equipmentsStats.equipments,
    {
      getSortAttributes: (x) =>
        equipmentDescriptionSorter.getSortAttributes(x.equipment, orderedSizes),
    },
  );

  equipmentsStats.clubEquipmentDescriptions = equipmentsStats.equipments
    .filter(
      (x) =>
        !x.equipment.selfEquipped &&
        (!!x.equipment.model1?.ref || !!x.equipment.model2?.ref), // on masque les équipements sans précision (plus bas, on va afficher "??? x2")
    )
    .map((equipment) => ({
      total: equipment.quantity.total,
      label: equipment.label,
    }));

  equipmentsStats.selfEquipmentDescriptions = equipmentsStats.equipments
    .filter(
      (x) =>
        x.equipment.selfEquipped &&
        (!!x.equipment.model1?.ref || !!x.equipment.model2?.ref), // on masque les équipements sans précision (plus bas, on va afficher "??? x2")
    )
    .map((equipment) => ({
      total: equipment.quantity.total,
      label: equipment.label,
    }));

  equipmentsStats.clubEquipmentDescriptionsSum =
    equipmentsStats.clubEquipmentDescriptions.reduce(
      (acc, x) => acc + x.total,
      0,
    );

  equipmentsStats.selfEquipmentDescriptionsSum =
    equipmentsStats.selfEquipmentDescriptions.reduce(
      (acc, x) => acc + x.total,
      0,
    );

  return equipmentsStats;
}

function buildOrderedSizes({
  settingsEquipment,
  equipmentType,
}: {
  settingsEquipment: DiveCenterPrivateSettingsEquipment;
  equipmentType: string;
}) {
  const conf: DiveCenterEquipmentConfig = (settingsEquipment.equipments as any)[
    equipmentType
  ];

  let orderedSizes: string[] = [];
  if (conf?.club?.enabled) {
    orderedSizes = orderedSizes.concat(conf.club.models.map((x) => x.ref));
  }
  if (conf?.self?.enabled) {
    orderedSizes = orderedSizes.concat(conf.self.models.map((x) => x.ref));
  }
  return orderedSizes;
}

/**
 *
 * @deprecated encore utilisé par l'ancien export de la fiche sécu
 */
export function buildEquipmentsStats({
  participants,
  settingsEquipment,
}: {
  participants: EquipmentsStatsParticipant[];
  settingsEquipment: DiveCenterPrivateSettingsEquipment;
}): SessionEquipmentStats {
  const filteredParticipants = participants.filter(
    (p) => p.bookingState.value !== 'cancelled',
  );

  const equipmentsStats: SessionEquipmentStats = {
    hasAnyEquipment: false,
    jacket: {
      equipmentType: 'jacket',
      hasAltSizes: false,
      equipments: [],
      totalClubCount: 0,
      totalSelfCount: 0,
      totalClubInstructorCount: 0,
      totalSelfInstructorCount: 0,
      totalCount: 0,
      unknownCount: 0,
      divesCounts: {
        isSeparateDiveCount: false,
        1: {
          club: 0,
          self: 0,
          clubInstructor: 0,
          selfInstructor: 0,
        },
        2: {
          club: 0,
          self: 0,
          clubInstructor: 0,
          selfInstructor: 0,
        },
      },
      clubEquipmentDescriptions: [],
      clubEquipmentDescriptionsSum: 0,
      selfEquipmentDescriptions: [],
      selfEquipmentDescriptionsSum: 0,
    },
    wetsuit: {
      equipmentType: 'wetsuit',
      hasAltSizes: false,
      equipments: [],
      totalClubCount: 0,
      totalSelfCount: 0,
      totalClubInstructorCount: 0,
      totalSelfInstructorCount: 0,
      totalCount: 0,
      unknownCount: 0,
      divesCounts: {
        isSeparateDiveCount: false,
        1: {
          club: 0,
          self: 0,
          clubInstructor: 0,
          selfInstructor: 0,
        },
        2: {
          club: 0,
          self: 0,
          clubInstructor: 0,
          selfInstructor: 0,
        },
      },
      clubEquipmentDescriptions: [],
      clubEquipmentDescriptionsSum: 0,
      selfEquipmentDescriptions: [],
      selfEquipmentDescriptionsSum: 0,
    },
    fins: {
      equipmentType: 'fins',
      hasAltSizes: false,
      equipments: [],
      totalClubCount: 0,
      totalSelfCount: 0,
      totalClubInstructorCount: 0,
      totalSelfInstructorCount: 0,
      totalCount: 0,
      unknownCount: 0,
      divesCounts: {
        isSeparateDiveCount: false,
        1: {
          club: 0,
          self: 0,
          clubInstructor: 0,
          selfInstructor: 0,
        },
        2: {
          club: 0,
          self: 0,
          clubInstructor: 0,
          selfInstructor: 0,
        },
      },
      clubEquipmentDescriptions: [],
      clubEquipmentDescriptionsSum: 0,
      selfEquipmentDescriptions: [],
      selfEquipmentDescriptionsSum: 0,
    },
  };

  filteredParticipants.reduce((stats, participant) => {
    countStats({
      participant,
      equipmentsStats,
      attributeName: 'jacket',
    });
    countStats({
      participant,
      equipmentsStats,
      attributeName: 'wetsuit',
    });
    countStats({
      participant,
      equipmentsStats,
      attributeName: 'fins',
    });

    return stats;
  }, equipmentsStats);

  const jacketSizes: string[] = buildOrderedSizes({
    settingsEquipment,
    equipmentType: 'jacket',
  });

  equipmentsStats.jacket.equipments = dataSorter.sortMultiple(
    equipmentsStats.jacket.equipments,
    {
      getSortAttributes: (x) =>
        equipmentDescriptionSorter.getSortAttributes(x.equipment, jacketSizes),
    },
  );

  const wetsuitSizes: string[] = buildOrderedSizes({
    settingsEquipment,
    equipmentType: 'wetsuit',
  });

  equipmentsStats.wetsuit.equipments = dataSorter.sortMultiple(
    equipmentsStats.wetsuit.equipments,
    {
      getSortAttributes: (x) =>
        equipmentDescriptionSorter.getSortAttributes(x.equipment, wetsuitSizes),
    },
  );

  const finSizes: string[] = buildOrderedSizes({
    settingsEquipment,
    equipmentType: 'fins',
  });
  equipmentsStats.fins.equipments = dataSorter.sortMultiple(
    equipmentsStats.fins.equipments,
    {
      getSortAttributes: (x) =>
        equipmentDescriptionSorter.getSortAttributes(x.equipment, finSizes),
    },
  );

  const totalEquipmentCount =
    equipmentsStats.jacket.totalCount +
    equipmentsStats.wetsuit.totalCount +
    equipmentsStats.fins.totalCount;
  equipmentsStats.hasAnyEquipment = totalEquipmentCount !== 0;

  return equipmentsStats;
}

function countStats({
  participant,
  equipmentsStats,
  attributeName,
}: {
  participant: Pick<ClubParticipant, 'equipment'>;
  equipmentsStats: SessionEquipmentStats;
  attributeName: 'jacket' | 'wetsuit' | 'fins';
}) {
  if (!participant.equipment) {
    return;
  }
  const equipment: EquipmentDescription = participant.equipment[attributeName];
  if (equipment) {
    const label = equipmentDescriptionFormatter.formatEquipmentDescription(
      equipment,
      { format: 'long' },
    );
    const equipmentStats = equipmentsStats[attributeName].equipments.find(
      (j) => j.label === label,
    );
    equipmentsStats[attributeName].totalCount++;

    if (isEquipmentInClub(equipment)) {
      equipmentsStats[attributeName].totalClubCount++;
    } else {
      equipmentsStats[attributeName].totalSelfCount++;
    }

    if (equipmentStats) {
      equipmentStats.quantity.nb++;
      if (equipment.model2?.ref) {
        equipmentStats.quantity.nbAlt++;
      }
      equipmentStats.quantity.total++;
    } else {
      equipmentsStats[attributeName].equipments.push({
        equipment,
        label,
        quantity: {
          nb: 1,
          nbAlt: equipment.model2?.ref ? 1 : 0,
          total: 1,
        },
      });
    }
  } else {
    equipmentsStats[attributeName].unknownCount++;
  }
}

function appendSingleEquipmentStatsParticipant({
  equipmentType,
  equipment,
  singleEquipmentStats,
  participantDives,
}: {
  equipmentType: EquipmentType;
  equipment: EquipmentDescription;
  singleEquipmentStats: SingleEquipmentStats;
  participantDives: MultipleDiveSessionNumber[];
}) {
  const isInDive1 = participantDives.includes(1);
  const isInDive2 = participantDives.includes(2);
  const divesCount = (isInDive1 ? 1 : 0) + (isInDive2 ? 1 : 0);
  // 1 bloc par plongée
  const equipmentCount = equipmentType === 'tank' ? divesCount : 1;
  if (equipment) {
    const label = equipmentDescriptionFormatter.formatEquipmentDescription(
      equipment,
      { format: 'long' },
    );
    const equipmentStats = singleEquipmentStats.equipments.find(
      (j) => j.label === label,
    );
    singleEquipmentStats.totalCount++;

    if (isEquipmentInClub(equipment)) {
      singleEquipmentStats.totalClubCount += equipmentCount;
      if (isInDive1) {
        singleEquipmentStats.divesCounts[1].club += 1;
      }
      if (isInDive2) {
        singleEquipmentStats.divesCounts[2].club += 1;
      }
    } else {
      singleEquipmentStats.totalSelfCount += equipmentCount;
      if (isInDive1) {
        singleEquipmentStats.divesCounts[1].self += 1;
      }
      if (isInDive2) {
        singleEquipmentStats.divesCounts[2].self += 1;
      }
    }

    if (equipmentStats) {
      equipmentStats.quantity.nb += equipmentCount;
      if (equipment.model2?.ref) {
        equipmentStats.quantity.nbAlt += equipmentCount;
      }
      equipmentStats.quantity.total += equipmentCount;
    } else {
      singleEquipmentStats.equipments.push({
        equipment,
        label,
        quantity: {
          nb: equipmentCount,
          nbAlt: equipment.model2?.ref ? equipmentCount : 0,
          total: equipmentCount,
        },
      });
    }
  } else {
    singleEquipmentStats.unknownCount += equipmentCount;
  }
}
function appendSingleEquipmentStatsInstructor({
  equipmentType,
  equipment,
  singleEquipmentStats,
  instructorDives: participantDives,
}: {
  equipmentType: EquipmentType;
  equipment: EquipmentDescription;
  singleEquipmentStats: SingleEquipmentStats;
  instructorDives: MultipleDiveSessionNumber[];
}) {
  const isInDive1 = participantDives.includes(1);
  const isInDive2 = participantDives.includes(2);
  const divesCount = (isInDive1 ? 1 : 0) + (isInDive2 ? 1 : 0);
  // 1 bloc par plongée
  const equipmentCount = equipmentType === 'tank' ? divesCount : 1;
  if (equipment) {
    const label = equipmentDescriptionFormatter.formatEquipmentDescription(
      equipment,
      { format: 'long' },
    );
    const equipmentStats = singleEquipmentStats.equipments.find(
      (j) => j.label === label,
    );
    singleEquipmentStats.totalCount++;

    if (isEquipmentInClub(equipment)) {
      singleEquipmentStats.totalClubInstructorCount += equipmentCount;
      if (isInDive1) {
        singleEquipmentStats.divesCounts[1].clubInstructor += 1;
      }
      if (isInDive2) {
        singleEquipmentStats.divesCounts[2].clubInstructor += 1;
      }
    } else {
      singleEquipmentStats.totalSelfInstructorCount += equipmentCount;
      if (isInDive1) {
        singleEquipmentStats.divesCounts[1].selfInstructor += 1;
      }
      if (isInDive2) {
        singleEquipmentStats.divesCounts[2].selfInstructor += 1;
      }
    }

    if (equipmentStats) {
      equipmentStats.quantity.nb += equipmentCount;
      if (equipment.model2?.ref) {
        equipmentStats.quantity.nbAlt += equipmentCount;
      }
      equipmentStats.quantity.total += equipmentCount;
    } else {
      singleEquipmentStats.equipments.push({
        equipment,
        label,
        quantity: {
          nb: equipmentCount,
          nbAlt: equipment.model2?.ref ? equipmentCount : 0,
          total: equipmentCount,
        },
      });
    }
  } else {
    singleEquipmentStats.unknownCount += equipmentCount;
  }
}

function isEquipmentInClub(equipment: EquipmentDescription) {
  return !equipment.selfEquipped;
}
