import React, { useCallback } from 'react';
import { useAppRouter } from '../../../../_core/data/hooks/useAppRouter';
import {
  AppButton,
  AppFormAutoSubmit,
  AppIconsAction,
  AppPageContainerWithFixedBars,
} from '../../../../_core/modules/layout';
import { confirmDialog } from '../../../../_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { uiStore } from '../../../../_core/store';
import { DiveCenterBookingInquiryResponsePage_FormModel } from '../model';
import { DiveCenterBookingInquiryResponsePageLocalState } from '../useDiveCenterBookingInquiryResponsePageLocalState.hook';
import { DiveCenterBookingInquiryResponsePage_FormHeader } from './DiveCenterBookingInquiryResponsePage_FormHeader';
import { DiveCenterBookingInquiryResponsePage_FormSection } from './DiveCenterBookingInquiryResponsePage_FormSection';

export const DiveCenterBookingInquiryResponsePage_FillFormPanel = ({
  localState,
}: {
  localState: DiveCenterBookingInquiryResponsePageLocalState;
}) => {
  const {
    state: { form, viewState, setViewState, clubResume },
    data: { loadableContent, aggregatedBookingResume, inquiry },
    actions,
  } = localState;

  const appRouter = useAppRouter();

  const submitForm = useCallback(async () => {
    try {
      return form.handleSubmit(
        async function onValid(
          formValue: DiveCenterBookingInquiryResponsePage_FormModel,
        ) {
          await actions.submitInquiryResponse({
            formValue,
            status: 'draft',
            nextAction: 'go-to-confirm-form',
          });
          setViewState({
            mode: 'confirm-form',
          });
        },
        function onInvalid(err) {
          uiStore.snackbarMessage.set({
            type: 'error',
            content:
              'Formulaire invalide. Veuillez corriger les erreurs ci-dessus.',
          });
          throw err;
        },
      )();
    } catch (err) {
      // message already displayed
    }
  }, [actions, form, setViewState]);
  const onCancel = useCallback(async () => {
    if (
      !form.formState.isDirty ||
      (await confirmDialog.confirmPromise({
        title: 'Annuler les modifications?',
        message: 'Êtes-vous sûr de vouloir annuler vos modifications?',
        // consentMessage: "Oui, je ",
        // consentMessage2: gdprConsentMessage,
        // consentMessage2Description: gdprConsentMessageDescription,
        type: 'noYesDanger',
      }))
    ) {
      actions.backToBookingHome();
    }
  }, [actions, form.formState.isDirty]);

  return (
    <AppFormAutoSubmit className="w-full" onSubmit={submitForm}>
      <AppPageContainerWithFixedBars
        contentState={loadableContent.contentState}
        lastActionStatus={loadableContent.lastActionStatus}
        marginBottom={true}
        paddingBottom={true}
        contentClassName="bg-gray-200 app-p-content"
        footerBar={() => (
          <div className="lg:hidden border-t border-gray-600 bg-gray-400 pt-2 pb-2 px-4">
            <div className="mx-auto w-full max-w-sm flex justify-center items-stretch xs:justify-center gap-4 md:gap-8">
              <AppButton
                type="button"
                className="flex-grow text-sm md:text-base font-bold uppercase"
                size="normal"
                fullWidth={false}
                icon={AppIconsAction.cancel}
                // iconPosition="right"
                color="gray-outline-light"
                onClick={() => {
                  onCancel();
                }}
              >
                Annuler
              </AppButton>
              <AppButton
                type="submit"
                className="flex-grow text-sm md:text-base font-bold uppercase"
                size="normal"
                icon={AppIconsAction.confirm}
                // iconPosition="right"
                color="primary-bg"
              >
                Suivant
              </AppButton>
            </div>
          </div>
        )}
      >
        <div className="w-full grid gap-4">
          <DiveCenterBookingInquiryResponsePage_FormHeader
            className="w-full"
            inquiry={inquiry}
            localState={localState}
            form={form}
          />
          <div className="w-full grid gap-4 md:gap-8">
            {inquiry.sections.map((section, sectionIndex) => (
              <DiveCenterBookingInquiryResponsePage_FormSection
                key={sectionIndex}
                section={section}
                sectionIndex={sectionIndex}
                form={form}
                inquiry={inquiry}
                localState={localState}
              />
            ))}
          </div>
          <div className="hidden lg:flex mt-8 w-full max-w-sm justify-center items-stretch xs:justify-center gap-4 md:gap-8">
            <AppButton
              type="button"
              className="flex-grow text-sm md:text-base font-bold uppercase"
              size="normal"
              fullWidth={false}
              icon={AppIconsAction.cancel}
              // iconPosition="right"
              color="gray-outline-light"
              onClick={() => {
                onCancel();
              }}
            >
              Annuler
            </AppButton>
            <AppButton
              type="submit"
              className="flex-grow text-sm md:text-base font-bold uppercase"
              size="normal"
              icon={AppIconsAction.confirm}
              // iconPosition="right"
              color="primary-bg"
            >
              Suivant
            </AppButton>
          </div>
        </div>
      </AppPageContainerWithFixedBars>
    </AppFormAutoSubmit>
  );
};
