import { bookingMemberResumeAttributesBuilder } from '../default-booking/bookingMemberResumeAttributesBuilder.service';
import { clubParticipantAttributesBuilder } from './clubParticipantAttributesBuilder.service';

export const editionBookingParticipantResumeAttributesBuilder = {
  buildAttributes,
};

/**
 * Build graphql attributes for EditionBookingResumeSessionParticipant
 */
function buildAttributes() {
  return `
    _id
    _createdAt
    _updatedAt
    _version
    clubReference
    bookingId
    bookingSessionId
    bookingMemberId
    participantId
    diveSessionReference
    diverId
    participantBookingStatus
    bookingMember {
      ${bookingMemberResumeAttributesBuilder.buildAttributes()}
    }
    diveSessionParticipant {
      ${clubParticipantAttributesBuilder.buildAttributes()}
    }
 `;
}
