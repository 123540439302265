import {
  ClubPublicSettings,
  Customer_ClubSettingsPublicCustomer,
  OnlineBookingOrderArticleFull,
} from '@mabadive/app-common-model';
import React from 'react';
import { AppIconsAction } from '../../../../_core/modules/layout';
import { DiveCenterShoppingCartOrderArticle } from './DiveCenterShoppingCartOrderArticle';

const DiveCenterShoppingCart = ({
  orderArticlesFull,
  clubPublicSettings,
  clubSettings,
  onClearShoppingCart,
}: {
  orderArticlesFull: OnlineBookingOrderArticleFull[];
  clubPublicSettings: ClubPublicSettings;
  clubSettings: Customer_ClubSettingsPublicCustomer;
  onClearShoppingCart?: () => void;
}) => {
  const appUiTheme = clubPublicSettings?.appUiTheme;
  return (
    <div className="app-card app-p-content ">
      <ul className="divide-y divide-gray-200">
        {orderArticlesFull.map((orderArticleFull, i) => (
          <DiveCenterShoppingCartOrderArticle
            key={i}
            orderArticleFull={orderArticleFull}
            clubSettings={clubSettings}
          />
        ))}
      </ul>
      {onClearShoppingCart && orderArticlesFull?.length > 0 && (
        <a
          className="cursor-pointer text-status-error"
          onClick={(e) => onClearShoppingCart()}
          {...appUiTheme.buttonCancel}
        >
          <AppIconsAction.delete className="inline-block w-4 h-4 mr-1" />
          <span className="hover:underline">Vider mon panier</span>
        </a>
      )}
    </div>
  );
};

export default DiveCenterShoppingCart;
