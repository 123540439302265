export const stringFormatter = {
  toUpperCaseFirst,
  toUpperCaseFirstEveryWord,
  buildStringWithCharacterRepetition,
  buildCDNUrl,
};

function toUpperCaseFirst(txt: string) {
  if (!txt || !txt.length) {
    return txt;
  }
  // appLogger.debug('toUpperCaseFirst:', toUpperCaseFirst)
  return (
    txt.substring(0, 1).toUpperCase() +
    (txt.length > 1 ? txt.substring(1).toLowerCase() : '')
  );
}

function toUpperCaseFirstEveryWord(txt: string) {
  if (!txt || !txt.length) {
    return txt;
  }
  return txt
    .split(' ')
    .filter((w) => w.length)
    .map((w) => toUpperCaseFirst(w))
    .join(' ');
}

function buildStringWithCharacterRepetition(
  character: string,
  count: number,
): string {
  if (count > 0) {
    let str = '';
    for (let i = 0; i < count; i++) {
      str += character;
    }
    return str;
  }
  return '';
}

function buildCDNUrl(cdn: string, suffix: string): string {
  return suffix
    ? `${cdn}${
        cdn?.endsWith('/') || suffix?.startsWith('/') ? '' : '/'
      }${suffix}`
    : undefined;
}
