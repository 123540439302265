import { ClubResumeGql_Public } from '@mabadive/app-common-model';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MQueryDescription, graphqlClient } from 'src/_common-browser';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { diveCenterGraphqlQuery } from './diveCenterGraphqlQuery.fn';
import { DiveCenterCriteria } from './model';

export const clubResumeGql_Public_GraphqlFetcher = {
  findOne,
};

function findOne(
  criteria: DiveCenterCriteria,
  {
    type,
    name = 'resume',
  }: {
    type: 'subscription' | 'query';
    name?: string;
  },
): Observable<ClubResumeGql_Public> {
  if (!criteria.clubPublicReference) {
    return of(undefined);
  }

  const query: MQueryDescription<any> = diveCenterGraphqlQuery(criteria);

  return graphqlClient.query
    .runOne<ClubResumeGql_Public>(query, {
      type,
      name,
    })
    .pipe(
      catchError((err) => {
        console.error(err);
        appLogger.warn('[diveCenterGraphqlFetcher] error fetching data', err);
        return throwError(err);
      }),
    );
}
