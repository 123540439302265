"use strict";
exports.__esModule = true;
exports.MULTI_CENTER_DEFAULT_PROFILE_AGENCY = void 0;
exports.MULTI_CENTER_DEFAULT_PROFILE_AGENCY = {
    label: 'Centrale',
    authorizations: {
        // NOUVELLE HIERARCHIE: inspirée de anim-live AuthUserCompanyAuthorizations
        staff: {
            view: {
                staffPlanning: true,
                staffSettingsDetails: true,
                staffSettingsList: true
            },
            action: {
                editStaffSheet: true,
                editPlanningDefault: true,
                editPlanningDaily: true,
                editPlanningSession: true
            }
        },
        creditNote: {
            // avoirs
            view: {
                bookingDetails: true,
                paymentsList: true
            },
            action: {
                create: true,
                edit: true,
                "delete": true
            }
        },
        communication: {
            action: {
                sendSessionMessage: true,
                sendSessionMessageEmail: true,
                sendSessionMessageSms: true,
                sendSessionMessageWhatsApp: true,
                sendMassMailing: false,
                sendBookingMessage: true,
                sendBookingMessageEmail: true,
                sendBookingMessageSms: true,
                sendBookingMessageWhatsApp: true
            }
        },
        // DEPRECATED: ancienne hiérarchie
        view: {
            planning: {
                participant: true,
                event: true
            },
            participant: {
                diver: true,
                history: true,
                booking: true,
                billing: true,
                payment: true
            },
            dashboard: {
                dailyCash: true,
                stats: true,
                dailyPlanningResume: true,
                dailyStats: true
            },
            lists: {
                diver: true,
                participant: true,
                billing: true,
                payment: true
            },
            settings: {
                global: false,
                diveCenter: false,
                offers: false,
                userAccounts: false,
                mabadiveBilling: false
            }
        },
        edit: {
            participant: {
                diver: true,
                booking: true,
                billing: true,
                billingCreate: true,
                billingEdit: true,
                billingDelete: true,
                payment: true,
                paymentCreate: true,
                paymentDelete: true,
                paymentEdit: true,
                paymentOnlineCapture: false
            },
            planning: {
                session: true,
                event: true,
                securitySheet: true
            },
            settings: {
                global: false,
                diveCenter: false,
                offers: false,
                userAccounts: false,
                accountProfiles: false,
                general: false,
                onlinePayment: false,
                accountProfilesImpersonate: false,
                archiveData: false,
                accomodation: false,
                equipment: false,
                customerSpace: false,
                exportDaily: false,
                inquiry: false
            }
        },
        download: {
            dailyCash: true,
            securitySheet: true,
            dailyResume: true,
            agenciesPurchases: true,
            bookings: true,
            divers: true
        },
        multiDiveCenters: {
            switchCenter: true,
            view: {
                planning: true,
                participant: true,
                dashboard: true,
                lists: true,
                settings: false
            },
            edit: {
                participant: true,
                planning: true,
                settings: false
            }
        }
    }
};
