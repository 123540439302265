// https://heroicons.com/ ?

import {
  ArrowLongRightIcon,
  ArrowRightOnRectangleIcon,
  ArrowUturnLeftIcon,
  ArrowsPointingOutIcon,
  Bars3Icon,
  BoltIcon,
  BuildingLibraryIcon,
  CalendarIcon,
  CheckBadgeIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpDownIcon,
  ChevronUpIcon,
  ClipboardDocumentCheckIcon,
  ClipboardIcon,
  CogIcon,
  CreditCardIcon,
  CurrencyEuroIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  EyeSlashIcon,
  FolderOpenIcon,
  InformationCircleIcon,
  LockClosedIcon,
  NoSymbolIcon,
  PencilIcon,
  PencilSquareIcon,
  PlusIcon,
  PrinterIcon,
  ShieldCheckIcon,
  ShoppingBagIcon,
  Square2StackIcon,
  Squares2X2Icon,
  SunIcon,
  TableCellsIcon,
  TrashIcon,
  UserGroupIcon,
  UserIcon,
  UsersIcon,
  ViewColumnsIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import {
  ArchiveBoxIcon,
  ArrowDownTrayIcon,
  ChatBubbleBottomCenterTextIcon,
  CheckIcon as CheckIconSolid,
  CubeIcon,
  EnvelopeIcon,
  EyeIcon,
  MapPinIcon,
  MinusCircleIcon as MinusCircleIconSolid,
  PhoneIcon,
  PlusCircleIcon as PlusCircleIconSolid,
  StarIcon,
  UserPlusIcon,
  XCircleIcon as XCircleIconSolid,
} from '@heroicons/react/24/solid';

export const AppHeroIcons = {
  comment: ChatBubbleBottomCenterTextIcon,
  calendar: CalendarIcon,
  planning: CalendarIcon,
  actionMove: ChevronUpDownIcon,
  disabled: NoSymbolIcon,
  autoFill: BoltIcon,
  history: ClipboardIcon,
  success: ShieldCheckIcon,
  checkSolid: CheckIconSolid,
  check: CheckIcon,
  info: InformationCircleIcon,
  infoArrow: ArrowLongRightIcon,
  active: BoltIcon,
  star: StarIcon,
  warn: ExclamationCircleIcon,
  meteo: SunIcon,
  error: ExclamationTriangleIcon,
  training: BuildingLibraryIcon,
  purchase: TableCellsIcon,
  payment: CurrencyEuroIcon,
  billing: CreditCardIcon,
  booking: ClipboardDocumentCheckIcon,
  diveSite: MapPinIcon,
  contact: EnvelopeIcon,
  actionRemove: TrashIcon,
  diver: UserIcon,
  divers: UsersIcon,
  eye: EyeIcon,
  eyeOff: EyeSlashIcon,
  menu: Bars3Icon,
  actionClose: XMarkIcon,
  actionSave: CheckIcon,
  actionAdd: PlusIcon,
  actionAddUser: UserPlusIcon,
  actionExtend: ChevronDownIcon,
  actionReduce: ChevronUpIcon,
  actionExpand: ArrowsPointingOutIcon,
  actionOpen: FolderOpenIcon,
  actionEdit: PencilIcon,
  actionEditBulk: TableCellsIcon,
  actionIncrement: MinusCircleIconSolid,
  actionDecrement: PlusCircleIconSolid,
  group: UserGroupIcon,
  staff: UsersIcon,
  actionEditAlt: PencilSquareIcon,
  actionDelete: TrashIcon,
  actionSettings: CogIcon,
  actionLock: LockClosedIcon,
  actionMenu: CogIcon,
  actionConfirm: CheckIcon,
  actionDownload: ArrowDownTrayIcon,
  actionImpute: CheckBadgeIcon,
  badgeCheck: CheckBadgeIcon,
  actionPrint: PrinterIcon,
  actionCancel: XCircleIcon,
  actionCancel2: XCircleIconSolid,
  actionClone: Square2StackIcon,
  actionArchive: ArchiveBoxIcon,
  actionExtract: ArrowRightOnRectangleIcon,
  viewPeriodIcon: ViewColumnsIcon,
  displayModeIcon: Squares2X2Icon,
  mail: EnvelopeIcon,
  phone: PhoneIcon,
  offerOther: CubeIcon,
  offerProducts: ShoppingBagIcon,
  back: ArrowUturnLeftIcon,
};
