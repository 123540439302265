import { CalendarIcon } from '@heroicons/react/24/outline';
import { dateService } from '@mabadive/app-common-services';
import { Input } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { dateServiceCore } from 'src/stories/services';
import { AppHackableCalendarDialog } from '../../03-advanced/AppHackableCalendar';
import { AppInputDatePickerProps } from './AppInputDatePicker';

export const AppInputDatePickerMaterial = ({
  placeholder,
  value: inputValue,
  onChange,
  minDate: minDateInput,
  maxDate: maxDateInput,
  minYear,
  maxYear,
  initialView,
  dialogZIndex,
  className,
}: AppInputDatePickerProps) => {
  const [open, setOpen] = useState(false);

  const value = useMemo(
    () => dateServiceCore.truncateTimeUTC(inputValue),
    [inputValue],
  );

  const minDate = useMemo(() => {
    if (minDateInput) {
      return dateServiceCore.truncateTimeUTC(minDateInput);
    } else if (minYear !== undefined && minYear !== null) {
      return new Date(Date.UTC(minYear, 0, 1));
    }
  }, [minDateInput, minYear]);

  const maxDate = useMemo(() => {
    if (maxDateInput) {
      return dateServiceCore.truncateTimeUTC(maxDateInput);
    } else if (maxYear !== undefined && maxYear !== null) {
      return new Date(Date.UTC(maxYear, 11, 31));
    }
  }, [maxDateInput, maxYear]);

  useEffect(() => {
    if (inputValue) {
      const d = new Date(inputValue);
      if (
        d.getUTCHours() ||
        d.getUTCMinutes() ||
        d.getUTCSeconds() ||
        d.getUTCMilliseconds()
      ) {
        // eslint-disable-next-line no-console
        console.info(
          '[AppInputDatePickerMaterial] remove hours/mn/s/ms from input date',
          inputValue,
        );
        onChange && onChange(value);
      }
    }
    // trigger only if 'inputValue' changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const [innerDate, setInnerDate] = useState(value);

  const [innerDateString, setInnerDateString] = useState(
    value ? dateService.formatUTC(value, 'DD/MM/YYYY') : '',
  );

  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (value) {
      const str = dateService.formatUTC(value, 'DD/MM/YYYY');
      if (str !== innerDateString) {
        setInnerDateString(str);
      }
    }
    // only trigger if "value" is updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const allowManualInput = true;

  return (
    <AppHackableCalendarDialog
      dialogZIndex={dialogZIndex}
      open={open}
      setOpen={setOpen}
      selectedDate={innerDate}
      minDate={minDate}
      maxDate={maxDate}
      initialView={initialView}
      onSelectDate={(date) => {
        setInnerDate(date);
        setInnerDateString(dateService.formatUTC(date, 'DD/MM/YYYY'));
        onChange && onChange(date);
      }}
    >
      <div className={`relative  ${className}`}>
        <Input
          required={false}
          placeholder={placeholder}
          value={innerDateString}
          className={'w-full pr-5'}
          type="text"
          readOnly={!allowManualInput}
          onChange={(e) => {
            const inputDateString = e.target.value;
            if (inputDateString?.trim().length) {
              const { date, isValid } = dateService.parseUTCFromDDMMYYYY(
                inputDateString,
                {
                  minDate,
                  maxDate,
                },
              );
              setIsValid(isValid);
              if (
                isValid &&
                dateService.formatUTC(innerDate, 'DD/MM/YYYY') !==
                  inputDateString
              ) {
                setInnerDate(date);
                setInnerDateString(inputDateString);
                onChange && onChange(date);
              } else {
                setInnerDateString(inputDateString);
              }
            } else {
              setInnerDate(undefined);
              setInnerDateString('');
              onChange && onChange(null);
              setIsValid(false);
            }
          }}
          onBlur={() => {
            const inerDateValidString = dateService.formatUTC(
              innerDate,
              'DD/MM/YYYY',
            );
            if (inerDateValidString !== innerDateString) {
              setInnerDateString(inerDateValidString);
              setIsValid(true);
            }
          }}
        />
        <CalendarIcon
          onClick={() => {
            setOpen(!open);
          }}
          className={
            'absolute top-1 -right-1 text-gray-600 hover:text-gray-800 w-6 h-6 cursor-pointer'
          }
        />
      </div>
    </AppHackableCalendarDialog>
  );
};
