import { AppAuthenticatedUser } from '@mabadive/app-common-model';
import { dataSorter } from '@mabadive/app-common-services';
import { CUS_BookingParticipantFull } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/models';
import {
  DiveCenterBookingPageAggregatedBookingResume,
  DiveCenterBookingPageAggregatedData,
  DiveCenterBookingPageDataCore,
  DiveCenterBookingPageLoadedContent,
  DiveCenterBookingPageUpdateState,
} from '../../model';
import { diveCenterBookingPageAggregatedBookingResumeBuilderBookingFull } from './diveCenterBookingPageAggregatedBookingResumeBuilderBookingFull.service';
import { diveCenterBookingPageAggregatedBookingResumeBuilderCore } from './diveCenterBookingPageAggregatedBookingResumeBuilderCore.service';
import { diveCenterBookingPageAggregatedBookingResumeBuilderParticipantFull } from './diveCenterBookingPageAggregatedBookingResumeBuilderParticipantFull.service';

export const diveCenterBookingPageAggregatedBookingResumeBuilder = {
  aggregateAll,
  aggregatedDataCore,
};

function aggregatedDataCore({
  loadedContent,
  bookingMembersIdsInitialOrder,
  securityUser,
}: {
  loadedContent: DiveCenterBookingPageLoadedContent;
  bookingMembersIdsInitialOrder: string[];
  securityUser: AppAuthenticatedUser;
}): {
  dataCore: DiveCenterBookingPageDataCore;
  aggregatedDataCore: DiveCenterBookingPageAggregatedData;
} {
  if (!loadedContent?.diverBookingGraphqlResult) {
    return {
      dataCore: undefined,
      aggregatedDataCore: undefined,
    };
  }
  let dataCore: DiveCenterBookingPageDataCore = loadedContent?.entities;

  if (loadedContent?.entities?.bookingCustomerUpdates?.length > 0) {
    for (const customerUpdate of loadedContent?.entities
      ?.bookingCustomerUpdates) {
      // apply all previous changes
      if (customerUpdate.payload.updateState) {
        dataCore =
          diveCenterBookingPageAggregatedBookingResumeBuilderCore.aggregateDataCore(
            {
              updateState: customerUpdate.payload.updateState,
              entities: dataCore,
            },
          );
      }
    }
  }
  const aggregatedDataCore = buildAggregatedData({
    dataCore,
    bookingMembersIdsInitialOrder,
    securityUser,
  });
  return { dataCore, aggregatedDataCore };
  // return buildAggregatedData({ aggregatedDataCore, clubReference, diveCenter });
}

function aggregateAll({
  dataCore: dataCoreInput,
  updateState,
  bookingMembersIdsInitialOrder,
  securityUser,
}: {
  dataCore: DiveCenterBookingPageDataCore;
  updateState: DiveCenterBookingPageUpdateState;
  bookingMembersIdsInitialOrder: string[];
  securityUser: AppAuthenticatedUser;
}): DiveCenterBookingPageAggregatedData {
  if (!dataCoreInput || !updateState?.bookingSessionsChanges) {
    return {
      bookingResumes: [],
      entities: {
        clubPublicReference: undefined,
        clubReference: undefined,
        diveCenter: undefined,
        clubResumes: [],
        clubDivers: [],
        bookings: [],
        bookingSessions: [],
        bookingProducts: [],
        bookingMembers: [],
        diveSessions: [],
        bookingSessionParticipants: [],
        clubParticipants: [],
        bookingCustomerUpdates: [],
        bookingPayments: [],
        inquiryResponses: [],
      },
    };
  }

  // now apply local changes
  const dataCoreUpdated =
    diveCenterBookingPageAggregatedBookingResumeBuilderCore.aggregateDataCore({
      updateState,
      entities: dataCoreInput,
    });

  return buildAggregatedData({
    dataCore: dataCoreUpdated,
    bookingMembersIdsInitialOrder,
    securityUser,
  });
}

function buildAggregatedData({
  dataCore,
  bookingMembersIdsInitialOrder,
  securityUser,
}: {
  dataCore: DiveCenterBookingPageDataCore;
  bookingMembersIdsInitialOrder: string[];
  securityUser: AppAuthenticatedUser;
}) {
  const bookingParticipantsFull: CUS_BookingParticipantFull[] =
    dataCore.bookingProducts
      .filter((x) => x.type === 'dive')
      .map((bookingProduct) => {
        return diveCenterBookingPageAggregatedBookingResumeBuilderParticipantFull.buildParticipantFull(
          {
            dataCore,
            bookingProduct,
          },
        );
      });

  const aggregatedBookings: DiveCenterBookingPageAggregatedBookingResume[] =
    dataCore.bookings.map((booking) =>
      diveCenterBookingPageAggregatedBookingResumeBuilderBookingFull.buildBookingFull(
        {
          dataCore,
          booking,
          bookingParticipantsFull,
          bookingMembersIdsInitialOrder,
          securityUser,
        },
      ),
    );

  const bookingResumes: DiveCenterBookingPageAggregatedBookingResume[] =
    dataSorter.sortMultiple(aggregatedBookings, {
      getSortAttributes: (x: DiveCenterBookingPageAggregatedBookingResume) => [
        {
          value: x?.booking?.bookingDate,
          type: 'full-text',
          asc: false,
        },
      ],
      asc: true,
    });

  const data: DiveCenterBookingPageAggregatedData = {
    entities: dataCore,
    bookingResumes,
  };
  return data;
}
