/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubPaymentPlatformLinkInfo } from '@mabadive/app-common-model';
import { default as React, useCallback, useEffect, useState } from 'react';
import { customerPaymentPlatformClient } from 'src/business/_core/data/app-operation';
import { AppButton, AppLoader } from 'src/business/_core/modules/layout';
import { AppIconsAction } from 'src/business/_core/modules/layout/icons';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { uiStore } from 'src/business/_core/store';

export function BookingPaymentCardOpenPaymentLinkDialogContent({
  clubPurchasePaymentId,
  onCancel,
  onConfirm,
}: {
  clubPurchasePaymentId: string;
  onCancel: () => any;
  onConfirm: () => any;
}) {
  const [operationInProgressMessage, setOperationInProgressMessage] =
    useState<string>();

  const [paymentLinkInfo, setPaymentLinkInfo] =
    useState<ClubPaymentPlatformLinkInfo>();

  const loadPaymentLink = useCallback(async () => {
    setOperationInProgressMessage('Préparation du paiement');
    try {
      const paymentLinkInfo: ClubPaymentPlatformLinkInfo =
        await customerPaymentPlatformClient.getPaymentLink({
          clubPurchasePaymentId,
        });

      if (paymentLinkInfo.paymentLink) {
        // redirection automatique
        window.location.href = paymentLinkInfo.paymentLink;
      }
      setTimeout(() => setPaymentLinkInfo(paymentLinkInfo), 1000);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      appLogger.error(
        'Erreur innatendue lors de la création du paiement Stripe',
      );
      uiStore.snackbarMessage.set({
        type: 'error',
        content: 'Erreur innatendue lors de la création du paiement!',
      });
    } finally {
      setOperationInProgressMessage(undefined);
    }
  }, [clubPurchasePaymentId]);

  useEffect(() => {
    loadPaymentLink();
  }, [loadPaymentLink]);

  return (
    <div className="app-p-content grid gap-8">
      <h2 className="text-xl font-bold text-gray-700">Paiement en ligne</h2>
      <div className={'grid gap-4'}>
        {paymentLinkInfo?.paymentLink && (
          <div className="text-gray-700">
            En cliquant sur le bouton "Payer maintenant", vous allez être
            redirigé sur la plateforme de paiement sécurisée Stripe.
          </div>
        )}
        {/* {paymentLinkInfo?.platformEnv === 'test' && (
          <div className="text-gray-700">
            Vous êtes sur un environnement de TEST. Pour simuler un paiement,
            vous pouvez utiliser les numéros de carte suivants:
            <ul>
              <li>
                <b>Paiement réussi</b>: 4242 4242 4242 4242
              </li>
            </ul>
          </div>
        )} */}
        {operationInProgressMessage && (
          <AppLoader type="loading" message={operationInProgressMessage} />
        )}
      </div>
      <div className="flex-grow flex gap-2 justify-around">
        <AppButton
          className={'text-base font-bold uppercase'}
          style={{ minWidth: '6rem' }}
          color={'gray-outline-light'}
          icon={() => <AppIconsAction.cancel className="w-6 h-6 mr-2" />}
          tabIndex={500}
          onClick={(e) => {
            onCancel();
          }}
        >
          Annuler
        </AppButton>

        {!operationInProgressMessage && paymentLinkInfo?.paymentLink && (
          <AppButton
            href={paymentLinkInfo?.paymentLink}
            // target="_blank"
            // rel="noopener noreferrer"
            className="text-base font-bold uppercase"
            style={{ minWidth: '6rem' }}
            color={'primary-outline-light'}
            icon={() => <AppIconsAction.creditCard className="w-6 h-6 mr-2" />}
            onClick={(e) => {
              onConfirm();
            }}
          >
            Payer maintenant
          </AppButton>
        )}
      </div>
    </div>
  );
}
