import {
  ClubSettingsPlanning,
  ClubSettingsPlanningDiveTour,
  DiveSession,
  DiveSessionBookingConfig,
  DiveSessionResumeFull,
  DiveTourSession2,
} from '@mabadive/app-common-model';
import { commonDiveSessionBuilder } from '../../../business/session/commonDiveSessionBuilder.service';
import { commonDiveSessionReferenceParser } from '../../../business/session/commonDiveSessionReferenceParser.service';
import { defaultPlanningPeriodConfigsSelector } from './defaultPlanningPeriodConfigsSelector.service';

export const virtualDiveSessionBuilder = {
  buildDailyVirtualSessions,
  getByReference,
  convertToDiveSessionResumeFull,
  buildSessionReferenceForVirtualDiveSession,
};

function convertToDiveSessionResumeFull(
  virtualSession: DiveSession,
): DiveSessionResumeFull {
  const virtualSessionFull: DiveSessionResumeFull = {
    ...virtualSession,
    bookingSessionParticipants: [],
    participants: [],
    groups: [],
    diveSite: undefined,
  };
  return virtualSessionFull;
}
function getByReference({
  diveSessionReference,
  clubReference,
  diveCenterId,
  settingsPlanning,
}: {
  diveSessionReference: string;
  clubReference: string;
  diveCenterId: string;
  settingsPlanning: ClubSettingsPlanning;
}): DiveSession {
  const { dayReference } =
    commonDiveSessionReferenceParser.parseSessionReference(
      diveSessionReference,
    );
  if (!dayReference) {
    console.warn(
      `[virtualDiveSessionBuilder.getByReference] 'dayReference' not found in "${diveSessionReference}"`,
    );
    return;
  }
  const dailyVirtualSessions = buildDailyVirtualSessions({
    dayReference,
    clubReference,
    diveCenterId,
    settingsPlanning,
  });
  return dailyVirtualSessions.find((x) => x.reference === diveSessionReference);
}

function buildDailyVirtualSessions({
  dayReference,
  clubReference,
  diveCenterId,
  settingsPlanning,
}: {
  dayReference: string;
  clubReference: string;
  diveCenterId: string;
  settingsPlanning: ClubSettingsPlanning;
}): DiveSession[] {
  if (!dayReference) {
    console.error(
      "[virtualDiveSessionBuilder.buildDailyVirtualSessions] 'dayReference' not set",
    );
    return [];
  }

  const date: Date =
    commonDiveSessionReferenceParser.parseDayReference(dayReference);

  const diveSessions: DiveSession[] = [];

  const diveTours: ClubSettingsPlanningDiveTour[] =
    defaultPlanningPeriodConfigsSelector.getPeriodConfigsDiveTours({
      date,
      settingsPlanning,
    });

  for (const diveTour of diveTours) {
    const dayTime = diveTour.session1.dayTime;

    const time = new Date(date);
    time.setUTCHours(dayTime.hours);
    time.setUTCMinutes(dayTime.minutes);

    const status = diveTour.isOpen ? 'on' : 'off';

    const boatsIds = diveTour.session1.boatsIds;

    const virtualDiveSessionReference = `${diveTour.reference};s1`;

    const reference = buildSessionReferenceForVirtualDiveSession({
      virtualDiveSessionReference,
      dayReference,
      clubReference,
    });

    const diveTourSession2: DiveTourSession2 = buildvirtualDiveTourSession2({
      diveTour,
      date,
    });

    // pour l'instant: une seule config, donc on prend la première
    const diveTourBookingConfig = diveTour.bookingConfigs?.[0];
    const bookingConfig: DiveSessionBookingConfig = diveTourBookingConfig
      ? {
          firstDive: diveTourBookingConfig.conf.firstDive,
          snorkelingSupervised: diveTourBookingConfig.conf.snorkelingSupervised,
        }
      : undefined;
    const virtualSession: DiveSession = {
      status,
      time,
      clubReference,
      diveCenterId,
      dayReference,
      reference,
      virtualDiveSessionReference,
      virtualDiveTourReference: diveTour.reference,
      special: diveTour.session1.special ?? false,
      name: diveTour.session1.name,
      emojiId: diveTour.session1.emojiId,
      diveSiteId: diveTour.session1.diveSiteId,
      theme: diveTour.theme,
      type: diveTour.type,
      details: {
        defaultDiveMode: diveTour.defaultDiveMode,
      },
      boatsIds,
      diveTourSession1: {},
      diveTourSession2,
      sessionsCount: diveTourSession2 ? 2 : 1,
      isVirtual: true,
      bookingConfig,
    };
    diveSessions.push(virtualSession);
  }
  return diveSessions;
}

function buildvirtualDiveTourSession2({
  diveTour,
  date,
}: {
  diveTour: ClubSettingsPlanningDiveTour;
  date: Date;
}): DiveTourSession2 {
  if (!!diveTour.session2) {
    const dayTime = diveTour.session2.dayTime;
    const time = new Date(date);
    time.setUTCHours(dayTime.hours);
    time.setUTCMinutes(dayTime.minutes);
    const session2: DiveTourSession2 = {
      time,
      diveSiteId: diveTour.session2.diveSiteId,
      sameStaffSession1: true,
    };
    return session2;
  }
}

function buildSessionReferenceForVirtualDiveSession({
  virtualDiveSessionReference,
  dayReference,
  clubReference,
}: {
  virtualDiveSessionReference: string;
  dayReference: string;
  clubReference: string;
}) {
  const virtualAlgoVersion = 2;
  const suffix = `s-${virtualDiveSessionReference}-v${virtualAlgoVersion}`;

  const reference = commonDiveSessionBuilder.buildDiveSessionReference({
    suffix,
    dayReference,
    clubReference,
  });
  // NOTE: il est important de conserver la date dans cette référence, car elle sera utilisée ensuite pour retrouver le jour
  return reference;
}
