import { Booking, ClubPublicSettings } from '@mabadive/app-common-model';
import { diverBookingBuilder } from '../../../business/club-commerce';
import { commonEntityBuilder } from '../../../data';

export const diverBookingCreator = { createBooking };

function createBooking({
  _id, // si pas renseigné, sera généré
  clubReference,
  diveCenterId,
  bookingContactDiverId,
  bookingStatus = 'confirmed',
  active = true,
  bookingJourney,
  bookingInitialPlan,
  bookingGroup,
  details,
  bookingCustomerConfig,
  bookingCustomerSpacePrivate,
  isSharedBooking,
  isOnlineBooking,
  publicSettings,
}: Pick<Booking, '_id' | 'clubReference' | 'diveCenterId' | 'details'> &
  Partial<
    Pick<
      Booking,
      | 'bookingContactDiverId'
      | 'bookingStatus'
      | 'active'
      | 'bookingJourney'
      | 'bookingInitialPlan'
      | 'bookingGroup'
      | 'bookingCustomerConfig'
      | 'bookingCustomerSpacePrivate'
      | 'isSharedBooking'
      | 'isOnlineBooking'
    >
  > & {
    publicSettings: ClubPublicSettings;
  }): Booking {
  const now = new Date();

  const defaultBookingCustomerSpace =
    diverBookingBuilder.createDefaultBookingCustomerSpace({
      publicSettings,
    });

  if (!bookingCustomerConfig) {
    bookingCustomerConfig = defaultBookingCustomerSpace.bookingCustomerConfig;
  }
  if (!bookingCustomerSpacePrivate) {
    bookingCustomerSpacePrivate =
      defaultBookingCustomerSpace.bookingCustomerSpacePrivate;
  }

  const booking = commonEntityBuilder.buildEntity<Booking>(
    {
      _id,
      clubReference,
      diveCenterId,
      bookingStatus,
      bookingContactDiverId,
      active,
      bookingDate: new Date(),
      bookingLastUpdateDate: new Date(),
      bookingJourney,
      bookingInitialPlan,
      bookingGroup,
      details,
      bookingCustomerConfig,
      bookingCustomerSpacePrivate,
      isSharedBooking,
      isOnlineBooking,
    },
    now,
  );

  return booking;
}
