import { StackLogLoggerOptions } from '../logger/stack-log-logger-options';

export class StackLogGlobalHistoryService<T extends StackLogLoggerOptions<any>> {

  private globalHistory: T[] = [];

  public getHistory(): T[] {
    return this.globalHistory;
  }


  public appendToHistory(logOptions: T, maxStackSize: number) {
    const historyList = this.getHistory();
    historyList.push(logOptions);
    if (historyList.length > maxStackSize) {
      // remove first item as max size has been reached
      historyList.shift();
    }
  }
}