import {
  AppBookletGql_Customer,
  AppBookletPageGql_Customer,
  AppBookletWithPagesGql_Customer,
  ClubResumeGql_Public,
  CustomerEcommerceProductArticleFull,
  Customer_ClubResumePublic,
  EcommerceProductArticleGql_Customer,
  EcommerceProductGql_Customer,
} from '@mabadive/app-common-model';
import { clubResumePublicCustomerBuilder } from '../../../_core/data/store-repository';
import {
  CustomerEcommerceDataFetchedData,
  CustomerEcommerceDataLoadedContent,
} from '../_model';

export const customerEcommerceDataLoadedContentBuilder = { buildContent };
function buildContent({
  fetchedData,
  diveCenterGraphqlResult,
}: {
  fetchedData: CustomerEcommerceDataFetchedData;
  diveCenterGraphqlResult: ClubResumeGql_Public;
}): CustomerEcommerceDataLoadedContent {
  const clubResume: Customer_ClubResumePublic =
    clubResumePublicCustomerBuilder.buildFromGql_Public(
      diveCenterGraphqlResult,
    );

  const content: CustomerEcommerceDataLoadedContent = {
    clubResume,
    categories: [], // EcommerceCategoryGql_Customer[];
    products: [], // EcommerceProductGql_Customer[];
    productArticles: [], // EcommerceProductArticleGql_Customer[];
    productArticlesFull: [], // CustomerEcommerceProductArticleFull[];
    productCategoryConfigs: [], // EcommerceProductCategoryConfigGql_Customer[];
    booklets: [], // AppBookletGql_Customer[];
    bookletPages: [], // AppBookletPageGql_Customer[];
  };

  if (fetchedData?.categoriesWithBooklet) {
    for (const categoryWithBooklet of fetchedData.categoriesWithBooklet) {
      const { booklet: bookletWithPages, ...category } = categoryWithBooklet;
      content.categories.push(category);
      appendBooklet({ bookletWithPages, content });
    }
  }
  if (fetchedData?.productsFull) {
    for (const productFull of fetchedData.productsFull) {
      const {
        booklet: bookletWithPages,
        categoryConfigs,
        articles: articlesFull,
        ...product
      } = productFull;
      content.products.push(product);
      appendBooklet({ bookletWithPages, content });
      content.productCategoryConfigs.push(...categoryConfigs);
      for (const articleFull of articlesFull) {
        const { booklet: bookletWithPages, ...article } = articleFull;
        content.productArticles.push(article);
        appendBooklet({ bookletWithPages, content });
      }
    }

    content.productArticlesFull = content.productArticles.map((article) => {
      return buildCustomerEcommerceProductArticleFull({
        article,
        products: content.products,
        booklets: content.booklets,
        bookletPages: content.bookletPages,
      });
    });
  }

  return content;
}
export function buildCustomerEcommerceProductArticleFull({
  article,
  products,
  booklets,
  bookletPages,
}: {
  article: EcommerceProductArticleGql_Customer;
  products: EcommerceProductGql_Customer[];
  booklets: AppBookletGql_Customer[];
  bookletPages: AppBookletPageGql_Customer[];
}): CustomerEcommerceProductArticleFull {
  const product = products.find((x) => x._id === article.productId);

  const validPrices = article.pricing?.prices.filter((p) => p.unitPrice > 0);

  const minPrice = validPrices.reduce((acc, p) => {
    return acc === 0 || p.unitPrice < acc ? p.unitPrice : acc;
  }, 0);

  const maxPrice = validPrices.reduce((acc, p) => {
    return p.unitPrice > acc ? p.unitPrice : acc;
  }, 0);

  const articleBooklet = booklets.find((b) => article.bookletId === b._id);
  const articleBookletPage = bookletPages.find(
    (page) => articleBooklet.rootPageId === page._id,
  );

  const productBooklet = booklets.find((b) => product.bookletId === b._id);
  const productBookletPage = bookletPages.find(
    (page) => productBooklet.rootPageId === page._id,
  );
  const productArticleFull: CustomerEcommerceProductArticleFull = {
    article,
    product,
    validPrices,
    minPrice,
    maxPrice,
    isMultiPrices: validPrices.length > 1,
    bookingAvailable:
      validPrices.length > 0 && article.details?.onlineBooking?.showButton,
    articleBookletPage,
    productBookletPage,
  };
  return productArticleFull;
}

function appendBooklet({
  bookletWithPages,
  content,
}: {
  bookletWithPages: AppBookletWithPagesGql_Customer;
  content: CustomerEcommerceDataLoadedContent;
}) {
  if (bookletWithPages) {
    const { pages, ...booklet } = bookletWithPages;
    content.booklets.push(booklet);
    for (const page of pages) {
      content.bookletPages.push(page);
    }
  }
}
