export const diveCenterBookingUrlBuilder = {
  buildBookingHomeUrl,
  buildBookingCheckoutUrl,
  buildBookingInquiryUrl,
  buildBookingOnlinePaymentUrl,
  buildDiveCenterEcommerceHomeUrl,
  buildDiveCenterHomeUrl,
  buildDiveCenterShoppingCartUrl,
  buildDiveCenterShoppingBookingContactUrl,
  buildDiveCenterShoppingBookingSubmitUrl,
};

function buildDiveCenterHomeUrl({
  clubPublicReference,
}: {
  clubPublicReference: string;
}) {
  return `/dive-center/${clubPublicReference}`;
}
function buildDiveCenterEcommerceHomeUrl({
  clubPublicReference,
}: {
  clubPublicReference: string;
}) {
  return `/dive-center/${clubPublicReference}/activities`;
}
function buildDiveCenterShoppingBookingContactUrl({
  clubPublicReference,
}: {
  clubPublicReference: string;
}) {
  return `/dive-center/${clubPublicReference}/order/booking-contact`;
}
function buildDiveCenterShoppingBookingSubmitUrl({
  clubPublicReference,
}: {
  clubPublicReference: string;
}) {
  return `/dive-center/${clubPublicReference}/order/booking-submit`;
}
function buildDiveCenterShoppingCartUrl({
  clubPublicReference,
}: {
  clubPublicReference: string;
}) {
  return `/dive-center/${clubPublicReference}/shopping-cart`;
}

function buildBookingHomeUrl({
  clubPublicReference,
  bookingId,
}: {
  clubPublicReference: string;
  bookingId: string;
}) {
  return `/dive-center/${clubPublicReference}/booking/${bookingId}`;
}

function buildBookingCheckoutUrl({
  clubPublicReference,
  bookingId,
}: {
  clubPublicReference: string;
  bookingId: string;
}) {
  const baseUrl = buildBookingHomeUrl({
    clubPublicReference,
    bookingId,
  });
  return `${baseUrl}/checkout`;
}
function buildBookingInquiryUrl({
  clubPublicReference,
  bookingId,
  inquiryId,
  bookingMemberId,
  diverId,
  inquiryResponseId,
}: {
  clubPublicReference: string;
  bookingId: string;
  inquiryId: string;
  diverId: string;
  inquiryResponseId: string;
  bookingMemberId: string;
}) {
  const baseUrl = buildBookingHomeUrl({
    clubPublicReference,
    bookingId,
  });
  const params = new URLSearchParams();
  params.append('inquiryId', inquiryId);
  params.append('bookingMemberId', bookingMemberId);
  params.append('diverId', diverId);
  if (inquiryResponseId) {
    params.append('inquiryResponseId', inquiryResponseId);
  }
  return `${baseUrl}/inquiry?${params.toString()}`;
}

function buildBookingOnlinePaymentUrl({
  clubPublicReference,
  bookingId,
}: {
  clubPublicReference: string;
  bookingId: string;
}) {
  const baseUrl = buildBookingHomeUrl({
    clubPublicReference,
    bookingId,
  });
  return `${baseUrl}/online-payments`;
}
