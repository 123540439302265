import { ClubDiverFull } from '@mabadive/app-common-model';
import {
  attributeStoreFactory,
  loadableAttributeCollectionStoreFactory,
} from '@mabadive/app-common-services';
import { getBaseStore } from 'src/business/_core/store/baseStore';
import { ClubDiverResumeCollectionLoadingState } from './ClubDiverResumeCollectionLoadingState.type';

export const CLUB_DIVER_RESUME_COLLECTION_INITIAL_LOADING_STATE: ClubDiverResumeCollectionLoadingState =
  {
    loaded: { loadedState: 'none', loadedCount: 0, totalCount: undefined },
    initialLoading: {
      status: 'not-started',
    },
  };

const clubDiverFullCollection =
  loadableAttributeCollectionStoreFactory.create<ClubDiverFull>(
    getBaseStore(),
    'c_divers',
  );
const loadingState =
  attributeStoreFactory.create<ClubDiverResumeCollectionLoadingState>(
    getBaseStore(),
    'c_divers_load',
    CLUB_DIVER_RESUME_COLLECTION_INITIAL_LOADING_STATE,
  );

export const clubDiverStore = {
  clubDiverFullCollection,
  loadingState,
  setInitialData,
  resetStore,
  addToStore,
};

function setInitialData({
  totalCount,
  clubDiversFull,
}: {
  totalCount: number;
  clubDiversFull: ClubDiverFull[];
}) {
  clubDiverFullCollection.setAll(clubDiversFull);

  const loadedCount = clubDiversFull.length;
  loadingState.reduce((state) => ({
    ...state,
    initialLoading: {
      status: 'success',
    },
    loaded: {
      loadedCount,
      totalCount,
      loadedState: loadedCount !== totalCount ? 'partial' : 'full',
    },
  }));
}

function resetStore() {
  loadingState.set(CLUB_DIVER_RESUME_COLLECTION_INITIAL_LOADING_STATE);
  clubDiverFullCollection.removeAll();
}

function addToStore(diver: ClubDiverFull) {
  clubDiverFullCollection.setOne({
    identify: (x) => x._id === diver._id,
    value: diver,
    actionId: 'addToStore',
  });
}
