/* eslint-disable jsx-a11y/alt-text */
import {
  ClubPublicSettings,
  EcommerceProductArticleGql_Customer,
  EcommerceProductGql_Customer,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useAppCurrencyMain } from '../../../../../../pages';
import { AppPriceLabel } from '../../../../../club/modules/_common/ui';

export const EcommerceProductPreviewCover_Resume = ({
  clubPublicSettings,
  product,
  productArticles,
  className,
}: {
  clubPublicSettings: ClubPublicSettings;
  product: Pick<EcommerceProductGql_Customer, 'name' | 'details'>;
  productArticles?: EcommerceProductArticleGql_Customer[];
  className?: string;
}) => {
  const mainCurrency = useAppCurrencyMain({ clubPublicSettings });

  const pricesBounds = useMemo(() => {
    const unitPrices = productArticles
      .reduce(
        (acc, p) => acc.concat(p.pricing.prices.map((pr) => pr.unitPrice)),
        [] as number[],
      )
      .filter((x) => x > 0);
    if (unitPrices.length > 0) {
      return {
        min: Math.min(...unitPrices),
        max: Math.max(...unitPrices),
      };
    }
    return {
      min: 0,
      max: 0,
    };
  }, [productArticles]);

  return (
    <div className={clsx('text-sm font-normal text-gray-600', className)}>
      {productArticles.length > 0 && (
        <span>
          <b>
            {productArticles.length} tarif
            {productArticles.length > 1 ? 's' : ''}{' '}
          </b>
          {product.details.minAge > 0 && (
            <span> dès {product.details.minAge} ans</span>
          )}
        </span>
      )}
      {pricesBounds?.min > 0 && (
        <span className="">
          {pricesBounds?.min === pricesBounds?.max ? (
            <span>
              {' - '}
              <AppPriceLabel
                className="font-bold"
                amount={pricesBounds?.min}
                mainCurrency={mainCurrency}
              />
            </span>
          ) : (
            <span>
              {' '}
              à partir de{' '}
              <AppPriceLabel
                className="font-bold"
                amount={pricesBounds?.min}
                mainCurrency={mainCurrency}
              />
            </span>
          )}
        </span>
      )}
    </div>
  );
};
