"use strict";
exports.__esModule = true;
exports.SESSION_IMPORTANT_NOTES_TAG = void 0;
exports.SESSION_IMPORTANT_NOTES_TAG = {
    reference: 'warning',
    avatar: {
        label: 'N',
        color: '#fff',
        background: '#d03232;'
    },
    content: {
        label: 'Notes importantes',
        color: '#fff',
        background: '#d03232;'
    }
};
