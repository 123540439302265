import { DiveSessionGroup, DiveSessionGroupDiveMode } from '@mabadive/app-common-model';
import { dataSorter } from '../../data/sorter/dataSorter.service';

export type SortableDiveSessionGroup = Pick<DiveSessionGroup, 'immersionStart' | 'diveMode' | '_id'>;

export const diveSessionGroupSorter = {
  sortGroups,
};

function sortGroups<T extends SortableDiveSessionGroup>(groups: T[]) {
  return dataSorter.sortMultiple(groups, {
    getSortAttributes: (group: T) => [
      {
        value: getSortByDiveMode(group.diveMode),
      },
      {
        value: group.immersionStart,
        type: 'full-text',
      },
      {
        value: group._id,
        type: 'full-text',
      },
    ],
  });
}

function getSortByDiveMode(x: DiveSessionGroupDiveMode): any {
  switch (x) {
    case 'first-dive':
      return 1;
    case 'training':
      return 2;
    case 'supervised':
      return 3;
    case 'autonomous':
      return 4;
  }
}
