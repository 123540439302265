import React from 'react';

export const AppDialogModalFixedBar = ({
  children,
}: {
  children?: React.ReactNode | React.ReactNode[];
}) => {
  return (
    <div className="fixed border-t border-gray-100 bg-white bottom-0 left-0 right-0 py-2 app-px-content">
      {children}
    </div>
  );
};
