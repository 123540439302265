import { concat, from, Observable, of, timer } from 'rxjs';
import { concatMap, reduce, take, takeLast } from 'rxjs/operators';

// NOTE: impossible de mutualiser ça côté serveur, ça créé une erreur dans ts-node (l'observable passé en paramètre n'est pas reconnu)
// à retester quand ts-node supportera les project references

// En attenant:
// web: rxjsUtilWeb
// node: rxjsUtilNode

export const rxjsUtilWeb = {
  processOneByOne,
  getSnapshot,
  timerLongInteger,
};

function timerLongInteger(ms: number) {
  // NOTE: timer ne fonctionne que si le timeout est inférieur à 2147483647: https://github.com/ReactiveX/rxjs/issues/3015#issuecomment-340654132=
  if (ms > 2147483647) {
    const MAX = 2147483647;
    const n = Math.floor(ms / MAX);
    const r = ms % MAX;
    return concat(timer(MAX, MAX).pipe(take(n)), timer(r)).pipe(takeLast(1));
  } else {
    return timer(ms);
  }
}

function processOneByOne<T, R = T>({
  items,
  op,
}: {
  items: T[];
  op: (item: T, index?: number) => Observable<R>;
}): Observable<R[]> {
  if (items.length === 0) {
    return of([]) as Observable<R[]>;
  }

  return from(items).pipe(
    // process one by one (wait until previous finish)
    // tap(x => libLogger.warn('[rxjsUtilWeb.processOneByOne] from:', x)),
    // tap(x => libLogger.warn('[rxjsUtilWeb.processOneByOne] op:', op)),
    concatMap((value, index) =>
      op(value, index)
        .pipe
        // tap(x => libLogger.warn('[rxjsUtilWeb.processOneByOne] x:', x)),
        // catchError(err => {
        //   libLogger.error('[rxjsUtilWeb.processOneByOne] err:', err)
        //   return throwError(err);
        // }),
        // first(),
        (),
    ),
    reduce((array, current) => array.concat([current]), []),
  );
}

function getSnapshot<T>(observable: Observable<T>): T | undefined {
  let snapshot: T;
  const subscription = observable.subscribe((value) => {
    snapshot = value;
  });
  subscription.unsubscribe();
  return snapshot;
}
