import { AppEnvId } from './model/AppEnvId.type';

export function getEnvIdFromUrl(): AppEnvId {
  switch (window.location.hostname) {
    case 'alpha.mabadive.com':
    case 'pro.alpha.mabadive.com':
    case 'diver.alpha.mabadive.com':
      return 'alpha';
    case 'demo468.mabadive.com':
    case 'pro.demo468.mabadive.com':
    case 'diver.demo468.mabadive.com':
      return 'demo468';
    case 'demo976.mabadive.com':
    case 'pro.demo976.mabadive.com':
    case 'diver.demo976.mabadive.com':
      return 'demo976';
    case 'secours.mabadive.com':
    case 'pro.secours.mabadive.com':
    case 'diver.secours.mabadive.com':
      return 'secours';
    case 'next.mabadive.com':
    case 'pro.next.mabadive.com':
    case 'diver.next.mabadive.com':
      return 'next';
    case 'mabadive.com':
    case 'pro.mabadive.com':
    case 'diver.mabadive.com':
      return 'prod';
    default:
      return 'dev';
  }
}
