/* eslint-disable jsx-a11y/alt-text */
import {
  AppBookletPageGql_Customer,
  ClubPublicSettings,
  EcommerceCategoryGql_Customer,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { BookletEditorDisplayWidth } from '../../../_model';
export const EcommerceCategoryPreviewContent = ({
  clubPublicSettings,
  displayWidth,
  category,
  appBookletPage,
  className,
  children,
}: {
  clubPublicSettings: ClubPublicSettings;
  displayWidth: BookletEditorDisplayWidth;
  category: Pick<EcommerceCategoryGql_Customer, 'name'>;
  appBookletPage: Partial<AppBookletPageGql_Customer>;
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}) => {
  const appUiTheme = clubPublicSettings?.appUiTheme;

  const content = appBookletPage?.mainContent?.content;

  return (
    category &&
    content && (
      <div className={`w-full overflow-y-auto ${className}`}>
        <>
          {content.image && (
            <img
              className="mb-4 relative object-center w-full rounded-t-xl"
              width={1000} // TODO à stocker dans l'image
              height={400} // TODO à stocker dans l'image
              src={content.image.publicUrl}
            />
          )}
          <div className="py-2 px-4">
            <div
              className={clsx(
                'uppercase font-bold w-full text-left',
                displayWidth === 'mobile'
                  ? 'text-base'
                  : 'text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl',
              )}
              style={{ color: appUiTheme.title1.textColor }}
            >
              {content?.title}
            </div>
            {content?.subTitle && (
              <div
                className={clsx(
                  'uppercase font-medium w-full text-left',
                  displayWidth === 'mobile'
                    ? 'text-sm'
                    : 'text-sm sm:text-base xl:text-lg 2xl:text-2xl',
                )}
                style={{ color: appUiTheme.title2.textColor }}
              >
                {content?.subTitle}
              </div>
            )}
            {children && <div className="my-4">{children}</div>}
          </div>
        </>
      </div>
    )
  );
};
