import React from 'react';
import { AppHeroIcons } from 'src/business/_core/modules/layout';
import { CUS_BookingMemberFull } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/models';
import { DiveCenterBookingPageAggregatedBookingResume } from '../../../model';
import { DiveCenterBookingMembersListItemRow1 } from './DiveCenterBookingMembersListItemRow1';
import { DiveCenterBookingMembersListItemRow2 } from './DiveCenterBookingMembersListItemRow2';

export type BookingResumeMembersTableOnClickMemberAction =
  | 'add'
  | 'edit'
  | 'set-booking-contact'
  | 'extract-from-booking'
  | 'delete-member-with-participants';

export function DiveCenterBookingMembersListItem({
  enableCustomerUpdate,
  aggregatedBookingResume,
  bookingMemberFull,
  onClick,
}: {
  enableCustomerUpdate: boolean;
  aggregatedBookingResume: DiveCenterBookingPageAggregatedBookingResume;
  bookingMemberFull: CUS_BookingMemberFull;
  onClick?: (
    bookingMemberFull: CUS_BookingMemberFull,
    action: BookingResumeMembersTableOnClickMemberAction,
  ) => void;
}) {
  return (
    <li
      className="w-full text-xs"
      onClick={() => enableCustomerUpdate && onClick(bookingMemberFull, 'edit')}
    >
      <button
        className={`w-full block ${
          onClick && enableCustomerUpdate
            ? 'cursor-pointer hover:bg-gray-50'
            : 'cursor-default select-text'
        }`}
        type="button"
      >
        <div className="w-full px-2 py-2 flex gap-2">
          {enableCustomerUpdate && (
            <div className="">
              <AppHeroIcons.actionEdit className="w-6 h-6" />
            </div>
          )}
          <div className="w-full">
            <DiveCenterBookingMembersListItemRow1
              bookingMemberFull={bookingMemberFull}
            />
            <DiveCenterBookingMembersListItemRow2
              className="mt-2"
              aggregatedBookingResume={aggregatedBookingResume}
              bookingMemberFull={bookingMemberFull}
            />
            {bookingMemberFull?.bookingMember?.details?.expectedDive
              ?.customerComment && (
              <div className="mt-1 block text-left">
                {/* <h3 className="text-gray-400 uppercase font-normal max-w-full">
                  Commentaire
                </h3> */}
                <p className="text-red-400 font-bold italic">
                  "
                  {
                    bookingMemberFull?.bookingMember?.details?.expectedDive
                      ?.customerComment
                  }
                  "
                </p>
              </div>
            )}
          </div>
        </div>
      </button>
    </li>
  );
}
