import { Customer_ClubSettingsPublicCustomer } from '@mabadive/app-common-model';
import React from 'react';
import { useDiveCenterShoppingCart } from '../services';

export const DiveCenterShoppingCartRemainingTime = ({
  clubSettings,
}: {
  clubSettings: Customer_ClubSettingsPublicCustomer;
}) => {
  const { cart, remainingTime } = useDiveCenterShoppingCart();

  return remainingTime?.seconds === undefined ? null : (
    <div className="my-2 text-base text-gray-500">
      Temps restant avant expiration du panier:{' '}
      {remainingTime?.hours > 1 ? (
        <span className="font-bold">{remainingTime.hours}h</span>
      ) : remainingTime?.minutes > 1 ? (
        <span className="font-bold">{remainingTime.minutes}mn</span>
      ) : (
        <span className="font-bold text-status-error">
          {remainingTime.seconds}s
        </span>
      )}
    </div>
  );
};
