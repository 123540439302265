"use strict";
exports.__esModule = true;
exports.DEMO_CLUB_PARTICIPANT_TAG_2 = void 0;
exports.DEMO_CLUB_PARTICIPANT_TAG_2 = {
    reference: 'hotel',
    avatar: {
        label: 'H',
        color: '#FFFFFF',
        background: '#85c42a'
    },
    content: {
        label: 'Hôtel',
        color: '#FFFFFF',
        background: '#74a829'
    }
};
