/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CustomerEcommerceProductArticleFull,
  EcommerceCategoryGql_Customer,
  EcommerceProductGql_Customer,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { DiveCenterEcommerceHomePageLocalState } from '../../useDiveCenterEcommerceHomePageLocalState.hook';
import { EcommerceCategoryPreviewContent } from '../03.content';
import { AppTranslationContentPanel } from '../03.content/AppTranslationContent/AppTranslationContentPanel';
import { CustomerProductsPage_CategoryProductArticleCoverCard } from './CustomerProductsPage_CategoryProductArticleCoverCard';
import { CustomerProductsPage_CategoryProductCoverCard } from './CustomerProductsPage_CategoryProductCoverCard';

export const CustomerProductsPage_CategoryContentCard = ({
  categoryId,
  localState,
  className,
}: {
  categoryId: string;
  localState: DiveCenterEcommerceHomePageLocalState;
  className?: string;
}) => {
  const redirectTo = useRedirect();

  const { state, data, actions } = localState;

  const category: EcommerceCategoryGql_Customer = useMemo(() => {
    return data.categories.find((x) => x._id === categoryId);
  }, [categoryId, data.categories]);
  const categoryConfigs = useMemo(() => {
    return data.productCategoryConfigs.filter(
      (cf) => cf.categoryId === categoryId,
    );
  }, [categoryId, data.productCategoryConfigs]);

  const products: EcommerceProductGql_Customer[] = useMemo(() => {
    return data.products.filter((p) =>
      categoryConfigs.some((cf) => cf.productId === p._id),
    );
  }, [categoryConfigs, data.products]);

  const categoryBooklet = useMemo(() => {
    return data.booklets.find((x) => x._id === category?.bookletId);
  }, [data.booklets, category?.bookletId]);

  const categoryBookletPage = useMemo(() => {
    return data.bookletPages.find((x) => x._id === categoryBooklet?.rootPageId);
  }, [categoryBooklet?.rootPageId, data.bookletPages]);

  const nextIndex = useMemo(() => {
    if (products.length > 0) {
      return Math.max(...categoryConfigs.map((p) => p.sortIndex)) + 1;
    }
    return 1;
  }, [categoryConfigs, products.length]);
  const content = categoryBookletPage?.mainContent?.content;

  const productArticlesFull: CustomerEcommerceProductArticleFull[] =
    useMemo(() => {
      return data.productArticlesFull.filter((a) =>
        categoryConfigs.some((cf) => cf.productId === a.product?._id),
      );
    }, [categoryConfigs, data.productArticlesFull]);

  return (
    <div
      className={clsx('flex flex-col gap-4 relative', className)}
      // onClick={(e) => {
      //   actions.selectCategory(category?._id);
      // }}
    >
      <div className="md:flex gap-16 items-start">
        <EcommerceCategoryPreviewContent
          displayWidth="screen"
          category={category}
          appBookletPage={categoryBookletPage}
          clubPublicSettings={data.clubPublicSettings}
        >
          <div className="flex flex-col gap-8">
            <div className="flex-grow max-w-md">
              {(content?.items ?? []).map((item, i) => (
                <AppTranslationContentPanel
                  key={i}
                  item={item}
                  appUiTheme={data.appUiTheme}
                />
              ))}
            </div>
            {products.length > 0 && (
              <div className="flex flex-col gap-4 md:gap-6">
                <h3
                  className="text-lg font-bold leading-4 uppercase"
                  style={{
                    color: data.appUiTheme.title1.textColor,
                  }}
                >
                  Choisissez un produit :
                </h3>
                <div className="grid xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-3 gap-6">
                  {products.map((product) => (
                    <CustomerProductsPage_CategoryProductCoverCard
                      key={product._id}
                      category={category}
                      product={product}
                      localState={localState}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </EcommerceCategoryPreviewContent>
        <div className="hidden xl:grid flex-0 min-w-64 gap-6">
          {productArticlesFull.map((productArticleFull) => (
            <CustomerProductsPage_CategoryProductArticleCoverCard
              key={productArticleFull.article._id}
              category={category}
              product={productArticleFull.product}
              productArticleFull={productArticleFull}
              localState={localState}
              showCoverImage={false}
              showContent={false}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
