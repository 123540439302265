"use strict";
exports.__esModule = true;
// FFESSM (France) :
//     Apnéiste Niveau 1 (A1) :
//         Initiation à l'apnée.
//         Capacité à plonger jusqu'à 10 mètres en sécurité.
//         Apprentissage des bases (respiration, relaxation, sécurité).
//     Apnéiste Niveau 2 (A2) :
//         Approfondissement des techniques.
//         Capacité à plonger jusqu'à 20 mètres.
//         Acquisition de compétences de sécurité pour aider un partenaire.
//     Apnéiste Niveau 3 (A3) :
//         Niveau avancé.
//         Capacité à plonger jusqu'à 30 mètres.
//         Maîtrise des techniques avancées et gestion des risques.
//     Apnéiste Expert (A4) :
//         Niveau expert.
//         Pas de limite de profondeur spécifique, selon les capacités individuelles.
//         Participation à des compétitions ou à des entraînements intensifs.
// AIDA International :
//     AIDA 1 : Découverte de l'apnée.
//     AIDA 2 : Plongée jusqu'à 20 mètres et développement des compétences.
//     AIDA 3 : Plongée jusqu'à 30-40 mètres, entraînement avancé.
//     AIDA 4 : Niveau élite, maîtrise des techniques pour les grandes profondeurs.
// CMAS :
//     CMAS utilise également un système de niveaux similaire, allant du débutant (1*) au niveau expert (4*).
