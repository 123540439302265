import { stringValidator } from '../string';

export const emailLinkBuilder = {
  buildLink,
  cleanEmailAddresss,
  cleanEmailAddress,
};

function cleanString(s: string) {
  return s.replace(/\s/g, '');
}

function cleanEmailAddress(emailAddress: string) {
  if (emailAddress) {
    emailAddress = cleanString(emailAddress.trim());
    if (!emailAddress.length) {
      return;
    }
    if (stringValidator.validateEmail(emailAddress)) {
      return emailAddress;
    }
  }
}
function cleanEmailAddresss(emailAddresses: string[]) {
  const numbers = (emailAddresses ?? [])
    .filter((x) => !!x?.length)
    .map((x) => cleanEmailAddress(x))
    .filter((x) => !!x);

  return [...new Set(numbers)];
}
function buildLink({
  to,
  cc,
  bcc,
  subject,
  body,
  signature,
}: {
  to?: string[];
  cc?: string[];
  bcc?: string[];
  subject?: string;
  body?: string;
  signature?: string;
}) {
  to = to?.filter((x) => !!x?.trim());
  cc = cc?.filter((x) => !!x?.trim());
  bcc = bcc?.filter((x) => !!x?.trim());
  if (
    to?.length ||
    cc?.length ||
    bcc?.length ||
    subject?.trim().length ||
    body?.trim().length
  ) {
    const parameters = [];
    if (cc?.length) {
      parameters.push(`bcc=${cc.join(',')}`);
    }
    if (bcc?.length) {
      parameters.push(`bcc=${bcc.join(',')}`);
    }
    if (subject?.trim().length) {
      const encodedMessage = encodeURIComponent(subject?.trim());
      parameters.push(`subject=${encodedMessage}`);
    }
    if (body?.trim().length || signature?.trim().length) {
      const content = [body?.trim(), signature?.trim()]
        .filter((x) => x?.length)
        .join('\r\n\r\n-- \r\n');
      const encodedMessage = encodeURIComponent(content);
      parameters.push(`body=${encodedMessage}`);
    }

    return `mailto:${(to ?? []).join(',')}${
      parameters.length ? `?${parameters.join('&')}` : ''
    }`;
  }
}
