import {
  DiveSession,
  DiveSessionTimeDayPeriod,
} from '@mabadive/app-common-model';
import {
  clubDiveSessionThemeBuilder,
  dateService,
  diveSessionTitleBuilder,
} from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { useClubResumePublic } from 'src/business/_core/store';
import { ClubDiveSessionBoats } from '../components/ClubDiveSessionBoats';
import { ClubDiveSessionNameLight } from './ClubDiveSessionNameLight';

export const ClubDiveSessionHeaderTitleWithPrefix = function ({
  sessionIndex,
  diveSession: session,
  className,
}: {
  sessionIndex: number;
  diveSession: Pick<
    DiveSession,
    | 'name'
    | 'status'
    | 'special'
    | 'time'
    | 'diveSiteId'
    | 'boatsIds'
    | 'diveTourSession2'
    | 'emojiId'
  >;
  className?: string;
}) {
  const clubResume = useClubResumePublic();

  const time = session.time;

  const timeDayPeriod: DiveSessionTimeDayPeriod = useMemo(
    () => clubDiveSessionThemeBuilder.buildTimeDayPeriod(time),
    [time],
  );
  const isPast = useMemo(
    () => !dateService.isTodayUTC(time) && dateService.isPastUTC(time),
    [time],
  );
  const sessionTitle = useMemo(
    () => diveSessionTitleBuilder.build(session),
    [session],
  );
  const hasAnySessionName: boolean =
    session.status !== 'on' ||
    // !!diveSite ||
    !!session?.emojiId ||
    !!sessionTitle ||
    !!session.diveTourSession2;
  return (
    <div
      className={`max-w-full flex gap-1 font-bold 
          bg-day-period-${timeDayPeriod}-light text-gray-600
          ${className}`}
    >
      <div
        className={`bg-day-period-${timeDayPeriod}-dark text-white py-0.5 px-1`}
      >
        {dateService.formatUTC(time, 'HH:mm')}
      </div>

      <div className={'flex gap-1 items-center overflow-hidden py-0.5'}>
        <ClubDiveSessionBoats diveSession={session} />

        {hasAnySessionName && (
          <ClubDiveSessionNameLight
            className={'font-bold'}
            diveSession={session}
            truncateText={true}
          />
        )}
      </div>
    </div>
  );
};
