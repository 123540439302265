import { useAppSecurityUserHasRole } from 'src/business/auth/services';
import { appWebConfig } from '../../modules/root/config';

export function useAutoFill(): boolean {
  // TODO:
  // - marquer les clubs comme étant des démos
  // - rendre ce bouton accessible seulement sur les clubs de démo
  // - rendre ce bouton visible par les utilisateurs des clubs démo (avec une explication)

  const isSuperAdmin = useAppSecurityUserHasRole('super-admin');

  return appWebConfig.debug.autoFill || isSuperAdmin;
}
