import clsx from 'clsx';
import React, { FC } from 'react';
import { AppHeroIcons } from '../../../icons';

export const AppMessageSingleLine: FC<{
  type?: 'info' | 'success' | 'warn' | 'danger';
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}> = ({ type = 'info', className, children }) => {
  return (
    <p
      className={clsx(
        type === 'danger'
          ? 'text-status-error'
          : type === 'success'
          ? 'text-status-success'
          : type === 'warn'
          ? 'text-status-warn'
          : 'text-app-primary',
        className,
      )}
    >
      <AppHeroIcons.info className="inline w-6 h-6 mr-1" /> {children}
    </p>
  );
};
