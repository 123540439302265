import {
  Booking,
  BookingMember,
  BookingMemberCreationStatus,
  BookingMemberDetails,
  ClubDiver,
  ClubPublicSettings,
} from '@mabadive/app-common-model';
import { commonEntityBuilder } from '../../../data';
import { diverBookingCreator } from './diverBookingCreator.service';
import { diverBookingMemberCreator } from './diverBookingMemberCreator.service';

export const initialBookingBuilder = { buildNewDiverAndBooking };

function buildNewDiverAndBooking({
  diverProps,
  bookingProps,
  bookingMemberProps,
  bookingMemberCreationStatus,
  clubReference,
  diveCenterId,
  publicSettings,
}: {
  diverProps: Partial<ClubDiver>;
  bookingProps: Partial<Booking>;
  bookingMemberProps: Partial<BookingMember>;
  bookingMemberCreationStatus: BookingMemberCreationStatus;
  clubReference: string;
  diveCenterId: string;
  publicSettings: ClubPublicSettings;
}): {
  diver: ClubDiver;
  booking: Booking;
  bookingMember: BookingMember;
} {
  // SINGLE PARTICIPANT

  const mainCertificationReference =
    diverProps?.divingCertification1?.mainCertificationReference;

  const deepDiver = diverProps?.divingCertification1?.specialties?.deepDiver;

  const diver = commonEntityBuilder.buildEntity<ClubDiver>({
    ...diverProps,
    clubReference,
    mainCertificationReference,
  });

  const booking = diverBookingCreator.createBooking({
    ...bookingProps,
    clubReference,
    diveCenterId,
    bookingContactDiverId: diver._id,
    bookingGroup: {
      customerOriginRef: diver.details?.customerOriginRef,
      customerOriginLabel: diver.details?.customerOriginLabel,
      residentType: diver.residentType, // on créé le booking avec le type de résident sélectionné sur la première page
    },
    publicSettings,
  });

  const bookingMemberPropsFromDiver =
    diverBookingMemberCreator.createBookingMemberFromDiver({
      diver,
      bookingId: booking._id,
      clubReference,
      diveCenterId,
    });

  const bookingMember: BookingMember = {
    ...bookingMemberPropsFromDiver,
    ...bookingMemberProps,
    details: {
      ...(bookingMemberProps.details ?? ({} as BookingMemberDetails)),
      isParticipant: true,
      initialPlanParticipantGroupId: undefined,
      creationStatus: bookingMemberCreationStatus,
    },
  };

  const result = { diver, booking, bookingMember };
  return result;
}
