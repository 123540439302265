/* eslint-disable no-console */
import Dexie, { Transaction } from 'dexie';
import * as localforage from 'localforage';
import { JWT_TOKEN_BROWSER_STORAGE_ID } from 'src/business/auth/services';

class MabadiveProDatabase extends Dexie {
  // Declare implicit table properties.
  // (just to inform Typescript. Instantiated by Dexie in stores() method)
  // contacts!: Dexie.Table<IContact, number>; // number = type of the primkey
  keyValue!: Dexie.Table<any, string>; // string = type of the primkey
  //...other tables goes here...

  initializeData = false;

  constructor() {
    super('mabadive-diver');
    this.version(1).stores({
      keyValue: '',
      //...other tables goes here...
    });

    this.on('populate', (transaction: Transaction) => {
      console.warn('[indexDbWrapper] populate');
      // https://dexie.org/docs/Dexie/Dexie.on.populate
      this.initializeData = true; // pas possible de faire de l'async ici
    });
    this.on('ready', async (vipDb: Dexie) => {
      console.warn('[indexDbWrapper] onReady start');
      // ici, on peut faire de l'async, et les méthodes ne seront pas exécutées tant que ce n'est pas terminé
      if (this.initializeData) {
        await this.populateData(vipDb);
      }
      console.warn('[indexDbWrapper] onReady done');
      return true;
    });
  }

  private async populateData(vipDb: Dexie) {
    console.warn('[indexDbWrapper] populateData');
    const keys = [JWT_TOKEN_BROWSER_STORAGE_ID];
    for (const key of keys) {
      try {
        const data = await localforage.getItem(key);
        if (data) {
          console.warn(`[indexDbWrapper] info migrate ${key} from localforage`);
          // NOTE: lock infini si on met un await ici ! (et ça ne semble pas être nécessaire)
          (vipDb as unknown as MabadiveProDatabase).keyValue.add(data, key);
          // return (vipDb as unknown as MabadiveProDatabase).keyValue.bulkAdd(data, key);
          await localforage.removeItem(key);
        }
      } catch (err) {
        console.warn(
          '[indexDbWrapper] error migrating data from localforage',
          err,
        );
      }
    }
  }
}

export const db = new MabadiveProDatabase();

// Introduit pour remplacer localStorage
// A voir si c'est pertinent, notamment en terme de perf, d'utiliser localstorage au lieu des méthodes mémoire maison
export const indexDbWrapper = {
  getItem: async <T>(key: string): Promise<T> => {
    await db.open(); // This will wait until the database is open
    const result = await db.keyValue.get(key);
    return result;
  },
  setItem: async <T>(key: string, value: T): Promise<T> => {
    await db.open();
    await db.keyValue.put(value, key);
    return value;
  },
  removeItem: async (key: string): Promise<void> => {
    await db.open();
    return db.keyValue.delete(key);
  },
};
