import { StackLogLevel } from '@mabadive/stack-log-core';
import { StackLogWebAppenderOptions } from '../../main/models/stack-log-web-appender-options.model';


export interface StackLogWebConsoleFormatterMain {
  level: StackLogLevel;
  message: string;
  options: StackLogWebAppenderOptions;
  dateFormat: string;
}

export interface StackLogWebConsoleFormatterTrace {
  level: StackLogLevel;
  message: string;
  options: StackLogWebAppenderOptions;
  processCreationDate: Date;
  processEndDate: Date;
  age: number;
}

class WebConsoleFormatter {

  constructor() {
  }

  formatLog(logForm: StackLogWebConsoleFormatterMain): string {

    const options = logForm.options;
    const context = options.context;

    const processIdPrefix = context.process ? `[${context.process.processId}]` : '';
    const modulePrefix = context.module ? `[${context.module}]` : '';
    const filenamePrefix = context.filename ? `[${context.filename}]` : '';
    const methodPrefix = context.method ? `[.${context.method}()]` : '';

    const contextString = `${modulePrefix}${filenamePrefix}${methodPrefix}`;

    if (contextString.length) {
      return `${logForm.message} at ${contextString}`;
    } else {
      return logForm.message;
    }
  }

  formatLogTrace(logForm: StackLogWebConsoleFormatterTrace): string {

    const options = logForm.options;
    const context = options.context;
    const durationString = logForm.age < 1000 ? `[${logForm.age}ms]` : `[${Math.floor(logForm.age / 1000)}s]`;
    const processIdPrefix = context.process ? `[${context.process.processId}]` : '';
    const modulePrefix = context.module ? `[${context.module}]` : '';
    const filenamePrefix = context.filename ? `[${context.filename}]` : '';
    const methodPrefix = context.method ? `[.${context.method}()]` : '';

    const contextString = `${modulePrefix}${filenamePrefix}${methodPrefix}`;
    const messageString = logForm.message;

    if (contextString.length) {
      return `\t${durationString} ${messageString} at ${contextString}`;
    } else {
      return `\t${durationString} ${messageString}`;
    }

  }
}

export const stackLogWebConsoleFormatter = new WebConsoleFormatter();