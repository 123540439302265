import { attributeStoreFactory } from '@mabadive/app-common-services';
import { baseStore } from 'src/business/_core/store/baseStore';
import { loadableAttributeStoreFactory } from 'src/lib/reactive/store';
import { AppAuth } from './model';

export const authenticationStore = {
  auth: loadableAttributeStoreFactory.create<AppAuth>(
    baseStore,
    'authentication_auth',
  ),
  logoutRequired: attributeStoreFactory.create<boolean>(
    baseStore,
    'authentication_logoutRequired',
    false,
  ),
};
