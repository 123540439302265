/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppBreadcrumbBar,
  AppBreadcrumbItem,
  AppIcons,
  AppLoadableContentContainer,
  AppPageContainer,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import { DiveCenterEcommerceHomePageLocalState } from '../../../useDiveCenterEcommerceHomePageLocalState.hook';
import {
  AppTranslationContentPanel,
  EcommerceProductArticlePreviewCover,
} from '../../03.content';
import { DiveCenterBackButton } from '../DiveCenterBackButton';
import { CustomerProductsPage_BookProductPanel_ChooseDateAndSession } from './CustomerProductsPage_BookProductPanel_ChooseDateAndSession';
import { CustomerProductsPage_BookProductPanel_ChooseQuantity } from './CustomerProductsPage_BookProductPanel_ChooseQuantity';
import { CustomerProductsPage_BookProductPanel_ChooseSingleDate } from './CustomerProductsPage_BookProductPanel_ChooseSingleDate';
import { CustomerProductsPage_BookProductPanel_ConfirmProduct } from './CustomerProductsPage_BookProductPanel_ConfirmProduct';
import { CustomerProductsPage_BookProductPanel_FillParticipants } from './CustomerProductsPage_BookProductPanel_FillParticipants';
import {
  CustomerProductsPage_BookProductPanelLocalState,
  useCustomerProductsPage_BookProductPanelLocalState,
} from './useCustomerProductsPage_BookProductPanelLocalState.hook';

export const CustomerProductsPage_BookProductPanel = ({
  localState: parentState,
}: {
  localState: DiveCenterEcommerceHomePageLocalState;
}) => {
  const redirectTo = useRedirect();

  const localState: CustomerProductsPage_BookProductPanelLocalState =
    useCustomerProductsPage_BookProductPanelLocalState({
      parentState,
    });
  const { state, data, actions } = localState;
  const currentStep = state.currentStep;

  const breadcrumbItems: AppBreadcrumbItem[] = useMemo(() => {
    const items: AppBreadcrumbItem[] = [];
    if (currentStep === 'choose-quantity') {
      items.push({
        label: 'Quantité',
        // onClick: () => actions.backToPreviousStep('choose-quantity'),
        className: 'hidden',
      });
    } else if (currentStep === 'choose-date') {
      items.push(
        ...[
          {
            label: 'Quantité',
            // onClick: () => actions.backToPreviousStep('choose-quantity'),
            // className: 'hidden sm:flex',
          },
          {
            label: 'Date',
          },
        ],
      );
    } else if (currentStep === 'fill-participants') {
      items.push(
        ...[
          {
            label: 'Quantité',
            // onClick: () => actions.backToPreviousStep('choose-quantity'),
            className: 'hidden sm:flex',
          },
          {
            label: 'Date',
            onClick: () => actions.backToPreviousStep(),
          },
          {
            label: 'Participants',
          },
        ],
      );
    } else if (currentStep === 'confirm-product') {
      items.push(
        ...[
          {
            label: 'Quantité',
            // onClick: () => actions.backToPreviousStep('choose-quantity'),
            className: 'hidden sm:flex',
          },
          {
            label: 'Date',
            onClick: () => actions.setCurrentStep('choose-date'),
          },
          {
            label: 'Participants',
            onClick: () => actions.backToPreviousStep(),
          },
          {
            label: 'Confirmation',
          },
        ],
      );
    }
    return items;
  }, [actions, currentStep]);

  return (
    <>
      <AppPageContainer
        className="bg-gray-50 overflow-y-auto"
        {...state.loadableContent}
      >
        <AppBreadcrumbBar
          classNameInner="max-w-6xl mx-auto"
          firstItemClassName="ml-0"
          color={'#1a7498'}
          // color={data.appUiTheme?.text?.textColor ?? '#444556'}
          items={[
            {
              icon: AppIcons.ecommerce.onlineBooking,
              label: 'Catalogue',
              onClick: () => actions.showCategories(),
              className: 'hidden sm:flex',
            },
            {
              label: state.selectedCategory?.name,
              onClick: () => actions.selectCategory(state.selectedCategory._id),
            },
            {
              label: state.selectedProduct?.name,
            },
          ]}
        />
        <AppPageContentContainer
          paddingBottom={false}
          className="bg-gray-50 app-p-content"
        >
          <AppLoadableContentContainer {...state.loadableContent}>
            <div className="w-full max-w-6xl mx-auto relative">
              <DiveCenterBackButton
                className="absolute z-10 top-1 left-1 "
                onClick={() => {
                  if (currentStep === 'choose-quantity') {
                    actions.selectCategoryProduct({
                      productId: data.productArticleFull?.product?._id,
                      categoryId: data.category._id,
                    });
                    // actions.selectCategory(data.category._id);
                  } else {
                    actions.backToPreviousStep();
                  }
                }}
              />
              {data.productArticleFull && (
                <>
                  <EcommerceProductArticlePreviewCover
                    className="w-full app-card"
                    productArticleFull={data.productArticleFull}
                    clubPublicSettings={data.clubPublicSettings}
                    showCoverImage={true}
                    showPrices={false}
                    showTitle={currentStep === 'choose-quantity'}
                  >
                    {currentStep === 'choose-quantity' && (
                      <>
                        <div className="h-full flex flex-col gap-8">
                          {(
                            data.productArticleFull?.articleBookletPage
                              ?.mainContent?.content?.items ?? []
                          ).map((item, i) => (
                            <AppTranslationContentPanel
                              key={i}
                              item={item}
                              appUiTheme={data.appUiTheme}
                            />
                          ))}
                        </div>
                        <CustomerProductsPage_BookProductPanel_ChooseQuantity
                          localState={localState}
                        />
                      </>
                    )}
                    {currentStep === 'choose-date' && (
                      <>
                        {state.sessionDiveMode ? (
                          <CustomerProductsPage_BookProductPanel_ChooseDateAndSession
                            localState={localState}
                          />
                        ) : (
                          <CustomerProductsPage_BookProductPanel_ChooseSingleDate
                            localState={localState}
                          />
                        )}
                      </>
                    )}
                    {currentStep === 'fill-participants' && (
                      <CustomerProductsPage_BookProductPanel_FillParticipants
                        localState={localState}
                      />
                    )}{' '}
                    {currentStep === 'confirm-product' && (
                      <CustomerProductsPage_BookProductPanel_ConfirmProduct
                        localState={localState}
                      />
                    )}
                  </EcommerceProductArticlePreviewCover>
                </>
              )}
            </div>
          </AppLoadableContentContainer>
        </AppPageContentContainer>
      </AppPageContainer>
    </>
  );
};
