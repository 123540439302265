import {
  EquipmentType,
  ExportDailyConfigContentSessionsEquipmentColumnId,
  SingleEquipmentStats,
} from '@mabadive/app-common-model';
export const equipmentFormatter = {
  formatEquipmentType,
  formatExportDailyConfigContentSessionsEquipmentColumn,
  formatEquipmentsStats,
};

function formatEquipmentType(type: EquipmentType): string {
  switch (type) {
    case 'jacket':
      return 'Stab';
    case 'wetsuit':
      return 'Combinaison';
    case 'fins':
      return 'Palmes';
    case 'scubaRegulator':
      return 'Détendeur';
    case 'divingBoots':
      return 'Bottillons';
    case 'mask':
      return 'Masque';
    case 'flashLight':
      return 'Lampe';
    case 'computer':
      return 'Ordi';
    case 'tank':
      return 'Bloc';
    case 'compass':
      return 'Compas';
    case 'weighting':
      return 'Lestage';
    case 'rebreather':
      return 'Recycleur';
    case 'underwaterScooter':
      return 'Scooter';
    default:
      return type;
  }
}
function formatExportDailyConfigContentSessionsEquipmentColumn(
  type: ExportDailyConfigContentSessionsEquipmentColumnId,
): string {
  switch (type) {
    case 'club-total':
      return 'Club';
    case 'club-details':
      return 'Détail éq. club';
    case 'perso-total':
      return 'Perso';
    case 'perso-details':
      return 'Détail éq. perso';
    case 'total':
      return 'Total';

    default:
      return type;
  }
}

function formatEquipmentsStats({
  singleEquipmentStats,
}: {
  singleEquipmentStats: SingleEquipmentStats;
}): string[] {
  const equipmentDescriptions = singleEquipmentStats.equipments.map(
    (equipment) => `${equipment.quantity.total} ${equipment.label}`,
  );
  if (singleEquipmentStats.unknownCount) {
    equipmentDescriptions.push(`${singleEquipmentStats.unknownCount} ?`);
  }
  return equipmentDescriptions;
}
