import { stackLogConfigurer } from '@mabadive/stack-log-core';
import { StackLogInnerConfig } from './stack-log-inner-config.model';

export class StackLogGlobalConfigHolder {

  constructor(
  ) { }

  private globalConfig: StackLogInnerConfig;

  public getGlobalConfig(): StackLogInnerConfig {

    if (!this.globalConfig) {
      this.globalConfig = this._getDefaultConfiguration();
    }
    return this.globalConfig;
  }
  public setGlobalConfig(config: StackLogInnerConfig): void {

    this.globalConfig = config;
  }
  private _getDefaultConfiguration(): StackLogInnerConfig {
    return {
      global: stackLogConfigurer.getDefaultInnerSeverity(),
      appenders: [],
    };
  }

}


export const stackLogGlobalConfigHolder = new StackLogGlobalConfigHolder();