import { DiveSession, DiveTourSession2 } from '@mabadive/app-common-model';

export const diveSessionMultipleBuilder = {
  isMultiSession,
  isMultiSessionConfigForStaff,
};

function isMultiSessionConfigForStaff(
  diveTourSession2: Pick<DiveTourSession2, 'sameStaffSession1'>,
): boolean {
  return !!diveTourSession2 && !diveTourSession2?.sameStaffSession1;
}

function isMultiSession(
  diveSession: Pick<DiveSession, 'diveTourSession2'>,
): boolean {
  return !!diveSession.diveTourSession2;
}
