import { Customer_ClubResumePublic } from '@mabadive/app-common-model';
import { loadableAttributeStoreFactory } from 'src/lib/reactive/store';
import { getBaseStore } from './baseStore';

export const diveCenterStore = {
  // initialDataLoaded: loadableAttributeStoreFactory.create<boolean>(
  //   getBaseStore(),
  //   'dibo_initialDataLoaded',
  // ),
  clubResume: loadableAttributeStoreFactory.create<Customer_ClubResumePublic>(
    getBaseStore(),
    'dibo_clubResume',
  ),
};
