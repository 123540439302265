import { ClubParticipant } from '@mabadive/app-common-model';
import { participantAptitudeBuilder } from '../dive';

const AUTONOMOUS_COMPATIBILITY_PREFIX = 'autonomous';
const SUPERVISED_COMPATIBILITY_PREFIX = 'supervised';
const TRAINING_COMPATIBILITY_PREFIX = 'training';

export const participantCompatibilityBuilder = {
  buildCompatibilityGroups,
};

function buildCompatibilityGroups(
  participant: Pick<
    ClubParticipant,
    'diveMode' | 'targetDeep' | 'trainingReference' | 'certificationReference'
  >,
  { clubReference }: { clubReference: string },
): string[] {
  const { diveMode } = participant;
  const aptitude = participantAptitudeBuilder.formatAptitude(
    { participant, diver: undefined },
    {
      clubReference,
    },
  );
  if (diveMode === 'training') {
    return [
      `${TRAINING_COMPATIBILITY_PREFIX}-${aptitude}-${participant.targetDeep}`,
    ];
  }
  if (diveMode === 'autonomous') {
    return [
      `${AUTONOMOUS_COMPATIBILITY_PREFIX}-${aptitude}-${participant.targetDeep}`,
    ];
  }
  if (diveMode === 'supervised') {
    return [
      `${SUPERVISED_COMPATIBILITY_PREFIX}-${aptitude}-${participant.targetDeep}`,
    ];
  }

  return [];
}
