import { StackLogInnerSeverityConfig } from '../config/stack-log-inner-severity-config.model';
import { StackLogLoggerOptions } from '../logger/stack-log-logger-options';
import { StackLogLevelConfig } from '../model/stack-log-level-config.type';
import { StackLogLevel } from '../model/stack-logger-level.type';
import { StackLoggerSource } from '../model/stack-logger-source.model';


export class StackLogLevelTester {

  constructor(
  ) { }

  public isEnabled(options: StackLogLoggerOptions<any>, config: StackLogInnerSeverityConfig) {

    // method level
    if (config.custom.hasMethodLevelConfig) {
      const methodKey = this.buildLogLevelContextKey(options.context);
      if (methodKey) {
        const methodLevel: StackLogLevelConfig = config.custom.levels[methodKey];
        if (methodLevel) {
          return this.isLevelEnabled(options.level, methodLevel);
        }
      }
    }
    // module level
    if (config.custom.hasModuleLevelConfig) {
      const moduleKey = this.buildLogLevelContextKey({
        app: options.context.app,
        module: options.context.module,
      });
      if (moduleKey) {
        const moduleLevel: StackLogLevelConfig = config.custom.levels[moduleKey];
        if (moduleLevel) {
          return this.isLevelEnabled(options.level, moduleLevel);
        }
      }
    }
    // app level
    if (config.custom.hasAppLevelConfig) {
      const appKey = this.buildLogLevelContextKey({
        app: options.context.app,
      });
      if (appKey) {
        const appLevel: StackLogLevelConfig = config.custom.levels[appKey];
        if (appLevel) {
          return this.isLevelEnabled(options.level, appLevel);
        }
      }
    }
    // global level
    return this.isLevelEnabled(options.level, config.defaultLogLevel);
  }

  private isLevelEnabled(messageLogLevel: StackLogLevel, configLevel: StackLogLevelConfig): boolean {

    switch (configLevel) {
      case 'disabled': {
        return false;
      }
      case 'error': {
        return messageLogLevel === 'error';
      }
      case 'warn': {
        return messageLogLevel === 'error' || messageLogLevel === 'warn';
      }
      case 'info': {
        return messageLogLevel === 'error' || messageLogLevel === 'warn' || messageLogLevel === 'info';
      }
      case 'debug': {
        return true;
      }
      default: {
        return false;
      }

    }
  }

  buildLogLevelContextKey(context: StackLoggerSource): string {
    let key = '';
    if (context.app) {
      key = `--ap-${context.app}`;
    }
    if (context.module) {
      key += `--mo-${context.module}`;
    }
    if (context.method) {
      key += `--me-${context.method}`;
    }
    return key;
  }

}


export const stackLogLevelTester = new StackLogLevelTester();