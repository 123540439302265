import { useMemo } from 'react';
import { useIsTouchDevice } from './useIsTouchDevice.hook';
import { useMediaSizeTailwind } from './useMediaSizeTailwind.hook';

export function useAutoFocus() {
  const mediaSize = useMediaSizeTailwind();

  const isTouchDevice = useIsTouchDevice();

  const autoFocus = useMemo(
    () =>
      (mediaSize === 'lg' || mediaSize === 'xl' || mediaSize === '2xl') &&
      !isTouchDevice,
    [isTouchDevice, mediaSize],
  );

  return autoFocus;
}
