import React from 'react';
import { Control, Path, useController } from 'react-hook-form';
import { AppInputBoolean } from './AppInputBoolean';
import { AppInputBooleanColor } from './AppInputBooleanColor.type';
import { AppInputBooleanType } from './AppInputBooleanType.type';

// TODO une fois complètement migré vers react-hook-form, migrer les composants de 'material-ui' vers '@headlessui/react' (voir https://tailwindui.com/components/application-ui/forms)
export function AppInputBooleanRHF<T>({
  clearable,
  control,
  name,
  description,
  className,
  onChange: onChangeInput,
  ...extraProps
}: {
  control: Control<T>;
  name: Path<T>;
  description?: string;
  className?: string;
  clearable?: boolean;
} & {
  type?: AppInputBooleanType;
  labelColorClassName?: string;
  labelFontClassName?: string;
  color?: AppInputBooleanColor;
  onChange?: (value: boolean) => any;
} & Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    'type' | 'color' | 'onChange'
  >) {
  const {
    field: { ref, value, onChange, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = useController<T>({
    name,
    control,
    rules: {
      required: extraProps.required,
    },
  });

  return (
    <AppInputBoolean
      {...extraProps}
      {...inputProps}
      value={value as unknown as boolean}
      onChange={(value) => {
        onChange(value);
        onChangeInput && onChangeInput(value);
      }}
      description={description}
      className={className}
      checked={value === true}
      // error={invalid}
    />
  );
}
