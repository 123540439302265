import { Theme } from '@material-ui/core';

type Value = number | string;

function attr(name: string, value: Value, suffix: string, prefix: string) {
  const attribute: any = {};
  attribute[name] = `${prefix ? prefix : ''}${value}${suffix ? suffix : ''}`;
  return attribute;
}
export interface ResponsiveAttributeValuesPropsAttr {
  values: [Value, Value, Value, Value, Value],
  prefix?: string,
  suffix?: string,
}

export const responsiveAttributesValues = (theme: Theme, attributes: { [attr: string]: ResponsiveAttributeValuesPropsAttr }) => {
  return {
    // xs
    ...buildAttributesValues(attributes, 0),
    [theme.breakpoints.only('sm')]: {
      ...buildAttributesValues(attributes, 1),
    },
    [theme.breakpoints.only('md')]: {
      ...buildAttributesValues(attributes, 2),
    },
    [theme.breakpoints.only('lg')]: {
      ...buildAttributesValues(attributes, 3),
    },
    [theme.breakpoints.up('xl')]: {
      ...buildAttributesValues(attributes, 4),
    },
  };
}

function buildAttributesValues(attributesMap: { [attr: string]: ResponsiveAttributeValuesPropsAttr }, index: 0 | 1 | 2 | 3 | 4) {
  return Object.keys(attributesMap).reduce((acc, key) => {
    const attribute = attributesMap[key];
    const value = attribute.values[index];
    return {
      ...acc,
      ...attr(key, value, attribute.suffix, attribute.prefix),
    };
  }, {} as any);
}
