/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useMemo } from 'react';
import { appStylesHelper } from '../../../root/theme';
import { useSideMenu } from '../SideMenu/useSideMenu.hook';

const SIDE_MARGIN = 0;

export const AppFixedButtonsBar: FC<{
  children?: React.ReactNode | React.ReactNode[];
  noBackground?: boolean;
  hasChanges?: boolean;
  className?: string;
}> = ({ noBackground, hasChanges, children, className = '' }) => {
  const { isMenuTemporary, isMenuCollapsed } = useSideMenu();

  const menuWidth = useMemo(() => {
    if (isMenuTemporary) {
      return 0;
    } else {
      // permanent
      if (isMenuCollapsed) {
        return appStylesHelper.getSideMenuCollapsedWidth(SIDE_MARGIN);
      } else {
        return appStylesHelper.getSideMenuWidthResponsive(SIDE_MARGIN);
      }
    }
  }, [isMenuCollapsed, isMenuTemporary]);

  return (
    <div
      className={`max-w-screen-2xl sticky z-50 bottom-0 w-full  bg-white ${className}`}
      // style={{ width: `calc(100% - ${menuWidth})` }}
    >
      <div
        className={`w-full flex gap-4 ${
          noBackground
            ? ''
            : hasChanges
            ? 'bg-status-info-light'
            : 'bg-status-info'
        } px-5 py-2 flex justify-around items-center`}
      >
        {children}
      </div>
    </div>
  );
};
