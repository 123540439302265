import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { appLogger } from '../../../_core/modules/root/logger';
import { ClubSettingsOnlineBookingProductsPageViewState } from '../_model';
import { diveCenterEcommerceHomePageLocalStateUrlManager } from './diveCenterEcommerceHomePageLocalStateUrlManager.service';
export function useDiveCenterEcommerceHomePageUpdateURL(
  viewState: ClubSettingsOnlineBookingProductsPageViewState,
  {
    documentTitle,
    enableRouteParams,
  }: { documentTitle: string; enableRouteParams: boolean },
) {
  const history = useHistory();
  const [lastTimestamp, setLastTimestamp] = useState(viewState.timestamp);
  // si timestamp change, on met à jour l'historique de navigation (et le back va fonctionner)

  useEffect(() => {
    if (enableRouteParams) {
      // update url params
      const searchParams: string[] =
        diveCenterEcommerceHomePageLocalStateUrlManager.buildSearchParams(
          viewState,
        );
      const route = {
        pathname: window.location.pathname,
        search: searchParams?.length ? `?${searchParams.join('&')}` : undefined,
      };
      const triggerNavigation =
        viewState.timestamp && lastTimestamp !== viewState.timestamp;
      if (triggerNavigation) {
        appLogger.info(
          '[useDiveCenterEcommerceHomePageUpdateURL] trigger navigation',
          viewState.timestamp,
        );
        setLastTimestamp(viewState.timestamp);
        history.push({
          ...route,
        });
        document.title = documentTitle;
      } else {
        history.replace(route);
      }
    }
  }, [documentTitle, enableRouteParams, history, lastTimestamp, viewState]);
}
