import {
  ClubDiverCertificationLabels,
  languageFlagConvertor,
} from '@mabadive/app-common-model';
import {
  dateService,
  diverCertificationFormatter,
  nameFormatter,
  phoneLinkBuilder,
  smsLinkBuilder,
  whatzappLinkBuilder,
} from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppHeroIcons, useAppDevice } from 'src/business/_core/modules/layout';
import { AppButton } from 'src/business/_core/modules/layout/components/_tailwind';
import { CUS_BookingMemberFull } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/models';

import { DiveCenterBookingMemberCertification } from './DiveCenterBookingMemberCertification';

export function DiveCenterBookingMembersListItemRow1({
  bookingMemberFull,
}: {
  bookingMemberFull: CUS_BookingMemberFull;
}) {
  const diver = bookingMemberFull?.diver;
  const memberDetails = bookingMemberFull?.bookingMember?.details;
  const age = useMemo(
    () =>
      bookingMemberFull.diver?.birthdate
        ? dateService.getAge(bookingMemberFull.diver?.birthdate)
        : undefined,
    [bookingMemberFull.diver?.birthdate],
  );

  const { isMobile } = useAppDevice();

  const phoneNumber = diver?.phoneNumber;
  const phoneNumberClean = useMemo(
    () => smsLinkBuilder.cleanPhoneNumber(diver?.phoneNumber),
    [diver?.phoneNumber],
  );
  const emailAddress = useMemo(
    () => diver?.emailAddress?.trim(),
    [diver?.emailAddress],
  );

  const whatzappLink = useMemo(
    () =>
      whatzappLinkBuilder.buildLink({
        message: `Bonjour ${diver?.firstName}, `,
        phoneNumber: diver?.phoneNumber,
        isMobile,
      }),
    [diver?.firstName, diver?.phoneNumber, isMobile],
  );

  const certificationLabels: ClubDiverCertificationLabels = useMemo(
    () =>
      diverCertificationFormatter.formatCertificationLabels({
        diver,
        format: 'full',
      }),
    [diver],
  );

  return (
    <div className="w-full flex gap-y-1 gap-x-2 justify-between flex-wrap">
      <div className="block text-left">
        <p className={'font-bold text-sm  text-app-blue flex gap-2'}>
          <span className="flex gap-2">
            {diver.languageCode && (
              <span>{languageFlagConvertor.getFlag(diver.languageCode)}</span>
            )}
            <span
              className={clsx(
                bookingMemberFull?.bookingMember?.details?.creationStatus ===
                  'draft'
                  ? 'italic'
                  : '',
              )}
            >
              {nameFormatter.formatFullName(bookingMemberFull.diver, {
                format: 'firstName-first',
              })}
            </span>
          </span>
          {certificationLabels.hasCertification && (
            <span className="uppercase text-gray-600 font-bold">
              (
              <DiveCenterBookingMemberCertification
                certificationLabels={certificationLabels}
              />
              )
            </span>
          )}
        </p>
        {age > 0 && <p className="font-bold text-gray-500">{age} ans</p>}
      </div>

      <div className="">
        <div className="flex flex-col gap-1 items-start">
          {emailAddress && (
            <AppButton
              className="text-xs"
              color="mail-outline"
              icon={AppHeroIcons.contact}
              href={`mailto:${emailAddress}`}
              size="small"
              onClick={(e) => e.stopPropagation()}
              target="mabadive_mail_client"
            >
              <span className="">{emailAddress}</span>
            </AppButton>
          )}

          {phoneNumberClean && (
            <AppButton
              fullWidth={true}
              className="text-xs"
              icon={AppHeroIcons.phone}
              color="primary-outline-light"
              href={`tel:${phoneNumberClean}`}
              size="small"
              onClick={(e) => e.stopPropagation()}
            >
              <span className="">
                {phoneLinkBuilder.formatPhoneNumber(phoneNumberClean)}
              </span>
            </AppButton>
          )}
          {/* {whatzappLink && (
                  <AppButton
                    fullWidth={true}
                    className="max-w-fit"
                    icon={() => <IoLogoWhatsapp />}
                    color="whatsapp-outline"
                    href={whatzappLink}
                    target="_blank"
                    size="small"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <span className="">WhatsApp</span>
                  </AppButton>
                )} */}
        </div>
      </div>
    </div>
  );
}
