import React from 'react';
import { Control, Path, useController } from 'react-hook-form';
import {
  AppSingleSelect2HeadlessUI,
  AppSingleSelect2HeadlessUIProps,
} from './AppSingleSelect2HeadlessUI';

export function AppSingleSelect2HeadlessUIRHF<
  T,
  L extends string | React.ReactNode,
  F = any,
>({
  control,
  name,
  onChange,
  ...extraProps
}: {
  control: Control<F>;
  name: Path<F>;
  className?: string;
} & AppSingleSelect2HeadlessUIProps<T, L>) {
  const {
    field: { ref, onChange: onChangeRHF, value, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = useController<F>({
    name,
    control,
    rules: {
      required: extraProps.required,
    },
    disabled: extraProps.disabled,
  });

  return (
    <AppSingleSelect2HeadlessUI<T, L>
      {...extraProps}
      {...inputProps}
      value={(value ?? '') as unknown as T}
      onChange={(value: T) => {
        onChangeRHF(value);
        onChange && onChange(value);
      }}
    />
  );
}
