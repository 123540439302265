import { AppInquiryType } from '@mabadive/app-common-model';
import { FunctionComponent, SVGProps } from 'react';
import { ReactComponent as all } from 'src/assets/icons/inquiry/inquiry.svg';
import { ReactComponent as medicalForm } from 'src/assets/icons/inquiry/medical-form.svg';
import { ReactComponent as parentalConsent } from 'src/assets/icons/inquiry/parental-consent.svg';
export const AppIconsInquiryType: {
  [type in AppInquiryType]: FunctionComponent<SVGProps<SVGSVGElement>>;
} = {
  'medical-form': medicalForm,
  'parental-consent': parentalConsent,
};

export const AppIconsInquiry = {
  all,
  ...AppIconsInquiryType,
};
