import { ClubDiverResidentType } from '@mabadive/app-common-model';

export const clubResidentTypeFormatter = {
  format,
};

function format(type: ClubDiverResidentType): string {
  if (!type) {
    return '';
  }
  if (type === 'local') {
    return 'Résident';
  } else if (type === 'holidays') {
    return 'Vacancier';
  } else if (type === 'member') {
    return 'Membre';
  } else if (type === 'privilege') {
    return 'Privilège';
  } else {
    return '';
  }
}
