"use strict";
exports.__esModule = true;
exports.DIVE_TRAININGS_REFERENCES_FR_DEFAULT = void 0;
var DIVE_TRAININGS_REFERENCES_FR_1 = require("./DIVE_TRAININGS_REFERENCES_FR");
var refsToIgnore = [
    'F-PBD',
    'F-PBJ',
    'F-PB1',
    'F-PB2',
    'F-FB1',
    'F-FB2',
    'F-FB3',
    'F-A1',
    'F-A2',
    'F-A3',
    'F-A4', // Formateur Apnéiste Niveau 4
];
exports.DIVE_TRAININGS_REFERENCES_FR_DEFAULT = DIVE_TRAININGS_REFERENCES_FR_1.DIVE_TRAININGS_REFERENCES_FR.filter(function (x) { return !refsToIgnore.includes(x); });
