import {
  ClubParticipant,
  EquipmentDescription,
} from '@mabadive/app-common-model';

export const equipmentSuffixBuilder = {
  buildParticipantEquipementSuffix,
  buildParticipantsEquipementSuffix,
};

function buildParticipantsEquipementSuffix<
  T extends Pick<ClubParticipant, 'equipment'>,
>(
  participants: T[],
  {
    cache,
  }: {
    cache: {
      [key: string]: number;
    };
  },
): T[] {
  return participants.map((p) =>
    buildParticipantEquipementSuffix<T>(p, { cache }),
  );
}

function buildParticipantEquipementSuffix<
  T extends Pick<ClubParticipant, 'equipment'>,
>(p: T, { cache }: { cache: { [key: string]: number } }): T {
  const equipment = {
    ...(p.equipment ?? {}),
    jacket: getNextEquipmentSuffix('jacket', p.equipment?.jacket, cache),
    wetsuit: getNextEquipmentSuffix('wetsuit', p.equipment?.wetsuit, cache),
    fins: getNextEquipmentSuffix('fins', p.equipment?.fins, cache),
  };
  return {
    ...p,
    equipment,
  };
}

function getNextEquipmentSuffix(
  eqType: string,
  eq: EquipmentDescription,
  cache: { [key: string]: number },
): EquipmentDescription {
  if (eq && !eq.selfEquipped) {
    eq = {
      ...eq,
    };
    if (eq.model1?.ref) {
      const key = `${eqType}-${eq.selfEquipped}-${eq.model1?.ref}`;
      if (cache[key]) {
        cache[key] = cache[key] + 1;
      } else {
        cache[key] = 1;
      }
      const suffix = `${cache[key]}`;
      eq.model1.ref += ` (${suffix})`;
    }
    if (eq.model2?.ref) {
      const key = `${eqType}-${eq.selfEquipped}-${eq.model2?.ref}`;
      if (cache[key]) {
        cache[key] = cache[key] + 1;
      } else {
        cache[key] = 1;
      }
      const suffix = `${cache[key]}`;
      eq.model2.ref += ` (${suffix})`;
    }
  }
  return eq;
}
