import { AppUserTrackingClient } from '@mabadive/app-common-model';
import { useMemo } from 'react';
import { useAppDevice } from './useAppDevice.hook';

export function useAppTrackingClient(): AppUserTrackingClient {
  const { isMobile, deviceOS, userAgent } = useAppDevice();

  return useMemo(() => {
    const trackingClient: AppUserTrackingClient = {
      isMobile,
      deviceOS,
      userAgent,
      width: window.innerWidth,
      height: window.innerHeight,
    };
    return trackingClient;
  }, [deviceOS, isMobile, userAgent]);
}
