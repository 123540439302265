import { graphqlClientAuth } from './auth';
import { graphqlClientQueryBuilder, graphqlClientQueryRunner } from './query';

export const graphqlClient = {
  query: {
    ...graphqlClientQueryBuilder,
    ...graphqlClientQueryRunner,
  },
  auth: graphqlClientAuth,
};
