import { dataSorter } from '../sorter';
import { searchChunks } from './searchChunks';
import { searchCore } from './searchCore';

type MatchWithScoreResults = {
  match: boolean;
  score: number;
};

function matchWord({
  attributes,
  word,
  withScore,
}: {
  attributes: string[];
  word: string;
  withScore: boolean;
}): MatchWithScoreResults {
  if (withScore) {
    const score = attributes.reduce((score, attr) => {
      if (!attr) {
        return score;
      }
      const cleanAttr = searchCore.clean(attr as unknown as string);
      const match = cleanAttr.indexOf(word) !== -1;
      if (match) {
        if (cleanAttr === word) {
          // perfect match
          return score + 50;
        } if (cleanAttr.indexOf(word) === 0) {
          // starts with match
          return score + 10;
        }
        return score + 1;
      }
      return score;
    }, 0);
    return {
      match: score !== 0,
      score,
    };
  }
  const firstMatchingAttribute = attributes.find((attr) => {
    if (!attr) {
      return false;
    }
    return searchCore.clean(attr as unknown as string).indexOf(word) !== -1;
  });
  if (firstMatchingAttribute !== undefined) {
    return {
      match: true,
      score: 1,
    };
  }
  return {
    match: false,
    score: 0,
  };
}

function match({
  attributes,
  words,
  withScore,
}: {
  attributes: string[];
  words: string[];
  withScore: boolean;
}): MatchWithScoreResults {
  if (!attributes || !attributes.length || !words || !words.length) {
    return {
      match: false,
      score: 0,
    };
  }

  if (withScore) {
    return words.reduce(
      ({ match, score }, word) => {
        const matchWordResult = matchWord({
          attributes,
          word,
          withScore,
        });
        return {
          match: match && matchWordResult.match,
          score: score + matchWordResult.score, // si un mot a matché, on calcul quand même un score, ça pourrait permettre de retourner des résultats même si aucun match
        };
      },
      { match: true, score: 0 } as MatchWithScoreResults,
    );
  }
  const firstNonMatchingWord = words.find((word) => {
    const matchWordResult = matchWord({
      attributes,
      word,
      withScore,
    });
    return !matchWordResult.match;
  });

  const matchAllWords = firstNonMatchingWord === undefined;
  if (matchAllWords) {
    return {
      match: true,
      score: 1,
    };
  }
  return {
    match: false,
    score: 0,
  };
}

function filter<T>(
  items: T[],
  {
    attributesNames,
    getAttributes,
    searchText,
    maxResults,
    sortResultsByBestMatch = false,
  }: {
    /** @deprecated use getAttributes instead  * */
    attributesNames?: (keyof T)[];
    getAttributes?: (item: T) => string[];
    searchText: string;
    maxResults?: number;
    sortResultsByBestMatch?: boolean;
  },
): T[] {
  if (!searchText || !searchText.length) {
    return sliceMaxResults(items.concat([]), { maxResults });
  }

  const words = searchCore.buildWords(searchText);
  if (words.length === 0) {
    return sliceMaxResults(items.concat([]), { maxResults });
  }

  if (sortResultsByBestMatch) {
    const itemsWithMatchResults = items.reduce((acc, item, initialIndex) => {
      if (item) {
        const attributes = getAttributes
          ? getAttributes(item)
          : attributesNames.map(
            (attributesName) => item[attributesName] as unknown as string,
          );
        const matchResult = match({
          attributes,
          words,
          withScore: true,
        });
        if (matchResult.match) {
          acc.push({ item, matchResult, initialIndex });
        }
      }
      return acc;
    }, []);

    const results = dataSorter.sortMultiple(itemsWithMatchResults, {
      getSortAttributes: (itemWithMatchResult) => [
        {
          // sort by score
          value: itemWithMatchResult.matchResult.score,
          asc: false,
        },
        {
          // then by initial position
          value: itemWithMatchResult.initialIndex,
          asc: true,
        },
      ],
    });

    if (maxResults > 0) {
      return sliceMaxResults(results, { maxResults }).map(
        (result) => result.item,
      );
    }
    return results.map((result) => result.item);
  }
  return items.reduce((acc, item) => {
    if ((item && !maxResults) || acc.length < maxResults) {
      const attributes = getAttributes
        ? getAttributes(item)
        : attributesNames.map(
          (attributesName) => item[attributesName] as unknown as string,
        );

      const matchResult = match({
        attributes,
        words,
        withScore: false,
      });
      if (matchResult.match) {
        acc.push(item);
      }
    }
    return acc;
  }, []);
}

function sliceMaxResults<T>(
  results: T[],
  { maxResults }: { maxResults: number },
): T[] {
  if (maxResults > 0) {
    return results.slice(0, maxResults);
  }
  return results;
}

export const search = {
  filter,
  match,
  contentChunks: searchChunks.find,
  clean: searchCore.clean,
  buildWords: searchCore.buildWords,
  buildPostgresSearchString: searchCore.buildPostgresSearchString,
};
