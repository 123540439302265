/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { EcommerceCategoryGql_Customer } from '@mabadive/app-common-model';
import React, { useMemo } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import {
  AppBreadcrumbBar,
  AppIcons,
  AppLoadableContentContainer,
  AppPageContainer,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import { DiveCenterEcommerceHomePageLocalState } from '../../useDiveCenterEcommerceHomePageLocalState.hook';
import { CustomerProductsPage_CategoryProductContentCard } from '../02.cards';
import { DiveCenterBackButton } from './DiveCenterBackButton';

export const CustomerProductsPage_ProductViewPanel = ({
  localState,
}: {
  localState: DiveCenterEcommerceHomePageLocalState;
}) => {
  const redirectTo = useRedirect();

  const { state, data, actions } = localState;

  const category: EcommerceCategoryGql_Customer = useMemo(() => {
    return data.categories.find((x) => x._id === state.viewState?.categoryId);
  }, [data.categories, state.viewState?.categoryId]);

  return (
    <>
      <AppPageContainer
        className="bg-gray-50 overflow-y-auto"
        {...state.loadableContent}
      >
        <AppBreadcrumbBar
          classNameInner="max-w-6xl mx-auto"
          firstItemClassName="ml-0"
          color={'#1a7498'}
          // color={data.appUiTheme?.text?.textColor ?? '#444556'}
          items={[
            {
              icon: AppIcons.ecommerce.onlineBooking,
              label: 'Catalogue',
              onClick: () => actions.showCategories(),
              className: 'hidden sm:flex',
            },
            {
              label: state.selectedCategory?.name,
              onClick: () =>
                actions.selectCategory(state.selectedCategory?._id),
            },
            {
              label: state.selectedProduct?.name,
            },
          ]}
        />
        <AppPageContentContainer
          paddingBottom={false}
          className="bg-white app-p-content w-full"
        >
          <AppLoadableContentContainer {...state.loadableContent}>
            <div className="w-full max-w-6xl mx-auto relative">
              <DiveCenterBackButton
                className="absolute z-10 top-1 left-1 "
                onClick={() => {
                  actions.selectCategory(category?._id);
                }}
              />
              <CustomerProductsPage_CategoryProductContentCard
                // className="max-w-5xl"
                categoryId={state.viewState?.categoryId}
                productId={state.viewState?.productId}
                localState={localState}
              />
            </div>
          </AppLoadableContentContainer>
        </AppPageContentContainer>
      </AppPageContainer>
    </>
  );
};
