import clsx from 'clsx';
import React, { FC } from 'react';
import { AppHeroIcons } from '../../../icons';

export const AppMessageLight: FC<{
  children?: React.ReactNode | React.ReactNode[];
  type?: 'info' | 'success' | 'warn' | 'danger';
  textClassName?: string;
  className?: string;
}> = ({
  type = 'info',
  textClassName = ' text-xs sm:text-base md:text-base',
  className,
  children,
}) => {
  return (
    <div
      className={clsx(
        'app-py-content px-2 border text-gray-500 border-dashed flex gap-2 items-center',
        type === 'danger'
          ? 'border-status-error'
          : type === 'success'
          ? 'border-status-success'
          : type === 'warn'
          ? 'border-status-warn'
          : 'border-blue-400',
        textClassName,
        className,
      )}
    >
      <AppHeroIcons.info
        className={clsx(
          'flex-shrink-0 mx-2 w-10 h-10',
          type === 'danger'
            ? 'text-status-error'
            : type === 'success'
            ? 'text-status-success'
            : type === 'warn'
            ? 'text-status-warn'
            : 'text-blue-400',
        )}
      />
      <div>{children}</div>
    </div>
  );
};
