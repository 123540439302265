"use strict";
exports.__esModule = true;
exports.DEFAULT_MESSAGES_TEMPLATES_SESSION = void 0;
exports.DEFAULT_MESSAGES_TEMPLATES_SESSION = [
    {
        name: 'A.1) Plongée confirmée',
        style: 'success',
        enabled: true,
        emailContent: {
            title: 'CONFIRMATION CENTRE_NOM - SESSION_DATE SESSION_HEURE',
            body: "Bonjour, \r\n\r\nVotre plong\u00E9e du SESSION_DATE \u00E0 SESSION_HEURE est confirm\u00E9e."
        },
        smsContent: {
            title: 'CONFIRMATION CENTRE_NOM',
            body: "Bonjour, votre plong\u00E9e du SESSION_DATE \u00E0 SESSION_HEURE est confirm\u00E9e."
        },
        whatsAppContent: {
            title: 'CONFIRMATION CENTRE_NOM',
            body: "Bonjour, votre plong\u00E9e du SESSION_DATE \u00E0 SESSION_HEURE est confirm\u00E9e."
        }
    },
    {
        name: 'B.1) Horaire modifié',
        style: 'warning',
        enabled: true,
        emailContent: {
            title: 'CHANGEMENT HORAIRE CENTRE_NOM',
            body: "Bonjour, \r\n\r\nVotre plong\u00E9e du SESSION_DATE est confirm\u00E9e, l'horaire a \u00E9t\u00E9 d\u00E9plac\u00E9 \u00E0 SESSION_HEURE."
        },
        smsContent: {
            title: 'CHANGEMENT HORAIRE CENTRE_NOM',
            body: "Bonjour, votre plong\u00E9e du SESSION_DATE \u00E0 SESSION_HEURE est confirm\u00E9e, l'horaire a \u00E9t\u00E9 d\u00E9plac\u00E9 \u00E0 SESSION_HEURE."
        },
        whatsAppContent: {
            title: 'CHANGEMENT HORAIRE CENTRE_NOM',
            body: "Bonjour, votre plong\u00E9e du SESSION_DATE \u00E0 SESSION_HEURE est confirm\u00E9e, l'horaire a \u00E9t\u00E9 d\u00E9plac\u00E9 \u00E0 SESSION_HEURE."
        }
    },
    {
        name: 'B.2) Programme modifié',
        style: 'warning',
        enabled: true,
        emailContent: {
            title: 'CHANGEMENT PROGRAMME CENTRE_NOM',
            body: "Bonjour, \r\n\r\nLe programme de la plong\u00E9e du SESSION_DATE \u00E0 SESSION_HEURE a \u00E9t\u00E9 modifi\u00E9. \r\n\r\nVeuillez nous contacter pour plus d'info."
        },
        smsContent: {
            title: 'CHANGEMENT PROGRAMME CENTRE_NOM',
            body: "Bonjour, le programme de la plong\u00E9e du SESSION_DATE \u00E0 SESSION_HEURE a \u00E9t\u00E9 modifi\u00E9. Veuillez nous contacter pour plus d'info."
        },
        whatsAppContent: {
            title: 'CHANGEMENT PROGRAMME CENTRE_NOM',
            body: "Bonjour, le programme de la plong\u00E9e du SESSION_DATE \u00E0 SESSION_HEURE a \u00E9t\u00E9 modifi\u00E9. Veuillez nous contacter pour plus d'info."
        }
    },
    {
        name: 'C.1) Annulation mauvais temps',
        style: 'danger',
        enabled: true,
        emailContent: {
            title: 'ANNULATION CENTRE_NOM - SESSION_DATE SESSION_HEURE',
            body: "Bonjour, \r\n\r\nVotre plong\u00E9e du SESSION_DATE \u00E0 SESSION_HEURE est annul\u00E9e pour cause de mauvais temps."
        },
        smsContent: {
            title: 'ANNULATION CENTRE_NOM',
            body: "Bonjour, votre plong\u00E9e du SESSION_DATE \u00E0 SESSION_HEURE est annul\u00E9e pour cause de mauvais temps."
        },
        whatsAppContent: {
            title: 'ANNULATION CENTRE_NOM',
            body: "Bonjour, votre plong\u00E9e du SESSION_DATE \u00E0 SESSION_HEURE est annul\u00E9e pour cause de mauvais temps."
        }
    },
    {
        name: 'C.2) Annulation problème technique',
        style: 'danger',
        enabled: true,
        emailContent: {
            title: 'ANNULATION CENTRE_NOM - SESSION_DATE SESSION_HEURE',
            body: "Bonjour, \r\n\r\nVotre plong\u00E9e du SESSION_DATE \u00E0 SESSION_HEURE est annul\u00E9e en raison d'un probl\u00E8me technique."
        },
        smsContent: {
            title: 'ANNULATION CENTRE_NOM',
            body: "Bonjour, votre plong\u00E9e du SESSION_DATE \u00E0 SESSION_HEURE est annul\u00E9e en raison d'un probl\u00E8me technique."
        },
        whatsAppContent: {
            title: 'ANNULATION CENTRE_NOM',
            body: "Bonjour, votre plong\u00E9e du SESSION_DATE \u00E0 SESSION_HEURE est annul\u00E9e en raison d'un probl\u00E8me technique."
        }
    },
];
