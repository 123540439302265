import {
  ClubSettingsUIDiveMode,
  DiveSessionType,
} from '@mabadive/app-common-model';
import { diveModeFormatter } from './diveModeFormatter.service';

export const diveSessionTypeFormatter = {
  formatType,
};

function formatType(
  type: DiveSessionType,
  {
    diveModesConf,
  }: {
    diveModesConf?: ClubSettingsUIDiveMode;
  },
): string {
  switch (type) {
    case 'dive':
      return 'Plongée';
    case 'first-dive':
      return 'Baptêmes';
    case 'snorkeling':
      return diveModeFormatter.formatDiveMode('snorkeling', {
        format: 'short-label',
        diveModesConf,
      });
    case 'snorkeling-tour':
      return diveModeFormatter.formatDiveMode('snorkelingSupervised', {
        format: 'short-label',
        diveModesConf,
      });
    case 'watching-tour':
      return diveModeFormatter.formatDiveMode('watchingTour', {
        format: 'short-label',
        diveModesConf,
      });
    case 'dive-auto-supervised':
      return diveModeFormatter.formatDiveMode('autoSupervised', {
        format: 'short-label',
        diveModesConf,
      });
    case 'boat-tour':
      return 'Tour en bateau';
    case 'theoretical-training':
      return 'Formation théorique';
  }
}
