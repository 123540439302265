import { ColoredTag } from '@mabadive/app-common-model';
import { Avatar, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { useCacheableClasses } from '../../../root/theme';
import { AppIconsMaterial } from '../../icons';
import { responsiveAttributesValues } from '../../services';
import { ColoredTagAvatarCardSize } from './ColoredTagAvatarCard';

export const ColoredTagAvatarCardDeprecated = ({
  tag,
  size,
  className,
}: {
  tag: ColoredTag;
  size: ColoredTagAvatarCardSize;
  className?: string;
}) => {
  const classes = useCacheableClasses(defineClasses(tag));

  const TagIcon =
    AppIconsMaterial[tag.avatar.appIcon as keyof typeof AppIconsMaterial];

  if (!size) {
    size = 'normal';
  }

  return !tag ? null : (
    <Avatar
      className={`${className ? className : ''} ${classes.root} size-${size}`}
    >
      {size !== 'micro' ? TagIcon ? <TagIcon /> : tag.avatar.label : null}
    </Avatar>
  );
};

function defineClasses(tag: ColoredTag) {
  if (!tag) {
    return;
  }
  return {
    cacheKey: `ColoredTagAvatarCardDeprecated-${tag.reference}`,
    buildStyles: (theme: Theme) =>
      makeStyles({
        root: !tag
          ? {}
          : {
              '&.MuiAvatar-root': {
                color: tag.avatar.color,
                background: tag.avatar.background,
                '&.size-small': {
                  fontWeight: 'bold',
                  ...responsiveAttributesValues(theme, {
                    fontSize: { values: [8, 8, 9, 9, 9], suffix: 'px' },
                    width: { values: [14, 14, 15, 16, 16], suffix: 'px' },
                    height: { values: [13, 13, 14, 15, 15], suffix: 'px' },
                  }),
                },
                '&.size-micro': {
                  width: 8,
                  height: 8,
                  border: '0px solid white',
                  margin: 1,
                },
                '&.size-normal': {
                  width: 24,
                  height: 24,
                  fontSize: '0.75rem',
                },
                '& .MuiSvgIcon-root': {
                  ...responsiveAttributesValues(theme, {
                    fontSize: { values: [8, 8, 9, 9, 9], suffix: 'px' },
                    width: { values: [11, 11, 12, 13, 13], suffix: 'px' },
                    height: { values: [11, 11, 12, 13, 13], suffix: 'px' },
                  }),
                },
              },
            },
      }),
  };
}
