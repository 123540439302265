import {
  dateService,
  diveModeFormatter,
  postalAddressFormatter,
} from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import {
  AppGenericStatus,
  AppGenericStatusIcon,
  appGenericStatusStyleBuilder,
} from 'src/business/_core/modules/layout/components/_tailwind';
import { CUS_BookingMemberFull } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/models';
import { DiveCenterBookingPageAggregatedBookingResume } from '../../../model';

export function DiveCenterBookingMembersListItemRow2({
  aggregatedBookingResume,
  bookingMemberFull,
  className,
}: {
  aggregatedBookingResume: DiveCenterBookingPageAggregatedBookingResume;
  bookingMemberFull: CUS_BookingMemberFull;
  className?: string;
}) {
  const diver = bookingMemberFull?.diver;
  const memberDetails = bookingMemberFull?.bookingMember?.details;
  const age = useMemo(
    () =>
      bookingMemberFull.diver?.birthdate
        ? dateService.getAge(bookingMemberFull.diver?.birthdate)
        : undefined,
    [bookingMemberFull.diver?.birthdate],
  );

  const medicalCertificateDateBadgeStatus: AppGenericStatus = useMemo(() => {
    if (!bookingMemberFull.diver?.medicalCertificateDate) {
      return 'warn';
    }
    if (
      dateService.getAge(bookingMemberFull.diver?.medicalCertificateDate) < 1
    ) {
      return 'success';
    }
    return 'error';
  }, [bookingMemberFull.diver?.medicalCertificateDate]);

  const medicalCertificateDateBadgeStatusClass: string = useMemo(
    () => appGenericStatusStyleBuilder.build(medicalCertificateDateBadgeStatus),
    [medicalCertificateDateBadgeStatus],
  );

  const maxDateOrToday = useMemo(() => {
    return aggregatedBookingResume?.datesRange?.maxDate ?? new Date();
  }, [aggregatedBookingResume?.datesRange?.maxDate]);

  const insuranceDateBadgeStatus: AppGenericStatus = useMemo(() => {
    if (!bookingMemberFull.bookingMember?.details?.insurance?.expirationDate) {
      return 'warn';
    }
    if (
      !dateService.isBefore(
        bookingMemberFull.bookingMember?.details?.insurance?.expirationDate,
        maxDateOrToday,
      )
    ) {
      return 'success';
    }
    return 'error';
  }, [
    bookingMemberFull.bookingMember?.details?.insurance?.expirationDate,
    maxDateOrToday,
  ]);

  const insuranceDateBadgeStatusClass: string = useMemo(
    () => appGenericStatusStyleBuilder.build(insuranceDateBadgeStatus),
    [insuranceDateBadgeStatus],
  );

  const lastDiveDateBadgeStatus: AppGenericStatus = useMemo(() => {
    if (!memberDetails?.lastDiveDate) {
      return 'error';
    }
    if (dateService.getAgeInMonths(memberDetails?.lastDiveDate) < 7) {
      return 'success';
    }
    if (dateService.getAgeInMonths(memberDetails?.lastDiveDate) < 12) {
      return 'warn';
    }
    return 'error';
  }, [memberDetails?.lastDiveDate]);

  const lastDiveDateBadgeStatusClass: string = useMemo(
    () => appGenericStatusStyleBuilder.build(lastDiveDateBadgeStatus),
    [lastDiveDateBadgeStatus],
  );

  return (
    <div
      className={`mt-2 w-full flex justify-between sm:justify-start flex-wrap gap-x-4 gap-y-2 ${className}`}
    >
      {memberDetails?.expectedDive?.diveMode && (
        <div className="block text-left">
          <h3 className="text-gray-400 uppercase font-normal max-w-full">
            Prestation souhaitée
          </h3>
          <p className="text-gray-600 font-bold uppercase">
            {diveModeFormatter.formatDiveMode(
              memberDetails?.expectedDive?.diveMode,
              {
                format: 'long-label',
              },
            )}
          </p>
        </div>
      )}
      {memberDetails?.lastDiveDate && (
        <div className="block text-left">
          <h3 className="text-gray-400 uppercase font-normal max-w-full">
            Dernière plongée
          </h3>
          <div className={`${lastDiveDateBadgeStatusClass}`}>
            <div className="flex items-center">
              <AppGenericStatusIcon
                status={lastDiveDateBadgeStatus}
                className={'w-4 h-4 '}
              />
              <div className="grow">
                <span className="ml-1">
                  {dateService.formatUTC(
                    memberDetails?.lastDiveDate,
                    'DD/MM/YYYY',
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {memberDetails?.totalDivesCount > 0 && (
        <div className="block text-left">
          <h3 className="text-gray-400 uppercase font-normal max-w-full">
            Total plongées
          </h3>
          <p className="text-gray-600 font-bold">
            {memberDetails?.totalDivesCount}
          </p>
        </div>
      )}
      {diver?.medicalCertificateDate && (
        <div className="block text-left">
          <h3 className="text-gray-400 uppercase font-normal max-w-full">
            Certificat médical
          </h3>
          <div className={`${medicalCertificateDateBadgeStatusClass}`}>
            <div className="flex items-center">
              <AppGenericStatusIcon
                status={medicalCertificateDateBadgeStatus}
                className={'w-4 h-4 '}
              />
              <div className="grow">
                <span className="ml-1">
                  {dateService.formatUTC(
                    bookingMemberFull.diver?.medicalCertificateDate,
                    'DD/MM/YYYY',
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {diver?.emergencyContact?.trim() && (
        <div className="block text-left">
          <h3 className="text-gray-400 uppercase font-normal max-w-full">
            Contact d'urgence
          </h3>
          <p className={'text-gray-500'}>{diver?.emergencyContact}</p>
        </div>
      )}
      {diver?.homeAddress && (
        <div className="block text-left">
          <h3 className="text-gray-400 uppercase font-normal max-w-full">
            Adresse postale
          </h3>
          <p className={'text-gray-500'}>
            {postalAddressFormatter.format(diver?.homeAddress)}
          </p>
        </div>
      )}
      {(memberDetails?.insurance?.hasInsurance === true ||
        memberDetails?.insurance?.hasInsurance === false) && (
        <div className="block text-left">
          <h3 className="text-gray-400 uppercase font-normal max-w-full">
            Assurance
          </h3>
          <div className={`${insuranceDateBadgeStatusClass}`}>
            {memberDetails?.insurance?.hasInsurance === true ? (
              <div className="flex flex-col gap-0.5 items-start">
                {memberDetails?.insurance?.number ? (
                  <div>{memberDetails?.insurance?.number}</div>
                ) : (
                  !memberDetails?.insurance?.expirationDate && (
                    <div className="text-xs text-status-success">OUI</div>
                  )
                )}
                <div className="flex items-center">
                  {memberDetails?.insurance?.expirationDate && (
                    <>
                      <AppGenericStatusIcon
                        status={insuranceDateBadgeStatus}
                        className={'w-4 h-4 '}
                      />
                      <div className="grow">
                        <span className="ml-1">
                          {dateService.formatUTC(
                            memberDetails?.insurance?.expirationDate,
                            'DD/MM/YYYY',
                          )}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <div className="text-xs text-status-error">NON</div>
            )}
          </div>
        </div>
      )}
      {diver?.details?.identificationNumber && (
        <div className="block text-left">
          <h3 className="text-gray-400 uppercase font-normal max-w-full">
            N° CNI ou Passeport
          </h3>
          <p className={'text-gray-500'}>
            {diver?.details?.identificationNumber}
          </p>
        </div>
      )}
      {diver?.details?.newsletter?.registrationActive && (
        <div className="block text-left">
          <h3 className="text-gray-400 uppercase font-normal max-w-full">
            Newsletter
          </h3>
          <div className="text-xs text-status-success">OUI</div>
        </div>
      )}
    </div>
  );
}
