/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubAuthUserProfile } from '@mabadive/app-common-model';
import { dataSorter, SortableAttribute } from '../../../data';

export const clubProfilesSorter = {
  sortProfiles,
  getClubProfileSortableAttributes,
};
function sortProfiles(clubProfiles: ClubAuthUserProfile[]) {
  return dataSorter.sortMultiple(clubProfiles ?? [], {
    getSortAttributes: (x) =>
      clubProfilesSorter.getClubProfileSortableAttributes(x),
    asc: true,
  });
}
function getClubProfileSortableAttributes(
  clubProfile: ClubAuthUserProfile,
): SortableAttribute[] {
  const az = clubProfile?.authorizations;
  return [
    {
      value: az.edit.settings.accountProfilesImpersonate,
    },
    {
      value: az.edit.settings.archiveData,
    },
    {
      value: az.edit.settings.onlinePayment,
    },
    {
      value: az.onlineBooking?.action?.editSettings ?? false,
    },
    {
      value: az.edit.settings.accountProfiles,
    },
    {
      value: az.edit.settings.offers,
    },
    {
      value: az.edit.settings.global,
    },
    {
      value: az.view.settings.global,
    },
    {
      value: az.edit.settings.diveCenter,
    },
    {
      value: az.view.settings.diveCenter,
    },
    {
      value: az.edit.planning.session,
    },
    {
      value: az.edit.participant.payment,
    },
    {
      value: az.edit.participant.booking,
    },
    {
      value: az.view.dashboard.stats,
    },
    {
      value: az.view.planning.participant,
    },
    {
      value: clubProfile._createdAt,
      asc: false,
    },
  ];
}
