import React from 'react';
import { AppLoader } from 'src/business/_core/modules/layout';
import {
  CustomerProductsPage_BookProductPanel,
  CustomerProductsPage_CategoryListPanel,
  CustomerProductsPage_CategoryViewPanel,
  CustomerProductsPage_ProductViewPanel,
} from './components/01.panels';
import {
  DiveCenterEcommerceHomePageLocalState,
  useDiveCenterEcommerceHomePageLocalState,
} from './useDiveCenterEcommerceHomePageLocalState.hook';

export const DiveCenterEcommerceHomePage = ({
  navigationContext,
}: {
  navigationContext: {
    clubPublicReference: string;
  };
}) => {
  const clubPublicReference = navigationContext.clubPublicReference;

  const localState: DiveCenterEcommerceHomePageLocalState =
    useDiveCenterEcommerceHomePageLocalState({
      clubPublicReference,
    });

  const { state, data, actions } = localState;

  

  return state.loadableContent.contentState !== 'full' ? (
    <AppLoader type="loading" />
  ) : (
    <div className="w-full">
      {!!data.categories && (
        <>
          {state.viewState.mode === 'categories-list' && (
            <CustomerProductsPage_CategoryListPanel localState={localState} />
          )}
          {state.viewState.mode === 'category-view' && (
            <CustomerProductsPage_CategoryViewPanel localState={localState} />
          )}
          {state.viewState.mode === 'product-view' && (
            <CustomerProductsPage_ProductViewPanel localState={localState} />
          )}
          {state.viewState.mode === 'book-product' && (
            <CustomerProductsPage_BookProductPanel localState={localState} />
          )}
        </>
      )}
      {state.operationInProgress && (
        <AppLoader
          type={state.operationInProgress.type}
          message={state.operationInProgress?.message}
        />
      )}
    </div>
  );
};
