import { AppLogger, JsonPatchOperation } from '@mabadive/app-common-model';
import { jsonParser } from './jsonParser.service';
import { jsonPatcher } from './jsonPatcher.service';

export const jsonPatcherSmart = {
  applySmartPatchOperations,
  buildPotentialMissingPrefixes,
  createOperationsForMissingPrefixes,
};

type SmartJsonOperationLog = {
  severity: 'info' | 'warn' | 'error';
  message: string;
  messageArg?: any;
};

function applySmartPatchOperations<T>(
  input: T,
  {
    patchOperations,
    logPrefix,
    ignoreErrors,
    mutateOriginal,
    appLogger,
  }: {
    patchOperations: JsonPatchOperation[];
    logPrefix: string;
    ignoreErrors: boolean;
    mutateOriginal?: boolean;
    appLogger?: AppLogger;
  },
): {
  output: T;
  errorsCount: number;
} {
  const { errorsCount, err, output, logs } = applySmartPatchOperationsCore({
    input,
    patchOperations,
    mutateOriginal,
    logPrefix,
  });

  logs.forEach((log) => {
    if (appLogger) {
      if (log.severity === 'error') {
        appLogger.error(log.message, log.messageArg);
      } else if (log.severity === 'warn') {
        appLogger.warn(log.message, log.messageArg);
      } else {
        appLogger.info(log.message, log.messageArg);
      }
    } else {
      console.log(log.message, log.messageArg);
    }
  });
  if (err) {
    console.error(err);
    if (appLogger && err.message) {
      appLogger.error(err.message);
    }
  }

  if (ignoreErrors || errorsCount === 0) {
    return { output, errorsCount };
  }
  throw err;
}

type ApplySmartPatchOperationsCoreReturn<T> = {
  output: T;
  errorsCount: number;
  err?: Error;
  logs: SmartJsonOperationLog[];
};

function applySmartPatchOperationsCore<T>({
  input,
  patchOperations,
  mutateOriginal,
  logPrefix,
}: {
  input: T;
  patchOperations: JsonPatchOperation[];
  mutateOriginal?: boolean;
  logPrefix: string;
}): ApplySmartPatchOperationsCoreReturn<T> {
  const logs: SmartJsonOperationLog[] = [];
  // apply patches
  let errorsCount = 0;
  try {
    const output = jsonPatcher.applyPatchOperations(input, patchOperations, {
      mutateOriginal,
    });
    const res: ApplySmartPatchOperationsCoreReturn<T> = {
      output,
      errorsCount: 0,
      logs,
    };
    return res;
  } catch (err: any) {
    let item = mutateOriginal
      ? input
      : jsonParser.parseJSONWithDates<T>(JSON.stringify(input)); // deep copy
    // try all operations one by one
    patchOperations.forEach((patchOperation, i) => {
      const localLogPrefix = `applySmartPatchOperations${
        logPrefix ? `/${logPrefix}` : ''
      } patch ${i + 1} ${patchOperation.op}`;
      try {
        item = jsonPatcher.applyPatchOperations(item, [patchOperation], {
          mutateOriginal,
        });
        logs.push({
          severity: 'info',
          message: `[${localLogPrefix}] OK`,
          messageArg: patchOperation,
        });
      } catch (err) {
        errorsCount++;
        // try to fix common issues
        if (
          tryToAutoFixSinglePatchOperation({
            patchOperation,
            item,
            logs,
            localLogPrefix,
          })
        ) {
          // auto-fix success
          errorsCount--;
        } else {
          logs.push({
            severity: 'warn',
            message: `[${localLogPrefix}] error`,
            messageArg: { item, patchOperation },
          });
        }
      }
    });
    if (errorsCount === 0) {
      logs.push({
        severity: 'warn',
        message: `[applySmartPatchOperations${
          logPrefix ? `/${logPrefix}` : ''
        }] AUTO-FIX SUCCESS on PATCH`,
        messageArg: { item, patchOperations },
      });
    } else {
      logs.push({
        severity: 'error',
        message: `[applySmartPatchOperations${
          logPrefix ? `/${logPrefix}` : ''
        }] Unable to apply PATCH`,
        messageArg: { item, patchOperations },
      });
    }
    const res: ApplySmartPatchOperationsCoreReturn<T> = {
      output: item,
      errorsCount,
      err: err as Error,
      logs,
    };
    return res;
  }
}

function tryToAutoFixSinglePatchOperation<T>({
  patchOperation,
  item,
  localLogPrefix,
  logs,
}: {
  patchOperation: JsonPatchOperation;
  item: T;
  localLogPrefix: string;
  logs: SmartJsonOperationLog[];
}): boolean {
  if (patchOperation.op === 'add') {
    const commonMissingPrefixes = [
      'divingCertification1',
      'divingCertification2',
      'equipment',
      'gaz',
      'details',
      'divesMeta',
      'diveTourSession1',
      'diveTourSession2',
      'defaultDiveConfig',
      'divingDirector',
    ];
    const commonMissingPrefix = commonMissingPrefixes.find((x) =>
      patchOperation.path.startsWith(`/${x}/`),
    );
    if (commonMissingPrefix && !(item as any)[commonMissingPrefix]) {
      (item as any)[commonMissingPrefix] = {};
      try {
        item = jsonPatcher.applyPatchOperations(item, [patchOperation], {
          mutateOriginal: true,
        });
        logs.push({
          severity: 'info',
          message: `[${localLogPrefix}] auto-fix SUCCESS`,
          messageArg: patchOperation,
        });
        return true;
      } catch (err) {
        logs.push({
          severity: 'info',
          message: `[${localLogPrefix}] auto-fix ERROR`,
          messageArg: patchOperation,
        });
        return false;
      }
    }
  }
}

function buildPotentialMissingPrefixes<T>({
  missingCandidates,
  patchOperations,
}: {
  missingCandidates: (keyof T)[];
  patchOperations: JsonPatchOperation[];
}): (keyof T)[] {
  const commonMissingPrefixes = missingCandidates.filter((x) => {
    const isCandidate =
      patchOperations.find((patchOperation) =>
        patchOperation.path.startsWith(`/${x as string}/`),
      ) !== undefined;
    if (isCandidate) {
      // on essaie d'ajouter un sous-attribut d'un attribut qui pourrait manquer
      return isCandidate;
    }
  });
  return commonMissingPrefixes;
}

function createOperationsForMissingPrefixes<T>(
  commonMissingPrefixes: (keyof T)[],
  item: T,
  patchOperations: JsonPatchOperation[],
): JsonPatchOperation[] {
  const localPatchOperations: JsonPatchOperation[] = [];
  for (const commonMissingPrefix of commonMissingPrefixes) {
    if (!(item as any)[commonMissingPrefix]) {
      // l'attribut est manquant, on insère un patch pour corriger ça
      const patch: JsonPatchOperation = {
        op: 'add',
        path: `/${commonMissingPrefix as string}`,
        value: {},
      };
      localPatchOperations.push(patch);
    }
  }
  localPatchOperations.push(...patchOperations);
  return localPatchOperations;
}
