/* eslint-disable @typescript-eslint/no-unused-vars */
import { EcommerceCategoryGql_Customer } from '@mabadive/app-common-model';
import { appUrlBuilderCustomerSpace } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppButton } from 'src/business/_core/modules/layout';
import { AppIconsAction } from 'src/business/_core/modules/layout/icons';
import { appWebConfig } from '../../../../_core/modules/root/config';
import { DiveCenterEcommerceHomePageLocalState } from '../../useDiveCenterEcommerceHomePageLocalState.hook';
import { EcommerceCategoryPreviewCover } from '../03.content';

export const CustomerProductsPage_CategoryCoverCard = ({
  category,
  localState,
}: {
  category: EcommerceCategoryGql_Customer;
  localState: DiveCenterEcommerceHomePageLocalState;
}) => {
  const redirectTo = useRedirect();

  const { state, data, actions } = localState;

  const categoryConfigs = useMemo(() => {
    return data.productCategoryConfigs.filter(
      (cf) => cf.categoryId === category._id,
    );
  }, [category._id, data.productCategoryConfigs]);

  const products = useMemo(() => {
    return data.products.filter((p) =>
      categoryConfigs.some((cf) => cf.productId === p._id),
    );
  }, [categoryConfigs, data.products]);

  const productArticles = useMemo(() => {
    const productsIds = products.map((p) => p._id);
    return data.productArticles.filter((pa) =>
      productsIds.includes(pa.productId),
    );
  }, [data.productArticles, products]);

  const pricesBounds = useMemo(() => {
    const unitPrices = productArticles
      .reduce(
        (acc, p) => acc.concat(p.pricing.prices.map((pr) => pr.unitPrice)),
        [] as number[],
      )
      .filter((x) => x > 0);
    if (productArticles.length > 0) {
      return {
        min: Math.min(...unitPrices),
        max: Math.max(...unitPrices),
      };
    }
    return {
      min: 0,
      max: 0,
    };
  }, [productArticles]);

  const appBooklet = useMemo(() => {
    return data.booklets.find((x) => x._id === category.bookletId);
  }, [data.booklets, category.bookletId]);

  const appBookletPage = useMemo(() => {
    return data.bookletPages.find((x) => x._id === appBooklet?.rootPageId);
  }, [appBooklet?.rootPageId, data.bookletPages]);

  const nextIndex = useMemo(() => {
    if (products.length > 0) {
      return Math.max(...categoryConfigs.map((p) => p.sortIndex)) + 1;
    }
    return 1;
  }, [categoryConfigs, products.length]);

  const clubPublicReference = localState.data.clubPublicReference;

  const customerSpaceCategoryPageURL = useMemo(() => {
    return appUrlBuilderCustomerSpace.buildCustomerOnlineBookingURL({
      diverWebUrl: appWebConfig.applications.diverWebUrl,
      clubPublicReference,
      categoryId: category._id,
    });
  }, [clubPublicReference, category._id]);

  return state.isIframe ? (
    <a
      className="app-card app-card-highlight rounded-t-xl grid gap-4 max-w-3xl"
      href={customerSpaceCategoryPageURL}
      target="_blank"
      rel="noreferrer"
    >
      <EcommerceCategoryPreviewCover
        className="h-full"
        category={category}
        appBookletPage={appBookletPage}
        clubPublicSettings={data.clubPublicSettings}
        productsLength={products?.length || 0}
        pricesBounds={pricesBounds}
      >
        <div className="flex flex-col gap-4">
          <AppButton
            className=""
            color="primary-bg"
            size="normal"
            icon={AppIconsAction.open}
            // onClick={(e) => {
            //   e.stopPropagation();
            //   actions.selectCategory(category._id);
            // }}
          >
            En savoir plus
          </AppButton>
        </div>
      </EcommerceCategoryPreviewCover>
    </a>
  ) : (
    <div
      className="app-card app-card-highlight rounded-t-xl grid gap-4 max-w-3xl"
      onClick={(e) => {
        actions.selectCategory(category._id);
      }}
    >
      <EcommerceCategoryPreviewCover
        className="h-full"
        category={category}
        appBookletPage={appBookletPage}
        clubPublicSettings={data.clubPublicSettings}
        productsLength={products?.length || 0}
        pricesBounds={pricesBounds}
      >
        <div className="flex flex-col gap-4">
          <AppButton
            className=""
            color="primary-bg"
            size="normal"
            icon={AppIconsAction.open}
            // onClick={(e) => {
            //   e.stopPropagation();
            //   actions.selectCategory(category._id);
            // }}
          >
            En savoir plus
          </AppButton>
        </div>
      </EcommerceCategoryPreviewCover>
    </div>
  );
};
