"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.APP_DIVE_MODE_COLORS = exports.APP_COLORS = exports.APP_GRADIENT_COLORS = exports.APP_BASE_COLORS = void 0;
exports.APP_BASE_COLORS = {
    red: '#b52206',
    orange: '#e0831b',
    orange2: '#ea8048',
    orange3: '#ee7c4f',
    blue: '#197297',
    blue2: '#1a7498',
    blue3: '#19618e',
    darkBlue: '#143d54',
    darkBlue2: '#162a38',
    black: '#000000',
    white: '#ffffff',
    grey1: '#999',
    grey2: '#aaa',
    black2: '#555',
    black3: '#777',
    blueGreen: 'rgb(16, 174, 180)',
    transparent: 'transparent',
    green: 'green'
};
exports.APP_GRADIENT_COLORS = {
    linearBlue: "linear-gradient(to bottom, ".concat(exports.APP_BASE_COLORS.blue2, " 0%,").concat(exports.APP_BASE_COLORS.blue3, " 100%)"),
    linearGrey: "linear-gradient(to bottom, ".concat(exports.APP_BASE_COLORS.grey2, " 0%,").concat(exports.APP_BASE_COLORS.grey1, " 100%)")
};
exports.APP_COLORS = __assign(__assign({}, exports.APP_BASE_COLORS), { 
    // layout
    menuSide: exports.APP_GRADIENT_COLORS.linearBlue, topBar: exports.APP_GRADIENT_COLORS.linearBlue, menuBottom: exports.APP_GRADIENT_COLORS.linearGrey, 
    // dive-mode
    diveModeObserver: '#999', diveModeSnorkeling: '#739165', diveModeWatchingTour: '#f547e1', diveModeSnorkelingSupervised: '#1c6912', diveModeAutoSupervised: '#ac0202', diveModeFirstDive: 'rgb(26, 204, 204)', diveModeTraining: '#38a4d5', diveModeTheoreticalTraining: '#d57a38', diveModeSupervised: '#215887', diveModeAutonomous: '#222', autoSupervised: '#ac0202', diveModeFreeDive: '#9b225b', diveModeFreeDiveAuto: '#590303', diveModeFreeDiveTraining: '#1a0cb0', diveModeUnknown: '#e88484', diveModeInstructor: '#f1ae0d', session: '#146775', participant: '#555', importantNotes: '#d03232', comment: '#555', weather: '#d78215', tide: '#1f358c', 
    // behavior
    update: exports.APP_BASE_COLORS.blue, error: exports.APP_BASE_COLORS.red, warn: exports.APP_BASE_COLORS.orange, success: exports.APP_BASE_COLORS.green });
exports.APP_DIVE_MODE_COLORS = {
    'free-dive': exports.APP_COLORS.diveModeFreeDive,
    'free-dive-auto': exports.APP_COLORS.diveModeFreeDiveAuto,
    'free-dive-training': exports.APP_COLORS.diveModeFreeDiveTraining,
    observer: exports.APP_COLORS.diveModeObserver,
    snorkeling: exports.APP_COLORS.diveModeSnorkeling,
    snorkelingSupervised: exports.APP_COLORS.diveModeSnorkelingSupervised,
    diveModeWatchingTour: exports.APP_COLORS.diveModeWatchingTour,
    autoSupervised: exports.APP_COLORS.diveModeAutoSupervised,
    'first-dive': exports.APP_COLORS.diveModeFirstDive,
    'theoretical-training': exports.APP_COLORS.diveModeTheoreticalTraining,
    training: exports.APP_COLORS.diveModeTraining,
    supervised: exports.APP_COLORS.diveModeSupervised,
    autonomous: exports.APP_COLORS.diveModeAutonomous,
    unknown: exports.APP_COLORS.diveModeUnknown,
    instructor: exports.APP_COLORS.diveModeInstructor
};
