import { DiveSession } from '@mabadive/app-common-model';
import { dataSorter } from '../../data/sorter/dataSorter.service';
import { DiveSessionSortByAttribute } from './DiveSessionSortByAttribute.type';

export const diveSessionsSorter = {
  sortDiveSessionsBy,
};

function sortDiveSessionsBy<
  T extends Pick<DiveSession, DiveSessionSortByAttribute>,
>(
  items: T[],
  {
    attributeName,
    asc,
  }: {
    attributeName: DiveSessionSortByAttribute;
    asc: boolean;
  },
): T[] {
  const type = isFullText() ? 'full-text' : 'default';

  return dataSorter.sortMultiple(items, {
    getSortAttributes: (x: T) => [
      {
        value: getAttribute(x, { attributeName }),
        type,
      },
      {
        value: x.time,
      },
      {
        value: x.reference,
      },
      {
        value: x.name,
        type: 'full-text',
      },
      {
        value: x.boatsIds,
      },
      {
        value: x._id,
      },
    ],
    asc,
  });

  function isFullText() {
    const textAttributes: DiveSessionSortByAttribute[] = ['name'];
    return textAttributes.includes(attributeName);
  }

  function getAttribute(
    x: Partial<T>,
    { attributeName }: { attributeName: DiveSessionSortByAttribute },
  ) {
    return x[attributeName] as any;
  }
}
