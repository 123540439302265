import { ClubProductPackageOfferView } from '@mabadive/app-common-model';
import { dataSorter } from '../../../data';
import { ClubProductPackageOfferViewPlanGroup } from './clubDiveServiceOfferViewTransformer.service';

export const clubProductPackageOfferGroupMatcher = {
  buildPlanGroupsFromOffers,
  getMatchingOffersPlan,
  findOfferPlanGroupIndex,
};

function buildPlanGroupsFromOffers(
  productPackageOffers: ClubProductPackageOfferView[],
): ClubProductPackageOfferViewPlanGroup[] {
  const groups = productPackageOffers.reduce((acc, productPackageOffer) => {
    const p = productPackageOffer.productPackage;
    const diveAttributes = p.diveAttributes;

    const successiveDivesCount =
      diveAttributes?.divePriceType === 'successive' &&
      diveAttributes?.successiveDivesCount > 1
        ? diveAttributes?.successiveDivesCount
        : 1;
    const divesCount = diveAttributes?.divesCount;
    const minDivesCount = diveAttributes?.diveSingleAttributes?.minDivesCount;
    const maxDivesCount = diveAttributes?.diveSingleAttributes?.maxDivesCount;

    if (
      !acc.some(
        (x) =>
          x.divesCount === divesCount &&
          x.successiveDivesCount == successiveDivesCount && // non strict-equal (null == undefined == 0)
          x.minDivesCount == minDivesCount && // non strict-equal (null == undefined == 0)
          x.maxDivesCount == maxDivesCount, // non strict-equal (null == undefined == 0)
      )
    ) {
      acc.push({
        successiveDivesCount,
        divesCount: diveAttributes?.divesCount,
        minDivesCount,
        maxDivesCount,
      });
    }

    return acc;
  }, [] as ClubProductPackageOfferViewPlanGroup[]);
  return dataSorter.sortByAttributes(
    groups,
    [
      {
        name: 'divesCount',
      },
      {
        name: 'successiveDivesCount',
      },
    ],
    {
      asc: true,
    },
  );
}

function findOfferPlanGroupIndex({
  planGroups,
  offer,
}: {
  planGroups: ClubProductPackageOfferViewPlanGroup[];
  offer: ClubProductPackageOfferView;
}) {
  const planGroupIndex = planGroups.findIndex((x) => {
    if (offer?.productPackage?.diveAttributes?.successiveDivesCount > 1) {
      return (
        x.successiveDivesCount ==
        offer?.productPackage?.diveAttributes?.successiveDivesCount // non strict-equal (null == undefined == 0)
      );
    } else {
      return (
        x.divesCount == offer?.productPackage?.diveAttributes?.divesCount && // non strict-equal (null == undefined == 0)
        x.minDivesCount ==
          offer?.productPackage?.diveAttributes?.diveSingleAttributes
            ?.minDivesCount && // non strict-equal (null == undefined == 0)
        x.maxDivesCount ==
          offer?.productPackage?.diveAttributes?.diveSingleAttributes
            ?.maxDivesCount // non strict-equal (null == undefined == 0)
      );
    }
  });
  return planGroupIndex;
}
function getMatchingOffersPlan({
  offers,
  group,
}: {
  offers: ClubProductPackageOfferView[];
  group: ClubProductPackageOfferViewPlanGroup;
}): ClubProductPackageOfferView[] {
  if (!group) {
    return offers;
  }
  return offers.filter((o) => {
    if (o.productPackage.diveAttributes?.divesCount !== group?.divesCount) {
      return false;
    }
    if (
      group?.successiveDivesCount > 1 &&
      o.productPackage.diveAttributes?.successiveDivesCount !==
        group?.successiveDivesCount
    ) {
      return false;
    }
    if (
      group?.minDivesCount !=
      o.productPackage.diveAttributes?.diveSingleAttributes?.minDivesCount // non strict-equal (null == undefined == 0)
    ) {
      return false;
    }
    if (
      group?.maxDivesCount !=
      o.productPackage.diveAttributes?.diveSingleAttributes?.maxDivesCount // non strict-equal (null == undefined == 0)
    ) {
      return false;
    }
    return true;
  });
}
