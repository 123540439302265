import { of } from 'rxjs';
import { catchError, first, switchMap, tap } from 'rxjs/operators';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { browserUrlParser } from 'src/lib/browser';
import { browserCache } from 'src/_common-browser';
import { authenticationClient } from './authenticationClient.service';
import { JWT_TOKEN_BROWSER_STORAGE_ID } from './JWT_TOKEN_BROWSER_STORAGE_ID.const';
import { AppSecurityUser } from './model';

export const bootstrapAuthentication = () => {
  appLogger.info('[bootstrapAuthentication] start');

  const urlAuthenticationToken =
    browserUrlParser.getUrlQueryParams()['auth-token'];

  const token$ = urlAuthenticationToken
    ? of(urlAuthenticationToken).pipe(
        tap(() =>
          appLogger.info(
            '[bootstrapAuthentication] authentication token loaded from url',
          ),
        ),
        catchError((err) => {
          // invalid token: ignore it
          return of(undefined);
        }),
      )
    : browserCache
        .get(JWT_TOKEN_BROWSER_STORAGE_ID, { ignoreError: true })
        .pipe(
          tap((token) => {
            if (token) {
              appLogger.info(
                '[bootstrapAuthentication] authentication token loaded from cache',
              );
            }
          }),
          catchError((err) => {
            // invalid token: ignore it
            return of(undefined);
          }),
        );

  return token$.pipe(
    switchMap((token) => {
      if (token) {
        return authenticationClient.authenticateByToken(token).pipe(
          catchError((err) => {
            // invalid token: ignore it
            return of(undefined);
          }),
        );
      }
      return of(undefined);
    }),
    switchMap((authUser: AppSecurityUser) => {
      appLogger.debug('[bootstrapAuthentication] authUser:', authUser);

      if (!authUser) {
        appLogger.debug('[bootstrapAuthentication] authUser not defined');

        return authenticationClient.clearAppAuth();
      }

      return of(authUser);
    }),
    catchError((err) => {
      // unexpected error: ignore
      return of(undefined);
    }),
    first(),
    tap((result) =>
      appLogger.info('[bootstrapAuthentication] result:', result),
    ),
  );
};
