import { useEffect, useState } from 'react';
import { IIdleTimer, useIdleTimer } from 'react-idle-timer';
import { appWebConfig } from '../../../../_core/modules/root/config';

const isDevEnv = appWebConfig.envId === 'dev';

export function useUserActivityTimeout({
  timerId,
  timeOutInMinutes,
  onTimeout,
  disabled,
}: {
  timerId: string; // identifiant unique du timer (pour ne pas avoir de conflits de synchro multi-tabs si plusieurs timer sur la même page)
  timeOutInMinutes: number;
  onTimeout: () => void;
  disabled: boolean; // on désactive le timer tant que ce n'est pas nécessaire (ex: pas encore de produit dans le panier)
}): {
  isExpired: boolean;
  idleTimer: IIdleTimer;
  useRemainingTimeInSeconds: ({
    periodInSeconds,
  }: {
    periodInSeconds: number;
  }) => number;
} {
  const [isExpired, setIsExpired] = useState(false);
  const handleOnActive = () => setIsExpired(false);
  const handleOnAction = () => setIsExpired(false);

  const handleOnIdle = (event?: Event, idleTimer?: IIdleTimer) => {
    if (isDevEnv) {
      // eslint-disable-next-line no-console
      console.info(
        '[useUserActivityTimeout] Timeout détecté',
        event,
        idleTimer,
      );
    }
    onTimeout();
  };

  const idleTimer: IIdleTimer = useIdleTimer({
    name: timerId,
    timeout: 1000 * timeOutInMinutes, // 15 minutes
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
    crossTab: true, // Enables the cross tab feature.
    syncTimers: 10 * 1000, // 10 seconds (fréquence de synchro cross-tab)
    onMessage: (data: any, idleTimer?: IIdleTimer) => {
      if (isDevEnv) {
        // eslint-disable-next-line no-console
        console.info(
          '[useUserActivityTimeout] Activité détectée sur un autre onglet',
          data,
          idleTimer,
        );
      }
    },
    // leaderElection: true, // permet de savoir quel est l'onglet actif de l'activité'
    disabled, // on désactive le timer tant que ce n'est pas nécessaire (ex: pas encore de produit dans le panier)
  });
  // idleTimer.getRemainingTime()

  const useRemainingTimeInSeconds = ({
    periodInSeconds,
  }: {
    periodInSeconds: number;
  }) => {
    const remainingTimeInMs = useRemainingTimeInMsInner({
      idleTimer,
      periodInSeconds,
    });
    return remainingTimeInMs ? Math.floor(remainingTimeInMs / 1000) : undefined;
  };

  return { isExpired, idleTimer, useRemainingTimeInSeconds };
}
const useRemainingTimeInMsInner = ({
  idleTimer,
  periodInSeconds,
}: {
  idleTimer: IIdleTimer;
  periodInSeconds: number;
}) => {
  const [remainingTimeInMs, setRemainingTimeInMs] =
    useState<number | undefined>(undefined);

  useEffect(() => {
    // Définir une fonction pour obtenir le temps restant
    const getRemainingTime = () => {
      const remainingTime = idleTimer?.getRemainingTime();
      setRemainingTimeInMs(remainingTime);
    };

    // Exécuter la fonction toutes les N secondes
    const intervalId = setInterval(getRemainingTime, periodInSeconds * 1000);

    // Nettoyer l'intervalle lorsque le hook est démonté
    return () => clearInterval(intervalId);
  }, [idleTimer, periodInSeconds]); // Recréer l'effet si idleTimer change

  return remainingTimeInMs;
};
