import { AppCurrency } from '@mabadive/app-common-model';
import React from 'react';
import { AppPriceLabel } from './AppPriceLabel';

export const AppPriceLabelRatio = ({
  amount,
  total,
  mainCurrency,
  hideTotalIfSameAmount,
  className,
}: {
  amount: number;
  total: number;
  hideTotalIfSameAmount?: boolean;
  mainCurrency: AppCurrency;
  className?: string;
}) => {
  return amount === null ||
    amount === undefined ||
    total === null ||
    total === undefined ? null : (
    <span className={className}>
      <AppPriceLabel amount={amount} mainCurrency={mainCurrency} />
      {total && (!hideTotalIfSameAmount || amount !== total) && (
        <span>
          <span className="mx-1">/</span>
          <AppPriceLabel amount={total} mainCurrency={mainCurrency} />
        </span>
      )}
    </span>
  );
};
