import {
  ClubDiverDefaultDiveConfig,
  ClubParticipant,
  ClubParticipantAutoSupervisedDiveMode,
  ClubParticipantDetails,
  ClubSettings,
  DIVE_CERTIFICATIONS_REFERENCE_MAP,
  DiveCenterResume,
  DiveCertificationReference,
  DiveGazType,
  DiveMode,
  DiveTrainingReference,
} from '@mabadive/app-common-model';

export const diveModeAnalyser = {
  isAutonomous,
  isAvailableGuide,
  hasScubaDivingSecurityParams,
  hasScubaDivingEquipments,
  hasExploPricingEquipmentOption,
  buildDiveModeChangeImpacts,
  buildAutoSupervisedDiveModeChangeImpacts,
  hasParticipantCertificationReference,
};

function isAutonomous(
  p: Pick<ClubParticipant, 'diveMode' | 'autoSupervisedDetails'>,
): boolean {
  const groupGuidesDiveModes: DiveMode[] = ['autonomous', 'instructor'];

  return (
    groupGuidesDiveModes.includes(p.diveMode) ||
    (p.diveMode === 'autoSupervised' &&
      groupGuidesDiveModes.includes(p.autoSupervisedDetails?.diveMode))
  );
}
function isAvailableGuide(
  p: Pick<ClubParticipant, 'diveMode' | 'autoSupervisedDetails'>,
): boolean {
  const groupGuidesDiveModes: DiveMode[] = ['autonomous', 'instructor'];

  return (
    groupGuidesDiveModes.includes(p.diveMode) ||
    (p.diveMode === 'autoSupervised' &&
      groupGuidesDiveModes.includes(p.autoSupervisedDetails?.diveMode))
  );
}
function hasParticipantCertificationReference(diveMode: DiveMode): boolean {
  const diveModes: DiveMode[] = ['autonomous', 'supervised', 'instructor'];

  return diveModes.includes(diveMode);
}
function hasScubaDivingSecurityParams(diveMode: DiveMode): boolean {
  const diveModes: DiveMode[] = [
    'first-dive',
    'autonomous',
    'supervised',
    'training',
    'instructor',
    'autoSupervised',
  ];

  return diveModes.includes(diveMode);
}
function hasScubaDivingEquipments(diveMode: DiveMode): boolean {
  const diveModes: DiveMode[] = [
    'first-dive',
    'training',
    'supervised',
    'autonomous',
    'instructor',
    'autoSupervised',
  ];

  return diveModes.includes(diveMode);
}

function hasExploPricingEquipmentOption(diveMode: DiveMode): boolean {
  const diveModes: DiveMode[] = ['supervised', 'autonomous', 'instructor'];

  return diveModes.includes(diveMode);
}

export type DiveModeChangeImpacts = {
  diveConfig: Pick<
    ClubDiverDefaultDiveConfig,
    | 'firstDiveReference'
    | 'trainingReference'
    | 'certificationReference'
    | 'gaz'
    | 'autoSupervisedDetails'
  >;
  details: Pick<ClubParticipantDetails, 'specialDiveType'>;
};

export type AutoSupervisedDiveModeChangeImpacts = {
  diveConfig: Pick<
    ClubDiverDefaultDiveConfig,
    'trainingReference' | 'certificationReference'
  >;
};

function buildDiveModeChangeImpacts(
  diveMode: DiveMode,
  {
    previousDiveMode,
    clubSettings,
    diveCenterResume,
    gazType,
    firstDiveReference,
    participantCertificationReference,
    diverCertificationReference,
    trainingReference,
  }: {
    previousDiveMode: DiveMode;
    clubSettings: ClubSettings;
    diveCenterResume: DiveCenterResume;
    gazType: DiveGazType;
    firstDiveReference: string;
    participantCertificationReference: string;
    diverCertificationReference: DiveCertificationReference;
    trainingReference: string;
  },
): DiveModeChangeImpacts {
  const diveConfigToUpdate: DiveModeChangeImpacts = {
    details: {},
    diveConfig: {},
  };
  const isScubaDivingEquipmentRequired =
    diveModeAnalyser.hasScubaDivingEquipments(diveMode);
  if (isScubaDivingEquipmentRequired) {
    if (!gazType) {
      diveConfigToUpdate.diveConfig.gaz =
        diveCenterResume?.publicSettings?.diving?.defaultGaz ?? null; // null indique qu'il faut supprimer la valeur
    }
  } else if (gazType) {
    diveConfigToUpdate.diveConfig.gaz = null; // null indique qu'il faut supprimer la valeur
  }

  switch (diveMode) {
    case 'first-dive': {
      diveConfigToUpdate.details.specialDiveType = null; // null indique qu'il faut supprimer la valeur
      if (
        !firstDiveReference &&
        clubSettings.firstDive.trainingReferences.length
      ) {
        const firstDiveReference =
          clubSettings.publicSettings?.services?.firstDives
            ?.defaultFirstDiveTrainingReference ??
          clubSettings.firstDive.trainingReferences[0] ??
          null; // null indique qu'il faut supprimer la valeur
        diveConfigToUpdate.diveConfig.firstDiveReference = firstDiveReference;
      }

      break;
    }
    case 'training':
    case 'theoretical-training': {
      diveConfigToUpdate.details.specialDiveType = null; // null indique qu'il faut supprimer la valeur
      diveConfigToUpdate.diveConfig.trainingReference =
        getTrainingReference({
          mainCertificationReference: diverCertificationReference,
        }) ?? null; // null indique qu'il faut supprimer la valeur
      break;
    }
    case 'instructor':
    case 'supervised':
    case 'autonomous': {
      if (!participantCertificationReference) {
        if (diverCertificationReference) {
          diveConfigToUpdate.diveConfig.certificationReference =
            diverCertificationReference;
        }
      }

      break;
    }
    case 'autoSupervised': {
      if (
        previousDiveMode === 'training' ||
        previousDiveMode === 'autonomous' ||
        previousDiveMode === 'instructor' ||
        previousDiveMode === 'supervised'
      ) {
        diveConfigToUpdate.diveConfig.autoSupervisedDetails = {
          diveMode: previousDiveMode,
        };
        switch (previousDiveMode) {
          case 'supervised':
          case 'autonomous': {
            diveConfigToUpdate.diveConfig.autoSupervisedDetails.certificationReference =
              participantCertificationReference;
            break;
          }
          case 'training': {
            diveConfigToUpdate.diveConfig.autoSupervisedDetails.trainingReference =
              trainingReference;
            break;
          }
        }
      }
      break;
    }
    default: {
      diveConfigToUpdate.details.specialDiveType = null; // null indique qu'il faut supprimer la valeur
    }
  }

  return diveConfigToUpdate;
}

function buildAutoSupervisedDiveModeChangeImpacts(
  autoSupervisedDiveMode: ClubParticipantAutoSupervisedDiveMode,
  {
    clubSettings,
    diveCenterResume,
    diverCertificationReference,
    autoSupervisedCertificationReference,
  }: {
    clubSettings: ClubSettings;
    diveCenterResume: DiveCenterResume;
    diverCertificationReference: DiveCertificationReference;
    autoSupervisedCertificationReference: string;
  },
): AutoSupervisedDiveModeChangeImpacts {
  const diveConfigToUpdate: AutoSupervisedDiveModeChangeImpacts = {
    diveConfig: {},
  };

  switch (autoSupervisedDiveMode) {
    case 'training':
      diveConfigToUpdate.diveConfig.trainingReference =
        getTrainingReference({
          mainCertificationReference: diverCertificationReference,
        }) ?? null; // null indique qu'il faut supprimer la valeur
      break;
    case 'instructor':
    case 'supervised':
    case 'autonomous': {
      if (!autoSupervisedCertificationReference) {
        if (diverCertificationReference) {
          diveConfigToUpdate.diveConfig.certificationReference =
            diverCertificationReference;
        }
      }
      break;
    }
  }

  return diveConfigToUpdate;
}
function getTrainingReference({
  mainCertificationReference,
}: {
  mainCertificationReference: DiveCertificationReference;
}): DiveTrainingReference {
  if (!mainCertificationReference) {
    return 'FN1';
  } else {
    const certification =
      DIVE_CERTIFICATIONS_REFERENCE_MAP[mainCertificationReference];
    if (certification && certification.nextSuggestedTrainingReference) {
      return certification.nextSuggestedTrainingReference;
    }
  }
}
