"use strict";
exports.__esModule = true;
exports.APP_CURRENCIES = void 0;
// iso code:  https://en.wikipedia.org/wiki/ISO_4217
exports.APP_CURRENCIES = [
    {
        isoCode: 'euro',
        stripeIsoCode: 'eur',
        label: 'Euro',
        sign: '€',
        cents: 2,
        excelNumFmt: '#,##0.00 €;-#,##0.00 €',
        excelNumFmtNegativeRed: '#,##0.00 €;[Red]-#,##0.00 €'
    },
    {
        isoCode: 'dollard',
        stripeIsoCode: 'usd',
        label: 'Dollar',
        sign: '$',
        cents: 2,
        excelNumFmt: '[$$-409]#,##0.00;-[$$-409]#,##0.00',
        excelNumFmtNegativeRed: '[$$-409]#,##0.00;[Red]-[$$-409]#,##0.00'
    },
    {
        isoCode: 'xpf',
        stripeIsoCode: 'xpf',
        label: 'Franc CFP',
        sign: 'XPF',
        cents: 0,
        excelNumFmt: '# ##0 XPF;-# ##0 XPF',
        excelNumFmtNegativeRed: '# ##0 XPF;[Red]-# ##0 XPF'
    },
    {
        isoCode: 'gpb',
        stripeIsoCode: 'gbp',
        label: 'Livre sterling',
        sign: '£',
        cents: 2,
        excelNumFmt: '£#,##0.00;-£#,##0.00',
        excelNumFmtNegativeRed: '£#,##0.00;[Red]-£#,##0.00'
    },
    {
        isoCode: 'chf',
        stripeIsoCode: 'chf',
        label: 'Franc suisse',
        sign: 'CHF',
        cents: 2,
        excelNumFmt: '#,##0.00" CHF";-#,##0.00" CHF"',
        excelNumFmtNegativeRed: '#,##0.00" CHF";[Red]-#,##0.00" CHF"'
    },
    {
        isoCode: 'xof',
        stripeIsoCode: 'xof',
        label: 'Franc CFA',
        sign: 'FCFA',
        cents: 0,
        excelNumFmt: '# ##0 FCFA;-# ##0 FCFA',
        excelNumFmtNegativeRed: '# ##0 FCFA;[Red]-# ##0 FCFA'
    },
    {
        isoCode: 'mur',
        stripeIsoCode: 'mur',
        label: 'Roupie Mauricienne',
        sign: 'Rs',
        cents: 0,
        excelNumFmt: 'Rs #,##0;-Rs #,##0',
        excelNumFmtNegativeRed: 'Rs #,##0;[Red]-Rs #,##0'
    },
    {
        isoCode: 'php',
        stripeIsoCode: 'php',
        label: 'Peso philippin',
        sign: '₱',
        cents: 2,
        excelNumFmt: '₱#,##0.00;-₱#,##0.00',
        excelNumFmtNegativeRed: '₱#,##0.00;[Red]-₱#,##0.00'
    },
];
