"use strict";
exports.__esModule = true;
exports.diveCertificationSortReferenceBuilder = void 0;
exports.diveCertificationSortReferenceBuilder = {
    buildSortReference: buildSortReference
};
function buildSortReference(_a) {
    var org = _a.org, cert = _a.cert, suffixIndex = _a.suffixIndex;
    var suffix = org.sortReference;
    if (!cert.autonomous && cert.supervised) {
        // seulements supervisés (N1...)
        var prefix = "".concat(cert.favourite ? '0' : '1', "-1-supervised");
        return "".concat(prefix, "-").concat(cert.supervised.maxDepth < 10 ? '0' : '').concat(cert.supervised.maxDepth, "m-").concat(suffix, "-").concat(suffixIndex);
    }
    else if (!cert.supervisor && cert.autonomous) {
        // autonomes non-superviseurs (N2, N3...)
        var prefix = "".concat(cert.favourite ? '0' : '2', "-2-autonomous");
        return "".concat(prefix, "-").concat(cert.autonomous.maxDepth < 10 ? '0' : '').concat(cert.autonomous.maxDepth, "m-").concat(suffix, "-").concat(suffixIndex);
    }
    else if (cert.supervisor) {
        // superviseurs (N4...)
        var prefix = "".concat(cert.favourite ? '0' : '3', "-3-supervisor");
        return "".concat(prefix, "-").concat(cert.autonomous.maxDepth < 10 ? '0' : '').concat(cert.autonomous.maxDepth, "m-").concat(suffix, "-").concat(suffixIndex);
    }
    return "4-unknown-".concat(suffix, "-").concat(suffixIndex);
}
