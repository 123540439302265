import { map } from 'rxjs/operators';
import { useLoadable } from 'src/business/_core/data/hooks';
import { authenticationStore } from './authenticationStore.service';
import { AppSecurityUser } from './model';

export function useAppSecurityUser(): AppSecurityUser {
  return useLoadable({
    debugName: 'useAppSecurityUser',
    initialValueFromLoadSnapshot: true,
    load: () => authenticationStore.auth.get().pipe(map(({ user }) => user)),
  });
}
