export const appUrlBuilderCore = {
  buildShortUrlLinkOnDiverSite,
};

function buildShortUrlLinkOnDiverSite({
  diverWebUrl,
  aliasKey,
}: {
  diverWebUrl: string;
  aliasKey: string;
}) {
  return `${diverWebUrl}/s/${aliasKey}`;
}
