"use strict";
exports.__esModule = true;
exports.DEFAULT_DIVE_CENTER_UI_THEME = void 0;
exports.DEFAULT_DIVE_CENTER_UI_THEME = {
    name: {
        bgColor: '#ffffff',
        textColor: '#1a7498'
    },
    location: {
        bgColor: undefined,
        textColor: '#1a7498'
    },
    proName: {
        bgColor: '#1a7498',
        textColor: '#ffffff'
    }
};
