import { DiveSessionResumeFull } from '@mabadive/app-common-model';
import { attributeStoreFactory, loadableAttributeCollectionStoreFactory } from '@mabadive/app-common-services';
import { getBaseStore } from 'src/business/_core/store/baseStore';
import { ClubDiveSessionResumeFullCollectionLoadingState } from './ClubDiveSessionResumeFullCollectionLoadingState.type';

export const CLUB_DIVE_SESSION_RESUME_COLLECTION_INITIAL_LOADING_STATE: ClubDiveSessionResumeFullCollectionLoadingState = {
  loaded: { loadedState: 'none', loadedWeekTimestamps: [] },
  initialLoading: {
    status: 'not-started',
  },
};

const diveSessionResumeFullCollection = loadableAttributeCollectionStoreFactory.create<DiveSessionResumeFull>(getBaseStore(), 'c_sessions_resumes');
const loadingState = attributeStoreFactory.create<ClubDiveSessionResumeFullCollectionLoadingState>(
  getBaseStore(),
  'c_sessions_resumes_load',
  CLUB_DIVE_SESSION_RESUME_COLLECTION_INITIAL_LOADING_STATE,
);

export const clubDiveSessionStore = {
  diveSessionResumeFullCollection,
  loadingState,
  setInitialData,
  resetStore,
  addOneToStore,
  addManyToStore,
};

function setInitialData({ loadedWeekTimestamps, clubDiversResumes }: { loadedWeekTimestamps: number[]; clubDiversResumes: DiveSessionResumeFull[] }) {
  diveSessionResumeFullCollection.setAll(clubDiversResumes);

  loadingState.reduce((state) => ({
    ...state,
    initialLoading: {
      status: 'success',
    },
    loaded: {
      loadedWeekTimestamps,
      loadedState: 'partial',
    },
  }));
}

function resetStore() {
  loadingState.set(CLUB_DIVE_SESSION_RESUME_COLLECTION_INITIAL_LOADING_STATE);
  diveSessionResumeFullCollection.removeAll();
}

function addOneToStore(diveSessionResume: DiveSessionResumeFull) {
  diveSessionResumeFullCollection.setOne({
    value: diveSessionResume,
    actionId: 'addOneToStore(upd)',
    identify: (x) => x.reference === diveSessionResume.reference,
  });
}

function addManyToStore({ minDateInclusive, diveSessionResumes }: { minDateInclusive: Date; diveSessionResumes: DiveSessionResumeFull[] }) {
  if (!loadingState.getSnapshot().loaded.loadedWeekTimestamps.includes(minDateInclusive.getTime())) {
    diveSessionResumeFullCollection.addMany({ values: diveSessionResumes, actionId: 'addManyToStore' });
  } else {
    // update
    diveSessionResumeFullCollection.addOrUpdateMany({
      values: diveSessionResumes,
      actionId: 'addManyToStore(upd)',
      identify: (a, b) => a.reference === b.reference,
    });
  }

  loadingState.reduce((x) => {
    const loadedWeekTimestamps = [].concat(...x.loaded.loadedWeekTimestamps);
    if (!loadedWeekTimestamps.includes(minDateInclusive.getTime())) {
      loadedWeekTimestamps.push(minDateInclusive.getTime());
    }

    return {
      ...x,
      loaded: {
        ...x.loaded,
        loadedState: 'partial',
        loadedWeekTimestamps,
      },
    };
  });
}
