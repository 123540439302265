import AccountIcon from '@material-ui/icons/AccountBoxOutlined';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AndroidIcon from '@material-ui/icons/Android';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import BarChartIcon from '@material-ui/icons/BarChart';
import CakeIcon from '@material-ui/icons/Cake';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CancelIcon from '@material-ui/icons/Cancel';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ChevronLeftTwoToneIcon from '@material-ui/icons/ChevronLeftTwoTone';
import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone';
import CloseIcon from '@material-ui/icons/Close';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DeleteIcon from '@material-ui/icons/Delete';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import EditIcon from '@material-ui/icons/Edit';
import ErrorIcon from '@material-ui/icons/Error';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import EventIcon from '@material-ui/icons/Event';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FaceIcon from '@material-ui/icons/Face';
import FacebookIcon from '@material-ui/icons/Facebook';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FlashAutoIcon from '@material-ui/icons/FlashAuto';
import GradeIcon from '@material-ui/icons/Grade';
import GroupIcon from '@material-ui/icons/Group';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import HistoryIcon from '@material-ui/icons/History';
import HomeIcon from '@material-ui/icons/Home';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import InfoIcon from '@material-ui/icons/Info';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Bars3Icon from '@material-ui/icons/Menu';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import PageviewIcon from '@material-ui/icons/Pageview';
import PeopleIcon from '@material-ui/icons/People';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PlaceIcon from '@material-ui/icons/Place';
import PoolIcon from '@material-ui/icons/Pool';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import PrintIcon from '@material-ui/icons/Print';
import PublishIcon from '@material-ui/icons/Publish';
import RefreshIcon from '@material-ui/icons/Refresh';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import SecurityIcon from '@material-ui/icons/Security';
import SettingsIcon from '@material-ui/icons/Settings';
import TabletMacIcon from '@material-ui/icons/TabletMac';
import TodayIcon from '@material-ui/icons/Today';
import TwitterIcon from '@material-ui/icons/Twitter';
import WarningIcon from '@material-ui/icons/Warning';

export type AppIconsTypeId = 'success' | 'warning';

// TODO remplacer par https://heroicons.com/ ?

export const AppIconsMaterial = {
  account: AccountIcon,
  add: AddCircleIcon,
  auto: FlashAutoIcon,
  back: NavigateBeforeIcon,
  birthday: CakeIcon,
  boats: DirectionsBoatIcon,
  calendar: CalendarTodayIcon,
  calendarEvent: EventIcon,
  calendarToday: TodayIcon,
  cancel: CancelIcon,
  check: CheckIcon,
  clone: FileCopyIcon,
  close: CloseIcon,
  collapseLeft: ChevronLeftTwoToneIcon,
  collapseRight: ChevronRightTwoToneIcon,
  dashboard: DashboardIcon,
  delete: DeleteIcon,
  demo: OndemandVideoIcon,
  dive: PoolIcon,
  diverAdd: PersonAddIcon,
  divers: FaceIcon,
  download: CloudDownloadIcon,
  edit: EditIcon,
  equipment: AndroidIcon,
  error: ErrorIcon,
  importExport: ImportExportIcon,
  expandLess: ExpandLessIcon,
  expandMore: ExpandMoreIcon,
  facebook: FacebookIcon,
  features: TabletMacIcon,
  group: GroupIcon,
  groupAdd: GroupAddIcon,
  groupRemove: CancelPresentationIcon,
  history: HistoryIcon,
  home: HomeIcon,
  info: InfoIcon,
  login: AccountCircleIcon,
  logout: PowerSettingsNewIcon,
  menu: Bars3Icon,
  next: NavigateNextIcon,
  place: PlaceIcon,
  pricing: EuroSymbolIcon,
  print: PrintIcon,
  refresh: RefreshIcon,
  resume: ArtTrackIcon,
  save: SaveIcon,
  search: SearchIcon,
  security: SecurityIcon,
  selectArrowDown: ArrowDropDownIcon,
  services: CardGiftcardIcon,
  settings: SettingsIcon,
  training: GradeIcon,
  plan: ListAltIcon,
  staff: PeopleIcon,
  multiplePeople: PeopleIcon,
  stats: BarChartIcon,
  success: CheckCircleIcon,
  twitter: TwitterIcon,
  upload: PublishIcon,
  view: PageviewIcon,
  warning: WarningIcon,
};
