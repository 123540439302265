import { StackLogLoggerOptions } from '../logger/stack-log-logger-options';
import { StackLogProcess } from '../model/stack-log-process.model';
import { StackLogProcessHistory } from './stack-log-process-history.model';

export class StackLogProcessHistoryService<T extends StackLogLoggerOptions<any>> {

  // WeakMap does not prevent garbage collection once `StackLogProcess` is not referenced anymore 
  // @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/WeakMap
  private processLogsHistoryMap = new WeakMap<StackLogProcess, StackLogProcessHistory<T>>();

  public getHistory(options: StackLogLoggerOptions<any>, timestamp: Date): StackLogProcessHistory<T> {
    const process = options.context.process;
    if (!process) {
      return undefined;
    }
    let logsHistory = this.processLogsHistoryMap.get(process);
    if (!logsHistory) {
      logsHistory = {
        process,
        creationDate: timestamp,
        logs: [],
      };
      this.processLogsHistoryMap.set(process, logsHistory);
    }
    return logsHistory;
  }


  public appendToHistory(logsHistory: StackLogProcessHistory<T>, logOptions: T, maxStackSize: number) {
    if (logsHistory) {
      logsHistory.logs.push(logOptions);
      if (logsHistory.logs.length > maxStackSize) {
        // remove first item as max size has been reached
        logsHistory.logs.shift();
      }
    }
  }
}