import {
  AppRichTextContent,
  AppRichTextElement,
} from '@mabadive/app-common-model';

export const richTextBuilder = {
  createRichTextContent,
  createRichTextElements,
};

function createRichTextContent({
  text = '',
  style = 'text',
}: {
  text: string;
  style?: 'text' | 'title+empty-text';
}): AppRichTextContent {
  const elements: AppRichTextElement[] = createRichTextElements({
    text,
    style,
  });
  const richTextContent: AppRichTextContent = {
    elements,
  };
  return richTextContent;
}
function createRichTextElements({
  text = '',
  style = 'text',
}: {
  text?: string;
  style?: 'text' | 'title+empty-text';
}): AppRichTextElement[] {
  let textChunks = text.split(/\n/g);
  if (textChunks.length === 0) {
    textChunks = [''];
  }
  if (style === 'text') {
    const elements: AppRichTextElement[] = textChunks.map((str) => ({
      type: 'p',
      children: [{ text: str }],
    }));
    return elements;
  } else if (style === 'title+empty-text') {
    const elements: AppRichTextElement[] = textChunks.map((str) => ({
      type: 'heading-one',
      children: [{ text: str }],
    }));
    elements.push({
      type: 'p',
      children: [{ text: '' }],
    });
    return elements;
  }
  return [];
}
