import { AppUiThemeText } from '@mabadive/app-common-model';
import { colorGenerator } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';

export function AppUiThemeTextCard({
  theme,
  children,
  style: styleInput,
  border,
  className = '',
}: {
  theme: AppUiThemeText;
  border?: boolean;
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
  style?: React.CSSProperties;
}) {
  const style = useMemo(() => {
    const style = {
      backgroundColor: theme?.bgColor,
      color: theme?.textColor,
      borderColor: border
        ? colorGenerator.darken(theme?.bgColor, 0.5)
        : undefined,
    };
    if (!styleInput) {
      return style;
    }
    return {
      ...styleInput,
      ...style,
    };
  }, [border, styleInput, theme?.bgColor, theme?.textColor]);
  return (
    <div
      className={clsx(
        (theme?.bgColor || border) && 'py-px px-0.5',
        border && 'border',
        className,
      )}
      style={style}
    >
      {children}
    </div>
  );
}
