import {
  ClubResumeGql_Customer,
  ClubResumeGql_Public,
  Customer_ClubResumePublic,
} from '@mabadive/app-common-model';

export const clubResumePublicCustomerBuilder = {
  buildFromGql_Customer,
  buildFromGql_Public,
};

export function buildFromGql_Customer(
  content: ClubResumeGql_Customer,
): Customer_ClubResumePublic {
  const { clubSettings, boats, diveSites, diveCenters, inquiries, ...club } =
    content;

  clubSettings.publicSettings.general = clubSettings.general;
  return {
    club,
    clubSettings,
    diveCenters: diveCenters ?? [],
    boats: boats ?? [],
    diveSites: diveSites ?? [],
    inquiries: inquiries ?? [],
  };
}

export function buildFromGql_Public(
  content: ClubResumeGql_Public,
): Customer_ClubResumePublic {
  const { clubSettings, diveCenters, ...club } = content;

  clubSettings.publicSettings.general = clubSettings.general;
  return {
    club,
    clubSettings,
    diveCenters: diveCenters ?? [],
    boats: [],
    diveSites: [],
    inquiries: [],
  };
}
