import {
  ClubPublicSettingsCustomerSpaceDisplaySessionConfig,
  Customer_ClubResumePublic,
  DiveSessionTimeDayPeriod,
} from '@mabadive/app-common-model';
import { clubDiveSessionThemeBuilder } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import {
  AggregatedBookingSessionFull,
  BookingResumeParticipantForSession,
} from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/models';
import { DiveCenterPublicBadge } from 'src/pages';
import { DiveSessionSecuritySheetCardHeader } from './DiveCenterSecuritySheetCardHeader';
import { DiveCenterSecuritySheetCardParticipant } from './DiveCenterSecuritySheetCardParticipant';
import { ClubDiveSessionHeaderSessionDate } from './components';

export const DiveSessionSecuritySheetCard = ({
  clubResume,
  bookingSessionFull,
  participants,
  config,
}: {
  clubResume: Customer_ClubResumePublic;
  bookingSessionFull: AggregatedBookingSessionFull;
  participants: BookingResumeParticipantForSession[];
  config: ClubPublicSettingsCustomerSpaceDisplaySessionConfig;
}) => {
  const session = bookingSessionFull.diveSession;
  const timeDayPeriod: DiveSessionTimeDayPeriod = useMemo(
    () => clubDiveSessionThemeBuilder.buildTimeDayPeriod(session.time),
    [session.time],
  );
  const isMultiDiveCenters = clubResume.diveCenters.length > 1;
  const sessionDiveCenter = useMemo(
    () =>
      clubResume.diveCenters.find(
        (dc) => dc._id === bookingSessionFull.diveSession.diveCenterId,
      ),
    [bookingSessionFull.diveSession.diveCenterId, clubResume.diveCenters],
  );
  return (
    <div
      className={`w-full bg-white border
      ${session.isVirtual ? 'border-dashed' : ''}
      ${`border-day-period-${timeDayPeriod}-dark`} 
      text-left`}
    >
      {isMultiDiveCenters && (
        <DiveCenterPublicBadge
          // className="my-1"
          diveCenter={sessionDiveCenter}
        />
      )}
      <ClubDiveSessionHeaderSessionDate
        className="px-1 bg-gray-600 py-0.5"
        diveSession={session}
        config={config}
      />
      <DiveSessionSecuritySheetCardHeader
        bookingSessionFull={bookingSessionFull}
        config={config}
      />
      <table
        className={
          'w-full table-fixed divide-y truncate divide-gray-200 bg-white'
        }
      >
        <tbody>
          {participants.map((participant) => (
            <DiveCenterSecuritySheetCardParticipant
              key={
                participant.bookingParticipantFull.diveSessionParticipant._id
              }
              bookingSessionFull={bookingSessionFull}
              participant={participant}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};
