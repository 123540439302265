"use strict";
exports.__esModule = true;
exports.DEFAULT_EQUIPMENT_SETTINGS_WETSUIT = void 0;
var DEFAULT_SIZES_CLUB = [
    'ENFANT',
    'XS',
    'XS5',
    'XSF5',
    'S',
    'SF',
    'S5',
    'SF5',
    'M',
    'ML',
    'MF',
    'MF5',
    'M5',
    'L',
    'L5',
    'LF5',
    'XL',
    'XL5',
    'XXL',
    'XXL5',
];
exports.DEFAULT_EQUIPMENT_SETTINGS_WETSUIT = {
    type: 'wetsuit',
    enabled: true,
    club: {
        enabled: true,
        models: DEFAULT_SIZES_CLUB.map(function (ref) { return ({
            ref: ref
        }); }),
        visibleOnSession: true
    },
    self: {
        autoSetOnGlobal: true,
        enabled: false,
        models: [],
        visibleOnSession: true
    }
};
