"use strict";
// ISO 3166-1 codes - https://github.com/umpirsky/country-list
exports.__esModule = true;
exports.CUSTOMER_ORIGIN_SD_VALUES = void 0;
exports.CUSTOMER_ORIGIN_SD_VALUES = [
    'Adrenaline Hunter',
    'Air France',
    'Albioma',
    'Alitalia',
    'Alizée',
    'Aqua2A',
    'Association diabète',
    'Atlantis',
    'Beluga',
    'Bon cadeau',
    'Bouche à oreilles',
    'Cap Bleu',
    'Carte routière',
    'CASAS',
    'CEF Port Louis',
    'Chez Flo',
    'Citron bleu',
    'Clean My Island',
    'Clients',
    'Clubs 971',
    'Clubs',
    'CNBT',
    'Cœur des iles',
    'CRAB',
    'CRESSMA',
    'CSL',
    'CTIG',
    'Destination Guadeloupe',
    'dette auprès de clubs 971',
    'EDM Aquarium',
    'Flyer',
    'Gendarme',
    'Gwadakiff',
    'Hasard',
    'Hébergeur',
    'Internet',
    'La Bulle',
    'Le petit futé',
    'Lycée Collège',
    'Magazine',
    'Manawa',
    'Noa',
    'Office du tourisme',
    'Parc National Guadeloupe',
    'Petit Futé',
    'PPK',
    'Racoon',
    'Salon de la plongée',
    'Salon de la plongée 2023',
    'Salon de la plongée 2024',
    'Sophie Renaud',
    'SSI',
    'Subaqua',
    'Tetratrip',
    'Tribloo',
    'Tripadvisor',
    'Turtle Prod',
    'Vakans o péyi',
    'VV EDF',
    "Spots d'évasion",
];
