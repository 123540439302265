import { AppLanguageCode, languageFlagConvertor } from '@mabadive/app-common-model';

export const appLanguageFormater = {
  formatLanguageLabel,
};

const languageMap: { [key in AppLanguageCode]?: string } = {
  af: 'Afrikaans',
  ak: 'Akan',
  sq: 'Albanais',
  am: 'Amharique',
  ar: 'Arabe',
  hy: 'Arménien',
  as: 'Assamais',
  ay: 'Aymara',
  az: 'Azéri',
  bm: 'Bambara',
  eu: 'Basque',
  be: 'Biélorusse',
  bn: 'Bengali',
  bho: 'Bhojpuri',
  bs: 'Bosnien',
  bg: 'Bulgare',
  ca: 'Catalan',
  ceb: 'Cebuano',
  ny: 'Chichewa',
  zh: 'Chinois Simplifié',
  'zh-TW': 'Chinois Traditionnel',
  co: 'Corse',
  hr: 'Croate',
  cs: 'Tchèque',
  da: 'Danois',
  dv: 'Divehi',
  doi: 'Dogri',
  nl: 'Néerlandais',
  en: 'Anglais',
  eo: 'Espéranto',
  et: 'Estonien',
  ee: 'Ewe',
  tl: 'Filipino',
  fi: 'Finnois',
  fr: 'Français',
  fy: 'Frison',
  gl: 'Galicien',
  lg: 'Ganda',
  ka: 'Géorgien',
  de: 'Allemand',
  gom: 'Konkani',
  el: 'Grec',
  gn: 'Guarani',
  gu: 'Gujarati',
  ht: 'Haïtien',
  ha: 'Hausa',
  haw: 'Hawaïen',
  iw: 'Hébreu',
  hi: 'Hindi',
  hmn: 'Hmong',
  hu: 'Hongrois',
  is: 'Islandais',
  ig: 'Igbo',
  ilo: 'Ilocano',
  id: 'Indonésien',
  ga: 'Irlandais',
  it: 'Italien',
  ja: 'Japonais',
  jw: 'Javanais',
  kn: 'Kannada',
  kk: 'Kazakh',
  km: 'Khmer',
  rw: 'Kinyarwanda',
  ko: 'Coréen',
  kri: 'Krio',
  ku: 'Kurde',
  ckb: 'Sorani',
  ky: 'Kirghize',
  lo: 'Lao',
  la: 'Latin',
  lv: 'Letton',
  ln: 'Lingala',
  lt: 'Lituanien',
  lb: 'Luxembourgeois',
  mk: 'Macédonien',
  mai: 'Maithili',
  mg: 'Malgache',
  ms: 'Malais',
  ml: 'Malayalam',
  mt: 'Maltais',
  'mni-Mtei': 'Manipuri',
  mi: 'Maori',
  mr: 'Marathi',
  lus: 'Mizo',
  mn: 'Mongol',
  my: 'Birman',
  ne: 'Népalais',
  nso: 'Sepedi',
  no: 'Norvégien',
  or: 'Odia',
  om: 'Oromo',
  ps: 'Pachto',
  fa: 'Perse',
  pl: 'Polonais',
  pt: 'Portugais',
  pa: 'Pendjabi',
  qu: 'Quechua',
  ro: 'Roumain',
  ru: 'Russe',
  sm: 'Samoan',
  sa: 'Sanskrit',
  gd: 'Gaélique écossais',
  sr: 'Serbe',
  st: 'Sesotho',
  sn: 'Shona',
  sd: 'Sindhi',
  si: 'Cinghalais',
  sk: 'Slovaque',
  sl: 'Slovène',
  so: 'Somali',
  es: 'Espagnol',
  su: 'Sundanais',
  sw: 'Swahili',
  sv: 'Suédois',
  tg: 'Tadjik',
  ta: 'Tamoul',
  tt: 'Tatar',
  te: 'Telugu',
  th: 'Thaï',
  ti: 'Tigrinya',
  ts: 'Tsonga',
  tr: 'Turc',
  tk: 'Turkmène',
  uk: 'Ukrainien',
  ur: 'Ourdou',
  ug: 'Ouïghour',
  uz: 'Ouzbek',
  vi: 'Vietnamien',
  cy: 'Gallois',
  xh: 'Xhosa',
  yi: 'Yiddish',
  yo: 'Yoruba',
  zu: 'Zoulou',
  he: 'Hébreu',
  jv: 'Javanais',
  'zh-CN': 'Chinois Simplifié',
};

export function formatLanguageLabel(lang: AppLanguageCode, {
  prependFlag
}:{
  prependFlag: boolean
}): string {
  const label = languageMap[lang] || 'Inconnu';
  if (prependFlag){
    const flag = languageFlagConvertor.getFlag(lang);
    return `${flag} ${label}`;
  }else{
    return label;
  }
}
