import { ClubDiveSite, ClubDiveSiteType } from '@mabadive/app-common-model';

export const diveSiteFormatter = {
  formatDiveSite,
  formatType,
  formatWreck,
  formatWall,
  formatManMade,
};

function formatDiveSite(
  diveSite: Pick<ClubDiveSite, 'ref' | 'name' | 'distance'>,
  { showDistance }: { showDistance: boolean } = { showDistance: false },
): string {
  if (!diveSite) {
    return undefined;
  }
  const label = diveSite.ref
    ? `${diveSite.ref} - ${diveSite.name}`
    : diveSite.name;
  if (showDistance && diveSite.distance > 0) {
    return `${label} (${diveSite.distance} nmi)`;
  }
  return label;
}

function formatType(type: ClubDiveSiteType): string {
  if (type) {
    switch (type) {
      case 'sea':
        return 'Mer';
      case 'ocean':
        return 'Océan';
      case 'lagoon':
        return 'Lagon';
      case 'lake':
        return 'Lac';
      case 'pond':
        return 'Étang';
      case 'basin':
        return 'Bassin';
      case 'bay':
        return 'Baie';
      case 'river':
        return 'Rivière';
      case 'pass':
        return 'Passe';
      case 'cave':
        return 'Grotte';
      case 'quarry':
        return 'Carrière';
      case 'pool':
        return 'Piscine';
      case 'pit':
        return 'Fosse';
    }
  }
}

function formatWreck(wreck: any) {
  return wreck ? 'épave' : null;
}

function formatWall(wall: any) {
  return wall ? 'tombant' : null;
}

function formatManMade(manMade: any) {
  return manMade ? 'site artificiel' : null;
}
