import { ClubPurchasePackagePaymentStatus } from '@mabadive/app-common-model';

export const clubPurchasePackagePaymentStatusFormatter = {
  formatStatus,
};

function formatStatus(
  status: ClubPurchasePackagePaymentStatus,
  purchasePaymentPending: boolean,
): string {
  if (purchasePaymentPending) {
    return 'En attente';
  }
  switch (status) {
    case 'todo':
      return 'Non-payé';
    case 'partial':
      return 'Partiel';

    case 'done':
      return 'Payé';
  }
  // default
  return status;
}
