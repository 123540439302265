import { BookingGroup } from '@mabadive/app-common-model';
import React from 'react';

export const BookingGroupLabel = ({
  bookingGroup,
}: {
  bookingGroup: BookingGroup;
}) => {
  return bookingGroup ? (
    <>
      {' '}
      {bookingGroup?.type === 'club' && (
        <span
          className={
            'text-xs mr-2 sm:mr-4 md:mr-5 px-1 font-bold bg-app-secondary text-white'
          }
        >
          CLUB {bookingGroup?.name?.toUpperCase()}
        </span>
      )}
      {bookingGroup?.type === 'company' && (
        <span
          className={
            'text-xs mr-2 sm:mr-4 md:mr-5 px-1 font-bold bg-app-secondary text-white'
          }
        >
          ENTREPRISE {bookingGroup?.name?.toUpperCase()}
        </span>
      )}
      {bookingGroup?.type === 'individual' &&
        bookingGroup?.name?.trim()?.length && (
          <span
            className={
              'text-xs mr-2 sm:mr-4 md:mr-5 px-1 font-bold bg-app-primary text-white'
            }
          >
            {bookingGroup?.name?.toUpperCase()}
          </span>
        )}
    </>
  ) : null;
};
