import { StackLogLevel, StackLogLogger, StackLogSimpleProcess } from '@mabadive/stack-log-core';
import { StackLogWebContext } from '../models/stack-log-web-context.model';
import { stackLogWebLoggerDispatcher } from './stack-log-web-logger-dispatcher.service';


export class StackLogWebLogger extends StackLogLogger {

  private context: StackLogWebContext;

  constructor(
    context: StackLogWebContext,
  ) {
    super();
    if (!context.type) {
      // defaut logger context type
      context.type = 'lib';
    }
    if (!context.process) {
      const pid = 1;
      const process = new StackLogSimpleProcess(`pid-${pid}`);
      context.process = process;
    }

    this.context = context;
  }

  log(level: StackLogLevel,
    message: string,
    params: any[] = [],
  ) {
    stackLogWebLoggerDispatcher.dispatchLog({
      level,
      message,
      params,
      context: this.context,
    });
  }

  public child(context: StackLogWebContext): StackLogWebLogger {

    return new StackLogWebLogger({
      ...this.context,
      ...context,
    });
  }

}