import {
  ClubSettingsOnlineBookingProductsPageMode,
  ClubSettingsOnlineBookingProductsPageViewState,
} from '../_model';

export type ClubSettingsOnlineBookingProductsPageUrlParameters = Pick<
  ClubSettingsOnlineBookingProductsPageViewState,
  'mode' | 'categoryId' | 'productId' | 'timestamp' | 'articleId' | 'context'
>;

export const diveCenterEcommerceHomePageLocalStateUrlManager = {
  getQueryParameters,
  buildSearchParams,
};

export const QUERY_PARAMS = {
  mode: 'm',
  categoryId: 'c',
  productId: 'p',
  articleId: 'a',
  timestamp: 'ts',
  context: 'context',
};

function getQueryParameters(): ClubSettingsOnlineBookingProductsPageUrlParameters {
  const params = new URLSearchParams(window.location.search);

  const mode = params.get(
    QUERY_PARAMS.mode,
  ) as ClubSettingsOnlineBookingProductsPageMode;

  const categoryId = params.get(QUERY_PARAMS.categoryId);
  const productId = params.get(QUERY_PARAMS.productId);
  const articleId = params.get(QUERY_PARAMS.articleId);
  const timestamp = params.get(QUERY_PARAMS.timestamp);
  const context = params.get(QUERY_PARAMS.context) as 'iframe';

  const config: ClubSettingsOnlineBookingProductsPageUrlParameters = {
    mode,
    categoryId,
    productId,
    articleId,
    timestamp: timestamp ? parseInt(timestamp, 10) : undefined,
    context,
  };
  return config;
}

function buildSearchParams(
  viewConfig: Partial<ClubSettingsOnlineBookingProductsPageUrlParameters>,
): string[] {
  if (!viewConfig) {
    return [];
  }
  const { mode, categoryId, productId, articleId, timestamp, context } =
    viewConfig;

  const searchParams: string[] = [];

  if (mode) {
    searchParams.push(`${QUERY_PARAMS.mode}=${mode}`);
  }
  if (categoryId) {
    searchParams.push(`${QUERY_PARAMS.categoryId}=${categoryId}`);
  }
  if (productId) {
    searchParams.push(`${QUERY_PARAMS.productId}=${productId}`);
  }
  if (articleId) {
    searchParams.push(`${QUERY_PARAMS.articleId}=${articleId}`);
  }
  if (timestamp) {
    searchParams.push(`${QUERY_PARAMS.timestamp}=${timestamp}`);
  }
  if (context) {
    searchParams.push(`${QUERY_PARAMS.context}=${context}`);
  }

  return searchParams;
}
