import React, { useMemo } from 'react';

export function AppMultilineText({
  text,
  className,
  lineClassName,
}: {
  text: string;
  className?: string;
  lineClassName?: string;
}) {
  const chunks = useMemo(() => text.split('\n'), [text]);
  return text?.trim()?.length > 0 ? (
    <div className={className}>
      {chunks.map((line, i) => (
        <p key={i} className={lineClassName}>
          {line}
        </p>
      ))}
    </div>
  ) : null;
}
