import { combineLatest } from 'rxjs';
import { distinctUntilChanged, mapTo, switchMap, tap } from 'rxjs/operators';
import {
  authenticationClient,
  authenticationStore,
} from 'src/business/auth/services';
import { useLoadable } from 'src/business/_core/data/hooks';
import { useAppTrackingClient } from '../../../layout';
import { appBootstrap, dataBootstrap } from '../../bootstrap';

export function useAppRoot() {
  const trackingClient = useAppTrackingClient();

  return useLoadable({
    debugName: 'useAppRoot',
    // NOTE: ne pas utiliser initialValueFromLoadBootstrap, or it will subscribe twice
    load: () => {
      const bootstrap$ = appBootstrap
        .init()
        .pipe(switchMap(() => dataBootstrap.init({ trackingClient })));

      return combineLatest(
        [manageLogoutRequired(), bootstrap$],
        (loadingStats) => ({ loadingStats }),
      );
    },
  });
}

function manageLogoutRequired() {
  return authenticationStore.logoutRequired.get().pipe(
    tap((authenticationRequired) => {
      if (authenticationRequired) {
        authenticationStore.logoutRequired.set(false);
        authenticationClient.logout().subscribe();
      }
    }),
    mapTo(true),
    distinctUntilChanged(),
  );
}
