import { StackLogConfig, stackLogConfigurer, StackLogInnerSeverityConfig, StackLogLevel, stackLogLevelTester, stackLogMessageAnonymizer, stackLogMessageFormatter } from '@mabadive/stack-log-core';
import * as sentry from '@sentry/browser';
import * as dateFns from 'date-fns';
import { StackLogWebAppender, StackLogWebAppenderOptions, StackLogWebProcessHistory } from '../../main';
import { stackLogWebSentryFormatter } from './stack-log-web-sentry-formatter.service';
import { StackLogWebSentryAppenderConfig } from './stack-log-web-sentry.appender-config.model';


const SENTRY_DEBUG = false;

// https://docs.sentry.io/error-reporting/quickstart/?platform=web
export class StackLogWebSentryAppender implements StackLogWebAppender {

  private historyDay: Date[] = [];
  private historyHour: Date[] = [];
  private historyMinute: Date[] = [];
  private quotaExceed: boolean = false;

  private config: Partial<StackLogWebSentryAppenderConfig> & { severity: StackLogInnerSeverityConfig } = {
    severity: stackLogConfigurer.getDefaultInnerSeverity(),
    quota: {
      perMinute: -1,
      perHour: -1,
      perDay: -1,
    },
    maxBreadcrumbs: 100,
    dns: undefined,
    enabled: true,
  };

  constructor(config?: StackLogWebSentryAppenderConfig) {
    if (config) {
      this.configure(config);
    }
  }

  configure(config: StackLogWebSentryAppenderConfig) {

    this.config = {
      severity: stackLogConfigurer.buildInnerSeverityConfig(config),
      quota: config.quota !== undefined ? config.quota : this.config.quota,
      maxBreadcrumbs: config.maxBreadcrumbs !== undefined ? config.maxBreadcrumbs : this.config.maxBreadcrumbs,
      dns: config.dns !== undefined ? config.dns : this.config.dns,
      enabled: config.enabled !== undefined ? config.enabled : this.config.enabled,
    };
  }

  activate(config: StackLogConfig) {

    const sentryOptions: sentry.BrowserOptions = {
      debug: SENTRY_DEBUG,
      integrations: integrations => {
        return integrations
          // .filter(integration => integration.name !== 'Breadcrumbs')
          .map(integration => {
            // https://docs.sentry.io/platforms/javascript/default-integrations/
            if (integration.name !== 'Breadcrumbs') {
              // https://docs.sentry.io/platforms/javascript/default-integrations/#breadcrumbs
              (integration as any)['options'] = {
                beacon: true,  // Log HTTP requests done with the Beacon API
                console: false, // Log calls to `console.log`, `console.debug`, etc
                dom: true,     // Log all click and keypress events
                fetch: true,   // Log HTTP requests done with the Fetch API
                history: true, // Log calls to `history.pushState` and friends
                sentry: true,  // Log whenever we send an event to the server
                xhr: true,     // Log HTTP requests done with the XHR API
              };
            }
            return integration;
          });
      },
      enabled: this.config.enabled,
      dsn: this.config.dns,
      release: config.context ? config.context.release : undefined,
      environment: config.context ? config.context.envId : undefined,
      maxBreadcrumbs: this.config.maxBreadcrumbs,
    };
    sentry.init(sentryOptions);
  }

  log(options: StackLogWebAppenderOptions, history: StackLogWebProcessHistory) {

    if (this.config.enabled && stackLogLevelTester.isEnabled(options, this.config.severity)) {

      if (!this.isQuotaOk()) {
        return;
      }

      // TODO Set user information, as well as tags and further extras
      // sentry.configureScope(scope => {
      //   scope.setExtra('battery', 0.7);
      //   scope.setTag('user_mode', 'admin');
      //   scope.setUser({ id: '4711' });
      //   // scope.clear();
      // });


      // print current log
      this.sendToSentry(options, history);
    }
  }

  private isQuotaOk(): boolean {
    const now = new Date();

    const isQuotaPerMinuteOk = this.isQuotaPerMinuteOk(now);
    const isQuotaPerHourOk = isQuotaPerMinuteOk && this.isQuotaPerHourOk(now);
    const isQuotaOk = isQuotaPerHourOk && this.isQuotaPerDayOk(now);

    if (isQuotaOk) {
      this.historyMinute.push(now);
      this.historyDay.push(now);
      this.historyDay.push(now);
      this.quotaExceed = false;
    } else {
      if (!this.quotaExceed) {
        if (!isQuotaPerMinuteOk) {
          console.error('[StackLogWebSentryAppender] Sentry quota per minute (%d) exceed!', this.config.quota.perMinute);
        } else if (!isQuotaPerHourOk) {
          console.error('[StackLogWebSentryAppender] Sentry quota per hour (%d) exceed!', this.config.quota.perHour);
        } else {
          console.error('[StackLogWebSentryAppender] Sentry quota per day (%d) exceed!', this.config.quota.perDay);
        }
      }
      this.quotaExceed = true;
    }
    return isQuotaOk;
  }

  private isQuotaPerMinuteOk(now: Date): boolean {
    if (this.config.quota.perMinute > 0) {
      const oneMinuteAgo = dateFns.addMinutes(now, -1);
      this.historyMinute = this.historyMinute.filter(date => {
        return dateFns.isAfter(date, oneMinuteAgo);
      });
      if (this.historyMinute.length >= this.config.quota.perMinute) {
        return false;
      }
    }
    return true;
  }

  private isQuotaPerHourOk(now: Date): boolean {
    if (this.config.quota.perHour > 0) {
      const oneHourAgo = dateFns.addHours(now, -1);
      this.historyHour = this.historyHour.filter(date => {
        return dateFns.isAfter(date, oneHourAgo);
      });
      if (this.historyHour.length >= this.config.quota.perHour) {
        return false;
      }
    }
    return true;
  }

  private isQuotaPerDayOk(now: Date): boolean {
    if (this.config.quota.perHour > 0) {
      const oneDayAgo = dateFns.addDays(now, -1);
      this.historyDay = this.historyDay.filter(date => {
        return dateFns.isAfter(date, oneDayAgo);
      });
      if (this.historyDay.length >= this.config.quota.perDay) {
        return false;
      }
    }
    return true;
  }

  private sendToSentry(options: StackLogWebAppenderOptions, history: StackLogWebProcessHistory) {

    const message = stackLogWebSentryFormatter.formatLogMessage({
      level: options.level,
      message: options.message,
      options,
    });

    const anonymizedParams = options.params ? stackLogMessageAnonymizer.anonymize(options.params) : options.params;

    const breadcrumbs: sentry.Breadcrumb[] = this.buildBreadcrumbs(options, history);
    const event: sentry.Event = {
      // event_id?: string;
      message,
      timestamp: this.getSentryTimestamp(options.timestamp),
      level: this.getSentryLevel(options.level),
      breadcrumbs,
      // platform?: string;
      // server_name?: string;
      extra: {
        params: anonymizedParams
      },
      // user?: User;
    };
    sentry.captureEvent(event);
  }

  private buildBreadcrumbs(options: StackLogWebAppenderOptions, history: StackLogWebProcessHistory) {
    let breadcrumbs: sentry.Breadcrumb[];
    if (options.level === 'error') {
      breadcrumbs = history.logs.slice(0, this.config.maxBreadcrumbs).map(log => this.buildBreadcrumb(log)).reverse();
    }
    return breadcrumbs;
  }

  // https://docs.sentry.io/enriching-error-data/breadcrumbs/?platform=web
  private buildBreadcrumb(options: StackLogWebAppenderOptions): sentry.Breadcrumb {

    const anonymizedParams = options.params ? stackLogMessageAnonymizer.anonymize(options.params) : options.params;

    const formatted = stackLogMessageFormatter.formatMessage(options.message, anonymizedParams, {
      formatJSON: true,
    });

    const message = stackLogWebSentryFormatter.formatBreadcrumbMessage({
      level: options.level,
      message: formatted.message,
      options,
    });

    // convert extra parameters array to breadcrumb object

    let breadcrumbData;
    if (anonymizedParams) {
      if (formatted.extraParameters && formatted.extraParameters.length) {
        breadcrumbData = {
          params: stackLogMessageFormatter.stringify(formatted.extraParameters, {
            formatJSON: false,
          }),
        };
      }
    }

    const breadcrumb: sentry.Breadcrumb = {
      level: this.getSentryLevel(options.level),
      message,
      data: breadcrumbData,
      timestamp: this.getSentryTimestamp(options.timestamp),
    };

    return breadcrumb;
  }

  private getSentryTimestamp(date: Date): number {
    return Math.round((new Date()).getTime() / 1000);
  }

  private getSentryLevel(level: StackLogLevel): sentry.Severity {

    let sentryLevel: sentry.Severity;
    switch (level) {
      case 'debug': {
        sentryLevel = sentry.Severity.Debug;
        break;
      }
      case 'info': {
        sentryLevel = sentry.Severity.Info;
        break;
      }
      case 'warn': {
        sentryLevel = sentry.Severity.Warning;
        break;
      }
      case 'error': {
        sentryLevel = sentry.Severity.Error;
        break;
      }
      default: {
        sentryLevel = sentry.Severity.Info;
        break;
      }
    }
    return sentryLevel;
  }
}

