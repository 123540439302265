import { stackLogWeb, StackLogWebConsoleAppender, StackLogWebSentryAppender } from '@mabadive/stack-log-browser';
import { appWebConfig } from '../config';

export const appLoggerManager = {
  configure,
}

function configure({
  appVersion,
}: {
  appVersion: string
}) {

  const logConfig = appWebConfig.log;
  const isDevEnv = appWebConfig.envId === 'dev';

  stackLogWeb.configure({
    context: {
      release: appVersion,
      envId: appWebConfig.envId,
    },
    global: {
      defaultLogLevel: 'info',
      appRules: {
        app: 'debug',
      },
    },
    appenders: [
      new StackLogWebConsoleAppender({
        maxStackTrace: 15,
        maxStackAgeMs: 5000,
        severity: {
          defaultLogLevel: 'warn',
          appRules: logConfig.console.appRules,
        },
        dateFormat: isDevEnv ? 'DD/MM/YY HH:mm:ss' : 'dd/MM/yy HH:mm:ss',
      }),
      new StackLogWebSentryAppender({
        dns: logConfig.sentry.configString,
        enabled: logConfig.sentry.enabled,
        defaultLogLevel: 'error',
        quota: {
          perMinute: 1,
          perHour: 5,
          perDay: 10,
        },
      })
    ],
  });
}