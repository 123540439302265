/* eslint-disable @typescript-eslint/no-unused-vars */

import { Box, FormControl, FormHelperText, FormLabel } from '@material-ui/core';
import React, { CSSProperties, ReactNode, useMemo } from 'react';
import {
  Control,
  Path,
  UseControllerProps,
  UseControllerReturn,
  useController,
} from 'react-hook-form';

export type AppFormControlRHF_Deprecated_BaseProps<T> = {
  control: Control<T>;
  name: Path<T>;
  required?: boolean;
  disabled?: boolean;
  validation?: {
    email?: boolean;
    pattern?: RegExp;
  } & Pick<UseControllerProps<T>, 'rules'>;
  value?: any;
};

const VALIDATION_EMAIL_PATTERN_ALLOW_TRAILING_LEADING_SPACES =
  /^\s*\S+@\S+\.\S+\s*$/; // https://stackoverflow.com/a/201447 + https://stackoverflow.com/a/22025206

export function AppFormControlRHF_Deprecated<T>({
  label,
  control,
  name,
  renderComponent,
  renderError,
  className,
  required,
  disabled,
  validation,
  style,
}: AppFormControlRHF_Deprecated_BaseProps<T> & {
  label?: string;
  renderComponent: (
    props: AppFormControlRHF_Deprecated_BaseProps<T>,
  ) => ReactNode;
  renderError?: (attrs: {
    baseProps: AppFormControlRHF_Deprecated_BaseProps<T>;
    state: UseControllerReturn<T, any>;
    error: any;
  }) => ReactNode | null;
  className?: string;
  style?: CSSProperties;
}) {
  // const hasError = !!control._formState.errors[name];

  const pattern = useMemo(() => {
    if (validation?.email) {
      return VALIDATION_EMAIL_PATTERN_ALLOW_TRAILING_LEADING_SPACES;
    }
    if (validation?.pattern) {
      return validation?.pattern;
    }
    return undefined;
  }, [validation?.email, validation?.pattern]);

  const rules = useMemo(() => validation?.rules ?? {}, [validation?.rules]);

  const state = useController<T>({
    name: name as unknown as Path<T>,
    control,
    rules: {
      ...rules,
      required,
      pattern,
    },
  });
  const {
    field: { ref, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = state;

  const baseProps: AppFormControlRHF_Deprecated_BaseProps<T> = useMemo(
    () => ({
      control,
      name,
      required,
      disabled,
      value: inputProps.value,
    }),
    [control, name, required, disabled, inputProps.value],
  );

  const hasError = invalid || !!error;

  return (
    <div className={`flex flex-col ${className ?? ''}`} style={style}>
      <FormControl
        className="whitespace-nowrap"
        required={required}
        error={hasError}
      >
        <FormLabel className={'form-label pl-1'} component="legend">
          {label && (
            <span
              className={`text-xs uppercase truncate  ${
                hasError
                  ? 'text-app-status-error'
                  : disabled
                  ? 'text-gray-400 italic'
                  : ''
              }`}
            >
              {label}
            </span>
          )}
        </FormLabel>
        <Box className="form-input">{renderComponent(baseProps)}</Box>
        <FormHelperText error={true}>
          {hasError ? renderErrorMessage(baseProps) : null}
        </FormHelperText>
      </FormControl>
    </div>
  );

  function renderErrorMessage(
    baseProps: AppFormControlRHF_Deprecated_BaseProps<T>,
  ): React.ReactNode {
    const {
      field: { ref, ...inputProps },
      fieldState: { invalid, isTouched, isDirty, error },
      formState: { touchedFields, dirtyFields },
    } = state;
    const msg = renderError ? renderError({ error, state, baseProps }) : null;
    if (msg) {
      return msg;
    }
    return renderGenericMessages({ state });
  }
}

function renderGenericMessages<T>({
  state,
}: {
  state: UseControllerReturn<any, Path<T>>;
}): React.ReactNode {
  const {
    field: { ref, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = state;

  switch (error?.type) {
    case 'required':
      return 'Champ obligatoire';
    case 'email':
      return 'Adresse e-mail invalide';
    case 'pattern':
      return 'Format invalide';
    // case 'minLength':
    //   if (
    //     attrState.validation.errorContext &&
    //     (attrState.validation.errorContext as any).minLength
    //   ) {
    //     return `Longueur minimale: ${
    //       (attrState.validation.errorContext as any).minLength
    //     } caractères`;
    //   }
    //   return 'Longeur trop courte';
    default: {
      return 'Champ invalide';
    }
  }
}
