import {
  AppCompanyMessageScheduleTarget,
  AppCompanyMessageScheduleTargetMessage,
  AppCompanyMessageScheduleTargetMessageRecipient,
  AppCompanyMessageTarget,
  DiveCenterPublicData,
} from '@mabadive/app-common-model';
import { nameFormatter } from '../../business/string';
import { dateService } from '../../data';
import { diverLinkParametersToReplaceRenderer } from './diverLinkParametersToReplaceRenderer.service';

export type AppRenderMessageParts = {
  paramsToReplace: {
    [key: string]: string;
  };
  linksButtons: {
    label: string;
    url: string;
  }[];
  target: AppCompanyMessageTarget;
  title: string;
  body: string;
  signature: string;
};

export const messageParamsArgsBuilder = {
  renderAppMessageParts,
};
export function renderAppMessageParts(
  {
    messageScheduleTarget,
    localizedMessage,
    recipient,
  }: {
    messageScheduleTarget: AppCompanyMessageScheduleTarget;
    localizedMessage: AppCompanyMessageScheduleTargetMessage;
    recipient: AppCompanyMessageScheduleTargetMessageRecipient;
  },
  {
    publicData,
  }: {
    publicData: DiveCenterPublicData;
  },
): AppRenderMessageParts {
  const paramsToReplace: {
    [key: string]: string;
  } = buildAppMessageParamsFromMeta(recipient, {
    publicData,
  });

  const linksButtons = buildLinksButtons(paramsToReplace, [
    messageScheduleTarget?.originalMessage?.title,
    messageScheduleTarget?.originalMessage?.body,
    messageScheduleTarget?.originalMessage?.signature,
  ]);

  const target = messageScheduleTarget?.target;

  const title = diverLinkParametersToReplaceRenderer.renderTemplate({
    template: localizedMessage?.title,
    paramsToReplace,
  });
  const body = diverLinkParametersToReplaceRenderer.renderTemplate({
    template: localizedMessage?.body,
    paramsToReplace,
  });

  const signature = diverLinkParametersToReplaceRenderer.renderTemplate({
    template: messageScheduleTarget?.originalMessage?.signature,
    paramsToReplace,
  });

  const messageParts: AppRenderMessageParts = {
    paramsToReplace,
    linksButtons,
    target,
    title,
    body,
    signature,
  };
  return messageParts;
}
export function buildAppMessageParamsFromMeta(
  recipient: AppCompanyMessageScheduleTargetMessageRecipient,
  {
    publicData,
  }: {
    publicData: DiveCenterPublicData;
  },
): {
  [key: string]: string;
} {
  const {
    context,
    sessionContext,
    bookingContext,
    recipientType,
    diverContext,
  } = recipient.meta;

  const CENTRE_NOM = publicData?.brand?.name;
  const CENTRE_LIEU = publicData?.brand?.locationResume;
  const CENTRE_TELEPHONE = publicData?.contact?.phoneNumber;

  const params: { [key: string]: string } = {
    CENTRE_NOM,
    CENTRE_LIEU,
    CENTRE_TELEPHONE,
  };

  if (context === 'booking' && bookingContext) {
    const bookingJourney = bookingContext?.booking?.bookingJourney;
    const beginDateString = bookingJourney?.steps?.[0]?.arrival?.date;
    const beginDate = beginDateString ? new Date(beginDateString) : undefined;
    if (beginDate) {
      params.DATE_DEBUT_SEJOUR = dateService.formatUTC(beginDate, 'DD/MM/YYYY');
    }
    const endDateString =
      bookingJourney?.steps?.[bookingJourney?.steps?.length - 1]?.departure
        ?.date;

    const endDate = endDateString ? new Date(endDateString) : undefined;
    if (endDate) {
      params.DATE_FIN_SEJOUR = dateService.formatUTC(endDate, 'DD/MM/YYYY');
    }
  }

  if (context === 'session') {
    const { diveSession } = sessionContext;

    if (diveSession?.time) {
      const sessionDateTime = new Date(diveSession?.time);
      params.SESSION_HEURE = dateService.formatUTC(sessionDateTime, 'HH:mm');
      params.SESSION_DATE = dateService.formatUTC(
        sessionDateTime,
        'DD/MM/YYYY',
      );
    }
    // TODO
    // if (diver) {
    //   params.PARTICIPANT_NOM = nameFormatter.formatLastName(diver);
    //   params.PARTICIPANT_PRENOM = nameFormatter.formatFirstName(diver);
    // } else {
    //   // ignore parameters
    //   params.PARTICIPANT_NOM = '';
    //   params.PARTICIPANT_PRENOM = '';
    // }
  }

  if (diverContext) {
    const { diver, customerSpaceAuthenticationUrl } = diverContext;
    if (diver) {
      params.PARTICIPANT_NOM = nameFormatter.formatLastName(diver);
      params.PARTICIPANT_PRENOM = nameFormatter.formatFirstName(diver);
    } else {
      // ignore parameters
      params.PARTICIPANT_NOM = '';
      params.PARTICIPANT_PRENOM = '';
    }
    params.ESPACE_CLIENT_LIEN_RESA = customerSpaceAuthenticationUrl;
  }
  return params;
}

export function buildLinksButtons(
  paramsToReplace: { [key: string]: string },
  templates: string[],
) {
  const linksButtons = Object.keys(paramsToReplace)
    .filter(
      (param) =>
        param === 'ESPACE_CLIENT_LIEN_RESA' &&
        paramsToReplace[param]?.trim()?.length > 0 &&
        templates.find((template) => template?.includes(param)) !== undefined,
    )
    .map((key) => {
      return {
        label: 'Espace client',
        url: paramsToReplace[key],
      };
    });
  return linksButtons;
}
