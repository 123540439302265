import { ClubProductPackageAttributesDiveType } from '@mabadive/app-common-model';

export const diveTypeFormatter = {
  formatDiveType,
};

function formatDiveType(
  diveType: ClubProductPackageAttributesDiveType,
  {
    format,
  }: {
    format: 'normal' | 'full';
  },
): string {
  if (diveType === 'scuba') {
    return format === 'normal' ? 'Scaphandre' : 'Plongée scaphandre';
  } if (diveType === 'free') {
    return 'Apnée';
  } if (diveType === 'observer') {
    return 'Accompagnateur';
  }
  return '';
}
