import React from 'react';
import {
  AppButtonFreeColor,
  AppIconsAction,
  AppLoadableContentContainer,
  AppLoader,
  AppPageContainer,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import { useRedirect } from '../../_core/data/hooks';
import { useAppRouter } from '../../_core/data/hooks/useAppRouter';
import { confirmDialog } from '../../_core/modules/layout/components/ConfirmDialog/confirmDialog.service';
import { diveCenterBookingUrlBuilder } from '../BO-booking/diveCenterBookingUrlBuilder.service';
import { DiveCenterBrandCard } from '../components';
import DiveCenterShoppingCart from './DiveCenterShoppingCart/components/DiveCenterShoppingCart';
import DiveCenterShoppingCartPaymentResume from './DiveCenterShoppingCart/components/DiveCenterShoppingCartPaymentResume';
import { DiveCenterShoppingCartRemainingTime } from './DiveCenterShoppingCart/components/DiveCenterShoppingCartRemainingTime';
import {
  DiveCenterShoppingCartPageLocalState,
  useDiveCenterShoppingCartPageLocalState,
} from './useDiveCenterShoppingCartPageLocalState.hook';

export const DiveCenterShoppingCartPage = ({
  navigationContext,
}: {
  navigationContext: {
    clubPublicReference: string;
  };
}) => {
  const clubPublicReference = navigationContext.clubPublicReference;
  const redirectTo = useRedirect();

  const localState: DiveCenterShoppingCartPageLocalState =
    useDiveCenterShoppingCartPageLocalState({
      clubPublicReference,
      context: 'shopping-cart',
    });
  const { state, data, actions } = localState;
  const appRouter = useAppRouter();
  const clubSettings = data.clubSettings;
  const clubPublicSettings = data.clubPublicSettings;
  const diveCenter = data.diveCenter;

  const appUiTheme = clubPublicSettings?.appUiTheme;
  const enableCatalog =
    clubPublicSettings?.customerSpace?.onlineBooking?.enableCatalog;

  return state.loadableContent.contentState !== 'full' ? (
    <AppLoader type="loading" />
  ) : (
    <AppPageContainer
      className="bg-gray-50 overflow-y-auto"
      {...state.loadableContent}
    >
      <AppPageContentContainer
        paddingBottom={false}
        className="bg-gray-50 app-p-content"
      >
        <AppLoadableContentContainer {...state.loadableContent}>
          <div className="h-full w-full flex flex-col gap-16 justify-between">
            <div className="w-full max-w-3xl mx-auto  flex flex-col justify-between gap-4 md:gap-8">
              <div className="w-full max-w-6xl mx-auto flex flex-col gap-4">
                <h2
                  className="text-lg font-bold leading-4 uppercase"
                  style={{
                    color: data.appUiTheme.title1.textColor,
                  }}
                >
                  Mon panier
                </h2>
                {data.orderArticlesFull?.length === 0 ? (
                  <div className="app-card app-p-content text-gray-600">
                    Votre panier est vide
                  </div>
                ) : (
                  <>
                    <DiveCenterShoppingCart
                      orderArticlesFull={data.orderArticlesFull}
                      clubPublicSettings={clubPublicSettings}
                      clubSettings={clubSettings}
                      onClearShoppingCart={() => {
                        confirmDialog
                          .confirm({
                            isTailwind: true,
                            title: 'Vider mon panier',
                            message:
                              'Êtes-vous sûr de vouloir vider votre panier et annuler la commande?',
                            type: 'cancel',
                          })
                          .subscribe((confirmed: boolean) => {
                            if (confirmed) {
                              actions.cartActions.clearCart({
                                cause: 'Cart cleared by user',
                              });
                            }
                          });
                      }}
                    />
                    <DiveCenterShoppingCartPaymentResume
                      orderArticlesFull={data.orderArticlesFull}
                      clubSettings={clubSettings}
                      clubPublicReference={clubPublicReference}
                    >
                      <div className="mt-8">
                        <button
                          type="submit"
                          className="cursor-pointer w-full rounded-md border border-transparent bg-app-primary px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-app-primary-dark focus:outline-none focus:ring-2 focus:ring-app-primary focus:ring-offset-2 focus:ring-offset-gray-50"
                          onClick={() => {
                            appRouter.navigate(
                              diveCenterBookingUrlBuilder.buildDiveCenterShoppingBookingContactUrl(
                                {
                                  clubPublicReference,
                                },
                              ),
                              {
                                cause: 'continue-from-shopping-cart',
                              },
                            );
                          }}
                        >
                          Continuer
                        </button>
                      </div>
                    </DiveCenterShoppingCartPaymentResume>
                  </>
                )}
              </div>

              <div className="flex justify-between">
                {enableCatalog && (
                  <AppButtonFreeColor
                    className="text-sm whitespace-nowrap uppercase max-w-60"
                    icon={AppIconsAction.back}
                    onClick={(e) => {
                      e.stopPropagation();
                      redirectTo(
                        diveCenterBookingUrlBuilder.buildDiveCenterEcommerceHomeUrl(
                          {
                            clubPublicReference,
                          },
                        ),
                      );
                    }}
                    {...appUiTheme.buttonPrimary}
                  >
                    Retour au catalogue
                  </AppButtonFreeColor>
                )}
              </div>
            </div>
            {
              <div>
                {data.orderArticlesFull?.length > 0 && (
                  <DiveCenterShoppingCartRemainingTime
                    clubSettings={clubSettings}
                  />
                )}
                <DiveCenterBrandCard
                  className="w-full app-card app-p-content hidden sm:block"
                  clubPublicData={clubSettings?.publicData}
                  clubPublicSettings={clubPublicSettings}
                  diveCenterPublicData={diveCenter?.publicData}
                  clubPublicReference={clubPublicReference}
                  showHeader={true}
                  showContactLinks={true}
                />
                <DiveCenterBrandCard
                  className="w-full  app-card app-p-content sm:hidden"
                  clubPublicData={clubSettings?.publicData}
                  clubPublicSettings={clubPublicSettings}
                  diveCenterPublicData={diveCenter?.publicData}
                  clubPublicReference={clubPublicReference}
                  showHeader={true}
                  showContactLinks={false} // sur mobile, on masque les numéros, ils sont visibles en bas de page
                  showLegalInformation={false} // sur mobile, on masque les infos légales, ils sont visibles en bas de page
                />
              </div>
            }
          </div>
        </AppLoadableContentContainer>
      </AppPageContentContainer>
    </AppPageContainer>
  );
};
