import { Theme } from '@material-ui/core';

function attr(name: string, value: any) {
  const attribute: any = {};
  attribute[name] = value;
  return attribute;
}
export interface ResponsiveAttributeRangeProps {
  name: string,
  min: number,
  max: number,
  suffix: string,
}

export const responsiveAttributeRange = (theme: Theme, {
  name,
  min,
  max,
  suffix,
}: ResponsiveAttributeRangeProps) => {
  const step = (max - min) / 4;
  return {
    // xs
    ...attr(name, `${min}${suffix}`),
    [theme.breakpoints.only('sm')]: {
      ...attr(name, `${min + step}${suffix}`),
    },
    [theme.breakpoints.only('md')]: {
      ...attr(name, `${min + 2 * step}${suffix}`),
    },
    [theme.breakpoints.only('lg')]: {
      ...attr(name, `${min + 3 * step}${suffix}`),
    },
    [theme.breakpoints.up('xl')]: {
      ...attr(name, `${min + 4 * step}${suffix}`),
    },
  };
}

export const responsiveAttributesRange = (theme: Theme, attributes: ResponsiveAttributeRangeProps[]) => {
  return {
    // xs
    ...buildAttributesRange(attributes, 0),
    [theme.breakpoints.only('sm')]: {
      ...buildAttributesRange(attributes, 1),
    },
    [theme.breakpoints.only('md')]: {
      ...buildAttributesRange(attributes, 2),
    },
    [theme.breakpoints.only('lg')]: {
      ...buildAttributesRange(attributes, 3),
    },
    [theme.breakpoints.up('xl')]: {
      ...buildAttributesRange(attributes, 4),
    },
  };
}


function buildAttributesRange(attributes: ResponsiveAttributeRangeProps[], multiplier: number) {
  return attributes.reduce((acc, attribute) => {
    const step = (attribute.max - attribute.min) / 4;
    const value = attribute.min + multiplier * step;
    return {
      ...acc,
      ...attr(attribute.name, `${value}${attribute.suffix}`),
    };
  }, {} as any);
}
