/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubProductPackageOfferView,
  PurchaseResumeOffer,
} from '@mabadive/app-common-model';
import { dataSorter } from '../../../data';
import { clubProductPackageMetaReader } from './clubProductPackageMetaReader.service';
import { clubProductPackageOfferMatcherBestCommon } from './clubProductPackageOfferMatcherBestCommon.service';
import { DiverPurchasePlanEditorDialogMatchingOfferCriteria } from './model';

export const clubProductPackageOfferMatcherBestPlan = {
  findBestMatchingOfferPlan,
};

function findBestMatchingOfferPlan({
  criteria,
  productPackageOffers,
}: {
  criteria?: DiverPurchasePlanEditorDialogMatchingOfferCriteria;
  productPackageOffers: ClubProductPackageOfferView[];
}): PurchaseResumeOffer {
  if (!criteria) {
    return undefined;
  }

  let matchingOffers: ClubProductPackageOfferView[] = excludeNonBestOffers({
    productPackageOffers,
    criteria,
    strictMode: true,
  });

  if (!matchingOffers.length) {
    matchingOffers = excludeNonBestOffers({
      productPackageOffers,
      criteria,
      strictMode: false,
    });
  }

  // sort by total count, then by min/max distance & depth
  const sortedMatchingOffers = dataSorter.sortMultiple(matchingOffers, {
    getSortAttributes: (o) => {
      const meta = clubProductPackageMetaReader.readMeta(o.productPackage);
      const totalCount = meta.productDefaultTotalMultipleCount ?? 0;
      const minDistance = meta.diveAttributes?.minDistance ?? 0;
      const minDepth = meta.diveAttributes?.minDepth ?? 0;
      const maxDistance = meta.diveAttributes?.maxDistance ?? 999;
      const maxDepth = meta.diveAttributes?.maxDepth ?? 999;
      const diveAttributes = o.productPackage.diveAttributes;
      const productAttributes = o.productPackage.productAttributes;
      const salesCriteria = o.productPackage.salesCriteria;
      return [
        {
          value: totalCount,
        },
        {
          value: minDistance,
        },
        {
          value: maxDistance,
        },
        {
          value: minDepth,
        },
        {
          value: maxDepth,
        },
        {
          value: productAttributes?.minAge > 0 ? 0 : 1, // on met en priorité les offres qui ont un filtre d'âge (elles ont été filtrées avant si elles ne matchen pas)
        },
        {
          value: productAttributes?.maxAge > 0 ? 0 : 1, // on met en priorité les offres qui ont un filtre d'âge (elles ont été filtrées avant si elles ne matchen pas)
        },
        {
          value: o.price,
          asc: false, // par défaut, on facture la prestation la plus chère
        },
        {
          value: o.reference,
          type: 'full-text',
        },
        {
          value: o._id,
          type: 'full-text',
        },
      ];
    },
    asc: true,
  });

  let bestMatchingOffer: ClubProductPackageOfferView;

  if (!bestMatchingOffer && criteria.specialDiveType) {
    // on recherche la première offre qui correspond au special type
    bestMatchingOffer =
      clubProductPackageOfferMatcherBestCommon.getFirstMatchingCountOffer({
        divesCount: criteria.divesCount,
        offers: sortedMatchingOffers.filter(
          (x) =>
            x.productPackage?.diveAttributes?.specialDiveType ===
            criteria.specialDiveType,
        ),
      });
  }

  if (!bestMatchingOffer && criteria.diveSessionTheme) {
    // on recherche la première offre qui correspond au theme
    bestMatchingOffer =
      clubProductPackageOfferMatcherBestCommon.getFirstMatchingCountOffer({
        divesCount: criteria.divesCount,
        offers: sortedMatchingOffers.filter(
          (x) =>
            x.productPackage?.salesCriteria?.diveSessionTheme ===
            criteria.diveSessionTheme,
        ),
      });
  }

  if (!bestMatchingOffer) {
    // sinon on prend la première qui correspond (= la plus chère)
    bestMatchingOffer =
      clubProductPackageOfferMatcherBestCommon.getFirstMatchingCountOffer({
        divesCount: criteria.divesCount,
        offers: sortedMatchingOffers,
      });
  }

  if (!bestMatchingOffer && matchingOffers.length) {
    // par défaut: on choisit la première offre
    bestMatchingOffer = matchingOffers[matchingOffers.length - 1];
  }
  if (!bestMatchingOffer && productPackageOffers.length) {
    // par défaut: on choisit la première offre
    bestMatchingOffer = productPackageOffers[productPackageOffers.length - 1];
  }
  return bestMatchingOffer;
}

function excludeNonBestOffers({
  productPackageOffers,
  criteria,
  strictMode,
}: {
  productPackageOffers: ClubProductPackageOfferView[];
  criteria: DiverPurchasePlanEditorDialogMatchingOfferCriteria;
  strictMode: boolean;
}): ClubProductPackageOfferView[] {
  return productPackageOffers.filter((o) => {
    const meta = clubProductPackageMetaReader.readMeta(o.productPackage);
    const diveAttributes = o.productPackage.diveAttributes;
    const productAttributes = o.productPackage.productAttributes;
    const salesCriteria = o.productPackage.salesCriteria;

    if (salesCriteria?.dontSelectByDefault) {
      return false;
    }

    if (!criteria.isInstructor && meta.diveMode === 'instructor') {
      return false;
    }

    if (diveAttributes?.specialDiveType && !criteria.specialDiveType) {
      return false; // les plongées spéciales sont ignorées si le plongeur n'y est pas explicitement associé
    }

    if (salesCriteria?.diveSessionTheme && !criteria.diveSessionTheme) {
      return false; // les presta de thème sont ignorées si le plongeur n'y est pas explicitement associé
    }
    if (
      productAttributes?.minAge > 0 &&
      criteria.age &&
      criteria.age < productAttributes?.minAge
    ) {
      // âge mini : si âge du participant inconnu, on ignore le critère
      return false;
    }
    if (
      productAttributes?.maxAge > 0 &&
      ((criteria.age && criteria.age > productAttributes?.maxAge) ||
        (!criteria.age && productAttributes?.maxAge >= 18))
    ) {
      // âge mini : si âge du participant inconnu,
      // on ignore le critère seulement si maxAge >=18 (les offres enfants sont exclues)
      return false;
    }

    if (
      meta.isPlan ||
      diveAttributes?.diveSingleAttributes?.minDivesCount > 0 ||
      diveAttributes?.diveSingleAttributes?.maxDivesCount > 0
    ) {
      if (
        strictMode &&
        (diveAttributes?.diveSingleAttributes?.minDivesCount > 0 ||
          diveAttributes?.diveSingleAttributes?.maxDivesCount > 0)
      ) {
        if (
          diveAttributes?.diveSingleAttributes?.minDivesCount > 0 &&
          diveAttributes?.diveSingleAttributes?.minDivesCount >
            criteria.divesCount
        ) {
          return false;
        }
        if (
          diveAttributes?.diveSingleAttributes?.maxDivesCount > 0 &&
          diveAttributes?.diveSingleAttributes?.maxDivesCount <
            criteria.divesCount
        ) {
          return false;
        }
      }

      if (criteria.divesDistanceMin > 0 || criteria.divesDistanceMax > 0) {
        if (
          diveAttributes?.maxDistance !== criteria.divesDistanceMax ||
          diveAttributes?.minDistance !== criteria.divesDistanceMin
        ) {
          // on exclue les prestations ne correspondant pas au distances de l'offre

          return false;
        }
      }

      if (criteria.divesDepthMin > 0 || criteria.divesDepthMax > 0) {
        if (
          diveAttributes?.maxDepth !== criteria.divesDepthMax ||
          diveAttributes?.minDepth !== criteria.divesDepthMin
        ) {
          // on exclue les prestations ne correspondant pas à la profondeur de l'offre
          // NOTE: ce n'est pas au point, car minDepth / maxDepth ne sont pas renseignés
          return false;
        }
      }

      if (criteria.successiveDivesCount > 1) {
        if (
          diveAttributes?.divePriceType !== 'successive' ||
          criteria.successiveDivesCount !== diveAttributes?.successiveDivesCount
        ) {
          return false;
        }
      } else {
        if (diveAttributes?.divePriceType === 'successive') {
          return false;
        }
      }
      const isSupervisionOk =
        !criteria.supervision ||
        diveAttributes?.supervision === 'any-supervision' ||
        diveAttributes?.supervision === criteria.supervision;

      const isEquipmentOk =
        !criteria.equipment ||
        diveAttributes?.equipment === 'any-equipment' ||
        diveAttributes?.equipment === criteria.equipment;

      if (isSupervisionOk && isEquipmentOk) {
        // TODO: mieux prendre en compte toutes les valeurs possibles :
        // - DiveServiceOfferEquipment: 'equipped' | 'not-equipped' | 'partially-equipped' | 'any-equipment'
        // - DiveServiceOfferSupervision: 'supervised' | 'autonomous' | 'any-supervision'
        return true;
      }
    }
    return false;
  });
}
