import { Theme } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { CreateCSSProperties } from '@material-ui/styles/withStyles';
import { responsiveAttributesValues } from '../../../layout';



export function buildGlobalPageBlock(theme: Theme): CreateCSSProperties<{}> {
  return {
    ...responsiveAttributesValues(theme, {
      marginTop: { values: [5, 10, 15, 20, 25], suffix: 'px' },
      marginBottom: { values: [5, 10, 15, 20, 25], suffix: 'px' },
    }),
  };
}

export function getPageBlockTopBottom(mediaSize: Breakpoint) {
  return getPageBlockMarginTop(mediaSize) + getPageBlockMarginBottom(mediaSize);
}

export function getPageBlockMarginBottom(mediaSize: Breakpoint) {
  switch (mediaSize) {
    case 'xs': return 5;
    case 'sm': return 10;
    case 'md': return 15;
    case 'lg': return 20;
    case 'xl': return 25;
  }
}

export function getPageBlockMarginTop(mediaSize: Breakpoint) {
  switch (mediaSize) {
    case 'xs': return 5;
    case 'sm': return 10;
    case 'md': return 15;
    case 'lg': return 20;
    case 'xl': return 25;
  }
}