const dateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)$/;
const dateRegexWithTimezone =
  /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)\+(\d{2}):(\d{2})$/;
const dateRegexUTC =
  /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/;

export const jsonParser = {
  parseJSONWithDates,
  cloneJSONWithDates,
};

function cloneJSONWithDates<T>(body: T): T {
  if (body === undefined || body === null) {
    return body;
  }
  return parseJSONWithDates(JSON.stringify(body));
}

function parseJSONWithDates<T>(body: any): T {
  if (body === undefined) {
    return body;
  }
  try {
    // console.log('[JsonDateParser] BODY:', body);
    const json =
      body !== ''
        ? JSON.parse(body, (key: any, value: any) => reviveUtcDate(key, value))
        : null;
    // console.log('[JsonDateParser] JSON:', json);
    return json;
  } catch (err) {
    console.warn('[jsonParser.parseJSONWithDates] JSON error parsing:', body);
    console.error('[jsonParser.parseJSONWithDates] JSON error', err);
    return body;
  }
}

function reviveUtcDate(key: any, value: any): any {
  if (typeof value !== 'string') {
    return value;
  }
  if (value === '0001-01-01T00:00:00') {
    return null;
  }
  if (dateRegex.exec(value)) {
    // LocalDateTime
    // console.log('[JsonDateParser] BASIC dateRegex:', new Date(value));

    return new Date(value);
  }
  if (dateRegexWithTimezone.exec(value)) {
    // console.log('[JsonDateParser] TIMEZONE dateRegex:', new Date(value));

    return new Date(value);
  }
  if (dateRegexUTC.exec(value)) {
    // OffsetDateTime
    // console.log('[JsonDateParser] UTC dateRegex:', new Date(value));
    return new Date(value);
  }
  return value;
}
