import {
  ClubResumeStaffMember,
  ClubStaffMember,
  DiveMode,
  DiveSession,
  DiveSessionGroup,
  DiveSessionGroupDiveMode,
  DiveSessionParticipantsByGroup,
  DiveSessionResumeGroup,
  DiveSessionResumeParticipant,
  DiveSessionResumeParticipantsByGroup,
  ParticipantExt,
} from '@mabadive/app-common-model';
import { diveSessionResumeParticipantSorter } from '../../business/participant/diveSessionResumeParticipantSorter.service';
import { diveSessionGroupSorter } from './diveSessionGroupSorter.service';

export const diveSessionGroupBuilder = {
  buildDiveSessionResumeFullParticipantsByGroup,
  buildDiveSessionParticipantsByGroupForBackend,
  buildDiveSessionParticipantsGroups,
  buildAssignedFreeInstructors,
};

function buildDiveSessionResumeFullParticipantsByGroup({
  diveSession,
  groups,
  participants,
  staffMembers,
  rebuildDiveMode = false,
  ignoreGroupsWithoutParticipants,
  diveModesToAutoGroup = [],
}: {
  diveSession: Pick<
    DiveSession,
    'diveTourSession1' | 'diveTourSession2' | 'staffConfig'
  >;
  groups: DiveSessionResumeGroup[];
  participants: DiveSessionResumeParticipant[];
  staffMembers: ClubResumeStaffMember[];
  rebuildDiveMode?: boolean;
  ignoreGroupsWithoutParticipants?: boolean;
  diveModesToAutoGroup?: DiveMode[];
}): DiveSessionResumeParticipantsByGroup {
  if (!groups || !participants || !staffMembers) {
    const empty: DiveSessionResumeParticipantsByGroup = {
      byGroup: [],
      byGroupExtended: [],
      groupped: [],
      ungroupped: [],
      ungrouppedByVirtualGroup: [],
      cancelled: [],
      all: [],
      allGroups: [],
      staffMembers: {
        all: [],
        divingDirectorsStaffs: [],
        divingInstructorsStaffs: [],
        surfaceSecurityStaffs: [],
      },
      assignedFreeInstructors: [],
    };
    return empty;
  }
  const participantsByGroups = participants.reduce(
    (acc, participant) => {
      const groupId = participant.diveSessionGroupId;

      if (groupId) {
        if (acc.groups[groupId]) {
          acc.groups[groupId].participants.push(participant);
        } else {
          const group = groups.find((g) => g._id === groupId);
          acc.groups[groupId] = {
            group,
            participants: [participant],
          };
        }
      } else {
        if (diveModesToAutoGroup.includes(participant.diveMode)) {
          const bookingId = participant?.booking?.bookingId;
          const groupByBookingId = participant.diveMode === 'autoSupervised';
          let group = acc.fakeGroups.find(
            (g) =>
              g.group.diveMode === participant.diveMode &&
              // si c'est un auto-supervisé, on regroupe par résa
              (!groupByBookingId || g.group.booking?.bookingId === bookingId),
          );
          if (group) {
            group.participants.push(participant);
          } else {
            const fakeGroup: DiveSessionResumeGroup = {
              isVirtualGroup: true,
              _id: `ungroupped-fake-${acc.fakeGroups.length}}`,
              diveMode: participant.diveMode,
              clubReference: undefined, // est-ce utilisé?
              booking: groupByBookingId ? participant.booking : undefined,
            };
            group = {
              group: fakeGroup,
              participants: [participant],
            };
            acc.fakeGroups.push(group);
          }
        } else {
          acc.ungroupped.push(participant);
        }
      }
      return acc;
    },
    {
      groups: {},
      fakeGroups: [],
      ungroupped: [],
    } as {
      groups: {
        [groupId: string]: {
          group: DiveSessionResumeGroup;
          participants: DiveSessionResumeParticipant[];
        };
      };
      fakeGroups: {
        group: DiveSessionResumeGroup;
        participants: DiveSessionResumeParticipant[];
      }[];
      ungroupped: DiveSessionResumeParticipant[];
    },
  );

  const emptyGroupsWithStaff = groups.filter(
    (g) =>
      participantsByGroups.groups[g._id] === undefined &&
      (g.diveTourGroupSession1?.instructor ||
        g.diveTourGroupSession2?.instructor),
  );
  // add empty groups with staff
  if (!ignoreGroupsWithoutParticipants) {
    emptyGroupsWithStaff.forEach((group) => {
      participantsByGroups.groups[group._id] = {
        group,
        participants: [],
      };
    });
  }
  // if (rebuildDiveMode) {
  //   Object.values(participantsByGroups.groups).forEach((g) => {
  //     // update dive mode
  //     let diveMode =
  //       diveSessionGroupDiveModeBuilder.buildFromParticipantsDiveModes({
  //         diveModes: g.participants.map((p) => p.diveMode),
  //         hasDiveGuide: !!g.group?.diveTourGroupSession1?.diveGuide,
  //         debugContext: 'diveSessionGroupBuilder (rebuild)',
  //       });
  //     if (
  //       diveMode === 'supervised' &&
  //       !!g.group.diveTourGroupSession1?.diveGuide?.participantId
  //     ) {
  //       diveMode = 'autonomous';
  //     }

  //     participantsByGroups.groups[g.group._id].group = {
  //       ...g.group,
  //       diveMode,
  //     };
  //   });
  // }

  const byGroup = Object.values(participantsByGroups.groups)
    .filter(
      (x) => !!x.group, // skip missing groups
    )
    .concat(participantsByGroups.fakeGroups);

  let ungroupped = participantsByGroups.ungroupped.filter(
    (x) => x.bookingState.value !== 'cancelled',
  );
  const cancelled = participantsByGroups.ungroupped.filter(
    (x) => x.bookingState.value === 'cancelled',
  );

  const ungrouppedByVirtualGroup: {
    group: DiveSessionResumeGroup;
    participants: DiveSessionResumeParticipant[];
  }[] = ungroupped.reduce(
    (acc, participant) => {
      const diveMode: DiveSessionGroupDiveMode = participant.diveMode;
      if (diveMode) {
        const group = acc.find((g) => g.group.diveMode === diveMode);
        if (group) {
          group.participants.push(participant);
        } else {
          acc.push({
            group: {
              _id: `ungroupped-${acc.length}}`,
              isVirtualGroup: true,
              clubReference: undefined, // inutile
              diveMode,
              diveTourGroupSession1: {
                diveGuide: null,
                instructor: null,
              },
              diveTourGroupSession2: {
                diveGuide: null,
                instructor: null,
              },
            },
            participants: [participant],
          });
        }
      }
      return acc;
    },
    [] as {
      group: DiveSessionResumeGroup;
      participants: DiveSessionResumeParticipant[];
    }[],
  );

  let byGroupExtended: {
    type: 'ungroupped' | 'groupped' | 'cancelled';
    group?: DiveSessionResumeGroup; // optionnal
    participants: DiveSessionResumeParticipant[];
  }[] = [];
  if (ungroupped.length) {
    byGroupExtended.push({
      type: 'ungroupped',
      participants: ungroupped,
    });
  }
  if (byGroup.length) {
    byGroupExtended = byGroupExtended.concat(
      byGroup.map((x) => ({
        ...x,
        type: 'groupped',
      })),
    );
  }
  if (cancelled.length) {
    byGroupExtended.push({
      type: 'cancelled',
      participants: cancelled,
    });
  }

  const divingInstructorsStaffs = staffMembers.filter(
    (s) =>
      s.profile.roles.includes('scuba-diving-instructor') ||
      s.profile.roles.includes('free-diving-instructor'),
  );
  const divingDirectorsStaffs = staffMembers.filter((s) =>
    s.profile.roles.includes('diving-director'),
  );
  const surfaceSecurityStaffs = staffMembers.filter((s) =>
    s.profile.roles.includes('surface-security'),
  );

  const assignedFreeInstructors: ClubResumeStaffMember[] =
    buildAssignedFreeInstructors({
      staffMembers,
      diveSession,
      groups,
    });

  const participantsByGroup: DiveSessionResumeParticipantsByGroup = {
    staffMembers: {
      all: staffMembers,
      divingDirectorsStaffs,
      divingInstructorsStaffs,
      surfaceSecurityStaffs,
    },
    all: participants,
    allGroups: groups,
    byGroup,
    byGroupExtended,
    groupped: byGroup.reduce(
      (acc, group) => acc.concat(group.participants),
      [],
    ),
    ungrouppedByVirtualGroup,
    ungroupped,
    cancelled,
    assignedFreeInstructors,
  };

  return participantsByGroup;
}

function buildAssignedFreeInstructors({
  staffMembers,
  diveSession,
  groups,
}: {
  staffMembers: ClubResumeStaffMember[];
  diveSession: Pick<
    DiveSession,
    'diveTourSession1' | 'diveTourSession2' | 'staffConfig'
  >;
  groups: Pick<
    DiveSessionGroup,
    'diveTourGroupSession1' | 'diveTourGroupSession2'
  >[];
}): ClubResumeStaffMember[] {
  const assignedSecurityStaffIds = [
    diveSession.diveTourSession1?.surfaceSecurityStaffId,
    diveSession.diveTourSession2?.surfaceSecurityStaffId,
  ].filter((x) => !!x);

  return staffMembers.filter((m) => {
    const staffMemberConfig = diveSession.staffConfig?.staffMembers?.find(
      (x) => x.staffMemberId === m._id,
    );
    // assigned as diving instructor
    return (
      staffMemberConfig?.assigned &&
      (staffMemberConfig?.assignedRoles?.includes('scuba-diving-instructor') ||
        staffMemberConfig?.assignedRoles?.includes('free-diving-instructor')) &&
      // not assigned as surface security
      !assignedSecurityStaffIds.includes(m._id) &&
      // not already assigned to a group
      !groups.find((g) => {
        return (
          g.diveTourGroupSession1?.instructor?.staffId === m._id ||
          g.diveTourGroupSession2?.instructor?.staffId === m._id
        );
      })
    );
  });
}

/**
 *
 * @param param0
 * @returns
 */
function buildDiveSessionParticipantsGroups({
  diveSession,
  participants,
  groups,
  staffMembers,
  ignoreCancelledParticipants = false,
  ignoreGroupsWithoutParticipants = false,
  diveModesToAutoGroup,
}: {
  diveSession: Pick<
    DiveSession,
    'diveTourSession1' | 'diveTourSession2' | 'staffConfig'
  >;
  participants: DiveSessionResumeParticipant[];
  groups: DiveSessionResumeGroup[];
  staffMembers: ClubResumeStaffMember[];
  ignoreCancelledParticipants?: boolean;
  ignoreGroupsWithoutParticipants?: boolean;
  diveModesToAutoGroup?: DiveMode[];
}): DiveSessionResumeParticipantsByGroup {
  if (ignoreCancelledParticipants) {
    participants = participants.filter(
      (p) => p.bookingState?.value !== 'cancelled',
    );
  }

  const sortedGroups = diveSessionGroupSorter.sortGroups(groups);

  const sortedParticipants =
    diveSessionResumeParticipantSorter.sortSessionParticipants(
      participants,
      {},
    );

  const result: DiveSessionResumeParticipantsByGroup =
    buildDiveSessionResumeFullParticipantsByGroup({
      diveSession,
      staffMembers,
      groups: sortedGroups,
      participants: sortedParticipants,
      rebuildDiveMode: false,
      ignoreGroupsWithoutParticipants,
      diveModesToAutoGroup,
    });

  if (ignoreCancelledParticipants) {
    result.cancelled = participants.filter(
      (p) => p.bookingState?.value === 'cancelled',
    );
  }

  return result;
}

/**
 *
 * Sur la backend, pas d'API graphql, donc on garde l'ancienne méthode pour l'instant
 * EDIT 22/02/2023: avec la fiche d'export journée, ça a changé, donc méthode dépréciée
 * @deprecated
 */
function buildDiveSessionParticipantsByGroupForBackend({
  staffMembers,
  groups,
  participantsExts,
}: {
  staffMembers: ClubStaffMember[];
  groups: DiveSessionGroup[];
  participantsExts: ParticipantExt[];
}): DiveSessionParticipantsByGroup {
  const participantsByGroups = participantsExts.reduce(
    (acc, participantExt) => {
      const { participant } = participantExt;
      const groupId = participant.diveSessionGroupId;

      if (groupId) {
        if (acc.groups[groupId]) {
          acc.groups[groupId].participantsExts.push(participantExt);
        } else {
          const group = groups.find((g) => g._id === groupId);
          acc.groups[groupId] = {
            group,
            participantsExts: [participantExt],
          };
        }
      } else {
        acc.ungroupped.push(participantExt);
      }
      return acc;
    },
    {
      groups: {},
      ungroupped: [],
    } as {
      groups: {
        [groupId: string]: {
          group: DiveSessionGroup;
          participantsExts: ParticipantExt[];
        };
      };
      ungroupped: ParticipantExt[];
    },
  );

  const byGroup = Object.values(participantsByGroups.groups).filter(
    (x) => !!x.group,
  ); // skip missing groups

  const participantsByGroup: DiveSessionParticipantsByGroup = {
    staffMembers: {
      all: staffMembers,
      divingDirectorsStaffs: staffMembers.filter((s) =>
        s.profile.roles.includes('diving-director'),
      ),
      divingInstructorsStaffs: staffMembers.filter(
        (s) =>
          s.profile.roles.includes('scuba-diving-instructor') ||
          s.profile.roles.includes('free-diving-instructor'),
      ),
      surfaceSecurityStaffs: staffMembers.filter((s) =>
        s.profile.roles.includes('surface-security'),
      ),
    },
    all: participantsExts,
    allGroups: groups,
    byGroup,
    groupped: byGroup.reduce(
      (acc, group) => acc.concat(group.participantsExts),
      [],
    ),
    ungroupped: participantsByGroups.ungroupped.filter(
      (x) => x.participant.bookingState.value !== 'cancelled',
    ),
    cancelled: participantsByGroups.ungroupped.filter(
      (x) => x.participant.bookingState.value === 'cancelled',
    ),
  };

  return participantsByGroup;
}
