import React from 'react';
import { AppIcon } from '../../../../business/_core/modules/layout';

type NativeInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export const AppInput = React.forwardRef<
  HTMLInputElement,
  {
    before?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
    icon?: AppIcon;
    unit?: string;
    label?: string;
    error?: boolean;
    paddingClasses?: string;
    iconClasses?: string;
    uppercase?: boolean;
    onClickIcon?: () => void;
  } & NativeInputProps
>(
  (
    {
      icon,
      unit,
      label,
      error,
      required,
      paddingClasses,
      iconClasses,
      className,
      uppercase,
      onClickIcon,
      ...nativeInputProps
    },
    ref,
  ) => {
    const { id = nativeInputProps.id ?? `appinput-${Math.random() * 10000}` } =
      nativeInputProps;

    const classNameInput = `focus:outline-none shadow-sm ${
      error
        ? 'focus:border-gray-200 focus-visible:border-gray-200 text-app-danger'
        : 'focus:border-gray-200 text-app-primary'
    } block w-full ${
      paddingClasses ?? 'px-2 py-1.5'
    } border border-gray-300 rounded-md`;

    const Icon = icon;

    return (
      <div className={`focus:outline-none ${className ?? ''}`}>
        {label && (
          <label
            className="mb-1 text-gray-400 font-bold text-xs block text-left"
            htmlFor={id}
          >
            {label} {required && <span className="text-red-400">*</span>}
          </label>
        )}
        <div className="relative">
          {Icon && (
            <Icon
              onClick={onClickIcon}
              className={
                iconClasses ?? 'absolute top-1 left-1 text-gray-600 w-8 h-8'
              }
            />
          )}
          {unit && (
            <span className="absolute top-2 right-2 text-base text-gray-400">
              {unit}
            </span>
          )}
          <input
            className={`bg-white ${
              uppercase ? 'uppercase' : ''
            } ${classNameInput} ${Icon && !paddingClasses ? 'pl-10' : ''} ${
              unit ? 'pr-10' : ''
            }`}
            ref={ref}
            id={id}
            required={required}
            {...nativeInputProps}
          />
        </div>
      </div>
    );
  },
);
AppInput.displayName = 'AppInput';
