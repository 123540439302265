import { from, Observable, of, throwError } from 'rxjs';
import { catchError, mapTo, switchMap, tap } from 'rxjs/operators';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { libLogger } from '../libLogger.service';
import { browserStorageKeyValueCache } from './browserStorageKeyValueCache.service';

const logger = appLogger.child({
  module: 'browser',
  filename: 'browserCache.service',
});

export const browserCache = {
  set,
  get,
  remove,
};

function set<T>(
  key: string,
  value: T,
  { ignoreError }: { ignoreError: boolean },
): Observable<T> {
  return of(new Date()).pipe(
    switchMap((startDate) =>
      from(browserStorageKeyValueCache.setItem(key, value)).pipe(
        tap(() => {
          const methodDuration = new Date().getTime() - startDate.getTime();
          if (methodDuration > 1000) {
            logger.warn(
              `[set] method duration for key "${key}": ${methodDuration}ms`,
            );
            logger.error('Unexpected method duration');
          }
        }),
        catchError((err) => {
          libLogger.error(
            '[browserCache] Error storing data with key "%s" into browser storage',
            key,
            err,
          );
          if (ignoreError) {
            return of(value);
          } else {
            return throwError(err);
          }
        }),
      ),
    ),
  );
}

function remove(
  key: string,
  { ignoreError }: { ignoreError: boolean },
): Observable<boolean> {
  return from(browserStorageKeyValueCache.removeItem(key)).pipe(
    mapTo(true),
    catchError((err) => {
      libLogger.error(
        '[browserCache] Error removing data with key "%s" into browser storage',
        key,
        err,
      );
      if (ignoreError) {
        return of(false);
      } else {
        return throwError(err);
      }
    }),
  );
}

function get<T = string>(
  key: string,
  { failsIfNull, ignoreError }: { failsIfNull?: boolean; ignoreError: boolean },
): Observable<T> {
  return from(browserStorageKeyValueCache.getItem<T>(key)).pipe(
    switchMap((value) => {
      if (value == null && failsIfNull) {
        libLogger.error('[browserCache] ERROR key "%s" not found:', key);
        return throwError(new Error('Key not found'));
      } else {
        libLogger.debug(
          '[Sec][PersistentCacheService] SUCCESS get key "%s" with value:',
          key,
          value,
        );
        return of(value);
      }
    }),
    catchError((err) => {
      libLogger.error(
        '[browserCache] Error getting data with key "%s" into browser storage',
        key,
        err,
      );
      if (ignoreError) {
        return of(undefined);
      } else {
        return throwError(err);
      }
    }),
  );
}
