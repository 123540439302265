import React from 'react';
import { Control, Path, useController } from 'react-hook-form';
import {
  AppInputBooleanSwitch,
  AppInputBooleanSwitchProps,
} from './AppInputBooleanSwitch';

export function AppInputBooleanSwitchRHF<T>({
  control,
  name,
  label,
  label2,
  icon,
  disabled,
  labelSrOnly,
  onChange: onChangeInput,
  ...extraProps
}: {
  control: Control<T>;
  name: Path<T>;
  required?: boolean;
  onChange?: (value: boolean) => any;
} & Omit<AppInputBooleanSwitchProps, 'onChange'>) {
  const {
    field: { ref, value, onChange, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = useController<T>({
    name,
    control,
    rules: {
      required: extraProps.required,
    },
  });

  return (
    <AppInputBooleanSwitch
      {...extraProps}
      {...inputProps}
      value={value as unknown as boolean}
      disabled={disabled}
      label={label}
      label2={label2}
      icon={icon}
      onChange={(value) => {
        onChange(value);
        onChangeInput && onChangeInput(value);
      }}
    />
  );
}
