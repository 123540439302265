import { DIVE_SESSION_DAY_REFERENCE_SEPARATOR } from './DIVE_SESSION_DAY_REFERENCE_SEPARATOR.const';
import { DIVE_SESSION_REFERENCE_WORDS_SEPARATOR } from './DIVE_SESSION_REFERENCE_WORDS_SEPARATOR.const';

export const commonDiveSessionReferenceParser = {
  parseDayReference,
  parseSessionReference,
  parseSessionReferenceToDate,
};

function parseDayReference(dayReference: string): Date {
  if (!dayReference) {
    return undefined;
  }
  const chunks = dayReference.split(DIVE_SESSION_DAY_REFERENCE_SEPARATOR);
  const year = parseInt(chunks[0], 10);
  const month = parseInt(chunks[1], 10);
  const day = parseInt(chunks[2], 10);

  return new Date(Date.UTC(year, month, day));
}

function parseSessionReference(sessionReference: string): {
  dayReference: string;
} {
  if (!sessionReference) {
    return {
      dayReference: undefined,
    };
  }
  const chunks = sessionReference.split(DIVE_SESSION_REFERENCE_WORDS_SEPARATOR);

  const dayReference = chunks[1];

  return {
    dayReference,
  };
}

function parseSessionReferenceToDate(sessionReference: string): Date {
  if (!sessionReference) {
    return undefined;
  }
  const { dayReference } = parseSessionReference(sessionReference);
  return parseDayReference(dayReference);
}
