export const appUrlBuilderCustomerSpace = {
  buildCustomerBookingTargetLink,
  buildCustomerSpaceBaseLink,
  buildCustomerBookingNewLink,
  buildCustomerOnlineBookingURL,
};
function buildCustomerSpaceBaseLink({
  clubPublicReference,
  diverWebUrl,
}: {
  clubPublicReference: string;
  diverWebUrl: string;
}): {
  targetDomain: string;
  targetRelativeUrl: string;
} {
  const baseUrl = diverWebUrl;
  return {
    targetDomain: baseUrl,
    targetRelativeUrl: `/dive-center/${clubPublicReference}`,
  };
}

function buildCustomerOnlineBookingURL({
  diverWebUrl,
  clubPublicReference,
  categoryId,
  productId,
  context,
}: {
  clubPublicReference: string;
  diverWebUrl: string;
  categoryId?: string;
  productId?: string;
  context?: 'iframe';
}): string {
  const baseUrl = diverWebUrl;
  let targetRelativeUrl = `/dive-center/${clubPublicReference}/activities`;
  const urlParams = new URLSearchParams();
  if (categoryId) {
    urlParams.append('c', categoryId);
    if (productId) {
      urlParams.append('p', productId);
      urlParams.append('m', 'product-view');
    } else {
      urlParams.append('m', 'category-view');
    }
  } else {
    urlParams.append('m', 'categories-list');
  }
  if (context) {
    urlParams.append('context', context);
  }
  if (Array.from(urlParams.keys()).length > 0) {
    // TS2339: Property 'size' does not exist on type 'URLSearchParams'
    targetRelativeUrl += `?${urlParams.toString()}`;
  }
  return `${baseUrl}${targetRelativeUrl}`;
}

function buildCustomerBookingTargetLink({
  clubPublicReference,
  bookingId,
  diverWebUrl,
}: {
  clubPublicReference: string;
  bookingId: string;
  diverWebUrl: string;
}): {
  targetDomain: string;
  targetRelativeUrl: string;
} {
  const baseUrl = diverWebUrl;
  return {
    targetDomain: baseUrl,
    targetRelativeUrl: `/dive-center/${clubPublicReference}/booking/${bookingId}`,
  };
}

function buildCustomerBookingNewLink({
  clubPublicReference,
  diverWebUrl,
  sourceIndex,
}: {
  clubPublicReference: string;
  diverWebUrl: string;
  sourceIndex: number;
}): {
  targetDomain: string;
  targetRelativeUrl: string;
} {
  return {
    targetDomain: diverWebUrl,
    targetRelativeUrl: `/dive-center/${clubPublicReference}/book/${sourceIndex}`,
  };
}
