import clsx from 'clsx';
import React from 'react';
import { Switch } from 'react-router-dom';
import { appRouteBuilder } from 'src/business/_core/modules/root/pages';
import { AppRoute } from 'src/business/_core/modules/router/AppRoute/AppRoute';
import useRouter from 'use-react-router';
import { TopBarRootDiveCenter, useIsIframe } from '../_core/modules/layout';
import { DiveCenterBookingRoutes } from './BO-booking/DiveCenterBookingRoutes';
import { DiveCenterBookingContactPage } from './DiveCenterBookingContactPage';
import { DiveCenterEcommerceHomePage } from './DiveCenterEcommerceHomePage';
import { DiveCenterHomePage } from './DiveCenterHomePage';
import {
  DiveCenterShoppingCartPage,
  DiveCenterShoppingCartProvider,
  DiveCenterShoppingCartSubmitPage,
} from './DiveCenterShoppingCartPage';

export const DiveCenterRootSpace = React.memo(
  function DiveCenterRootSpaceMemo() {
    const { match } =
      useRouter<{
        clubPublicReference: string;
      }>();

    const clubPublicReference = match.params.clubPublicReference;

    const navigationContext = {
      clubPublicReference,
    };

    const isIframe = useIsIframe();
    return (
      <DiveCenterShoppingCartProvider clubPublicReference={clubPublicReference}>
        {!isIframe && (
          <TopBarRootDiveCenter clubPublicReference={clubPublicReference} />
        )}
        <main
          className={clsx(
            'flex w-screen h-[100dvh] overflow-y-hidden',
            !isIframe && 'pt-[50px]',
          )}
        >
          <Switch>
            <AppRoute
              path={`${match.url}/order/booking-contact`}
              exact
              navigationContext={navigationContext}
              component={DiveCenterBookingContactPage}
            />{' '}
            <AppRoute
              path={`${match.url}/order/booking-submit`}
              exact
              navigationContext={navigationContext}
              component={DiveCenterShoppingCartSubmitPage}
            />
            <AppRoute
              path={`${match.url}/shopping-cart`}
              exact
              navigationContext={navigationContext}
              component={DiveCenterShoppingCartPage}
            />
            <AppRoute
              path={`${match.url}/activities`}
              exact
              navigationContext={navigationContext}
              component={DiveCenterEcommerceHomePage}
            />
            <AppRoute
              path={`${match.url}/booking/:bookingId`}
              component={DiveCenterBookingRoutes}
              navigationContext={navigationContext}
              auth={{ requiredRoles: ['diver-booking'] }}
            />
            <AppRoute
              path={`${match.url}`}
              exact
              navigationContext={navigationContext}
              component={DiveCenterHomePage}
            />
            {/* default route */}
            <AppRoute
              path={`${match.url}/`}
              redirectTo={({ appAuth }) =>
                appRouteBuilder.getDefaultRouteFromAuth({ appAuth })
              }
            />
          </Switch>
        </main>
      </DiveCenterShoppingCartProvider>
    );
  },
);
