import { ShoppingCartIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React from 'react';
import { useAppRouter } from '../../../../_core/data/hooks/useAppRouter';
import { diveCenterBookingUrlBuilder } from '../../../BO-booking/diveCenterBookingUrlBuilder.service';
import { useDiveCenterShoppingCart } from '../services';

const DiveCenterShoppingCartIconWithBadge = ({
  clubPublicReference,
  className,
}: {
  clubPublicReference: string;
  className?: string;
}) => {
  const { cart } = useDiveCenterShoppingCart();
  const itemCount = (cart.orderArticles ?? []).reduce(
    (count, article) => count + article?.items.length,
    0,
  );
  const appRouter = useAppRouter();

  return itemCount === 0 ? null : (
    <div
      className={clsx('relative cursor-pointer', className)}
      onClick={() =>
        appRouter.navigate(
          diveCenterBookingUrlBuilder.buildDiveCenterShoppingCartUrl({
            clubPublicReference,
          }),
          {
            cause: 'shopping-cart-icon-click',
          },
        )
      }
    >
      <ShoppingCartIcon className="h-8 w-8 text-gray-700" />
      {itemCount > 0 && (
        <span className="absolute -top-2 -right-2 inline-flex items-center justify-center px-1 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
          {itemCount}
        </span>
      )}
    </div>
  );
};

export default DiveCenterShoppingCartIconWithBadge;
