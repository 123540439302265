"use strict";
exports.__esModule = true;
exports.DEFAULT_PROFILE_VIEW_PLANNING = void 0;
exports.DEFAULT_PROFILE_VIEW_PLANNING = {
    label: 'Vue planning',
    authorizations: {
        // NOUVELLE HIERARCHIE: inspirée de anim-live AuthUserCompanyAuthorizations
        staff: {
            view: {},
            action: {}
        },
        creditNote: {
            // avoirs
            view: {
                bookingDetails: false,
                paymentsList: false
            },
            action: {
                create: false,
                edit: false,
                "delete": false
            }
        },
        communication: {
            action: {
                sendSessionMessage: true,
                sendSessionMessageEmail: true,
                sendSessionMessageSms: true,
                sendSessionMessageWhatsApp: true,
                sendMassMailing: false,
                sendBookingMessage: true,
                sendBookingMessageEmail: true,
                sendBookingMessageSms: true,
                sendBookingMessageWhatsApp: true
            }
        },
        // DEPRECATED: ancienne hiérarchie
        view: {
            planning: {
                participant: true,
                event: true
            },
            participant: {
                diver: true,
                history: true,
                booking: false,
                billing: false,
                payment: false
            },
            dashboard: {
                dailyCash: false,
                dailyPlanningResume: true,
                dailyStats: true,
                stats: false
            },
            lists: {
                diver: false,
                participant: false,
                billing: false,
                payment: false
            },
            settings: {
                global: false,
                diveCenter: false,
                offers: false,
                userAccounts: false,
                mabadiveBilling: false
            }
        },
        edit: {
            participant: {
                diver: false,
                booking: false,
                billing: false,
                billingCreate: false,
                billingEdit: false,
                billingDelete: false,
                payment: false,
                paymentCreate: false,
                paymentDelete: false,
                paymentEdit: false,
                paymentOnlineCapture: false
            },
            planning: {
                session: false,
                event: false,
                securitySheet: false
            },
            settings: {
                global: false,
                diveCenter: false,
                offers: false,
                userAccounts: false,
                accountProfiles: false,
                general: false,
                onlinePayment: false,
                accountProfilesImpersonate: false,
                archiveData: false,
                accomodation: false,
                equipment: false,
                customerSpace: false,
                exportDaily: false,
                inquiry: false
            }
        },
        download: {
            dailyCash: false,
            agenciesPurchases: false,
            securitySheet: true,
            dailyResume: true,
            bookings: false,
            divers: false
        },
        multiDiveCenters: {
            switchCenter: false,
            view: {
                planning: false,
                participant: false,
                dashboard: false,
                lists: false,
                settings: false
            },
            edit: {
                participant: false,
                planning: false,
                settings: false
            }
        }
    }
};
