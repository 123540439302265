import { Customer_ClubResumePublic } from '@mabadive/app-common-model';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { useLoadable } from 'src/business/_core/data/hooks';
import { diveCenterStore } from './diveCenterStore.service';

export function useClubResumePublic(): Customer_ClubResumePublic {
  const { clubResume } = useLoadable({
    debugName: 'useClubResumePublic',
    initialValueFromLoadSnapshot: true,
    load: () =>
      diveCenterStore.clubResume.get().pipe(
        filter((x) => !!x),
        distinctUntilChanged(),
        map((clubResume) => ({ clubResume })),
      ),
  });

  return clubResume;
}
