import { Transition } from '@headlessui/react';
import React, { FC, useMemo, useState } from 'react';
import { AppHeroIcons } from '../../../icons';
import { AppButton } from '../AppButton';

// from https://tailwindui.com/components/application-ui/feedback/alerts
export const AppMessage: FC<{
  children?: React.ReactNode | React.ReactNode[];
  type?: 'info' | 'warn';
  title?: React.ReactNode;
  message?: React.ReactNode;
  className?: string;
  isExpanded?: boolean;
  setIsExpanded?: (isExpanded: boolean) => void;
  isExpandable?: boolean;
}> = ({
  isExpanded: isExpandedInput,
  setIsExpanded: setIsExpandedInput,
  isExpandable: isExpandableInput,
  type = 'info',
  title,
  message,
  children,
  className,
}) => {
  const Icon = type === 'info' ? AppHeroIcons.info : AppHeroIcons.warn;

  const [isExpandedInner, setIsExpandedInner] = useState(isExpandedInput);

  const [isExpanded, setIsExpanded] = useMemo(() => {
    const isExpanded =
      (!!setIsExpandedInput ? isExpandedInput : isExpandedInner) ?? true;
    const setIsExpanded = !!setIsExpandedInput
      ? setIsExpandedInput
      : setIsExpandedInner;
    return [isExpanded, setIsExpanded];
  }, [isExpandedInner, isExpandedInput, setIsExpandedInput]);

  const isExpandable =
    (!isExpandedInput || isExpandableInput) ?? !!setIsExpandedInput;

  return (
    <div
      className={`rounded-md ${
        type === 'info' ? 'bg-blue-50' : 'bg-red-50'
      } p-4 text-gray-600 text-left ${className ?? ''}`}
    >
      <div>
        <div
          className={`flex items-center ${
            isExpandable ? 'cursor-pointer' : ''
          }`}
          onClick={() => isExpandable && setIsExpanded(!isExpanded)}
        >
          <div className="flex-shrink-0">
            <Icon
              className={`h-5 w-5 ${
                type === 'info' ? 'text-status-info' : 'text-status-warn'
              }`}
              aria-hidden="true"
            />
          </div>
          <div
            className={`flex-grow ml-2 font-bold ${
              type === 'info' ? 'text-status-info' : 'text-status-warn'
            }`}
          >
            {!!title && title}
          </div>
          {isExpandable &&
            (isExpanded ? (
              <>
                <AppHeroIcons.actionReduce className="cursor-pointer mr-1 h-4 w-4 lg:h-5 lg:w-5 text-gray-400 group-hover:text-gray-600" />
              </>
            ) : (
              <>
                {!isExpanded && (
                  <AppButton
                    className="hidden md:flex mr-2 font-bold"
                    style={{ minWidth: '6rem' }}
                    size="normal"
                    color={'primary-outline-light'}
                    icon={AppHeroIcons.eye}
                    type="button"
                  >
                    Voir le détail
                  </AppButton>
                )}
                <AppHeroIcons.actionExtend className="cursor-pointer mr-1 h-4 w-4 lg:h-5 lg:w-5 text-gray-400 group-hover:text-gray-600" />
              </>
            ))}
        </div>
        <Transition
          show={isExpanded}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <>
            {message && (
              <div className="mt-3 flex-1 md:flex md:justify-between">
                <div className="text-sm">{message}</div>
              </div>
            )}
            <div className="mt-3">{children && children}</div>
          </>
        </Transition>
      </div>
    </div>
  );
};
