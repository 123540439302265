import React from 'react';
import {
  AppLoadableContentContainer,
  AppLoader,
  AppPageContainer,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import { DiveCenterShoppingCartSubmitPage_AcceptConsent } from './DiveCenterShoppingCartSubmitPage_AcceptConsent';
import { DiveCenterShoppingCartSubmitPage_PanelResume } from './DiveCenterShoppingCartSubmitPage_PanelResume';
import {
  DiveCenterShoppingCartPageLocalState,
  useDiveCenterShoppingCartPageLocalState,
} from './useDiveCenterShoppingCartPageLocalState.hook';

export const DiveCenterShoppingCartSubmitPage = ({
  navigationContext,
}: {
  navigationContext: {
    clubPublicReference: string;
  };
}) => {
  const clubPublicReference = navigationContext.clubPublicReference;

  const localState: DiveCenterShoppingCartPageLocalState =
    useDiveCenterShoppingCartPageLocalState({
      clubPublicReference,
      context: 'shopping-card-submit',
    });
  const { state, data, actions } = localState;

  return state.loadableContent.contentState !== 'full' ? (
    <AppLoader type="loading" />
  ) : (
    <AppPageContainer
      className="bg-gray-50 overflow-y-auto"
      {...state.loadableContent}
    >
      <AppPageContentContainer
        paddingBottom={false}
        className="bg-gray-50 app-p-content"
      >
        <AppLoadableContentContainer {...state.loadableContent}>
          {state.currentView === 'resume' && (
            <DiveCenterShoppingCartSubmitPage_PanelResume
              localState={localState}
            />
          )}
          {state.currentView === 'consent' && (
            <DiveCenterShoppingCartSubmitPage_AcceptConsent
              localState={localState}
            />
          )}
        </AppLoadableContentContainer>
      </AppPageContentContainer>
    </AppPageContainer>
  );
};
