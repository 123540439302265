import { ClubPurchasePackage } from '@mabadive/app-common-model';
import { dataSorter } from '../../../data/sorter';
import { ClubPurchasePackageSortByAttribute } from './ClubPurchasePackageSortByAttribute.type';

export const clubPurchasePackageSorter = {
  sortPurchasesBy,
};

function sortPurchasesBy<T extends ClubPurchasePackage>(
  purchasePackages: T[],
  {
    attributeName,
    asc,
  }: {
    attributeName: ClubPurchasePackageSortByAttribute;
    asc: boolean;
  },
): T[] {
  const type = isFullText() ? 'full-text' : 'default';

  return dataSorter.sortMultiple(purchasePackages, {
    getSortAttributes: (x: T) => [
      {
        value: getAttribute(x, { attributeName }),
        type,
      },
      {
        value: x._createdAt,
        asc: false,
      },
    ],
    asc,
  });

  function isFullText() {
    const textAttributes: ClubPurchasePackageSortByAttribute[] = [];
    return textAttributes.includes(attributeName);
  }

  function getAttribute(
    x: Partial<T>,
    { attributeName }: { attributeName: ClubPurchasePackageSortByAttribute },
  ) {
    return x[attributeName] as any;
  }
}
