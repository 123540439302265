import { DiveServicePlan } from '@mabadive/app-common-model';

export type DiveServicePlanStringFormat = 'xs' | 'short' | 'long' | 'options' | 'long-no-prefix';

export const diveServicePlanFormatter = {
  formatDiveServicePlan,
};

function formatDiveServicePlan(plan: Pick<DiveServicePlan, 'name' | 'divesNumber' | 'nameShort'>, { format }: { format: DiveServicePlanStringFormat }) {
  if (format === 'long') {
    if (plan.divesNumber === 1) {
      return `Plongée x${plan.divesNumber} ${plan.name.trim()}`;
    }
    return `Forfait x${plan.divesNumber} ${plan.name.trim()}`;
  } if (format === 'long-no-prefix') {
    if (plan.divesNumber === 1) {
      return `x${plan.divesNumber} ${plan.name.trim()}`;
    }
    return `x${plan.divesNumber} ${plan.name.trim()}`;
  } if (format === 'xs') {
    return `x${plan.divesNumber}`;
  } if (format === 'short') {
    return `x${plan.divesNumber} ${plan.nameShort}`;
  } if (format === 'options') {
    return plan.name;
  }
}
