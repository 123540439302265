import React from 'react';
import { AppButton, AppHeroIcons } from 'src/business/_core/modules/layout';
import { DiveCenterBookingCheckoutStateInfo } from 'src/business/dive-center/components';
import { DiveCenterBookingPageLocalState } from '../../useDiveCenterBookingPageLocalState.hook';
import { DiveCenterBookingPageTab1BookingResumeMessageCard } from './DiveCenterBookingPageTab1BookingResumeMessageCard';

export function DiveCenterBookingPageTab1BookingResumeStartCheckoutPanel({
  localState,
}: {
  localState: DiveCenterBookingPageLocalState;
}) {
  const { aggregatedBookingResume } = localState;
  const { booking, links, checkoutState } = aggregatedBookingResume;

  const messageToDisplay =
    booking.bookingCustomerConfig?.customerUi?.messageToDisplay;

  const linkToDisplay =
    booking.bookingCustomerConfig?.customerUi?.linkToDisplay;

  return booking.bookingCustomerConfig?.customerUi?.messageToDisplay ||
    links.bookingCheckoutUrl ? (
    <DiveCenterBookingPageTab1BookingResumeMessageCard
      aggregatedBookingResume={aggregatedBookingResume}
      messageToDisplay={messageToDisplay}
      linkToDisplay={linkToDisplay}
    >
      <>
        {links.bookingCheckoutUrl && (
          <>
            <DiveCenterBookingCheckoutStateInfo checkoutState={checkoutState} />

            {links.bookingCheckoutUrl && (
              <AppButton
                fullWidth={true}
                className="mt-8 w-full max-w-sm text-base md:text-lg text-white uppercase"
                color="primary-bg"
                icon={AppHeroIcons.actionEditAlt}
                href={links.bookingCheckoutUrl}
              >
                Renseigner mes informations
              </AppButton>
            )}
          </>
        )}
      </>
    </DiveCenterBookingPageTab1BookingResumeMessageCard>
  ) : null;
}
