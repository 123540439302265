/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';

export const AppMiniProgressBar: FC<{
  progress: number;
}> = ({ progress }) => {
  return (
    <div className="my-1 bg-gray-300 rounded-full">
      <div
        className={'bg-app-blue text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-l-full'}
        style={{
          width: `${Math.floor(100 * progress)}%`,
        }}
      />
    </div>
  );
};
