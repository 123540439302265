import { AppUrlAlias } from '@mabadive/app-common-model';
import { apiClient } from 'src/_common-browser';

export const shortLinkResolverClient = {
  resolveKey,
};

function resolveKey(
  aliasKey: string,
): Promise<Pick<AppUrlAlias, 'targetDomain' | 'targetRelativeUrl'>> {
  return apiClient
    .post<any>('/short-link', {
      authenticate: false,
      json: {
        aliasKey,
      },
    })
    .toPromise()
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      throw err;
    });
}
