"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.MULTI_CENTER_AUTH_USER_DEFAULT_PROFILES = void 0;
var DEFAULT_PROFILE_ACCOUNTANT_const_1 = require("./DEFAULT_PROFILE_ACCOUNTANT.const");
var DEFAULT_PROFILE_BOOKING_PAYMENTS_const_1 = require("./DEFAULT_PROFILE_BOOKING_PAYMENTS.const");
var DEFAULT_PROFILE_COMPANY_ADMIN_const_1 = require("./DEFAULT_PROFILE_COMPANY_ADMIN.const");
var DEFAULT_PROFILE_VIEW_PLANNING_const_1 = require("./DEFAULT_PROFILE_VIEW_PLANNING.const");
var MULTI_CENTER_DEFAULT_PROFILE_AGENCY_const_1 = require("./MULTI_CENTER_DEFAULT_PROFILE_AGENCY.const");
var MULTI_CENTER_DEFAULT_PROFILE_DIVE_CENTER_MANAGER_const_1 = require("./MULTI_CENTER_DEFAULT_PROFILE_DIVE_CENTER_MANAGER.const");
exports.MULTI_CENTER_AUTH_USER_DEFAULT_PROFILES = [
    __assign(__assign({}, DEFAULT_PROFILE_VIEW_PLANNING_const_1.DEFAULT_PROFILE_VIEW_PLANNING), { authorizations: __assign(__assign({}, DEFAULT_PROFILE_VIEW_PLANNING_const_1.DEFAULT_PROFILE_VIEW_PLANNING.authorizations), { multiDiveCenters: __assign(__assign({}, DEFAULT_PROFILE_VIEW_PLANNING_const_1.DEFAULT_PROFILE_VIEW_PLANNING.authorizations.multiDiveCenters), { view: __assign(__assign({}, DEFAULT_PROFILE_VIEW_PLANNING_const_1.DEFAULT_PROFILE_VIEW_PLANNING.authorizations.multiDiveCenters.view), { participant: true }) }) }) }),
    __assign(__assign({}, DEFAULT_PROFILE_BOOKING_PAYMENTS_const_1.DEFAULT_PROFILE_BOOKING), { authorizations: __assign(__assign({}, DEFAULT_PROFILE_BOOKING_PAYMENTS_const_1.DEFAULT_PROFILE_BOOKING.authorizations), { view: __assign(__assign({}, DEFAULT_PROFILE_BOOKING_PAYMENTS_const_1.DEFAULT_PROFILE_BOOKING.authorizations.view), { dashboard: __assign(__assign({}, DEFAULT_PROFILE_BOOKING_PAYMENTS_const_1.DEFAULT_PROFILE_BOOKING.authorizations.view.dashboard), { dailyCash: true }) }), multiDiveCenters: __assign(__assign({}, DEFAULT_PROFILE_BOOKING_PAYMENTS_const_1.DEFAULT_PROFILE_BOOKING.authorizations.multiDiveCenters), { switchCenter: false, view: __assign(__assign({}, DEFAULT_PROFILE_BOOKING_PAYMENTS_const_1.DEFAULT_PROFILE_BOOKING.authorizations.multiDiveCenters.view), { planning: true, participant: true, dashboard: true, lists: true }) }) }) }),
    __assign(__assign({}, DEFAULT_PROFILE_COMPANY_ADMIN_const_1.DEFAULT_PROFILE_COMPANY_ADMIN), { authorizations: __assign(__assign({}, DEFAULT_PROFILE_COMPANY_ADMIN_const_1.DEFAULT_PROFILE_COMPANY_ADMIN.authorizations), { multiDiveCenters: __assign(__assign({}, DEFAULT_PROFILE_COMPANY_ADMIN_const_1.DEFAULT_PROFILE_COMPANY_ADMIN.authorizations.multiDiveCenters), { switchCenter: true, view: {
                    // ...DEFAULT_PROFILE_COMPANY_ADMIN.authorizations.multiDiveCenters
                    //   .view,
                    planning: true,
                    participant: true,
                    dashboard: true,
                    lists: true,
                    settings: true
                }, edit: {
                    // ...DEFAULT_PROFILE_COMPANY_ADMIN.authorizations.multiDiveCenters
                    //   .edit,
                    participant: true,
                    planning: true,
                    settings: true
                } }) }) }),
    __assign(__assign({}, DEFAULT_PROFILE_ACCOUNTANT_const_1.DEFAULT_PROFILE_ACCOUNTANT), { authorizations: __assign(__assign({}, DEFAULT_PROFILE_ACCOUNTANT_const_1.DEFAULT_PROFILE_ACCOUNTANT.authorizations), { multiDiveCenters: __assign(__assign({}, DEFAULT_PROFILE_ACCOUNTANT_const_1.DEFAULT_PROFILE_ACCOUNTANT.authorizations.multiDiveCenters), { switchCenter: false, view: __assign(__assign({}, DEFAULT_PROFILE_ACCOUNTANT_const_1.DEFAULT_PROFILE_ACCOUNTANT.authorizations.multiDiveCenters.view), { planning: false, participant: true, dashboard: true, lists: true, settings: false }) }) }) }),
    MULTI_CENTER_DEFAULT_PROFILE_DIVE_CENTER_MANAGER_const_1.MULTI_CENTER_DEFAULT_PROFILE_DIVE_CENTER_MANAGER,
    MULTI_CENTER_DEFAULT_PROFILE_AGENCY_const_1.MULTI_CENTER_DEFAULT_PROFILE_AGENCY,
];
