import { ClubPublicSettingsCustomerSpaceDisplaySessionConfig } from '@mabadive/app-common-model';
import React from 'react';
import { BookingResumeParticipantsBySession } from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/models';
import { DiveCenterBookingPageAggregatedBookingResume } from '../../BO-booking/DiveCenterBookingPage/model';
import { DiveSessionSecuritySheetCard } from '../DiveCenterSecuritySheetCard';

export function DiveCenterBookingSessionsTable({
  bookingResume,
  participantsBySessions,
  config,
  className,
}: {
  bookingResume: DiveCenterBookingPageAggregatedBookingResume;
  participantsBySessions: BookingResumeParticipantsBySession[];
  config: ClubPublicSettingsCustomerSpaceDisplaySessionConfig;
  className?: string;
}) {
  return (
    <>
      <div
        className={`grid md:grid-cols-2 xl:grid-cols-4 gap-x-4 gap-y-4 max-w-full ${
          className ?? ''
        }`}
      >
        {participantsBySessions.map(
          ({ bookingSessionFull, participants }, j) => (
            <div key={j}>
              <DiveSessionSecuritySheetCard
                clubResume={bookingResume.clubResume}
                bookingSessionFull={bookingSessionFull}
                participants={participants}
                config={config}
              />
            </div>
          ),
        )}
      </div>
    </>
  );
}
