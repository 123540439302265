"use strict";
exports.__esModule = true;
exports.CANCEL_CLUB_TAG = exports.CANCEL_DIVER_TAG = void 0;
exports.CANCEL_DIVER_TAG = {
    reference: 'annulation-plongeur',
    avatar: {
        label: 'A',
        color: '#fff',
        background: '#a84646'
    },
    content: {
        label: 'Annulé (plongeur)',
        color: '#fff',
        background: '#ea5f5f'
    }
};
exports.CANCEL_CLUB_TAG = {
    reference: 'annulation-club',
    avatar: {
        label: 'A',
        color: '#fff',
        background: '#000000'
    },
    content: {
        label: 'Annulé (club)',
        color: '#fff',
        background: '#000000  '
    }
};
