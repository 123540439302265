/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubPublicSettings,
  CustomerEcommerceProductArticleFull,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React from 'react';
import { FieldArrayWithId, UseFormReturn } from 'react-hook-form';
import { useAppSecurityUser } from 'src/business/auth/services';
import { AppFormControlRHF, AppInputRHF } from '../../../../../../../lib/form';
import { useAppCurrencyMain } from '../../../../../../../pages';
import { AppPriceLabel } from '../../../../../../club/modules/_common/ui';
import { CustomerProductsPage_BookProductPanelFormModel } from '../_model';

export const CustomerProductsPage_BookProductPanel_ChooseQuantityPricesItem = ({
  productArticleFull,
  className,
  form,
  field,
  fieldIndex,
  clubPublicSettings,
}: {
  productArticleFull: CustomerEcommerceProductArticleFull;
  form: UseFormReturn<CustomerProductsPage_BookProductPanelFormModel>;
  field: FieldArrayWithId<
    CustomerProductsPage_BookProductPanelFormModel,
    'article.items',
    '_id'
  >;
  fieldIndex: number;
  clubPublicSettings: ClubPublicSettings;
  className?: string;
}) => {
  const mainCurrency = useAppCurrencyMain({ clubPublicSettings });

  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    setValue,
    getValues,
  } = form;

  const securityUser = useAppSecurityUser();

  const formAttributeBaseName = `article.items.${fieldIndex}` as const;

  return (
    <div
      className={clsx(
        'rounded-lg border border-gray-300 bg-gray-50 p-2 w-full grid md:flex gap-2 items-start',
        className,
      )}
    >
      <div className="w-full flex flex-row gap-4 sm:gap-8 justify-between items-start">
        <div className="flex-grow">
          <div className="w-full flex flex-row gap-4 justify-between items-start">
            <div className="uppercase font-bold text-gray-600">
              <div className="text-app-primary">
                {productArticleFull?.product?.name}
              </div>
              <div className="">{field.price.label}</div>
            </div>
            <AppPriceLabel
              className="text-gray-500 font-bold"
              amount={field.price.unitPrice}
              mainCurrency={mainCurrency}
            />
          </div>
          <div className="italic text-gray-500">{field.price.comment}</div>
        </div>
        <AppFormControlRHF
          control={control}
          label={'Quantité'}
          name={`${formAttributeBaseName}.quantity`}
          validation={{
            rules: {
              min: 0,
            },
          }}
          renderComponent={(props) => (
            <AppInputRHF
              {...props}
              className="w-16 text-lg font-bold"
              type="number"
              showIncrementButtons={true}
              showIncrementButtonsClassName="h-12 w-12"
              minValue={0}
            />
          )}
        />
      </div>
    </div>
  );
};
