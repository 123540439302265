"use strict";
exports.__esModule = true;
exports.EXPORT_DAILY_GROUP_DIVER_COLUMN = void 0;
exports.EXPORT_DAILY_GROUP_DIVER_COLUMN = [
    'bookingSessionParticipantsPurchase',
    'sessionParticipantDivesCount',
    'name',
    'level',
    'aptitude',
    'totalDive',
    'lastDive',
    'medicalFormFilled',
    'medicalCertificate',
    'insurance',
    'gaz',
    'gazMod',
    'gazPressure',
    'tank',
    'tankNumber',
    'jacket',
    'wetsuit',
    'fins',
    'divingBoots',
    'scubaRegulator',
    'mask',
    'compass',
    'weighting',
    'rebreather',
    'underwaterScooter',
    'signature',
];
