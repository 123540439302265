import clsx from 'clsx';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { AppButton, AppIconsAction } from 'src/business/_core/modules/layout';
import { DiveCenterBookingCheckoutPageLocalState } from '../../useDiveCenterBookingCheckoutPageLocalState.hook';
import { BookingCheckoutEditMemberSubmitPanelFormModel } from './BookingCheckoutEditMemberSubmitPanel';

export const BookingCheckoutEditMemberSubmitPanelActionsBar = ({
  localState,
  form,
  className,
}: {
  localState: DiveCenterBookingCheckoutPageLocalState;
  form: UseFormReturn<
    BookingCheckoutEditMemberSubmitPanelFormModel,
    any,
    undefined
  >;
  className?: string;
}) => {
  const {
    loadableContent,
    aggregatedBookingResume,
    clubSettings,
    setViewState,
    breadcrumbItems,
    actions,
  } = localState;
  const { diveCenter, links, checkoutState } = aggregatedBookingResume;

  return (
    <div className={className}>
      <div
        className={clsx(
          'flex flex-col-reverse items-stretch xs:grid xs:grid-cols-2 gap-4 md:gap-8 justify-start md:justify-center md:items-start',
        )}
      >
        {checkoutState.customerUpdateState.enableUpdate && (
          <AppButton
            type="button"
            className="text-sm md:text-base font-bold uppercase"
            size="normal"
            fullWidth={false}
            icon={AppIconsAction.confirm}
            // iconPosition="right"
            color="gray-outline-light"
            onClick={() => {
              setViewState({
                mode: 'checkout-edit-members',
                participantsIndex: 0,
              });
            }}
          >
            Modifier
          </AppButton>
        )}
        <AppButton
          type="button"
          className="text-sm md:text-base font-bold uppercase"
          size="normal"
          icon={AppIconsAction.confirm}
          // iconPosition="right"
          color="primary-bg"
          // disabled={!formState.isValid}
          onClick={() => {
            actions.submitConsents(form);
          }}
        >
          Confirmer
        </AppButton>
      </div>
    </div>
  );
};
