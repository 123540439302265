/* eslint-disable @typescript-eslint/no-unused-vars */
import { concat, of } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';
import { useLoadable } from 'src/business/_core/data/hooks';
import { uiStore } from 'src/business/_core/store';
import { AppSnackbarMessage } from './AppSnackbarMessage.model';
import { AppSnackbarProps, AppSnackbarState } from './AppSnackbarState.model';


const initialSnackbar: AppSnackbarProps = {
  open: false,
  type: 'info',
  message: '',
};
export function useAppSnackbar() {

  return useLoadable<AppSnackbarState>({
    debugName: 'useAppSnackbar',
    initialValue: {
      snackbar: initialSnackbar,
    },
    load: () => {

      const state$ = uiStore.snackbarMessage.get().pipe(
        map(snackbarMessage => ({
          open: true,
          type: snackbarMessage.type,
          message: snackbarMessage.content,
          duration: snackbarMessage.duration ? snackbarMessage.duration : getDefaultDuration(snackbarMessage),
        } as AppSnackbarProps)),
        switchMap(snackbar => concat(
          of({
            snackbar,
          } as AppSnackbarState),
          of({
            snackbar: {
              ...snackbar,
              open: false,
            },
          }).pipe(
            delay(snackbar.duration),
          ),
        )),
      );

      return state$;
    },
  });
}

function getDefaultDuration(snackbarMessage: AppSnackbarMessage): number {
  if (snackbarMessage.type === 'warn' || snackbarMessage.type === 'error') {
    return 10000;
  }
  return 2000;
}
