// ISO 3166-1 codes - https://github.com/umpirsky/country-list

import {
  DiveServiceOrganizationReference,
  DIVE_SERVICE_ORGANIZATIONS,
} from '@mabadive/app-common-model';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';

export const DIVE_SERVICE_ORGANIZATIONS_OPTIONS: ValueLabel<
  DiveServiceOrganizationReference,
  string
>[] = DIVE_SERVICE_ORGANIZATIONS.map((org) => {
  const o: ValueLabel<DiveServiceOrganizationReference, string> = {
    value: org?.reference,
    label: org.label,
  };
  return o;
});
