"use strict";
// ISO 3166-1 codes - https://github.com/umpirsky/country-list
exports.__esModule = true;
exports.COUNTRIES_LABELS_BY_CODE = void 0;
// TODO, il faut passer à ISO 3166-2, soit via geonames, soit constistuer en local une liste prédéfinies, à compléter
exports.COUNTRIES_LABELS_BY_CODE = {
    AF: 'Afghanistan',
    ZA: 'Afrique du Sud',
    AL: 'Albanie',
    DZ: 'Alg\u00e9rie',
    DE: 'Allemagne',
    AD: 'Andorre',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctique',
    AG: 'Antigua-et-Barbuda',
    SA: 'Arabie saoudite',
    AR: 'Argentine',
    AM: 'Arm\u00e9nie',
    AW: 'Aruba',
    AU: 'Australie',
    AT: 'Autriche',
    AZ: 'Azerba\u00efdjan',
    BS: 'Bahamas',
    BH: 'Bahre\u00efn',
    BD: 'Bangladesh',
    BB: 'Barbade',
    BE: 'Belgique',
    BZ: 'Belize',
    BJ: 'B\u00e9nin',
    BM: 'Bermudes',
    BT: 'Bhoutan',
    BY: 'Bi\u00e9lorussie',
    BO: 'Bolivie',
    BA: 'Bosnie-Herz\u00e9govine',
    BW: 'Botswana',
    BR: 'Br\u00e9sil',
    BN: 'Brun\u00e9i Darussalam',
    BG: 'Bulgarie',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodge',
    CM: 'Cameroun',
    CA: 'Canada',
    CV: 'Cap-Vert',
    CL: 'Chili',
    CN: 'Chine',
    CY: 'Chypre',
    CO: 'Colombie',
    KM: 'Comores',
    CG: 'Congo-Brazzaville',
    CD: 'Congo-Kinshasa',
    KP: 'Cor\u00e9e du Nord',
    KR: 'Cor\u00e9e du Sud',
    CR: 'Costa Rica',
    CI: 'C\u00f4te d\u2019Ivoire',
    HR: 'Croatie',
    CU: 'Cuba',
    CW: 'Cura\u00e7ao',
    DK: 'Danemark',
    DJ: 'Djibouti',
    DM: 'Dominique',
    EG: '\u00c9gypte',
    AE: '\u00c9mirats arabes unis',
    EC: '\u00c9quateur',
    ER: '\u00c9rythr\u00e9e',
    ES: 'Espagne',
    EE: 'Estonie',
    SZ: 'Eswatini',
    VA: '\u00c9tat de la Cit\u00e9 du Vatican',
    FM: '\u00c9tats f\u00e9d\u00e9r\u00e9s de Micron\u00e9sie',
    US: '\u00c9tats-Unis',
    ET: '\u00c9thiopie',
    FJ: 'Fidji',
    FI: 'Finlande',
    FR: 'France',
    GA: 'Gabon',
    GM: 'Gambie',
    GE: 'G\u00e9orgie',
    GS: 'G\u00e9orgie du Sud et \u00eeles Sandwich du Sud',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Gr\u00e8ce',
    GD: 'Grenade',
    GL: 'Groenland',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernesey',
    GN: 'Guin\u00e9e',
    GQ: 'Guin\u00e9e \u00e9quatoriale',
    GW: 'Guin\u00e9e-Bissau',
    GY: 'Guyana',
    GF: 'Guyane fran\u00e7aise',
    HT: 'Ha\u00efti',
    HN: 'Honduras',
    HU: 'Hongrie',
    BV: '\u00cele Bouvet',
    CX: '\u00cele Christmas',
    IM: '\u00cele de Man',
    NF: '\u00cele Norfolk',
    AX: '\u00celes \u00c5land',
    KY: '\u00celes Ca\u00efmans',
    CC: '\u00celes Cocos',
    CK: '\u00celes Cook',
    FO: '\u00celes F\u00e9ro\u00e9',
    HM: '\u00celes Heard et McDonald',
    FK: '\u00celes Malouines',
    MP: '\u00celes Mariannes du Nord',
    MH: '\u00celes Marshall',
    UM: '\u00celes mineures \u00e9loign\u00e9es des \u00c9tats-Unis',
    PN: '\u00celes Pitcairn',
    SB: '\u00celes Salomon',
    TC: '\u00celes Turques-et-Ca\u00efques',
    VG: '\u00celes Vierges britanniques',
    VI: '\u00celes Vierges des \u00c9tats-Unis',
    IN: 'Inde',
    ID: 'Indon\u00e9sie',
    IQ: 'Irak',
    IR: 'Iran',
    IE: 'Irlande',
    IS: 'Islande',
    IL: 'Isra\u00ebl',
    IT: 'Italie',
    JM: 'Jama\u00efque',
    JP: 'Japon',
    JE: 'Jersey',
    JO: 'Jordanie',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KG: 'Kirghizistan',
    KI: 'Kiribati',
    KW: 'Kowe\u00eft',
    RE: 'La R\u00e9union',
    LA: 'Laos',
    LS: 'Lesotho',
    LV: 'Lettonie',
    LB: 'Liban',
    LR: 'Lib\u00e9ria',
    LY: 'Libye',
    LI: 'Liechtenstein',
    LT: 'Lituanie',
    LU: 'Luxembourg',
    MK: 'Mac\u00e9doine du Nord',
    MG: 'Madagascar',
    MY: 'Malaisie',
    MW: 'Malawi',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malte',
    MA: 'Maroc',
    MQ: 'Martinique',
    MU: 'Maurice',
    MR: 'Mauritanie',
    YT: 'Mayotte',
    MX: 'Mexique',
    MD: 'Moldavie',
    MC: 'Monaco',
    MN: 'Mongolie',
    ME: 'Mont\u00e9n\u00e9gro',
    MS: 'Montserrat',
    MZ: 'Mozambique',
    MM: 'Myanmar (Birmanie)',
    NA: 'Namibie',
    NR: 'Nauru',
    NP: 'N\u00e9pal',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nig\u00e9ria',
    NU: 'Niue',
    NO: 'Norv\u00e8ge',
    NC: 'Nouvelle-Cal\u00e9donie',
    NZ: 'Nouvelle-Z\u00e9lande',
    OM: 'Oman',
    UG: 'Ouganda',
    UZ: 'Ouzb\u00e9kistan',
    PK: 'Pakistan',
    PW: 'Palaos',
    PA: 'Panama',
    PG: 'Papouasie-Nouvelle-Guin\u00e9e',
    PY: 'Paraguay',
    NL: 'Pays-Bas',
    BQ: 'Pays-Bas carib\u00e9ens',
    PE: 'P\u00e9rou',
    PH: 'Philippines',
    PL: 'Pologne',
    PF: 'Polyn\u00e9sie fran\u00e7aise',
    PR: 'Porto Rico',
    PT: 'Portugal',
    QA: 'Qatar',
    HK: 'R.A.S. chinoise de Hong Kong',
    MO: 'R.A.S. chinoise de Macao',
    CF: 'R\u00e9publique centrafricaine',
    DO: 'R\u00e9publique dominicaine',
    RO: 'Roumanie',
    GB: 'Royaume-Uni',
    RU: 'Russie',
    RW: 'Rwanda',
    EH: 'Sahara occidental',
    BL: 'Saint-Barth\u00e9lemy',
    KN: 'Saint-Christophe-et-Ni\u00e9v\u00e8s',
    SM: 'Saint-Marin',
    MF: 'Saint-Martin',
    SX: 'Saint-Martin (partie n\u00e9erlandaise)',
    PM: 'Saint-Pierre-et-Miquelon',
    VC: 'Saint-Vincent-et-les-Grenadines',
    SH: 'Sainte-H\u00e9l\u00e8ne',
    LC: 'Sainte-Lucie',
    SV: 'Salvador',
    WS: 'Samoa',
    AS: 'Samoa am\u00e9ricaines',
    ST: 'Sao Tom\u00e9-et-Principe',
    SN: 'S\u00e9n\u00e9gal',
    RS: 'Serbie',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapour',
    SK: 'Slovaquie',
    SI: 'Slov\u00e9nie',
    SO: 'Somalie',
    SD: 'Soudan',
    SS: 'Soudan du Sud',
    LK: 'Sri Lanka',
    SE: 'Su\u00e8de',
    CH: 'Suisse',
    SR: 'Suriname',
    SJ: 'Svalbard et Jan Mayen',
    SY: 'Syrie',
    TJ: 'Tadjikistan',
    TW: 'Ta\u00efwan',
    TZ: 'Tanzanie',
    TD: 'Tchad',
    CZ: 'Tch\u00e9quie',
    TF: 'Terres australes fran\u00e7aises',
    IO: 'Territoire britannique de l\u2019oc\u00e9an Indien',
    PS: 'Territoires palestiniens',
    TH: 'Tha\u00eflande',
    TL: 'Timor oriental',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinit\u00e9-et-Tobago',
    TN: 'Tunisie',
    TM: 'Turkm\u00e9nistan',
    TR: 'Turquie',
    TV: 'Tuvalu',
    UA: 'Ukraine',
    UY: 'Uruguay',
    VU: 'Vanuatu',
    VE: 'Venezuela',
    VN: 'Vietnam',
    WF: 'Wallis-et-Futuna',
    YE: 'Y\u00e9men',
    ZM: 'Zambie',
    ZW: 'Zimbabwe'
};
