import { DiveServicePurchaseStatus } from '@mabadive/app-common-model';

export const diveServicePurchaseStatusFormatter = {
  formatStatus,
};

function formatStatus(status: DiveServicePurchaseStatus): string {
  switch (status) {
    case 'active':
      return 'En cours';
    case 'completed':
      return 'Imputé';
    case 'obsolete':
      return 'Périmé';
  }
  // default
  return status;
}
