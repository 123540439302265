"use strict";
exports.__esModule = true;
exports.languageFlagConvertor = void 0;
var APP_LANGUAGES_FLAGS_const_1 = require("./APP_LANGUAGES_FLAGS.const");
var getFlag = function (languageCode) {
    // Default to a generic flag for languages not mapped explicitly
    var flag = '🏳️';
    var code = languageCode.split('-')[0]; // Extracts primary language subtag
    if (APP_LANGUAGES_FLAGS_const_1.APP_LANGUAGES_FLAGS[code]) {
        flag = APP_LANGUAGES_FLAGS_const_1.APP_LANGUAGES_FLAGS[code];
    }
    return flag;
};
exports.languageFlagConvertor = {
    getFlag: getFlag
};
