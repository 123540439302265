import { search } from './search';

export const referenceBuilder = { buildCleanReference };

export function buildCleanReference(baseRef: string): string {
  const ref = search
    .clean(baseRef, '-') // normalise (trim, lowercase, replace diacritics...)
    .replace(/[\W_]/g, '-') // replace all non alpha-numeric
    .replace(/(-)\1+/g, '$1') // remove consecutive "-" https://stackoverflow.com/a/55636681/1097926
    .replace(/(^\W*)|(\W*$)/g, ''); // remove initial and final "-"
  return ref;
}
