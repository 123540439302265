/* eslint-disable @typescript-eslint/no-unused-vars */
import { BookingCustomerCheckoutState } from '@mabadive/app-common-model';
import { diveCenterBookingUrlBuilder } from '../../diveCenterBookingUrlBuilder.service';

export const diveCenterBookingLinksBuilder = { buildLinks };

export type DiveCenterBookingCheckoutPageLinks = {
  bookingHomeUrl: string;
  bookingCheckoutUrl: string;
  bookingOnlinePaymentUrl: string;
};

export function buildLinks({
  clubPublicReference,
  bookingId,
  checkoutState,
}: {
  clubPublicReference: string;
  bookingId: string;
  checkoutState: BookingCustomerCheckoutState;
}): DiveCenterBookingCheckoutPageLinks {
  if (clubPublicReference && bookingId) {
    const bookingHomeUrl = diveCenterBookingUrlBuilder.buildBookingHomeUrl({
      bookingId,
      clubPublicReference,
    });
    const bookingCheckoutUrl = checkoutState.customerUpdateState.enableUpdate
      ? diveCenterBookingUrlBuilder.buildBookingCheckoutUrl({
          bookingId,
          clubPublicReference,
        })
      : undefined;
    const bookingOnlinePaymentUrl = checkoutState.paymentsState
      .enableOnlinePayment
      ? diveCenterBookingUrlBuilder.buildBookingOnlinePaymentUrl({
          bookingId,
          clubPublicReference,
        })
      : undefined;
    return {
      bookingHomeUrl,
      bookingCheckoutUrl,
      bookingOnlinePaymentUrl,
    };
  }
  const empty: DiveCenterBookingCheckoutPageLinks = {
    bookingHomeUrl: undefined,
    bookingCheckoutUrl: undefined,
    bookingOnlinePaymentUrl: undefined,
  };
  return empty;
}
