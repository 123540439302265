"use strict";
exports.__esModule = true;
exports.DEFAULT_EQUIPMENT_SETTINGS = void 0;
var DEFAULT_EQUIPMENT_SETTINGS_COMPASS_const_1 = require("./DEFAULT_EQUIPMENT_SETTINGS_COMPASS.const");
var DEFAULT_EQUIPMENT_SETTINGS_COMPUTER_const_1 = require("./DEFAULT_EQUIPMENT_SETTINGS_COMPUTER.const");
var DEFAULT_EQUIPMENT_SETTINGS_DIVING_BOOTS_const_1 = require("./DEFAULT_EQUIPMENT_SETTINGS_DIVING_BOOTS.const");
var DEFAULT_EQUIPMENT_SETTINGS_FIN_const_1 = require("./DEFAULT_EQUIPMENT_SETTINGS_FIN.const");
var DEFAULT_EQUIPMENT_SETTINGS_FLASHLIGHT_const_1 = require("./DEFAULT_EQUIPMENT_SETTINGS_FLASHLIGHT.const");
var DEFAULT_EQUIPMENT_SETTINGS_JACKET_type_1 = require("./DEFAULT_EQUIPMENT_SETTINGS_JACKET.type");
var DEFAULT_EQUIPMENT_SETTINGS_MASK_const_1 = require("./DEFAULT_EQUIPMENT_SETTINGS_MASK.const");
var DEFAULT_EQUIPMENT_SETTINGS_REBREATHER_const_1 = require("./DEFAULT_EQUIPMENT_SETTINGS_REBREATHER.const");
var DEFAULT_EQUIPMENT_SETTINGS_SCUBA_REGULATOR_const_1 = require("./DEFAULT_EQUIPMENT_SETTINGS_SCUBA_REGULATOR.const");
var DEFAULT_EQUIPMENT_SETTINGS_TANK_const_1 = require("./DEFAULT_EQUIPMENT_SETTINGS_TANK.const");
var DEFAULT_EQUIPMENT_SETTINGS_UNDERWATER_SCOOTER_const_1 = require("./DEFAULT_EQUIPMENT_SETTINGS_UNDERWATER_SCOOTER.const");
var DEFAULT_EQUIPMENT_SETTINGS_WEIGHTING_type_1 = require("./DEFAULT_EQUIPMENT_SETTINGS_WEIGHTING.type");
var DEFAULT_EQUIPMENT_SETTINGS_WETSUIT_const_1 = require("./DEFAULT_EQUIPMENT_SETTINGS_WETSUIT.const");
exports.DEFAULT_EQUIPMENT_SETTINGS = [
    DEFAULT_EQUIPMENT_SETTINGS_JACKET_type_1.DEFAULT_EQUIPMENT_SETTINGS_JACKET,
    DEFAULT_EQUIPMENT_SETTINGS_WETSUIT_const_1.DEFAULT_EQUIPMENT_SETTINGS_WETSUIT,
    DEFAULT_EQUIPMENT_SETTINGS_FIN_const_1.DEFAULT_EQUIPMENT_SETTINGS_FIN,
    DEFAULT_EQUIPMENT_SETTINGS_DIVING_BOOTS_const_1.DEFAULT_EQUIPMENT_SETTINGS_DIVING_BOOTS,
    DEFAULT_EQUIPMENT_SETTINGS_MASK_const_1.DEFAULT_EQUIPMENT_SETTINGS_MASK,
    DEFAULT_EQUIPMENT_SETTINGS_TANK_const_1.DEFAULT_EQUIPMENT_SETTINGS_TANK,
    DEFAULT_EQUIPMENT_SETTINGS_SCUBA_REGULATOR_const_1.DEFAULT_EQUIPMENT_SETTINGS_SCUBA_REGULATOR,
    DEFAULT_EQUIPMENT_SETTINGS_COMPUTER_const_1.DEFAULT_EQUIPMENT_SETTINGS_COMPUTER,
    DEFAULT_EQUIPMENT_SETTINGS_FLASHLIGHT_const_1.DEFAULT_EQUIPMENT_SETTINGS_FLASHLIGHT,
    DEFAULT_EQUIPMENT_SETTINGS_COMPASS_const_1.DEFAULT_EQUIPMENT_SETTINGS_COMPASS,
    DEFAULT_EQUIPMENT_SETTINGS_WEIGHTING_type_1.DEFAULT_EQUIPMENT_SETTINGS_WEIGHTING,
    DEFAULT_EQUIPMENT_SETTINGS_REBREATHER_const_1.DEFAULT_EQUIPMENT_SETTINGS_REBREATHER,
    DEFAULT_EQUIPMENT_SETTINGS_UNDERWATER_SCOOTER_const_1.DEFAULT_EQUIPMENT_SETTINGS_UNDERWATER_SCOOTER,
];
// remplace:
// const equipment: ClubSettingsCustomerEquipment = {
//     provider: true,
//     jacket: false, // disabled by default
//     wetsuit: false, // disabled by default
//     fins: false, // disabled by default
//     altSize: false, // disabled by default
//     scubaRegulator: false, // disabled by default
//     mask: false, // disabled by default
//     flashLight: false, // disabled by default
//     computer: false, // disabled by default
//     tank: false, // disabled by default
//     compass: false, // disabled by default
//   };
