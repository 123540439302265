import { StackLogConfig, stackLogConfigurer, StackLogInnerSeverityConfig, StackLogLevel, stackLogLevelTester } from '@mabadive/stack-log-core';
import { StackLogWebAppender, StackLogWebAppenderOptions, StackLogWebProcessHistory } from '../..';
import { stackLogWebConsoleFormatter, StackLogWebConsoleFormatterMain, StackLogWebConsoleFormatterTrace } from './stack-log-web-console-formatter.service';
import { StackLogWebConsoleAppenderConfig } from './stack-log-web-console.appender-config.model';


const DEFAULT_DATE_FORMAT = 'dd/MM/yy HH:mm:ss';

export class StackLogWebConsoleAppender implements StackLogWebAppender {

  private config: {
    severity: StackLogInnerSeverityConfig;
    dateFormat: string;
    maxStackTrace: number;
    maxStackAgeMs: number;
  } = {
      severity: stackLogConfigurer.getDefaultInnerSeverity(),
      dateFormat: DEFAULT_DATE_FORMAT,
      maxStackTrace: 20,
      maxStackAgeMs: 30000,
    };

  constructor(config?: StackLogWebConsoleAppenderConfig) {
    if (config) {
      this.configure(config);
    }
  }

  configure(config: StackLogWebConsoleAppenderConfig) {

    this.config = {
      severity: config.severity !== undefined ? stackLogConfigurer.buildInnerSeverityConfig(config.severity) : this.config.severity,
      dateFormat: config.dateFormat !== undefined ? config.dateFormat : this.config.dateFormat,
      maxStackTrace: config.maxStackTrace !== undefined ? config.maxStackTrace : this.config.maxStackTrace,
      maxStackAgeMs: config.maxStackAgeMs !== undefined ? config.maxStackAgeMs : this.config.maxStackAgeMs,
    };
  }

  activate(config: StackLogConfig) {
  }

  log(options: StackLogWebAppenderOptions, history: StackLogWebProcessHistory) {
    const isEnabled = stackLogLevelTester.isEnabled(options, this.config.severity);
    if (isEnabled) {

      if (options.level === 'error') {
        this.logHistoryTraces(options, history, options.timestamp);
      }

      // print current log
      this.logMain(options);
    }
  }


  private logMain(options: StackLogWebAppenderOptions) {

    // console.error('options:', options)

    const main: StackLogWebConsoleFormatterMain = {
      level: options.level,
      message: options.message,
      options,
      dateFormat: this.config.dateFormat,
    };

    const logMethod = this.getLogMethod(options.level);
    const logMessage = stackLogWebConsoleFormatter.formatLog(main);

    if (options.params && options.params.length !== 0) {
      logMethod(logMessage, ...options.params);
    } else {
      logMethod(logMessage);
    }

  }

  private logHistoryTraces(options: StackLogWebAppenderOptions, history: StackLogWebProcessHistory, processEndDate: Date) {
    history.logs.reverse().forEach(log => {
      this.logTrace(log, history.creationDate, processEndDate);
    });
  }

  private logTrace(options: StackLogWebAppenderOptions, processCreationDate: Date, processEndDate: Date) {

    const age = processEndDate.getTime() - options.timestamp.getTime();

    if (age <= this.config.maxStackAgeMs) {

      const trace: StackLogWebConsoleFormatterTrace = {
        level: options.level,
        message: options.message,
        options,
        processCreationDate,
        processEndDate,
        age,
      };

      const logMethod = this.getLogMethod(options.level);
      const traceMessage = stackLogWebConsoleFormatter.formatLogTrace(trace);

      if (options.params && options.params.length !== 0) {
        logMethod(traceMessage, ...options.params);
      } else {
        logMethod(traceMessage);
      }
    }
  }

  getLogMethod(level: StackLogLevel): (message?: any, ...optionalParams: any[]) => void {
    switch (level) {
      case 'debug': {
        return console.debug;
      }
      case 'info': {
        return console.info;
      }
      case 'warn': {
        return console.warn;
      }
      case 'error': {
        return console.error;
      }
      default: {
        return console.debug;
      }
    }
  }
}

