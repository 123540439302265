import {
  AppAuthenticatedUser,
  AuthUserProfile,
  AuthUserRole,
  ClubAuthUserAuthorizations,
} from '@mabadive/app-common-model';

export const appRolesBuilder = {
  getAppRoles,
};

function getAppRoles(
  authenticatedUser: Omit<AppAuthenticatedUser, 'roles' | 'hasuraRoles'>,
): AuthUserRole[] {
  if (!authenticatedUser) {
    return [];
  }
  const profile: AuthUserProfile = authenticatedUser.profile;
  const rootProfile: AuthUserProfile =
    authenticatedUser.impersonate?.root?.profile ?? authenticatedUser.profile;

  const clubAuthorizations = authenticatedUser.club?.authorizations;

  let roles: AuthUserRole[] = [];

  if (profile === 'super-admin') {
    roles = ['super-admin', 'admin-impersonate-club', 'account-profile'];
  } else if (profile === 'club') {
    if (rootProfile === 'super-admin') {
      // ajout des droits spécifiques au super-admin
      roles = roles.concat([
        'club-download-divers',
        'impersonate-back',
        'club-import-divers',
        'super-admin',
        'club-data-purge', // massive purge
        'club-edit-planning-session',
        'club-edit-settings-user-profiles',
      ]);
    } else if (authenticatedUser?.club?.offerState === 'archived') {
      console.log('xxx club archivé');
      return ['club'];
    }

    roles = roles
      .concat(buildRolesFromClubAuthorizations(clubAuthorizations))
      .concat(['club', 'account', 'account-profile']);

    if (
      clubAuthorizations?.view?.settings?.diveCenter ||
      clubAuthorizations?.edit?.settings?.diveCenter
    ) {
      // admin, manager
      roles.push('club-edit-planning-session');
      roles.push('club-download-divers');
    }
    if (authenticatedUser.impersonate) {
      roles.push('impersonate-back');
    }
  } else if (profile === 'diver-booking') {
    roles = ['diver', 'diver-booking'];
  } else if (profile === 'temporary-token') {
    roles = [authenticatedUser.tmpTokenInfo.role];
  } else {
    throw new Error(`Invalid user profile "${profile}"`);
  }
  return roles;
}

function buildRolesFromClubAuthorizations(
  clubAuthorizations: ClubAuthUserAuthorizations,
): AuthUserRole[] {
  if (clubAuthorizations) {
    return [].concat(
      buildRolesViewFromClubAuthorizations(clubAuthorizations),

      buildRolesEditFromClubAuthorizations(clubAuthorizations),
      buildRolesDownloadFromClubAuthorizations(clubAuthorizations),
      buildRolesMultiDiveCentersFromClubAuthorizations(clubAuthorizations),
    );
  }
  return [];
}

function buildRolesViewFromClubAuthorizations(
  clubAuthorizations: ClubAuthUserAuthorizations,
): AuthUserRole[] {
  const roles: AuthUserRole[] = [];

  const clubViewPlanning =
    clubAuthorizations.view?.planning?.participant ||
    clubAuthorizations.view?.planning?.event;
  if (clubViewPlanning) {
    roles.push('club-view-planning');
    if (clubAuthorizations.view?.planning?.participant) {
      roles.push('club-view-planning-participant');
    }
    if (clubAuthorizations.view?.planning?.event) {
      roles.push('club-view-planning-event');
    }
  }
  const clubViewParticipant =
    clubAuthorizations.view?.participant?.diver ||
    clubAuthorizations.view?.participant?.booking ||
    clubAuthorizations.view?.participant?.billing ||
    clubAuthorizations.view?.participant?.payment;
  if (clubViewParticipant) {
    roles.push('club-view-participant');

    if (clubAuthorizations.view?.participant?.diver) {
      roles.push('club-view-participant-diver');
    }
    if (clubAuthorizations.view?.participant?.booking) {
      roles.push('club-view-participant-booking');
    }
    if (clubAuthorizations.view?.participant?.history) {
      roles.push('club-view-participant-history');
    }
    if (clubAuthorizations.view?.participant?.billing) {
      roles.push('club-view-participant-billing');
    }

    if (clubAuthorizations.view?.participant?.payment) {
      roles.push('club-view-participant-payments');
    }
  }

  const clubViewDashboard =
    clubAuthorizations.view?.dashboard?.dailyCash ||
    clubAuthorizations.view?.dashboard?.dailyPlanningResume ||
    clubAuthorizations.view?.dashboard?.dailyStats ||
    clubAuthorizations.view?.dashboard?.stats;

  if (clubViewDashboard) {
    roles.push('club-view-dashboard');

    if (
      clubAuthorizations.view?.dashboard?.dailyCash ||
      clubAuthorizations.view?.dashboard?.dailyPlanningResume ||
      clubAuthorizations.view?.dashboard?.dailyStats
    ) {
      roles.push('club-view-dashboard-home');
    }

    if (clubAuthorizations.view?.dashboard?.dailyCash) {
      roles.push('club-view-dashboard-daily-cash');
    }
    if (clubAuthorizations.view?.dashboard?.dailyPlanningResume) {
      roles.push('club-view-dashboard-daily-planning-resume');
    }
    if (clubAuthorizations.view?.dashboard?.dailyStats) {
      roles.push('club-view-dashboard-daily-stats');
    }
    if (clubAuthorizations.view?.dashboard?.stats) {
      roles.push('club-view-dashboard-stats');
    }
  }

  const clubViewList =
    clubAuthorizations.view?.lists?.diver ||
    clubAuthorizations.view?.lists?.participant ||
    clubAuthorizations.view?.lists?.billing ||
    clubAuthorizations.view?.lists?.payment;
  if (clubViewList) {
    roles.push('club-view-lists');

    if (clubAuthorizations.view?.lists?.diver) {
      roles.push('club-view-lists-diver');
    }
    if (clubAuthorizations.view?.lists?.participant) {
      roles.push('club-view-lists-participant');
    }
    if (clubAuthorizations.view?.lists?.billing) {
      roles.push('club-view-lists-billing');
    }
    if (clubAuthorizations.view?.lists?.payment) {
      roles.push('club-view-lists-payment');
    }
  }

  const clubViewSettings =
    clubAuthorizations.view?.settings?.diveCenter ||
    clubAuthorizations.view?.settings?.global ||
    clubAuthorizations.view?.settings?.userAccounts ||
    clubAuthorizations.view?.settings?.offers ||
    // clubAuthorizations.view?.settings?.mabadiveBilling || => non ça ce n'est pas dans les paramètres
    clubAuthorizations.view?.settings?.offers;
  if (clubViewSettings) {
    roles.push('club-view-settings');

    if (clubAuthorizations.view?.settings?.diveCenter) {
      roles.push('club-view-settings-dive-center');
    }
    if (clubAuthorizations.view?.settings?.global) {
      roles.push('club-view-settings-global');
    }
    if (clubAuthorizations.view?.settings?.userAccounts) {
      roles.push('club-view-settings-user-accounts');
    }
    if (clubAuthorizations.view?.settings?.offers) {
      roles.push('club-view-settings-offers');
    }
  }
  if (
    clubViewPlanning ||
    clubViewParticipant ||
    clubViewDashboard ||
    clubViewList ||
    clubViewSettings
  ) {
    roles.push('club-view');
  }
  return roles;
}

function buildRolesEditFromClubAuthorizations(
  clubAuthorizations: ClubAuthUserAuthorizations,
): AuthUserRole[] {
  const roles: AuthUserRole[] = [];

  const clubEditPlanning =
    clubAuthorizations.edit?.planning?.session ||
    clubAuthorizations.edit?.planning?.event ||
    clubAuthorizations.edit?.planning?.securitySheet;

  if (clubEditPlanning) {
    roles.push('club-edit-planning');

    if (clubAuthorizations.edit?.planning?.session) {
      roles.push('club-edit-planning-session');
    }
    if (clubAuthorizations.edit?.planning?.event) {
      roles.push('club-edit-planning-event');
    }
    if (clubAuthorizations.edit?.planning?.securitySheet) {
      roles.push('club-edit-planning-security-sheet');
    }
  }
  const clubEditParticipant =
    clubAuthorizations.edit?.participant?.diver ||
    clubAuthorizations.edit?.participant?.booking ||
    clubAuthorizations.edit?.participant?.billing ||
    clubAuthorizations.edit?.participant?.payment;
  if (clubEditParticipant) {
    roles.push('club-edit-participant');

    if (clubAuthorizations.edit?.participant?.diver) {
      roles.push('club-edit-participant-diver');
    }
    if (clubAuthorizations.edit?.participant?.booking) {
      roles.push('club-edit-participant-booking');
    }
    if (clubAuthorizations.edit?.participant?.billing) {
      roles.push('club-edit-participant-billing');
    }
    if (clubAuthorizations.edit?.participant?.payment) {
      roles.push('club-edit-participant-payment');
    }
  }

  const clubEditSettings =
    clubAuthorizations.edit?.settings?.global ||
    clubAuthorizations.edit?.settings?.diveCenter ||
    clubAuthorizations.edit?.settings?.userAccounts ||
    clubAuthorizations.edit?.settings?.general ||
    clubAuthorizations.edit?.settings?.accountProfiles ||
    clubAuthorizations.edit?.settings?.offers;

  if (clubEditSettings) {
    roles.push('club-edit-settings');

    if (clubAuthorizations.edit?.settings?.global) {
      roles.push('club-edit-settings-global');
    }
    if (clubAuthorizations.edit?.settings?.diveCenter) {
      roles.push('club-edit-settings-dive-center');
    }
    if (clubAuthorizations.edit?.settings?.userAccounts) {
      roles.push('club-edit-settings-user-accounts');
    }
    if (clubAuthorizations.edit?.settings?.general) {
      roles.push('club-edit-settings-general');
    }
    if (clubAuthorizations.edit?.settings?.accountProfiles) {
      roles.push('club-edit-settings-user-profiles');
    }
    if (clubAuthorizations.edit?.settings?.offers) {
      roles.push('club-edit-settings-offers');
    }
  }
  if (clubEditPlanning || clubEditParticipant || clubEditSettings) {
    roles.push('club-edit');
  }
  return roles;
}

function buildRolesDownloadFromClubAuthorizations(
  clubAuthorizations: ClubAuthUserAuthorizations,
): AuthUserRole[] {
  const roles: AuthUserRole[] = [];

  const clubDownloadParticipant =
    clubAuthorizations.download?.dailyResume ||
    clubAuthorizations.download?.dailyCash ||
    clubAuthorizations.download?.securitySheet ||
    clubAuthorizations.download?.bookings ||
    clubAuthorizations.download?.divers;
  if (clubDownloadParticipant) {
    roles.push('club-download');

    if (clubAuthorizations.download?.dailyResume) {
      roles.push('club-download-daily-custom');
    }
    if (clubAuthorizations.download?.dailyCash) {
      roles.push('club-download-daily-cash');
    }
    if (clubAuthorizations.download?.securitySheet) {
      roles.push('club-download-security-sheet');
    }
    if (clubAuthorizations.download?.bookings) {
      roles.push('club-download-bookings');
    }
    if (clubAuthorizations.download?.divers) {
      roles.push('club-download-divers');
    }
  }

  return roles;
}

function buildRolesMultiDiveCentersFromClubAuthorizations(
  clubAuthorizations: ClubAuthUserAuthorizations,
): AuthUserRole[] {
  const roles: AuthUserRole[] = [];

  const clubMultiDiveCentersSwitch =
    clubAuthorizations.multiDiveCenters?.switchCenter;
  if (clubMultiDiveCentersSwitch) {
    roles.push('club-multi-dive-centers-switch-center');
  }

  const clubMultiDiveCentersEdit =
    clubAuthorizations.multiDiveCenters?.edit?.participant ||
    clubAuthorizations.multiDiveCenters?.edit?.planning ||
    clubAuthorizations.multiDiveCenters?.edit?.settings;

  if (clubMultiDiveCentersEdit) {
    roles.push('club-multi-dive-centers-edit');

    if (clubAuthorizations.multiDiveCenters?.edit?.participant) {
      roles.push('club-multi-dive-centers-edit-participant');
    }
    if (clubAuthorizations.multiDiveCenters?.edit?.planning) {
      roles.push('club-multi-dive-centers-edit-planning');
    }
    if (clubAuthorizations.multiDiveCenters?.edit?.settings) {
      roles.push('club-multi-dive-centers-edit-settings');
    }
  }

  if (clubMultiDiveCentersSwitch || clubMultiDiveCentersEdit) {
    roles.push('club-multi-dive-centers');
  }

  return roles;
}
