import * as Sentry from '@sentry/browser';
import { AppSecurityUser } from 'src/business/auth/services';
import { appWebConfig } from 'src/business/_core/modules/root/config';

export const appWebLogger = { login, logout, captureMessage };

function buildSentryUser(securityUser: AppSecurityUser): Sentry.User {
  if (securityUser) {
    const u: Sentry.User = {
      id: securityUser?.clubReference,
      username: securityUser?.userId,
    };
    return u;
  }
}

function login(securityUser: AppSecurityUser) {
  Sentry.setUser(buildSentryUser(securityUser));
}

function logout() {
  Sentry.setUser(undefined);
}

export function captureMessage(
  msg: string,
  {
    logContext,
    clubReference,
    extra,
  }: {
    logContext: string;
    clubReference?: string;
    extra: { [attr: string]: any };
  },
) {
  const sentryMessage = `[FRONT]${
    clubReference ? ` [${clubReference.substring(0, 14)}]` : ''
  } ${msg}`;
  Sentry.captureMessage(sentryMessage, {
    extra: {
      ...extra,
      _rawExtra: JSON.stringify(extra),
    },
    tags: {
      logContext,
      clubReference,
    },
  });
  if (appWebConfig.envId === 'dev') {
    // eslint-disable-next-line no-console
    console.warn(logContext, extra);
  }
  // eslint-disable-next-line no-console
  console.error(msg);
}
