/* eslint-disable @typescript-eslint/no-unused-vars */
import { LoadableContent } from 'src/business/_core/data/app-loading';
import { DiveCenterHomePageLoadedContent } from '../DiveCenterHomePage/model';
import { useDiveCenterHomePageLoadedContent } from '../DiveCenterHomePage/useDiveCenterHomePageLoadedContent.hook';

export function useDiveCenterHomePageLocalState({
  clubPublicReference,
}: {
  clubPublicReference: string;
}) {
  const {
    content: loadedContent,
    ...loadableContent
  }: LoadableContent<DiveCenterHomePageLoadedContent> = useDiveCenterHomePageLoadedContent(
    {
      clubPublicReference,
    },
  );

  return {
    loadableContent,
    loadedContent,
  };
}
