/* eslint-disable @typescript-eslint/no-unused-vars */

import { Box, FormControl, FormLabel } from '@material-ui/core';
import React, { CSSProperties, ReactNode } from 'react';

export type AppFormControlV2BaseProps<T> = {
  required?: boolean;
  disabled?: boolean;
  value?: any;
};
export function AppFormControlV2<T>({
  label,
  renderComponent,
  className,
  required,
  disabled,
  style,
}: AppFormControlV2BaseProps<T> & {
  label?: string;
  renderComponent: () => ReactNode;

  className?: string;
  style?: CSSProperties;
}) {
  return (
    <div className={`flex flex-col ${className ?? ''}`} style={style}>
      <FormControl required={required} error={false}>
        <FormLabel className={'form-label pl-1'} component="legend">
          {label && (
            <span
              className={`text-xs uppercase truncate  ${
                disabled ? 'text-gray-400 italic' : ''
              }`}
            >
              {label}
            </span>
          )}
        </FormLabel>
        <Box className="form-input">{renderComponent()}</Box>
      </FormControl>
    </div>
  );
}
