import {
  AppTranslationContentItemButton,
  AppUiTheme,
} from '@mabadive/app-common-model';
import React, { useMemo } from 'react';

import {
  AppButtonUnstyled,
  AppButtonUnstyledProps,
  AppIconsAction,
  AppIconsSocialMedia,
  AppIconsUI,
} from '../../../../../_core/modules/layout';
import { gisMapUrlBuilder } from './gisMapUrlBuilder.service';

export const AppTranslationContentButton = ({
  button,
  theme,
  className,
}: {
  button: AppTranslationContentItemButton;
  theme: AppUiTheme;
  className?: string;
}) => {
  const isFullWidth = useMemo(
    () => (className ?? '').split(' ').includes('w-full'),
    [className],
  );

  const locationUrl: string = useMemo(() => {
    if (button.type === 'direction' || button.type === 'gps-coordinates') {
      return gisMapUrlBuilder.getMapUrl(button.value);
    }
    return '';
  }, [button.type, button.value]);

  // const internalLinkHref = useMemo(() => {
  //   if (button.type === 'internal-link') {
  //     switch (button.internalLink?.page) {
  //       case 'gift-card':
  //         return `${appWebConfig.urls.customerWebMobile}/company/${button.internalLink?.companyReference}/gift-card`;
  //     }
  //   }
  // }, [
  //   button.internalLink?.companyReference,
  //   button.internalLink?.page,
  //   button.type,
  // ]);

  const buttonProps: Pick<AppButtonUnstyledProps, 'icon' | 'href' | 'target'> =
    useMemo(() => {
      switch (button.type) {
        case 'phone': {
          return {
            icon: AppIconsSocialMedia.phone as (
              props: React.ComponentProps<'svg'>,
            ) => JSX.Element,
            href: `tel:${button.value}`,
          };
        }
        case 'email': {
          return {
            icon: AppIconsSocialMedia.mail as (
              props: React.ComponentProps<'svg'>,
            ) => JSX.Element,
            href: `mailto:${button.value}`,
          };
        }
        case 'url': {
          return {
            icon: AppIconsAction.linkExternal as (
              props: React.ComponentProps<'svg'>,
            ) => JSX.Element,
            href: button.value,
            target: '_blank',
          };
        }
        // case 'internal-link': {
        //   return {
        //     icon: AppIcons.link as (
        //       props: React.ComponentProps<'svg'>,
        //     ) => JSX.Element,
        //     href: internalLinkHref,
        //     target: '_blank',
        //   };
        // }
        case 'direction': {
          return {
            icon: AppIconsUI.location as (
              props: React.ComponentProps<'svg'>,
            ) => JSX.Element,
            href: locationUrl,
            target: '_blank',
          };
        }
        case 'gps-coordinates': {
          return {
            icon: AppIconsUI.location as (
              props: React.ComponentProps<'svg'>,
            ) => JSX.Element,
            href: locationUrl,
            target: '_blank',
          };
        }
      }
    }, [button.type, button.value, locationUrl]);

  return (
    button &&
    !!button.label &&
    !!button.value && (
      <AppButtonUnstyled
        {...buttonProps}
        // bgColor={theme.buttonPrimary.bgColor}
        // textColor={theme.buttonPrimary.textColor}
        // externalLink={true}
      >
        <div className={button.label2 ? '-mt-4' : ''}>{button.label}</div>
        {button.label2 && (
          <div className="h-0 -mt-1 text-center text-app-xxs1">
            {button.label2}
          </div>
        )}
      </AppButtonUnstyled>

      // <AppButtonUnstyled
      //     {...buttonProps}
      //     color="primary-outline-light"
      //     className={clsx('w-full max-w-md', className)}
      //     label={button.label}
      //     label2={button.label2}
      //   />
    )
  );
};
