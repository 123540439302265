import { DiveSession, DiveSessionTheme } from '@mabadive/app-common-model';

import { diveModeFormatter } from '../../business/dive/diveModeFormatter.service';

export const diveSessionTitleBuilder = { build };
function build(
  diveSession: Pick<DiveSession, 'name' | 'theme' | 'type' | 'special'>,
): string | undefined {
  if (diveSession.name?.trim()?.length) {
    return diveSession.name?.trim();
  }
  const title = formatDiveSessionTheme(diveSession?.theme);
  if (title) {
    return title;
  }
  switch (diveSession.type) {
    case 'first-dive': {
      return 'Baptêmes'.toUpperCase();
    }
    case 'snorkeling': {
      return diveModeFormatter
        .formatDiveMode('snorkeling', {
          format: 'short-label',
        })
        .toUpperCase();
    }
    case 'snorkeling-tour': {
      return diveModeFormatter
        .formatDiveMode('snorkelingSupervised', {
          format: 'short-label',
        })
        .toUpperCase();
    }
    case 'watching-tour': {
      return diveModeFormatter
        .formatDiveMode('watchingTour', {
          format: 'short-label',
        })
        .toUpperCase();
    }
    case 'dive-auto-supervised': {
      return diveModeFormatter
        .formatDiveMode('autoSupervised', {
          format: 'short-label',
        })
        .toUpperCase();
    }
    case 'theoretical-training': {
      return 'Théorie'.toUpperCase();
    }
  }
  if (diveSession.special) {
    return 'SPÉCIALE';
  }
}
function formatDiveSessionTheme(theme?: DiveSessionTheme): string | undefined {
  switch (theme) {
    case 'night': {
      return 'Nuit'.toUpperCase();
    }
    case 'shark': {
      return 'requins'.toUpperCase();
    }
    case 'whale': {
      return 'baleines'.toUpperCase();
    }
    case 'turtle': {
      return 'tortues'.toUpperCase();
    }
    case 'dolphin': {
      return 'dauphins'.toUpperCase();
    }
    case 'ccr': {
      return 'Recycleur'.toUpperCase();
    }
    case 'photo': {
      return 'Photo'.toUpperCase();
    }
    case 'tek': {
      return 'Tek'.toUpperCase();
    }
    case 'afterwork': {
      return 'Afterwork'.toUpperCase();
    }
    case 'private': {
      return 'Sortie privée'.toUpperCase();
    }
  }
  return undefined;
}
