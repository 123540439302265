import React, { useMemo } from 'react';
import {
  AppButton,
  AppHeroIcons,
  AppMessageLight,
} from 'src/business/_core/modules/layout';
import { DiveCenterBrandCard } from 'src/business/dive-center/components';
import { DiveCenterBookingPageLocalState } from '../../useDiveCenterBookingPageLocalState.hook';
import { cuAppInquiriesByMemberBuilder } from '../DiveCenterBookingPageTab5Inquiries/cuAppInquiriesByMemberBuilder';
import { DiveCenterBookingPageTab1BookingResumeCard } from './DiveCenterBookingPageTab1BookingResumeCard';
import { DiveCenterBookingPageTab1BookingResumeStartCheckoutPanel } from './DiveCenterBookingPageTab1BookingResumeStartCheckoutPanel';

export function DiveCenterBookingPageTab1BookingResume({
  localState,
}: {
  localState: DiveCenterBookingPageLocalState;
}) {
  const { aggregatedBookingResume } = localState;
  const { booking, clubResume, diveCenter, links, checkoutState } =
    aggregatedBookingResume;

  const clubSettings = clubResume?.clubSettings;
  const diveCenterPublicData = diveCenter?.publicData;
  const clubPublicData = clubSettings?.publicData;
  const clubPublicSettings = clubSettings?.publicSettings;
  const clubPublicReference = clubSettings?.clubPublicReference;

  const inquiriesByMember = useMemo(() => {
    return cuAppInquiriesByMemberBuilder.buildInquiriesByMemberModel({
      aggregatedBookingResume,
      clubResume,
    });
  }, [aggregatedBookingResume, clubResume]);

  // TODO: prendre en compte quand le formulaire est rempli
  const pendingInquiriesCount = inquiriesByMember.reduce(
    (acc, x) => acc + x.inquiriesWithResponse.length,
    0,
  );
  const DISABLE_INQUIRIES = false; // clubResume.clubSettings?.general?.inquiry?.enabled;

  return (
    <div className="grid gap-4">
      <div className="flex flex-col-reverse gap-4 md:gap-8 md:flex-row md:justify-between">
        <DiveCenterBookingPageTab1BookingResumeCard
          aggregatedBookingResume={aggregatedBookingResume}
        >
          {checkoutState?.paymentsState.enableOnlinePayment &&
            links.bookingOnlinePaymentUrl && (
              <AppButton
                fullWidth={true}
                className="mt-8 w-full max-w-sm text-base md:text-lg text-white uppercase"
                color="primary-bg"
                icon={AppHeroIcons.payment}
                href={links.bookingOnlinePaymentUrl}
                // onClick={async () => {
                // actions.redirectoToCheckout();
                // setCurrentTab('booking-participants');
                // window.scrollTo({ top: 0 });
                // }}
              >
                Paiement en attente
              </AppButton>
            )}
          {checkoutState?.customerUpdateState.enableUpdate &&
            links.bookingCheckoutUrl && (
              <AppButton
                fullWidth={true}
                className="mt-8 w-full max-w-sm text-base md:text-lg text-white uppercase"
                color="primary-bg"
                icon={AppHeroIcons.actionEditAlt}
                href={links.bookingCheckoutUrl}
                // onClick={async () => {
                // actions.redirectoToCheckout();
                // setCurrentTab('booking-participants');
                // window.scrollTo({ top: 0 });
                // }}
              >
                Renseigner mes informations
              </AppButton>
            )}
          {!DISABLE_INQUIRIES && pendingInquiriesCount > 0 && (
            <AppButton
              fullWidth={true}
              className="mt-8 w-full max-w-sm text-base md:text-lg text-white uppercase"
              color="primary-bg"
              icon={AppHeroIcons.actionEditAlt}
              onClick={async () => {
                // actions.redirectoToCheckout();
                localState.setCurrentTab('booking-inquiries');
                window.scrollTo({ top: 0 });
              }}
            >
              {pendingInquiriesCount === 1
                ? 'Remplir mon formulaire'
                : `Remplir mes formulaires (${pendingInquiriesCount})`}
            </AppButton>
          )}
        </DiveCenterBookingPageTab1BookingResumeCard>
        <DiveCenterBrandCard
          className="app-card app-p-content "
          clubPublicData={clubPublicData}
          clubPublicSettings={clubPublicSettings}
          diveCenterPublicData={diveCenterPublicData}
          clubPublicReference={clubPublicReference}
        />
      </div>

      {checkoutState?.customerUpdateState.status === 'to-validate' && (
        <div className="app-card app-p-content">
          <AppMessageLight className="my-5">
            <div>
              <h2 className="text-lg font-bold">INFORMATIONS ENVOYÉES</h2>
              <p className="my-2">Nous avons bien reçu vos informations.</p>
              <p className="my-2 leading-5">
                Nous allons désormais les vérifier afin de pouvoir valider votre
                réservation.
              </p>
              <p className="my-2 font-bold">A bientôt!</p>
            </div>
          </AppMessageLight>
        </div>
      )}

      <DiveCenterBookingPageTab1BookingResumeStartCheckoutPanel
        localState={localState}
      />

      {/* <div className={'my-5'}>
          <AppButton
            fullWidth={true}
            className="xs:max-w-fit"
            color="primary-outline-light"
            icon={AppHeroIcons.eye}
            onClick={async () => {
              setCurrentTab('booking-sessions-future');
            }}
          >
            Voir le planning de mes futures plongées
          </AppButton>
        )}
      </div> */}
    </div>
  );
}
