import {
  ClubPublicSettingsCustomerSpaceDisplaySessionConfig,
  DiveSession,
} from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import React from 'react';

export const ClubDiveSessionHeaderSessionDate = function ({
  diveSession: session,
  config,
  className = '',
}: {
  diveSession: Pick<DiveSession, 'time'>;
  config: ClubPublicSettingsCustomerSpaceDisplaySessionConfig;
  className?: string;
}) {
  return (
    <div className={`text-white font-bold uppercase text-center ${className}`}>
      {session.time &&
        dateService.formatUTC(
          session.time,
          config?.showDate ? 'dddd DD/MM/YYYY' : 'dddd DD/MM/YYYY',
        )}
    </div>
  );
};
