"use strict";
// https://en.wikipedia.org/wiki/IETF_language_tag
exports.__esModule = true;
exports.APP_COMPANY_DEFAULT_LANGUAGES = void 0;
// IETF BCP 47
exports.APP_COMPANY_DEFAULT_LANGUAGES = [
    'fr',
    'en',
    'es',
    'de',
    'it',
    'nl', // Néerlandais
];
