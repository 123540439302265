/* eslint-disable @typescript-eslint/no-unused-vars */
import { AppAuthenticatedCustomerAuthorizations } from '@mabadive/app-common-model';
import { useEffect, useMemo, useState } from 'react';
import { LoadableContent } from 'src/business/_core/data/app-loading';
import { DiverBookingCriteria } from 'src/business/_core/data/store-repository/diver-booking/model';
import {
  useAppSecurityUser,
  useAppSecurityUserDiverAuthorizations,
} from 'src/business/auth/services';
import {
  DiveCenterBookingPageAggregatedBookingResume,
  DiveCenterBookingPageLoadedContent,
} from './model';
import { diveCenterBookingPageAggregatedBookingResumeBuilder } from './services/aggregators/diveCenterBookingPageAggregatedBookingResumeBuilder.service';
import { DiveCenterBookingPageTabId } from './tabs';
import { useDiveCenterBookingPageLoadedContent } from './useDiveCenterBookingPageLoadedContent.hook';

export type DiveCenterBookingPageLocalState = ReturnType<
  typeof useDiveCenterBookingPageLocalState
>;

export function useDiveCenterBookingPageLocalState({
  bookingId,
  diverId,
}: DiverBookingCriteria) {
  const {
    content: loadedContent,
    ...loadableContent
  }: LoadableContent<DiveCenterBookingPageLoadedContent> = useDiveCenterBookingPageLoadedContent(
    {
      bookingId,
      diverId,
    },
  );

  const securityUser = useAppSecurityUser();
  const az: AppAuthenticatedCustomerAuthorizations =
    useAppSecurityUserDiverAuthorizations();

  const [bookingMembersIdsInitialOrder, setBookingMembersIdsInitialOrder] =
    useState<string[]>();
  const { dataCore, aggregatedDataCore: aggregatedData } =
    diveCenterBookingPageAggregatedBookingResumeBuilder.aggregatedDataCore({
      loadedContent,
      bookingMembersIdsInitialOrder,
      securityUser,
    });

  const initialBookingResume: DiveCenterBookingPageAggregatedBookingResume =
    useMemo(
      () =>
        aggregatedData?.bookingResumes.find(
          (x) => x.booking?._id === bookingId,
        ),
      [aggregatedData?.bookingResumes, bookingId],
    );

  useEffect(() => {
    if (
      initialBookingResume?.bookingMembersIdsInitialOrder &&
      !bookingMembersIdsInitialOrder
    ) {
      setBookingMembersIdsInitialOrder(
        initialBookingResume?.bookingMembersIdsInitialOrder,
      );
    }
  }, [
    bookingMembersIdsInitialOrder,
    initialBookingResume?.bookingMembersIdsInitialOrder,
  ]);

  const [currentTab, setCurrentTab] =
    useState<DiveCenterBookingPageTabId>('booking-resume');

  const aggregatedBookingResume = useMemo(
    () =>
      aggregatedData?.bookingResumes.find(
        (x) => x.booking?._id === initialBookingResume?.booking?._id,
      ),
    [aggregatedData?.bookingResumes, initialBookingResume?.booking?._id],
  );

  return {
    state: {
      bookingId,
    },
    data: {
      aggregatedBookingResume,
    },
    securityUser,
    az,
    loadableContent,
    aggregatedData,
    initialBookingResume,
    aggregatedBookingResume,
    currentTab,
    setCurrentTab,
    clubSettings: aggregatedBookingResume?.clubResume?.clubSettings,
    clubResume: aggregatedBookingResume?.clubResume,
  };
}
