import {
  BookingMember,
  BookingMemberDetails,
  ClubDiver,
} from '@mabadive/app-common-model';

import { commonEntityBuilder } from '../../../data';

export const diverBookingMemberCreator = {
  createBookingMemberFromDiver,
  createBookingMemberFromSourceMember,
};

function createBookingMemberFromDiver({
  clubReference,
  diveCenterId,
  bookingId,
  diver,
}: {
  clubReference: string;
  diveCenterId: string;
  bookingId: string;
  diver: Pick<ClubDiver, '_id' | 'mainCertificationReference'>;
}): BookingMember {
  const now = new Date();

  const bookingMember = commonEntityBuilder.buildEntity<BookingMember>(
    {
      clubReference,
      diveCenterId,
      bookingId,
      diverId: diver._id,
      mainCertificationReference: diver.mainCertificationReference,
      details: {} as unknown as BookingMemberDetails,
    },
    now,
  );

  return bookingMember;
}

function createBookingMemberFromSourceMember({
  clubReference,
  diveCenterId,
  bookingId,
  sourceMember,
}: {
  clubReference: string;
  diveCenterId: string;
  bookingId: string;
  sourceMember: Pick<
    BookingMember,
    'diverId' | 'mainCertificationReference' | 'details'
  >;
}): BookingMember {
  const now = new Date();

  const bookingMember = commonEntityBuilder.buildEntity<BookingMember>(
    {
      clubReference,
      diveCenterId,
      bookingId,
      diverId: sourceMember.diverId,
      mainCertificationReference: sourceMember.mainCertificationReference,
      details: sourceMember.details,
    },
    now,
  );

  return bookingMember;
}
