"use strict";
exports.__esModule = true;
exports.DEFAULT_EQUIPMENT_SETTINGS_DIVING_BOOTS = void 0;
var DEFAULT_SIZES_CLUB = [
    { ref: '27-28', label: '27-28' },
    { ref: '29-30', label: '29-30' },
    { ref: '31-32', label: '31-32' },
    { ref: '33-34', label: '33-34' },
    { ref: '35-36', label: '35-36' },
    { ref: '37-38', label: '37-38' },
    { ref: '39-40', label: '39-40' },
    { ref: '41-42', label: '41-42' },
    { ref: '43-44', label: '43-44' },
    { ref: '45-46', label: '45-46' },
    { ref: '47-48', label: '47-48' }, // Tailles très grandes pour hommes.
];
exports.DEFAULT_EQUIPMENT_SETTINGS_DIVING_BOOTS = {
    type: 'divingBoots',
    enabled: false,
    club: {
        enabled: true,
        models: DEFAULT_SIZES_CLUB.map(function (_a) {
            var ref = _a.ref, label = _a.label;
            return ({
                ref: ref,
                label: label
            });
        }),
        visibleOnSession: false
    },
    self: {
        autoSetOnGlobal: true,
        enabled: false,
        models: [],
        visibleOnSession: false
    }
};
