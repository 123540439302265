import {
  BookingCustomerSpacePrivate,
  ClubPublicSettings,
} from '@mabadive/app-common-model';

export const diverBookingBuilder = {
  createDefaultBookingCustomerSpace,
};

function createDefaultBookingCustomerSpace({
  publicSettings,
}: {
  publicSettings: ClubPublicSettings;
}) {
  const defaultBookingConfig =
    publicSettings?.customerSpace?.defaultBookingConfig;

  // TODO rendre ça configurable au niveau du club
  const defaultBookingCustomerSpacePrivate: BookingCustomerSpacePrivate = {
    generateIndividualLinks: false,
    authorizationsRules: {
      // permet de construire AppAuthenticatedCustomerAuthorizations
      view: {
        // qui peut voir les fiches des autres participants ?
        otherParticipantsDetails: 'contact',
      },
      actions: {
        editSelfDetails: true, // chacun peut éditer sa fiche
        // qui peut modifier les fiches des autres participants ?
        editOtherParticipantsDetails: 'contact',
      },
    },
  };

  return {
    bookingCustomerConfig: defaultBookingConfig,
    bookingCustomerSpacePrivate: defaultBookingCustomerSpacePrivate,
  };
}
