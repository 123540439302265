import { dataSorter, dateService } from '@mabadive/app-common-services';
import {
  AggregatedBookingSessionFull,
  BookingResumeParticipantForSession,
  BookingResumeParticipantsBySession,
} from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/models';
import { DiveCenterBookingPageAggregatedBookingResume } from '../../BO-booking/DiveCenterBookingPage/model';

export const diveCenterBookingParticipantsBySessionModelBuilder = {
  buildParticipantsBySession,
};

export function buildParticipantsBySession({
  bookingResume,
  period,
}: {
  bookingResume: DiveCenterBookingPageAggregatedBookingResume;
  period: 'past' | 'future';
}): BookingResumeParticipantsBySession[] {
  const { bookingMembersFull, bookingParticipantsFull, bookingSessionsFull } =
    bookingResume;

  const today = dateService.getUTCDateWithoutTime(new Date());

  const bookingSessionsFullFiltered = bookingSessionsFull.filter((bs) => {
    if (period === 'past') {
      if (
        dateService.isBefore(bs.diveSession.time, today) ||
        dateService.isTodayUTC(bs.diveSession.time)
      ) {
        return true;
      }
    } else if (period === 'future') {
      if (!dateService.isBefore(bs.diveSession.time, today)) {
        return true;
      }
    }

    return false;
  });

  const bookingSessionsFullSorted = sortSessions(bookingSessionsFullFiltered, {
    asc: period === 'future',
  });

  return bookingSessionsFullSorted.map((bookingSessionFull) => {
    const participants: BookingResumeParticipantForSession[] =
      bookingParticipantsFull
        .filter(
          (p) => p.bookingSession._id === bookingSessionFull.bookingSession._id,
        )
        .map((p) => {
          const bookingMemberFull = bookingMembersFull.find((m) => {
            return p.bookingMember._id === m.bookingMember._id;
          });
          const participant: BookingResumeParticipantForSession = {
            bookingMemberFull,
            bookingParticipantFull: p,
            style: 'normal',
          };
          return participant;
        })
        .filter((x) => x.bookingMemberFull);

    const participantsBySession: BookingResumeParticipantsBySession = {
      bookingSessionFull,
      participants,
    };
    return participantsBySession;
  });
}

function sortSessions(
  all: AggregatedBookingSessionFull[],
  { asc }: { asc: boolean },
): AggregatedBookingSessionFull[] {
  return dataSorter.sortMultiple(all, {
    getSortAttributes: (x) => [
      {
        value: x?.diveSession?.time,
        type: 'default',
        asc: true,
      },
      {
        value: x?.bookingSession?._id,
        type: 'full-text',
      },
    ],
    asc,
  });
}
