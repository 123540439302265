import { stackLogObjectTypeChecker } from './stack-log-object-type-checker.service';


class StackLogMessageAnonymizer {

  public anonymize(item: any | any[], options = {
    privateAttributes: [] as string[],
    maxLevel: 3,
    maxAttributes: 20,
  }): any {
    return this.anonymizeRecursive(item, {
      privateAttributes: options.privateAttributes,
      level: {
        count: 0,
        max: options.maxLevel,
      },
      attributes: {
        count: 0,
        max: options.maxAttributes,
      }
    });
  }

  private anonymizeRecursive(item: any | any[], options: {
    privateAttributes: string[],
    level: {
      count: number,
      max: number,
    },
    attributes: {
      count: number,
      max: number,
    }
  }): any {
    if (!item) {
      return item;
    }
    if (options.level.count > options.level.max) {
      return '...';
    }
    if (Array.isArray(item)) {
      // only display first items
      item = this.anonymizeArray(item, options);
    } else if (stackLogObjectTypeChecker.isObject(item)) {
      if (typeof item.toString === 'function') {
        const toString = item.toString();
        if (toString && toString !== '[object Object]') {
          item = toString;
        } else {
          // anonymize
          item = this.anonymizeObject(item, options);
        }
      } else {
        // anonymize
        item = this.anonymizeObject(item, options);
      }
    }

    return item;
  }

  private anonymizeObject(obj: any, options: {
    privateAttributes: string[],
    level: {
      count: number,
      max: number,
    },
    attributes: {
      count: number,
      max: number,
    }
  }): any {

    if (!obj) {
      return obj;
    }
    if (options.level.count > options.level.max) {
      return '...';
    }
    const anonymousObj = {};

    Object.keys(obj).forEach(key => {

      options.attributes.count++;
      if (options.attributes.count === options.attributes.max + 1) {
        (anonymousObj as any)['...'] = 'more attributes...';
      }
      if (options.attributes.count > options.attributes.max) {
        return;
      }

      let value = obj[key];

      if (value != null) {
        if (options.privateAttributes.find(a => a === key) !== undefined) {
          // anonymize
          value = '***';
        } else {
          value = this.anonymizeRecursive(value, {
            ...options,
            level: {
              ...options.level,
              count: options.level.count + 1,
            },
          });
        }
      }
      if (value != null) {
        // only append if defined
        (anonymousObj as any)[key] = value;
      }
    });

    return anonymousObj;
  }

  private anonymizeArray(arr: any[], options: {
    privateAttributes: string[],
    level: {
      count: number,
      max: number,
    },
    attributes: {
      count: number,
      max: number,
    }
  }): any[] {

    const maxDisplay = 4;
    const value = arr.slice().splice(0, maxDisplay).map(v => this.anonymizeRecursive(v, {
      ...options,
      level: {
        ...options.level,
        count: options.level.count + 1,
      },
    }));
    if (arr.length > maxDisplay) {
      value.push(`${(maxDisplay - arr.length)} more items ...`);
    }
    return value;
  }
}

export const stackLogMessageAnonymizer = new StackLogMessageAnonymizer();