import { diacritics } from './diacritics';

function trim(str: string, replacement = ''): string {
  return str.replace(/^\s+|\s+$/g, replacement);
}

function clean(str: string, replacement = ''): string {
  if (!str) {
    return str;
  }
  return diacritics.clean(trim(str, replacement).toLowerCase());
}

function buildWords(searchText: string): string[] {
  const str = searchText ? clean(searchText) : undefined;
  if (!str || !str.length) {
    return [];
  }
  return str
    .split(' ')
    .map((word) => word.trim())
    .filter((x) => !!x);
}

function buildPostgresSearchString(
  searchText: string,
  {
    requireAllWords,
  }: {
    requireAllWords: boolean;
  },
): string {
  const str = searchText ? clean(searchText) : undefined;
  if (!str || !str.length) {
    return '';
  }
  return str
    .split(' ')
    .map((word) => word.trim())
    .filter((x) => !!x)
    .map((word) => `${word}:*`)
    .join(requireAllWords ? '&' : '|');
}

export const searchCore = {
  trim,
  clean,
  buildWords,
  buildPostgresSearchString,
};
