import { EquipmentDescription } from '@mabadive/app-common-model';

export const equipmentDescriptionFormatter = {
  formatEquipmentDescription,
  formatEquipmentDescriptions,
};

export type EquipmentDescriptionStringFormat = 'short' | 'long';

function formatEquipmentDescriptions({
  equipment,
  format,
  defaultLabel,
  suffix = '',
}: {
  equipment: EquipmentDescription;
  format: EquipmentDescriptionStringFormat;
  defaultLabel?: string;
  suffix?: string;
}): string {
  if (!equipment) {
    return '';
  }

  return formatEquipmentDescription(equipment, {
    format,
    defaultLabel,
    suffix,
  });
}

function formatEquipmentDescription(
  equipment: EquipmentDescription,
  {
    format,
    defaultLabel,
    suffix = '',
  }: {
    format: EquipmentDescriptionStringFormat;
    defaultLabel?: string;
    suffix?: string;
  },
): string {
  let label = defaultLabel || '';
  if (equipment) {
    if (equipment.model1?.ref) {
      if (equipment.model2?.ref) {
        label = `${equipment.model1?.ref} - ${equipment.model2?.ref}`;
      } else {
        label = equipment.model1?.ref;
      }
    } else if (equipment.selfEquipped) {
      label = format === 'long' ? 'ÉQUIPÉ' : 'ÉQ';
    } else if (equipment.clubEquipped) {
      label = 'CLUB';
    }
  }

  return `${label}${suffix ? ` (${suffix})` : ''}`;
}
