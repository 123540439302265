import { ColoredTag } from '@mabadive/app-common-model';
import { Chip, makeStyles } from '@material-ui/core';
import React from 'react';
import { useCacheableClasses } from '../../../root/theme';
import {
  ColoredTagAvatarCardDeprecated,
  ColoredTagAvatarCardSize,
} from '../ColoredTagAvatarCard';

export const ColoredTagCard = ({
  tag,
  avatarSize,
  avatarOnly,
  className,
}: {
  tag: ColoredTag;
  avatarSize?: ColoredTagAvatarCardSize;
  avatarOnly?: boolean;
  className?: string;
}) => {
  const classes = useCacheableClasses(defineClasses(tag));

  if (!avatarSize) {
    avatarSize = 'normal';
  }

  return !tag ? null : avatarOnly ? (
    <ColoredTagAvatarCardDeprecated
      className={className}
      tag={tag}
      size={avatarSize}
    />
  ) : (
    <Chip
      avatar={<ColoredTagAvatarCardDeprecated tag={tag} size={avatarSize} />}
      label={tag.content.label}
      size="small"
      className={`${className ? className : ''} ${classes.chipRoot}`}
    />
  );
};

function defineClasses(tag: ColoredTag) {
  if (!tag) {
    return;
  }
  return {
    cacheKey: `ColoredTagCard-${tag.reference}`,
    buildStyles: () =>
      makeStyles({
        chipRoot: !tag
          ? {}
          : {
              '&.MuiChip-root': {
                color: tag.content.color,
                background: tag.content.background,
              },
              '& .MuiAvatar-root.MuiChip-avatar': {
                color: tag.avatar.color,
                background: tag.avatar.background,
              },
            },
      }),
  };
}
