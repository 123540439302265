import {
  Booking,
  BookingJourneyStep,
  CompanyAccommodation,
} from '@mabadive/app-common-model';

export const bookingJourneyStepTransformer = {
  getFirstStepWithPickup,
  getJourneyStepsMeta,
  getJourneyDates,
  getArrivalDateTime,
  getDepartureDateTime,
  getAccommodations,
};

function getJourneyStepsMeta(
  booking: Pick<Booking, 'bookingJourney'>,
  {
    accommodations,
  }: {
    accommodations: CompanyAccommodation[];
  },
): {
  accommodations: CompanyAccommodation[];
  journeyDates: {
    arrival: Date;
    departure: Date;
  };
  pickupActive: boolean;
} {
  const journeyDates = getJourneyDates(booking);
  const journeyAccommodations = getAccommodations(booking, { accommodations });
  return {
    journeyDates,
    accommodations: journeyAccommodations,
    pickupActive: !!getFirstStepWithPickup(booking),
  };
}

function getFirstStepWithPickup(
  booking: Pick<Booking, 'bookingJourney'>,
): BookingJourneyStep {
  const firstStepWithPickupActive: BookingJourneyStep = (
    booking?.bookingJourney?.steps ?? []
  ).find((s) => s?.pickup?.active);

  return firstStepWithPickupActive;
}

function getJourneyDates(booking: Pick<Booking, 'bookingJourney'>): {
  arrival: Date;
  departure: Date;
} {
  const journeyDates = {
    arrival: bookingJourneyStepTransformer.getArrivalDateTime(booking),
    departure: bookingJourneyStepTransformer.getDepartureDateTime(booking),
  };
  return journeyDates;
}
function getArrivalDateTime(booking: Pick<Booking, 'bookingJourney'>): Date {
  const arrivalDates = (booking?.bookingJourney?.steps ?? [])
    .map((s) => s?.arrival?.date?.getTime())
    .filter((x) => !!x);

  const arrivalDateTime =
    arrivalDates.length > 0 ? new Date(Math.min(...arrivalDates)) : undefined;

  return arrivalDateTime;
}

function getDepartureDateTime(booking: Pick<Booking, 'bookingJourney'>): Date {
  const departureDates = (booking?.bookingJourney?.steps ?? [])
    .map((s) => s?.departure?.date?.getTime())
    .filter((x) => !!x);

  const departureDateTime =
    departureDates.length > 0
      ? new Date(Math.max(...departureDates))
      : undefined;

  return departureDateTime;
}

function getAccommodations(
  booking: Pick<Booking, 'bookingJourney'>,
  {
    accommodations,
  }: {
    accommodations: CompanyAccommodation[];
  },
): CompanyAccommodation[] {
  const bookingAccommodations: CompanyAccommodation[] = (
    booking?.bookingJourney?.steps ?? []
  )
    .map((s) => {
      if (s?.accommodationId) {
        return accommodations.find((x) => x._id === s?.accommodationId);
      }
    })
    .filter((x) => !!x);

  return bookingAccommodations;
}
