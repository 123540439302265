// @ts-ignore
import {
  StackTracker,
  TrackEventArgs,
  TrackPageViewArgs,
} from '../model/StackTracker.type';

// => 'matomo-tracker' n'est plus compatible avec CRA v5 à cause de Webpack5 qui n'inclue plus les polyfill de node: exemple:
// BREAKING CHANGE: webpack < 5 used to include polyfills for node.js core modules by default.
// This is no longer the case. Verify if you need this module and configure a polyfill for it.

// If you want to include a polyfill, you need to:
// 	- add a fallback 'resolve.fallback: { "url": require.resolve("url/") }'
// 	- install 'url'

// https://github.com/matomo-org/matomo-nodejs-tracker
// https://developer.matomo.org/api-reference/tracking-api

export type MatomoArgs = {
  siteId: string;
  url: string;
  authToken?: string;
};

export const matomoTracker = {
  build(matomoConfig: MatomoArgs): StackTracker {
    // const matomo = new NodeMatomoTracker(matomoConfig.siteId, matomoConfig.url);
    // // Optional: Respond to tracking errors
    // matomo.on('error', function (err: Error) {
    //   console.error('[matomoTracker] error tracking request: ', err);
    // });

    return {
      trackPageView,
      trackEvent,
    };

    function trackPageView({ pageTitle, pageUrl }: TrackPageViewArgs) {
      // FIXME disabled for now
      // matomo.track({
      //   url: pageUrl,
      //   action_name: pageTitle,
      //   // ua: 'Node.js v0.10.24',
      // });
    }

    function trackEvent({
      pageUrl,
      category,
      action,
      varName,
      varValue,
      clientIpAddress,
    }: TrackEventArgs) {
      // FIXME disabled for now
      // matomo.track({
      //   url: pageUrl,
      //   e_c: category,
      //   e_a: action,
      //   e_n: varName,
      //   e_v: varValue,
      //   token_auth: matomoConfig.authToken,
      //   cip: clientIpAddress,
      // });
    }
  },
};
