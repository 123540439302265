import { ObjectMap } from './ObjectMap.model';

export const objectMapBuilder = {
  buildMap,
  buildMapCollection,
  buildMapCollectionFn,
};

function buildMap<T>(items: T[], keyAttributeName: keyof T): ObjectMap<T> {
  return items.reduce((acc, item) => {
    acc[(item as any)[keyAttributeName]] = item;
    return acc;
  }, {} as ObjectMap<T>);
}

function buildMapCollection<T>(items: T[], keyAttributeName: keyof T): ObjectMap<T[]> {
  return items.reduce((acc, item) => {
    let arr: T[] = acc[(item as any)[keyAttributeName]];
    if (!arr) {
      arr = [];
      acc[(item as any)[keyAttributeName]] = arr;
    }
    arr.push(item);
    return acc;
  }, {} as ObjectMap<T[]>);
}

function buildMapCollectionFn<T>(items: T[], getKey: (item: T) => string | number): ObjectMap<T[]> {
  return items.reduce((acc, item) => {
    const key = getKey(item);
    let arr: T[] = acc[key];
    if (!arr) {
      arr = [];
      acc[key] = arr;
    }
    arr.push(item);
    return acc;
  }, {} as ObjectMap<T[]>);
}
