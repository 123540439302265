/* eslint-disable @typescript-eslint/no-unused-vars */
import { Listbox } from '@headlessui/react';
import clsx from 'clsx';
import React from 'react';
import { ValueLabel } from 'src/business/club/modules/_common/form/components/ValueLabel.model';
import { AppSingleSelect2HeadlessUIOptionContent } from './AppSingleSelect2HeadlessUIOptionContent';

export type AppSingleSelect2HeadlessUIOptionProps<
  T,
  L extends string | React.ReactNode = string,
> = {
  option: ValueLabel<T, L>;
  color: 'gray' | 'green' | 'blue' | 'fuchsia';
  showIcons?: boolean;
  className?: string;
};

export function AppSingleSelect2HeadlessUIOption<
  T,
  L extends string | React.ReactNode = string,
>({
  option,
  color,
  showIcons = true,
  className,
}: AppSingleSelect2HeadlessUIOptionProps<T, L>) {
  const OptionIcon = option?.icon;

  return (
    <Listbox.Option
      as="div"
      className={({ active }) =>
        clsx(
          'w-full',
          active
            ? `text-white ${
                color === 'blue'
                  ? 'bg-blue-600'
                  : color === 'green'
                  ? 'bg-green-600'
                  : color === 'fuchsia'
                  ? 'bg-fuchsia-600'
                  : 'bg-gray-600'
              }`
            : 'text-gray-500',
          ' font-bold uppercase cursor-pointer select-none relative py-2 pl-2 pr-4',
          className,
        )
      }
      value={option}
    >
      {({ selected, active }) => (
        <AppSingleSelect2HeadlessUIOptionContent
          className="w-full"
          option={option}
          selected={selected}
          active={active}
          color={color}
          showIcons={showIcons}
        />
      )}
    </Listbox.Option>
  );
}
