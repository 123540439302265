import { AppCompanyMessageTargetMode } from '@mabadive/app-common-model';

export const appCompanyMessageTargetModeFormatter = {
  formatMode,
};

function formatMode(mode: AppCompanyMessageTargetMode): string {
  switch (mode) {
    case 'auto':
      return 'envoi direct ⚡';
    case 'manual':
      return 'envoi manuel ❌';
  }
}
