/* eslint-disable no-console */
import * as localforage from 'localforage';
import { appLogger } from 'src/business/_core/modules/root/logger';
import { indexDbWrapper } from './indexDbWrapper.service';

export const browserStorageKeyValueCache = {
  getItem: async <T>(key: string): Promise<T> => {
    try {
      return indexDbWrapper.getItem(key);
    } catch (err) {
      console.error(err);
      appLogger.error(
        `[browserStorageKeyValueCache] Error getting data with key "${key}"`,
        err,
      );
      return localforage.getItem(key);
    }
  },
  setItem: async <T>(key: string, value: T): Promise<T> => {
    try {
      return indexDbWrapper.setItem(key, value);
    } catch (err) {
      console.error(err);
      appLogger.error(
        '[browserStorageKeyValueCache] Error setting data with key "%s"',
        key,
        err,
      );
      return localforage.setItem(key, value);
    }
  },
  removeItem: async (key: string): Promise<void> => {
    try {
      return indexDbWrapper.removeItem(key);
    } catch (err) {
      console.error(err);
      appLogger.error(
        '[browserStorageKeyValueCache] Error removing data with key "%s"',
        key,
        err,
      );
      return localforage.removeItem(key);
    }
  },
};
