import {
  BookingStatus,
  DiveSessionTimeDayPeriod,
} from '@mabadive/app-common-model';
import {
  clubDiveSessionThemeBuilder,
  diveSessionServiceFormatter,
  participantAptitudeBuilder,
} from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { useClubResumePublic } from 'src/business/_core/store';
import {
  AggregatedBookingSessionFull,
  BookingResumeParticipantForSession,
} from 'src/business/club/modules/club-diver-participant/pages/DiverBookingPage/models';
import {
  ParticipantDiveCard,
  ParticipantDiveTags,
  ParticipantDiverAgeLabel,
  ParticipantDiverNameLabel,
} from 'src/business/club/modules/club-planning-light/components';
import { useMergedBookingParticipantStatus } from './useMergedBookingParticipantStatus.hook';

export const DiveCenterSecuritySheetCardParticipant = ({
  bookingSessionFull,
  participant: participantForSession,
}: {
  bookingSessionFull: AggregatedBookingSessionFull;
  participant: BookingResumeParticipantForSession;
}) => {
  const clubPublicSettings =
    useClubResumePublic()?.clubSettings?.publicSettings;
  const clubReference = bookingSessionFull.booking.clubReference;
  const diveSession = bookingSessionFull.diveSession;
  const timeDayPeriod: DiveSessionTimeDayPeriod = useMemo(
    () => clubDiveSessionThemeBuilder.buildTimeDayPeriod(diveSession.time),
    [diveSession.time],
  );
  const diver = participantForSession.bookingMemberFull?.diver;

  const participant =
    participantForSession.bookingParticipantFull?.diveSessionParticipant;
  const bookingSessionParticipant =
    participantForSession.bookingParticipantFull?.bookingSessionParticipant;

  const aptitude = useMemo(
    () =>
      participantAptitudeBuilder.formatAptitude(
        { diver, participant },
        {
          clubReference,
        },
      ),
    [clubReference, diver, participant],
  );

  const mergedBookingParticipantStatus: BookingStatus =
    useMergedBookingParticipantStatus({
      bookingState: participant.bookingState,
      bookingStatus: bookingSessionFull.booking.bookingStatus,
    });
  return (
    <tr
      key={`${participant._id}`}
      className={`align-top row divide-y divide-gray-200 bg-blue-50 booking-status-${
        participant.bookingState.value
      } 
   
    ${participant.bookingState.value === 'cancelled' ? 'bg-gray-200' : ''}`}
    >
      <td
        className={`w-16 px-1
        
        ${
          participant?.bookingState?.value === 'cancelled' ? 'line-through' : ''
        }
        `}
      >
        <div
        // className={bookingStatusBorderStyleBuilder.buildStyle(
        //   mergedBookingParticipantStatus,
        //   {
        //     widthMode: 'large',
        //   },
        // )}
        >
          <ParticipantDiveCard
            className="text-center"
            diveSession={diveSession}
            participant={participant}
          >
            {diveSessionServiceFormatter.formatServiceFromParticipant(
              { diver, participant },
              {
                format: 'short-ref',
                addSuffix: false,
                clubPublicSettings,
                clubReference,
              },
            )}
          </ParticipantDiveCard>
        </div>
      </td>
      {/* <td
        className={`w-16   px-1      
          ${
            participant?.bookingState?.value === 'cancelled'
              ? 'line-through'
              : ''
          }
        `}
      >
        {aptitude?.length !== 0 && (
          <div className="text-center px-0.5 py-0.5 rounded border border-gray-600 text-xs font-bold">
            {aptitude}
          </div>
        )}
      </td> */}
      <td colSpan={2} className={'px-1'}>
        <div>
          <div className="flex justify-start items-center">
            <ParticipantDiverNameLabel
              participant={participant}
              diveSession={diveSession}
              diver={diver}
              displayAgeIfMinor={true}
            />
            <ParticipantDiveTags
              className="ml-1"
              diveSession={diveSession}
              participant={participant}
              diver={diver}
              includeComment={false}
              includeBookingState={true}
            ></ParticipantDiveTags>
          </div>
        </div>
        <ParticipantDiverAgeLabel
          className="hidden sm:div"
          refDate={diveSession?.time}
          diver={diver}
          filter="none"
        />
      </td>

      {/* <td className={`hidden lg:table-cell w-28 pl-1`}>
        <div
          className={bookingStatusBorderStyleBuilder.buildStyle(
            mergedBookingParticipantStatus,
            {
              widthMode: 'large',
              confirmStatus: 'transparent',
              position: 'left',
            },
          )}
        >
          <div className="px-0.5 w-full flex justify-between gap-2">
            {participant.bookingState?.value === 'confirmed' ? (
              <div className="">
                {dateService.formatLocal(
                  participant.bookingState.date,
                  'DD/MM/YYYY',
                )}
              </div>
            ) : (
              <BookingStatusBadge
                className="block truncate text-xs"
                bookingState={participant.bookingState}
                mode="status-only"
              />
            )}
          </div>
        </div>
      </td> */}
    </tr>
  );
};
