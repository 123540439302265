import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { appLogger } from '../../../root/logger';

export const ErrorFallback = ({
  error,
  componentStack,
}: {
  error?: Error;
  componentStack?: string;
}) => {
  useMemo(() => {
    appLogger.warn('[ErrorFallback] unexpected error', error, componentStack);
    appLogger.error(
      `[ErrorFallback] unexpected error ${
        error && error.message ? error.message : ''
      }`,
    );
  }, [componentStack, error]);

  return <ErrorFallbackMessage />;
};

export const ErrorFallbackMessage = () => {
  function refresh() {
    window.location.replace('/');
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          component={Paper}
          m="auto"
          mt={5}
          p={10}
          width="100%"
          maxWidth={500}
          display="flex"
          flexDirection="column"
        >
          <Box mt={2}>
            <Typography>Oups, une erreur innatendue est survenue.</Typography>
          </Box>
          <Box mt={5}>
            <Typography>
              Cliquez sur le bouton ci-dessous pour rafraichir la page.
            </Typography>
          </Box>
          <Box
            mt={10}
            display="flex"
            flexDirection="row"
            justifyContent="center"
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => refresh()}
            >
              Rafraichir la page
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
