import { PackageAttributeDivesCountExtensionMode } from '@mabadive/app-common-model';

export const packageAttributeDivesCountExtensionModeFormatter = {
  formatDivesCountExtensionMode,
};

function formatDivesCountExtensionMode(
  mode: PackageAttributeDivesCountExtensionMode,
): string {
  switch (mode) {
    case 'disabled':
      return 'Bloqué';
    case 'free':
      return 'Libre';
    case 'ratio-rounded':
      return 'Ratio arrondi';
  }
  return mode;
}
