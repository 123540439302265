"use strict";
exports.__esModule = true;
exports.DIVE_MOVE_GROUPS_FULL = void 0;
exports.DIVE_MOVE_GROUPS_FULL = [
    {
        diveModeGroup: 'dive-discover',
        diveModes: ['first-dive']
    },
    {
        diveModeGroup: 'dive-training',
        diveModes: [
            'training', // formation pratique
        ]
    },
    {
        diveModeGroup: 'dive-explo',
        diveModes: [
            'supervised',
            'autonomous',
            'instructor', // guide de palanquée
        ]
    },
    {
        diveModeGroup: 'store',
        diveModes: []
    },
    {
        diveModeGroup: 'dive-autosupervised',
        diveModes: [
            'autoSupervised', // auto-encadrés
        ]
    },
    {
        diveModeGroup: 'snorkeling',
        diveModes: [
            'snorkeling',
            'snorkelingSupervised', // Randonnée palmée (PMT encadré) (+ formation)
        ]
    },
    {
        diveModeGroup: 'watchingTour',
        diveModes: ['watchingTour']
    },
    {
        diveModeGroup: 'free-dive',
        diveModes: ['free-dive', 'free-dive-auto', 'free-dive-training']
    },
    {
        diveModeGroup: 'observer',
        diveModes: ['observer']
    },
];
