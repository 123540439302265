import {
  AppInquiryForClubResumeGql_Customer,
  AppInquiryResponseForBookingGql_Customer,
  Customer_ClubResumePublic,
  CustomerInquiryWithResponse,
  DiveMode,
  INQUIRY_RESPONSE_EDITABLE_STATUSES,
} from '@mabadive/app-common-model';
import {
  appInquiryFilter,
  dataSorter,
  dateService,
} from '@mabadive/app-common-services';
import { useMemo } from 'react';
import { CUS_BookingMemberFull } from '../../../../../club/modules/club-diver-participant/pages/DiverBookingPage/models';
import { DiveCenterBookingPageAggregatedBookingResume } from '../../model';

export type CuApp_MemberInquiriesDetails = {
  bookingMemberFull: CUS_BookingMemberFull;
  activeInquiries: AppInquiryForClubResumeGql_Customer[];
  inquiriesWithResponse: CustomerInquiryWithResponse[];
  nextDiveModes: DiveMode[];
};

export const cuAppInquiriesByMemberBuilder = {
  buildInquiriesByMemberModel,
};

// voir traitement similaire côté PRO: proInquiriesByMemberBuilder
function buildInquiriesByMemberModel({
  aggregatedBookingResume,
  clubResume,
}: {
  aggregatedBookingResume: DiveCenterBookingPageAggregatedBookingResume;
  clubResume: Customer_ClubResumePublic;
}): CuApp_MemberInquiriesDetails[] {
  if (!aggregatedBookingResume || !clubResume) {
    return []; // data not loaded
  }
  const { bookingMembersFull, bookingParticipantsFull } =
    aggregatedBookingResume;
  return bookingMembersFull.map((bookingMemberFull) => {
    const diver = bookingMemberFull.diver;
    const memberBookingParticipantsFull = bookingParticipantsFull.filter(
      (bpf) => bpf.diver?._id === diver._id,
    );
    // only next
    const nextMemberBookingParticipantsFull =
      memberBookingParticipantsFull?.filter(
        (bpf) =>
          dateService.isTodayUTC(bpf?.diveSession?.time) ||
          dateService.isFutureDayUTC(bpf?.diveSession?.time),
      );

    const diveModes = nextMemberBookingParticipantsFull?.reduce((acc, bpf) => {
      if (!acc.includes(bpf?.diveSessionParticipant?.diveMode)) {
        acc.push(bpf?.diveSessionParticipant?.diveMode);
      }
      return acc;
    }, [] as DiveMode[]);
    const nextDiveModes = [...new Set(diveModes)];

    const activeInquiries = appInquiryFilter.findActiveInquiries(
      clubResume.inquiries,
      {
        nextDiveModes,
        diver,
        bookingMemberConfigInquiry:
          bookingMemberFull?.bookingMember?.config?.inquiry,
      },
    );

    const inquiriesWithResponse: CustomerInquiryWithResponse[] = useMemo(() => {
      return buildCustomerInquiryWithResponse({
        aggregatedBookingResume,
        bookingMemberFull,
        activeInquiries,
      });
    }, [activeInquiries, bookingMemberFull]);

    const details: CuApp_MemberInquiriesDetails = {
      bookingMemberFull,
      activeInquiries,
      nextDiveModes,
      inquiriesWithResponse,
    };
    return details;
  });
}
function buildCustomerInquiryWithResponse({
  aggregatedBookingResume,
  bookingMemberFull,
  activeInquiries,
}: {
  aggregatedBookingResume: DiveCenterBookingPageAggregatedBookingResume;
  bookingMemberFull: CUS_BookingMemberFull;
  activeInquiries: AppInquiryForClubResumeGql_Customer[];
}): CustomerInquiryWithResponse[] {
  // only booking member diver inquiries responses
  const bmInquiryResponses =
    aggregatedBookingResume?.bookingInquiryResponses.filter(
      (x) => x.diverId === bookingMemberFull?.diver?._id,
    );

  return activeInquiries
    .map((inquiry) => {
      const matchingInquiryResponses: AppInquiryResponseForBookingGql_Customer[] =
        (bmInquiryResponses ?? []).filter((x) => x.inquiryId === inquiry._id);
      const responses = dataSorter.sortMultiple(matchingInquiryResponses, {
        getSortAttributes: (response) => {
          return [
            {
              // les réponses éditables en premier
              value: INQUIRY_RESPONSE_EDITABLE_STATUSES.includes(
                response?.status,
              )
                ? 1
                : 2,
            },
            {
              // puis par autre statut
              value:
                response?.status === 'draft'
                  ? 1
                  : response?.status === 'rejected'
                  ? 2
                  : response?.status === 'submitted'
                  ? 3
                  : 4,
            },
            {
              // puis par date
              value: response?._createdAt,
            },
          ];
        },
      });
      const lastResponse = responses?.[0];
      return {
        inquiry,
        responses,
        lastResponse,
      };
    })
    .filter(
      (x) =>
        !x.lastResponse ||
        INQUIRY_RESPONSE_EDITABLE_STATUSES.includes(x.lastResponse?.status),
    ); // le questionnaire est masqué une fois validé
}
