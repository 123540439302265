import { DiveServiceTrainingType } from '@mabadive/app-common-model';

export const trainingTypeFormatter = {
  formatTrainingType,
};

function formatTrainingType(trainingType: DiveServiceTrainingType): string {
  switch (trainingType) {
    case 'main-certification':
      return 'Scaphandre';
    case 'child':
      return 'Enfant';
    case 'gaz-certification':
      return 'Gaz spéciaux';
    case 'security-certification':
      return 'Sécurité';
    case 'equipment-certification':
      return 'Équipement';
  }

  // default
  return trainingType;
}
