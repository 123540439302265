"use strict";
exports.__esModule = true;
/**
Nouveaux profils:
- Vue planning: on voit juste le planning
- Vue planning et réservations: on voit le planning et les réservations (mais pas possible de modifier)
- Inscriptions: possibilité d'inscrire + gérer les notes
- Gérant: possibilité d'inscrire + modifier les sessions, bateaux, moniteurs, tarifs... (sauf pour top dive, pas de modif des tarifs)
- SEULEMENT TOP DIVE: profil centrale = Gérant + tarifs
- Admin: Gérant + droits d'accès


 */
// Actuellement, on a plusieurs profils côté club::
// | 'club-admin'
// | 'club-manager'
// | 'club-instructor' // planning + détail profils + inscriptions TODO; renommer en "club-profile-registrations"
// | 'club-profile-planning' // planning seul
// TOPDIVE:
// Profil vue planning : accès uniquement au centre dont il est rattaché
// Profil inscriptions : vue sur l'ensemble des réservations mais aucune modification possible pour les autres centres.
// Profil gérant : idem, vue sur l'ensemble des réservations mais aucune modification possible sur les autres centres.
// Profil de la centrale : accès à tous les centres et modifications possibles sur tous les centres. Mais pas de modification des paramètres (tarifs...)
// Profil administrateur : accès à tous les centres et toutes modifications possibles.
// + profil compta???
