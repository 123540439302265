import { JsonPatchOperation } from '@mabadive/app-common-model';
import { ReplaceOperation } from 'fast-json-patch';

export const jsonPatcherReplaceFullyProcessor = {
  applyReplaceFully,
};

function applyReplaceFully<T>({
  patches,
  attributesToReplaceFully,
  object2,
}: {
  patches: JsonPatchOperation[];
  attributesToReplaceFully?: (keyof T)[];
  object2: Partial<T>;
}): JsonPatchOperation[] {
  if (attributesToReplaceFully && attributesToReplaceFully.length !== 0) {
    return patches.reduce(
      (acc, op) => {
        const propFount = attributesToReplaceFully.find((prop) =>
          op.path.startsWith(`/${prop as unknown as string}/`),
        );
        if (propFount === undefined) {
          acc.remainingPathOperations.push(op);
        } else {
          // replace operation by a full replacement of array
          if (!acc.arraysPropertiesFound.includes(propFount)) {
            acc.arraysPropertiesFound.push(propFount);
            const op: ReplaceOperation<T[keyof T]> = {
              op: 'replace',
              path: `/${propFount as unknown as string}`,
              value: object2[propFount],
            };
            acc.remainingPathOperations.push(op);
          }
        }
        return acc;
      },
      {
        remainingPathOperations: [],
        arraysPropertiesFound: [],
      } as {
        remainingPathOperations: JsonPatchOperation[];
        arraysPropertiesFound: (keyof T)[];
      },
    ).remainingPathOperations;
  }
  return patches;
}
