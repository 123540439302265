import {
  ParticipantActionUser,
  ParticipantBookingStatus,
} from '@mabadive/app-common-model';

export const participantBookingStatusFormatter = {
  formatStatus,
};

function formatStatus(
  {
    value,
    user,
  }: {
    value: ParticipantBookingStatus;
    user?: ParticipantActionUser;
  },
  {
    mode = 'full',
  }: {
    mode?: 'full' | 'status-only' | 'user-only';
  } = { mode: 'full' },
): string {
  if (!value) {
    return '';
  }
  switch (value) {
    case 'pending':
      if (user === 'diver') {
        if (mode === 'full') {
          return 'En attente (plongeur)';
        }
        if (mode === 'status-only') {
          return 'En attente';
        }
        if (mode === 'user-only') {
          return '(plongeur)';
        }
      }
      if (mode === 'full') {
        return 'En attente (club)';
      }
      if (mode === 'status-only') {
        return 'En attente';
      }
      if (mode === 'user-only') {
        return '(club)';
      }
      break;
    case 'confirmed':
      if (mode === 'full') {
        return 'Confirmée';
      }
      if (mode === 'status-only') {
        return 'Confirmée';
      }
      if (mode === 'user-only') {
        return '';
      }
      break;
    case 'cancelled':
      if (user === 'diver') {
        if (mode === 'full') {
          return 'Annulée (plongeur)';
        }
        if (mode === 'status-only') {
          return 'Annulée';
        }
        if (mode === 'user-only') {
          return '(plongeur)';
        }
      }
      if (mode === 'full') {
        return 'Annulée (club)';
      }
      if (mode === 'status-only') {
        return 'Annulée';
      }
      if (mode === 'user-only') {
        return '(club)';
      }
      break;
  }
  // default
  return value;
}
