import { AppCompanyMessageContext } from '@mabadive/app-common-model';

export const appCompanyMessageContextFormatter = {
  formatContext,
};

function formatContext(context: AppCompanyMessageContext): string {
  switch (context) {
    case 'session':
      return `Participants d'une session`;
    case 'booking':
      return `Participants d'une réservation`;
    case 'customer':
      return 'Fiche client';
  }
}
