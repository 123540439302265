import clsx from 'clsx';
import React from 'react';
import { AppLoaderSpinner } from './AppLoaderSpinner';

export const AppLoader = ({
  type = 'loading',
  message: inputMessage,
  className,
}: {
  type?: 'loading' | 'persist';
  message?: string;
  className?: string;
}) => {
  const message =
    inputMessage ??
    (type === 'loading' ? 'Chargement en cours' : 'Sauvegarde en cours');
  return (
    <div
      className={clsx(
        'font-bold text-sm md:text-base text-gray-600 flex gap-4 items-center',
        className,
      )}
    >
      <div>
        <AppLoaderSpinner className="w-6 h-6 md:w-8 md:h-8" />
      </div>
      <div className="flex-grow text-left">{message}...</div>
      <div></div>
    </div>
  );
};
