import { makeStyles, Theme, useTheme } from '@material-ui/core';
import { Context } from 'react';
import { createReactContext } from './createReactContext.service';

const LOCAL_CONTEXT: Context<any> = createReactContext('useCacheableClasses');

export function useCacheableClasses<
  K extends string | number | symbol,
>(options: {
  cacheKey: string;
  buildStyles: (theme: Theme) => (props?: never) => Record<K, string>;
}) {
  const theme = useTheme<Theme>();

  const { cacheKey, buildStyles } = options
    ? options
    : {
        cacheKey: 'useCacheableClasses-EMPTY',
        buildStyles: (theme: Theme) => makeStyles<Theme, any, any>({}),
      };

  const cache = {
    useStyles: getFromCache<K>(cacheKey),
  };

  if (!cache.useStyles) {
    // appLogger.debug('BUILD CLASSES ONLY ONCE for cacheKey "%s"', cacheKey)
    cache.useStyles = saveToCache(cacheKey, buildStyles(theme) as any);
  }

  // const useStyles = contextValue.useStyles;
  return cache.useStyles();
}

function getFromCache<K extends string | number | symbol>(cacheKey: string) {
  return (LOCAL_CONTEXT as any)[cacheKey] as (
    props?: never,
  ) => Record<K, string>;
}

function saveToCache<K extends string | number | symbol>(
  cacheKey: string,
  useStyles: (props?: never) => Record<K, string>,
) {
  (LOCAL_CONTEXT as any)[cacheKey] = useStyles;
  return useStyles;
}
