import { makeStyles, Theme } from '@material-ui/core';
import { useCacheableClasses } from '../useCacheableClasses.hook';
import { buildGlobalButtonsBar } from './buildGlobalButtonsBar.service';
import {
  buildGlobalButtonCancelStyles,
  buildGlobalButtonNavigateStyles,
  buildGlobalButtonRemoveStyles,
  buildGlobalButtonSaveStyles,
} from './buildGlobalButtonStyles.service';
import { buildGlobalClassesTableStyles } from './buildGlobalClassesTableStyles.service';
import { buildGlobalDialogStyles } from './buildGlobalDialogStyles.service';
import { buildGlobalFormCardStyles } from './buildGlobalFormCardStyles.service';
import { buildGlobalInfoCardStyles } from './buildGlobalInfoCardStyles.service';
import { buildGlobalPageBlock } from './buildGlobalPageBlock.service';

export const useGlobalClasses = () =>
  useCacheableClasses({
    cacheKey: 'useGlobalClasses',
    buildStyles: (theme: Theme) =>
      makeStyles({
        paper: {
          padding: theme.spacing(1),
          '&.MuiPaper-root': {
            overflow: 'initial',
          },
        },
        lightExpansionPanel: {
          '&.MuiExpansionPanel-root': {
            boxShadow: 'none',
            border: 0,
            background: 'transparent',
            margin: 0,
            padding: 0,
            '& .MuiExpansionPanelSummary-root, & .MuiExpansionPanelDetails-root':
              {
                margin: 0,
                padding: 0,
              },
            '& .Mui-focusVisible': {
              background: 'transparent',
            },
            '&.MuiPaper-root': {
              overflow: 'initial', // to avoid scrollbar when opened in participant editor dialog
            },
          },
        },
        formCard: buildGlobalFormCardStyles(theme),
        infoCard: buildGlobalInfoCardStyles(theme),
        buttonsBar: buildGlobalButtonsBar(theme),
        pageBlock: buildGlobalPageBlock(theme),
        buttonCancel: buildGlobalButtonCancelStyles(theme),
        buttonSave: buildGlobalButtonSaveStyles(theme),
        buttonRemove: buildGlobalButtonRemoveStyles(theme),
        buttonIconOnly: {},
        buttonNavigate: buildGlobalButtonNavigateStyles(theme),
        table: buildGlobalClassesTableStyles(theme),
        dialog: buildGlobalDialogStyles(theme),
        tabsAppBar: {
          '&.override': {
            backgroundColor: theme.palette.background.paper,
          },
        },
      }),
  });
