/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubResumeGql_Public,
  Customer_ClubResumePublic,
} from '@mabadive/app-common-model';
import { useEffect, useMemo } from 'react';
import {
  LoadableContent,
  LoadableContentActions,
  appLoader,
  useLoadableContent,
} from 'src/business/_core/data/app-loading';
import {
  clubResumeGql_Public_GraphqlFetcher,
  clubResumePublicCustomerBuilder,
} from 'src/business/_core/data/store-repository';
import { diveCenterStore } from 'src/business/_core/store/diveCenterStore.service';

import { DiveCenterHomePageLoadedContent } from '../DiveCenterHomePage/model';

export function useDiveCenterHomePageLoadedContent({
  clubPublicReference,
}: {
  clubPublicReference: string;
}): LoadableContent<DiveCenterHomePageLoadedContent> {
  const criteria = useMemo(
    () => ({ clubPublicReference }),
    [clubPublicReference],
  );

  const loadableContent: LoadableContent<ClubResumeGql_Public> &
    LoadableContentActions = useLoadableContent(
    () =>
      appLoader.load(
        clubResumeGql_Public_GraphqlFetcher.findOne(criteria, {
          type: 'subscription',
        }),
        {
          type: 'full',
          isSubscription: true,
        },
      ),
    [criteria],
    {
      debugName: 'useDiveCenterHomePageLoadedContent',
      useSnapshot: false,
    },
  );

  const clubResume: Customer_ClubResumePublic = useMemo(() => {
    const clubResumeGraphql = loadableContent?.content;
    if (clubResumeGraphql) {
      const clubResume: Customer_ClubResumePublic =
        clubResumePublicCustomerBuilder.buildFromGql_Public(clubResumeGraphql);
      return clubResume;
    }
  }, [loadableContent?.content]);

  useEffect(() => {
    if (clubResume) {
      diveCenterStore.clubResume.set(clubResume);
    }
  }, [clubResume]);

  const content: DiveCenterHomePageLoadedContent = useMemo(() => {
    const diveCenterGraphqlResult: ClubResumeGql_Public =
      loadableContent?.content;

    const content: DiveCenterHomePageLoadedContent = {
      diveCenterGraphqlResult,
      clubResume,
    };
    return content;
  }, [clubResume, loadableContent?.content]);

  return {
    contentState: loadableContent?.contentState,
    lastActionStatus: loadableContent?.lastActionStatus,
    startDate: loadableContent?.startDate,
    endDate: loadableContent?.endDate,
    durationInMs: loadableContent?.durationInMs,
    content,
  };
}
