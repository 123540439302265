import {
  DiveCertification,
  DiveCertificationReference,
  DIVE_CERTIFICATIONS_REFERENCE_MAP,
} from '@mabadive/app-common-model';

export const certificationFormatter = {
  formatCertification,
  formatCertificationReference,
  getFullName,
};

export type CertificationStringFormat =
  | 'ref'
  | 'name'
  | 'ref-name'
  | 'ref-name-org'
  | 'name-org';

function formatCertificationReference(
  reference: string,
  {
    format,
    deepDiver,
  }: {
    format: CertificationStringFormat;
    deepDiver?: boolean;
  },
): string {
  const certification: DiveCertification = getCertification(reference);

  return formatCertification(certification, {
    format,
    deepDiver,
  });
}

function getCertification(reference: string) {
  let certification: DiveCertification =
    DIVE_CERTIFICATIONS_REFERENCE_MAP[reference];
  if (reference === 'NXS-TD') {
    certification = {
      reference,
      label: `Nitrox TD`,
      nitrox: 'simple',
      defaultDiveMode: 'supervised',
    } as unknown as DiveCertification;
  }
  return certification;
}

function formatCertification(
  cert: DiveCertification,
  {
    format,
    deepDiver,
  }: {
    format: CertificationStringFormat;
    deepDiver?: boolean;
  },
): string {
  if (!cert) {
    return '';
  }
  let baseName: string;
  if (format === 'ref') {
    baseName = cert.reference;
  }
  if (format === 'name') {
    baseName = getFullName(cert.reference);
  }
  if (format === 'ref-name') {
    baseName = `${getReferenceLabel(cert)} - ${getFullName(cert.reference)}`;
  }
  if (format === 'name-org') {
    baseName = `${getFullName(cert.reference)} ${getOrganizationLabel(cert)} `;
  }
  if (format === 'ref-name-org') {
    baseName = `${getReferenceLabel(cert)} - ${getFullName(
      cert.reference,
    )} ${getOrganizationLabel(cert)} `;
  }
  if (deepDiver) {
    baseName += '-DD';
  }
  return baseName;
}

function getOrganizationLabel(cert: DiveCertification) {
  const org = cert.organization;
  return org && org.reference === 'padi'
    ? `(${org.reference.toUpperCase()})`
    : '';
}

function getReferenceLabel(cert: DiveCertification) {
  if (cert.referenceDescription) {
    return `${cert.reference} (${cert.referenceDescription})`;
  }
  return cert.reference;
}

function getFullName(reference: DiveCertificationReference) {
  const certification: DiveCertification = getCertification(reference);

  return certification?.label ?? reference;
}
