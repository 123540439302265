"use strict";
exports.__esModule = true;
exports.DEFAULT_EQUIPMENT_SETTINGS_FIN = void 0;
var DEFAULT_SIZES_CLUB = [
    '30/31',
    '32/33',
    '34/35',
    '36/37',
    '38/39',
    '40/41',
    '42/43',
    '44/45',
    '46/47',
    '48/49',
    '50/51',
];
exports.DEFAULT_EQUIPMENT_SETTINGS_FIN = {
    type: 'fins',
    enabled: false,
    club: {
        enabled: false,
        models: DEFAULT_SIZES_CLUB.map(function (ref) { return ({
            ref: ref
        }); }),
        visibleOnSession: true
    },
    self: {
        autoSetOnGlobal: true,
        enabled: false,
        models: [],
        visibleOnSession: true
    }
};
