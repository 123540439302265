/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CustomerEcommerceProductArticleFull,
  EcommerceCategoryGql_Customer,
  EcommerceProductGql_Customer,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { DiveCenterEcommerceHomePageLocalState } from '../../useDiveCenterEcommerceHomePageLocalState.hook';
import { EcommerceProductPreviewContent } from '../03.content';
import { AppTranslationContentPanel } from '../03.content/AppTranslationContent/AppTranslationContentPanel';
import { CustomerProductsPage_CategoryProductArticleCoverCard } from './CustomerProductsPage_CategoryProductArticleCoverCard';
import { CustomerProductsPage_CategoryProductCoverCard } from './CustomerProductsPage_CategoryProductCoverCard';

export const CustomerProductsPage_CategoryProductContentCard = ({
  categoryId,
  productId,
  localState,
  className,
}: {
  categoryId: string;
  productId: string;
  localState: DiveCenterEcommerceHomePageLocalState;
  className?: string;
}) => {
  const { state, data, actions } = localState;

  const category: EcommerceCategoryGql_Customer = useMemo(() => {
    return data.categories.find((x) => x._id === categoryId);
  }, [categoryId, data.categories]);

  const product: EcommerceProductGql_Customer = useMemo(() => {
    return data.products.find((x) => x._id === productId);
  }, [data.products, productId]);

  const categoryConfigs = useMemo(() => {
    return data.productCategoryConfigs.filter(
      (cf) => cf.categoryId === categoryId,
    );
  }, [categoryId, data.productCategoryConfigs]);

  const productCategoryConfigs = useMemo(() => {
    return data.productCategoryConfigs.filter(
      (cf) => cf.productId === product?._id,
    );
  }, [data.productCategoryConfigs, product?._id]);

  const similarProducts: EcommerceProductGql_Customer[] = useMemo(() => {
    return data.products.filter(
      (p) =>
        p._id !== product?._id &&
        categoryConfigs.some((cf) => cf.productId === p._id),
    );
  }, [categoryConfigs, data.products, product?._id]);

  const productArticlesFull: CustomerEcommerceProductArticleFull[] =
    useMemo(() => {
      return data.productArticlesFull.filter(
        (a) => a.product?._id === product?._id,
      );
    }, [data.productArticlesFull, product?._id]);

  const productBooklet = useMemo(() => {
    return data.booklets.find((x) => x._id === product?.bookletId);
  }, [data.booklets, product?.bookletId]);

  const productBookletPage = useMemo(() => {
    return data.bookletPages.find((x) => x._id === productBooklet?.rootPageId);
  }, [productBooklet?.rootPageId, data.bookletPages]);

  const productContent = productBookletPage?.mainContent?.content;

  return (
    <div className={clsx('flex flex-col gap-4 relative', className)}>
      <div className="md:flex gap-16 items-start">
        <div className="flex-grow md:col-span-3">
          <EcommerceProductPreviewContent
            displayWidth="screen"
            product={product}
            appBookletPage={productBookletPage}
            clubPublicSettings={data.clubPublicSettings}
          >
            <div className="h-full flex flex-col gap-8">
              <div className="flex-grow">
                {(productContent?.items ?? []).map((item, i) => (
                  <AppTranslationContentPanel
                    key={i}
                    item={item}
                    appUiTheme={data.appUiTheme}
                  />
                ))}
              </div>
            </div>
          </EcommerceProductPreviewContent>
          <div className="mt-8 w-full">
            <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 gap-y-16 gap-x-4">
              {productArticlesFull.map((productArticleFull) => (
                <CustomerProductsPage_CategoryProductArticleCoverCard
                  className="mx-auto sm:mx-0 max-w-80"
                  key={productArticleFull.article._id}
                  category={category}
                  product={product}
                  productArticleFull={productArticleFull}
                  localState={localState}
                  showCoverImage={true}
                  showContent={true}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="hidden xl:grid flex-0 min-w-64 gap-6">
          {productArticlesFull.map((productArticleFull) => (
            <CustomerProductsPage_CategoryProductArticleCoverCard
              key={productArticleFull.article._id}
              category={category}
              product={product}
              productArticleFull={productArticleFull}
              localState={localState}
              showCoverImage={false}
              showContent={false}
            />
          ))}
        </div>
      </div>

      {similarProducts.length > 0 && (
        <div className="mt-16 border-t-4 border-gray-400 py-4 flex flex-col gap-4 md:gap-6">
          <h3
            className="text-lg font-bold leading-4 uppercase"
            style={{
              color: data.appUiTheme.title1.textColor,
            }}
          >
            Dans la même catégorie
          </h3>
          <p className="text-gray-600">
            Autres produits pouvant vous intéresser:
          </p>
          <div className="grid xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {similarProducts.map((product) => (
              <CustomerProductsPage_CategoryProductCoverCard
                key={product?._id}
                category={category}
                product={product}
                localState={localState}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
